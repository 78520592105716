import { API_ENDPOINTS, APP_LABELS, CDP } from './app-config';

export function getEnvironment() {
  if (
    window.location.origin.includes('localhost') ||
    window.location.origin.includes('dev')
  )
    return 'DEV';

  if (window.location.origin.includes('stage')) return 'STAGE';

  return 'PROD';
}

export function getShowLoginPage() {
  if (getEnvironment() === 'DEV') {
    return {
      showLoginPage: process?.env?.REACT_APP_DEV_SHOW_LOGIN_PAGE,
      showLoginMessage: process?.env?.REACT_APP_DEV_SHOW_LOGIN_PAGE_MESSAGE
    };
  }
  if (getEnvironment() === 'STAGE') {
    return {
      showLoginPage: process?.env?.REACT_APP_STAGE_SHOW_LOGIN_PAGE,
      showLoginMessage: process?.env?.REACT_APP_STAGE_SHOW_LOGIN_PAGE_MESSAGE
    };
  }
  if (getEnvironment() === 'PROD') {
    return {
      showLoginPage: process?.env?.REACT_APP_PROD_SHOW_LOGIN_PAGE,
      showLoginMessage: process?.env?.REACT_APP_PROD_SHOW_LOGIN_PAGE_MESSAGE
    };
  }
}
// export function getStrapiBaseUrl() {
//   if (getEnvironment() === 'STAGE') {
//     return `https://datacollectionhub-cms.y.org/api`;
//   } else {
//     return `${getServicePath()}/Strapi-cms/api`;
//   }
// }
export function getGaTrackingId() {
  if (getEnvironment() === 'DEV' || getEnvironment() === 'STAGE')
    return process.env.REACT_APP_AZURE_STAGE_GA_TRACKING_ID;

  return process.env.REACT_APP_AZURE_PROD_GA_TRACKING_ID;
}

export function getServicePath() {
  if (getEnvironment() === 'DEV') return API_ENDPOINTS.DEV;
  if (getEnvironment() === 'STAGE') return API_ENDPOINTS.STAG;

  return API_ENDPOINTS.PRODCUCTION;
}

export function getAuthConfig() {
  if (getEnvironment() === 'DEV')
    return {
      instance: APP_LABELS.DEV_INSTANCE_URL,
      tenant: APP_LABELS.DEV_TENANT,
      signInPolicy: APP_LABELS.SIGNUP_POLICY,
      clientId: APP_LABELS.DEV_CLIENT_ID,
      cacheLocation: APP_LABELS.CACHE_STORAGE,
      redirectUri: APP_LABELS.DEV_REDIRECT_URI,
      postLogoutRedirectUri: APP_LABELS.DEV_LOGOUT_REDIRECT_URI
      // redirectUri: 'http://localhost:5055/signin-oidc',
      // postLogoutRedirectUri: 'http://localhost:5055/login'
    };

  if (getEnvironment() === 'STAGE')
    return {
      instance: APP_LABELS.STAGE_INSTANCE_URL,
      tenant: APP_LABELS.STAGE_TENANT,
      signInPolicy: APP_LABELS.SIGNUP_POLICY,
      clientId: APP_LABELS.STAGE_CLIENT_ID,
      cacheLocation: APP_LABELS.CACHE_STORAGE,
      redirectUri: APP_LABELS.STAGE_REDIRECT_URI,
      postLogoutRedirectUri: APP_LABELS.STAGE_LOGOUT_REDIRECT_URI
    };

  return {
    instance: APP_LABELS.PROD_INSTANCE_URL,
    tenant: APP_LABELS.PROD_TENANT,
    signInPolicy: APP_LABELS.SIGNUP_POLICY,
    clientId: APP_LABELS.PROD_CLIENT_ID,
    cacheLocation: APP_LABELS.CACHE_STORAGE,
    redirectUri: APP_LABELS.PROD_REDIRECT_URI,
    postLogoutRedirectUri: APP_LABELS.PROD_LOGOUT_REDIRECT_URI
  };
}

// Example ENDPOINT
export const ASSOCIATION_ADMIN_LOGIN = () =>
  `${getServicePath()}/Identity-Management/api/authenticate/login`;
export const GET_ASSOCIATION_NUMBER = AssociationNumber =>
  `${getServicePath()}/Employee-Management/api/Association/associatedBranches/${AssociationNumber}`;
export const GET_ASSOCIATION_SUMMARY = (startDate, endDate, Offset) =>
  `${getServicePath()}/Employee-Management/api/Association/summary/${startDate}/${endDate}/${Offset}`;
export const GET_ASSOCIATION_CHANGE_LOG = (
  associationNumber,
  startDate,
  endDate
) =>
  `${getServicePath()}/Employee-Management/api/HistoryData/changeLogs/${associationNumber}/${startDate}/${endDate}`;
export const GET_ASSOCIATION_LIST = (
  startDate,
  endDate,
  includeAllYs,
  Offset
) =>
  `${getServicePath()}/Employee-Management/api/Association/associations/${startDate}/${endDate}/${includeAllYs}/${Offset}`;
export const FETCH_FILE_TEMPLATE_URL = () =>
  `${getServicePath()}/Employee-Management/api/FetchData/Employee`;
export const FETCH_FILE_TEMPLATE = URL =>
  `${getServicePath()}/Employee-Management/api/EmployeeDataImport/DownloadFile?filePath=${URL}`;
export const UPLOAD_FILE = () =>
  `${getServicePath()}/Employee-Management/api/FileUpload/Employee`;
//'http://localhost:7044/api/FileUpload/Employee';
export const IMPORT_DATA = () =>
  `${getServicePath()}/Employee-Management/api/EmployeeDataImport/ImportData`;
export const UPLOAD_FILE_DROPDOWN = () =>
  `${getServicePath()}/Employee-Management/api/Association/associatedBranches`;
export const GET_BRANCH_SUMMARY_LOGS = (ImportId, BranchId) =>
  `${getServicePath()}/Employee-Management/api/HistoryData/employee/${ImportId}/${BranchId}`;
export const ASSOCIATION_ADMIN_DETAILS = () =>
  `${getServicePath()}/Identity-Management/api/Authenticate/token`;
export const GET_ASSOCIATION_DASHBOARD_SUMMARY = (
  AssociationId,
  startDate,
  endDate,
  Offset
) =>
  `${getServicePath()}/Employee-Management/api/Association/summary/${AssociationId}/${startDate}/${endDate}/${Offset}`;
export const GET_ASSOCIATION_DASHBOARD_CHANGE_LOG = (
  AssociationId,
  startDate,
  endDate
) =>
  `${getServicePath()}/Employee-Management/api/HistoryData/changeLogs/${AssociationId}/${startDate}/${endDate}`;
export const GET_FILE_DOWNLOAD_PATH = filePath =>
  `${getServicePath()}/Employee-Management/api/EmployeeDataImport/DownloadFile?filePath=${filePath}`;
export const GET_BLANK_FILE_DOWNLOAD_PATH = associationNumber =>
  `${getServicePath()}/Employee-Management/api/EmployeeDataImport/DownloadBlankTemplate/${associationNumber}`;
export const GET_TERMS_AND_CONDITIONS = associationNumber =>
  `${getServicePath()}/Employee-Management/api/Configuration/empconfig/${associationNumber}`;
export const POST_TERMS_AND_CONDITIONS = () =>
  `${getServicePath()}/Employee-Management/api/Configuration/updateConfig`;
export const GET_EXPORT_EMPLOYEE_YMCA_ASSOCIATIONS = (
  startDate,
  endDate,
  includeAllYs,
  Offset
) =>
  `${getServicePath()}/Employee-Management/api/Association/ExportUploadData/${startDate}/${endDate}/${includeAllYs}/${Offset}`;
export const GET_ASSOCIATION_SUMMARY_DATE_RANGE = (
  associationNumber,
  startDate,
  endDate,
  Offset
) =>
  `${getServicePath()}/Employee-Management/api/Association/summary/${associationNumber}/${startDate}/${endDate}/${Offset}`;
export const GET_ASSOCIATION_MOYP_CHANGE_LOG = (
  associationNumber,
  startDate,
  endDate,
  Offset
) =>
  `${getServicePath()}/Employee-Management/api/HistoryData/moypChangeLogs/${associationNumber}/${startDate}/${endDate}/${Offset}`;
export const GET_MOYP_DETAIL_VIEW = (associationNumber, uploadeddate, Offset) =>
  `${getServicePath()}/Employee-Management/api/HistoryData/moypChangeLogDetails/${associationNumber}/${uploadeddate}/${Offset}`;
export const GET_ANNUAL_REPORT_SITE_INFORMATION = (
  AssociationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/SiteInformation/${AssociationId}/${selectedYear}`;

export const ANNUAL_REPORT_OVERVIEW_GETAPI = (
  AssociationNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/AnnualReportOverview/${AssociationNumber}/${selectedYear}`;

export const YMCA_BRANCES_LIST_GETAPI = (
  AssociationNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/AssociationInformation/${AssociationNumber}/${selectedYear}`;

export const COLLAB_NON_PROFIT_ORG_GETAPI = (
  AssociationNumber,
  branchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (!branchNumber && !AssociationNumber) {
    return `${getServicePath()}/Annual-Report/api/NonProfitOrganizations`;
  }
  if (branchNumber) {
    return `${getServicePath()}/Annual-Report/api/NonProfitOrganizations/${AssociationNumber}/${branchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/NonProfitOrganizations/${AssociationNumber}/${selectedYear}`;
};

export const COLLAB_EDUCATION_ORG_GETAPI = (
  AssociationNumber,
  branchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (!branchNumber && !AssociationNumber) {
    return `${getServicePath()}/Annual-Report/api/EducationalOrganization`;
  }
  if (branchNumber) {
    return `${getServicePath()}/Annual-Report/api/EducationalOrganization/${AssociationNumber}/${branchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/EducationalOrganization/${AssociationNumber}/${selectedYear}`;
};

export const COLLAB_GOVT_ORG_AFFILIATES_GETAPI = (
  AssociationNumber,
  branchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (!branchNumber && !AssociationNumber) {
    return `${getServicePath()}/Annual-Report/api/GovtOrgAndAffiliates`;
  }
  if (branchNumber) {
    return `${getServicePath()}/Annual-Report/api/GovtOrgAndAffiliates/${AssociationNumber}/${branchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/GovtOrgAndAffiliates/${AssociationNumber}/${selectedYear}`;
};

export const COLLAB_OTHER_COMMUNITY_ORG_GETAPI = (
  AssociationNumber,
  branchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (!branchNumber && !AssociationNumber) {
    return `${getServicePath()}/Annual-Report/api/OtherCommunityOrganizations`;
  }
  if (branchNumber) {
    return `${getServicePath()}/Annual-Report/api/OtherCommunityOrganizations/${AssociationNumber}/${branchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/OtherCommunityOrganizations/${AssociationNumber}/${selectedYear}`;
};
export const COLLAB_PRIVATE_ORG_BUSINESS_GETAPI = (
  AssociationNumber,
  branchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (!branchNumber && !AssociationNumber) {
    return `${getServicePath()}/Annual-Report/api/PrivateOrganizationsAndBusinesses`;
  }
  if (branchNumber) {
    return `${getServicePath()}/Annual-Report/api/PrivateOrganizationsAndBusinesses/${AssociationNumber}/${branchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/PrivateOrganizationsAndBusinesses/${AssociationNumber}/${selectedYear}`;
};

export const COLLAB_ORATIONS_AMOUNG_YS_GETAPI = (
  AssociationNumber,
  branchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (!branchNumber && !AssociationNumber) {
    return `${getServicePath()}/Annual-Report/api/CollaborationsAmongYs`;
  }
  if (branchNumber) {
    return `${getServicePath()}/Annual-Report/api/CollaborationsAmongYs/${AssociationNumber}/${branchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/CollaborationsAmongYs/${AssociationNumber}/${selectedYear}`;
};

export const ANNUAL_REPORT_DASHBOARD = AssociationId =>
  `${getServicePath()}/Annual-Report/api/dashboard/${AssociationId}`;

export const SUBMIT_FEEDBACK_AR = () =>
  `${getServicePath()}/Annual-Report/api/FormExperienceFeedback`;

export const ANNUAL_REPORT_DASHBOARD_OVERVIEW = (AssociationId, year) =>
  `${getServicePath()}/Annual-Report/api/dashboard/${AssociationId}/${year}`;

export const AR_REOPEN = (AssociationId, year) =>
  `${getServicePath()}/Annual-Report/api/ReviewAndSubmit/revoke/${AssociationId}/${year}`;

export const ANNUAL_REPORT_SITE_INFORMATION = () =>
  `${getServicePath()}/Annual-Report/api/SiteInformation`;

//*************************/ Diversity Inclusion & Global Metrics API Calls /***************************/
export const ORGANIZATIONAL_COMMITMENT_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/OrganizationalCommitment/${associationsNum}/${selectedYear}`;
export const ORGANIZATIONAL_COMMITMENT_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/OrganizationalCommitment`;

export const MEMBERSHIP_NOW_YOUR_COMMUNITY_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/KnowYourCommunity/${associationsNum}/${selectedYear}`;
export const MEMBERSHIP_NOW_YOUR_COMMUNITY_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/KnowYourCommunity/`;

export const LEADERSHIP_STAFF_DEVELOPMENT_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/StaffDevelopment/${associationsNum}/${selectedYear}`;
export const LEADERSHIP_STAFF_DEVELOPMENT_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/StaffDevelopment/`;

export const PROGRAM_INNOVATION_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ProgramInnovation/${associationsNum}/${selectedYear}`;

export const PROGRAM_INNOVATION_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ProgramInnovation/`;

export const STRATEGIC_RELATIONSHIPS_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/StrategicRelationship/${associationsNum}/${selectedYear}`;

export const STRATEGIC_RELATIONSHIPS_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/StrategicRelationship/`;

export const RESOURCE_MOBILIZATION_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ResourceMobilization/${associationsNum}/${selectedYear}`;
export const RESOURCE_MOBILIZATION_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ResourceMobilization/`;

export const BRAND_VISIBILITY_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/BrandVisibility/${associationsNum}/${selectedYear}`;
export const BRAND_VISIBILITY_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/BrandVisibility/`;
// ******************************************************************************************************

//*************************/ Constituency Report /***************************/
export const MEMBERSHIPS_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteMembership/${associationsNum}/${selectedYear}`;

export const MEMBERSHIPS_NWM_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteMembership/nwm/${associationsNum}/${selectedYear}`;

export const MEMBERSHIPS_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ConstituteMembership/`;

export const MEMBER_DEMOGRAPHICS_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteMemberDemographics/${associationsNum}/${selectedYear}`;

export const MEMBER_DEMOGRAPHICS_NWM_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteMemberDemographics/nwm/${associationsNum}/${selectedYear}`;

export const MEMBER_DEMOGRAPHICS_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ConstituteMemberDemographics/`;

export const PROGRAM_MEMBER_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteProgramMember/${associationsNum}/${selectedYear}`;

export const PROGRAM_MEMBER_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ConstituteProgramMember/`;

export const PROGRAM_ENROLLMENTS_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteProgramEnrollments/${associationsNum}/${selectedYear}`;

export const PROGRAM_ENROLLMENTS_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ConstituteProgramEnrollments/`;

export const VOLUNTEERS_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteVolunteers/${associationsNum}/${selectedYear}`;

export const VOLUNTEERS_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ConstituteVolunteers/`;

export const DONORS_GET_URL = (
  associationsNum,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ConstituteDonors/${associationsNum}/${selectedYear}`;

export const DONORS_POST_URL = () =>
  `${getServicePath()}/Annual-Report/api/ConstituteDonors/`;
// ******************************************************************************************************

export const GET_BRANCH_DETAILS = (
  associationId,
  branchId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/AssociationInformation/${associationId}/${branchId}/${selectedYear}`;

export const SET_BRANCH_DETAILS = () =>
  `${getServicePath()}/Annual-Report/api/AssociationInformation`;

export const GET_AR_BRANCH_LIST = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/AssociationInformation/associationBranches/${associationId}/${selectedYear}`;

export const ANNUAL_REPORT_GET_PROGRAM_ACTIVITIES = (
  AssociationNumber,
  BranchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (BranchNumber) {
    return `${getServicePath()}/Annual-Report/api/ProgramActivities/${AssociationNumber}/${BranchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/ProgramActivities/${AssociationNumber}/${selectedYear}`;
};

export const ANNUAL_REPORT_PROGRAM_ACTIVITIES = () =>
  `${getServicePath()}/Annual-Report/api/ProgramActivities`;

export const ANNUAL_REPORT_GET_HOUSING = (
  AssociationNumber,
  BranchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (BranchNumber) {
    return `${getServicePath()}/Annual-Report/api/ProgramActivityHousing/${AssociationNumber}/${BranchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/ProgramActivityHousing/${AssociationNumber}/${selectedYear}`;
};

export const ANNUAL_REPORT_HOUSING = () =>
  `${getServicePath()}/Annual-Report/api/ProgramActivityHousing`;

export const ANNUAL_REPORT_GET_FOOD_PROGRAM = (
  AssociationNumber,
  BranchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (BranchNumber) {
    return `${getServicePath()}/Annual-Report/api/ProgramActivityFoodProgram/${AssociationNumber}/${BranchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/ProgramActivityFoodProgram/${AssociationNumber}/${selectedYear}`;
};

export const ANNUAL_REPORT_FOOD_PROGRAM = () =>
  `${getServicePath()}/Annual-Report/api/ProgramActivityFoodProgram`;

export const ANNUAL_REPORT_GET_ARMED_SERVICES = (
  AssociationNumber,
  BranchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (BranchNumber) {
    return `${getServicePath()}/Annual-Report/api/ProgramActivityArmedService/${AssociationNumber}/${BranchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/ProgramActivityArmedService/${AssociationNumber}/${selectedYear}`;
};
export const ANNUAL_REPORT_ARMED_SERVICES = () =>
  `${getServicePath()}/Annual-Report/api/ProgramActivityArmedService`;

export const ANNUAL_REPORT_GET_OLDER_ADULTS = (
  AssociationNumber,
  BranchNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (BranchNumber) {
    return `${getServicePath()}/Annual-Report/api/ProgramActivityOlderAdult/${AssociationNumber}/${BranchNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/ProgramActivityOlderAdult/${AssociationNumber}/${selectedYear}`;
};
export const ANNUAL_REPORT_OLDER_ADULTS = () =>
  `${getServicePath()}/Annual-Report/api/ProgramActivityOlderAdult`;

export const GET_AR_PLEDGE_LIST = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (associationId) {
    return `${getServicePath()}/Annual-Report/api/Pledge/${associationId}/${selectedYear}`;
    // return `http://localhost:7193/api/Pledge/${associationId}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/Pledge`;
  // return `http://localhost:7193/api/Pledge`;
};

export const GET_CEO_CVO_DETAILS = (
  associationNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/Pledge/CeoCvoDetails/${associationNumber}/${selectedYear}`;
// `http://localhost:7193/api/Pledge/CeoCvoDetails/${associationNumber}/${selectedYear}`;

export const POST_SEND_EMAIL_CEO_CVO_PLEDGE = () =>
  `${getServicePath()}/Annual-Report/api/Pledge/SendRequest`;
// `http://localhost:7193/api/Pledge/SendRequest`;

export const VALID_EMAIL_TOKEN = () =>
  `${getServicePath()}/Annual-Report/api/Pledge/token/`;
// `http://localhost:7193/api/Pledge/token/`;

export const SAVE_PLEDGE_PAGE_DATA = () =>
  `${getServicePath()}/Annual-Report/api/Pledge/SavePledge`;
// `http://localhost:7193/api/Pledge/SavePledge`;

export const GET_AR_CVO_CONTACT_LIST = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (associationId) {
    return `${getServicePath()}/Annual-Report/api/CvoContactDetail/${associationId}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/CvoContactDetail`;
};
export const GET_PLEDGE_CVO_CONTACT_LIST = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (associationId) {
    return `${getServicePath()}/Annual-Report/api/CvoContactDetail/moyp/${associationId}/${selectedYear}`;
  }
};
export const GET_SUBMIT_INFO = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) =>
  `${getServicePath()}/Annual-Report/api/ReviewAndSubmit/${associationId}/${selectedYear}`;
// `http://localhost:7193/api/ReviewAndSubmit/${associationId}/${selectedYear}`;

export const FINAL_SUBMIT = () =>
  `${getServicePath()}/Annual-Report/api/ReviewAndSubmit`;

export const POST_REVIEW_SUBMIT_DATA = () =>
  `${getServicePath()}/Annual-Report/api/ReviewAndSubmit/ReviewSubmit`;
// `http://localhost:7193/api/ReviewAndSubmit/ReviewSubmit`;

export const GET_ANNUALREPORT_PDF_DOWNLOAD_PATH = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear'),
  Offset
) =>
  `${getServicePath()}/Annual-Report/api/AnnualReportPdf/${associationId}/${selectedYear}/${Offset}`;

export const GET_COUNTRIES_LIST = () =>
  `${getServicePath()}/Annual-Report/api/Countries`;

// annual report employee update
export const AR_EMPLOYEE_UPDATE_GETAPI = (
  AssociationNumber,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (AssociationNumber) {
    return `${getServicePath()}/Annual-Report/api/EmployeeUpdate/${AssociationNumber}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/EmployeeUpdate`;
};
export const GET_AR_INCOMING_CVO_CONTACT_LIST = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (associationId) {
    return `${getServicePath()}/Annual-Report/api/IncomingCvoContactDetail/${associationId}/${selectedYear}`;
  }
  return `${getServicePath()}/Annual-Report/api/IncomingCvoContactDetail`;
};
export const GET_PLEDGE_INCOMING_CVO_CONTACT_LIST = (
  associationId,
  selectedYear = sessionStorage.getItem('selectedBatchYear')
) => {
  if (associationId) {
    return `${getServicePath()}/Annual-Report/api/IncomingCvoContactDetail/moyp/${associationId}/${selectedYear}`;
  }
};

// YUSA user Association List API
export const GET_YUSA_ASSOCIATION_LIST = year =>
  `${getServicePath()}/annual-report/api/YUSAAssociation/${year}`;

export const GET_YUSA_YEAR_LIST = year =>
  `${getServicePath()}/annual-report/api/dashboard/Years`;

export const GET_EXPORT_YMCA_ASSOCIATIONS = (year, offSet) =>
  `${getServicePath()}/Annual-Report/api/YUSAAssociation/ExportAssociations/${year}/${offSet}`;
// Get ModuleConfig API
export const GET_MODULES_CONFIG = () =>
  `${getServicePath()}/Annual-Report/api/dashboard/getModulesConfig`;
// GET CRM Dropdown list for YMCA DIR INFO API
export const GET_CRM_PROVIDER_LIST = () =>
  `${getServicePath()}/Annual-Report/api/AssociationInformation/crmProvider`;
//STRAPI URL
export const STRAPI_BASE_URL = () => `${getServicePath()}/Strapi-cms/api`;

// Volunteer-Registration

export const POST_VOLUNTEER_DETAILS = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerRegistration/Volunteer`;

export const GET_VOLUNTEER_DETAILS = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerRegistration/VolunteerDetails`;

export const GET_VOLUNTEER_DASHBOARD_DETAILS = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerDashboard/VolunteerCount`;

export const GET_VOLUNTEER_DASHBOARD_TABLE_DETAILS = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerDashboard/Volunteers`;

export const GET_DOWNLOAD_FILE_PREVIEW = fileURI =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerRegistration/VolunteerFile?filePath=${fileURI}`;

export const POST_VOLUNTEER_APPROVAL = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerDashboard/VolunteerApproval`;
export const POST_VOLUNTEER_DOCUMENT_APPROVAL = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerDashboard/DocumentApproval`;

export const GET_VOLUNTEER_DETAILS_BY_PERSON_ID = volunteerId =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerRegistration/VolunteerDetailsById?volunteerId=${volunteerId}`;

export const GET_VOLUNTEER_REGISTRATION_TERMS_CONDITION = () =>
  `https://data-collection-dev-cms.y.org/api/volunteer-registrations`;

export const GET_VOLUNTEER_COUNTRIES_LIST = () =>
  `${getServicePath()}/Volunteer-Registration/api/Countries`;

export const POST_VOLUNTEER_INITIATE_BACKGROUND_SCREENING = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerDashboard/InitiateBackgroundScreening`;

export const POST_VOLUNTEER_CANCEL_INITIATED_BACKGROUND_SCREENING = () =>
  `${getServicePath()}/Volunteer-Registration/api/VolunteerDashboard/ResetVolunteerDocument`;

/******************************************Chronic Disease Program *************************************/

const cdpApiUrl = `${getServicePath()}/Chronic-Disease-Program/api`;
//const cdpApiUrl = `http://localhost:7097/api`;

const cdpDataFetchUrls = `${cdpApiUrl}/cdpDataFetch`;
const chronicDiseaseUrls = `${cdpApiUrl}/chronicdisease`;
const cdpLookupUrls = `${cdpApiUrl}/lookup`;
const cdpRedcapUrls = `${cdpApiUrl}/redcap`;
const cdpYProfileUrls = `${cdpApiUrl}/yProfile`;
const cdpFileUrls = `${cdpApiUrl}/file`;

export const DASHBOARD_EXPORT = (majorGroupId, programId) =>
  `${cdpDataFetchUrls}/exportassociations/program/${majorGroupId}/${programId}`;

export const CDP_DASHBOARD_URLS = {
  DASHBOARD: (majorGroupId, programId) =>
    `${cdpDataFetchUrls}/dashboardcounts/program/${majorGroupId}/${programId}`,

  ADMIN_DASHBOARD_KPI: () => `${cdpDataFetchUrls}/dashboardcounts`,
  DOWNLOAD_FILE: fileName => `${cdpDataFetchUrls}/file?filePath=${fileName}`,
  ASSOCIATION: (associationNumber, programid, showHidden) =>
    `${cdpDataFetchUrls}/dashboard/${associationNumber}/${showHidden}/${programid}`,
  YPROFILE_ASSOCIATION: () => `${cdpYProfileUrls}`,
  ASSOCIATION_BY_NUMBER: associationNumber =>
    `${cdpYProfileUrls}/${associationNumber}`,
  HIPAA_CERTIFICATE: () => `${cdpYProfileUrls}/hippaCertificate`,
  UPDATE_VIEW_STATUS: (cdpDataFetchId, status) =>
    `${cdpDataFetchUrls}/UpdateViewStatus/${cdpDataFetchId}/${status}`
};

export const CDP_UPLOAD_URLS = {
  REDCAP_FETCH: () => `${cdpRedcapUrls}`,
  DS_UPLOAD: () => `${chronicDiseaseUrls}`,
  PROGRAM: associationNumber =>
    `${cdpLookupUrls}/programs/${associationNumber}`,
  DOLTYPES: () => `${cdpLookupUrls}/dolTypes`,
  DOLPROGRAM: () => `${cdpLookupUrls}/dolPrograms`,
  DATASYSTEM: Program => `${cdpLookupUrls}/sourceSystems/${Program}`,
  DOWNLOAD_FILE: file => `${cdpFileUrls}/downloadTemplates/${file}`,
  IS_IMPORTED: (dataSystemId, programId, associationNumber) =>
    `${cdpLookupUrls}/association/isimported/${dataSystemId}/${programId}/${associationNumber}`
};

export const CDP_DETAIL_URLS = {
  REDCAP_IMPORT: () => `${cdpRedcapUrls}/import`,
  CDP_DS_IMPORT: () => `${chronicDiseaseUrls}/import`,
  REDCAP_DETAIL: (fetchId, programEnum) =>
    `${cdpRedcapUrls}/${fetchId}/${programEnum}`,
  CDP_DATA_FETCH: fetchId => `${cdpDataFetchUrls}/${fetchId}`,
  REDCAP_ERROR: (fetchId, programEnum) =>
    `${cdpRedcapUrls}/failedRecords/${fetchId}/${programEnum}`,
  CDP_DS_DETAIL: (fetchId, dataSystem, programEnum, type) => {
    if (type && type > 0) {
      return `${chronicDiseaseUrls}/${fetchId}/${dataSystem}/${programEnum}/${type}`;
    }
    return `${chronicDiseaseUrls}/${fetchId}/${dataSystem}/${programEnum}`;
  },
  CDP_DS_ERROR: (fetchId, dataSystemType, type, programEnum) => {
    if (type && type > 0) {
      return `${chronicDiseaseUrls}/errors/${fetchId}/${dataSystemType}/${programEnum}/${type}`;
    }
    return `${chronicDiseaseUrls}/errors/${fetchId}/${dataSystemType}/${programEnum}`;
  }
};

/******************************************     WellD  ***********************************************/
export const GET_CDP_WELLD_FETCH = (
  dataSyatemId,
  ProgramId,
  associationNumber
) => `${cdpApiUrl}/welld/${dataSyatemId}/${ProgramId}/${associationNumber}`;

export const POST_CDP_WELLD_IMPORT = () => `${cdpApiUrl}/Welld/Import`;

export const GET_CDP_WELLD_VIEW_DETAILS = cdpDatafetchId =>
  `${cdpApiUrl}/Welld/GetWelldData?cdpDataFetchId=${cdpDatafetchId}`;
/******************************************User Management *********************************************/

export const POST_USER_DETAILS = () =>
  `${getServicePath()}/Identity-Management/api/Users/User`;

export const GET_USER_DETAILS = () =>
  `${getServicePath()}/Identity-Management/api/Users/Users`;

export const GET_USER_DETAILS_BY_USER_ID = userId =>
  `${getServicePath()}/Identity-Management/api/Users/User?UserId=${userId}`;

export const GET_MODULES = () =>
  `${getServicePath()}/Identity-Management/api/Module/Modules`;

export const GET_USER_BLANK_TEMPLATE_DOWNLOAD_PATH = () =>
  `${getServicePath()}/Identity-Management/api/Users/DownloadBlankTemplate`;

export const GET_USER_EXPORT_LIST_DOWNLOAD_PATH = () =>
  `${getServicePath()}/Identity-Management/api/Users/ExportUsersData`;
export const USER_MANAGEMENT_IMPORT_DATA = () =>
  `${getServicePath()}/Identity-Management/api/Users/ImportUsersData`;
export const USER_MANAGEMENT_UPLOAD_FILE = () =>
  `${getServicePath()}/Identity-Management/api/FileUpload/User`;

// swim safety apis list

export const SWIMSAFETY_ASSOCIATION_DASHBOARD_COUNT_API = (
  startDate,
  endDate,
  Offset,
  ShowAll,
  associationNumber
) =>
  `${getServicePath()}/Swim-Safety/api/Dashboard/DashboardCounts/${startDate}/${endDate}/${Offset}/${ShowAll}/${associationNumber}`;

export const GET_SWIMSAFETY_PROGRAMS_LIST_API = AssociationId =>
  `${getServicePath()}/Swim-Safety/api/SwimProgram/programList/${AssociationId}`;

export const GET_SWIMSAFETY_PROGRAMS_DETAILS_API = programId =>
  `${getServicePath()}/Swim-Safety/api/SwimProgram/${programId}`;

export const GET_SWIMSAFETY_ADD_OR_UPDATE_API = () =>
  `${getServicePath()}/Swim-Safety/api/SwimProgram/SwimProgram`;

export const GET_SWIMSAFETY_DELETE_API = programId =>
  `${getServicePath()}/Swim-Safety/api/SwimProgram/program/${programId}`;

export const GET_SWIMSAFETY_PROGRAMS_BY_BRANCH_API = BranchId =>
  `${getServicePath()}/Swim-Safety/api/SwimProgram/programs/${BranchId}`;

export const GET_SWIMSAFETY_PROFILES_BY_PROGRAMID_API = ProgramId =>
  `${getServicePath()}/Swim-Safety/api/SwimProfile/GetProfilesByProgramId/${ProgramId}`;

export const GET_SWIMSAFETY_ASSOCIATION_DELETE_API = associationId =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/association/${associationId}`;

export const GET_SWIMSAFETY_ASSOCIATION_API = (
  startDate,
  endDate,
  Offset,
  includeAllYs
) =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/getDashboardAssociations/${startDate}/${endDate}/${Offset}/${includeAllYs}`;

export const GET_SWIMSAFETY_ASSOCIATION_BY_FILTERS_API = (
  startDate,
  endDate,
  includeAllYs,
  Offset
) =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/associations/${startDate}/${endDate}/${includeAllYs}/${Offset}`;

export const GET_SWIMSAFETY_BRANCHES_BY_FILTERS_API = (
  startDate,
  endDate,
  Offset,
  ShowAll,
  associationNumber
) => {
  if (associationNumber)
    return `${getServicePath()}/Swim-Safety/api/SwimAssociation/branchesByNumber/${startDate}/${endDate}/${Offset}/${ShowAll}/${associationNumber}`;

  return `${getServicePath()}/Swim-Safety/api/SwimAssociation/branchesByNumber/${startDate}/${endDate}/${Offset}/${ShowAll}`;
};

export const GET_SWIMSAFETY_PROFILES_BY_FILTERS_API = (
  startDate,
  endDate,
  Offset,
  ShowAll,
  associationNumber
) => {
  if (associationNumber)
    return `${getServicePath()}/Swim-Safety/api/SwimProfile/profile/${startDate}/${endDate}/${Offset}/${ShowAll}/${associationNumber}`;

  return `${getServicePath()}/Swim-Safety/api/SwimProfile/profile/${startDate}/${endDate}/${Offset}/${ShowAll}`;
};

export const GET_SWIMSAFETY_PROGRAMS_BY_FILTERS_API = (
  startDate,
  endDate,
  Offset,
  ShowAll,
  associationNumber
) => {
  if (associationNumber)
    return `${getServicePath()}/Swim-Safety/api/SwimProgram/programList/${startDate}/${endDate}/${Offset}/${ShowAll}/${associationNumber}`;

  return `${getServicePath()}/Swim-Safety/api/SwimProgram/programList/${startDate}/${endDate}/${Offset}/${ShowAll}`;
};

export const GET_SWIMSAFETY_DASHBOARD_COUNT_API = (
  startDate,
  endDate,
  Offset,
  includeAllYs
) =>
  `${getServicePath()}/Swim-Safety/api/Dashboard/DashboardCounts/${startDate}/${endDate}/${Offset}/${includeAllYs}`;

export const GET_SWIMSAFETY_ADD_UPDATE_ASSOCIATION_API = programId =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/addorupdateSwimAssociation`;

export const GET_SWIMSAFETY_EXPORT_ASSOCIATION_API = (
  startDate,
  endDate,
  offSet,
  includeAll
) =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/exportassociations/${startDate}/${endDate}/${offSet}/${includeAll}`;

export const GET_SWIMSAFETY_BRANCH_LIST_API = associationNumber => {
  if (associationNumber)
    return `${getServicePath()}/Swim-Safety/api/SwimAssociation/branches/${associationNumber}`;

  return `${getServicePath()}/Swim-Safety/api/SwimAssociation/branches`;
};

export const GET_SWIMSAFETY_BRANCH_DETAILS_API = branchId =>
  `${getServicePath()}/Swim-Safety/api/SwimSafetyBranch/GetBranchById/${branchId}`;

export const GET_SWIMSAFETY_ADD_OR_UPDATE_BARNCH_API = associationNumber =>
  `${getServicePath()}/Swim-Safety/api/SwimSafetyBranch/SaveSwimSafetyBranch`;

export const GET_SWIMSAFETY_UPLOAD_PROFILES = programId =>
  `${getServicePath()}/Swim-Safety/api/UploadProfiles/uploadswimProfile?programId=${programId}`;

export const GET_SWIMSAFETY_BLANK_TEMPLATE = programId =>
  `${getServicePath()}/Swim-Safety/api/UploadProfiles/DownloadBlankTemplate`;

export const GET_SWIMSAFETY_IMPORT_PROFILES = () =>
  `${getServicePath()}/Swim-Safety/api/UploadProfiles/ImportProfileData`;

export const GET_SWIM_ASSOCIATION_LIST = () =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/association`;

export const SAVE_SWIM_ASSOCIATION = () =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/SwimAssociation`;

export const GET_SPECIAL_GRANT_LIST = () =>
  `${getServicePath()}/Swim-Safety/api/SwimSpecialGrant/grantList`;

export const GET_SWIMSAFETY_PROFILE_LIST_API = AssociationId =>
  `${getServicePath()}/Swim-Safety/api/SwimProfile/DashboardProfiles/${AssociationId}`;

export const GET_SWIMS_PROFILE_DETAILS_API = profileId =>
  `${getServicePath()}/Swim-Safety/api/SwimProfile/GetProfileById/${profileId}`;

export const SAVE_SWIM_PROFILE_ADD_OR_UPDATE_API = () =>
  `${getServicePath()}/Swim-Safety/api/SwimProfile/SaveSwimProfile`;

export const GET_SWIMSAFETY_PROFILE_DELETE_API = profileId =>
  `${getServicePath()}/Swim-Safety/api/SwimProfile/profile/${profileId}`;

export const GET_SWIMSAFETY_BRANCH_DELETE_API = branchId =>
  `${getServicePath()}/Swim-Safety/api/SwimBranch/Branch/${branchId}`;

export const GET_SWIM_ASSOCIATION_DROPDOWN_API = () =>
  `${getServicePath()}/Swim-Safety/api/SwimAssociation/swimassociationList`;

// Certificate of liability insurance

export const GET_COLI_DASHBOARD_COUNT_API = () =>
  `${getServicePath()}/Coli/api/LiabilityInsurance/DashboardCount`;

export const GET_COLI_DASHBOARD_ACCOCIATION_API = status => {
  if (!status) {
    return `${getServicePath()}/Coli/api/LiabilityInsurance/Associations`;
  }
  return `${getServicePath()}/Coli/api/LiabilityInsurance/Associations/${status}`;
};

export const GET_COLI_FILE_UPLOAD_API = () =>
  `${getServicePath()}/Coli/api/FileUpload`;

export const GET_COLI_ASSOCIATION_DETAILS_API = associationNumber => {
  if (!associationNumber) {
    return `${getServicePath()}/Coli/api/LiabilityInsurance/Association`;
  }
  return `${getServicePath()}/Coli/api/LiabilityInsurance/Association/${associationNumber}`;
};

export const GET_COLI_FILE_DOWNLOAD_API = fileName =>
  `${getServicePath()}/Coli/api/FileUpload?filePath=${fileName}`;

export const GET_COLI_EXPORT_ASSOCIATION_API = offset =>
  `${getServicePath()}/Coli/api/LiabilityInsurance/ExportAssociations/${offset}`;

export const COLI_APPROVE_REJECT_DOCUMENT = () =>
  `${getServicePath()}/Coli/api/LiabilityInsurance`;
