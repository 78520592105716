import React from 'react';
import PropTypes from 'prop-types';
import wentWrong from '../../static/images/went-wrong.svg';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.redirectToLogin = this.redirectToLogin.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  redirectToLogin() {
    this.setState({ hasError: false });
    window.location.href = '/home';
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="YMCA-wentWrongs">
          <div className="YMCA-mainWrongs">
            <img
              src={wentWrong}
              className="img fluid"
              alt="something went wrong"
            />
            <h4>Oops! Something went wrong</h4>
            <p>Sorry,something went wrong here. Encountered an error.</p>
            <button
              type="submit"
              onClick={this.redirectToLogin}
              className="btn ymca-btnWrongs"
            >
              Back to Home
            </button>
          </div>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  children: null
};

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
