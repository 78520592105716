import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { CDP, APP_LABELS } from '@config/app-config';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import {
  getCdpAdminDashboard,
  getCdpAdminDashboardKIP
} from '@redux/chronic-disease-program/admin-dashboard/actions';

import BPSDARK from '@static/images/Blood-Pressure-Self-Monitoring-Colored.svg';
import BPSLIGHT from '@static/images/Blood-Pressure-Self-Monitoring-white.svg';
import DPPDARK from '@static/images/YMCA-Diabetes-Prevention-Program-Colored.svg';
import DPPLIGHT from '@static/images/YMCA-Diabetes-Prevention-Program-white.svg';
import EFDARK from '@static/images/Enhance-Fitness-Colored.svg';
import EFLIGHT from '@static/images/Enhance-Fitness-white.svg';
import HWDARK from '@static/images/Healthy-Weight-your-Child-Colored.svg';
import HWLIGHT from '@static/images/Healthy-Weight-your-Child-White.svg';
import LSDARK from '@static/images/LS-dark-(2).svg';
import LSLIGHT from '@static/images/LS-light-(2).svg';
import YWPDARK from '@static/images/YWP-dark.svg';
import YWPLIGHT from '@static/images/YWP-light.svg';

import ADMIN_DASHBOARD_COLUMNS from './columns';
import Table from './table';

const CDPAdminDashboard = props => {
  const { cdpAdminDashboard, loginUserData, handleCdpAdminDashboardKIP } =
    props;
  const [dashboardData, setDashboardData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [kpiData, setKpiData] = useState();

  const [majorGroup, setMajorGroup] = useState();
  const [program, setProgram] = useState();

  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getDashboardDetails = async () => {
    setLoader(true);
    let majorGroupId;
    let programId;
    const kipResponse = await handleCdpAdminDashboardKIP();
    if (kipResponse.status === 200) {
      setKpiData(kipResponse.data);
      [{ majorGroupId }] = kipResponse.data;
      [{ programId }] = kipResponse.data;
    } else {
      setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
      setErrorPopup(true);
    }

    handleAdminData(majorGroupId, programId);
    setLoader(false);
  };

  const handleAdminData = async (majorGroupIdPara, programIdPara) => {
    setLoader(true);
    setMajorGroup(majorGroupIdPara);
    setProgram(programIdPara);
    const response = await cdpAdminDashboard(majorGroupIdPara, programIdPara);
    if (response.status === 200) {
      setDashboardData(response.data);
    } else {
      setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
      setErrorPopup(true);
    }
    setLoader(false);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    await getDashboardDetails();
    sessionStorage.removeItem('selectedKPI');
  }, []);

  const onLinkClick = row => {
    props.history.push({
      pathname: '/cdp/associate/dashboard',
      state: { associationNumber: row.associationNumber }
    });
    sessionStorage.setItem('yusa_selected_associate', JSON.stringify(row));
  };

  const getKpiClasses = programValue => {
    switch (programValue) {
      case CDP.PROGRAM_FULL.DPP:
        return 'ymca-diabetes-prevention-card';
      case CDP.PROGRAM_FULL.BPSM:
        return 'ymca-bp-monitoring-card';
      case CDP.PROGRAM_FULL.EF:
        return 'ymca-fitness-card';
      case CDP.PROGRAM_FULL.HWYC:
        return 'ymca-child-health-card';
      case CDP.PROGRAM_FULL.LS:
        return 'ymca-strong-life-card';
      case CDP.PROGRAM_FULL.YWP:
        return 'ymca-youth-workforce-card';
      default:
        return 'ymca-diabetes-prevention-card';
    }
  };

  const getKpiDarkIcon = programValue => {
    switch (programValue) {
      case CDP.PROGRAM_FULL.DPP:
        return DPPDARK;
      case CDP.PROGRAM_FULL.BPSM:
        return BPSDARK;
      case CDP.PROGRAM_FULL.EF:
        return EFDARK;
      case CDP.PROGRAM_FULL.HWYC:
        return HWDARK;
      case CDP.PROGRAM_FULL.LS:
        return LSDARK;
      case CDP.PROGRAM_FULL.YWP:
        return YWPDARK;
      default:
        return DPPDARK;
    }
  };

  const getKpiLightIcon = programValue => {
    switch (programValue) {
      case CDP.PROGRAM_FULL.DPP:
        return DPPLIGHT;
      case CDP.PROGRAM_FULL.BPSM:
        return BPSLIGHT;
      case CDP.PROGRAM_FULL.EF:
        return EFLIGHT;
      case CDP.PROGRAM_FULL.HWYC:
        return HWLIGHT;
      case CDP.PROGRAM_FULL.LS:
        return LSLIGHT;
      case CDP.PROGRAM_FULL.YWP:
        return YWPLIGHT;
      default:
        return DPPLIGHT;
    }
  };

  const displayLsOrEf = (kpi) => {
    switch (kpi.displayName) {
      case CDP.PROGRAM_FULL_NAME.LS:
        return (<span> LIVESTRONG<sup>®</sup> at the YMCA</span>);
      case CDP.PROGRAM_FULL_NAME.EF:
        return (<span> Enhance<sup>®</sup>Fitness</span>);
      default:
        return (<>{kpi.displayName}</>);
    }
  }

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper volunteer-dashboard-wrapper admin-cards">
          <div className="container">
            <section className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <div className="user-name custom-username">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>Welcome!</h6>
                        <h2 className="ymca-sub-heading mb-0 notranslate">
                          {loginUserData?.user?.firstName}{' '}
                          {loginUserData?.user?.lastName}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="stats-data mb-4">
              <div className="row gx-3">
                {kpiData?.map(kpi => (
                  <div
                    className="col-sm cdp-assocation-card-width"
                    key={kpi.programId}
                    aria-hidden="true"
                  >
                    <button
                      onClick={() =>
                        handleAdminData(kpi.majorGroupId, kpi.programId)
                      }
                      type="button"
                      className={`card stats-card-sm ${getKpiClasses(
                        kpi.programName
                      )}`}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={getKpiDarkIcon(kpi.programName)}
                            alt="DPPDARK"
                            className="dark-icon"
                          />
                          <img
                            src={getKpiLightIcon(kpi.programName)}
                            alt="DPPLIGHT"
                            className="light-icon"
                          />
                        </div>

                        <div className="card-title">
                          <h4 style={{ textAlign: 'left' }}>
                            {kpi.associationCount}
                          </h4>
                          <p>
                            {displayLsOrEf(kpi)}
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <hr className="custom-hr mt-0" />

          <Table
            rows={dashboardData}
            headers={ADMIN_DASHBOARD_COLUMNS(onLinkClick)}
            showCount
            title={CDP.TITLES.ADMIN_DASHBOARD}
            majorGroup={majorGroup}
            program={program}
            setLoader={setLoader}
          />
        </div>
      </main>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

CDPAdminDashboard.propTypes = {
  cdpAdminDashboard: PropTypes.func.isRequired,
  handleCdpAdminDashboardKIP: PropTypes.func.isRequired,
  loginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapDispatchToProps = {
  cdpAdminDashboard: getCdpAdminDashboard,
  handleCdpAdminDashboardKIP: getCdpAdminDashboardKIP
};

const mapStateToProps = state => ({
  loginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CDPAdminDashboard));
