import { CDP_DASHBOARD_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getCdpAdminDashboard =
  (majorGroupId, programId) => (dispatch, getState) =>
    asyncRequest({
      url: CDP_DASHBOARD_URLS.DASHBOARD(majorGroupId, programId),
      method: 'GET',
      // data: payloadBody,
      successAction: data => ({
        type: types.GET_CDP_ADMIN_DASHBOARD_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_CDP_ADMIN_DASHBOARD_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCdpAdminDashboardKIP = () => (dispatch, getState) =>
  asyncRequest({
    url: CDP_DASHBOARD_URLS.ADMIN_DASHBOARD_KPI(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_ADMIN_DASHBOARD_KPI_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_ADMIN_DASHBOARD_KPI_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCdpAdminDashboardCards = () => (dispatch, getState) =>
  asyncRequest({
    url: CDP_DASHBOARD_URLS.DASHBOARD(),
    method: 'GET',
    // data: payloadBody,
    successAction: data => ({
      type: types.GET_CDP_ADMIN_DASHBOARD_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_ADMIN_DASHBOARD_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getAssociationByNumber =
  associationNumber => (dispatch, getState) =>
    asyncRequest({
      url: CDP_DASHBOARD_URLS.ASSOCIATION_BY_NUMBER(associationNumber),
      successAction: data => ({
        type: types.GET_ASSOCIATION_BY_NUMBER_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_ASSOCIATION_BY_NUMBER_FAILURE,
        payload: error
      }),
      dispatch
    });
