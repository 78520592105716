import React from 'react';
import PropTypes from 'prop-types';
import {
  finalSubmit,
  postReviewSubmitData
} from '@redux/review-submit/actions';
import { ReviewSubmitInfoSelector } from '@redux/review-submit/selectors';
import { connect } from 'react-redux';
import { APP_LABELS } from '@config/app-config';
import closeIcomoons from '@static/images/closeIcomoons.svg';
import submitReview from '@static/images/submitReview.svg';
import Inprogress from '@static/images/Inprogress.svg';

const Popup = props => {
  const {
    handleClosePopup,
    inCompleteList,
    ReviewSubmitData,
    submitHandler,
    setSubmitStatus,
    setShowPopup,
    setLoader,
    handlePostReviewSubmitData,
    fromValues,
    associationNum,
    modalOpen,
    ReviewSubmitInfoSelectorData
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');

  const saveNewFields = () => {
    setLoader(true);
    const payloadData = {
      createdByName: ReviewSubmitInfoSelectorData?.data?.createdByName,
      createdByPersonId: ReviewSubmitInfoSelectorData?.data?.createdByPersonId,
      createdDatetime: ReviewSubmitInfoSelectorData?.data?.createdDatetime,
      reviewSubmitId:
        ReviewSubmitInfoSelectorData?.reviewSubmitData?.reviewSubmitId,
      membership: fromValues?.membership === 'true',
      financial: fromValues?.financial === 'true',
      workforceDiversity: fromValues?.workforceDiversity === 'true',
      genralStaff: fromValues?.generalStaff === 'true',
      associationNumber: associationNum,
      year: selectedYear
    };

    handlePostReviewSubmitData(payloadData)
      .then(() => {
        setLoader(false);
        setShowPopup(true);
      })
      .catch(() => {
        setSubmitStatus(false);
        setLoader(false);
      });
  };

  const submit = () => {
    setLoader(true);

    submitHandler(ReviewSubmitData?.data)
      .then(res => {
        if (selectedYear !== '2022') {
          saveNewFields();
        }

        if (res.status === 200) {
          setSubmitStatus(true);
        } else {
          setSubmitStatus(false);
        }

        if (selectedYear === '2022') {
          setShowPopup(true);
          setLoader(false);
        }
      })
      .catch(() => {
        setSubmitStatus(false);
        setLoader(false);
      });
  };
  const showHideClassName = modalOpen ? 'modal d-block' : 'modal d-none';
  const modal = {
    background: 'rgb(0 0 0 / 0.5)'
  };

  return (
    <div>
      {inCompleteList.length === 0 ||
      (inCompleteList[0]?.status === 'Completed' &&
        inCompleteList[1]?.status === 'Completed' &&
        inCompleteList[2]?.status === 'Completed' &&
        inCompleteList[3]?.status === 'Completed' &&
        inCompleteList[4]?.status === 'Completed' &&
        inCompleteList[5]?.status === 'Completed' &&
        inCompleteList[6]?.status === 'Completed' &&
        inCompleteList[7]?.status === 'Completed') ? (
        <div
          className={`${showHideClassName}  ymca-submitMyReview`}
          style={modal}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ymca-dialogModel">
            <div className="modal-content ymca-content">
              <div className="modal-body text-center ymca-body">
                <div>
                  <button
                    type="button"
                    className="ymca-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleClosePopup();
                    }}
                  >
                    <img
                      src={closeIcomoons}
                      alt="close"
                      className="img-fluid"
                    />
                  </button>
                  <img src={submitReview} alt="" className="img-fluid" />
                  <h4>You’re almost done!</h4>
                  <p>{APP_LABELS.REVIEW_SUBMIT_ALERT_INFO}</p>
                  <button
                    className="btn ymca-submitBlue d-flex"
                    data-bs-dismiss="modal"
                    type="button"
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${showHideClassName}  ymca-moduleInComplete`}
          id="exampleModal"
          style={modal}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ymca-dialogModel">
            <div className="modal-content ymca-content">
              <div className="modal-body text-center ymca-body">
                <div>
                  <div className="ymca-headerArea d-flex">
                    <img
                      src={Inprogress}
                      alt="inprogress"
                      className="img-fluid"
                    />
                    <div className="ymca-texArea">
                      <h4>Some Modules are Not Completed!</h4>
                      <p>Please complete all modules and try.</p>
                    </div>
                    <button
                      type="button"
                      className="ymca-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        handleClosePopup();
                      }}
                    >
                      <img
                        src={closeIcomoons}
                        alt="close"
                        className="img-fluid"
                      />
                    </button>
                  </div>
                  <div className="ymca-tableResponsive">
                    <table className="table ymca-modelTable">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Module Name</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inCompleteList?.map(
                          (data, index) =>
                            data?.status?.toUpperCase() !==
                              APP_LABELS.STATUS_COMPLETED && (
                              <tr key={data?.name?.replace(/ /g, '_')}>
                                <td>{index + 1}</td>
                                <td>{data?.name}</td>
                                <td>
                                  <lable
                                    className={`${
                                      data?.status?.toUpperCase() ===
                                      APP_LABELS.STATUS_IN_PROGRESS
                                        ? 'ymca-progressIn'
                                        : 'ymca-danger'
                                    } `}
                                  >
                                    {data?.status?.toUpperCase() ===
                                    APP_LABELS.STATUS_IN_PROGRESS
                                      ? 'IN PROGRESS'
                                      : 'NOT STARTED'}
                                  </lable>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="ymca-varientTwo">
                    <button
                      className="btn ymca-savingBTN ymca-submitBuble ymca-ok-btn"
                      data-bs-dismiss="modal"
                      type="button"
                      onClick={() => {
                        handleClosePopup();
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Popup.propTypes = {
  inCompleteList: PropTypes.arrayOf(PropTypes.any).isRequired,
  ReviewSubmitData: PropTypes.objectOf(PropTypes.any).isRequired,
  submitHandler: PropTypes.func.isRequired,
  setSubmitStatus: PropTypes.func.isRequired,
  setShowPopup: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  handlePostReviewSubmitData: PropTypes.func.isRequired,
  fromValues: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClosePopup: PropTypes.func.isRequired,
  associationNum: PropTypes.number.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  ReviewSubmitInfoSelectorData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  ReviewSubmitData: ReviewSubmitInfoSelector(state)
});

const mapDispatchToProps = {
  submitHandler: finalSubmit,
  handlePostReviewSubmitData: postReviewSubmitData
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
