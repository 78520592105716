import * as types from './types';

const initialState = {
  SwimSafetyProgramsList: {},
  SwimProgramDetails: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SWIMSAFETY_DASHBOARD_COUNT_SUCCESS: {
      return {
        ...state,
        SwimSafetyProgramsList: action.payload
      };
    }
    case types.SWIMSAFETY_DASHBOARD_COUNT_FAILURE:
      return { ...state, error: action.payload };

    case types.SWIMSAFETY_PROGRAMS_DETAILS_SUCCESS: {
      return {
        ...state,
        SwimProgramDetails: action.payload
      };
    }
    case types.SWIMSAFETY_PROGRAMS_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
