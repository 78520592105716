import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CDP } from '@config/app-config';
import PropTypes from 'prop-types';
import Modal from '@modules/shared/components/reusable-components/model-popup';
import { setPopoverDetails } from '@redux/chronic-disease-program/details/actions';

const ErrorSummaryColumn = props => {
  const { errorKeys, errors, id, handelPopoverDetails } = props;
  const [showPopover, setShowPopover] = useState(false);
  const [activeCell, setActiveCell] = useState(undefined);
  const timerRef = useRef(null);


  const adjustPopoverPosition = (event) => {
    const cellRect = event.target.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const popoverWidth = 400;

    const initialLeft = cellRect.left + window.scrollX;
    const initialTop = (cellRect.bottom) + window.scrollY;
    let finalLeft = initialLeft;

    if (initialLeft + popoverWidth > windowWidth) {
      finalLeft = (windowWidth - popoverWidth);
    }
    handelPopoverDetails({ show: true, errors, position: { left: finalLeft - 300, top: initialTop } });
  }


  useEffect(() => {
    const handleResize = () => {
      if (showPopover && activeCell) {
        adjustPopoverPosition(activeCell);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [activeCell]);

  useEffect(() => {
    const handleScroll = () => {
      if (activeCell) {
        adjustPopoverPosition(activeCell);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeCell, showPopover]);


  const handleMouseEnter = (event) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      adjustPopoverPosition(event);
      setShowPopover(true);
      setActiveCell(event);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current);
    handelPopoverDetails({ show: false, undefined, position: { left: 0, top: 0 } });
    setShowPopover(false);
    setActiveCell(undefined);
  };

  return (
    <>
      <ul className={`mb-0 list-unstyled errorSummary-ui-li ${showPopover ? '.active-over-cell' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {errorKeys.map(er => (
          <li className='li-error-summary' key={errors[er]}>
            [{er}]: {errors[er]}
          </li>
        ))}

      </ul>

      {Object.keys(errors).length > 3 && (
        <div
          className="ymca-link"
          data-bs-toggle="modal"
          data-bs-target={`#errorModal${id}`}
        >
          more..!
        </div>
      )}

      {
        <Modal
          popupTitle="Error Summary"
          showClose="true"
          classNames="chronic-program"
          id={`errorModal${id}`}
        >
          <div className="row">
            <div className="table-responsive">
              <table className="table table-active table-bordered table-striped ymca-cdppopup-table">
                <tbody>
                  {errors &&
                    Object.keys(errors)
                      .filter(c => c !== CDP.ErrorRowIndexField)
                      .map(key => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>{errors[key]}</td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

const mapDispatchToProps = {
  handelPopoverDetails: setPopoverDetails
};

ErrorSummaryColumn.propTypes = {
  id: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.any).isRequired,
  errorKeys: PropTypes.arrayOf(PropTypes.any).isRequired,
  handelPopoverDetails: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(ErrorSummaryColumn);
