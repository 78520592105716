export const BranchListSelector = ({ directoryInformation: { BranchList } }) =>
  BranchList;

export const BranchDetailsSelector = ({
  directoryInformation: { BranchData }
}) => BranchData;

export const CountriesListSelector = ({
  directoryInformation: { CountiresList }
}) => CountiresList;

// export const CrmProviderListDataSelector=({
//   directoryInformation:{ CrmProviderList }
// })=> CrmProviderList
