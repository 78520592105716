
export const GET_MEMBER_DEMOGRAPHICS_SUCCESS =
  'GET_MEMBER_DEMOGRAPHICS_SUCCESS';
export const GET_MEMBER_DEMOGRAPHICS_FAILURE =
  'GET_MEMBER_DEMOGRAPHICS_FAILURE';

export const GET_MEMBER_DEMOGRAPHICS_NWM_SUCCESS =
  'GET_MEMBER_DEMOGRAPHICS_NWM_SUCCESS';
export const GET_MEMBER_DEMOGRAPHICS_NWM_FAILURE =
  'GET_MEMBER_DEMOGRAPHICS_NWM_FAILURE';

export const POST_MEMBER_DEMOGRAPHICS_SUCCESS =
  'POST_MEMBER_DEMOGRAPHICS_SUCCESS';
export const POST_MEMBER_DEMOGRAPHICS_FAILURE =
  'POST_MEMBER_DEMOGRAPHICS_FAILURE';
