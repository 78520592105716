import * as types from './types';

const initialState = {
  Errors: [],
  PledgeData: [],
  CvoConatactDetails: [],
  IncomingCvoDetails: [],
  PostIncomingCvoDetails: [],
  IncomingCvoDetailsData: [],
  SendEmailRequest: [],
  CeoCvoDetails: []
};

export default function AnnualReportCollaborationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.ANNUALREPORT_PLEDGE_STATEMENT_FAILURE:
      return { ...state, Errors: action.payload };

    case types.ANNUALREPORT_CVO_CONTACT_DATA: {
      return {
        ...state,
        CvoConatactDetails: action.payload
      };
    }
    case types.ANNUALREPORT_PLEDGE_STATEMENT_DATA:
      return {
        ...state,
        PledgeData: action.payload
      };
    case types.GET_AR_INCOMING_CONTACT_DETAILS_SUCCESS: {
      return {
        ...state,
        IncomingCvoDetails: action.payload
      };
    }
    case types.GET_AR_INCOMING_CONTACT_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_AR_INCOMING_CONTACT_DETAILS_SUCCESS: {
      return {
        ...state,
        PostIncomingCvoDetails: action.payload
      };
    }
    case types.POST_AR_INCOMING_CONTACT_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_INCOMING_CONTACT_DETAILS_SUCCESS: {
      return {
        ...state,
        IncomingCvoDetailsData: action.payload
      };
    }
    case types.GET_INCOMING_CONTACT_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    case types.SEND_CEO_CVO_EMAIL_REQUEST_SUCCESS: {
      return {
        ...state,
        SendEmailRequest: action.payload
      };
    }
    case types.SEND_CEO_CVO_EMAIL_REQUEST_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CEO_CVO_DETAILS_SUCCESS: {
      return {
        ...state,
        CeoCvoDetails: action.payload
      };
    }
    case types.GET_CEO_CVO_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
