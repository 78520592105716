import * as types from './types';

const initialState = {
  GetProgramInnovation: {},
  PostProgramInnovation: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_KEY':
      return {
        ...state,
        [action.payload.key]: {}
      };
    case types.GET_PROGRAM_INNOVATION_SUCCESS: {
      return {
        ...state,
        GetProgramInnovation: action.payload
      };
    }
    case types.GET_PROGRAM_INNOVATION_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_PROGRAM_INNOVATION_SUCCESS: {
      return {
        ...state,
        PostProgramInnovation: action.payload
      };
    }
    case types.POST_PROGRAM_INNOVATION_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
