/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { APP_LABELS } from '@config/app-config';
import getUserManagementTableList from '@state/redux/user-management/user-table/actions';
import Table from './table';
import USER_TABLE_COLUMNS from './user-table-column';

const UserTable = props => {
  const { handleGetUserManagementTableList } = props;
  const [userManagementList, setUserManagementList] = useState({});

  const [loader, setLoader] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    setLoader(true);
    handleGetUserManagementTableList()
      .then(result => {
        if (result.status === 200) {
          setUserManagementList(result?.data?.users);
          setLoader(false);
        } else {
          setLoader(false);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorPopup(true);
      });
  }, []);

  const setModuleData = userModuleRoles => {
    const selectedData = userModuleRoles?.map(module => module.modulename);
    return selectedData;
  };
  const getModuleRoleLength = userModuleRoles => {
    const selectedData = userModuleRoles?.map(module => module.roles.length);
    return selectedData;
  };

  const setRoleData = userModuleRoles => {
    const selectedData = [];
    const userModuleRolesData = JSON.parse(JSON.stringify(userModuleRoles));
    for (const module of userModuleRolesData) {
      for (const role of module.roles) {
        selectedData.push(role.roleName);
      }
    }
    return selectedData;
  };

  const processRole = roleList =>
    roleList?.map(role => (
      <table key={role}>
        <tr key={role}>
          <th key={role}>{role}</th>
        </tr>
      </table>
    ));

  const processModule = (moduleList, moduleRoleLengthList) =>
    moduleList?.map((module, i) => (
      <table key={`module-${module}`}>
        <tr key={`module-${module}`}>
          <th
            key={`module-${module}`}
            rowSpan={
              moduleRoleLengthList && moduleRoleLengthList[i]
                ? moduleRoleLengthList[i]
                : 0
            }
          >
            {module}
          </th>
        </tr>
      </table>
    ));

  const getUserManagementListForTable = userManagementData => {
    const tableData = userManagementData;

    if (tableData && tableData.length > 0) {
      return tableData?.map(data => {
        const location = {
          pathname: `/userManagement/form/${data?.userId}`,
          state: data
        };
        const moduleData = setModuleData(data?.userModuleRoles);
        const roleData = setRoleData(data?.userModuleRoles);
        const moduleRoleLength = getModuleRoleLength(data?.userModuleRoles);
        return {
          DCHUserId: get(data, 'DCHUserId', '-'),
          personId: get(data, 'personId', '-'),
          emailAddress: data?.emailAddress,
          name: `${get(data, 'firstName')} ${get(data, 'lastName', '-')}`,
          associationId: get(data, 'associationId', '-'),
          associationName: get(data, 'associationName', '-'),
          module: processModule(moduleData, moduleRoleLength),
          Role: processRole(roleData),
          status: data?.userStatus ? (
            <span className="badge ymca-volunteer-badge green-badge">
              Active
            </span>
          ) : (
            <span className="badge ymca-volunteer-badge red-badge">
              Inactive
            </span>
          ),
          DetailPage: (
            <Link to={location} className="table-link">
              Edit
            </Link>
          )
        };
      });
    }
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
          <div className="container">
            <section className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="feedback-support-title">
                        User Management
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="stats-data text-end">
              <Link
                to="/userManagement/upload/file"
                className="btn ymca-primary-btn mb-2 mx-3 custom-align"
                type="button"
              >
                + Upload User Data
              </Link>
              <Link
                to="/userManagement/form"
                className="btn ymca-primary-btn mb-2 custom-align"
                type="button"
              >
                Add User
              </Link>
            </section>
          </div>

          <hr className="custom-hr mt-0" />

          <Table
            rows={getUserManagementListForTable(userManagementList)}
            headers={USER_TABLE_COLUMNS}
            setLoader={setLoader}
          />
        </div>
      </main>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={APP_LABELS.COMMON_ERROR_MESSAGE}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

UserTable.propTypes = {
  handleGetUserManagementTableList: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetUserManagementTableList: getUserManagementTableList
};

export default connect(null, mapDispatchToProps)(React.memo(UserTable));
