import * as types from './types';

const initialState = {
    programs: {},
    dataSystems: {}
};

export default function clientsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SELECTED_DATA_SYSTEM:
            return {
                ...state,
                dataSystem: action.payload
            };

        case types.SELECTED_PROGRAM:
            return {
                ...state,
                program: action.payload
            };

        case types.DETAIL_PAGE_TYPE:
            return {
                ...state,
                detailPageType: action.payload
            };

        case types.SAVE_CDP_FETCH_ID:
            return {
                ...state,
                cdpFetchId: action.payload
            };
        case types.SAVE_FETCH_TYPE:
            return {
                ...state,
                fetchType: action.payload
            };
        default:
            return state;
    }
}