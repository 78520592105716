/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import {
  getMemberships,
  getMembershipsNWM,
  postMemberships,
  getCrmProviderList
} from '@redux/constituency-report/memberships/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import {
  GetMembershipsSelector,
  GetMembershipsNWMSelector,
  CrmProviderListDataSelector
} from '@redux/constituency-report/memberships/selectors';
import { GetMemberDemographicsSelector } from '@redux/constituency-report/member-demographics/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import TextError from '@modules/shared/components/reusable-components/text-error';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const MembershipTab = props => {
  const {
    setLoader,
    CrmProviderListSelectorList,
    handleToGetCrmProviderList,
    handleGetMemberships,
    handleGetMembershipsNWM,
    handlePostMemberships,
    handleGetAnnualReportsDashboard,
    GetMembershipsSelectorData,
    GetMembershipsNWMSelectorData,
    GetMemberDemographicsSelectorData,
    LoginUserData,
    status
  } = props;

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [subInstructions, setSubInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [resStatus, setResStatus] = useState();
  const formikref = useRef();

  const [showNotOfferMemberships, setShowNotOfferMemberships] = useState(false);
  const [showEditNWMData, setShowEditNWMData] = useState(false);
  const [getCrmName, setGetCrmName] = useState('');
  const [saveNwmData, setSaveNwmData] = useState(false);

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Constituency Report';

  const initialValues = {
    doNotOfferFacilityMemberships: false,
    isNwmDataAvailable: false,

    male1To5: '',
    female1To5: '',
    additionalIdentities1To5: '',
    unknown1To5: '',

    male6To11: '',
    female6To11: '',
    additionalIdentities6To11: '',
    unknown6To11: '',

    male12To17: '',
    female12To17: '',
    additionalIdentities12To17: '',
    unknown12To17: '',

    male18To29: '',
    female18To29: '',
    additionalIdentities18To29: '',
    unknown18To29: '',

    male30To54: '',
    female30To54: '',
    additionalIdentities30To54: '',
    unknown30To54: '',

    male55To64: '',
    female55To64: '',
    additionalIdentities55To64: '',
    unknown55To64: '',

    male65Plus: '',
    female65Plus: '',
    additionalIdentities65Plus: '',
    unknown65Plus: '',

    maleUnknown: '',
    femaleUnknown: '',
    additionalIdentitiesUnknown: '',
    unknownUnknown: '',

    total1To5: '',
    total6To11: '',
    total12To17: '',
    total18To29: '',
    total30To54: '',
    total55To64: '',
    total65Plus: '',
    totalUnknownRow: '',

    totalMale: '',
    totalFemale: '',
    totalAdditionalIdentities: '',
    totalUnknown: '',
    totaltotal: '',
    crmradio: 'false',
    yourCrm: 'Other',
    crmname: '',
    currentCRM: ''
  };

  const editValues = {
    doNotOfferFacilityMemberships:
      GetMembershipsSelectorData?.data?.doNotOfferFacilityMemberships,
    isNwmDataAvailable: GetMembershipsSelectorData?.data?.isNwmDataAvailable,
    male1To5:
      GetMembershipsSelectorData?.data?.male1To5 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.male1To5,
    female1To5:
      GetMembershipsSelectorData?.data?.female1To5 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.female1To5,
    additionalIdentities1To5:
      GetMembershipsSelectorData?.data?.additionalIdentities1To5 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentities1To5,
    unknown1To5:
      GetMembershipsSelectorData?.data?.unknown1To5 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknown1To5,

    male6To11:
      GetMembershipsSelectorData?.data?.male6To11 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.male6To11,
    female6To11:
      GetMembershipsSelectorData?.data?.female6To11 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.female6To11,
    additionalIdentities6To11:
      GetMembershipsSelectorData?.data?.additionalIdentities6To11 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentities6To11,
    unknown6To11:
      GetMembershipsSelectorData?.data?.unknown6To11 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknown6To11,

    male12To17:
      GetMembershipsSelectorData?.data?.male12To17 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.male12To17,
    female12To17:
      GetMembershipsSelectorData?.data?.female12To17 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.female12To17,
    additionalIdentities12To17:
      GetMembershipsSelectorData?.data?.additionalIdentities12To17 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentities12To17,
    unknown12To17:
      GetMembershipsSelectorData?.data?.unknown12To17 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknown12To17,

    male18To29:
      GetMembershipsSelectorData?.data?.male18To29 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.male18To29,
    female18To29:
      GetMembershipsSelectorData?.data?.female18To29 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.female18To29,
    additionalIdentities18To29:
      GetMembershipsSelectorData?.data?.additionalIdentities18To29 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentities18To29,
    unknown18To29:
      GetMembershipsSelectorData?.data?.unknown18To29 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknown18To29,

    male30To54:
      GetMembershipsSelectorData?.data?.male30To54 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.male30To54,
    female30To54:
      GetMembershipsSelectorData?.data?.female30To54 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.female30To54,
    additionalIdentities30To54:
      GetMembershipsSelectorData?.data?.additionalIdentities30To54 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentities30To54,
    unknown30To54:
      GetMembershipsSelectorData?.data?.unknown30To54 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknown30To54,

    male55To64:
      GetMembershipsSelectorData?.data?.male55To64 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.male55To64,
    female55To64:
      GetMembershipsSelectorData?.data?.female55To64 === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.female55To64,
    additionalIdentities55To64:
      GetMembershipsSelectorData?.data?.additionalIdentities55To64 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentities55To64,
    unknown55To64:
      GetMembershipsSelectorData?.data?.unknown55To64 === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknown55To64,

    male65Plus:
      GetMembershipsSelectorData?.data?.male65Plus === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.male65Plus,
    female65Plus:
      GetMembershipsSelectorData?.data?.female65Plus === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.female65Plus,
    additionalIdentities65Plus:
      GetMembershipsSelectorData?.data?.additionalIdentities65Plus === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentities65Plus,
    unknown65Plus:
      GetMembershipsSelectorData?.data?.unknown65Plus === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknown65Plus,

    maleUnknown:
      GetMembershipsSelectorData?.data?.maleUnknown === null && showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.maleUnknown,
    femaleUnknown:
      GetMembershipsSelectorData?.data?.femaleUnknown === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.femaleUnknown,
    additionalIdentitiesUnknown:
      GetMembershipsSelectorData?.data?.additionalIdentitiesUnknown === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.additionalIdentitiesUnknown,
    unknownUnknown:
      GetMembershipsSelectorData?.data?.unknownUnknown === null &&
      showEditNWMData
        ? 0
        : GetMembershipsSelectorData?.data?.unknownUnknown,
    crmradio:
      GetMembershipsSelectorData?.data?.crmradio === null ||
      GetMembershipsSelectorData?.data?.crmradio === undefined
        ? 'false'
        : GetMembershipsSelectorData?.data?.crmradio,
    yourCrm:
      GetMembershipsSelectorData?.data?.yourCrm === null ||
      GetMembershipsSelectorData?.data?.yourCrm === undefined
        ? 'Other'
        : GetMembershipsSelectorData?.data?.yourCrm,
    crmname:
      GetMembershipsSelectorData?.data?.crmname === null ||
      GetMembershipsSelectorData?.data?.crmname === undefined
        ? ''
        : GetMembershipsSelectorData?.data?.crmname,
    currentCRM:
      GetMembershipsSelectorData?.data?.crmname === null ||
      GetMembershipsSelectorData?.data?.crmname === undefined
        ? ''
        : GetMembershipsSelectorData?.data?.currentCRM,
    total1To5: '',
    total6To11: '',
    total12To17: '',
    total18To29: '',
    total30To54: '',
    total55To64: '',
    total65Plus: '',
    totalUnknownRow: '',
    totalMale: '',
    totalFemale: '',
    totalAdditionalIdentities: '',
    totalUnknown: '',
    totaltotal: ''
  };

  const editValuesNWM = {
    doNotOfferFacilityMemberships:
      GetMembershipsNWMSelectorData?.data?.doNotOfferFacilityMemberships,
    isNwmDataAvailable: GetMembershipsNWMSelectorData?.data?.isNwmDataAvailable,

    male1To5:
      GetMembershipsNWMSelectorData?.data?.male1To5 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.male1To5,
    female1To5:
      GetMembershipsNWMSelectorData?.data?.female1To5 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.female1To5,
    additionalIdentities1To5:
      GetMembershipsNWMSelectorData?.data?.additionalIdentities1To5 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentities1To5,
    unknown1To5:
      GetMembershipsNWMSelectorData?.data?.unknown1To5 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknown1To5,

    male6To11:
      GetMembershipsNWMSelectorData?.data?.male6To11 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.male6To11,
    female6To11:
      GetMembershipsNWMSelectorData?.data?.female6To11 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.female6To11,
    additionalIdentities6To11:
      GetMembershipsNWMSelectorData?.data?.additionalIdentities6To11 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentities6To11,
    unknown6To11:
      GetMembershipsNWMSelectorData?.data?.unknown6To11 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknown6To11,

    male12To17:
      GetMembershipsNWMSelectorData?.data?.male12To17 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.male12To17,
    female12To17:
      GetMembershipsNWMSelectorData?.data?.female12To17 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.female12To17,
    additionalIdentities12To17:
      GetMembershipsNWMSelectorData?.data?.additionalIdentities12To17 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentities12To17,
    unknown12To17:
      GetMembershipsNWMSelectorData?.data?.unknown12To17 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknown12To17,

    male18To29:
      GetMembershipsNWMSelectorData?.data?.male18To29 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.male18To29,
    female18To29:
      GetMembershipsNWMSelectorData?.data?.female18To29 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.female18To29,
    additionalIdentities18To29:
      GetMembershipsNWMSelectorData?.data?.additionalIdentities18To29 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentities18To29,
    unknown18To29:
      GetMembershipsNWMSelectorData?.data?.unknown18To29 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknown18To29,

    male30To54:
      GetMembershipsNWMSelectorData?.data?.male30To54 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.male30To54,
    female30To54:
      GetMembershipsNWMSelectorData?.data?.female30To54 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.female30To54,
    additionalIdentities30To54:
      GetMembershipsNWMSelectorData?.data?.additionalIdentities30To54 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentities30To54,
    unknown30To54:
      GetMembershipsNWMSelectorData?.data?.unknown30To54 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknown30To54,

    male55To64:
      GetMembershipsNWMSelectorData?.data?.male55To64 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.male55To64,
    female55To64:
      GetMembershipsNWMSelectorData?.data?.female55To64 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.female55To64,
    additionalIdentities55To64:
      GetMembershipsNWMSelectorData?.data?.additionalIdentities55To64 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentities55To64,
    unknown55To64:
      GetMembershipsNWMSelectorData?.data?.unknown55To64 === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknown55To64,

    male65Plus:
      GetMembershipsNWMSelectorData?.data?.male65Plus === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.male65Plus,
    female65Plus:
      GetMembershipsNWMSelectorData?.data?.female65Plus === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.female65Plus,
    additionalIdentities65Plus:
      GetMembershipsNWMSelectorData?.data?.additionalIdentities65Plus === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentities65Plus,
    unknown65Plus:
      GetMembershipsNWMSelectorData?.data?.unknown65Plus === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknown65Plus,

    maleUnknown:
      GetMembershipsNWMSelectorData?.data?.maleUnknown === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.maleUnknown,
    femaleUnknown:
      GetMembershipsNWMSelectorData?.data?.femaleUnknown === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.femaleUnknown,
    additionalIdentitiesUnknown:
      GetMembershipsNWMSelectorData?.data?.additionalIdentitiesUnknown === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.additionalIdentitiesUnknown,
    unknownUnknown:
      GetMembershipsNWMSelectorData?.data?.unknownUnknown === null
        ? 0
        : GetMembershipsNWMSelectorData?.data?.unknownUnknown,

    total1To5: '',
    total6To11: '',
    total12To17: '',
    total18To29: '',
    total30To54: '',
    total55To64: '',
    total65Plus: '',
    totalUnknownRow: '',
    totalMale: '',
    totalFemale: '',
    totalAdditionalIdentities: '',
    totalUnknown: '',
    totaltotal: '',
    crmradio: getCrmName ? '' : 'false',
    yourCrm: getCrmName ? '' : 'Other',
    crmname: ''
  };

  const handelGetCall = success => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetMemberships(associationsNum).then(res => {
      if (res.status === 200) {
        const MembershipLocalData = res?.data?.data;
        setGetCrmName(res?.data?.data?.currentCRM);

        if (
          MembershipLocalData?.doNotOfferFacilityMemberships === false &&
          MembershipLocalData?.male1To5 === null &&
          MembershipLocalData?.female1To5 === null &&
          MembershipLocalData?.additionalIdentities1To5 === null &&
          MembershipLocalData?.unknown1To5 === null &&
          MembershipLocalData?.male6To11 === null &&
          MembershipLocalData?.female6To11 === null &&
          MembershipLocalData?.additionalIdentities6To11 === null &&
          MembershipLocalData?.unknown6To11 === null &&
          MembershipLocalData?.male12To17 === null &&
          MembershipLocalData?.female12To17 === null &&
          MembershipLocalData?.additionalIdentities12To17 === null &&
          MembershipLocalData?.unknown12To17 === null &&
          MembershipLocalData?.male18To29 === null &&
          MembershipLocalData?.female18To29 === null &&
          MembershipLocalData?.additionalIdentities18To29 === null &&
          MembershipLocalData?.unknown18To29 === null &&
          MembershipLocalData?.male30To54 === null &&
          MembershipLocalData?.female30To54 === null &&
          MembershipLocalData?.additionalIdentities30To54 === null &&
          MembershipLocalData?.unknown30To54 === null &&
          MembershipLocalData?.male55To64 === null &&
          MembershipLocalData?.female55To64 === null &&
          MembershipLocalData?.additionalIdentities55To64 === null &&
          MembershipLocalData?.unknown55To64 === null &&
          MembershipLocalData?.male65Plus === null &&
          MembershipLocalData?.female65Plus === null &&
          MembershipLocalData?.additionalIdentities65Plus === null &&
          MembershipLocalData?.unknown65Plus === null &&
          MembershipLocalData?.maleUnknown === null &&
          MembershipLocalData?.femaleUnknown === null &&
          MembershipLocalData?.additionalIdentitiesUnknown === null &&
          MembershipLocalData?.unknownUnknown === null &&
          MembershipLocalData?.crmradio === null &&
          MembershipLocalData?.crmname === null &&
          MembershipLocalData?.yourCrm === null
        ) {
          setShowEditNWMData(true);
          handleGetMembershipsNWM(associationsNum).then(resp => {
            if (resp.status === 200) {
              setSaveNwmData(true);

              setGetCrmName(resp?.data?.crmData?.crmname);
              if (resp?.data?.data === null) {
                setShowNotOfferMemberships(true);
                setShowEditNWMData(false);
              }
              setLoader(false);
              if (success) {
                setSuccessPopup(true);
              }
            } else {
              setLoader(false);
              setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
              setErrorPopup(true);
            }
          });
        } else if (MembershipLocalData.isNwmDataUsed === true) {
          setShowEditNWMData(true);
        } else {
          setShowNotOfferMemberships(true);
        }
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    handleToGetCrmProviderList()
      .then(res => {
        if (res?.data?.isSuccess) {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorPopup(true);
      });
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions')).members?.Instruction
    );
    setSubInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))['sub-members']
        ?.Instruction
    );
  }, []);

  const validationSchema = Yup.object({
    male1To5: Yup.string().nullable(),
    female1To5: Yup.string().nullable(),
    additionalIdentities1To5: Yup.string().nullable(),
    unknown1To5: Yup.string().nullable(),
    crmradio: Yup.string().nullable(),
    yourCrm: Yup.string().nullable(),
    crmname: Yup.string().nullable(),
    male6To11: Yup.string().nullable(),
    female6To11: Yup.string().nullable(),
    additionalIdentities6To11: Yup.string().nullable(),
    unknown6To11: Yup.string().nullable(),

    male12To17: Yup.string().nullable(),
    female12To17: Yup.string().nullable(),
    additionalIdentities12To17: Yup.string().nullable(),
    unknown12To17: Yup.string().nullable(),

    male18To29: Yup.string().nullable(),
    female18To29: Yup.string().nullable(),
    additionalIdentities18To29: Yup.string().nullable(),
    unknown18To29: Yup.string().nullable(),

    male30To54: Yup.string().nullable(),
    female30To54: Yup.string().nullable(),
    additionalIdentities30To54: Yup.string().nullable(),
    unknown30To54: Yup.string().nullable(),

    male55To64: Yup.string().nullable(),
    female55To64: Yup.string().nullable(),
    additionalIdentities55To64: Yup.string().nullable(),
    unknown55To64: Yup.string().nullable(),

    male65Plus: Yup.string().nullable(),
    female65Plus: Yup.string().nullable(),
    additionalIdentities65Plus: Yup.string().nullable(),
    unknown65Plus: Yup.string().nullable(),

    maleUnknown: Yup.string().nullable(),
    femaleUnknown: Yup.string().nullable(),
    additionalIdentitiesUnknown: Yup.string().nullable(),
    unknownUnknown: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? Number(value) : null);

  function handelTotalMembers(MembersValue) {
    let total =
      MembersValue?.male1To5 +
      MembersValue?.female1To5 +
      MembersValue?.additionalIdentities1To5 +
      MembersValue?.unknown1To5 +
      MembersValue?.male6To11 +
      MembersValue?.female6To11 +
      MembersValue?.additionalIdentities6To11 +
      MembersValue?.unknown6To11 +
      MembersValue?.male12To17 +
      MembersValue?.female12To17 +
      MembersValue?.additionalIdentities12To17 +
      MembersValue?.unknown12To17 +
      MembersValue?.male18To29 +
      MembersValue?.female18To29 +
      MembersValue?.additionalIdentities18To29 +
      MembersValue?.unknown18To29 +
      MembersValue?.male30To54 +
      MembersValue?.female30To54 +
      MembersValue?.additionalIdentities30To54 +
      MembersValue?.unknown30To54 +
      MembersValue?.male55To64 +
      MembersValue?.female55To64 +
      MembersValue?.additionalIdentities55To64 +
      MembersValue?.unknown55To64 +
      MembersValue?.male65Plus +
      MembersValue?.female65Plus +
      MembersValue?.additionalIdentities65Plus +
      MembersValue?.unknown65Plus +
      MembersValue?.maleUnknown +
      MembersValue?.femaleUnknown +
      MembersValue?.additionalIdentitiesUnknown +
      MembersValue?.unknownUnknown;

    if (selectedYear === '2022') {
      total -=
        MembersValue?.maleUnknown +
        MembersValue?.femaleUnknown +
        MembersValue?.additionalIdentitiesUnknown +
        MembersValue?.unknownUnknown;
    }

    return total;
  }

  const TotalMembers = handelTotalMembers(GetMembershipsSelectorData?.data);

  const TotalMembersDemographics =
    GetMemberDemographicsSelectorData?.data?.nativeAmericanAlaskanNative +
    GetMemberDemographicsSelectorData?.data?.asian +
    GetMemberDemographicsSelectorData?.data?.blackOrAfricanAmerican +
    GetMemberDemographicsSelectorData?.data?.hispanicLatino +
    GetMemberDemographicsSelectorData?.data?.middleEastNorthAfrican +
    GetMemberDemographicsSelectorData?.data?.nativeHawaiOtherPacificIslander +
    GetMemberDemographicsSelectorData?.data?.white +
    GetMemberDemographicsSelectorData?.data?.twoOrMoreRaces +
    GetMemberDemographicsSelectorData?.data?.other +
    GetMemberDemographicsSelectorData?.data?.additionalIdentities +
    GetMemberDemographicsSelectorData?.data?.unknown;

  const onSubmit = values => {
    setLoader(true);
    let payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetMembershipsSelectorData?.data?.createdByName,
      createdByPersonId: GetMembershipsSelectorData?.data?.createdByPersonId,
      createdDatetime: GetMembershipsSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      membershipId: GetMembershipsSelectorData?.data?.membershipId,
      reportBatchId: GetMembershipsSelectorData?.data?.reportBatchId,
      recordStatus: GetMemberDemographicsSelectorData?.data?.recordStatus,
      doNotOfferFacilityMemberships: values.doNotOfferFacilityMemberships,
      isNwmDataAvailable: values.isNwmDataAvailable,
      isNwmDataUsed: showEditNWMData,

      male1To5: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.male1To5),
      female1To5: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.female1To5),
      additionalIdentities1To5: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.additionalIdentities1To5),
      unknown1To5: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.unknown1To5),
      male6To11: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.male6To11),
      female6To11: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.female6To11),
      additionalIdentities6To11: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.additionalIdentities6To11),
      unknown6To11: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.unknown6To11),
      male12To17: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.male12To17),
      female12To17: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.female12To17),
      additionalIdentities12To17: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.additionalIdentities12To17),
      unknown12To17: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.unknown12To17),
      male18To29: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.male18To29),
      female18To29: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.female18To29),
      additionalIdentities18To29: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.additionalIdentities18To29),
      unknown18To29: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.unknown18To29),

      male30To54: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.male30To54),
      female30To54: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.female30To54),
      additionalIdentities30To54: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.additionalIdentities30To54),
      unknown30To54: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.unknown30To54),
      male55To64: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.male55To64),
      female55To64: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.female55To64),
      additionalIdentities55To64: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.additionalIdentities55To64),
      unknown55To64: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.unknown55To64),

      male65Plus: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.male65Plus),
      female65Plus: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.female65Plus),
      additionalIdentities65Plus: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.additionalIdentities65Plus),
      unknown65Plus: values.doNotOfferFacilityMemberships
        ? null
        : convertedValue(values.unknown65Plus),

      totalMembersDemographicsCount: TotalMembersDemographics
    };

    if (selectedYear !== '2022') {
      payloadBody = {
        ...payloadBody,
        maleUnknown: values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.maleUnknown),
        femaleUnknown: values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.femaleUnknown),
        additionalIdentitiesUnknown: values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.additionalIdentitiesUnknown),
        unknownUnknown: values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.unknownUnknown),
        crmradio: values?.crmradio?.toString() === 'true',
        yourCrm: values?.crmradio?.toString()==='true'?null:values?.yourCrm,
        crmname:  values?.crmradio?.toString()==='true'?null:values?.crmname,
        currentCRM: getCrmName
      };
    } else {
      payloadBody = {
        ...payloadBody,
        maleUnknown: 0,
        femaleUnknown: 0,
        additionalIdentitiesUnknown: 0,
        unknownUnknown: 0,
        crmradio: null,
        yourCrm: null,
        crmname: null,
        currentCRM: getCrmName
      };
    }
    const totalMembers = handelTotalMembers(payloadBody);
    payloadBody = {
      ...payloadBody,
      totalMembersCount: totalMembers
    };
    setSuccessPopup(false);
    handlePostMemberships(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );

        if (showEditNWMData) {
          setSaveNwmData(false);
        }
        if (res?.data?.data?.isNwmDataUsed === true) {
          setShowEditNWMData(true);
        }
        if (res?.data?.data?.isNwmDataUsed === false) {
          setShowNotOfferMemberships(true);
          setShowEditNWMData(false);
        }

        handelGetCall(true);
        sessionStorage.setItem('dirty', false);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };
  const handleCRMRadioYes = (e, setFieldValue) => {
    setFieldValue(e.target.name, 'true');
  };

  const handleCRMRadioNo = (e, setFieldValue) => {
    setFieldValue(e.target.name, 'false');
  };

  const totalCount = (
    value1 = 0,
    value2 = 0,
    value3 = 0,
    value4 = 0,
    value5 = 0,
    value6 = 0,
    value7 = 0,
    value8 = 0,
    value9 = 0,
    value10 = 0,
    value11 = 0,
    value12 = 0,
    value13 = 0,
    value14 = 0,
    value15 = 0,
    value16 = 0,
    value17 = 0,
    value18 = 0,
    value19 = 0,
    value20 = 0,
    value21 = 0,
    value22 = 0,
    value23 = 0,
    value24 = 0,
    value25 = 0,
    value26 = 0,
    value27 = 0,
    value28 = 0,
    value29 = 0,
    value30 = 0,
    value31 = 0,
    value32 = 0
  ) =>
    Number(value1) +
    Number(value2) +
    Number(value3) +
    Number(value4) +
    Number(value5) +
    Number(value6) +
    Number(value7) +
    Number(value8) +
    Number(value9) +
    Number(value10) +
    Number(value11) +
    Number(value12) +
    Number(value13) +
    Number(value14) +
    Number(value15) +
    Number(value16) +
    Number(value17) +
    Number(value18) +
    Number(value19) +
    Number(value20) +
    Number(value21) +
    Number(value22) +
    Number(value23) +
    Number(value24) +
    Number(value25) +
    Number(value26) +
    Number(value27) +
    Number(value28) +
    Number(value29) +
    Number(value30) +
    Number(value31) +
    Number(value32);

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>

      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 pb-3">
        <div className="ymca-instructionBoxes">
          <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
          <p className="ymca-instructTitle">
            <div
              dangerouslySetInnerHTML={{
                __html: instructions
              }}
            />
          </p>
        </div>
      </div>

      {GetMembershipsSelectorData?.data?.recordStatus === 'Partial' && (
        <PartialSaveWarningPopup />
      )}

      <Formik
        innerRef={formikref}
        initialValues={
          saveNwmData ? editValuesNWM : editValues || initialValues
        }
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              {showEditNWMData && (
                <div className="ymca-program p-0">
                  <h6 className="ymca-programActivities">
                    {`These numbers are from NWM. Please review the numbers below. 
                      If you would like to edit any data please select the checkbox.`}
                  </h6>
                  <div className="d-flex ymca-listingERA mt-4 mb-4">
                    <Field
                      className="styled-checkbox"
                      name="isNwmDataAvailable"
                      type="checkbox"
                    />
                    <label
                      htmlFor="isNwmDataAvailable"
                      className="ymca-cachet-normal-dark-text ms-2"
                    >
                      I would like to edit my NWM data below.
                    </label>
                  </div>
                </div>
              )}

              {showNotOfferMemberships && (
                <div className="ymca-program pt-0 mt-4">
                  <h6 className="ymca-programActivities">
                    {`You do not participate in NWM. Please self-report your
                      facility membership information below. If you do not offer
                      facility memberships, please select the checkbox below and
                      skip to the Program Members Section.`}
                  </h6>
                  <div className="d-flex ymca-listingERA mt-4 mb-4">
                    <Field
                      className="styled-checkbox"
                      name="doNotOfferFacilityMemberships"
                      type="checkbox"
                    />
                    <label
                      htmlFor="doNotOfferFacilityMemberships"
                      className="ymca-cachet-normal-dark-text ms-2"
                    >
                      I do not offer facility memberships.
                    </label>
                  </div>
                </div>
              )}

              {!values.doNotOfferFacilityMemberships && (
                <>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                    <div className="ymca-instructionBoxes">
                      <h4 className="ymca-instructHeadline">
                        {APP_LABELS.INSTRUCTIONS}
                      </h4>
                      <p className="ymca-instructTitle">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: subInstructions
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <div>
                    {selectedYear !== '2022' && (
                      <>
                        <div className="ymca-program p-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                          <h4 className="ymca-programActivities ms-0 mt-1">
                            Customer Relationship Management System
                          </h4>
                        </div>

                        <div className="ymca-program p-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                          <div className="ymca-selectWrap">
                            <div className="ymca-labelling ymca-dullText ms-2 mt-1">
                              The current Customer Relationship Management
                              System we have on file is{' '}
                              <span className="ymca-text-primary">
                                {getCrmName === null ||
                                getCrmName === undefined ||
                                getCrmName === ''
                                  ? 'Unknown'
                                  : `${getCrmName}`}
                              </span>
                              {/* */}. Is this correct?
                            </div>
                          </div>
                        </div>
                        <div className=" ymca-program p-0 ymca-normalWrap d-flex mt-2 ms-2">
                          <ul className="ymca-mainCheckBTN">
                            <li className="ymca-listCheckBTN">
                              <input
                                name="crmradio"
                                value="true"
                                checked={
                                  values?.crmradio?.toString() === 'true'
                                }
                                className="ymca-fieldRadio"
                                type="radio"
                                disabled={
                                  getCrmName === null ||
                                  getCrmName === undefined ||
                                  getCrmName === ''
                                }
                                onChange={e =>
                                  handleCRMRadioYes(e, setFieldValue)
                                }
                              />
                            </li>
                          </ul>
                          <span className="ymca-padRight checkboxlabel">
                            Yes
                          </span>
                          <ul className="ymca-mainCheckBTN">
                            <li className="ymca-listCheckBTN">
                              <input
                                name="crmradio"
                                value="false"
                                className="ymca-fieldRadio"
                                checked={
                                  values?.crmradio?.toString() === 'false'
                                }
                                type="radio"
                                onChange={e =>
                                  handleCRMRadioNo(e, setFieldValue)
                                }
                              />
                            </li>
                          </ul>
                          <span className="checkboxlabel">No</span>
                        </div>
                        <div className="row ymca-program p-0 mb-5">
                          {values?.crmradio?.toString() === 'false' && (
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 ms-2">
                              <div className="ymca-selectWrap">
                                <div className="ymca-labelling ymca-dullText txt-mandatory">
                                  Your Customer Relationship Management System
                                </div>
                                <select
                                  defaultValue="Other"
                                  name="yourCrm"
                                  className="ymca-select ymca-customArrow"
                                  onChange={e => {
                                    setFieldValue('yourCrm', e.target.value);
                                    setFieldValue('crmname', '');
                                  }}
                                  value={values?.yourCrm}
                                >
                                 <option value="">--Select--</option>
                                  {CrmProviderListSelectorList?.data?.map(
                                    item => (
                                      <option
                                        key={item?.crmProviderId}
                                        value={item?.name}
                                      >
                                        {item?.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          )}

                          {values?.yourCrm === 'Other' && values?.crmradio?.toString()==='false' && (
                            <div className=" col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                              <div className="ymca-selectWrap">
                                <div className="ymca-labelling ymca-dullText txt-mandatory">
                                  Customer Relationship Management System Name
                                </div>
                                <input
                                  name="crmname"
                                  type="text"
                                  value={values?.crmname}
                                  onChange={e => {
                                    setFieldValue('crmname', e.target.value);
                                  }}
                                  className="ymca-fieldSet ymca-icon-svg"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={`ymca-tableResponsive ${
                      showEditNWMData && !values.isNwmDataAvailable
                        ? 'ymca-disabled-section'
                        : ''
                    }`}
                  >
                    <table className="table ymca-table table-striped ymca-autoLength">
                      <thead>
                        <tr className="ymca-cuttingData">
                          <th>&nbsp;</th>
                          <th>Male</th>
                          <th>Female</th>
                          <th>Additional Identities</th>
                          <th>Unknown</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody className="ymca-cuttingBody ymca-lengths">
                        <tr>
                          <td className="txt-mandatory">
                            Infant / Toddler / Preschool (Birth-5 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male1To5')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="male1To5"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female1To5')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="female1To5"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities1To5'
                                )
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="additionalIdentities1To5"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown1To5')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="unknown1To5"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              value={totalCount(
                                values.male1To5,
                                values.female1To5,
                                values.additionalIdentities1To5,
                                values.unknown1To5
                              )}
                              disabled
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Elementary (6-11 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male6To11')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="male6To11"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female6To11')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="female6To11"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities6To11'
                                )
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="additionalIdentities6To11"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown6To11')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="unknown6To11"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male6To11,
                                values.female6To11,
                                values.additionalIdentities6To11,
                                values.unknown6To11
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Jr / Sr High (12-17 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male12To17')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="male12To17"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female12To17')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="female12To17"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities12To17'
                                )
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="additionalIdentities12To17"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown12To17')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="unknown12To17"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male12To17,
                                values.female12To17,
                                values.additionalIdentities12To17,
                                values.unknown12To17
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Young Adult (18-29 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male18To29')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="male18To29"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female18To29')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="female18To29"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities18To29'
                                )
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="additionalIdentities18To29"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                errors.unknown18To29 && touched.unknown18To29
                                  ? 'ymca-field invalid'
                                  : 'ymca-field'
                              }
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown18To29')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="unknown18To29"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male18To29,
                                values.female18To29,
                                values.additionalIdentities18To29,
                                values.unknown18To29
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">Adult (30-54 years)</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male30To54')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="male30To54"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female30To54')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="female30To54"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities30To54'
                                )
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="additionalIdentities30To54"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown30To54')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="unknown30To54"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male30To54,
                                values.female30To54,
                                values.additionalIdentities30To54,
                                values.unknown30To54
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">Adult (55-64 years)</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male55To64')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="male55To64"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female55To64')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="female55To64"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities55To64'
                                )
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="additionalIdentities55To64"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown55To64')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="unknown55To64"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total55to64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male55To64,
                                values.female55To64,
                                values.additionalIdentities55To64,
                                values.unknown55To64
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Older Adult (65+ years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male65Plus')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="male65Plus"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female65Plus')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="female65Plus"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities65Plus'
                                )
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="additionalIdentities65Plus"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field"
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown65Plus')
                              }
                              disabled={
                                showEditNWMData && !values.isNwmDataAvailable
                              }
                            />
                            <div>
                              <ErrorMessage
                                component={TextError}
                                name="unknown65Plus"
                              />
                            </div>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male65Plus,
                                values.female65Plus,
                                values.additionalIdentities65Plus,
                                values.unknown65Plus
                              )}
                            />
                          </td>
                        </tr>
                        {selectedYear !== '2022' && (
                          <tr>
                            <td className="txt-mandatory">Unknown</td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="maleUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                className="ymca-field"
                                onChange={e =>
                                  handleChange(e, setFieldValue, 'maleUnknown')
                                }
                                disabled={
                                  showEditNWMData && !values.isNwmDataAvailable
                                }
                              />
                              <div>
                                <ErrorMessage
                                  component={TextError}
                                  name="maleUnknown"
                                />
                              </div>
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="femaleUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                className="ymca-field"
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'femaleUnknown'
                                  )
                                }
                                disabled={
                                  showEditNWMData && !values.isNwmDataAvailable
                                }
                              />
                              <div>
                                <ErrorMessage
                                  component={TextError}
                                  name="femaleUnknown"
                                />
                              </div>
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="additionalIdentitiesUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                className="ymca-field"
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'additionalIdentitiesUnknown'
                                  )
                                }
                                disabled={
                                  showEditNWMData && !values.isNwmDataAvailable
                                }
                              />
                              <div>
                                <ErrorMessage
                                  component={TextError}
                                  name="additionalIdentitiesUnknown"
                                />
                              </div>
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="unknownUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                className="ymca-field"
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'unknownUnknown'
                                  )
                                }
                                disabled={
                                  showEditNWMData && !values.isNwmDataAvailable
                                }
                              />
                              <div>
                                <ErrorMessage
                                  component={TextError}
                                  name="unknownUnknown"
                                />
                              </div>
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="totalUnknownRow"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                className="ymca-field ymca-fieldDisabled"
                                disabled
                                value={totalCount(
                                  values.maleUnknown,
                                  values.femaleUnknown,
                                  values.additionalIdentitiesUnknown,
                                  values.unknownUnknown
                                )}
                              />
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>Total</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalMale"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male1To5,
                                values.male6To11,
                                values.male12To17,
                                values.male18To29,
                                values.male30To54,
                                values.male55To64,
                                values.male65Plus,
                                values.maleUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalFemale"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.female1To5,
                                values.female6To11,
                                values.female12To17,
                                values.female18To29,
                                values.female30To54,
                                values.female55To64,
                                values.female65Plus,
                                values.femaleUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalAdditionalIdentities"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.additionalIdentities1To5,
                                values.additionalIdentities6To11,
                                values.additionalIdentities12To17,
                                values.additionalIdentities18To29,
                                values.additionalIdentities30To54,
                                values.additionalIdentities55To64,
                                values.additionalIdentities65Plus,
                                values.additionalIdentitiesUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalUnknown"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.unknown1To5,
                                values.unknown6To11,
                                values.unknown12To17,
                                values.unknown18To29,
                                values.unknown30To54,
                                values.unknown55To64,
                                values.unknown65Plus,
                                values.unknownUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totaltotal"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              value={totalCount(
                                values.male1To5,
                                values.male6To11,
                                values.male12To17,
                                values.male18To29,
                                values.male30To54,
                                values.male55To64,
                                values.male65Plus,
                                values.maleUnknown,
                                values.female1To5,
                                values.female6To11,
                                values.female12To17,
                                values.female18To29,
                                values.female30To54,
                                values.female55To64,
                                values.female65Plus,
                                values.femaleUnknown,
                                values.additionalIdentities1To5,
                                values.additionalIdentities6To11,
                                values.additionalIdentities12To17,
                                values.additionalIdentities18To29,
                                values.additionalIdentities30To54,
                                values.additionalIdentities55To64,
                                values.additionalIdentities65Plus,
                                values.additionalIdentitiesUnknown,
                                values.unknown1To5,
                                values.unknown6To11,
                                values.unknown12To17,
                                values.unknown18To29,
                                values.unknown30To54,
                                values.unknown55To64,
                                values.unknown65Plus,
                                values.unknownUnknown
                              )}
                              disabled
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="d-flex flex-column">
                      {Number.isNaN(TotalMembersDemographics) === false &&
                        Number.isNaN(TotalMembers) === false &&
                        TotalMembersDemographics !== TotalMembers && (
                          <div className="errorNote position-static fw-bold mb-5">
                            * Total count of Facility Members and Facility
                            Members Demographics must be equal.
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}

              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={GetMembershipsSelectorData?.data?.updatedByName}
                  Time={GetMembershipsSelectorData?.data?.updatedDatetime}
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitled float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {help && <Help hidePopup={hideShowHelp} keyValue="members" />}
    </div>
  );
};

MembershipTab.propTypes = {
  handleGetMemberships: PropTypes.func.isRequired,
  handleGetMembershipsNWM: PropTypes.func.isRequired,
  handlePostMemberships: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetMembershipsSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  GetMembershipsNWMSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  GetMemberDemographicsSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleToGetCrmProviderList: PropTypes.func.isRequired,
  CrmProviderListSelectorList: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetMemberships: getMemberships,
  handleGetMembershipsNWM: getMembershipsNWM,
  handlePostMemberships: postMemberships,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleToGetCrmProviderList: getCrmProviderList
};

const mapStateToProps = state => ({
  GetMembershipsSelectorData: GetMembershipsSelector(state),
  GetMembershipsNWMSelectorData: GetMembershipsNWMSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  GetMemberDemographicsSelectorData: GetMemberDemographicsSelector(state),
  CrmProviderListSelectorList: CrmProviderListDataSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(MembershipTab));
