import {
  GET_SWIMSAFETY_UPLOAD_PROFILES,
  GET_SWIMSAFETY_IMPORT_PROFILES,
  GET_SWIMSAFETY_PROFILE_LIST_API,
  GET_SWIMS_PROFILE_DETAILS_API,
  SAVE_SWIM_PROFILE_ADD_OR_UPDATE_API,
  GET_SWIMSAFETY_PROFILE_DELETE_API,
  GET_SWIMSAFETY_PROFILES_BY_PROGRAMID_API,
  GET_SWIMSAFETY_PROFILES_BY_FILTERS_API
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSwimSafetyUplaodProfiles =
  (uploadFileData, programId) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_UPLOAD_PROFILES(programId),
      method: 'POST',
      data: uploadFileData,
      successAction: data => ({
        type: types.SWIMSAFETY_UPLOAD_PROFILES_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_PROFILES_API_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyImportProfiles = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_IMPORT_PROFILES(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.SWIMSAFETY_IMPORT_PROFILES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_PROFILES_API_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimSafetyProfileList = AssociationId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_PROFILE_LIST_API(AssociationId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_PROFILES_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_PROFILES_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSelectedBranchAndProgram = data => (dispatch, getState) =>
  dispatch({
    type: types.SWIMSAFETY_SELECTED_PROGRAM_SUCCESS,
    async: true,
    payload: data,
    state: getState()
  });

export const getSwimSafetyProfileDetails = ProfileId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMS_PROFILE_DETAILS_API(ProfileId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_PROFILE_DETAILS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_PROFILE_DETAILS_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimSafetyProfileAddORupdate =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: SAVE_SWIM_PROFILE_ADD_OR_UPDATE_API(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.SWIMSAFETY_PROFILE_SAVE_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_PROFILE_SAVE_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyProfileDelete = DeleteId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_PROFILE_DELETE_API(DeleteId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_PROFILE_DELETE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_PROFILE_DELETE_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimProfilesByProgramId = ProgramId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_PROFILES_BY_PROGRAMID_API(ProgramId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_PROFILE_BY_PROGRAM_ID_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_PROFILE_BY_PROGRAM_ID_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimSafetyProfilesByFilters =
  (startDate, endDate, Offset, ShowAll, associationNumber) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_PROFILES_BY_FILTERS_API(
        startDate,
        endDate,
        Offset,
        ShowAll,
        associationNumber
      ),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_PROFILES_BY_FILTERS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_PROFILES_BY_FILTERS_FAILURE,
        payload: error
      }),
      dispatch
    });
