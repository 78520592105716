import React from 'react';
import { CDP } from '@config/app-config';
import CDP_COLUMNS from './cdp-columns';
import ErrorSummaryColumn from './error-summary-column';

const hiddenColumns = [
  'STATE',
  'GLUCTEST',
  'GDM',
  'RISKTEST',
  'AGE',
  'DATE',
  'WEIGHT',
  'Solera OrderNumber C',
  'Payer',
  'Program',
  'Custom Grp2C',
  'Custom Grp4C',
  'Course Name',
  'Location Name',
  'Course StartDate',
  'Created By',
  'Created Date',
  'First Name',
  'Last Name',
  'Age',
  'Registration Date',
  'Attendance',
  'Attendance Complete',
  'Assiociation Number',
  'Association No.',
  'Association Name',
  'Record Id',
  'Coach Id Mapping Complete',
  'Coach 1705',
  'Npi',
  'SEX',
  'ETHNICITY',
  'EDUCATION',
  'SYSTOLIC',
  'DIASTOLIC',
  'CHAIRSTAND',
  'ARMCURL',
  'UPANDGO',
  'LEGPRESS',
  'CHESTPRESS',
  'SIXMINWALK',
  'Height',
  'Weight'
];

const ManageColumns = props => {
  const { fetchType, viewDetalsPopup, activeCard, dataSystem, programName } =
    props;

  let columns = [];
  let DataSys = '';

  const getErrorSummary = (errorKeys, errors, row) => <ErrorSummaryColumn
    errorKeys={errorKeys}
    errors={errors}
    id={row.id}
    viewDetalsPopup={viewDetalsPopup}
  />;

  const getErrorKeys = (errors) => {
    if (!errors) {
      return undefined;
    }
    const errorKeys = Object.keys(errors);
    return errorKeys.length > 3
      ? errorKeys.filter(c => c !== CDP.ErrorRowIndexField).slice(0, 3)
      : errorKeys.filter(c => c !== CDP.ErrorRowIndexField);
  }

  const redcapErrorSummary = (row) => {
    let esummary = row.original.erroR_SUMMARY_R;
    let errorSummaryDetails;
    if (programName === CDP.PROGRAM_NAMES.DPP) {
      if (row.original.erroR_SUMMARY_V) {
        esummary = row.original.erroR_SUMMARY_V;
      }
      if (row.original.erroR_SUMMARY_O) {
        esummary = row.original.erroR_SUMMARY_O;
      }
      if (row.original.erroR_SUMMARY_C) {
        esummary = row.original.erroR_SUMMARY_C;
      }
      const { errors } = JSON.parse(esummary);
      errorSummaryDetails = errors;
    } else {
      errorSummaryDetails = JSON.parse(row.original.errorSummary);
    }
    const errorKeys = getErrorKeys(errorSummaryDetails);
    return getErrorSummary(errorKeys, errorSummaryDetails, row);
  }

  const processErrorSummary = (row) => {
    const { errors } = JSON.parse(row?.original?.errorSummary);
    const errorKeys = getErrorKeys(errors);
    return getErrorSummary(errorKeys, errors, row);
  }

  if (fetchType) {
    const columnsArray = CDP_COLUMNS(viewDetalsPopup);
    if (
      programName === 'BPSM' &&
      (fetchType === CDP.DATA_SYSTEM_NAMES.HABITNU ||
        fetchType === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC ||
        fetchType === CDP.DATA_SYSTEM_NAMES.OCHI ||
        fetchType === CDP.DATA_SYSTEM_NAMES.WELLD)
    ) {
      DataSys = 'BPSM_OCHI';
    } else if (
      programName === 'BPSM' && dataSystem === CDP.DATA_SYSTEM_NAMES.REDCAP
    ) {
      DataSys = `BPSM_${fetchType}`;
    } else if (programName === 'EF' || fetchType === "ODES") {
      DataSys = `EF`;
    }
    else if (programName === 'LS') {
      DataSys = `LS`;
    }
    else if (programName === 'HW') {
      DataSys = `HW`;
    }
    else {
      DataSys = fetchType;
    }
    columns = columnsArray.find(v => Object.keys(v)[0] === DataSys)[DataSys];
    if (activeCard === '3') {
      columns = columns.filter(c => hiddenColumns.indexOf(c.Header) === -1);
      const summaryColumn = {
        Header: 'Error Summary',
        accessor: 'errorSummary',
        className: `notranslate ${programName === CDP.PROGRAM_NAMES.DPP ? 'cdp-dpp-invalid-summary' : 'cdp-other-invalid-summary'}`,
        Cell: (r) => {
          const { row } = r;
          if (dataSystem === CDP.DATA_SYSTEM_NAMES.REDCAP) {
            return redcapErrorSummary(row);
          }
          return processErrorSummary(row);
        }
      };
      columns.splice(columns.length, 0, summaryColumn);
    }
  }

  return columns;
};

export default ManageColumns;
