import React from 'react';

const AccessErrorPage = () => (
  <div className="container">
    <div className="row my-5 pt-5 justify-content-center">
      <div className="col-10">
        <div className="card ymca-primary-card d-flex align-items-center justify-content-center h-100 mt-2">
          <h2 className="ymca-error-head">
            {'Hello User,'}
            <br />
            <br />
            {
              'You do not have correct permission to access the Data Collection Hub, please contact your association local administrator.'
            }
          </h2>
        </div>
      </div>
    </div>
  </div>
);

export default AccessErrorPage;
