const ErrorColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'notranslate'
  },
  {
    Header: 'Email Id',
    accessor: 'emailAddress',
    className: 'notranslate'
  },
  {
    Header: 'Errors Found',
    accessor: 'errorSummary'
  }
];

export default ErrorColumns;
