import { STRAPI_BASE_URL } from '@config/api-config';
import axios from 'axios';

const commonAxios = axios.create({
  baseURL: STRAPI_BASE_URL(),
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});
export default commonAxios;
