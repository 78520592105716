const NewStaffColumns = [
  {
    Header: 'Branch',
    accessor: 'branchName',
    className: 'notranslate'
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'notranslate'
  },
  {
    Header: 'Staff Type',
    accessor: 'staffType',
    className: 'notranslate'
  },
  {
    Header: 'Current Unique Job Title',
    accessor: 'currentJobTitle',
    className: 'notranslate'
  },
  {
    Header: '',
    accessor: 'viewDetails',
    disableSortBy: true
  }
];

export default NewStaffColumns;
