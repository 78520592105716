import {
  GET_ASSOCIATION_DASHBOARD_SUMMARY,
  GET_ASSOCIATION_DASHBOARD_CHANGE_LOG
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getAssociationDashboardChangeLog =
  (AssociationId, startDate, endDate) => (dispatch, getState) =>
    asyncRequest({
      url: GET_ASSOCIATION_DASHBOARD_CHANGE_LOG(
        AssociationId,
        startDate,
        endDate
      ),
      successAction: data => ({
        type: types.ASSOCIATION_DASHBOARD_CHANGE_LOG_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_DASHBOARD_CHANGE_LOG_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAssociationDashboardSummary =
  (AssociationId, startDate, endDate, Offset) => (dispatch, getState) =>
    asyncRequest({
      url: GET_ASSOCIATION_DASHBOARD_SUMMARY(
        AssociationId,
        startDate,
        endDate,
        Offset
      ),
      successAction: data => ({
        type: types.ASSOCIATION_DASHBOARD_SUMMARY_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_DASHBOARD_SUMMARY_FAILURE,
        payload: error
      }),
      dispatch
    });
