import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';
import MembershipTab from './memberships';
import MembersDemographicsTab from './members-demographics';
import ProgramMemberTab from './program-member';
import ProgramEnrollmentsTab from './program-enrollments';
import VolunteersTab from './volunteers';
import DonorsTab from './donors';

const ConstituencyReportTab = props => {
  const { setLoader, status } = props;
  const [activeTab, setActiveTab] = useState('Members');
  const [showPopup, setShowPopup] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [lastActiveTab, setLastActiveTab] = useState('');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onActive = value => {
    if (value !== activeTab && sessionStorage.getItem('dirty') === 'true') {
      setShowPopup(true);
    } else {
      setActiveTab(value);
    }
    setCurrentTab(value);
    setLastActiveTab(activeTab);
  };

  const handleOk = () => {
    setActiveTab(currentTab);
    setShowPopup(false);
  };

  const handleCancel = () => {
    const activetab = lastActiveTab;
    document.getElementById(activetab).click();
    setActiveTab(activetab);
    setShowPopup(false);
  };

  return (
    <div>
      <div className="ymca-completeBox">
        <div className="ymca-directorInfo">
          <ul
            className="nav nav-tabs ymca-navTabs ymca-sixRows"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks active"
                id="Members"
                data-bs-toggle="tab"
                data-bs-target="#memberships"
                type="button"
                role="tab"
                aria-controls="memberships"
                aria-selected="true"
                onClick={() => {
                  onActive('Members');
                }}
              >
                Members
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Members Demographics"
                data-bs-toggle="tab"
                data-bs-target="#Ethnicity"
                type="button"
                role="tab"
                aria-controls="Ethnicity"
                aria-selected="false"
                onClick={() => {
                  onActive('Members Demographics');
                }}
              >
                Members Demographics
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Program Members"
                data-bs-toggle="tab"
                data-bs-target="#proggramMembers"
                type="button"
                role="tab"
                aria-controls="proggramMembers"
                aria-selected="false"
                onClick={() => {
                  onActive('Program Members');
                }}
              >
                Program Members
              </button>
            </li>
            <li
              className="nav-item ymca-navItems ymca-sixList"
              role="tab"
            >
              <button
                className="nav-link ymca-navLinks"
                id="Program Enrollments"
                data-bs-toggle="tab"
                data-bs-target="#programEnrollments"
                type="button"
                role="tab"
                aria-controls="programEnrollments"
                aria-selected="false"
                onClick={() => {
                  onActive('Program Enrollments');
                }}
              >
                Program Enrollments
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Volunteers"
                data-bs-toggle="tab"
                data-bs-target="#volunteers"
                type="button"
                role="tab"
                aria-controls="volunteers"
                aria-selected="false"
                onClick={() => {
                  onActive('Volunteers');
                }}
              >
                Volunteers
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Donors"
                data-bs-toggle="tab"
                data-bs-target="#donors"
                type="button"
                role="tab"
                aria-controls="donors"
                aria-selected="false"
                onClick={() => {
                  onActive('Donors');
                }}
              >
                Donors
              </button>
            </li>
          </ul>
          <div className="tab-content ymca-tabContent" id="myTabContent">
            <div
              className="tab-pane ymca-tabPane fade show active"
              id="memberships"
              role="tabpanel"
              aria-labelledby="Members"
            >
              {activeTab === 'Members' && (
                <MembershipTab setLoader={setLoader} status={status} />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="Ethnicity"
              role="tabpanel"
              aria-labelledby="Members Demographics"
            >
              {activeTab === 'Members Demographics' && (
                <MembersDemographicsTab setLoader={setLoader} status={status} />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="proggramMembers"
              role="tabpanel"
              aria-labelledby="Program Members"
            >
              {activeTab === 'Program Members' && (
                <ProgramMemberTab setLoader={setLoader} status={status} />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="programEnrollments"
              role="tabpanel"
              aria-labelledby="Program Enrollments"
            >
              {activeTab === 'Program Enrollments' && (
                <ProgramEnrollmentsTab setLoader={setLoader} status={status} />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPane fade"
              id="volunteers"
              role="tabpanel"
              aria-labelledby="Volunteers"
            >
              {activeTab === 'Volunteers' && (
                <VolunteersTab setLoader={setLoader} status={status} />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="donors"
              role="tabpanel"
              aria-labelledby="Donors"
            >
              {activeTab === 'Donors' && (
                <DonorsTab setLoader={setLoader} status={status} />
              )}
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        show={showPopup}
        type={APP_LABELS.WARNING}
        message="You have some unsaved changes. If you leave before saving, your
                changes will be lost."
        submitButtonName="Ok"
        onSubmit={handleOk}
        cancelButtonName="Cancel"
        onCancel={handleCancel}
      />
    </div>
  );
};

ConstituencyReportTab.propTypes = {
  setLoader: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired
};

export default React.memo(ConstituencyReportTab);
