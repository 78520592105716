/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import {
  getProgramInnovation,
  postProgramInnovation,
  resetReduxState
} from '@redux/diversity-inclusion-&-global-metrics/program-innovation/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetProgramInnovationSelector } from '@redux/diversity-inclusion-&-global-metrics/program-innovation/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import Help from '@modules/shared/components/reusable-components/help';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import TextError from '@modules/shared/components/reusable-components/text-error';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import helpicon from '@static/images/caretdoubleright.svg';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  adaintoConsideration: Yup.string().nullable(),

  flexiblePayment: Yup.string().nullable(),
  underservedYmca: Yup.string().nullable(),
  diverseAbilities: Yup.string().nullable(),
  nonEnglishSpeakers: Yup.string().nullable(),
  amongParticipants: Yup.string().nullable(),

  yconsideredAda: Yup.string().nullable(),
  flexiblePaymentDescription: Yup.string().nullable(),
  personsFromGroupsDescription: Yup.string().nullable(),
  personsWithDiverseDescription: Yup.string().nullable(),
  nonEnglishSpeakersDescription: Yup.string().nullable(),
  globalAwarenessDescription: Yup.string().nullable()
});

const ProgramInnovationTab = props => {
  const {
    handleGetProgramInnovation,
    handleResetRedux,
    handlePostProgramInnovation,
    GetProgramInnovationSelectorData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Diversity Inclusion & Global Metrics';

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [help, setHelp] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [resStatus, setResStatus] = useState('');
  const formikref = useRef();
  const [yconsideredAdaCount, setYconsideredAdaCount] = useState(
    GetProgramInnovationSelectorData?.data?.yconsideredAda?.length || 0
  );
  const [flexiblePaymentDescriptionCount, setFlexiblePaymentDescriptionCount] =
    useState(
      GetProgramInnovationSelectorData?.data?.flexiblePaymentDescription
        ?.length || 0
    );
  const [
    personsFromGroupsDescriptionCount,
    setPersonsFromGroupsDescriptionCount
  ] = useState(
    GetProgramInnovationSelectorData?.data?.personsFromGroupsDescription
      ?.length || 0
  );
  const [
    personsWithDiverseDescriptionCount,
    setPersonsWithDiverseDescriptionCount
  ] = useState(
    GetProgramInnovationSelectorData?.data?.personsWithDiverseDescription
      ?.length || 0
  );
  const [
    nonEnglishSpeakersDescriptionCount,
    setNonEnglishSpeakersDescriptionCount
  ] = useState(
    GetProgramInnovationSelectorData?.data?.nonEnglishSpeakersDescription
      ?.length || 0
  );
  const [globalAwarenessDescriptionCount, setGlobalAwarenessDescriptionCount] =
    useState(
      GetProgramInnovationSelectorData?.data?.globalAwarenessDescription
        ?.length || 0
    );

  const [yconsideredAda, setYconsideredAda] = useState(
    GetProgramInnovationSelectorData?.data?.yconsideredAda
  );
  const [flexiblePaymentDescription, setFlexiblePaymentDescription] = useState(
    GetProgramInnovationSelectorData?.data?.flexiblePaymentDescription
  );
  const [personsFromGroupsDescription, setPersonsFromGroupsDescription] =
    useState(
      GetProgramInnovationSelectorData?.data?.personsFromGroupsDescription
    );
  const [personsWithDiverseDescription, setPersonsWithDiverseDescription] =
    useState(
      GetProgramInnovationSelectorData?.data?.personsWithDiverseDescription
    );
  const [nonEnglishSpeakersDescription, setNonEnglishSpeakersDescription] =
    useState(
      GetProgramInnovationSelectorData?.data?.nonEnglishSpeakersDescription
    );
  const [globalAwarenessDescription, setGlobalAwarenessDescription] = useState(
    GetProgramInnovationSelectorData?.data?.globalAwarenessDescription
  );

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = () => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetProgramInnovation(associationsNum).then(res => {
      if (res.status === 200) {
          setFlexiblePaymentDescriptionCount(res?.data?.data?.flexiblePaymentDescription?.length || 0);
          setFlexiblePaymentDescription(res?.data?.data?.flexiblePaymentDescription);
          setPersonsFromGroupsDescriptionCount(res?.data?.data?.personsFromGroupsDescription?.length || 0);
          setPersonsFromGroupsDescription(res?.data?.data?.personsFromGroupsDescription);
          setPersonsWithDiverseDescriptionCount(res?.data?.data?.personsWithDiverseDescription?.length || 0);
          setPersonsWithDiverseDescription(res?.data?.data?.personsWithDiverseDescription);
          setNonEnglishSpeakersDescriptionCount(res?.data?.data?.nonEnglishSpeakersDescription?.length || 0);
          setNonEnglishSpeakersDescription(res?.data?.data?.nonEnglishSpeakersDescription);
          setGlobalAwarenessDescriptionCount(res?.data?.data?.globalAwarenessDescription?.length || 0);
          setGlobalAwarenessDescription(res?.data?.data?.globalAwarenessDescription);
          setYconsideredAdaCount(res?.data?.data?.yconsideredAda?.length || 0);
          setYconsideredAda(res?.data?.data?.yconsideredAda);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'program-innovation'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetProgramInnovation');
    };
  }, []);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const initialValues = {
    adaintoConsideration: '',

    flexiblePayment: '',
    underservedYmca: '',
    diverseAbilities: '',
    nonEnglishSpeakers: '',
    amongParticipants: ''
  };

  const editValues = {
    adaintoConsideration: `${GetProgramInnovationSelectorData?.data?.adaintoConsideration}`,

    flexiblePayment: GetProgramInnovationSelectorData?.data?.flexiblePayment[0],
    underservedYmca: GetProgramInnovationSelectorData?.data?.underservedYmca[0],
    diverseAbilities:
      GetProgramInnovationSelectorData?.data?.diverseAbilities[0],
    nonEnglishSpeakers:
      GetProgramInnovationSelectorData?.data?.nonEnglishSpeakers[0],
    amongParticipants:
      GetProgramInnovationSelectorData?.data?.amongParticipants[0]
  };
  const convertedValue = value =>
    value === 'null' ? null : value.toLowerCase() === 'true';

  const getAssociationNumber = () => {
    const userAssociations =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');

    return String(userAssociations);
  };

  const preparePayloadBody = values => {
    const basePayload = {
      associationNumber: getAssociationNumber(),
      createdByName: GetProgramInnovationSelectorData?.data?.createdByName,
      createdByPersonId:
        GetProgramInnovationSelectorData?.data?.createdByPersonId,
      createdDatetime: GetProgramInnovationSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      programInnovationId:
        GetProgramInnovationSelectorData?.data?.programInnovationId,
      reportBatchId: GetProgramInnovationSelectorData?.data?.reportBatchId,
      recordStatus: GetProgramInnovationSelectorData?.data?.recordStatus,
      flexiblePayment: [values.flexiblePayment],
      underservedYmca: [values.underservedYmca],
      diverseAbilities: [values.diverseAbilities],
      nonEnglishSpeakers: [values.nonEnglishSpeakers],
      amongParticipants: [values.amongParticipants],
      flexiblePaymentDescription,
      personsFromGroupsDescription,
      personsWithDiverseDescription,
      nonEnglishSpeakersDescription,
      globalAwarenessDescription
    };
    if(values?.adaintoConsideration==='false'){
      setYconsideredAda('')
    }
    if (selectedYear >= '2023') {
      return {
        ...basePayload,
        adaintoConsideration:
          values?.adaintoConsideration === 'null' && yconsideredAda != null
            ? true
            : convertedValue(values?.adaintoConsideration),
        yconsideredAda:
          values?.adaintoConsideration === 'false' ? null : yconsideredAda
      };
    }
    return {
      ...basePayload,
      adaintoConsideration:
        values.adaintoConsideration.toLowerCase() === 'true',
      yconsideredAda
    };
  };

  const handleSubmission = async payloadBody => {
    try {
      const res = await handlePostProgramInnovation(payloadBody);

      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(
          getAssociationNumber(),
          sessionStorage.getItem('selectedBatchYear')
        ).then(dashData => {
          dashData?.data?.data?.dashboardDTOs.forEach(record => {
            if (
              record.name === sectionName &&
              record.status === 'Completed' &&
              record.rating === null &&
              selectedYear >= '2023' &&
              ![
                APP_LABELS.YUSA_DATA_STEWARD,
                APP_LABELS.YESS_DATA_STEWARD
              ].includes(role)
            ) {
              setFeedbackPopup(true);
              setFormId(record?.formId);
            }
          });
        });
        handelGetCall();
        setLoader(false);
        setSuccessPopup(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    } catch (error) {
      setLoader(false);
      setErrorMessage('An error occurred during submission.');
      setErrorPopup(true);
    }
  };

  const onSubmit = values => {
    setLoader(true);

    const payloadBody = preparePayloadBody(values);
    setSuccessPopup(false);

    handleSubmission(payloadBody);
  };

  const handelCount = (value, textareaName) => {
    if (textareaName === 'flexiblePaymentDescription') {
      setFlexiblePaymentDescriptionCount(value?.length || 0);
      setFlexiblePaymentDescription(value);
    } else if (textareaName === 'personsFromGroupsDescription') {
      setPersonsFromGroupsDescriptionCount(value?.length || 0);
      setPersonsFromGroupsDescription(value);
    } else if (textareaName === 'personsWithDiverseDescription') {
      setPersonsWithDiverseDescriptionCount(value?.length || 0);
      setPersonsWithDiverseDescription(value);
    } else if (textareaName === 'nonEnglishSpeakersDescription') {
      setNonEnglishSpeakersDescriptionCount(value?.length || 0);
      setNonEnglishSpeakersDescription(value);
    } else if (textareaName === 'globalAwarenessDescription') {
      setGlobalAwarenessDescriptionCount(value?.length || 0);
      setGlobalAwarenessDescription(value);
    } else if (textareaName === 'yconsideredAda') {
      setYconsideredAdaCount(value?.length || 0);
      setYconsideredAda(value);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ dirty, errors, setFieldValue, values }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-covidRelif">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <p className="ymca-instructTitle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: instructions
                        }}
                      />
                    </p>
                  </div>
                </div>
                {GetProgramInnovationSelectorData?.data?.recordStatus?.toUpperCase() ===
                  APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                <h4 className="ymca-headingTitle txt-mandatory">
                  Our Y takes the American With Disabilities Act (ADA) into
                  consideration when developing our facilities and programs?
                </h4>
                <div className="ymca-radioBTN d-flex">
                  <div className="ymca-setListing me-5">
                    <Field
                      type="radio"
                      name="adaintoConsideration"
                      value="true"
                      className="ymca-fieldRadio"
                    />
                    <span className="ymca-texting">Yes</span>
                  </div>
                  <div className="ymca-setListing">
                    <Field
                      type="radio"
                      name="adaintoConsideration"
                      value="false"
                      className="ymca-fieldRadio"
                      onChange={e => {
                        setFieldValue('adaintoConsideration', e.target.value);
                      }}
                    />
                    <span className="ymca-texting">No</span>
                  </div>
                </div>
                <ErrorMessage
                  component={TextError}
                  name="adaintoConsideration"
                />
                {((values.adaintoConsideration === 'true' ||
                  values.adaintoConsideration === 'null') &&
                  selectedYear >= '2023') ||
                selectedYear === '2022' ? (
                  <div>
                    <h4
                      className={
                        selectedYear >= '2023'
                          ? 'ymca-headingTitle txt-mandatory'
                          : 'ymca-headingTitle'
                      }
                    >
                      Please provide an example of how your Y considered ADA.
                    </h4>
                    <textarea
                      name="yconsideredAda"
                      className="ymca-areaTextWidth"
                      maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                      value={yconsideredAda}
                      defaultValue={
                        GetProgramInnovationSelectorData?.data?.yconsideredAda
                      }
                      onChange={e =>
                        handelCount(e.target.value, 'yconsideredAda')
                      }
                    />
                    <div className="row">
                      <div className="col-10" />
                      <div className="col-2 text-end ymca-textarea-count">
                        <p>{`(${yconsideredAdaCount}/8000)`}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <h4 className="ymca-headingTitle">
                  {
                    'Our Y has processes to develop and adapt programs, services, and program policies to ensure that all segments of society have access to the Y (i.e., newcomer/immigrant, low-income, diverse abilities,  interfaith, military/veterans, LGBTQ+, and non-English speaking communities) within the following:'
                  }
                </h4>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th>Fully implemented process</th>
                        <th>Process underway</th>
                        <th>Process not started</th>
                        <th>No plan for implementation</th>
                        <th>Our Y has not identified a need in this area</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody">
                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Flexible payment (and registration) options available
                          for program or membership participation
                          <ErrorMessage
                            component={TextError}
                            name="flexiblePayment"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="flexiblePayment"
                              id="flexiblePaymentFullyImplementedProcess"
                              type="radio"
                              className="styled-checkbox"
                              value="flexiblePaymentFullyImplementedProcess"
                            />
                            <label htmlFor="flexiblePaymentFullyImplementedProcess" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="flexiblePayment"
                              id="flexiblePaymentProcessUnderway"
                              type="radio"
                              className="styled-checkbox"
                              value="flexiblePaymentProcessUnderway"
                            />
                            <label htmlFor="flexiblePaymentProcessUnderway" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="flexiblePayment"
                              id="flexiblePaymentProcessNotStarted"
                              type="radio"
                              className="styled-checkbox"
                              value="flexiblePaymentProcessNotStarted"
                            />
                            <label htmlFor="flexiblePaymentProcessNotStarted" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="flexiblePayment"
                              id="flexiblePaymentNoPlanForImplementation"
                              type="radio"
                              className="styled-checkbox"
                              value="flexiblePaymentNoPlanForImplementation"
                            />
                            <label htmlFor="flexiblePaymentNoPlanForImplementation" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="flexiblePayment"
                              id="flexiblePaymentYnotIdentified"
                              type="radio"
                              className="styled-checkbox"
                              value="flexiblePaymentYnotIdentified"
                            />
                            <label htmlFor="flexiblePaymentYnotIdentified" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Program options provided for persons from groups who
                          are underserved (by the YMCA) (e.g., Interfaith
                          communities,) military/veterans, LGBTQ+, etc.)
                          <ErrorMessage
                            component={TextError}
                            name="underservedYmca"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="underservedYmca"
                              id="ymcaFullyImplementedProcess"
                              type="radio"
                              className="styled-checkbox"
                              value="ymcaFullyImplementedProcess"
                            />
                            <label htmlFor="ymcaFullyImplementedProcess" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="underservedYmca"
                              id="ymcaProcessUnderway"
                              type="radio"
                              className="styled-checkbox"
                              value="ymcaProcessUnderway"
                            />
                            <label htmlFor="ymcaProcessUnderway" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="underservedYmca"
                              id="ymcaProcessNotStarted"
                              type="radio"
                              className="styled-checkbox"
                              value="ymcaProcessNotStarted"
                            />
                            <label htmlFor="ymcaProcessNotStarted" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="underservedYmca"
                              id="ymcaNoPlanForImplementation"
                              type="radio"
                              className="styled-checkbox"
                              value="ymcaNoPlanForImplementation"
                            />
                            <label htmlFor="ymcaNoPlanForImplementation" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="underservedYmca"
                              id="ymcaYnotIdentified"
                              type="radio"
                              className="styled-checkbox"
                              value="ymcaYnotIdentified"
                            />
                            <label htmlFor="ymcaYnotIdentified" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Program options provided for persons with diverse
                          abilities (e.g., individuals with physical, cognitive,
                          and/or psychological disabilities)
                          <ErrorMessage
                            component={TextError}
                            name="diverseAbilities"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="diverseAbilities"
                              id="diverseAbilitiesFullyImplementedProcess"
                              type="radio"
                              className="styled-checkbox"
                              value="diverseAbilitiesFullyImplementedProcess"
                            />
                            <label htmlFor="diverseAbilitiesFullyImplementedProcess" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="diverseAbilities"
                              id="diverseAbilitiesProcessUnderway"
                              type="radio"
                              className="styled-checkbox"
                              value="diverseAbilitiesProcessUnderway"
                            />
                            <label htmlFor="diverseAbilitiesProcessUnderway" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="diverseAbilities"
                              id="diverseAbilitiesProcessNotStarted"
                              type="radio"
                              className="styled-checkbox"
                              value="diverseAbilitiesProcessNotStarted"
                            />
                            <label htmlFor="diverseAbilitiesProcessNotStarted" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="diverseAbilities"
                              id="diverseAbilitiesNoPlanForImplementation"
                              type="radio"
                              className="styled-checkbox"
                              value="diverseAbilitiesNoPlanForImplementation"
                            />
                            <label htmlFor="diverseAbilitiesNoPlanForImplementation" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="diverseAbilities"
                              id="diverseAbilitiesYnotIdentified"
                              type="radio"
                              className="styled-checkbox"
                              value="diverseAbilitiesYnotIdentified"
                            />
                            <label htmlFor="diverseAbilitiesYnotIdentified" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Program options provided for newcomers, immigrants
                          and/or non-English speakers
                          <ErrorMessage
                            component={TextError}
                            name="nonEnglishSpeakers"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="nonEnglishSpeakers"
                              id="nonEnglishSpeakersFullyImplementedProcess"
                              type="radio"
                              className="styled-checkbox"
                              value="nonEnglishSpeakersFullyImplementedProcess"
                            />
                            <label htmlFor="nonEnglishSpeakersFullyImplementedProcess" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="nonEnglishSpeakers"
                              id="nonEnglishSpeakersProcessUnderway"
                              type="radio"
                              className="styled-checkbox"
                              value="nonEnglishSpeakersProcessUnderway"
                            />
                            <label htmlFor="nonEnglishSpeakersProcessUnderway" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="nonEnglishSpeakers"
                              id="nonEnglishSpeakersProcessNotStarted"
                              type="radio"
                              className="styled-checkbox"
                              value="nonEnglishSpeakersProcessNotStarted"
                            />
                            <label htmlFor="nonEnglishSpeakersProcessNotStarted" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="nonEnglishSpeakers"
                              id="nonEnglishSpeakersNoPlanForImplementation"
                              type="radio"
                              className="styled-checkbox"
                              value="nonEnglishSpeakersNoPlanForImplementation"
                            />
                            <label htmlFor="nonEnglishSpeakersNoPlanForImplementation" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="nonEnglishSpeakers"
                              id="nonEnglishSpeakersYnotIdentified"
                              type="radio"
                              className="styled-checkbox"
                              value="nonEnglishSpeakersYnotIdentified"
                            />
                            <label htmlFor="nonEnglishSpeakersYnotIdentified" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Programs that intentionally develop cultural
                          competencies, empathy, and/or global awareness among
                          participants
                          <ErrorMessage
                            component={TextError}
                            name="amongParticipants"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="amongParticipants"
                              id="amongParticipantsFullyImplementedProcess"
                              type="radio"
                              className="styled-checkbox"
                              value="amongParticipantsFullyImplementedProcess"
                            />
                            <label htmlFor="amongParticipantsFullyImplementedProcess" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="amongParticipants"
                              id="amongParticipantsProcessUnderway"
                              type="radio"
                              className="styled-checkbox"
                              value="amongParticipantsProcessUnderway"
                            />
                            <label htmlFor="amongParticipantsProcessUnderway" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="amongParticipants"
                              id="amongParticipantsProcessNotStarted"
                              type="radio"
                              className="styled-checkbox"
                              value="amongParticipantsProcessNotStarted"
                            />
                            <label htmlFor="amongParticipantsProcessNotStarted" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="amongParticipants"
                              id="amongParticipantsNoPlanForImplementation"
                              type="radio"
                              className="styled-checkbox"
                              value="amongParticipantsNoPlanForImplementation"
                            />
                            <label htmlFor="amongParticipantsNoPlanForImplementation" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="amongParticipants"
                              id="amongParticipantsYnotIdentified"
                              type="radio"
                              className="styled-checkbox"
                              value="amongParticipantsYnotIdentified"
                            />
                            <label htmlFor="amongParticipantsYnotIdentified" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  Please provide a brief description of Flexible payment options
                  offered by your Y.
                </h4>
                <textarea
                  name="flexiblePaymentDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={flexiblePaymentDescription}
                  defaultValue={
                    GetProgramInnovationSelectorData?.data
                      ?.flexiblePaymentDescription
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'flexiblePaymentDescription')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${flexiblePaymentDescriptionCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle">
                  Please provide a brief description of Program options provided
                  for persons from groups who are underserved offered by your Y.
                </h4>
                <textarea
                  name="personsFromGroupsDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={personsFromGroupsDescription}
                  defaultValue={
                    GetProgramInnovationSelectorData?.data
                      ?.personsFromGroupsDescription
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'personsFromGroupsDescription')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${personsFromGroupsDescriptionCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle">
                  Please provide a brief description of Program options for
                  persons with diverse abilities offered by your Y.
                </h4>
                <textarea
                  name="personsWithDiverseDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={personsWithDiverseDescription}
                  defaultValue={
                    GetProgramInnovationSelectorData?.data
                      ?.personsWithDiverseDescription
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'personsWithDiverseDescription')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${personsWithDiverseDescriptionCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle">
                  Please provide a brief description of Program options provided
                  for newcomers, immigrants and /or non-English speakers offered
                  by your Y.
                </h4>
                <textarea
                  name="nonEnglishSpeakersDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={nonEnglishSpeakersDescription}
                  defaultValue={
                    GetProgramInnovationSelectorData?.data
                      ?.nonEnglishSpeakersDescription
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'nonEnglishSpeakersDescription')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${nonEnglishSpeakersDescriptionCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle">
                  Please provide a brief description of Programs that
                  intentionally develop cultural competencies, empathy, and/or
                  global awareness among participants offered by your Y.
                </h4>
                <textarea
                  name="globalAwarenessDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={globalAwarenessDescription}
                  defaultValue={
                    GetProgramInnovationSelectorData?.data
                      ?.globalAwarenessDescription
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'globalAwarenessDescription')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${globalAwarenessDescriptionCount}/8000)`}</p>
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={
                    GetProgramInnovationSelectorData?.data?.updatedByName
                  }
                  Time={GetProgramInnovationSelectorData?.data?.updatedDatetime}
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
      {help && <Help hidePopup={hideShowHelp} keyValue="program-innovation" />}
    </div>
  );
};

ProgramInnovationTab.propTypes = {
  handleGetProgramInnovation: PropTypes.func.isRequired,
  handlePostProgramInnovation: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetProgramInnovationSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetProgramInnovation: getProgramInnovation,
  handlePostProgramInnovation: postProgramInnovation,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetProgramInnovationSelectorData: GetProgramInnovationSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ProgramInnovationTab));
