/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import get from 'lodash.get';
import { connect } from 'react-redux';
import {
  getAssociationDashboardSummary,
  getAssociationDashboardChangeLog
} from '@redux/association-dashboard/actions';
import {
  getAssociationNumber,
  getAssociationMoypChangeLog
} from '@redux/dashboard/actions';
import { AssociationMoypChangeLogSelector } from '@redux/dashboard/selectors';
import {
  AssociationDashboardSummarySelector,
  AssociationDashboardChangeLogSelector
} from '@redux/association-dashboard/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, DATE_ARRAY } from '@config/app-config';
import moment from 'moment';
import OrangeIconVr from '@static/images/orange-icon-vr.png';
import UsersWhiteIcon from '@static/images/users-white-icon.svg';
import warningiconmedium from '@static/images/warning-icon-medium.svg';
import BuildingIconVoilet from '@static/images/building-icon-voilet.svg';
import BuildingIconWhite from '@static/images/building-icon-white.svg';
import UploadsBlueIcon from '@static/images/uploads-blue-icon.svg';
import UploadsWhiteIcon from '@static/images/uploads-white-icon.svg';
import BlueUserIcon from '@static/images/blue-user-icon.png';
import UploadPurpleIcon from '@static/images/upload-purple-icon.png';
import UploadGreenIcon from '@static/images/upload-green-icon.svg';
import UploadWhiteGreenIcon from '@static/images/upload-white-green-icon.svg';
import successtickicon from '@static/images/success-tick-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import Table from './Table';
import COLUMNS from './columns';
import VIEWONLYCOLUMNS from './viewonlycolumns';
import MOYPCOLUMNS from '../dashboard/moyp_columns';
import VIEWMOYPCOLUMNS from './viewonlymoypcolumns';

const AssociationDashboard = props => {
  const {
    LoginUserData,
    handleAssociationSummary,
    handleAssociationChangeLog,
    AssociationSummaryData,
    AssociationChangeLogData,
    location,
    handleAssociationNumber,
    handleAssociationMoypChangeLog,
    AssociationMoypChangeData
  } = props;
  const [loader, setLoader] = useState(false);
  const { state } = location;
  const [columnData, setColumnData] = useState('changelogdetails');
  const [selectedRange, setSelectedRange] = useState(90);
  const defaultStartDate = moment().subtract(90, 'd').format('MM-DD-YYYY');
  const defaultEndDate = moment().format('MM-DD-YYYY');
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const Offset = new Date().getTimezoneOffset();
  const [summarySuccess, setSummarySuccess] = useState(false);
  const [changeLogSuccess, setChangeLogSuccess] = useState(false);
  const [associationDetailSuccess, setAssociationDetailSuccess] =
    useState(true);
  const filterDate = days => {
    if (days === 'Custom') {
      setStartDate('');
      setEndDate('');
    } else if (days === '1') {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
    } else {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(defaultEndDate);
    }
  };
  const getEmployeeListByDateRange = (startDateValue, endDateValue) => {
    setLoader(true);
    setColumnData('changelogdetails');
    const association = get(JSON.parse(sessionStorage.getItem('yusa_selected_associate')),'associationNumber')
    const startdate = startDateValue
      ? moment(startDateValue).format('MM-DD-YYYY')
      : defaultStartDate;
    const enddate = endDateValue
      ? moment(endDateValue).format('MM-DD-YYYY')
      : defaultEndDate;
    setSummarySuccess(false);
    handleAssociationSummary(association, startdate, enddate, Offset).then(
      res => {
        if (res) {
          setSummarySuccess(true);
        }
      }
    );
    setChangeLogSuccess(false);
    handleAssociationChangeLog(association, startdate, enddate).then(res => {
      if (res) {
        setChangeLogSuccess(true);
      }
    });
  };
  useEffect(() => {
    if (summarySuccess && changeLogSuccess && associationDetailSuccess) {
      setLoader(false);
    }
  }, [summarySuccess, changeLogSuccess, associationDetailSuccess]);

  useEffect(() => {
    state?.fromDashboard===true&& sessionStorage.setItem('yusa_selected_associate', JSON.stringify(state?.data));
    setLoader(true);
    window.scrollTo(0, 0);
    getEmployeeListByDateRange(startDate, endDate);
    setAssociationDetailSuccess(false);
    handleAssociationNumber(get(JSON.parse(sessionStorage.getItem('yusa_selected_associate')),'associationNumber')).then(res => {
      if (res) {
        setAssociationDetailSuccess(true);
      }
    });
  }, []);

  const newAssociationChangeLogData = AssociationChangeLogData?.data?.map(
    data => {
      const successMeg = (
        <span className="ymca-success-text">
          <img src={successtickicon} alt="" className="img-fluid success-img" />
          {'Success'}
        </span>
      );
      const faildMeg = (
        <span className="ymca-warning-text">
          <img
            src={warningiconmedium}
            alt=""
            className="img-fluid success-img"
          />
          {'Failed'}
        </span>
      );

      const location1 = {
        pathname: '/employee/dashboard/detailView',
        state: data
      };

      return {
        BranchName: get(data, 'branchName', '-'),
        UploadedBy: get(data, 'uploadedBy', '-'),
        UploadedDateTime: convertUtcToLocal(get(data, 'uploadedDateTime')),
        SourceSystem: get(data, 'sourceSystem', '-'),
        Summary: get(data, 'summary', '-'),
        Status: get(data, 'status', '-') ? successMeg : faildMeg,
        DetailPage: (
          <Link to={location1} className="table-link">
            View Details
          </Link>
        )
      };
    }
  );
  const newAssociationMoypChangeLogData = AssociationMoypChangeData?.data?.map(
    data => {
      const successMeg = (
        <span className="ymca-success-text">
          <img src={successtickicon} alt="" className="img-fluid success-img" />
          {APP_LABELS.DASH_SUCCESS}
        </span>
      );
      const faildMeg = (
        <span className="ymca-warning-text">
          <img
            src={warningiconmedium}
            alt=""
            className="img-fluid success-img"
          />
          {APP_LABELS.DASH_FAILED}
        </span>
      );

      const location1 = {
        pathname: '/employee/moyp/detailView',
        state: data
      };

      return {
        UploadDate: get(data, 'lastUpdatedDate')
          ? moment(get(data, 'lastUpdatedDate')).format('D MMMM YYYY')
          : '-',
        SourceSystem: get(data, 'sourceSystem', '-'),
        Summary: get(data, 'summary', '-')
          .split(',')
          .map(item => <div key={item}>{item}</div>),
        Status: get(data, 'status', '-') ? successMeg : faildMeg,
        DetailPage: (
          <Link to={location1} className="table-link">
            {APP_LABELS.DASH_VIEW_DETAILS}
          </Link>
        )
      };
    }
  );
  const getMoypChangeLog = (startDateValue, endDateValue) => {
    setColumnData('moypchangelogdetails');
    setLoader(true);
    const association = get(JSON.parse(sessionStorage.getItem('yusa_selected_associate')),'associationNumber')
    const startdate = startDateValue
      ? moment(startDateValue).format('MM-DD-YYYY')
      : defaultStartDate;
    const enddate = endDateValue
      ? moment(endDateValue).format('MM-DD-YYYY')
      : defaultEndDate;
    handleAssociationMoypChangeLog(
      association,
      startdate,
      enddate,
      Offset
    ).then(() => {
      setLoader(false);
    });
  };
  const getChangeLogdetails = (startdate, enddate) => {
    setLoader(true);
    const association = get(JSON.parse(sessionStorage.getItem('yusa_selected_associate')),'associationNumber');
    handleAssociationChangeLog(association, startdate, enddate).then(() => {
      setLoader(false);
    });
  };
  const getLastupDatedDate = () => {
    const lastUploadDatetime = get(
      AssociationSummaryData,
      'data.lastUploadDatetime'
    );
    const moypLastUpdatedDatetime = get(
      AssociationSummaryData,
      'data.moypLastUpdatedDatetime'
    );

    let renderedDate = '---';

    if (new Date(lastUploadDatetime) > new Date(moypLastUpdatedDatetime)) {
      renderedDate = convertUtcToLocal(lastUploadDatetime);
    } else if (moypLastUpdatedDatetime) {
      renderedDate = moment(moypLastUpdatedDatetime).format(
        'DD MMM YYYY hh:mm a'
      );
    }
    return renderedDate;
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper dashboard-wrapper">
          <div className="container">
            <section className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <div className="user-name custom-username">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>Welcome!</h6>
                        <h2 className="ymca-sub-heading mb-0">
                          {`${get(
                            LoginUserData,
                            'user.firstName',
                            'User'
                          )} ${get(LoginUserData, 'user.lastName', '')}`}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
                  {get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YUSA_DATA_VIEWER &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.ALLIANCE_DATA_STEWARD ? (
                    <Link
                      className="btn ymca-primary-btn login-btn custom-align"
                      type="button"
                      to="/employee/dashboard/upload/file"
                    >
                      {APP_LABELS.DASH_UPLOAD_DATA}
                    </Link>
                  ) : (
                    <Link
                      className="ymca-title-link d-flex justify-content-end"
                      to="/employee/dashboard"
                    >
                      <img src={BackIcon} className="me-2" alt="" />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  )}
                </div>
              </div>
            </section>
            <div className=" mt-5">
              <h2 className="ymca-section-heading  mb-4">
                {APP_LABELS.EMPLOYEE_DATA_COLLECTION}
              </h2>
              <div className="row mb-2">
                <div className="col-lg-3 ymca-label ">
                  {' '}
                  <span className="txt-mandatory"> Range Selection</span>
                  <select
                    className="fieldSetSelect mt-1"
                    value={selectedRange}
                    onChange={e => {
                      setSelectedRange(e.target.value);
                      filterDate(e.target.value);
                    }}
                    defaultValue={90}
                  >
                    {DATE_ARRAY.map(items => (
                      <option key={items.id} value={items.value}>
                        {items.name}
                      </option>
                    ))}
                  </select>
                  <span className="ps-4 fs-14">Or</span>
                </div>

                <div className="col-lg-auto ymca-label pe-0">
                  <span className="txt-mandatory"> From Date</span>
                  <div>
                    <input
                      type="date"
                      onChange={e => {
                        setStartDate(e.target.value);
                        setEndDate('');
                      }}
                      value={startDate}
                      disabled={selectedRange !== APP_LABELS.CUSTOM}
                      onKeyDown={e => {
                        e.preventDefault();
                      }}
                      className={`${
                        selectedRange === APP_LABELS.CUSTOM
                          ? ''
                          : 'cursor-not-allowed ymca-disabled-section'
                      } fieldSet mt-1`}
                      min={moment(new Date('1/1/1901 12:00:00')).format(
                        'YYYY-MM-DD'
                      )}
                      max={moment(new Date())
                        .subtract(1, 'd')
                        .format('YYYY-MM-DD')}
                    />
                  </div>
                </div>
                <div className="col-lg-2 ymca-label">
                  <span className="txt-mandatory"> To Date</span>
                  <div>
                    <input
                      type="date"
                      onChange={e => {
                        setEndDate(e.target.value);
                      }}
                      onKeyDown={e => {
                        e.preventDefault();
                      }}
                      disabled={selectedRange !== APP_LABELS.CUSTOM || startDate === ''}
                      className={`${
                        selectedRange !== APP_LABELS.CUSTOM || startDate === ''
                          ? 'cursor-not-allowed ymca-disabled-section'
                          : ''
                      } fieldSet mt-1`}
                      value={endDate}
                      min={
                        selectedRange === APP_LABELS.CUSTOM
                          ? moment(new Date(startDate))
                              .add(1, 'days')
                              .format('YYYY-MM-DD')
                          : ''
                      }
                      max={
                        moment(new Date(startDate))
                          .add(2, 'Y')
                          .format('YYYY-MM-DD') <
                        moment(new Date()).format('YYYY-MM-DD')
                          ? moment(new Date(startDate))
                              .add(2, 'Y')
                              .format('YYYY-MM-DD')
                          : moment(new Date()).format('YYYY-MM-DD')
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-1 mt-1 ps-0">
                  <button
                    type="button"
                    onClick={() => {
                      getEmployeeListByDateRange(startDate, endDate);
                    }}
                    disabled={
                      (startDate === '' || endDate === '') &&
                      selectedRange === APP_LABELS.CUSTOM
                    }
                    className="btn ymca-primary-btn apply-btn custom-align"
                  >
                    Apply
                  </button>
                </div>
                <div className="col-lg-1 mt-1">
                  <button
                    type="button"
                    onClick={() => {
                      setStartDate(defaultStartDate);
                      setEndDate(defaultEndDate);
                      setSelectedRange(90);
                      getEmployeeListByDateRange(
                        defaultStartDate,
                        defaultEndDate
                      );
                    }}
                    className="btn  ymc-outlineBtn apply-btn custom-align"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <section className="stats-data">
              <div className="row ">
                <div className="col-lg-auto col-md-6 col-sm-12">
                  <div className="card stats-card branches min-width">
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={BuildingIconVoilet}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={BuildingIconWhite}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {get(
                            AssociationSummaryData,
                            'data.noOFBranches',
                            '0'
                          )}
                        </h4>
                        <p>No.of Branches</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12">
                  <div className="card stats-card associations min-width">
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={BlueUserIcon}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={UsersWhiteIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {get(
                            AssociationSummaryData,
                            'data.activeEmployees',
                            '0'
                          )}
                        </h4>
                        <p>Active Employees</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12 ">
                  <div
                    aria-hidden="true"
                    onClick={() => {
                      setColumnData('changelogdetails');
                      getChangeLogdetails(startDate, endDate);
                    }}
                    className={`card stats-card staff-assign c-pointer ${
                      columnData === 'changelogdetails' ? 'active' : ''
                    } c-pointer `}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={OrangeIconVr}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={UploadsWhiteIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {get(
                            AssociationSummaryData,
                            'data.noOFEmployees',
                            '0'
                          )}
                        </h4>
                        <p>No.of Employees updated </p>
                        <p> in Hub</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12 ">
                  <div
                    aria-hidden="true"
                    onClick={() => getMoypChangeLog(startDate, endDate)}
                    className={`card stats-card programs ${
                      columnData === 'moypchangelogdetails' ? 'active' : ''
                    } c-pointer`}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={UploadsBlueIcon}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={UploadsWhiteIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {get(
                            AssociationSummaryData,
                            'data.moypTotalUpdates',
                            '0'
                          )}
                        </h4>
                        <p>MOYP Updates</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12">
                  <div
                    className={`${
                      new Date(
                        get(AssociationSummaryData, 'data.lastUploadDatetime')
                      ) <
                      new Date(
                        get(
                          AssociationSummaryData,
                          'data.moypLastUpdatedDatetime',
                          ''
                        )
                      )
                        ? ' stats-card upload-green'
                        : 'stats-card min-width upload-purple'
                    } card `}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={
                            new Date(
                              get(
                                AssociationSummaryData,
                                'data.lastUploadDatetime'
                              )
                            ) <
                            new Date(
                              get(
                                AssociationSummaryData,
                                'data.moypLastUpdatedDatetime',
                                ''
                              )
                            )
                              ? UploadGreenIcon
                              : UploadPurpleIcon
                          }
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={UploadWhiteGreenIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>{getLastupDatedDate()}</h4>
                        <p>
                          {' '}
                          {new Date(
                            get(
                              AssociationSummaryData,
                              'data.lastUploadDatetime'
                            )
                          ) >
                          new Date(
                            get(
                              AssociationSummaryData,
                              'data.moypLastUpdatedDatetime',
                              ''
                            )
                          )
                            ? 'Last Updated in Hub'
                            : 'Last Updated in MOYP'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {columnData === 'changelogdetails' ? (
            <Table
              rows={newAssociationChangeLogData}
              headers={
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD
                  ? VIEWONLYCOLUMNS
                  : COLUMNS
              }
            />
          ) : (
            <Table
              rows={newAssociationMoypChangeLogData}
              headers={
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD
                  ? VIEWMOYPCOLUMNS
                  : MOYPCOLUMNS
              }
            />
          )}
        </div>
      </main>
    </BlockUi>
  );
};

AssociationDashboard.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationSummaryData: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationChangeLogData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAssociationSummary: PropTypes.func.isRequired,
  handleAssociationChangeLog: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAssociationNumber: PropTypes.func.isRequired,
  handleAssociationMoypChangeLog: PropTypes.func.isRequired,
  AssociationMoypChangeData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleAssociationSummary: getAssociationDashboardSummary,
  handleAssociationChangeLog: getAssociationDashboardChangeLog,
  handleAssociationNumber: getAssociationNumber,
  handleAssociationMoypChangeLog: getAssociationMoypChangeLog
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationSummaryData: AssociationDashboardSummarySelector(state),
  AssociationChangeLogData: AssociationDashboardChangeLogSelector(state),
  AssociationMoypChangeData: AssociationMoypChangeLogSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociationDashboard);
