import * as types from './types';

const initialState = {
  UserManagementTableList: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_MANAGEMENT_TABLE_SUCCESS: {
      return {
        ...state,
        UserManagementTableList: action.payload
      };
    }
    case types.USER_MANAGEMENT_TABLE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
