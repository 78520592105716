import {
  LEADERSHIP_STAFF_DEVELOPMENT_GET_URL,
  LEADERSHIP_STAFF_DEVELOPMENT_POST_URL
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getLeadershipStaffDevelopment =
  associationsNum => (dispatch, getState) =>
    asyncRequest({
      url: LEADERSHIP_STAFF_DEVELOPMENT_GET_URL(associationsNum),
      successAction: data => ({
        type: types.GET_LEADERSHIP_STAFF_DEVELOPMENT_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_LEADERSHIP_STAFF_DEVELOPMENT_FAILURE,
        payload: error
      }),
      dispatch
    });

export const postLeadershipStaffDevelopment =
  payloadBody => (dispatch, getState) =>
    asyncRequest({
      url: LEADERSHIP_STAFF_DEVELOPMENT_POST_URL(),
      method: 'POST',
      data: payloadBody,
      successAction: data => ({
        type: types.POST_LEADERSHIP_STAFF_DEVELOPMENT_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.POST_LEADERSHIP_STAFF_DEVELOPMENT_FAILURE,
        payload: error
      }),
      dispatch
    });
export const resetReduxState = key => ({
  type: 'RESET_KEY',
  payload: { key }
});
