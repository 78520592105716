import React, { useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy
} from 'react-table';
import PropTypes from 'prop-types';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import { GET_EXPORT_EMPLOYEE_YMCA_ASSOCIATIONS } from '@config/api-config';
import axios from 'axios';
import SearchIcon from '@static/images/search-icon.svg';
import PreviousIcon from '@static/images/previous-icon.svg';
import NextIcon from '@static/images/Next-icon.svg';
import SortUpIcon from '@static/images/sorting-icon-up.svg';
import SortDownIcon from '@static/images/sorting-icon-down.svg';

const Table = props => {
  const { rows, headers, setLoader, startDate, endDate, includeAllYs } = props;
  const columns = useMemo(() => headers, [headers]);
  const data = useMemo(() => rows, [rows]);
  const { LoginUserData } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      defaultCanSort: true,
      initialState: {
        // Use your own data structure and add as many columns that need sorting by default
        sortBy: [{ uploadedDateTime: data.uploadedDateTime, desc: true }],
        pageSize: 25
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;
  const authToken = JSON.parse(sessionStorage.getItem('token'));
  const Offset = new Date().getTimezoneOffset();
  const handleExportFile = () => {
    setLoader(true);
    axios
      .get(
        GET_EXPORT_EMPLOYEE_YMCA_ASSOCIATIONS(
          startDate,
          endDate,
          includeAllYs,
          Offset
        ),
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'blob',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': window.location.origin,
            'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
            Authorization: `Bearer ${get(authToken, 'token', '')}`
          }
        }
      )
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'YMCA-Associations.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  const getShortButton = column => {
    let sortButton;

    if (column.isSorted) {
      if (column.isSortedDesc) {
        sortButton = (
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortUpIcon} className="img-fluid" alt="sorting-icon" />
          </button>
        );
      } else {
        sortButton = (
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortDownIcon} className="img-fluid" alt="sorting-icon" />
          </button>
        );
      }
    } else if (
      column.Header !== 'Summary' &&
      column.Header !== 'Status' &&
      column.Header !== ''
    ) {
      sortButton = (
        <>
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortUpIcon} className="img-fluid" alt="sorting-icon-up" />
          </button>
          <button type="button" className="btn btn-link p-0 custom-align">
            <img
              src={SortDownIcon}
              className="img-fluid"
              alt="sorting-icon-down"
            />
          </button>
        </>
      );
    } else {
      sortButton = null;
    }
    return sortButton;
  };
  return (
    <section className="dashboard-list">
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`${
              get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.YUSA_DATA_STEWARD ||
              get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.YUSA_DATA_VIEWER ||
              get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.ALLIANCE_DATA_STEWARD ||
              get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.YESS_DATA_STEWARD
                ? 'col-lg-6'
                : 'col-lg-8'
            }`}
          >
            <h2 className="ymca-section-heading">
              {get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.YUSA_DATA_STEWARD ||
              get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.YUSA_DATA_VIEWER ||
              get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.ALLIANCE_DATA_STEWARD ||
              get(LoginUserData, 'user.role[0]') ===
                APP_LABELS.YESS_DATA_STEWARD
                ? 'YMCA Associations'
                : 'Change Log Details'}
            </h2>
          </div>
          <div className="col-lg-4">
            <div className="search-form-wrapper">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={SearchIcon} alt="SearchIcon" />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control search-form-control"
                  placeholder="Search for a log...."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={globalFilter}
                  onChange={e => setGlobalFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          {get(LoginUserData, 'user.role[0]') ===
            APP_LABELS.YUSA_DATA_STEWARD ||
          get(LoginUserData, 'user.role[0]') === APP_LABELS.YUSA_DATA_VIEWER ||
          get(LoginUserData, 'user.role[0]') ===
            APP_LABELS.ALLIANCE_DATA_STEWARD ||
          get(LoginUserData, 'user.role[0]') ===
            APP_LABELS.YESS_DATA_STEWARD ? (
            <div className="col-lg-2 col-md-6 col-sm-6 ps-0">
              {' '}
              <button
                className="btn ymca-primary-btn export-btn mb-2 custom-align"
                type="button"
                onClick={handleExportFile}
              >
                {' '}
                Export
              </button>{' '}
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive custom-scrollbar">
              <table
                className="table ymca-table ymca-tableHeight"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups?.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map(column => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <div className="sorting-icon-wrapper ms-2">
                            {getShortButton(column)}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page?.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row?.cells?.map(cell => (
                          <td
                            {...cell.getCellProps()}
                            className={`${cell.column.className ?? ''}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {page.length === 0 && (
                <div className="text-center py-3 fw-bold ymca-warning-text">
                  No records found!
                </div>
              )}
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation" className="ymca-pagination">
          <ul className="pagination justify-content-center">
            <li className="page-item ">
              <button
                type="button"
                className="page-link custom-align"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img src={PreviousIcon} className="pe-1" alt="" />
                {'Previous'}
              </button>
            </li>

            {[...Array(pageCount).keys()].map(count => {
              if (
                count >= (pageIndex > 25 ? pageIndex - 25 : 0) &&
                count <= (pageIndex < 25 ? 24 : pageIndex)
              ) {
                return (
                  <li className="page-item notranslate" key={count}>
                    <button
                      type="button"
                      className={`page-link custom-align ${
                        count === pageIndex && 'active'
                      }`}
                      onClick={() => gotoPage(count)}
                    >
                      {count + 1}
                    </button>
                  </li>
                );
              }
              return null;
            })}

            <li className="page-item">
              <button
                type="button"
                className="page-link custom-align"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'Next '}
                <img src={NextIcon} className="pl-1 ms-1" alt="" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
  headers: PropTypes.arrayOf(PropTypes.any)
};

Table.defaultProps = {
  rows: [],
  headers: []
};
Table.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  includeAllYs: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
export default connect(mapStateToProps, null)(Table);
