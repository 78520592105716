/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  getARemployeeUpdateApi,
  PostARemployeeUpdateApi
} from '@redux/annualReport-employeeUpdate/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import ARemployeeDataSelector from '@redux/annualReport-employeeUpdate/selectors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { APP_LABELS } from '@config/app-config';
import Help from '@modules/shared/components/reusable-components/help';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import helpicon from '@static/images/caretdoubleright.svg';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const EmployeesTab = props => {
  const {
    LoginUserData,
    handleGetARemployeeUpdateApi,
    handlePostARemployeeUpdateApi,
    setLoader,
    status,
    handleGetAnnualReportsDashboard,
    EmployeeUpdateData
  } = props;
  const selectedYear = sessionStorage.getItem('selectedBatchYear');

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [employeeData, setEmployeeData] = useState(null);
  const [resStatus, setResStatus] = useState();
  const [help, setHelp] = useState(false);

  const sectionName = 'Employee';

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const defaultInitialValues = {
    totMoypstaffCount: ''
  };

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  let getEmployeeUpdateData;

  const formik = useFormik({
    initialValues: employeeData || defaultInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      totMoypstaffCount: Yup.string().nullable()
    }),
    onSubmit: () => {
      setSuccessPopup(false);
      // eslint-disable-next-line no-use-before-define
      postAPICall();
    }
  });

  const convertedValue = value => (value === 0 || value ? value : null);

  const postAPICall = () => {
    setLoader(true);
    const object = {
      ...formik?.values,
      totMoypstaffCount: convertedValue(formik?.values?.totMoypstaffCount)
    };
    handlePostARemployeeUpdateApi(object)
      .then(res => {
        if (res?.data?.isSuccess) {
          setResStatus(res.data.data?.recordStatus);
        }
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        setLoader(false);
        if (res?.data?.isSuccess) {
          getEmployeeUpdateData(false, true);
        } else {
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  getEmployeeUpdateData = (loader, success) => {
    if (loader) setLoader(true);
    handleGetARemployeeUpdateApi(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    )
      .then(result => {
        const resultData = result?.data?.data;
        setEmployeeData(resultData);
        if (loader) setLoader(false);
        if (result?.data?.isSuccess && result?.status === 200) {
          if (success) {
            setSuccessPopup(true);
          }
        } else {
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        if (loader) setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const [instructions, setInstructions] = useState('');

  useEffect(() => {
    formik.resetForm({ values: defaultInitialValues });
    window.scroll(0, 0);
    getEmployeeUpdateData(true);

    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.employee
        ?.Instruction
    );
    window.scroll(0, 0);
    getEmployeeUpdateData(true);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('dirty', formik.dirty);
  }, [formik.dirty]);

  const handleStaffCountChange = e => {
    const result = e.target.value.replace(/\D/g, '');
    formik.setFieldValue('totMoypstaffCount', result);
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <form onSubmit={formik.handleSubmit}>
        <div className="ymca-completeBox">
          <div className="ymca-program mb-4">
            <div className="row">
              {/* instructions box  */}
              <div className="ymca-sorroundings pt-0">
                <h4 className="ymca-informationSiteOff">Employee Details</h4>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <p className="ymca-instructTitle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: instructions
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              {EmployeeUpdateData?.data?.recordStatus?.toUpperCase() ===
                APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}

              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="ymca-selectWrap">
                  <div className="ymca-programActivities txt-mandatory">
                    {APP_LABELS.AR_EMPLOYEE_HEAD_COUNT_TEXT}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-12 col-sm-12 col-12">
                <div className="ymca-selectWrap">
                  <input
                    type="text"
                    className="ymca-fieldSet"
                    name="totMoypstaffCount"
                    onChange={handleStaffCountChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.totMoypstaffCount}
                    maxLength={9}
                  />
                  {formik.touched.totMoypstaffCount &&
                  formik.errors.totMoypstaffCount ? (
                    <div className="errorNote">
                      {formik.errors.totMoypstaffCount}
                    </div>
                  ) : null}
                </div>
              </div>

              <h4 className="ymca-programActivities ms-0">
                {APP_LABELS.AR_EMPLOYEE_FOLLOWING}
              </h4>

              <p className="ymca-textTitle mt-2 mb-1">
                {APP_LABELS.AR_EMPLOYEE_LOCAL_Y}
              </p>

              <p className="ymca-textTitle mb-3">
                {`Local Y agrees to obtain `}
                &nbsp;
                <a
                  href="https://apim-datacollectionhub-prod-01.azure-api.net/Strapi-cms/uploads/Employee_Data_Sharing_Consent_Form_0c9e6f98ff.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  consent form
                </a>
                &nbsp;
                {APP_LABELS.AR_EMPLOYEE_ITS_EMPLOYEE}
              </p>

              <p className="ymca-textTitle mt-3 mb-3">
                {APP_LABELS.AR_EMPLOYEE_YUSA}
              </p>
            </div>
          </div>

          <div className="ymca-submitHighlighter">
            <LastUpdateName
              Username={formik.values.updatedByName}
              Time={formik.values.updatedDatetime}
            />
          </div>
        </div>

        {role !== APP_LABELS.YUSA_DATA_VIEWER &&
          role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
            <button
              type="submit"
              className="btn ymca-savingBTN ymca-btnRightTitle float-end"
              disabled={status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED}
            >
              Save
            </button>
          )}
      </form>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      {help && <Help hidePopup={hideShowHelp} keyValue="employee" />}
    </div>
  );
};

EmployeesTab.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetARemployeeUpdateApi: PropTypes.func.isRequired,
  handlePostARemployeeUpdateApi: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  EmployeeUpdateData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  EmployeeUpdateData: ARemployeeDataSelector(state)
});

const mapDispatchToProps = {
  handleGetARemployeeUpdateApi: getARemployeeUpdateApi,
  handlePostARemployeeUpdateApi: PostARemployeeUpdateApi,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(EmployeesTab));
