import * as types from './types';

const initialState = {
  FetchFileTemplateURL: {},
  FetchFileTemplate: {},
  UploadFile: {},
  UploadFileDropdown: {},
  TermsAndConditions: {},
  PostTermsAndConditions: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FILE_TEMPLATE_URL_SUCCESS: {
      return {
        ...state,
        FetchFileTemplateURL: action.payload
      };
    }
    case types.FETCH_FILE_TEMPLATE_URL_FAILURE:
      return { ...state, error: action.payload };

    case types.FETCH_FILE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        FetchFileTemplate: action.payload
      };
    }
    case types.FETCH_FILE_TEMPLATE_FAILURE:
      return { ...state, error: action.payload };

    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        UploadFile: action.payload
      };
    }
    case types.UPLOAD_FILE_FAILURE:
      return { ...state, error: action.payload };

    case types.UPLOAD_FILE_DROPDOWN_SUCCESS: {
      return {
        ...state,
        UploadFileDropdown: action.payload
      };
    }
    case types.UPLOAD_FILE_DROPDOWN_FAILURE:
      return { ...state, error: action.payload };
    case types.GET_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        TermsAndConditions: action.payload
      };
    }
    case types.GET_TERMS_AND_CONDITIONS_FAILURE:
      return { ...state, error: action.payload };
    case types.POST_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        PostTermsAndConditions: action.payload
      };
    }
    case types.POST_TERMS_AND_CONDITIONS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
