// App Config
module.exports = {
  PORT: process.env.PORT,
  HOST: process.env.HOST || 'localhost',
  __DEV__: process.env.NODE_ENV === 'development',
  __TEST__: process.env.NODE_ENV === 'test',
  REDUX_LOGGER_ENABLED: true,
  MOCK_MODE: null, // success | failure | false
  MOCK_RESPONSE_DELAY: 1, // (ms) // 1000
  API_ENDPOINTS: {
    DEV: process.env.REACT_APP_AZURE_DEV_API_BASE_URI,
    STAG: process.env.REACT_APP_AZURE_STAGE_API_BASE_URI,
    PRODCUCTION: process.env.REACT_APP_AZURE_PROD_API_BASE_URI
  },
  REGEX: {
    NUMERIC: /^[0-9]+$/,
    LEADING_SLASH: /^\/+/,
    ALL_SLASHES: /\//g,
    ALPHABETS: /^[A-Za-z ']+$/,
    ALPHABETSWITHHYPHEN: /^[A-Za-z ]+(?:-[a-zA-Z ]+)*$/,
    alphanumeric: /^[a-zA-Z0-9]+$/,
    ALPHANUMERICWITHSPACES: /^[a-zA-Z0-9 ]+$/,
    ALPHANUMERICWITHSPACESANDHYPHEN: /^[a-zA-Z0-9 ]+(?:-[a-zA-Z0-9 ]+)*$/,
    ZIP: /^((\d{5}(?:[-]\d{4})?$)|(\d{9}))*$/g,
    PHONE: /^(1\s?)?(\d{3}|\(\d{3}\))[-]?\d{3}[-]?\d{4}$/g,
    PAGE_DATE_FORMAT: 'MMM DD, YYYY, hh:mma',
    DATE_FORMAT_FOR_DOCUMENT: 'MMM DD, YYYY | hh:mm a',
    EXPIRY_DATE_FORMAT: 'MMM DD, YYYY'
  },
  VALIDATION_MESSAGES: {
    FIELD_REQUIRED: '* Field is required.',
    RADIO_BUTTON: '* Please select any option.',
    RADIO_BUTTON_STAFF: '* Please select any option from Staff.',
    RADIO_BUTTON_VOLUNTEERS: '* Please select any option from Volunteers.',
    TEXT_AREA_MIN: '* Please enter more than 5 characters.',
    SET_OF_CHECKBOX: '* Please select minimum one option.',
    PROGRAM_ENROLLMENTS:
      '* Duplicated value should always be equal to or greater than Unduplicated value',
    FILE_UPLOAD: '* Please upload document.',
    MAX_FILE_SIZE: '* File size must be less than 2 MB',
    INSURENCE_MAX_FILE_SIZE: '* File size must be less than 750 kb',
    INSURENCE_UPLOAD_VALID_FILE:
      '* Please upload file with .pdf/.doc/.docx extension.',
    UPLOAD_VALID_FILE: '* Please upload file with .jpeg/.png/.pdf extension.',
    FILE_UPLOAD_OR_REQUEST_BS:
      '* Please upload the document or select the request background screening option.'
  },
  FIELD_LENGTH: {
    TEXTAREA_LENGTH: '8000',
    TEXTBOX_LENGTH: '9',
    VOLUNTEER_TEXTBOX: '255',
    EMAIL_LENGTH: '100',
    ADDRESS_LENGTH: '500'
  },

  APP_LABELS: {
    EMPLOYEE_WARNING_TEXT: 'Are you sure you want to separate the employment of all part-time staff?',
    MAX_UPLOAD_FILE_ERROR_MESSAGE:
      'This File size is more than 10MB. Please reduce the file size by removing empty rows.',
    APIM_KEY: process.env.REACT_APIM_SUBSCRIPTION_KEY,
    DEV_INSTANCE_URL: process.env.REACT_APP_AZURE_DEV_INSTANCE_URL,
    DEV_TENANT: process.env.REACT_APP_AZURE_DEV_TENANT,
    DEV_CLIENT_ID: process.env.REACT_APP_AZURE_DEV_CLIENT_ID,
    DEV_REDIRECT_URI: process.env.REACT_APP_AZURE_DEV_REDIRECT_URI,
    DEV_LOGOUT_REDIRECT_URI:
      process.env.REACT_APP_AZURE_DEV_LOGOUT_REDIRECT_URI,
    STAGE_INSTANCE_URL: process.env.REACT_APP_AZURE_STAGE_INSTANCE_URL,
    STAGE_TENANT: process.env.REACT_APP_AZURE_STAGE_TENANT,
    STAGE_CLIENT_ID: process.env.REACT_APP_AZURE_STAGE_CLIENT_ID,
    STAGE_REDIRECT_URI: process.env.REACT_APP_AZURE_STAGE_REDIRECT_URI,
    STAGE_LOGOUT_REDIRECT_URI:
      process.env.REACT_APP_AZURE_STAGE_LOGOUT_REDIRECT_URI,
    PROD_INSTANCE_URL: process.env.REACT_APP_AZURE_PROD_INSTANCE_URL,
    PROD_TENANT: process.env.REACT_APP_AZURE_PROD_TENANT,
    PROD_CLIENT_ID: process.env.REACT_APP_AZURE_PROD_CLIENT_ID,
    PROD_REDIRECT_URI: process.env.REACT_APP_AZURE_PROD_REDIRECT_URI,
    PROD_LOGOUT_REDIRECT_URI:
      process.env.REACT_APP_AZURE_PROD_LOGOUT_REDIRECT_URI,
    SIGNUP_POLICY: process.env.REACT_APP_AZURE_SIGNUP_POLICY,
    CACHE_STORAGE: process.env.REACT_APP_AZURE_CACHE_STORAGE,
    LOADER_MESSAGE: 'Loading, please wait',
    TOGETHER_WE: 'TOGETHER WE',
    CAN_BUILD_A: 'CAN BUILD A ',
    BETTER_US: 'BETTER US',
    WELCOME_TO: 'Welcome to',
    LOGIN_PLATFORM: 'YMCA Data Collection Hub',
    GET_STARTED: 'Get Started',
    TOP_HEADING: 'YMCA OF THE USA',
    EMPLOYEE_DATA_COLLECTION: 'Employee Data Collection',
    ANNUAL_REPORTS: 'Annual Reports',
    CDP_TITLE: 'Chronic Disease Program',
    SWIM_TITLE: 'Safety Around Water Program',
    INSURANCE_TITLE: 'Certificate of Liability Insurance',
    CAMP_HEADER: 'Employee Data Collection',
    ANNUAL_REPORT_HEADER: 'Employee Data Collection',
    NAV_HOME: 'Home',
    NAV_DASHBOARD: 'Dashboard',
    NAV_LOGOUT: 'Logout',
    NAV_ACTIVE: 'active',
    WELCOME: 'Welcome!',
    QUICK_INFO: 'Quick Information',
    DATA_EXCHANGE: 'Data Exchange',
    START_NOW: 'START NOW',
    VIEW: 'VIEW',
    DASH_ASSOCIATE_NAME: 'Associate Name',
    DASH_BACK: 'Back',
    DASH_UPLOAD_DATA: '+ Upload New Data',
    DASH_VIEW_DETAILS: 'View Details',
    DASH_SUCCESS: 'Success',
    DASH_FAILED: 'Failed',
    DETAIL_TERMINATED: 'Separated Staff Role',
    DETAIL_ASSIGNMENT: 'Assignment',
    DETAIL_UPLOAD_DATA: 'Upload Data',
    UPLOAD_EMPLOYEE_DATA: 'Upload Employee Data',
    UPLOAD_USER_MANAGEMENT_DATA: 'Upload User Data',
    DETAIL_NEW_STAFF: 'New Staff',
    DETAIL_TOTAL_STAFF: 'Total Staff',
    DETAIL_NO_RECORD_FOUND: 'No records found!',
    DETAIL_EXISTING_STAFF: 'Un-Changed Staff',
    DETAIL_CHANGED_STAFF: 'Changed Staff',
    DETAIL_TERMINATED_STAFF: 'Terminated Staff Role Assignment',
    DETAIL_COMMENTS: 'Comments',
    DETAIL_POPUP_TITLE: 'popupTitle',
    DETAIL_ASSOCIATION_DETAIL: 'Association Details',
    DETAIL_BRANCH_NAME: 'Branch Name',
    DETAIL_PERSONAL_DETAILS: 'Personal Details',
    DETAIL_FIRST_NAME: 'First Name',
    DETAIL_MIDDLE_NAME: 'Middle Name',
    DETAIL_LAST_NAME: 'Last Name',
    DETAIL_BIRTH_DATE: 'Birth date',
    DETAIL_RACE: 'Race/Ethnicity',
    DETAIL_GENDER: 'Gender',
    DETAIL_ADDRESS: 'Address',
    DETAIL_ADDRESS2: 'Address2',
    DETAIL_CITY: 'City',
    DETAIL_STATE: 'State',
    DETAIL_RECORD_TYPE: 'Record Type',
    DETAIL_STAFF_DETAILS: 'Staff Details',
    DETAIL_BRAND_ADVOCATE: 'Brand Advocate',
    DETAIL_STAFF_TYPE: 'Staff type',
    DETAIL_CURRENT_SALARY: 'Current Salary',
    DETAIL_CURRENT_SALARY_START_DATE: 'Current Pay Start Date',
    DETAIL_CURRENT_POSITION: 'Current Position Role',
    UPLOAD_DATA_WARNING_TEXT: 'Unchecked record(s) will not be imported',
    UPLOAD_DATA_CANCEL: 'Cancel',
    UPLOAD_DATA_IMPORT_DATA: 'Import Data',
    UPLOAD_DATA_OK: 'OK',
    SAVE_DATA: 'Save and Continue',
    UPLOAD_DATA_CURRENT_POSITION: 'Current Position Leadership Level',
    UPLOAD_DATA_CURRENT_POSITION_PRIMARY: 'Current Position Primary Function',
    UPLOAD_DATA_ERROR: 'Error!',
    UPLOAD_DATA_CONFRIM: 'I confirm to the changes in system.',
    UPLOAD_DATA_RECORDS:
      ' To proceed further, make sure you fix invalid records in file.',
    UPLOAD_DATA_INVALID: 'Error Records',
    UPLOAD_DATA_TERMINATED: 'Separated Staff',
    LOG_DETAIL_YMCA: 'YMCA Associations',
    LOGS_DETAIL_LOG: 'Logs',
    LOGS_DETAIL_YMCA_SOUTHCOAST: 'YMCA Southcoast',
    LOGS_DETAIL_CONTACT_EMAIL: 'Contact Email',
    LOGS_DETAIL_CONTACT_PERSON: 'Contact Person',
    LOGS_DETAIL_CONTACT_NUMBER: 'Contact Number',
    LOG_DETAIL_LOG: 'Log Details',
    LOG_DETAIL_BRANCH: 'Branch Name',
    LOG_DETAIL_UPLOADED: 'Uploaded By',
    LOG_DETAIL_UPLOAD: 'Upload Date & Time',
    LOG_DETAIL_SUMMARY: 'Summary',
    LOG_DETAIL_STATUS: 'Status',
    LOG_DETAIL_SHOWING: 'Showing 1 to 10 of 22 entries',
    LOG_DETAIL_FIRST: 'First',
    LOG_DETAIL_PREVIOUS: 'Previous',
    LOG_DETAIL_NEXT: 'Next',
    LOG_DETAIL_LAST: 'Last',
    FOOTER_COPYRIGHT: 'Copyright © 2022. YMCA of the USA. All rights reserved.',
    FOOTER_YMCA: 'YMCA',
    UPLOAD_FILE_ATTENTION: 'Attention!',
    UPLOAD_FILE_ALL_EXISTING:
      'All existing data in system will be wiped and new',
    UPLOAD_FILE_DATA: 'data will be stored as per the uploaded file.',
    UPLOAD_FILE_CONTINUE_TO_DOWNLOAD: 'Continue to Download',
    UPLOAD_FILE_CONTINUE: 'Continue',
    UPLOAD_FILE_BRANCH_LIST: 'Branch List :',
    UPLOAD_FILE_ASSOCIATION: 'Association :',
    UPLOAD_FILE_CONFIRMATION: 'Confirmation!',
    UPLOAD_FILE_PLEASE_WAIT: 'Please Wait!',
    UPLOAD_FILE_HEADING:
      'This will take a couple of seconds, please bear with us!',
    UPLOAD_FILE_ASSOCIATIONS: 'Association',
    UPLOAD_FILE_BRANCH: 'Branch',
    UPLOAD_FILE_FETCH_DATA: 'Download Existing Data',
    UPLOAD_FILE_EMPLOYEE_DATA: 'Employee Data',
    UPLOAD_FILE_DOWNLOAD: 'Download',
    UPLOAD_FILE_TO_RECIEVE:
      'To recieve the latest data from MOYP, download this file, edit and upload.',
    UPLOAD_FILE_UPLOADING_DATA_VIA:
      ' Uploading data via this template will expire all previous data and store only the new data.',
    UPLOAD_DOWNLOAD_FILE: 'Download Blank Template',
    LAST_UPDATED_BY: 'Last Updated By',
    ANNUAL_REPORT_SITE_INFORMATION_HEADER: 'Site Information',
    UPLOAD_DOWNLOAD: 'Download',
    ERROR_MESSAGE: 'Error occured while processing request.',
    LOGIN_PAGE_MESSAGE: `A new and improved platform for submitting data to Y-USA!`,
    UPLOAD_EMPLOYEE_DATA_DETAILS: `In libero augue, eleifend eget pharetra et, posuere vitae
    odio. Proin pharetra dolor nec dignissim fermentum. Praesent
    pretium tempor nisl at porta. Phasellus sit amet eros
    facilisis, fringilla justo id, sodales tortor.`,
    STEP_1_TEXT: ` Step 2 : Download existing data from MOYP or Download blank
    template`,
    FETCH_DATA_WARNING: ` Uploading data via this template will expire all
    previous data and store only the new data.`,
    STEP_2_TEXT: ` Step 3 : Upload the existing data file for employee
    update.`,
    STEP_3_TEXT: `Step 1 : Please review the following statements regarding uploading your employee data`,
    UPLOAD_DATA_WARNING: ` *Associations are require to use same selection as
    per the fetched data.`,
    UPLOAD_DATA_TITLE: `Employee Update Review `,
    FEEDBACK_SUPPORT: `Feedback & Support`,
    SOMETHING_WENT_WRONG: 'Something went wrong. Please try after some time.',
    REVIEW_REDIRECTING_TO_DASHBOARD: 'Successfully submitted annual reports.',
    COMMON_ERROR_MESSAGE:
      'An error has occurred while processing your request, please try again!',
    YUSA_DATA_STEWARD: 'YUSA-Data-Steward',
    YESS_DATA_STEWARD: 'YESS-Data-Steward',
    YUSA_DATA_VIEWER: 'YUSA-Data-Viewer',
    ALLIANCE_DATA_STEWARD: 'Alliance-Data-Steward',
    CVO_CONTACT_DETAILS: 'CVO Contact Details',
    INCOMING_CVO_CONTACT_DETAILS: 'Incoming CVO Contact Details',
    VIEWER_PERMISSION_ROLES: ['Alliance-Data-Steward'],
    COI_PERMISSION_ROLES: ['YUSA-Data-Viewer'],
    CEO_DATA_STEWARD: 'CEO-Data-Steward',
    CVO_DATA_STEWARD: 'CVO-Data-Steward',
    BRANCH_DATA_STEWARD: 'Branch-Data-Steward',
    ASSOCIATION_DATA_STEWARD: 'Association-Data-Steward',
    YMCA_DIRECTORY_INFO: 'YMCA Directory Information',
    SITE_INFORMATION: 'Site Information',
    EMPLOYEES: 'Employees',
    EMPLOYEE: 'Employee',
    CONSTITUENCY_REPORT: 'Constituency Report',
    DIVERSITY: 'Diversity, Inclusion and Global Metrics',
    DIVERSITYMETRICS: 'Diversity, Inclusion & Global Metrics',
    DIVERSITY_METRICS: 'Diversity Inclusion & Global Metrics',
    COLLABORATIONS: 'Collaborations',
    PROGRAM_ACTIVITIES: 'Program and Activities',
    PLEDGE_STATEMENT: 'Pledge Statement',
    PLEDGE: 'Pledge',
    REVIEW_SUBMIT: 'Review & Submit',
    INSTRUCTIONS: 'Instructions',
    NONE_OF_THE_BELOW_APPLY: 'None of the below apply',
    INFORMATION: 'Information',
    SUCCESS: 'Success',
    ERROR: 'Error',
    WARNING: 'Warning!',
    CLOSE: 'Close',
    STATUS_YET_TO_BEGIN: 'YET TO BEGIN',
    STATUS_YETTOBEGIN: 'YETTOBEGIN',
    STATUS_COMPLETED: 'COMPLETED',
    STATUS_FAILED: 'FAILED',
    STATUS_INPROGRESS: 'INPROGRESS',
    STATUS_IN_PROGRESS: 'IN PROGRESS',
    STATUS_NOT_STARTED: 'NOT STARTED',
    STATUS_SUBMITTED: 'SUBMITTED',
    STATUS_PARTIAL: 'PARTIAL',
    REOPEN_STATUS: 'Report successfully Re-opened.',
    OK: 'OK',
    REVIEW_SUBMIT_CHECKBOX_TEXT:
      'I have checked and verified all the above information, to the best of my knowledge.',
    REVIEW_SUBMIT_SUBMITTED_TEXT:
      'Annual Reports data is already submitted and data is locked.',
    REVIEW_SUBMIT_ALERT_INFO:
      'Please make sure the all information is correct before submitting the Annual Report.',
    AR_EMPLOYEE_HEAD_COUNT_TEXT:
      'Enter the basic head count of all staff (full-time exempt, full-time nonexempt, part-time, and seasonal/temporary staff members) employed on a given day during the reporting year. Use whatever date you had your highest number of employees include all employees across the entire association, including all staff at the corporate office, branch locations, and program sites.',
    AR_EMPLOYEE_FOLLOWING:
      'Please review the following statements regarding your Annual Report submission',
    AR_EMPLOYEE_LOCAL_Y:
      'Local Y will share data with Y-USA to create Movement-wide insights to Y staff trends, workforce diversity and leadership pipeline.',
    AR_EMPLOYEE_YUSA:
      'Y-USA agrees to use the data provided by Local Y to understand Movement-wide insights to Y staff trends, to improvement strategies related to workplace diversity and leadership pipeline in compliance with applicable privacy and security laws and regulations.',
    AR_EMPLOYEE_ITS_EMPLOYEE:
      'its employees to collect, use, and disclose their data to Y-USA for the purposes of Movement-wide insights to staff trends, workforce diversity and leadership pipeline in compliance with applicable privacy and security laws and regulations.',
    USER_MANAGEMENT: 'User Management',
    SWIM_SAFETY: 'Swim Safety',
    SWIM_PROGRAM: 'Swim Program',
    ADD_SWIM_PROGRAM: 'Add Swim Program',
    EDIT_SWIM_PROGRAM: 'Edit Swim Program',
    SWIM_PROGRAM_DETAILS: 'Swim Program Details',
    PROGRAM_DETAILS: 'Program Details',
    PROGRAM_NAME: 'Program Name',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    INSTRUCTOR_NAME: 'Instructor Name',
    ADD_ASSOCIATION: 'Add Association',
    ADD_BRANCH: 'Add Branch',
    ADD_PROFILE: 'Add Profile',
    EDIT_PROFILE: 'Edit Profile',
    SAVE: 'Save',
    UPDATE: 'Update',
    ADD_BRANCH_TEXT: 'Click on Add Branch button to add a branch',
    ASSOCIATION_NAME: 'Association Name',
    BRANCH: 'Branch',
    ASSOCIATION_SUCCESS_MESSAGE: 'Association added successfully!',
    BRANCH_SUCCESS_MESSAGE: 'Branch(es) added successfully!',
    DOWNLOAD_TEMPLATE_SUCCESS: 'Your file has been downloaded successfully.',
    SWIMPROGRAM_SUCCESS_MESSAGE: 'Swim program saved successfully!',
    SWIMPROGRAM_UPDATED_MESSAGE: 'Swim program updated successfully!',
    SWIMPROFILE_SUCCESS_MESSAGE: 'Swim profile saved successfully!',
    SWIMPROFILE_UPDATED_MESSAGE: 'Swim profile Updated successfully!',
    INITIATE_MESSAGE:
      'Would you like to initiate a background screening for this volunteer?',
    CANCEL_INITIATE_MESSAGE:
      'Would you like to cancel the initiated background screening for this volunteer?',
    INITIATE_SUCCESS_MESSAGE: 'Background check process may initiate for you.',
    CANCEL_INITIATED_SUCCESS_MESSAGE: 'Background screening request cancelled successfully!',
    INITIATE_INFO_MESSAGE:
      'If you have requested a background check to be initiated, please look for an email from backgroundcheck@praesidiuminc.com. Please check your junk/spam inbox if you have not received an email to complete this process within four business days of submitting your documentation to YUSA.',
    CUSTOM: 'Custom'
  },
  ENUMS: {
    IS_SWIMMING_REGISTRATION: 'isSwimmingRegistration',
    IS_GYMNASTICS_REGISTRATION: 'isGymnasticsRegistration',
    IS_PICKLE_BALL_REGISTRATION: 'isPickleballRegistration',
    IS_DIVING_REGISTRATION: 'isDivingRegistration',
    IS_CERTIFIED_SWIM_OFFICIAL: 'isCertifiedSwimOfficial',
    BACKGROUND_SCREENING: 'BackgroundScreening',
    ATHLETE_PROTECTION_TRAINING: 'AthleteProtectionTraining',
    SWIM_OFFICIAL_CERTIFICATION: 'SwimOfficialCertification',
    VOLUNTEER_ATTESTATION: 'VolunteerAttestation',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    PENDING: 'Pending',
    INITIATED: 'Initiated',
    SUCCESS: 'Success',
    DENIED: 'Denied',
    NEW: 'New',
    UNDER_REVIEW: 'Under Review',
    SUBMITTED: 'Submitted',
    NEW_REGISTER: 'New Register',
    VOLUNTEER_REGISTRATION: 'Volunteer Registration',
    VOLUNTEER: 'Volunteer',
    YUSA_ADMIN: 'YUSA Admin'
  },
  MESSAGES: {
    CDP_FETCH_LOADING: 'Fetching data, this might take a while...!',
    CDP_UPLOAD_LOADING: 'Uploading data, this might take a while...!',
    DEFAULT_LOADING_MESSAGE: 'Loading, please wait'
  },
  CDP: {
    DASHBOARD: {
      inProgress: 'Uploaded',
      success: 'Imported',
      error: 'Rejected',
      hippaExpiry: 'Your HIPAA Certification has expired, and you no longer have access to the Chronic Disease Program Module.'
    },
    SELECT_DEFAULT: '-Select-',
    ErrorRowIndexField: 'Original File Row Number',
    REDCAP_DISPLAY_TEXT: 'REDCap',
    DETAIL_PAGE_TYPE: {
      FETCH: 'fetch',
      VIEW: 'viewDetails'
    },
    MESSAGES: {
      updateStatusSuccess: 'Selected record is now hidden.',
      updateStatusShowSuccess: 'Selected record is now visible.',
      updateStatusFailure: 'Oops! Something went wrong while hiding the record. Please try again later.',
      pledgeSuccessMessage: 'Your Data has been saved Successfully.',
      importPendingWaring:
        '*Note: Some file(s) are not imported to this association',
      importSuccessFile:
        'File imported successfully!. Redirecting to dashboard.',
      importSuccess: 'Records imported successfully! Redirecting to dashboard.',
      uploadSuccess:
        'Records Uploaded successfully!. Redirecting to details page in {0} secs',
      NotPartofAssociation:
        'This association is not part of any Chronic Disease Programs, Please contact the administrator for more details.',
      CardSuccessCount:
        '*Note: Card represents success uploads count and click on individual cards to see specific program details.',
      CheckBoxConfirmationMessage:
        'I confirm that this data is accurate for importing into the system.',
      ImportErrorMessage:
        '* Please check the confirm import data by checking checkbox.',
      DownloadErrorDetails: 'Download Error Details',
      ImprotWarningPopupMessage:
        'You have not imported data yet. Do you want leave without importing the data?',
      ImprotWarningPopupMessageFrench: `Vous n'avez pas encore importé de données. Voulez-vous partir sans importer les données ?`,
      ImprotWarningPopupMessageGerman:
        'Sie haben noch keine Daten importiert. Möchten Sie gehen, ohne die Daten zu importieren?',
      ImprotWarningPopupMessageSpanish:
        'Aún no has importado datos. ¿Quieres salir sin importar los datos?',
      FileUploadErrorDoc:
        'File could not be uploaded. Only Doc, Docx and PDF file is allowed.',
      FileUploadErrorCSV:
        'File could not be uploaded. Only CSV file is allowed.',
      NoDataMessage:
        'There is no data found for selected association, please contact admin.',
      importSuccessSwim: 'Records imported successfully!.'
    },
    DUE_DATE_STATUS: {
      UPCOMING: 'Upcoming',
      COMPLETED: 'Completed',
      TODAY: 'Today',
      OVERDUE: 'Overdue'
    },
    PROGRAM_ENUM: {
      none: '0',
      DPP: '1',
      BPSM: '2',
      YWP: '6',
      EF: '4',
      LS: '3',
      HW: '5'
    },
    DATA_SYSTEMS_ENUM: {
      REDCAP: '1',
      Solera: '2',
      Welld: '3',
      OCHI: '4',
      Habitnu: '5',
      'Other - Third Party': '6',
      ODES: '7',
      DOL: '999',
      'DEPARTMENT OF LABOR REPORTING': '999'
    },
    CDP_TYPE_ENUM: {
      'Participant Level': '1',
      'Aggregate Partnership': '2',
      'Aggregate Participant': '3',
      'Narrative Report': '4'
    },
    DATA_SYSTEM_NAMES: {
      REDCAP: 'REDCap',
      WELLD: 'Welld',
      SOLERA: 'Solera',
      DOL: 'DEPARTMENT OF LABOR REPORTING',
      OCHI: 'OCHI',
      HABITNU: 'Habitnu',
      THIRDPARTY_GENERIC: 'Other - Third Party',
      ODES: 'ODES'
    },
    PROGRAM_NAMES: {
      DPP: 'DPP',
      BPSM: 'BPSM',
      LS: 'LS',
      EF: 'EF',
      HWYC: 'HW',
      YWP: 'YWP'
    },
    PROGRAM_FULL_NAME: {
      YWP: 'Youth Workforce Pathways',
      DPP: `YMCA's Diabetes Prevention Program`,
      BPSM: `YMCA's Blood Pressure Self-Monitoring Program`,
      EF: 'Enhance®Fitness',
      LS: 'LIVESTRONG® at the YMCA',
      HW: 'Healthy Weight and Your Child'
    },
    PROGRAM_ABBR: {
      "Youth Workforce Pathways": 'YWP',
      "YMCA's Diabetes Prevention Program": 'DPP',
      "YMCA's Blood Pressure Self-Monitoring Program": 'BPSM',
      "Enhance®Fitness": 'EF',
      "LIVESTRONG® at the YMCA": 'LS',
      "Healthy Weight and Your Child": 'HW'
    },
    PROGRAM_FULL: {
      DPP: 'Diabetes Prevention Program',
      LS: 'LiveStrong Program',
      BPSM: 'Blood Pressure Self-Monitoring',
      HWYC: 'Healthy Weight and Your Child Program',
      EF: 'Enhance Fitness Program',
      YWP: 'Youth Workforce Pathways'
    },
    DOL_TYPE_NAMES: {
      ParticipantLevel: 'Participant Level',
      AggregatePartnership: 'Aggregate Partnership',
      AggregateParticipant: 'Aggregate Participant',
      NarrativeReport: 'Narrative Report'
    },
    DOL_TEMPLATES: {
      'Participant Level':
        'DOL Youth Workforce Pathways - Participant Level Data.csv',
      'Aggregate Partnership':
        'DOL Youth Workforce Pathways - Aggregate Partnership Data.csv',
      'Aggregate Participant':
        'DOL Youth Workforce Pathways - Aggregate Participant Data.csv',
      'Other - Third Party': 'DPP Other - Third Party Upload Template.csv'
    },
    TITLES: {
      ADMIN_DASHBOARD: 'YMCA Associations',
      DASHBOARD: 'Chronic Disease Program Log Details',
      REDCAP_DETAILS_EXISTING: 'Chronic Disease Program Data',
      REDCAP_DETAILS_NEW: 'Chronic Disease Program New Data-DPRP Format',
      SOLERA_DETAILS_EXISTING: 'Chronic Disease Program Data',
      SOLERA_DETAILS_NEW: 'Chronic Disease Program New Data-DPRP Format',
      DOL_DETAILS: 'Chronic Disease Program - DOL'
    },
    REDCAP_ITEMS: {
      REGISTRATION: 'registration',
      VISIT: 'visit',
      ORGCODE: 'orgCode',
      COACH: 'coach'
    },
    LABELS: {
      DOL_DOWNLOAD_TEMPLATE: 'Download {0} Template'
    },
    VIEW_DETAILS: {
      DATE_FIELDS: ['date'],
      EXCLUED_FIELDS: [
        'cdpRedcapRegistrationFetchId',
        'cdpRedcapVisitsFetchId',
        'cdpDataFetchId',
        'cdpSoleraFetchId',
        'hashValue',
        'cdpDataFetch',
        'cdpRedcapOrgcodeFetchId',
        'cdpRedcapCoachidFetchId',
        'cdpSoleraCrosswalkFetchId',
        'sourceSystemId',
        'createdDate',
        'validationStatus',
        'isProcessed',
        'isValidRecord',
        'createdDttm',
        'errorSummary',
        'cdpDolParticipantLevelFetchId',
        'iS_VALID_RECORD',
        'erroR_SUMMARY',
        'iS_VALID_RECORD_R',
        'erroR_SUMMARY_R',
        'iS_VALID_RECORD_V',
        'erroR_SUMMARY_V',
        'iS_VALID_RECORD_O',
        'erroR_SUMMARY_O',
        'iS_VALID_RECORD_C',
        'erroR_SUMMARY_C',
        'originalData',
        'originalDataC',
        'originalDataO',
        'originalDataR',
        'originalDataV',
        'origianlErrorSummary',
        'systolicInitial',
        'systolicReading',
        'diastolicReading',
        'diastolicInitial',
        'dateAttendance',
        'dateBaseline',
        'originalRow',
        'deleteInd'
      ]
    },
    STORAGE_KEYS: {
      ASSOCIATION_DATA: 'association_data',
      CDP_TYPE_ENUM: 'CDP_TYPE'
    }
  },
  STORAGE_KEYS: {
    DASHBOARD_URL: 'dashboardUrl',
    FETCH_ID: 'FetchId',
    PERSON_ID: 'personid',
    YUSA_SELECTED_ASSOCIATE: 'yusa_selected_associate',
    SELECTED_ASSOCIATION: 'selected_association'
  },
  DATE_ARRAY: [
    {
      id: '0',
      name: 'Yesterday',
      value: 1
    },
    {
      id: '1',
      name: 'Last 7 Days',
      value: 7
    },
    {
      id: '2',
      name: 'Last 30 Days',
      value: 30
    },
    {
      id: '3',
      name: 'Last 90 days',
      value: 90
    },

    {
      id: '4',
      name: 'Custom',
      value: 'Custom'
    }
  ],
  DATE_SHOWALL_ARRAY: [
    {
      id: '0',
      name: 'Show All',
      value: true
    },
    {
      id: '1',
      name: 'Yesterday',
      value: 1
    },
    {
      id: '2',
      name: 'Last 7 Days',
      value: 7
    },
    {
      id: '3',
      name: 'Last 30 Days',
      value: 30
    },
    {
      id: '4',
      name: 'Last 90 days',
      value: 90
    },
    {
      id: '5',
      name: 'Custom',
      value: 'Custom'
    }
  ]
};
