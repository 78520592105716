import { CDP } from '@config/app-config';
import * as types from './types';

const initialState = {
  CdpAssociateDashboard: {},
  CdpRedCapRegistrationViewDetails: {},
  CdpRedCapVisitsViewDetails: {},
  CdpRedCapOrgCodeViewDetails: {},
  CdpRedCapCoachViewDetails: {},
  CdpSoleraViewDetails: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CDP_ASSOCIATE_DASHBOARD_SUCCESS: {
      return {
        ...state,
        CdpAssociateDashboard: action.payload
      };
    }
    case types.GET_CDP_ASSOCIATE_DASHBOARD_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_REDCAP_VIEW_DETAILS_REGISTRATION_SUCCESS: {
      return {
        ...state,
        CdpRedCapRegistrationViewDetails: action.payload,
        [CDP.REDCAP_ITEMS.REGISTRATION]: action.payload,
      };
    }
    case types.GET_CDP_REDCAP_VIEW_DETAILS_REGISTRATON_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_REDCAP_VIEW_DETAILS_VISITS_SUCCESS: {
      return {
        ...state,
        CdpRedCapVisitsViewDetails: action.payload
      };
    }
    case types.GET_CDP_REDCAP_VIEW_DETAILS_VISITS_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_REDCAP_VIEW_DETAILS_ORG_CODE_SUCCESS: {
      return {
        ...state,
        CdpRedCapOrgCodeViewDetails: action.payload
      };
    }
    case types.GET_CDP_REDCAP_VIEW_DETAILS_ORG_CODE_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_REDCAP_VIEW_DETAILS_COACH_SUCCESS: {
      return {
        ...state,
        CdpRedCapCoachViewDetails: action.payload
      };
    }
    case types.GET_CDP_REDCAP_VIEW_DETAILS_COACH_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_SOLERA_VIEW_DETAILS_SUCCESS: {
      return {
        ...state,
        CdpSoleraViewDetails: action.payload
      };
    }
    case types.GET_CDP_SOLERA_VIEW_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    case types.SAVE_CDP_ASSOCIATION: {
      return {
        ...state,
        associationData: action.payload
      };
    }

    case types.CHECK_HIPAA_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        hippaCertificate: action.payload
      };
    }

    case types.CHECK_HIPAA_CERTIFICATE_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_STATUS_UPDATE_SUCCESS: {
      return {
        ...state,
        updateStatus: action.payload
      };
    }
    case types.POST_STATUS_UPDATE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
