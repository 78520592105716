/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import { getHousing, housing } from '@redux/program-activities/housing/actions';
import { connect } from 'react-redux';
import { GetHousingSelector } from '@redux/program-activities/housing/selectors';
import PropTypes from 'prop-types';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';

import TextError from '@modules/shared/components/reusable-components/text-error';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const maxLength = 9;

const handleChange = (e, setFieldValue, name) => {
  const result = e.target.value.replace(/\D+/g, '');
  setFieldValue(name, result);
};

const checkValue = values => values === 'false' || values === 'null';

const getHotelServiceClassName = values => {
  if (checkValue(values)) {
    return 'ymca-field ymca-fieldDisabled';
  }
  return 'ymca-field';
};

const HotelFieldRow = (values, setFieldValue) => (
  <tr>
    <td colSpan="2" className="ymca-normalWrap txt-mandatory">
      Hotel
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-5 ">
        <Field type="radio" name="didHotelServiceOfferedin2022" value="true" />
      </div>
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-3 ">
        <Field type="radio" name="didHotelServiceOfferedin2022" value="false" />
      </div>
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        maxLength={maxLength}
        className={`${getHotelServiceClassName(
          values.didHotelServiceOfferedin2022
        )}`}
        name="totNoBedsHotel"
        onChange={e => handleChange(e, setFieldValue, 'totNoBedsHotel')}
        value={
          values.didHotelServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoBedsHotel
        }
        disabled={checkValue(values.didHotelServiceOfferedin2022)}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        maxLength={maxLength}
        className={`${getHotelServiceClassName(
          values.didHotelServiceOfferedin2022
        )}`}
        name="totNoOfUnitsHotel"
        onChange={e => handleChange(e, setFieldValue, 'totNoOfUnitsHotel')}
        value={
          values.didHotelServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfUnitsHotel
        }
        disabled={checkValue(values.didHotelServiceOfferedin2022)}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        maxLength={maxLength}
        className={`${getHotelServiceClassName(
          values.didHotelServiceOfferedin2022
        )}`}
        name="totNoOfResidentsHotel"
        onChange={e => handleChange(e, setFieldValue, 'totNoOfResidentsHotel')}
        value={
          values.didHotelServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfResidentsHotel
        }
        disabled={checkValue(values.didHotelServiceOfferedin2022)}
      />
    </td>
  </tr>
);

const AffordableHousingRow = (values, setFieldValue) => (
  <tr>
    <td colSpan="2" className="ymca-normalWrap txt-mandatory">
      Affordable Housing Including SRO
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-5 ">
        <Field
          type="radio"
          name="didHousingSrosServiceOfferedin2022"
          value="true"
        />
      </div>
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-3 ">
        <Field
          type="radio"
          name="didHousingSrosServiceOfferedin2022"
          value="false"
        />
      </div>
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        className={`${getHotelServiceClassName(
          values.didHousingSrosServiceOfferedin2022
        )}`}
        name="totNoBedsHousingSros"
        maxLength={maxLength}
        onChange={e => handleChange(e, setFieldValue, 'totNoBedsHousingSros')}
        value={
          values.didHousingSrosServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoBedsHousingSros
        }
        disabled={checkValue(values.didHousingSrosServiceOfferedin2022)}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        className={`${getHotelServiceClassName(
          values.didHousingSrosServiceOfferedin2022
        )}`}
        maxLength={maxLength}
        name="totNoOfUnitsHousingSros"
        onChange={e =>
          handleChange(e, setFieldValue, 'totNoOfUnitsHousingSros')
        }
        value={
          values.didHousingSrosServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfUnitsHousingSros
        }
        disabled={checkValue(values.didHousingSrosServiceOfferedin2022)}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        maxLength={maxLength}
        className={`${getHotelServiceClassName(
          values.didHousingSrosServiceOfferedin2022
        )}`}
        name="totNoOfResidentsHousingSros"
        onChange={e =>
          handleChange(e, setFieldValue, 'totNoOfResidentsHousingSros')
        }
        value={
          values.didHousingSrosServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfResidentsHousingSros
        }
        disabled={checkValue(values.didHousingSrosServiceOfferedin2022)}
      />
    </td>
  </tr>
);

const HomelessnessRow = (values, setFieldValue) => (
  <tr>
    <td colSpan="2" className="ymca-normalWrap txt-mandatory">
      Units for those facing homelessnes
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-5 ">
        <Field
          type="radio"
          name="didHomelessnessUnitsServiceOfferedin2022"
          value="true"
        />
      </div>
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-3 ">
        <Field
          type="radio"
          name="didHomelessnessUnitsServiceOfferedin2022"
          value="false"
        />
      </div>
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        className={`${getHotelServiceClassName(
          values.didHomelessnessUnitsServiceOfferedin2022
        )}`}
        maxLength={maxLength}
        name="totNoBedsHomelessUnits"
        onChange={e => handleChange(e, setFieldValue, 'totNoBedsHomelessUnits')}
        value={
          values.didHomelessnessUnitsServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoBedsHomelessUnits
        }
        disabled={checkValue(values.didHomelessnessUnitsServiceOfferedin2022)}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        className={`${getHotelServiceClassName(
          values.didHomelessnessUnitsServiceOfferedin2022
        )}`}
        name="totNoOfUnitsHomelessUnits"
        maxLength={maxLength}
        onChange={e =>
          handleChange(e, setFieldValue, 'totNoOfUnitsHomelessUnits')
        }
        value={
          values.didHomelessnessUnitsServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfUnitsHomelessUnits
        }
        disabled={checkValue(values.didHomelessnessUnitsServiceOfferedin2022)}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        className={`${getHotelServiceClassName(
          values.didHomelessnessUnitsServiceOfferedin2022
        )}`}
        name="totNoOfResidentsHomelessUnits"
        maxLength={maxLength}
        onChange={e =>
          handleChange(e, setFieldValue, 'totNoOfResidentsHomelessUnits')
        }
        value={
          values.didHomelessnessUnitsServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfResidentsHomelessUnits
        }
        disabled={checkValue(values.didHomelessnessUnitsServiceOfferedin2022)}
      />
    </td>
  </tr>
);

const ClinicalServiceRow = (values, setFieldValue) => (
  <tr>
    <td colSpan="2" className="ymca-normalWrap txt-mandatory">
      Units for those receiving rehabilitation or clinical service
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-5 ">
        <Field
          type="radio"
          name="didRehabUnitsClinicalServicesServiceOfferedin2022"
          value="true"
        />
      </div>
    </td>
    <td className="ymca-radioBTN">
      <div className="ps-3 ">
        <Field
          type="radio"
          name="didRehabUnitsClinicalServicesServiceOfferedin2022"
          value="false"
        />
      </div>
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        className={`${getHotelServiceClassName(
          values.didRehabUnitsClinicalServicesServiceOfferedin2022
        )}`}
        maxLength={maxLength}
        name="totNoBedsRehabUnitsClinicalServices"
        onChange={e =>
          handleChange(e, setFieldValue, 'totNoBedsRehabUnitsClinicalServices')
        }
        value={
          values.didRehabUnitsClinicalServicesServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoBedsRehabUnitsClinicalServices
        }
        disabled={checkValue(
          values.didRehabUnitsClinicalServicesServiceOfferedin2022
        )}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        className={`${getHotelServiceClassName(
          values.didRehabUnitsClinicalServicesServiceOfferedin2022
        )}`}
        maxLength={maxLength}
        name="totNoOfUnitsRehabUnitsClinicalServices"
        onChange={e =>
          handleChange(
            e,
            setFieldValue,
            'totNoOfUnitsRehabUnitsClinicalServices'
          )
        }
        value={
          values.didRehabUnitsClinicalServicesServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfUnitsRehabUnitsClinicalServices
        }
        disabled={checkValue(
          values.didRehabUnitsClinicalServicesServiceOfferedin2022
        )}
      />
    </td>
    <td colSpan="2" className="ymca-fieldLine">
      <Field
        type="text"
        maxLength={maxLength}
        className={`${getHotelServiceClassName(
          values.didRehabUnitsClinicalServicesServiceOfferedin2022
        )}`}
        name="totNoOfResidentsRehabUnitsClinicalServices"
        onChange={e =>
          handleChange(
            e,
            setFieldValue,
            'totNoOfResidentsRehabUnitsClinicalServices'
          )
        }
        value={
          values.didRehabUnitsClinicalServicesServiceOfferedin2022 === 'false'
            ? ''
            : values.totNoOfResidentsRehabUnitsClinicalServices
        }
        disabled={checkValue(
          values.didRehabUnitsClinicalServicesServiceOfferedin2022
        )}
      />
    </td>
  </tr>
);

const DuringTheYearYesNoElement = () => (
  <>
    <h4 className="ymca-headingTitle txt-mandatory">
      Did your Y offer Housing during the year?
    </h4>
    <div className="ymca-radioBTN d-flex">
      <div className="me-5">
        <Field
          type="radio"
          name="didYofferHousingServiceDuringYear"
          value="true"
          className="me-2"
        />
        <span>Yes</span>
      </div>
      <div className="">
        <Field
          type="radio"
          name="didYofferHousingServiceDuringYear"
          value="false"
          className="me-2"
        />
        <span>No</span>
      </div>
    </div>
    <ErrorMessage
      component={TextError}
      name="didYofferHousingServiceDuringYear"
    />
  </>
);

const HousingTab = props => {
  const {
    handleGetHousing,
    GetHousingSelectorData,
    handleHousing,
    setLoader,
    selectedAsso,
    selectedBranch,
    selectedBranchName,
    setErrorPopup,
    setErrorMessage,
    status,
    role,
    handleGetAnnualReportsDashboard,
    LoginUserData
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Program and Activities';

  const [isSuccess, setIsSuccess] = useState(false);
  const [resStatus, setResStatus] = useState();
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const formikref = useRef();
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const getHousingData = sucesspopup => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetHousing(selectedAsso, selectedBranch).then(res => {
        if (res.status === 200) {
          setLoader(false);
          if (sucesspopup) {
            setIsSuccess(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        }
      });
    }
  };

  useEffect(() => {
    getHousingData();
    formikref?.current?.resetForm();
  }, [selectedAsso, selectedBranch]);

  const initialValues = {
    didYofferHousingServiceDuringYear: 'true',
    didHotelServiceOfferedin2022: '',
    didHousingSrosServiceOfferedin2022: '',
    didHomelessnessUnitsServiceOfferedin2022: '',
    didRehabUnitsClinicalServicesServiceOfferedin2022: '',
    totNoBedsHotel: '',
    totNoOfUnitsHotel: '',
    totNoOfResidentsHotel: '',
    totNoBedsHousingSros: '',
    totNoOfUnitsHousingSros: '',
    totNoOfResidentsHousingSros: '',
    totNoBedsHomelessUnits: '',
    totNoOfUnitsHomelessUnits: '',
    totNoOfResidentsHomelessUnits: '',
    totNoBedsRehabUnitsClinicalServices: '',
    totNoOfUnitsRehabUnitsClinicalServices: '',
    totNoOfResidentsRehabUnitsClinicalServices: ''
  };

  const editValues = {
    didYofferHousingServiceDuringYear:
      `${GetHousingSelectorData?.data?.didYofferHousingServiceDuringYear}` ===
      'null'
        ? 'true'
        : `${GetHousingSelectorData?.data?.didYofferHousingServiceDuringYear}`,
    didHotelServiceOfferedin2022: `${GetHousingSelectorData?.data?.didHotelServiceOfferedin2022}`,
    didHousingSrosServiceOfferedin2022: `${GetHousingSelectorData?.data?.didHousingSrosServiceOfferedin2022}`,
    didHomelessnessUnitsServiceOfferedin2022: `${GetHousingSelectorData?.data?.didHomelessnessUnitsServiceOfferedin2022}`,
    didRehabUnitsClinicalServicesServiceOfferedin2022: `${GetHousingSelectorData?.data?.didRehabUnitsClinicalServicesServiceOfferedin2022}`,
    totNoBedsHotel:
      GetHousingSelectorData?.data?.totNoBedsHotel === null
        ? ''
        : GetHousingSelectorData?.data?.totNoBedsHotel,
    totNoOfUnitsHotel:
      GetHousingSelectorData?.data?.totNoOfUnitsHotel === null
        ? ''
        : GetHousingSelectorData?.data?.totNoOfUnitsHotel,
    totNoOfResidentsHotel:
      GetHousingSelectorData?.data?.totNoOfResidentsHotel === null
        ? ''
        : GetHousingSelectorData?.data?.totNoOfResidentsHotel,
    totNoBedsHousingSros:
      GetHousingSelectorData?.data?.totNoBedsHousingSros === null
        ? ''
        : GetHousingSelectorData?.data?.totNoBedsHousingSros,
    totNoOfUnitsHousingSros:
      GetHousingSelectorData?.data?.totNoOfUnitsHousingSros === null
        ? ''
        : GetHousingSelectorData?.data?.totNoOfUnitsHousingSros,
    totNoOfResidentsHousingSros:
      GetHousingSelectorData?.data?.totNoOfResidentsHousingSros === null
        ? ''
        : GetHousingSelectorData?.data?.totNoOfResidentsHousingSros,
    totNoBedsHomelessUnits:
      GetHousingSelectorData?.data?.totNoBedsHomelessUnits === null
        ? ''
        : GetHousingSelectorData?.data?.totNoBedsHomelessUnits,
    totNoOfUnitsHomelessUnits:
      GetHousingSelectorData?.data?.totNoOfUnitsHomelessUnits === null
        ? ''
        : GetHousingSelectorData?.data?.totNoOfUnitsHomelessUnits,
    totNoOfResidentsHomelessUnits:
      GetHousingSelectorData?.data?.totNoOfResidentsHomelessUnits === null
        ? ''
        : GetHousingSelectorData?.data?.totNoOfResidentsHomelessUnits,
    totNoBedsRehabUnitsClinicalServices:
      GetHousingSelectorData?.data?.totNoBedsRehabUnitsClinicalServices === null
        ? ''
        : GetHousingSelectorData?.data?.totNoBedsRehabUnitsClinicalServices,
    totNoOfUnitsRehabUnitsClinicalServices:
      GetHousingSelectorData?.data?.totNoOfUnitsRehabUnitsClinicalServices ===
      null
        ? ''
        : GetHousingSelectorData?.data?.totNoOfUnitsRehabUnitsClinicalServices,
    totNoOfResidentsRehabUnitsClinicalServices:
      GetHousingSelectorData?.data
        ?.totNoOfResidentsRehabUnitsClinicalServices === null
        ? ''
        : GetHousingSelectorData?.data
            ?.totNoOfResidentsRehabUnitsClinicalServices
  };

  const validationSchema = Yup.object({
    didYofferHousingServiceDuringYear: Yup.string().nullable(),
    didHotelServiceOfferedin2022: Yup.string(),
    didHousingSrosServiceOfferedin2022: Yup.string(),
    didHomelessnessUnitsServiceOfferedin2022: Yup.string(),
    didRehabUnitsClinicalServicesServiceOfferedin2022: Yup.string(),
    totNoBedsHotel: Yup.string().nullable(),
    totNoOfUnitsHotel: Yup.string().nullable(),
    totNoOfResidentsHotel: Yup.string().nullable(),
    totNoBedsHousingSros: Yup.string().nullable(),
    totNoOfUnitsHousingSros: Yup.string().nullable(),
    totNoOfResidentsHousingSros: Yup.string().nullable(),
    totNoBedsHomelessUnits: Yup.string().nullable(),
    totNoOfUnitsHomelessUnits: Yup.string().nullable(),
    totNoOfResidentsHomelessUnits: Yup.string().nullable(),
    totNoBedsRehabUnitsClinicalServices: Yup.string().nullable(),
    totNoOfUnitsRehabUnitsClinicalServices: Yup.string().nullable(),
    totNoOfResidentsRehabUnitsClinicalServices: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? value : null);

  const setDidHotelServiceOfferedin2022 = values => {
    if (values.didYofferHousingServiceDuringYear === 'true') {
      return values.didHotelServiceOfferedin2022 !== 'null'
        ? values.didHotelServiceOfferedin2022.toLowerCase() === 'true'
        : null;
    }
    return null;
  };

  const setDidHousingSrosServiceOfferedin2022 = values => {
    if (values.didYofferHousingServiceDuringYear === 'true') {
      return values.didHousingSrosServiceOfferedin2022 !== 'null'
        ? values.didHousingSrosServiceOfferedin2022.toLowerCase() === 'true'
        : null;
    }
    return null;
  };

  const setDidHomelessnessUnitsServiceOfferedin2022 = values => {
    if (values.didYofferHousingServiceDuringYear === 'true') {
      return values.didHomelessnessUnitsServiceOfferedin2022 !== 'null'
        ? values.didHomelessnessUnitsServiceOfferedin2022.toLowerCase() ===
            'true'
        : null;
    }
    return null;
  };

  const setDidRehabUnitsClinicalServicesServiceOfferedin2022 = values => {
    if (values.didYofferHousingServiceDuringYear === 'true') {
      return values.didRehabUnitsClinicalServicesServiceOfferedin2022 !== 'null'
        ? values.didRehabUnitsClinicalServicesServiceOfferedin2022.toLowerCase() ===
            'true'
        : null;
    }
    return null;
  };

  const getConvertedValue = (condition1, condition2, value) =>
    condition1 === 'true' && condition2 === 'true'
      ? convertedValue(value)
      : null;

  const getBooleanValue = value => value.toLowerCase() === 'true';

  const getPayload = values => {
    const {
      didYofferHousingServiceDuringYear,
      didHotelServiceOfferedin2022,
      didHousingSrosServiceOfferedin2022,
      didHomelessnessUnitsServiceOfferedin2022,
      didRehabUnitsClinicalServicesServiceOfferedin2022
    } = values;

    return {
      programActivityHousingId:
        GetHousingSelectorData?.data?.programActivityHousingId,
      associationNumber: selectedAsso,
      year: sessionStorage.getItem('selectedBatchYear'),
      branchNumber: selectedBranch,
      branchName: selectedBranchName?.toString(),
      didYofferHousingServiceDuringYear: getBooleanValue(
        didYofferHousingServiceDuringYear
      ),
      didHotelServiceOfferedin2022: setDidHotelServiceOfferedin2022(values),
      totNoBedsHotel: getConvertedValue(
        didHotelServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoBedsHotel
      ),
      totNoOfUnitsHotel: getConvertedValue(
        didHotelServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfUnitsHotel
      ),
      totNoOfResidentsHotel: getConvertedValue(
        didHotelServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfResidentsHotel
      ),
      didHousingSrosServiceOfferedin2022:
        setDidHousingSrosServiceOfferedin2022(values),
      totNoBedsHousingSros: getConvertedValue(
        didHousingSrosServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoBedsHousingSros
      ),
      totNoOfUnitsHousingSros: getConvertedValue(
        didHousingSrosServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfUnitsHousingSros
      ),
      totNoOfResidentsHousingSros: getConvertedValue(
        didHousingSrosServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfResidentsHousingSros
      ),
      didHomelessnessUnitsServiceOfferedin2022:
        setDidHomelessnessUnitsServiceOfferedin2022(values),
      totNoBedsHomelessUnits: getConvertedValue(
        didHomelessnessUnitsServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoBedsHomelessUnits
      ),
      totNoOfUnitsHomelessUnits: getConvertedValue(
        didHomelessnessUnitsServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfUnitsHomelessUnits
      ),
      totNoOfResidentsHomelessUnits: getConvertedValue(
        didHomelessnessUnitsServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfResidentsHomelessUnits
      ),
      didRehabUnitsClinicalServicesServiceOfferedin2022:
        setDidRehabUnitsClinicalServicesServiceOfferedin2022(values),
      totNoBedsRehabUnitsClinicalServices: getConvertedValue(
        didRehabUnitsClinicalServicesServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoBedsRehabUnitsClinicalServices
      ),
      totNoOfUnitsRehabUnitsClinicalServices: getConvertedValue(
        didRehabUnitsClinicalServicesServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfUnitsRehabUnitsClinicalServices
      ),
      totNoOfResidentsRehabUnitsClinicalServices: getConvertedValue(
        didRehabUnitsClinicalServicesServiceOfferedin2022,
        didYofferHousingServiceDuringYear,
        values.totNoOfResidentsRehabUnitsClinicalServices
      ),
      status: GetHousingSelectorData?.data?.status,
      reportBatchId: GetHousingSelectorData?.data?.reportBatchId,
      createdByPersonId: GetHousingSelectorData?.data?.createdByPersonId,
      createdByName: GetHousingSelectorData?.data?.createdByName,
      createdDatetime: GetHousingSelectorData?.data?.createdDatetime,
      updatedByPersonId: GetHousingSelectorData?.data?.updatedByPersonId,
      updatedByName: GetHousingSelectorData?.data?.updatedByName,
      updatedDatetime: GetHousingSelectorData?.data?.updatedDatetime
    };
  };

  const onSubmit = values => {
    setLoader(true);
    const payloadBody = getPayload(values);
    setIsSuccess(false);
    handleHousing(payloadBody).then(res => {
      getHousingData(true);
      if (res?.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.foreach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const totalCount = (value1, value2, value3, value4) =>
    value1 + value2 + value3 + value4;

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-programs">
                <div className="ymc-alert mt-1">
                  {GetHousingSelectorData?.data?.recordStatus?.toUpperCase() ===
                    APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                </div>
                <div className="ymca-covidRelif pt-0 ">
                  {DuringTheYearYesNoElement()}

                  {values.didYofferHousingServiceDuringYear === 'true' && (
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped ymca-autoLength">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th colSpan="2">
                              Please provide an overview of housing services
                              provided in {selectedYear}
                            </th>
                            <th colSpan="2">
                              <table className="table TT-borderTable">
                                <thead>
                                  <tr>
                                    <th colSpan="2">
                                      {`Did the Y offer this service in
                                     ${selectedYear}?`}
                                    </th>
                                  </tr>
                                  <tr>
                                    <th className="YMCA-borderNoOne">Yes</th>
                                    <th>No</th>
                                  </tr>
                                </thead>
                              </table>
                            </th>
                            <th colSpan="2" className="YMCA-borderNoOne">
                              Total No. of Beds
                            </th>
                            <th colSpan="2">Total No. of Units</th>
                            <th colSpan="2">Total No. of Residents</th>
                            <th colSpan="2" className="ymca-defaultSpace">
                              &nbsp;
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody ymca-lengths">
                          {HotelFieldRow(values, setFieldValue)}
                          {AffordableHousingRow(values, setFieldValue)}
                          {HomelessnessRow(values, setFieldValue)}
                          {ClinicalServiceRow(values, setFieldValue)}
                          <tr>
                            <td colSpan="2" className="ymca-normalWrap">
                              Total
                            </td>
                            <td className="ymca-radioBTN" />
                            <td className="ymca-radioBTN" />
                            <td colSpan="2" className="ymca-fieldLine">
                              <Field
                                type="text"
                                className="ymca-field ymca-fieldDisabled"
                                value={totalCount(
                                  values.didHotelServiceOfferedin2022 === 'true'
                                    ? Number(values.totNoBedsHotel)
                                    : 0,
                                  Number(
                                    values.didHousingSrosServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoBedsHousingSros
                                      : 0
                                  ),
                                  Number(
                                    values.didHomelessnessUnitsServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoBedsHomelessUnits
                                      : 0
                                  ),
                                  Number(
                                    values.didRehabUnitsClinicalServicesServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoBedsRehabUnitsClinicalServices
                                      : 0
                                  )
                                )}
                                disabled
                              />
                            </td>
                            <td colSpan="2" className="ymca-fieldLine">
                              <Field
                                type="text"
                                className="ymca-field ymca-fieldDisabled"
                                value={totalCount(
                                  Number(
                                    values.didHotelServiceOfferedin2022 ===
                                      'true'
                                      ? Number(values.totNoOfUnitsHotel)
                                      : 0
                                  ),
                                  Number(
                                    values.didHousingSrosServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoOfUnitsHousingSros
                                      : 0
                                  ),
                                  Number(
                                    values.didHomelessnessUnitsServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoOfUnitsHomelessUnits
                                      : 0
                                  ),
                                  Number(
                                    values.didRehabUnitsClinicalServicesServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoOfUnitsRehabUnitsClinicalServices
                                      : 0
                                  )
                                )}
                                disabled
                              />
                            </td>
                            <td colSpan="2" className="ymca-fieldLine" name="">
                              <Field
                                type="text"
                                className="ymca-field ymca-fieldDisabled"
                                value={totalCount(
                                  Number(
                                    values.didHotelServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoOfResidentsHotel
                                      : 0
                                  ),
                                  Number(
                                    values.didHousingSrosServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoOfResidentsHousingSros
                                      : 0
                                  ),
                                  Number(
                                    values.didHomelessnessUnitsServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoOfResidentsHomelessUnits
                                      : 0
                                  ),
                                  Number(
                                    values.didRehabUnitsClinicalServicesServiceOfferedin2022 ===
                                      'true'
                                      ? values.totNoOfResidentsRehabUnitsClinicalServices
                                      : 0
                                  )
                                )}
                                disabled
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="ymca-submitHighlighter">
                  <LastUpdateName
                    Username={GetHousingSelectorData?.data?.updatedByName}
                    Time={GetHousingSelectorData?.data?.updatedDatetime}
                  />
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </div>
              {isSuccess && <SuccessToaster Status={resStatus} />}
            </Form>
          );
        }}
      </Formik>

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </>
  );
};

HousingTab.propTypes = {
  handleGetHousing: PropTypes.func.isRequired,
  handleHousing: PropTypes.func.isRequired,
  GetHousingSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  selectedAsso: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranch: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranchName: PropTypes.objectOf(PropTypes.any).isRequired,
  setErrorPopup: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetHousing: getHousing,
  handleHousing: housing,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

const mapStateToProps = state => ({
  GetHousingSelectorData: GetHousingSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HousingTab));
