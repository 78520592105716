import * as types from './types';

const initialState = {
  uploadProfiles: [],
  profilesList: [],
  selectedProgramDetails: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SWIMSAFETY_UPLOAD_PROFILES_SUCCESS: {
      return {
        ...state,
        uploadProfiles: action.payload
      };
    }
    case types.SWIMSAFETY_SELECTED_PROGRAM_SUCCESS: {
      return {
        ...state,
        selectedProgramDetails: action.payload
      };
    }

    case types.SWIMSAFETY_PROFILES_API_FAILURE:
      return { ...state, error: action.payload };

    case types.SWIMSAFETY_PROFILES_LIST_SUCCESS: {
      return {
        ...state,
        profilesList: action.payload
      };
    }
    case types.SWIMSAFETY_PROFILES_LIST_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
