/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import get from 'lodash.get';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  APP_LABELS,
  VALIDATION_MESSAGES,
  FIELD_LENGTH
} from '@config/app-config';
import {
  getOrganizationCommitment,
  postOrganizationCommitment,
  resetReduxState
} from '@redux/diversity-inclusion-&-global-metrics/organization-commitment/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetOrganizationCommitmentSelector } from '@redux/diversity-inclusion-&-global-metrics/organization-commitment/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import Help from '@modules/shared/components/reusable-components/help';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import TextError from '@modules/shared/components/reusable-components/text-error';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  commitmentStatus: Yup.string().nullable(),
  ystatement: Yup.string().min(6, VALIDATION_MESSAGES.TEXT_AREA_MIN).nullable(),
  yEmploysOrganizationalStructures: Yup.array().nullable(),
  digeffortsDescription: Yup.string().nullable(),
  eeoPolicy: Yup.string().nullable()
});

const OrganizationalCommitmentTab = props => {
  const {
    handleGetOrganizationCommitment,
    handlePostOrganizationCommitment,
    handleResetRedux,
    GetOrganizationCommitmentSelectorData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Diversity Inclusion & Global Metrics';
  const formikref = useRef();
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [ystatementCount, setYstatementCount] = useState(
    GetOrganizationCommitmentSelectorData?.data?.ystatement?.length || 0
  );
  const [ystatement, setYstatement] = useState(
    GetOrganizationCommitmentSelectorData?.data?.ystatement
  );
  const [digeffortsDescriptionCount, setDigeffortsDescriptionCount] = useState(
    GetOrganizationCommitmentSelectorData?.data?.digeffortsDescription
      ?.length || 0
  );

  const [digeffortsDescription, setDigeffortsDescription] = useState(
    GetOrganizationCommitmentSelectorData?.data?.digeffortsDescription
  );
  const [resStatus, setResStatus] = useState();
  const role = get(LoginUserData, 'user.role[0]');

  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = success => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetOrganizationCommitment(associationsNum).then(res => {
      if (res.status === 200) {
        setDigeffortsDescriptionCount(
          res?.data?.data?.digeffortsDescription?.length || 0
        );
        setDigeffortsDescription(res?.data?.data?.digeffortsDescription);
        setYstatement(res?.data?.data?.ystatement);
        setYstatementCount(res?.data?.data?.ystatement?.length || 0);
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'organizational-commitment'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetOrganizationCommitment');
    };
  }, []);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const initialValues = {
    commitmentStatus: '',
    ystatement: '',
    yEmploysOrganizationalStructures: [],
    eeoPolicy: ''
  };

  const editValues = {
    commitmentStatus: `${GetOrganizationCommitmentSelectorData?.data?.commitmentStatus}`,
    ystatement: GetOrganizationCommitmentSelectorData?.data?.ystatement,
    yEmploysOrganizationalStructures:
      GetOrganizationCommitmentSelectorData?.data
        ?.yEmploysOrganizationalStructures,
    eeoPolicy: `${GetOrganizationCommitmentSelectorData?.data?.eeoPolicy}`
  };
  const convertedValue = value =>
    value === 'null' ? null : value.toLowerCase() === 'true';

  const getPayload = values => {
    let payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetOrganizationCommitmentSelectorData?.data?.createdByName,
      createdByPersonId:
        GetOrganizationCommitmentSelectorData?.data?.createdByPersonId,
      createdDatetime:
        GetOrganizationCommitmentSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      organizationalCommitmentId:
        GetOrganizationCommitmentSelectorData?.data?.organizationalCommitmentId,
      reportBatchId: GetOrganizationCommitmentSelectorData?.data?.reportBatchId,
      recordStatus: GetOrganizationCommitmentSelectorData?.data?.recordStatus,
      yEmploysOrganizationalStructures: values.yEmploysOrganizationalStructures,
      digeffortsDescription,
      eeoPolicy: values.eeoPolicy.toLowerCase() === 'true'
    };
    if (selectedYear >= '2023') {
      payloadBody = {
        ...payloadBody,
        commitmentStatus:
          values?.commitmentStatus === 'null' && ystatement != null
            ? true
            : convertedValue(values?.commitmentStatus),
        ystatement: values?.commitmentStatus === 'false' ? null : ystatement
      };
    } else {
      payloadBody = {
        ...payloadBody,
        commitmentStatus: values.commitmentStatus.toLowerCase() === 'true',
        ystatement: values.ystatement
      };
    }
    return payloadBody;
  };

  const onSubmit = values => {
    setLoader(true);
    const payload = getPayload(values);
    setSuccessPopup(false);
    handlePostOrganizationCommitment(payload).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall(true);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handelCount = (value, textareaName) => {
    if (textareaName === 'ystatement') {
      console.log(value);
      setYstatementCount(value?.length || 0);
      setYstatement(value);
    } else if (textareaName === 'digeffortsDescription') {
      setDigeffortsDescriptionCount(value?.length || 0);
      setDigeffortsDescription(value);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <div>
        <Formik
          innerRef={formikref}
          initialValues={editValues || initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, dirty, setFieldValue }) => {
            sessionStorage.setItem('dirty', dirty);
            return (
              <Form autoComplete="off">
                <div className="ymca-covidRelif">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                    <div className="ymca-instructionBoxes">
                      <h4 className="ymca-instructHeadline">
                        {APP_LABELS.INSTRUCTIONS}
                      </h4>
                      <p className="ymca-instructTitle">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: instructions
                          }}
                        />
                      </p>
                    </div>
                  </div>

                  {GetOrganizationCommitmentSelectorData?.data?.recordStatus?.toUpperCase() ===
                    APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}

                  <h4 className="ymca-headingTitle txt-mandatory">
                    {
                      'Our YMCA has a board of directors-approved diversity, inclusion, global policy(ies) (e.g., DIG statements, board-approved resolutions, statements within the constitution) that drives our diversity, inclusion,  and global efforts and calls for access and engagement of all in the community regardless of race, ethnicity, gender identity, national origin, faith, age, income levels, sexual orientation, immigration status, or other protected status.'
                    }
                  </h4>
                  <div className="ymca-radioBTN d-flex">
                    <div className="ymca-setListing me-4">
                      <Field
                        className="ymca-fieldRadio"
                        type="radio"
                        name="commitmentStatus"
                        value="true"
                      />
                      <span className="ymca-texting">Yes</span>
                    </div>
                    <div className="ymca-setListing">
                      <Field
                        className="ymca-fieldRadio"
                        type="radio"
                        name="commitmentStatus"
                        value="false"
                        onChange={e => {
                          setFieldValue('commitmentStatus', e.target.value);
                        }}
                      />
                      <span className="ymca-texting">No</span>
                    </div>
                  </div>
                  <ErrorMessage component={TextError} name="commitmentStatus" />
                  {((values.commitmentStatus === 'true' ||
                    values.commitmentStatus === 'null') &&
                    selectedYear >= '2023') ||
                  selectedYear === '2022' ? (
                    <div>
                      <h4 className="ymca-headingTitle txt-mandatory">
                        {"Please provide a copy of your Y's statement below:"}
                      </h4>
                      <textarea
                        name="ystatement"
                        className="ymca-areaTextWidth"
                        maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                        value={ystatement}
                        defaultValue={
                          GetOrganizationCommitmentSelectorData?.data
                            ?.ystatement
                        }
                        onChange={e => {
                          setFieldValue('ystatement', e.target.value);
                          handelCount(e.target.value, 'ystatement');
                        }}
                      />
                      {/* <Field
                        as="textarea"
                        name="ystatement"
                        className={
                          errors.ystatement && touched.ystatement
                            ? 'ymca-areaTextWidth mb-0 invalid'
                            : 'ymca-areaTextWidth mb-0'
                        }
                        maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                        onKeyDown={handelCount(values.ystatement, 'ystatement')}
                      /> */}
                      <div className="row">
                        <div className="col-10">
                          <ErrorMessage
                            className="mb-2"
                            component={TextError}
                            name="ystatement"
                          />
                        </div>
                        <div className="col-2 text-end ymca-textarea-count">
                          <p>{`(${ystatementCount}/8000)`}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <h4 className="ymca-headingTitle txt-mandatory">
                    Our Y employs organizational structures and processes to
                    enhance and drive our diversity, inclusion, and global
                    efforts, as evidenced by the following: Check all that
                    apply.
                  </h4>
                  <div className="ymca-tableResponsive">
                    <table className="table ymca-table ymca-lastTable ymca-equalSpace">
                      <tbody className="ymca-cuttingBody ymca-nostripeBody">
                        <tr className="">
                          <td className="ymca-normalWrap ymca-checkboxers">
                            <div className="d-flex ymca-listingERA">
                              <Field
                                className="styled-checkbox"
                                name="yEmploysOrganizationalStructures"
                                type="checkbox"
                                value="ystrategicPlan"
                                id="ystrategicPlan"
                              />
                              <label
                                htmlFor="ystrategicPlan"
                                className="ymca-texting"
                              >
                                Diversity, inclusion, and global goals are
                                integrated into the Y strategic plan
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ymca-normalWrap ymca-checkboxers">
                            <div className="d-flex ymca-listingERA">
                              <Field
                                className="styled-checkbox"
                                name="yEmploysOrganizationalStructures"
                                type="checkbox"
                                value="fundamentalBusiness"
                                id="fundamentalBusiness"
                              />
                              <label
                                htmlFor="fundamentalBusiness"
                                className="ymca-texting"
                              >
                                Diversity, inclusion, and global goals are
                                clearly communicated internally and externally
                                as fundamental to mission and business success
                                integrated into the Y strategic plan
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ymca-normalWrap ymca-checkboxers">
                            <div className="d-flex ymca-listingERA">
                              <Field
                                className="styled-checkbox"
                                name="yEmploysOrganizationalStructures"
                                type="checkbox"
                                value="resourcedAndEvaluated"
                                id="resourcedAndEvaluated"
                              />
                              <label
                                htmlFor="resourcedAndEvaluated"
                                className="ymca-texting"
                              >
                                Organizational diversity, inclusion, and global
                                efforts are resourced and evaluated
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ymca-normalWrap ymca-checkboxers">
                            <div className="d-flex ymca-listingERA">
                              <Field
                                className="styled-checkbox"
                                name="yEmploysOrganizationalStructures"
                                type="checkbox"
                                value="communityNeeds"
                                id="communityNeeds"
                              />
                              <label
                                htmlFor="communityNeeds"
                                className="ymca-texting"
                              >
                                Y programs, facilities, practices, and
                                procedures reflect community needs
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ymca-normalWrap ymca-checkboxers">
                            <div className="d-flex ymca-listingERA">
                              <Field
                                className="styled-checkbox"
                                name="yEmploysOrganizationalStructures"
                                type="checkbox"
                                value="integratedIntoAllY"
                                id="integratedIntoAllY"
                              />
                              <label
                                htmlFor="integratedIntoAllY"
                                className="ymca-texting"
                              >
                                Diversity, inclusion, and global measures are
                                integrated into all Y evaluations, practices,
                                and procedures
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ymca-normalWrap ymca-checkboxers">
                            <div className="d-flex ymca-listingERA">
                              <Field
                                className="styled-checkbox"
                                name="yEmploysOrganizationalStructures"
                                type="checkbox"
                                value="noneOfTheAbove"
                                id="noneOfTheAbove"
                              />
                              <label
                                htmlFor="noneOfTheAbove"
                                className="ymca-texting"
                              >
                                We are doing none of the above
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <ErrorMessage
                        component={TextError}
                        name="yEmploysOrganizationalStructures"
                      />
                    </table>
                  </div>
                  <h4 className="ymca-headingTitle">
                    Please provide a brief description of the organizational
                    structures and process in place that support DIG efforts.
                  </h4>
                  <textarea
                    name="digeffortsDescription"
                    className="ymca-areaTextWidth mb-0"
                    maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                    value={digeffortsDescription}
                    defaultValue={
                      GetOrganizationCommitmentSelectorData?.data
                        ?.digeffortsDescription
                    }
                    onChange={e =>
                      handelCount(e.target.value, 'digeffortsDescription')
                    }
                  />
                  <div className="row">
                    <div className="col-10" />
                    <div className="col-2 text-end ymca-textarea-count">
                      <p>{`(${digeffortsDescriptionCount}/8000)`}</p>
                    </div>
                  </div>

                  <h4 className="ymca-headingTitle txt-mandatory">
                    Does your Y have an EEO/nondiscrimination and harassment
                    policy in place?
                  </h4>
                  <div className="ymca-radioBTN d-flex">
                    <div className="ymca-setListing me-4">
                      <Field
                        className="ymca-fieldRadio"
                        type="radio"
                        name="eeoPolicy"
                        value="true"
                      />
                      <span className="ymca-texting">Yes</span>
                    </div>
                    <div className="ymca-setListing">
                      <Field
                        className="ymca-fieldRadio"
                        type="radio"
                        name="eeoPolicy"
                        value="false"
                      />
                      <span className="ymca-texting">No</span>
                    </div>
                  </div>
                  <ErrorMessage component={TextError} name="eeoPolicy" />
                </div>
                <div className="ymca-submitHighlighter">
                  <LastUpdateName
                    Username={
                      GetOrganizationCommitmentSelectorData?.data?.updatedByName
                    }
                    Time={
                      GetOrganizationCommitmentSelectorData?.data
                        ?.updatedDatetime
                    }
                  />
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightTitle float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </Form>
            );
          }}
        </Formik>
      </div>
      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      {help && (
        <Help hidePopup={hideShowHelp} keyValue="organizational-commitment" />
      )}
    </div>
  );
};

OrganizationalCommitmentTab.propTypes = {
  handleGetOrganizationCommitment: PropTypes.func.isRequired,
  handlePostOrganizationCommitment: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetOrganizationCommitmentSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetOrganizationCommitment: getOrganizationCommitment,
  handlePostOrganizationCommitment: postOrganizationCommitment,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetOrganizationCommitmentSelectorData:
    GetOrganizationCommitmentSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OrganizationalCommitmentTab));
