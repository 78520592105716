/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { APP_LABELS } from '@config/app-config';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import numberBg from '@static/images/number-bg.svg';
import checkBox2x from '@static/images/checkbox 2x.svg';
import Loading2x from '@static/images/loading 2x.svg';

const isPledge = name =>
  name === APP_LABELS.PLEDGE_STATEMENT || name === APP_LABELS.PLEDGE;

const isBranchDataSteward = userRole =>
  userRole === APP_LABELS.BRANCH_DATA_STEWARD;

const isDataViewerOrSteward = userRole =>
  userRole === APP_LABELS.YUSA_DATA_VIEWER ||
  userRole === APP_LABELS.ALLIANCE_DATA_STEWARD ||
  userRole === APP_LABELS.YUSA_DATA_STEWARD ||
  userRole === APP_LABELS.YESS_DATA_STEWARD;

const isStartLinkVisible = (name, userRole) => {
  const isSpecialCase =
    (isPledge(name) &&
      ![
        APP_LABELS.CVO_DATA_STEWARD,
        APP_LABELS.CEO_DATA_STEWARD,
        APP_LABELS.YUSA_DATA_VIEWER,
        APP_LABELS.ALLIANCE_DATA_STEWARD,
        APP_LABELS.YUSA_DATA_STEWARD,
        APP_LABELS.YESS_DATA_STEWARD
      ].includes(userRole)) ||
    (name === APP_LABELS.SITE_INFORMATION && isBranchDataSteward(userRole)) ||
    (name === APP_LABELS.EMPLOYEE && isBranchDataSteward(userRole)) ||
    (name === APP_LABELS.CONSTITUENCY_REPORT &&
      isBranchDataSteward(userRole)) ||
    (name === APP_LABELS.DIVERSITY_METRICS && isBranchDataSteward(userRole));

  return !isSpecialCase;
};

const Card = props => {
  const { id, name, status, lastUpdatedBy, lastUpdatedDate, userRole, year } =
    props;
  const mapperToSection = {
    1: 'v-pills-Directory-tab',
    2: 'v-pills-siteInformations-tab',
    3: 'v-pills-updateEmployee-tab',
    4: 'v-pills-Constituency-tab',
    5: 'v-pills-globalMetrics-tab',
    6: 'v-pills-Collaborations-tab',
    7: 'v-pills-Program-tab',
    8: 'v-pills-Pledge-tab',
    9: 'v-pills-ReviewSubmit-tab'
  };
  const location1 = {
    pathname: '/annual/report',
    state: { sectionId: mapperToSection[id], selectedYear: year }
  };
  const handleSetYear = () => {
    sessionStorage.setItem('selectedBatchYear', location1?.state?.selectedYear);
  };

  const cardBody = () => (
    <div className="card-body">
      <h5>{name}</h5>
      <h6
        className={`${
          (status === APP_LABELS.STATUS_COMPLETED ||
            status === APP_LABELS.STATUS_SUBMITTED) &&
          'completed'
        }`}
      >
        {status}
      </h6>
      {status !== APP_LABELS.STATUS_YET_TO_BEGIN && (
        <>
          <div className="status_updated">{APP_LABELS.LAST_UPDATED_BY}</div>
          <div className="updated_by notranslate">
            {`${lastUpdatedBy || ''} ${convertUtcToLocal(
              lastUpdatedDate,
              'DD MMM YYYY, hh:mm a'
            )}`}
          </div>
        </>
      )}
    </div>
  );

  const renderCard = () => (
    <div
      className="card status-card"
      tabIndex="0"
      role="button"
      onClick={handleSetYear}
    >
      <div className="card_content d-flex">
        <div className="card-no_bg">
          <img src={numberBg} alt="number-background" className="img-fluid" />
          <h6>{`0${id}`}</h6>
        </div>
        {cardBody()}
        {status === APP_LABELS.STATUS_COMPLETED ||
        status === APP_LABELS.STATUS_IN_PROGRESS ||
        status === APP_LABELS.STATUS_SUBMITTED ? (
          <div className="checkbox">
            <img
              src={
                status === APP_LABELS.STATUS_COMPLETED ||
                status === APP_LABELS.STATUS_SUBMITTED
                  ? checkBox2x
                  : Loading2x
              }
              alt="number-background"
              className="img-fluid"
            />
          </div>
        ) : (
          ''
        )}
      </div>
      {status === APP_LABELS.STATUS_YET_TO_BEGIN && (
        <div className="card-footer start-link">
          {isStartLinkVisible(name, userRole) ? (
            <Link to={location1} className="card-link float-end">
              {isDataViewerOrSteward(userRole) ||
              (isPledge(name) && isDataViewerOrSteward(userRole))
                ? APP_LABELS.VIEW
                : APP_LABELS.START_NOW}
            </Link>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className="col-12 col-md-6 col-lg-4">
      {((name === APP_LABELS.PLEDGE_STATEMENT || name === APP_LABELS.PLEDGE) &&
        userRole !== APP_LABELS.CVO_DATA_STEWARD &&
        userRole !== APP_LABELS.CEO_DATA_STEWARD &&
        userRole !== APP_LABELS.YUSA_DATA_STEWARD &&
        userRole !== APP_LABELS.YESS_DATA_STEWARD &&
        userRole !== APP_LABELS.YUSA_DATA_VIEWER &&
        userRole !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
        year === '2022') ||
      (name === APP_LABELS.SITE_INFORMATION &&
        userRole === APP_LABELS.BRANCH_DATA_STEWARD) ||
      (name === APP_LABELS.EMPLOYEE &&
        userRole === APP_LABELS.BRANCH_DATA_STEWARD) ||
      (name === APP_LABELS.CONSTITUENCY_REPORT &&
        userRole === APP_LABELS.BRANCH_DATA_STEWARD) ||
      (name === APP_LABELS.DIVERSITY_METRICS &&
        userRole === APP_LABELS.BRANCH_DATA_STEWARD) ||
        (name === APP_LABELS.DIVERSITYMETRICS &&
          userRole === APP_LABELS.BRANCH_DATA_STEWARD)||
      ((name === APP_LABELS.PLEDGE_STATEMENT || name === APP_LABELS.PLEDGE) &&
        userRole === APP_LABELS.BRANCH_DATA_STEWARD) ||
      (name === APP_LABELS.REVIEW_SUBMIT &&
        userRole === APP_LABELS.BRANCH_DATA_STEWARD) ? (
        <div className="cursor-not-allowed custom-disable-tooltip">
          {renderCard()}
          <span className="ymca-option-tooltip">
            You are not authorized to access this section
          </span>
        </div>
      ) : (
        <Link to={location1} className="text-decoration-none">
          {renderCard()}
        </Link>
      )}
    </div>
  );
};
Card.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  status: PropTypes.string,
  lastUpdatedBy: PropTypes.string,
  lastUpdatedDate: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired
};
Card.defaultProps = {
  name: '',
  status: '',
  lastUpdatedBy: '',
  lastUpdatedDate: ''
};
export default Card;
