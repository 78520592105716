import React, { useState, useEffect } from 'react';
import getBranchSummaryAndLogs from '@redux/detail-view/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KpiCard from '@modules/shared/components/reusable-components/kpi-card';
import DetailsCard from '@modules/shared/components/reusable-components/details-card';
import get from 'lodash.get';
import { BranchSummaryLogsSelector } from '@redux/detail-view/selectors';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { APP_LABELS } from '@config/app-config';

import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import totalstaffpurpleicon from '@static/images/total-staff-purple-icon.svg';
import totalstaffwhiteicon from '@static/images/total-staff-white-icon.svg';
import unchangedpinkicon from '@static/images/un-changed-pink-icon.svg';
import UnChangedwhiteicon from '@static/images/Un-Changed-white-icon.svg';
import newstaffyellowicon from '@static/images/new-staff-yellow-icon.svg';
import newstaffwhiteicon from '@static/images/new-staff-white-icon.svg';
import existingstaffblueicon from '@static/images/existing-staff-blue-icon.svg';
import existingstaffwhiteicon from '@static/images/existing-staff-white-icon.svg';
import expiredstafforangeicon from '@static/images/expired-staff-orange-icon.svg';
import terminatedstaffwhiteicon from '@static/images/terminated-staff-white-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import ViewDetailsPopup from '@modules/shared/components/reusable-components/view-details-popup';
import StaffColumns from './staff-columns';
import Table from './Table';

const DetailView = props => {
  const {
    AssociationNumberData,
    branchSummaryAndLogs,
    handleBranchSummaryAndLogs,
    location
  } = props;

  const { state } = location;
  const selectedRows = {};
  const selectableRows = {};
  const selectedRowsChangeStaff = {};
  const [newStaffViewDetails, setNewStaffViewDetails] = useState();
  const [unChangedViewDetails, setUnChangedViewDetails] = useState();
  const [changedStaffViewDetails, setChangedStaffViewDetails] = useState();
  const [terminatedStaffViewDetails, setTerminatedStaffViewDetails] =
    useState();
  const [loader, setLoader] = useState(false);
  const [key, setKey] = useState('newstaff');
  const [showPopup, setShowPopup] = useState(false);
  const [totalStaffDetails, setTotalStaffDetails] = useState();
  const [popupTitle, setPopupTitle] = useState('New Staff Details');
  const [popupData, setPopupData] = useState();

  const [showTotalStaff, setShowTotalStaff] = useState('');
  const [showNewStaff, setShowNewStaff] = useState('show active');
  const [showExistingStaff, setShowExistingStaff] = useState('');
  const [showChangedstaff, setShowChangedstaff] = useState('');
  const [
    showTerminatedstaffroleassignment,
    setShowTerminatedstaffroleassignment
  ] = useState('');

  const [activeTotalStaff, setActiveTotalStaff] = useState('');
  const [activeNewStaff, setActiveNewStaff] = useState('active');
  const [activeExistingStaff, setActiveExistingStaff] = useState('');
  const [activeChangedstaff, setActiveChangedstaff] = useState('');
  const [
    activeTerminatedstaffroleassignment,
    setActiveTerminatedstaffroleassignment
  ] = useState('');

  const handleViewDetailsPopup = v => {
    setPopupData(v);
  };

  const closePopup = () => setShowPopup(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    handleBranchSummaryAndLogs(state?.dataImportId, state?.branchNumber).then(
      () => {
        setLoader(false);
      }
    );
  }, []);

  const onSelectedRowsChange = row => {
    const newRows = row?.map(data => {
      const newRow = data.original;
      return newRow;
    });
    selectableRows.newStaff = newRows;
  };

  const onSelectedRowsExistingStaff = row => {
    const newRows = row?.map(data => {
      const newRow = data.original;
      return newRow;
    });
    selectableRows.changeStaff = newRows;
  };

  useEffect(() => {
    const NewStaffViewDetailsRow = get(
      branchSummaryAndLogs,
      'data.segregatedList.NewStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      viewDetails: state?.status ? (
        <button
          type="button"
          className="btn btn-link table-link custom-align p-0"
          onClick={() => handleViewDetailsPopup(v)}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      ) : (
        <span className="error-note">Error occured</span>
      )
    }));
    setNewStaffViewDetails(NewStaffViewDetailsRow);

    const UnChangedStaffViewDetailsRow = get(
      branchSummaryAndLogs,
      'data.segregatedList.UnChangedStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      displayStaffRecordType: (
        <span
          className={`${
            v.staffRecordType === 'ChangedStaff'
              ? 'warning-badge'
              : 'success-badge'
          }`}
        >
          {v.displayStaffRecordType}
        </span>
      ),
      viewDetails: state?.status ? (
        <button
          type="button"
          className="btn btn-link table-link custom-align p-0"
          onClick={() => handleViewDetailsPopup(v)}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      ) : (
        <span className="error-note">Error occured</span>
      )
    }));
    setUnChangedViewDetails(UnChangedStaffViewDetailsRow);

    const ChangedStaffRow = get(
      branchSummaryAndLogs,
      'data.segregatedList.ChangedStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      displayStaffRecordType: (
        <span
          className={`${
            v.staffRecordType === 'ChangedStaff'
              ? 'warning-badge'
              : 'success-badge'
          }`}
        >
          {v.displayStaffRecordType}
        </span>
      ),
      viewDetails: state?.status ? (
        <button
          type="button"
          className="btn btn-link table-link custom-align p-0"
          onClick={() => handleViewDetailsPopup(v)}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      ) : (
        <span className="error-note">Error occured</span>
      )
    }));
    setChangedStaffViewDetails(ChangedStaffRow);

    const TerminatedStaffViewDetailsRow = get(
      branchSummaryAndLogs,
      'data.segregatedList.TerminatedStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      displayStaffRecordType: (
        <span
          className={`${
            v.staffRecordType === 'StaffRoleChangedStaff'
              ? 'success-badge'
              : 'warning-badge'
          }`}
        >
          {v.displayStaffRecordType}
        </span>
      ),
      viewDetails: state?.status ? (
        <button
          type="button"
          className="btn btn-link table-link custom-align p-0"
          onClick={() => handleViewDetailsPopup(v)}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      ) : (
        <span className="error-note">Error occured</span>
      )
    }));
    setTerminatedStaffViewDetails(TerminatedStaffViewDetailsRow);

    const totalStaffDetailsRow = NewStaffViewDetailsRow.concat(
      ChangedStaffRow,
      UnChangedStaffViewDetailsRow,
      TerminatedStaffViewDetailsRow
    );
    setTotalStaffDetails(totalStaffDetailsRow);
  }, [branchSummaryAndLogs]);

  useEffect(() => {
    if (key === 'totalstaff') {
      setShowTotalStaff('show active');
      setShowNewStaff('');
      setShowExistingStaff('');
      setShowChangedstaff('');
      setShowTerminatedstaffroleassignment('');

      setActiveTotalStaff('active');
      setActiveNewStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('');
      setActiveTerminatedstaffroleassignment('');

      setPopupTitle('Total Staff Details');
    } else if (key === 'newstaff') {
      setShowTotalStaff('');
      setShowNewStaff('show active');
      setShowExistingStaff('');
      setShowChangedstaff('');
      setShowTerminatedstaffroleassignment('');

      setActiveTotalStaff('');
      setActiveNewStaff('active');
      setActiveExistingStaff('');
      setActiveChangedstaff('');
      setActiveTerminatedstaffroleassignment('');

      setPopupTitle('New Staff Details');
    } else if (key === 'unchangedstaff') {
      setShowTotalStaff('');
      setShowNewStaff('');
      setShowExistingStaff('show active');
      setShowChangedstaff('');
      setShowTerminatedstaffroleassignment('');

      setActiveTotalStaff('');
      setActiveNewStaff('');
      setActiveExistingStaff('active');
      setActiveChangedstaff('');
      setActiveTerminatedstaffroleassignment('');

      setPopupTitle('Un-Changed Staff Details');
    } else if (key === 'changedstaff') {
      setShowTotalStaff('');
      setShowNewStaff('');
      setShowExistingStaff('');
      setShowChangedstaff('show active');
      setShowTerminatedstaffroleassignment('');

      setActiveTotalStaff('');
      setActiveNewStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('active');
      setActiveTerminatedstaffroleassignment('');
      setPopupTitle('Changed Staff Details');
    } else if (key === 'terminatedstaffroleassignment') {
      setShowTotalStaff('');
      setShowNewStaff('');
      setShowExistingStaff('');
      setShowChangedstaff('');
      setShowTerminatedstaffroleassignment('show active');

      setActiveTotalStaff('');
      setActiveNewStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('');
      setActiveTerminatedstaffroleassignment('active');
      setPopupTitle('Seperated Staff Details');
    }
  }, [key]);

  const TerminatedStaffTitle = (
    <span>
      {APP_LABELS.DETAIL_TERMINATED}
      <br />
      {APP_LABELS.DETAIL_ASSIGNMENT}
    </span>
  );

  const onkipclick = type => {
    setKey(type);
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper">
          <div className="container">
            <div className="row mb-3 align-items-center">
              <div className="col-11">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb ymca-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/employee/dashboard">
                        {APP_LABELS.NAV_DASHBOARD}
                      </a>
                      <img
                        src={BreadcrumbIcon}
                        className="breadcrumb-icon"
                        alt=""
                      />
                    </li>
                    <li className="breadcrumb-item active">
                      {APP_LABELS.DETAIL_UPLOAD_DATA}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-1 text-end">
                <div
                  className="ymca-title-link d-flex align-items-center c-pointer"
                  aria-hidden="true"
                  onClick={() => {
                    window.history.go(-1);
                  }}
                >
                  <img src={BackIcon} className="me-1" alt="back-icon" />
                  {APP_LABELS.DASH_BACK}
                </div>
              </div>
            </div>

            <section className="info-cards">
              <div className="row">
                <DetailsCard
                  cardTitle="Association"
                  cardValue={get(
                    AssociationNumberData,
                    'data.association.associationName',
                    'Association Name'
                  )}
                />

                <DetailsCard
                  cardTitle="Branch"
                  cardValue={get(state, 'branchName', 'Branch Name')}
                />
                <DetailsCard
                  cardTitle="Source System"
                  cardValue={get(state, 'sourceSystem', '')}
                />

                <DetailsCard
                  cardTitle="Last Updated"
                  cardValue={convertUtcToLocal(get(state, 'uploadedDateTime'))}
                />
              </div>
            </section>
          </div>

          <hr className="custom-hr" />

          <section className="stats-data">
            <div className="container">
              <div className="row ">
                <div id="myTab" role="tablist" className="" tabIndex="0">
                  <div className="nav nav-tabs cursor-not-allowed">
                    <div className="row g-1">
                      <div className="col-lg-auto col-md-6 col-sm-12">
                        <KpiCard
                          id="totalstaff-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#totalstaff"
                          role="tab"
                          ariacontrols="totalstaff"
                          ariaselected="true"
                          activeClass={activeTotalStaff}
                          darkIcon={totalstaffpurpleicon}
                          lightIcon={totalstaffwhiteicon}
                          onkipclick={() => onkipclick('totalstaff')}
                          cardClass="cursor-not-allowed"
                          cardHoverClass="associations"
                          cardName="Total Staff"
                          cardValue={get(
                            branchSummaryAndLogs,
                            'data.fileUploadSummary.totalStaff',
                            'Total Staff'
                          )}
                        />
                      </div>
                      <div className="col-lg-auto col-md-6 col-sm-12">
                        <KpiCard
                          activeClass={activeExistingStaff}
                          id="unchangedstaff-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#unchangedstaff"
                          role="tab"
                          ariacontrols="unchangedstaff"
                          ariaselected="true"
                          darkIcon={unchangedpinkicon}
                          lightIcon={UnChangedwhiteicon}
                          onkipclick={() => onkipclick('unchangedstaff')}
                          cardClass="col-lg-2 me-4"
                          cardHoverClass="profiles"
                          cardName="Un-Changed"
                          cardValue={get(
                            branchSummaryAndLogs,
                            'data.fileUploadSummary.unChangedStaff',
                            'un-changed staff'
                          )}
                        />
                      </div>
                      <div className="col-lg-auto col-md-6 col-sm-12">
                        <KpiCard
                          activeClass={activeChangedstaff}
                          id="changedstaff-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#changedstaff"
                          role="tab"
                          ariacontrols="changedstaff"
                          ariaselected="true"
                          darkIcon={existingstaffblueicon}
                          lightIcon={existingstaffwhiteicon}
                          onkipclick={() => onkipclick('changedstaff')}
                          cardClass="col-lg-2 me-4"
                          cardHoverClass="programs"
                          cardName="Changed Staff"
                          cardValue={get(
                            branchSummaryAndLogs,
                            'data.fileUploadSummary.changedStaff',
                            'changed staff'
                          )}
                        />
                      </div>
                      <div className="col-lg-auto col-md-6 col-sm-12">
                        <KpiCard
                          activeClass={activeNewStaff}
                          id="newstaff-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#newstaff"
                          role="tab"
                          ariacontrols="newstaff"
                          ariaselected="true"
                          darkIcon={newstaffyellowicon}
                          lightIcon={newstaffwhiteicon}
                          onkipclick={() => onkipclick('newstaff')}
                          cardClass="col-lg-2 me-4"
                          cardHoverClass="new-staff"
                          cardName="New Staff"
                          cardValue={get(
                            branchSummaryAndLogs,
                            'data.fileUploadSummary.newStaff',
                            'new staff'
                          )}
                        />
                      </div>

                      <div className="col-lg-auto col-md-6 col-sm-12">
                        <KpiCard
                          activeClass={activeTerminatedstaffroleassignment}
                          id="terminatedstaffroleassignment-tab"
                          databstoggle="tab"
                          databstarget="#terminatedstaffroleassignment"
                          role="tab"
                          ariacontrols="terminatedstaffroleassignment"
                          ariaselected="true"
                          darkIcon={expiredstafforangeicon}
                          lightIcon={terminatedstaffwhiteicon}
                          onkipclick={() =>
                            onkipclick('terminatedstaffroleassignment')
                          }
                          cardClass="col-lg-2 me-4"
                          cardHoverClass="staff-assign"
                          cardName={TerminatedStaffTitle}
                          cardValue={get(
                            branchSummaryAndLogs,
                            'data.fileUploadSummary.terminatedStaffRoleAssignment',
                            'terminated Staff Role Assignment'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ymca-tabs">
            <div className="container">
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade ${showTotalStaff}`}
                  id="totalstaff"
                  role="tabpanel"
                  aria-labelledby="totalstaff-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 className="ymca-section-heading my-3 ms-3">
                        {APP_LABELS.DETAIL_TOTAL_STAFF}
                      </h3>
                    </div>

                    {totalStaffDetails !== undefined &&
                    totalStaffDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={totalStaffDetails}
                            headers={StaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsChange}
                            selectedRows={selectedRows}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: false
                              }
                            ]}
                          />
                        </div>
                    ) : (
                      <div className="col-lg-12 col-sm-12">
                        <div className="record-not-found">
                          <p className="ymca-warning-text">
                            {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${showNewStaff}`}
                  id="newstaff"
                  role="tabpanel"
                  aria-labelledby="newstaff-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 className="ymca-section-heading my-3 ms-3">
                        {APP_LABELS.DETAIL_NEW_STAFF}
                      </h3>
                    </div>

                    {newStaffViewDetails !== undefined &&
                    newStaffViewDetails?.length !== 0 ? (                 
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={newStaffViewDetails}
                            headers={StaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsChange}
                            selectedRows={selectedRows}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: false
                              }
                            ]}
                          />
                        </div>
                    ) : (
                      <div className="col-lg-12 col-sm-12">
                        <div className="record-not-found">
                          <p className="ymca-warning-text">
                            {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${showExistingStaff}`}
                  id="existingstaff"
                  role="tabpanel"
                  aria-labelledby="existingstaff-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 className="ymca-section-heading my-3 ms-3">
                        {APP_LABELS.DETAIL_EXISTING_STAFF}
                      </h3>
                    </div>

                    {unChangedViewDetails !== undefined &&
                    unChangedViewDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={unChangedViewDetails}
                            headers={StaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsExistingStaff}
                            selectedRows={selectedRowsChangeStaff}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: true
                              }
                            ]}
                          />
                        </div>
                    ) : (
                      <div className="col-lg-12 col-sm-12">
                        <div className="record-not-found">
                          <p className="ymca-warning-text">
                            {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${showChangedstaff}`}
                  id="changedstaff"
                  role="tabpanel"
                  aria-labelledby="changedstaff-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 className="ymca-section-heading my-3 ms-3">
                        {APP_LABELS.DETAIL_CHANGED_STAFF}
                      </h3>
                    </div>

                    {changedStaffViewDetails !== undefined &&
                    changedStaffViewDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={changedStaffViewDetails}
                            headers={StaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsExistingStaff}
                            selectedRows={selectedRowsChangeStaff}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: true
                              }
                            ]}
                          />
                        </div>
                    ) : (
                      <div className="col-lg-12 col-sm-12">
                        <div className="record-not-found">
                          <p className="ymca-warning-text">
                            {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${showTerminatedstaffroleassignment}`}
                  id="terminatedstaffroleassignment"
                  role="tabpanel"
                  aria-labelledby="terminatedstaffroleassignment-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 className="ymca-section-heading my-3 ms-3">
                        {APP_LABELS.UPLOAD_DATA_TERMINATED}
                      </h3>
                    </div>

                    {terminatedStaffViewDetails !== undefined &&
                    terminatedStaffViewDetails?.length !== 0 ? (
                      <div className="col-lg-12 col-sm-12">
                        <Table
                          datarows={terminatedStaffViewDetails}
                          headers={StaffColumns}
                          defaultSorted={[
                            {
                              id: 'name',
                              desc: false
                            }
                          ]}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-12 col-sm-12">
                        <div className="record-not-found">
                          <p className="ymca-warning-text">
                            {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      <ViewDetailsPopup
        showViewDetailsPopup={showPopup}
        handleCloseNewStaffPopup={closePopup}
        popupTitle={popupTitle}
        popupData={popupData}
      />
    </BlockUi>
  );
};

DetailView.propTypes = {
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  branchSummaryAndLogs: PropTypes.objectOf(PropTypes.any).isRequired,
  handleBranchSummaryAndLogs: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleBranchSummaryAndLogs: getBranchSummaryAndLogs
};

const mapStateToProps = state => ({
  AssociationNumberData: AssociationNumberSelector(state),
  branchSummaryAndLogs: BranchSummaryLogsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailView);
