export const FETCH_ANNUAL_REPORT_OVERVIEW_SUCCESS =
  'FETCH_ANNUAL_REPORT_OVERVIEW_SUCCESS';
export const FETCH_ANNUAL_REPORT_OVERVIEW_FAILURE =
  'FETCH_ANNUAL_REPORT_OVERVIEW_FAILURE';
export const YMCA_BRANCHES_LIST_SUCCESS = 'FETCH_YMCA_BRANCHES_LIST_SUCCESS';
export const YMCA_BRANCHES_LIST_FAILURE = 'FETCH_YMCA_BRANCHES_LIST_FAILURE';
export const COLLAB_NON_PROFIT_ORG = 'FETCH_COLLAB_NON_PROFIT_ORG';
export const COLLAB_EDUCATION_ORG = 'FETCH_COLLAB_EDUCATION_ORG';
export const COLLAB_GOVT_ORG_AFFILIATES = 'FETCH_COLLAB_GOVT_ORG_AFFILIATES';
export const COLLAB_OTHER_COMMUNITY_ORG = 'FETCH_COLLAB_OTHER_COMMUNITY_ORG';
export const COLLAB_PRIVATE_ORG_BUSINESS = 'FETCH_COLLAB_PRIVATE_ORG_BUSINESS';
export const COLLAB_ORATIONS_AMOUNG_YS = 'COLLAB_ORATIONS_AMOUNG_YS';
export const PROGRAM_ACTIVITIES = 'FETCH_PROGRAM_ACTIVITIES';
export const PROGRAM_ACTIVITIES_HOUSING = 'FETCH_PROGRAM_ACTIVITIES_HOUSING';
export const PROGRAM_ACTIVITIES_FOODPROGRAMS =
  'FETCH_PROGRAM_ACTIVITIES_FOODPROGRAMS';
export const PROGRAM_ACTIVITIES_ARMED_SERVICES =
  'FETCH_PROGRAM_ACTIVITIES_ARMED_SERVICES';
export const PROGRAM_ACTIVITIES_OLDER_ADULTS =
  'FETCH_PROGRAM_ACTIVITIES_OLDER_ADULTS';

export const ANNUAL_REPORT_DASHBOARD_OVERVIEW_SUCCESS =
  'ANNUAL_REPORT_DASHBOARD_OVERVIEW_SUCCESS';
export const ANNUAL_REPORT_DASHBOARD_OVERVIEW_FAILURE =
  'ANNUAL_REPORT_DASHBOARD_OVERVIEW_FAILURE';
