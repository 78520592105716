/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import get from 'lodash.get';
import {
  getBrandVisibility,
  postBrandVisibility,
  resetReduxState
} from '@redux/diversity-inclusion-&-global-metrics/brand-visibility/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetBrandVisibilitySelector } from '@redux/diversity-inclusion-&-global-metrics/brand-visibility/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import TextError from '@modules/shared/components/reusable-components/text-error';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  MarketingMaterialsLanguages: Yup.string().nullable(),
  ImagesReflectingTheDiversity: Yup.string().nullable(),
  TranslationInterpretationServices: Yup.string().nullable(),
  SignageAccessible: Yup.string().nullable(),
  MultipleMarketingChannels: Yup.string().nullable(),
  WebTechnologyPlace: Yup.string().nullable(),

  ArabicLanguages: Yup.array().nullable(),
  ChineseLanguages: Yup.array().nullable(),
  FrenchLanguages: Yup.array().nullable(),
  KoreanLanguages: Yup.array().nullable(),
  PolishLanguages: Yup.array().nullable(),
  PortugueseLanguages: Yup.array().nullable(),
  RussianLanguages: Yup.array().nullable(),
  SpanishLanguages: Yup.array().nullable(),
  TagalogLanguages: Yup.array().nullable(),
  VietnameseLanguages: Yup.array().nullable(),
  OtherLanguages: Yup.array().nullable(),

  PresenceonYMCA: Yup.string().nullable(),

  NewsMediaImpressions: Yup.string().nullable(),

  SocialMediaMessaging: Yup.string().nullable(),

  ychallengesDescription: Yup.string().nullable(),

  otherDescription: Yup.string().nullable()
});

const BrandVisibilityTab = props => {
  const {
    handleGetBrandVisibility,
    handlePostBrandVisibility,
    handleResetRedux,
    GetBrandVisibilitySelectorData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Diversity Inclusion & Global Metrics';

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [help, setHelp] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [resStatus, setResStatus] = useState();
  const formikref = useRef();
  const [ychallengesDescriptionCount, setYchallengesDescriptionCount] =
    useState(
      GetBrandVisibilitySelectorData?.data?.ychallengesDescription?.length || 0
    );
  const [otherDescriptionCount, setOtherDescriptionCount] = useState(
    GetBrandVisibilitySelectorData?.data?.otherDescription?.length || 0
  );
  const [ychallengesDescription, setYchallengesDescription] = useState(
    GetBrandVisibilitySelectorData?.data?.ychallengesDescription
  );
  const [otherDescription, setOtherDescription] = useState(
    GetBrandVisibilitySelectorData?.data?.otherDescription
  );

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = () => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetBrandVisibility(associationsNum).then(res => {
      if (res.status === 200) {
        setYchallengesDescriptionCount(
          res?.data?.data?.ychallengesDescription?.length || 0
        );
        setYchallengesDescription(res?.data?.data?.ychallengesDescription);
        setOtherDescription(res?.data?.data?.otherDescription);
        setOtherDescriptionCount(
          res?.data?.data?.otherDescription?.length || 0
        );
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'brand-visibility'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetBrandVisibility');
    };
  }, []);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const initialValues = {
    MarketingMaterialsLanguages: '',
    ImagesReflectingTheDiversity: '',
    TranslationInterpretationServices: '',
    SignageAccessible: '',
    MultipleMarketingChannels: '',
    WebTechnologyPlace: '',

    ArabicLanguages: [],
    ChineseLanguages: [],
    FrenchLanguages: [],
    KoreanLanguages: [],
    PolishLanguages: [],
    PortugueseLanguages: [],
    RussianLanguages: [],
    SpanishLanguages: [],
    TagalogLanguages: [],
    VietnameseLanguages: [],
    OtherLanguages: [],

    PresenceonYMCA: '',
    NewsMediaImpressions: '',
    SocialMediaMessaging: '',

    otherDescription: ''
  };

  const editValues = {
    MarketingMaterialsLanguages:
      GetBrandVisibilitySelectorData?.data?.marketingMaterialsLanguages[0],
    ImagesReflectingTheDiversity:
      GetBrandVisibilitySelectorData?.data?.imagesReflectingTheDiversity[0],
    TranslationInterpretationServices:
      GetBrandVisibilitySelectorData?.data
        ?.translationInterpretationServices[0],
    SignageAccessible:
      GetBrandVisibilitySelectorData?.data?.signageAccessible[0],
    MultipleMarketingChannels:
      GetBrandVisibilitySelectorData?.data?.multipleMarketingChannels[0],
    WebTechnologyPlace:
      GetBrandVisibilitySelectorData?.data?.webTechnologyPlace[0],

    ArabicLanguages: GetBrandVisibilitySelectorData?.data?.arabicLanguages,
    ChineseLanguages: GetBrandVisibilitySelectorData?.data?.chineseLanguages,
    FrenchLanguages: GetBrandVisibilitySelectorData?.data?.frenchLanguages,
    KoreanLanguages: GetBrandVisibilitySelectorData?.data?.koreanLanguages,
    PolishLanguages: GetBrandVisibilitySelectorData?.data?.polishLanguages,
    PortugueseLanguages:
      GetBrandVisibilitySelectorData?.data?.portugueseLanguages,
    RussianLanguages: GetBrandVisibilitySelectorData?.data?.russianLanguages,
    SpanishLanguages: GetBrandVisibilitySelectorData?.data?.spanishLanguages,
    TagalogLanguages: GetBrandVisibilitySelectorData?.data?.tagalogLanguages,
    VietnameseLanguages:
      GetBrandVisibilitySelectorData?.data?.vietnameseLanguages,
    OtherLanguages: GetBrandVisibilitySelectorData?.data?.otherLanguages,

    PresenceonYMCA: GetBrandVisibilitySelectorData?.data?.presenceonYMCA[0],
    NewsMediaImpressions:
      GetBrandVisibilitySelectorData?.data?.newsMediaImpressions[0],
    SocialMediaMessaging:
      GetBrandVisibilitySelectorData?.data?.socialMediaMessaging[0],

    otherDescription:
      GetBrandVisibilitySelectorData?.data?.otherDescription === ''
        ? ''
        : GetBrandVisibilitySelectorData?.data?.otherDescription
  };

  const onSubmit = values => {
    setLoader(true);
    const payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetBrandVisibilitySelectorData?.data?.createdByName,
      createdByPersonId:
        GetBrandVisibilitySelectorData?.data?.createdByPersonId,
      createdDatetime: GetBrandVisibilitySelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      brandVisibilityId:
        GetBrandVisibilitySelectorData?.data?.brandVisibilityId,
      reportBatchId: GetBrandVisibilitySelectorData?.data?.reportBatchId,
      recordStatus: GetBrandVisibilitySelectorData?.data?.recordStatus,
      marketingMaterialsLanguages: [values.MarketingMaterialsLanguages],
      imagesReflectingTheDiversity: [values.ImagesReflectingTheDiversity],
      translationInterpretationServices: [
        values.TranslationInterpretationServices
      ],
      signageAccessible: [values.SignageAccessible],
      multipleMarketingChannels: [values.MultipleMarketingChannels],
      webTechnologyPlace: [values.WebTechnologyPlace],

      arabicLanguages: values.ArabicLanguages,
      chineseLanguages: values.ChineseLanguages,
      frenchLanguages: values.FrenchLanguages,
      koreanLanguages: values.KoreanLanguages,
      polishLanguages: values.PolishLanguages,
      portugueseLanguages: values.PortugueseLanguages,
      russianLanguages: values.RussianLanguages,
      spanishLanguages: values.SpanishLanguages,
      tagalogLanguages: values.TagalogLanguages,
      vietnameseLanguages: values.VietnameseLanguages,
      otherLanguages: values.OtherLanguages,

      presenceonYMCA: [values.PresenceonYMCA],
      newsMediaImpressions: [values.NewsMediaImpressions],
      socialMediaMessaging: [values.SocialMediaMessaging],

      ychallengesDescription,
      otherDescription
    };

    setSuccessPopup(false);

    handlePostBrandVisibility(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall();
        setLoader(false);
        setSuccessPopup(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handelCount = (value, textareaName) => {
    if (textareaName === 'ychallengesDescription') {
      setYchallengesDescriptionCount(value?.length || 0);
      setYchallengesDescription(value);
    } else if (textareaName === 'otherDescription') {
      setOtherDescriptionCount(value?.length || 0);
      setOtherDescription(value);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ dirty, errors, values, setFieldValue }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-covidRelif">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <p className="ymca-instructTitle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: instructions
                        }}
                      />
                    </p>
                  </div>
                </div>
                {GetBrandVisibilitySelectorData?.data?.recordStatus?.toUpperCase() ===
                  APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}

                <h4 className="ymca-headingTitle">
                  {
                    'Our Y develops and adapts messaging materials and critical signage to ensure that information is accessible and welcoming of all segments of society, with a particular commitment to underserved communities (by the YMCA), as evidenced by the following:'
                  }
                </h4>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th>Fully implemented process</th>
                        <th>Process underway</th>
                        <th>Process not started</th>
                        <th>No plan for implementation</th>
                        <th>Our Y has not identified a need in this area</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody">
                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Marketing materials are in languages (other than
                          English) mirroring local community demographics
                          <ErrorMessage
                            component={TextError}
                            name="MarketingMaterialsLanguages"
                          />
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MarketingMaterialsLanguages"
                              value="fullyImplementedProcessMarketingMaterials"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MarketingMaterialsLanguages"
                              value="processUnderwayMarketingMaterials"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MarketingMaterialsLanguages"
                              value="processNotStartedMarketingMaterials"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MarketingMaterialsLanguages"
                              value="noPlanForImplementationMarketingMaterials"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MarketingMaterialsLanguages"
                              value="yhasNotIdentifiedMarketingMaterials"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Images reflecting the diversity of community members
                          are utilized in collateral
                          <ErrorMessage
                            component={TextError}
                            name="ImagesReflectingTheDiversity"
                          />
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="ImagesReflectingTheDiversity"
                              value="fullyImplementedProcessImagesReflecting"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="ImagesReflectingTheDiversity"
                              value="processUnderwayImagesReflecting"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="ImagesReflectingTheDiversity"
                              value="processNotStartedImagesReflecting"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="ImagesReflectingTheDiversity"
                              value="noPlanForImplementationImagesReflecting"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="ImagesReflectingTheDiversity"
                              value="yhasNotIdentifiedImagesReflecting"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Translation and/or interpretation services are
                          available to members and program participants
                          <ErrorMessage
                            component={TextError}
                            name="TranslationInterpretationServices"
                          />
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="TranslationInterpretationServices"
                              value="fullyImplementedProcessTranslationServices"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="TranslationInterpretationServices"
                              value="processUnderwayTranslationServices"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="TranslationInterpretationServices"
                              value="processNotStartedTranslationServices"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="TranslationInterpretationServices"
                              value="noPlanForImplementationTranslationServices"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="TranslationInterpretationServices"
                              value="yhasNotIdentifiedTranslationServices"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Signage is accessible, welcoming and inclusive
                          <ErrorMessage
                            component={TextError}
                            name="SignageAccessible"
                          />
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="SignageAccessible"
                              value="fullyImplementedProcessSignage"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="SignageAccessible"
                              value="processUnderwaySignage"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="SignageAccessible"
                              value="processNotStartedSignage"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="SignageAccessible"
                              value="noPlanForImplementationSignage"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="SignageAccessible"
                              value="yhasNotIdentifiedSignage"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Multiple marketing channels are available to ensure
                          all people have access to information
                          <ErrorMessage
                            component={TextError}
                            name="MultipleMarketingChannels"
                          />
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MultipleMarketingChannels"
                              value="fullyImplementedProcessMultipleMarketingChannel"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MultipleMarketingChannels"
                              value="processUnderwayMultipleMarketingChannel"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MultipleMarketingChannels"
                              value="processNotStartedMultipleMarketingChannel"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MultipleMarketingChannels"
                              value="noPlanForImplementationMultipleMarketingChannel"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="MultipleMarketingChannels"
                              value="yhasNotIdentifiedMultipleMarketingChannel"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Web technology is in place to support accessibility
                          for language, as well as audio and visual impairment
                          <ErrorMessage
                            component={TextError}
                            name="WebTechnologyPlace"
                          />
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="WebTechnologyPlace"
                              value="fullyImplementedProcessWebTechnology"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="WebTechnologyPlace"
                              value="processUnderwayWebTechnology"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="WebTechnologyPlace"
                              value="processNotStartedWebTechnology"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="WebTechnologyPlace"
                              value="noPlanForImplementationWebTechnology"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="justify-content-center ps-0">
                            <Field
                              type="radio"
                              name="WebTechnologyPlace"
                              value="yhasNotIdentifiedWebTechnology"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  What languages do you provide marketing materials in? Check
                  all that apply.
                </h4>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th>Staff</th>
                        <th className="text-center">Translation Services </th>
                        <th className="text-center">Marketing materials</th>
                        <th>Website</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody">
                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Arabic
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ArabicLanguages"
                              type="checkbox"
                              className="styled-checkbox"
                              id="checkStaffArabic"
                              value="checkStaffArabic"
                            />
                            <label htmlFor="checkStaffArabic" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ArabicLanguages"
                              type="checkbox"
                              className="styled-checkbox"
                              id="checkTranslationServicesArabic"
                              value="checkTranslationServicesArabic"
                            />
                            <label htmlFor="checkTranslationServicesArabic" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ArabicLanguages"
                              type="checkbox"
                              className="styled-checkbox"
                              id="checkMarketingMaterialsArabic"
                              value="checkMarketingMaterialsArabic"
                            />
                            <label htmlFor="checkMarketingMaterialsArabic" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ArabicLanguages"
                              type="checkbox"
                              className="styled-checkbox"
                              id="checkWebsiteArabic"
                              value="checkWebsiteArabic"
                            />
                            <label htmlFor="checkWebsiteArabic" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Chines (including Cantonese and Mandarin)
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ChineseLanguages"
                              id="checkStaffChinese"
                              value="checkStaffChinese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffChinese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ChineseLanguages"
                              id="checkTranslationServicesChinese"
                              value="checkTranslationServicesChinese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesChinese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ChineseLanguages"
                              id="checkMarketingMaterialsChinese"
                              value="checkMarketingMaterialsChinese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsChinese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="ChineseLanguages"
                              id="checkWebsiteChinese"
                              value="checkWebsiteChinese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteChinese" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          French
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="FrenchLanguages"
                              id="checkStaffFrench"
                              value="checkStaffFrench"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffFrench" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="FrenchLanguages"
                              id="checkTranslationServicesFrench"
                              value="checkTranslationServicesFrench"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesFrench" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="FrenchLanguages"
                              id="checkMarketingMaterialsFrench"
                              value="checkMarketingMaterialsFrench"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsFrench" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="FrenchLanguages"
                              id="checkWebsiteFrench"
                              value="checkWebsiteFrench"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteFrench" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Korean
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="KoreanLanguages"
                              id="checkStaffKorean"
                              value="checkStaffKorean"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffKorean" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="KoreanLanguages"
                              id="checkTranslationServicesKorean"
                              value="checkTranslationServicesKorean"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesKorean" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="KoreanLanguages"
                              id="checkMarketingMaterialsKorean"
                              value="checkMarketingMaterialsKorean"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsKorean" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="KoreanLanguages"
                              id="checkWebsiteKorean"
                              value="checkWebsiteKorean"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteKorean" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Polish
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PolishLanguages"
                              id="checkStaffPolish"
                              value="checkStaffPolish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffPolish" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PolishLanguages"
                              id="checkTranslationServicesPolish"
                              value="checkTranslationServicesPolish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesPolish" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PolishLanguages"
                              id="checkMarketingMaterialsPolish"
                              value="checkMarketingMaterialsPolish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsPolish" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PolishLanguages"
                              id="checkWebsitePolish"
                              value="checkWebsitePolish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsitePolish" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Portuguese
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PortugueseLanguages"
                              id="checkStaffPortuguese"
                              value="checkStaffPortuguese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffPortuguese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PortugueseLanguages"
                              id="checkTranslationServicesPortuguese"
                              value="checkTranslationServicesPortuguese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesPortuguese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PortugueseLanguages"
                              id="checkMarketingMaterialsPortuguese"
                              value="checkMarketingMaterialsPortuguese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsPortuguese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PortugueseLanguages"
                              id="checkWebsitePortuguese"
                              value="checkWebsitePortuguese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsitePortuguese" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Russian
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="RussianLanguages"
                              id="checkStaffRussian"
                              value="checkStaffRussian"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffRussian" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="RussianLanguages"
                              id="checkTranslationServicesRussian"
                              value="checkTranslationServicesRussian"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesRussian" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="RussianLanguages"
                              id="checkMarketingMaterialsRussian"
                              value="checkMarketingMaterialsRussian"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsRussian" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="RussianLanguages"
                              id="checkWebsiteRussian"
                              value="checkWebsiteRussian"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteRussian" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Spanish
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SpanishLanguages"
                              id="checkStaffSpanish"
                              value="checkStaffSpanish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffSpanish" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SpanishLanguages"
                              id="checkTranslationServicesSpanish"
                              value="checkTranslationServicesSpanish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesSpanish" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SpanishLanguages"
                              id="checkMarketingMaterialsSpanish"
                              value="checkMarketingMaterialsSpanish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsSpanish" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SpanishLanguages"
                              id="checkWebsiteSpanish"
                              value="checkWebsiteSpanish"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteSpanish" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Tagalog
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="TagalogLanguages"
                              id="checkStaffTagalog"
                              value="checkStaffTagalog"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffTagalog" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="TagalogLanguages"
                              id="checkTranslationServicesTagalog"
                              value="checkTranslationServicesTagalog"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesTagalog" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="TagalogLanguages"
                              id="checkMarketingMaterialsTagalog"
                              value="checkMarketingMaterialsTagalog"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsTagalog" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="TagalogLanguages"
                              id="checkWebsiteTagalog"
                              value="checkWebsiteTagalog"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteTagalog" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Vietnamese
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="VietnameseLanguages"
                              id="checkStaffVietnamese"
                              value="checkStaffVietnamese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffVietnamese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="VietnameseLanguages"
                              id="checkTranslationServicesVietnamese"
                              value="checkTranslationServicesVietnamese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesVietnamese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="VietnameseLanguages"
                              id="checkMarketingMaterialsVietnamese"
                              value="checkMarketingMaterialsVietnamese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsVietnamese" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="VietnameseLanguages"
                              id="checkWebsiteVietnamese"
                              value="checkWebsiteVietnamese"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteVietnamese" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          Other, Please list:
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="OtherLanguages"
                              id="checkStaffOther"
                              value="checkStaffOther"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkStaffOther" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="OtherLanguages"
                              id="checkTranslationServicesOther"
                              value="checkTranslationServicesOther"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkTranslationServicesOther" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="OtherLanguages"
                              id="checkMarketingMaterialsOther"
                              value="checkMarketingMaterialsOther"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkMarketingMaterialsOther" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="OtherLanguages"
                              id="checkWebsiteOther"
                              value="checkWebsiteOther"
                              type="checkbox"
                              className="styled-checkbox"
                            />
                            <label htmlFor="checkWebsiteOther" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <textarea
                  name="otherDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={otherDescription}
                  defaultValue={
                    GetBrandVisibilitySelectorData?.data?.otherDescription
                  }
                  onChange={e => {
                    setFieldValue('otherDescription', e.target.value);
                    handelCount(e.target.value, 'otherDescription');
                  }}
                />
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${otherDescriptionCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle">
                  {`Our Y develops and shares messages that demonstrate our YMCA's
                  commitment to diversity, inclusion and global efforts. How
                  Frequently do these messages appear in the following media
                  channels ?`}
                </h4>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th />
                        <th>Very Often</th>
                        <th>Often </th>
                        <th>Sometimes</th>
                        <th>Rarely</th>
                        <th>Never</th>
                        <th>Our Y has not identified a need in this area</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody">
                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Presence on YMCA website that showcases diversity,
                          inclusion, and global efforts
                          <ErrorMessage
                            component={TextError}
                            name="PresenceonYMCA"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PresenceonYMCA"
                              id="ymcaWesiteVeryOftenPresence"
                              value="ymcaWesiteVeryOftenPresence"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="ymcaWesiteVeryOftenPresence" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PresenceonYMCA"
                              id="ymcaWesiteOftenPresence"
                              value="ymcaWesiteOftenPresence"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="ymcaWesiteOftenPresence" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PresenceonYMCA"
                              id="ymcaWesiteSometimesPresence"
                              value="ymcaWesiteSometimesPresence"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="ymcaWesiteSometimesPresence" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PresenceonYMCA"
                              id="ymcaWesiteRarelyPresence"
                              value="ymcaWesiteRarelyPresence"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="ymcaWesiteRarelyPresence" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PresenceonYMCA"
                              id="ymcaWesiteNeverPresence"
                              value="ymcaWesiteNeverPresence"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="ymcaWesiteNeverPresence" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="PresenceonYMCA"
                              id="ymcaWesiteYnotIdentifiedPresence"
                              value="ymcaWesiteYnotIdentifiedPresence"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="ymcaWesiteYnotIdentifiedPresence" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          News media impressions that highlight YMCA diversity,
                          inclusion, and global efforts
                          <ErrorMessage
                            component={TextError}
                            name="NewsMediaImpressions"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NewsMediaImpressions"
                              id="newsMediaVeryOften"
                              value="newsMediaVeryOften"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="newsMediaVeryOften" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NewsMediaImpressions"
                              id="newsMediaOften"
                              value="newsMediaOften"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="newsMediaOften" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NewsMediaImpressions"
                              id="newsMediaSometimes"
                              value="newsMediaSometimes"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="newsMediaSometimes" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NewsMediaImpressions"
                              id="newsMediaRarely"
                              value="newsMediaRarely"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="newsMediaRarely" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NewsMediaImpressions"
                              id="newsMediaNever"
                              value="newsMediaNever"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="newsMediaNever" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NewsMediaImpressions"
                              id="newsMediaYnotIdentified"
                              value="newsMediaYnotIdentified"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="newsMediaYnotIdentified" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Social media messaging that promote diversity,
                          inclusion, and global efforts
                          <ErrorMessage
                            component={TextError}
                            name="SocialMediaMessaging"
                          />
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SocialMediaMessaging"
                              id="socialMediaMsgVeryOften"
                              value="socialMediaMsgVeryOften"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="socialMediaMsgVeryOften" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SocialMediaMessaging"
                              id="socialMediaMsgOften"
                              value="socialMediaMsgOften"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="socialMediaMsgOften" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SocialMediaMessaging"
                              id="socialMediaMsgSometimes"
                              value="socialMediaMsgSometimes"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="socialMediaMsgSometimes" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SocialMediaMessaging"
                              id="socialMediaMsgRarely"
                              value="socialMediaMsgRarely"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="socialMediaMsgRarely" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SocialMediaMessaging"
                              id="socialMediaMsgNever"
                              value="socialMediaMsgNever"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="socialMediaMsgNever" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="SocialMediaMessaging"
                              id="socialMediaMsgYnotIdentified"
                              value="socialMediaMsgYnotIdentified"
                              type="radio"
                              className="styled-checkbox"
                            />
                            <label htmlFor="socialMediaMsgYnotIdentified" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  Please describe any challenges your Y faces in this area.
                </h4>
                <textarea
                  name="ychallengesDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={ychallengesDescription}
                  defaultValue={
                    GetBrandVisibilitySelectorData?.data?.ychallengesDescription
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'ychallengesDescription')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${ychallengesDescriptionCount}/8000)`}</p>
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={GetBrandVisibilitySelectorData?.data?.updatedByName}
                  Time={GetBrandVisibilitySelectorData?.data?.updatedDatetime}
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      {help && <Help hidePopup={hideShowHelp} keyValue="brand-visibility" />}
    </div>
  );
};

BrandVisibilityTab.propTypes = {
  handleGetBrandVisibility: PropTypes.func.isRequired,
  handlePostBrandVisibility: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetBrandVisibilitySelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetBrandVisibility: getBrandVisibility,
  handlePostBrandVisibility: postBrandVisibility,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetBrandVisibilitySelectorData: GetBrandVisibilitySelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(BrandVisibilityTab));
