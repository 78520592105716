const homePageDataCollection = {
  data: [
    {
      id: 1,
      attributes: {
        createdAt: '2022-10-18T12:45:13.908Z',
        updatedAt: '2023-10-11T10:32:57.622Z',
        publishedAt: '2022-11-14T10:03:19.952Z',
        Title: 'Home Page',
        Slug: 'home-page',
        Data_Collection:
          '<p><span style="color:hsl(0,0%,0%);font-size:27px;"><strong>Data Collection Hub</strong></span></p><p>Welcome to the YMCA Data Collection Hub, a new and improved platform for submitting data to Y-USA!</p>',
        Quick_Information:
          '<p>&nbsp;<span style="font-size:19px;"><strong>Quick Information&nbsp;</strong></span></p><p><span style="font-family:Verdana, Geneva, sans-serif;"><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="ymca-list-icon.svg"> &nbsp;</span><a target="_blank" rel="noopener noreferrer" href="https://experience.arcgis.com/experience/cee808c0392b4d068ed30c35f823361c/"><span style="color:#00AEEF;font-family:Verdana, Geneva, sans-serif;"><u>Insights Hub</u></span></a></p><p><span style="font-family:Verdana, Geneva, sans-serif;"><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="ymca-list-icon.svg">&nbsp;</span><span style="color:#00AEEF;font-family:Verdana, Geneva, sans-serif;"> </span><a target="_blank" rel="noopener noreferrer" href="https://link.ymca.net/mlink/file/NDg1OTEx"><span style="color:#00AEEF;font-family:Verdana, Geneva, sans-serif;"><u>Annual Report Nonfinancials Guide</u></span></a></p><p><span style="font-family:Verdana, Geneva, sans-serif;"><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="ymca-list-icon.svg">&nbsp;</span><span style="color:#00AEEF;font-family:Verdana, Geneva, sans-serif;"> </span><a target="_blank" rel="noopener noreferrer" href="https://link.ymca.net/mlink/file/NDkyMjQz"><span style="color:#00AEEF;font-family:Verdana, Geneva, sans-serif;"><u>Annual Report Nonfinancials Data Collection Template</u></span></a></p><p><span style="font-family:Verdana, Geneva, sans-serif;"><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="ymca-list-icon.svg">&nbsp;</span><span style="color:#00AEEF;font-family:Verdana, Geneva, sans-serif;"> </span><a target="_blank" rel="noopener noreferrer" href="https://link.ymca.net/mlink/file/NTEwNzc1"><span style="color:#00AEEF;font-family:Verdana, Geneva, sans-serif;"><u>Employee Update Guide</u></span></a></p>',
        Employee_Data_Collection:
          '<p>The Employee Update Module is for bulk updating employee records. You will still be able to make staff updates directly in  Manage Our Y Profile (MOYP) like you’ve done in the past but now you can also use this module to edit or replace those records in MOYP System via a bulk upload process. Changes made here will be visible in MOYP within 24 hours after submission.&nbsp;</p>',
        Employee_Data_Collection_Title: '<p>Employee Data</p>',
        Employee_Submit_Date: '<p>Submit by January 31, 2024</p>',
        Employee_Data_Image:
          '<p><img src="https://datacollectionhub-cms.y.org/uploads/emp_data_exchange_img_685c979581.svg" alt="emp-data-exchange-img.svg"></p>',
        Annual_Report_Collection:
          '<p>Submit your Nonfinancial data using the Annual Report Module.</p>',
        Annual_Report_Collection_Title: '<p>Annual Report</p>',
        Annual_Report_Submit_Date: '<p>Submit by January 31, 2024</p>',
        Annual_Report_Image:
          '<p><img src="https://datacollectionhub-cms.y.org/uploads/camp_data_img_1_ce31bcb5d2.svg" alt="camp-data-img (1).svg"></p>',
        EmployeeTab: true,
        AnnualReportTab: true,
        Volunteer_Registration_Title: '<p>Volunteer Registration</p>',
        Volunteer_Registration_Date: '<p>Submit by March 15, 2023&nbsp;</p>',
        Volunteer_Registration_Card_Text:
          "<p>Thank you for serving as a volunteer at one or&nbsp;more YMCA national youth event. As part of the YMCA's efforts to provide a safe and positive&nbsp;environment in which youth can reach their&nbsp;full potential, all national youth event volunteers complete an annual online&nbsp;registration. Individuals enter their&nbsp;identification information and upload the certificates or documents required for the&nbsp;event(s) in which they will be serving in a volunteer capacity. &nbsp;</p>",
        Volunteer_Registration_Image:
          '<p><img src="https://datacollectionhub-cms.y.org/uploads/YMCA_volunterr_registration_bed24964bf.png" alt="YMCA-volunterr-registration.png"></p>',
        VolunteerRegistratioTab: true,
        Chronic_Disease_Program_Title: '<p>Chronic Disease Programs</p>',
        Chronic_Disease_Program_Date: '<p>Submit information</p>',
        Chronic_Disease_Program_Card_Text:
          "<span>Submit your Chronic Disease Program data using the Chronic Disease Program Module.<li>YMCA's Blood Pressure Self-Monitoring <span style='padding-left:22px'>Program</span></li><li>YMCA's Diabetes Prevention Program</li><li>Enhance<sup>®</sup>Fitness</li><li>Healthy Weight and Your Child</li><li>LIVESTRONG<sup>®</sup> at the YMCA</li></span><p><p>This module should also be used to upload grant program data (I.e., Department of Labor Youth Workforce Pathways).</p></p>",
        Chronic_Disease_Program_Image:
          '<p><img src="https://datacollectionhub-cms.y.org/uploads/chronic_disease_program_image_42a7eb0ec2.png" alt="chronic_disease_program_75106e88e2.png"></p>',
        ChronicDiseaseProgramTab: true,
        SwimTab: true,
        Swim_Safety_Title: '<p>Safety Around Water Program</p>',
        Swim_Safety_Date: '<p>Submit information</p>',
        Swim_Safety_Card_Text:
          '<p>Submit your Safety Around Water program data, Swim Lessons data for stages 1-3, as well as Swim Skills Grant Data using the Safety Around Water Program Module.</p>',
        Swim_Safety_Image:
          '<p><img src="https://datacollectionhub-cms.y.org/uploads/swim_program_b8e4fbcfa2.png" alt="swim-program.png"></p>',
        Liability_Title: '<p>Certificate of Liability Insurance</p>',
        Liability_Date: '<p>Submit Document</p>',
        Liability_Card_Text:
          '<p>Each YMCA represented by participants in any YMCA national competitive event is required to have a certificate of liability insurance on file, valid through the end of the event.</p><p>Local YMCAs upload your certificate of liability insurance here.</p>',
        Liability_Image:
          '<p><img src="https://datacollectionhub-cms.y.org/uploads/certificate_of_insurance_image_b709b6455b.png" alt="YMCA-volunterr-registration.png"></p>',
        LiabilityTab: true
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pageSize: 25,
      pageCount: 1,
      total: 1
    }
  }
};

export default homePageDataCollection;
