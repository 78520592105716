import { asyncRequest } from '@config/http-config';
import {
  CDP_DETAIL_URLS,
  POST_CDP_WELLD_IMPORT
} from '@config/api-config';

import { CDP } from '@config/app-config';
import * as types from './types';

const getDetails = (
  cdpDataFetch,
  dispatch,
  getState,
  dataSystemEnum,
  programEnum,
  type
) =>
  asyncRequest({
    url: CDP_DETAIL_URLS.CDP_DS_DETAIL(
      cdpDataFetch.cdpDataFetchId,
      dataSystemEnum,
      programEnum,
      type
    ),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_FETCH_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_FETCH_FAILURE,
      payload: error
    }),
    dispatch
  });

const getRedcapFetch = async (
  cdpDataFetchId,
  dispatch,
  getState,
  programEnum
) =>
  asyncRequest({
    url: CDP_DETAIL_URLS.REDCAP_DETAIL(cdpDataFetchId, programEnum),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_FETCH_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_FETCH_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCdpFetchDetailsAction =
  (cdpDataFetch, fetchType, dataSystemEnum, type, programEnum) =>
    (dispatch, getState) => {
      switch (fetchType) {
        case CDP.DATA_SYSTEM_NAMES.REDCAP:
          return getRedcapFetch(
            cdpDataFetch.cdpDataFetchId,
            dispatch,
            getState,
            programEnum
          );

        case CDP.DATA_SYSTEM_NAMES.DOL:
        case CDP.DATA_SYSTEM_NAMES.SOLERA:
        case CDP.DATA_SYSTEM_NAMES.OCHI:
        case CDP.DATA_SYSTEM_NAMES.HABITNU:
        case CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC:
        case CDP.DATA_SYSTEM_NAMES.WELLD:
        case CDP.DATA_SYSTEM_NAMES.ODES:
          return getDetails(
            cdpDataFetch,
            dispatch,
            getState,
            dataSystemEnum,
            programEnum,
            type
          );
        default:
          break;
      }
    };

export const getCdpDataFetchAction = cdpDataFetchId => (dispatch, getState) =>
  asyncRequest({
    url: CDP_DETAIL_URLS.CDP_DATA_FETCH(cdpDataFetchId),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_DATA_FETCH_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_DATA_FETCH_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postRedCapImportAction = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: CDP_DETAIL_URLS.REDCAP_IMPORT(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_CDP_REDCAP_IMPORT_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_CDP_REDCAP_IMPORT_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postSoleraImportAction = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: CDP_DETAIL_URLS.CDP_DS_IMPORT(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_CDP_SOLERA_IMPORT_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_CDP_SOLERA_IMPORT_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postWellDImportAction = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: POST_CDP_WELLD_IMPORT(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_CDP_WELLD_IMPORT_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_CDP_WELLD_IMPORT_FAILURE,
      payload: error
    }),
    dispatch
  });


export const setPopoverDetails = payload => (dispatch) =>
  dispatch({
    type: types.SET_POPOVER_DETAILS,
    payload
  });