/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { getSubmitInfo } from '@redux/review-submit/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { ReviewSubmitInfoSelector } from '@redux/review-submit/selectors';
import { GET_ANNUALREPORT_PDF_DOWNLOAD_PATH } from '@config/api-config';
import { connect } from 'react-redux';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { Redirect } from 'react-router-dom';
import { APP_LABELS } from '@config/app-config';
import Help from '@modules/shared/components/reusable-components/help';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import TextError from '@modules/shared/components/reusable-components/text-error';
import axios from 'axios';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';
import { DashboardOverviewSelector } from '@redux/annualReport-overview/selectors';
import CustomModal from '@utilities/CustomModal';
import helpicon from '@static/images/caretdoubleright.svg';
import pdfIcon from '@static/images/pdfIcon.svg';
import Popup from './popup';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  membership: Yup.string().required('* Please select an option'),
  financial: Yup.string().required('* Please select an option'),
  workforceDiversity: Yup.string().required('* Please select an option'),
  generalStaff: Yup.string().required('* Please select an option'),
  acknowledge: Yup.boolean().oneOf([true], '* You must acknowledge')
});

const ReviewSubmitTab = props => {
  const {
    LoginUserData,
    handleToGetReviewSubmit,
    setLoader,
    AssociationNumberData,
    AnnualReportsDashboardData,
    ReviewSubmitInfoSelectorData,
    handleGetAnnualReportsDashboard
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');

  const authToken = JSON.parse(sessionStorage.getItem('token'));
  const [inCompleteList, setInCompleteList] = useState([]);
  const [dataStatus, setDataStatus] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorPopup, setErrorPopup] = useState(false);
  const [help, setHelp] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [formData, setFormData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [reviewSubmitRating, setReviewSubmitRating] = useState();
  const sectionName = 'Review & Submit';

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNum =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', '0');

  const initialValues = {
    membership: '',
    financial: '',
    workforceDiversity: '',
    generalStaff: '',
    acknowledge: false
  };

  const editValues = {
    membership:
      ReviewSubmitInfoSelectorData?.reviewSubmitData?.membership === null
        ? ''
        : `${ReviewSubmitInfoSelectorData?.reviewSubmitData?.membership}`,
    financial:
      ReviewSubmitInfoSelectorData?.reviewSubmitData?.financial === null
        ? ''
        : `${ReviewSubmitInfoSelectorData?.reviewSubmitData?.financial}`,
    workforceDiversity:
      ReviewSubmitInfoSelectorData?.reviewSubmitData?.workforceDiversity ===
      null
        ? ''
        : `${ReviewSubmitInfoSelectorData?.reviewSubmitData?.workforceDiversity}`,
    generalStaff:
      ReviewSubmitInfoSelectorData?.reviewSubmitData?.genralStaff === null
        ? ''
        : `${ReviewSubmitInfoSelectorData?.reviewSubmitData?.genralStaff}`,
    acknowledge: false
  };

  useEffect(() => {
    window.scroll(0, 0);
    setLoader(true);

    handleToGetReviewSubmit(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    )
      .then(res => {
        setDataStatus(res?.data?.data.status);
        setInCompleteList(get(res, 'data.data.dashboardMenuList', []));
        setLoader(false);
        window.scroll(0, 0);
      })
      .catch(() => {
        setLoader(false);
        setErrorPopup(true);
      });

    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'review-and-submit'
      ]?.Instruction
    );
  }, []);
  useEffect(() => {
    handleGetAnnualReportsDashboard(associationNum, selectedYear).then(
      dashData => {
        dashData?.data?.data?.dashboardDTOs.forEach(record => {
          if (
            record.name === sectionName &&
            record.status === 'Submitted' &&
            record.rating === null &&
            selectedYear >= '2023' &&
            role !== APP_LABELS.YUSA_DATA_STEWARD &&
            role !== APP_LABELS.YESS_DATA_STEWARD
          ) {
            setFormId(record?.formId);
            setReviewSubmitRating(record.rating);
          }
        });
      }
    );
  }, [submitStatus]);

  const handleSubmit = values => {
    if (Object.keys(validationSchema.cast(values)).length === 5) {
      setIsModalOpen(true); // Open the modal if the form is valid
    }
    setFormData(values);
  };

  const downloadPdfApi = () => {
    const associationname = get(
      AssociationNumberData,
      'data.association.associationName',
      ''
    );
    setLoader(true);
    const Offset = new Date().getTimezoneOffset();
    axios
      .get(
        GET_ANNUALREPORT_PDF_DOWNLOAD_PATH(
          role === APP_LABELS.YUSA_DATA_STEWARD ||
            role === APP_LABELS.YUSA_DATA_VIEWER ||
            role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
            role === APP_LABELS.YESS_DATA_STEWARD
            ? get(associateData, 'associationNumber', '')
            : get(LoginUserData, 'user.associations[0]', '0'),
          selectedYear,
          Offset
        ),
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'blob',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': window.location.origin,
            'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
            Authorization: `Bearer ${get(authToken, 'token', '')}`
          }
        }
      )
      .then(res => {
        const fileName = `${
          role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
            ? get(associateData, 'associationName', '')
            : associationname
        }-AnnualReport.pdf`;
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName.replaceAll('"', ''));
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        setErrorPopup(true);
      });
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);

    if (errorKeys?.length > 0) {
      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={editValues || initialValues}
        validationSchema={selectedYear !== '2022' ? validationSchema : null}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ dirty, errors }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <>
                <div className="ymca-completeBox ymca-completeBox ymca-overDraftView ymca-covidRelif">
                  <button
                    type="button"
                    className="ymca-help-link text-decoration-none annual-report-help"
                    onClick={handleShowHelp}
                  >
                    HELP{/* */}
                    <img
                      src={helpicon}
                      className="img-fluid"
                      alt="caret-icon"
                    />
                  </button>
                  <div className="ymca-program p-1">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="YMCA-outline">
                          <h4 className="ymca-programActivities mt-0 pb-2">
                            Review & Submit
                          </h4>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                            <div className="ymca-instructionBoxes">
                              <h4 className="ymca-instructHeadline">
                                {APP_LABELS.INSTRUCTIONS}
                              </h4>
                              <p className="ymca-instructTitle">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: instructions
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                          {selectedYear !== '2022' && (
                            <div className="ymca-programs">
                              <h6 className="ymca-programActivities txt-mandatory">
                                Do you wish to view other Ys summary* data for
                                comparison purposes? If yes, please opt in to
                                share yours as well. Please indicate the summary
                                data areas you are willing to share:
                              </h6>
                              <div className="ymca-covidRelif pt-0">
                                <div className="ymca-tableResponsive">
                                  <table className="table ymca-table table-striped">
                                    <thead>
                                      <tr className="ymca-cuttingData">
                                        <th className="ymca-defaultSpace">
                                          &nbsp;
                                        </th>
                                        <th>Yes</th>
                                        <th>No</th>
                                        <th className="ymca-defaultSpace">
                                          &nbsp;
                                        </th>
                                        <th className="ymca-defaultSpace">
                                          &nbsp;
                                        </th>
                                        <th className="ymca-defaultSpace">
                                          &nbsp;
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ymca-cuttingBody">
                                      <tr>
                                        <td className="ymca-normalWrap txt-mandatory mt-2">
                                          Membership
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="membership"
                                              value="true"
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name="membership"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="membership"
                                              value="false"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="ymca-normalWrap txt-mandatory">
                                          Financial
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="financial"
                                              value="true"
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name="financial"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="financial"
                                              value="false"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="ymca-normalWrap txt-mandatory">
                                          Workforce Diversity
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="workforceDiversity"
                                              value="true"
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name="workforceDiversity"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="workforceDiversity"
                                              value="false"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="ymca-normalWrap txt-mandatory">
                                          General staff
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="generalStaff"
                                              value="true"
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name="generalStaff"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-radioBTN">
                                          <div className="">
                                            <Field
                                              type="radio"
                                              name="generalStaff"
                                              value="false"
                                            />
                                          </div>
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                        <td className="ymca-defaultSpace">
                                          &nbsp;
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}

                          {AnnualReportsDashboardData?.data?.status?.toUpperCase() !== 'YETTOBEGIN' && (
                            <button
                              className="btn ymca-pdfFormat d-flex custom-align"
                              type="button"
                              onClick={downloadPdfApi}
                            >
                              <img
                                src={pdfIcon}
                                alt="download-template-icon"
                                className="img-fluid me-2 template-icon"
                              />
                              {/* */}
                              Download PDF
                            </button>
                          )}
                          <div className="d-flex ymca-listingERA mt-4">
                            <Field
                              className="styled-checkbox"
                              type="checkbox"
                              name="acknowledge"
                            />
                            <label
                              htmlFor="acknowledge"
                              className="ymca-cachet-normal-dark-text ms-2"
                            >
                              {APP_LABELS.REVIEW_SUBMIT_CHECKBOX_TEXT}
                            </label>
                          </div>
                          <ErrorMessage
                            component={TextError}
                            name="acknowledge"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      className="btn ymca-submitBlue d-flex custom-align"
                      type="submit"
                      onClick={() => scrollToErrors(errors)}
                    >
                      Submit
                    </button>
                  )}
              </>
            </Form>
          );
        }}
      </Formik>

      {dataStatus?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED && (
        <div className="alert alert-danger" role="alert">
          {APP_LABELS.REVIEW_SUBMIT_SUBMITTED_TEXT}
        </div>
      )}

      <Popup
        modalOpen={isModalOpen}
        handleClosePopup={() => {
          setIsModalOpen(false);
        }}
        inCompleteList={inCompleteList}
        setSubmitStatus={setSubmitStatus}
        setShowPopup={setShowPopup}
        setLoader={setLoader}
        fromValues={formData}
        remaingTabs={
          ReviewSubmitInfoSelectorData?.data?.dashboardMenuList.length
        }
        ReviewSubmitInfoSelectorData={ReviewSubmitInfoSelectorData}
        associationNum={associationNum}
      />

      <CustomModal
        show={showPopup}
        type={submitStatus ? APP_LABELS.SUCCESS : APP_LABELS.ERROR}
        message={
          submitStatus
            ? APP_LABELS.REVIEW_REDIRECTING_TO_DASHBOARD
            : APP_LABELS.COMMON_ERROR_MESSAGE
        }
        submitButtonName={submitStatus ? 'Ok' : 'Cancel'}
        onSubmit={() => {
          setShowPopup(false);
          if (submitStatus && selectedYear === '2022') setRedirect(true);
          if (
            submitStatus &&
            selectedYear !== '2022' &&
            reviewSubmitRating === null
          ) {
            setFeedbackPopup(true);
          } else {
            setRedirect(true);
          }
        }}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
          if (submitStatus) setRedirect(true);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNum}
        formId={formId}
      />

      {redirect && <Redirect to="/annual/report/dashboard" />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={APP_LABELS.COMMON_ERROR_MESSAGE}
        showErrorPopup={errorPopup}
      />

      {help && <Help hidePopup={hideShowHelp} keyValue="review-and-submit" />}
    </div>
  );
};

ReviewSubmitTab.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleToGetReviewSubmit: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  AnnualReportsDashboardData: PropTypes.objectOf(PropTypes.any).isRequired,
  ReviewSubmitInfoSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationNumberData: AssociationNumberSelector(state),
  AnnualReportsDashboardData: DashboardOverviewSelector(state),
  ReviewSubmitInfoSelectorData: ReviewSubmitInfoSelector(state)
});

const mapDispatchToProps = {
  handleToGetReviewSubmit: getSubmitInfo,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ReviewSubmitTab));
