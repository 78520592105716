import moment from 'moment';

// To convert UTC date time to Local date time
const convertUtcToLocal = (utcDateTime, dateFormat = 'DD MMM YYYY hh:mm a') => {
  if (utcDateTime) {
    const lastUpdated = moment(utcDateTime).format(dateFormat);
    const stillUtc = moment.utc(lastUpdated).toDate();
    const localDateTime = moment(stillUtc).local().format(dateFormat);
    return localDateTime;
  }
  return '----';
};

export default convertUtcToLocal;
