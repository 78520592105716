import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { APP_LABELS } from '@config/app-config';
import PropTypes from 'prop-types';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';
import get from 'lodash.get';
import moment from 'moment';
import getMoypDetailView from '@redux/moyp-detail-view/actions';
import { MoypDetailViewSelector } from '@redux/moyp-detail-view/selectors';
import DetailsCard from '@modules/shared/components/reusable-components/details-card';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import BackIcon from '@static/images/back-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import ViewDetailsPopup from '@modules/shared/components/reusable-components/view-details-popup';
import Columns from './column';
import Table from './Table';

const MoypDetailView = props => {
  const {
    handleMoypDetailView,
    handleMoypDetailData,
    location,
    AssociationNumberData,
    LoginUserData
  } = props;

  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState();
  const { state } = location;
  const Offset = new Date().getTimezoneOffset();
  useEffect(() => {
    const associationNumber =
      get(LoginUserData, 'user.role[0]', '0') ===
        APP_LABELS.YUSA_DATA_STEWARD ||
      get(LoginUserData, 'user.role[0]', '0') === APP_LABELS.YUSA_DATA_VIEWER ||
      get(LoginUserData, 'user.role[0]', '0') ===
        APP_LABELS.ALLIANCE_DATA_STEWARD ||
      get(LoginUserData, 'user.role[0]', '0') === APP_LABELS.YESS_DATA_STEWARD
        ? state?.associationNumber
        : get(LoginUserData, 'user.associations[0]', '0');
    window.scrollTo(0, 0);
    setLoader(true);
    handleMoypDetailView(
      associationNumber,
      moment(state?.lastUpdatedDate).format('MM-DD-YYYY'),
      Offset
    ).then(() => {
      setLoader(false);
    });
  }, []);
  const handleViewDetailsPopup = v => {
    setShowPopup(true);
    setPopupData(v);
  };
  const moypdetailviewdata = handleMoypDetailData?.data?.map(data => ({
    UploadDateTime: get(data, 'updatedDate')
      ? moment(get(data, 'updatedDate')).format('DD MMM YYYY hh:mm a')
      : '-',
    Name: `${get(data, 'firstName', '-')} ${get(data, 'lastName', '-')}`,
    StaffType: get(data, 'staffType', '-'),
    CurrentUniqueJobTitle: get(data, 'currentJobTitle', '-'),
    RecordType: get(data, 'displayStaffRecordType', '-'),
    viewDetails: state?.status ? (
      <button
        type="button"
        className="btn btn-link table-link custom-align p-0"
        onClick={() => handleViewDetailsPopup(data)}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {APP_LABELS.DASH_VIEW_DETAILS}
      </button>
    ) : (
      <span className="error-note">Error occured</span>
    )
  }));
  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper">
          <div className="container">
            <div className="row mb-3 align-items-center">
              <div className="col-11">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb ymca-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/employee/dashboard">
                        {APP_LABELS.NAV_DASHBOARD}
                      </a>
                      <img
                        src={BreadcrumbIcon}
                        className="breadcrumb-icon"
                        alt=""
                      />
                    </li>
                    <li className="breadcrumb-item active">
                      {APP_LABELS.DETAIL_UPLOAD_DATA}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-1 text-end">
                <div
                  className="ymca-title-link d-flex align-items-center c-pointer"
                  aria-hidden="true"
                  onClick={() => {
                    window.history.go(-1);
                  }}
                >
                  <img src={BackIcon} className="me-1" alt="back-icon" />
                  {APP_LABELS.DASH_BACK}
                </div>
              </div>
            </div>

            <section className="info-cards">
              <div className="row">
                <DetailsCard
                  cardTitle="Association"
                  cardValue={get(
                    AssociationNumberData,
                    'data.association.associationName',
                    ''
                  )}
                />
                <DetailsCard
                  cardTitle="Source System"
                  cardValue={get(state, 'sourceSystem', '')}
                />

                <DetailsCard
                  cardTitle="Last Updated"
                  lastUpdatedDate
                  cardValue={
                    get(state, 'lastUpdatedDate')
                      ? moment(get(state, 'lastUpdatedDate')).format(
                          'DD MMM YYYY hh:mm a'
                        )
                      : ''
                  }
                />
              </div>
            </section>
          </div>

          <hr className="custom-hr" />
        </div>
        <div className="container border">
          <div className="col-lg-6 col-md-6 ymca-section-heading my-3 ms-3">
            Change Log Details
          </div>
          <div className="col-lg-12 col-sm-12">
            <Table rows={moypdetailviewdata} headers={Columns} />
          </div>
        </div>
      </main>

      <ViewDetailsPopup
        showViewDetailsPopup={showPopup}
        popupTitle=" Change Log Details"
        popupData={popupData}
      />
    </BlockUi>
  );
};
MoypDetailView.propTypes = {
  handleMoypDetailData: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleMoypDetailView: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapDispatchToProps = {
  handleMoypDetailView: getMoypDetailView
};
const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationNumberData: AssociationNumberSelector(state),
  handleMoypDetailData: MoypDetailViewSelector(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(MoypDetailView);
