/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import jwt from 'jwt-decode';
import PropTypes from 'prop-types';
import { getAssociationAdminDetails } from '@redux/login/actions';
import { connect } from 'react-redux';
import { APP_LABELS, STORAGE_KEYS } from '@config/app-config';
import BlockUi from 'react-block-ui';
import get from 'lodash.get';
import { Loader } from 'react-loaders';
import SecureStorage from '../../utilities/SecureStorage';

const PostLogin = props => {
  const { handleToGetLoginInfo } = props;
  const [redirect, setRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    // eslint-disable-next-line react/prop-types
    const result = props.location.hash.split('=');
    const returnValue = result[result.length - 1];
    const receivedToke = jwt(returnValue);
    if (receivedToke.extension_PersonID === undefined) {
      setRedirectValue('/user/error/page');
      setLoader(false);
      setRedirect(true);
    }
    if (parseInt(receivedToke?.extension_PersonID) > 0) {
      handleToGetLoginInfo(returnValue).then(response => {
        if (response?.data?.tokenType?.length > 0) {
          if (response.status === 200 && response.data.token) {
            sessionStorage.setItem('token', JSON.stringify(response.data));
            SecureStorage.setItem(
              STORAGE_KEYS.PERSON_ID,
              receivedToke?.extension_PersonID
            );
            const role = get(response.data, 'user.role[0]', null);
            const branches = get(response.data, 'user.branches', null);
            const UserManagementInfo = get(
              response.data,
              'user.UserManagementInfo',
              null
            );
            const associations = get(response.data, 'user.associations', null);
            if (
              (branches?.length === 0 &&
                role === APP_LABELS.BRANCH_DATA_STEWARD) ||
              (role === null && UserManagementInfo === null) ||
              (associations?.length === 0 &&
                (role === APP_LABELS.ASSOCIATION_DATA_STEWARD ||
                  role === APP_LABELS.CEO_DATA_STEWARD ||
                  role === APP_LABELS.CVO_DATA_STEWARD))
            ) {
              setRedirectValue('/access/error/page');
            } else {
              setRedirectValue('/home');
            }
            setLoader(false);
            setRedirect(true);
          }
        } else {
          setLoader(false);
          setRedirectValue('/user/error/page');
          setRedirect(true);
        }
      });
    }
  }, []);
  return (
    <div className="custom-block">
      <BlockUi
        blocking={loader}
        message="Signing in..."
        loader={<Loader active type="semi-circle-spin" />}
      >
        <div>{redirect && <Redirect to={redirectValue} />}</div>
      </BlockUi>
    </div>
  );
};

PostLogin.propTypes = {
  handleToGetLoginInfo: PropTypes.func.isRequired
};
const mapDispatchToProps = {
  handleToGetLoginInfo: getAssociationAdminDetails
};

export default connect(null, mapDispatchToProps)(PostLogin);
