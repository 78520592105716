/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  getProgramMember,
  postProgramMember,
  resetReduxState
} from '@redux/constituency-report/programs-members/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetProgramMemberSelector } from '@redux/constituency-report/programs-members/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import PropTypes from 'prop-types';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import TextError from '@modules/shared/components/reusable-components/text-error';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const ProgramMemberTab = props => {
  const {
    setLoader,
    handleGetProgramMember,
    handleResetRedux,
    handlePostProgramMember,
    GetProgramMemberSelectorData,
    LoginUserData,
    handleGetAnnualReportsDashboard,
    status
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Constituency Report';

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [help, setHelp] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [resStatus, setResStatus] = useState();
  const formikref = useRef();
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationsNum =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', '0');

  const handelGetCall = success => {
    setLoader(true);

    handleGetProgramMember(associationsNum).then(res => {
      if (res.status === 200) {
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))['program-members']
        ?.Instruction
    );
    return () => {
      handleResetRedux('GetProgramMember');
    };
  }, []);

  const initialValues = {
    yourAssociationPrograms: 'associationOfferPrograms',
    facilityMemberUnit: '',

    male1To5: '',
    female1To5: '',
    additionalIdentities1To5: '',
    unknown1To5: '',

    male6To11: '',
    female6To11: '',
    additionalIdentities6To11: '',
    unknown6To11: '',

    male12To17: '',
    female12To17: '',
    additionalIdentities12To17: '',
    unknown12To17: '',

    male18To29: '',
    female18To29: '',
    additionalIdentities18To29: '',
    unknown18To29: '',

    male30To54: '',
    female30To54: '',
    additionalIdentities30To54: '',
    unknown30To54: '',

    male55To64: '',
    female55To64: '',
    additionalIdentities55To64: '',
    unknown55To64: '',

    male65Plus: '',
    female65Plus: '',
    additionalIdentities65Plus: '',
    unknown65Plus: '',

    maleUnknown: '',
    femaleUnknown: '',
    additionalIdentitiesUnknown: '',
    unknownUnknown: '',

    total1To5: '',
    total6To11: '',
    total12To17: '',
    total18To29: '',
    total30To54: '',
    total55To64: '',
    total65Plus: '',
    totalUnknownRow: '',

    totalMale: '',
    totalFemale: '',
    totalAdditionalIdentities: '',
    totalUnknown: '',
    totaltotal: ''
  };

  const getFacilityMemberUnit = () => {
    const facilityMemberDuplicated =
      GetProgramMemberSelectorData?.data?.facilityMemberDuplicated === true;
    const facilityMemberUnDuplicated =
      GetProgramMemberSelectorData?.data?.facilityMemberUnDuplicated === true;

    if (facilityMemberDuplicated) {
      return 'facilityMemberDuplicated';
    }
    if (facilityMemberUnDuplicated) {
      return 'facilityMemberUnDuplicated';
    }
    return null;
  };

  const editValues = {
    yourAssociationPrograms:
      GetProgramMemberSelectorData?.data?.yourAssociationPrograms[0] === null ||
      GetProgramMemberSelectorData?.data?.yourAssociationPrograms[0] ===
        undefined ||
      GetProgramMemberSelectorData?.data?.yourAssociationPrograms?.length === 0
        ? 'associationOfferPrograms'
        : GetProgramMemberSelectorData?.data?.yourAssociationPrograms[0],
    facilityMemberUnit: getFacilityMemberUnit(),

    male1To5: GetProgramMemberSelectorData?.data?.male1To5,
    female1To5: GetProgramMemberSelectorData?.data?.female1To5,
    additionalIdentities1To5:
      GetProgramMemberSelectorData?.data?.additionalIdentities1To5,
    unknown1To5: GetProgramMemberSelectorData?.data?.unknown1To5,

    male6To11: GetProgramMemberSelectorData?.data?.male6To11,
    female6To11: GetProgramMemberSelectorData?.data?.female6To11,
    additionalIdentities6To11:
      GetProgramMemberSelectorData?.data?.additionalIdentities6To11,
    unknown6To11: GetProgramMemberSelectorData?.data?.unknown6To11,

    male12To17: GetProgramMemberSelectorData?.data?.male12To17,
    female12To17: GetProgramMemberSelectorData?.data?.female12To17,
    additionalIdentities12To17:
      GetProgramMemberSelectorData?.data?.additionalIdentities12To17,
    unknown12To17: GetProgramMemberSelectorData?.data?.unknown12To17,

    male18To29: GetProgramMemberSelectorData?.data?.male18To29,
    female18To29: GetProgramMemberSelectorData?.data?.female18To29,
    additionalIdentities18To29:
      GetProgramMemberSelectorData?.data?.additionalIdentities18To29,
    unknown18To29: GetProgramMemberSelectorData?.data?.unknown18To29,

    male30To54: GetProgramMemberSelectorData?.data?.male30To54,
    female30To54: GetProgramMemberSelectorData?.data?.female30To54,
    additionalIdentities30To54:
      GetProgramMemberSelectorData?.data?.additionalIdentities30To54,
    unknown30To54: GetProgramMemberSelectorData?.data?.unknown30To54,

    male55To64: GetProgramMemberSelectorData?.data?.male55To64,
    female55To64: GetProgramMemberSelectorData?.data?.female55To64,
    additionalIdentities55To64:
      GetProgramMemberSelectorData?.data?.additionalIdentities55To64,
    unknown55To64: GetProgramMemberSelectorData?.data?.unknown55To64,

    male65Plus: GetProgramMemberSelectorData?.data?.male65Plus,
    female65Plus: GetProgramMemberSelectorData?.data?.female65Plus,
    additionalIdentities65Plus:
      GetProgramMemberSelectorData?.data?.additionalIdentities65Plus,
    unknown65Plus: GetProgramMemberSelectorData?.data?.unknown65Plus,

    maleUnknown: GetProgramMemberSelectorData?.data?.maleUnknown,
    femaleUnknown: GetProgramMemberSelectorData?.data?.femaleUnknown,
    additionalIdentitiesUnknown:
      GetProgramMemberSelectorData?.data?.additionalIdentitiesUnknown,
    unknownUnknown: GetProgramMemberSelectorData?.data?.unknownUnknown,

    total1To5: '',
    total6To11: '',
    total12To17: '',
    total18To29: '',
    total30To54: '',
    total55To64: '',
    total65Plus: '',
    totalUnknownRow: '',

    totalMale: '',
    totalFemale: '',
    totalAdditionalIdentities: '',
    totalUnknown: '',
    totaltotal: ''
  };

  const validationSchema = Yup.object({
    yourAssociationPrograms: Yup.string().nullable(),
    facilityMemberUnit: Yup.string().nullable(),

    male1To5: Yup.string().nullable(),
    female1To5: Yup.string().nullable(),
    additionalIdentities1To5: Yup.string().nullable(),
    unknown1To5: Yup.string().nullable(),

    male6To11: Yup.string().nullable(),
    female6To11: Yup.string().nullable(),
    additionalIdentities6To11: Yup.string().nullable(),
    unknown6To11: Yup.string().nullable(),

    male12To17: Yup.string().nullable(),
    female12To17: Yup.string().nullable(),
    additionalIdentities12To17: Yup.string().nullable(),
    unknown12To17: Yup.string().nullable(),

    male18To29: Yup.string().nullable(),
    female18To29: Yup.string().nullable(),
    additionalIdentities18To29: Yup.string().nullable(),
    unknown18To29: Yup.string().nullable(),

    male30To54: Yup.string().nullable(),
    female30To54: Yup.string().nullable(),
    additionalIdentities30To54: Yup.string().nullable(),
    unknown30To54: Yup.string().nullable(),

    male55To64: Yup.string().nullable(),
    female55To64: Yup.string().nullable(),
    additionalIdentities55To64: Yup.string().nullable(),
    unknown55To64: Yup.string().nullable(),

    male65Plus: Yup.string().nullable(),
    female65Plus: Yup.string().nullable(),
    additionalIdentities65Plus: Yup.string().nullable(),
    unknown65Plus: Yup.string().nullable(),

    maleUnknown: Yup.string().nullable(),
    femaleUnknown: Yup.string().nullable(),
    additionalIdentitiesUnknown: Yup.string().nullable(),
    unknownUnknown: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? value : null);

  const onSubmit = values => {
    setLoader(true);
    let payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetProgramMemberSelectorData?.data?.createdByName,
      createdByPersonId: GetProgramMemberSelectorData?.data?.createdByPersonId,
      createdDatetime: GetProgramMemberSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      programMemberId: GetProgramMemberSelectorData?.data?.programMemberId,
      reportBatchId: GetProgramMemberSelectorData?.data?.reportBatchId,
      recordStatus: GetProgramMemberSelectorData?.data?.recordStatus,
      yourAssociationPrograms: [values.yourAssociationPrograms],

      male1To5: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.male1To5),
      female1To5: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.female1To5),
      additionalIdentities1To5: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.additionalIdentities1To5),
      unknown1To5: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.unknown1To5),

      male6To11: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.male6To11),
      female6To11: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.female6To11),
      additionalIdentities6To11: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.additionalIdentities6To11),
      unknown6To11: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.unknown6To11),

      male12To17: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.male12To17),
      female12To17: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.female12To17),
      additionalIdentities12To17: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.additionalIdentities12To17),
      unknown12To17: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.unknown12To17),

      male18To29: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.male18To29),
      female18To29: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.female18To29),
      additionalIdentities18To29: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.additionalIdentities18To29),
      unknown18To29: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.unknown18To29),

      male30To54: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.male30To54),
      female30To54: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.female30To54),
      additionalIdentities30To54: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.additionalIdentities30To54),
      unknown30To54: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.unknown30To54),

      male55To64: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.male55To64),
      female55To64: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.female55To64),
      additionalIdentities55To64: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.additionalIdentities55To64),
      unknown55To64: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.unknown55To64),

      male65Plus: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.male65Plus),
      female65Plus: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.female65Plus),
      additionalIdentities65Plus: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.additionalIdentities65Plus),
      unknown65Plus: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : convertedValue(values.unknown65Plus),

      facilityMemberDuplicated: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : values.facilityMemberUnit === 'facilityMemberDuplicated',

      facilityMemberUnDuplicated: [values.yourAssociationPrograms].includes(
        'associationNotOfferPrograms'
      )
        ? null
        : values.facilityMemberUnit === 'facilityMemberUnDuplicated'
    };

    if (selectedYear !== '2022') {
      payloadBody = {
        ...payloadBody,
        maleUnknown: [values.yourAssociationPrograms].includes(
          'associationNotOfferPrograms'
        )
          ? null
          : convertedValue(values.maleUnknown),
        femaleUnknown: [values.yourAssociationPrograms].includes(
          'associationNotOfferPrograms'
        )
          ? null
          : convertedValue(values.femaleUnknown),
        additionalIdentitiesUnknown: [values.yourAssociationPrograms].includes(
          'associationNotOfferPrograms'
        )
          ? null
          : convertedValue(values.additionalIdentitiesUnknown),

        unknownUnknown: [values.yourAssociationPrograms].includes(
          'associationNotOfferPrograms'
        )
          ? null
          : convertedValue(values.unknownUnknown)
      };
    } else {
      const { maleUnknown, ...restmaleUnknown } = payloadBody;
      const { femaleUnknown, ...restfemaleUnknown } = restmaleUnknown;
      const {
        additionalIdentitiesUnknown,
        ...restadditionalIdentitiesUnknown
      } = restfemaleUnknown;
      const { unknownUnknown, ...restunknownUnknown } =
        restadditionalIdentitiesUnknown;
      payloadBody = { ...restunknownUnknown };
    }
    setSuccessPopup(false);
    handlePostProgramMember(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationsNum, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall(true);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  const totalCount = (
    value1 = 0,
    value2 = 0,
    value3 = 0,
    value4 = 0,
    value5 = 0,
    value6 = 0,
    value7 = 0,
    value8 = 0,
    value9 = 0,
    value10 = 0,
    value11 = 0,
    value12 = 0,
    value13 = 0,
    value14 = 0,
    value15 = 0,
    value16 = 0,
    value17 = 0,
    value18 = 0,
    value19 = 0,
    value20 = 0,
    value21 = 0,
    value22 = 0,
    value23 = 0,
    value24 = 0,
    value25 = 0,
    value26 = 0,
    value27 = 0,
    value28 = 0,
    value29 = 0,
    value30 = 0,
    value31 = 0,
    value32 = 0
  ) =>
    Number(value1) +
    Number(value2) +
    Number(value3) +
    Number(value4) +
    Number(value5) +
    Number(value6) +
    Number(value7) +
    Number(value8) +
    Number(value9) +
    Number(value10) +
    Number(value11) +
    Number(value12) +
    Number(value13) +
    Number(value14) +
    Number(value15) +
    Number(value16) +
    Number(value17) +
    Number(value18) +
    Number(value19) +
    Number(value20) +
    Number(value21) +
    Number(value22) +
    Number(value23) +
    Number(value24) +
    Number(value25) +
    Number(value26) +
    Number(value27) +
    Number(value28) +
    Number(value29) +
    Number(value30) +
    Number(value31) +
    Number(value32);

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>

      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3 pt-3">
        <div className="ymca-instructionBoxes">
          <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
          <p className="ymca-instructTitle">
            <div
              dangerouslySetInnerHTML={{
                __html: instructions
              }}
            />
          </p>
        </div>
      </div>
      {GetProgramMemberSelectorData?.data?.recordStatus?.toUpperCase() ===
        APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-tableResponsive mt-3">
                <table className="table ymca-table ymca-lastTable">
                  <tbody className="ymca-cuttingBody ymca-nostripeBody">
                    <tr>
                      <td className="ymca-normalWrap ymca-radioBTN d-flex">
                        <Field
                          type="radio"
                          name="yourAssociationPrograms"
                          value="associationOfferPrograms"
                          className="me-2"
                        />
                        <span>
                          {
                            'Click here if your association offers Programs to report your program members and enrollments.'
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="ymca-normalWrap ymca-radioBTN d-flex">
                        <Field
                          type="radio"
                          name="yourAssociationPrograms"
                          value="associationNotOfferPrograms"
                          className="me-2"
                        />

                        <span>
                          {
                            'Click here if your association does not offer Programs.'
                          }
                        </span>
                      </td>
                    </tr>

                    <ErrorMessage
                      component={TextError}
                      name="yourAssociationPrograms"
                    />
                  </tbody>
                </table>
              </div>
              {values.yourAssociationPrograms ===
                'associationOfferPrograms' && (
                <div>
                  <div className="ymca-covidRelif pt-0 pb-0">
                    <h4 className="ymca-headingTitle txt-mandatory">
                      Confirm the count of program members / participants
                      (select one).
                    </h4>
                    <div className="ymca-normalWrap d-flex">
                      <ul className="ymca-mainCheckBTN">
                        <li className="ymca-listCheckBTN">
                          <Field
                            name="facilityMemberUnit"
                            value="facilityMemberDuplicated"
                            className="ymca-fieldRadio"
                            type="radio"
                          />
                        </li>
                      </ul>
                      <span className="ymca-padRight checkboxlabel">
                        Duplicated
                      </span>
                      <ul className="ymca-mainCheckBTN">
                        <li className="ymca-listCheckBTN">
                          <Field
                            name="facilityMemberUnit"
                            value="facilityMemberUnDuplicated"
                            className="ymca-fieldRadio"
                            type="radio"
                          />
                        </li>
                      </ul>
                      <span className="checkboxlabel"> Unduplicated</span>
                    </div>
                  </div>
                  <div className="ymca-tableResponsive">
                    <table className="table ymca-table table-striped ymca-autoLength">
                      <thead>
                        <tr className="ymca-cuttingData">
                          <th>&nbsp;</th>
                          <th>Male</th>
                          <th>Female</th>
                          <th>Additional Identities</th>
                          <th>Unknown</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody className="ymca-cuttingBody ymca-lengths">
                        <tr>
                          <td className="txt-mandatory">
                            Infant / Toddler / Preschool (Birth-5 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male1To5')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female1To5')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities1To5'
                                )
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown1To5')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total1To5"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male1To5,
                                values.female1To5,
                                values.additionalIdentities1To5,
                                values.unknown1To5
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Elementary (6-11 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male6To11')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female6To11')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities6To11'
                                )
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown6To11')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total6To11"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male6To11,
                                values.female6To11,
                                values.additionalIdentities6To11,
                                values.unknown6To11
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Jr / Sr High (12-17 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male12To17')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female12To17')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities12To17'
                                )
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown12To17')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total12To17"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male12To17,
                                values.female12To17,
                                values.additionalIdentities12To17,
                                values.unknown12To17
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Young Adult (18-29 years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male18To29')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female18To29')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities18To29'
                                )
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown18To29')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total18To29"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male18To29,
                                values.female18To29,
                                values.additionalIdentities18To29,
                                values.unknown18To29
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">Adult (30-54 years)</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male30To54')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female30To54')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities30To54'
                                )
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown30To54')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total30To54"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male30To54,
                                values.female30To54,
                                values.additionalIdentities30To54,
                                values.unknown30To54
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">Adult (55-64 years)</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male55To64')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female55To64')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities55To64'
                                )
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown55To64')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total55To64"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male55To64,
                                values.female55To64,
                                values.additionalIdentities55To64,
                                values.unknown55To64
                              )}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Older Adult (65+ years)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="male65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'male65Plus')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="female65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'female65Plus')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities65Plus'
                                )
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown65Plus')
                              }
                              className="ymca-field"
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total65Plus"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male65Plus,
                                values.female65Plus,
                                values.additionalIdentities65Plus,
                                values.unknown65Plus
                              )}
                            />
                          </td>
                        </tr>
                        {selectedYear !== '2022' && (
                          <tr>
                            <td className="txt-mandatory">Unknown</td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="maleUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                onChange={e =>
                                  handleChange(e, setFieldValue, 'maleUnknown')
                                }
                                className="ymca-field"
                              />
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="femaleUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'femaleUnknown'
                                  )
                                }
                                className="ymca-field"
                              />
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="additionalIdentitiesUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'additionalIdentitiesUnknown'
                                  )
                                }
                                className="ymca-field"
                              />
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="unknownUnknown"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'unknownUnknown'
                                  )
                                }
                                className="ymca-field"
                              />
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                name="total65Plus"
                                type="text"
                                maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                                className="ymca-field ymca-fieldDisabled"
                                disabled
                                value={totalCount(
                                  values.maleUnknown,
                                  values.femaleUnknown,
                                  values.additionalIdentitiesUnknown,
                                  values.unknownUnknown
                                )}
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>Total</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalMale"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male1To5,
                                values.male6To11,
                                values.male12To17,
                                values.male18To29,
                                values.male30To54,
                                values.male55To64,
                                values.male65Plus,
                                values.maleUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalFemale"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.female1To5,
                                values.female6To11,
                                values.female12To17,
                                values.female18To29,
                                values.female30To54,
                                values.female55To64,
                                values.female65Plus,
                                values.femaleUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalAdditionalIdentities"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.additionalIdentities1To5,
                                values.additionalIdentities6To11,
                                values.additionalIdentities12To17,
                                values.additionalIdentities18To29,
                                values.additionalIdentities30To54,
                                values.additionalIdentities55To64,
                                values.additionalIdentities65Plus,
                                values.additionalIdentitiesUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totalUnknown"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.unknown1To5,
                                values.unknown6To11,
                                values.unknown12To17,
                                values.unknown18To29,
                                values.unknown30To54,
                                values.unknown55To64,
                                values.unknown65Plus,
                                values.unknownUnknown
                              )}
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <input
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                values.male1To5,
                                values.male6To11,
                                values.male12To17,
                                values.male18To29,
                                values.male30To54,
                                values.male55To64,
                                values.male65Plus,
                                values.maleUnknown,
                                values.female1To5,
                                values.female6To11,
                                values.female12To17,
                                values.female18To29,
                                values.female30To54,
                                values.female55To64,
                                values.female65Plus,
                                values.femaleUnknown,
                                values.additionalIdentities1To5,
                                values.additionalIdentities6To11,
                                values.additionalIdentities12To17,
                                values.additionalIdentities18To29,
                                values.additionalIdentities30To54,
                                values.additionalIdentities55To64,
                                values.additionalIdentities65Plus,
                                values.additionalIdentitiesUnknown,
                                values.unknown1To5,
                                values.unknown6To11,
                                values.unknown12To17,
                                values.unknown18To29,
                                values.unknown30To54,
                                values.unknown55To64,
                                values.unknown65Plus,
                                values.unknownUnknown
                              )}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={GetProgramMemberSelectorData?.data?.updatedByName}
                  Time={GetProgramMemberSelectorData?.data?.updatedDatetime}
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightUntitled float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationsNum}
        formId={formId}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {help && <Help hidePopup={hideShowHelp} keyValue="program-members" />}
    </div>
  );
};

ProgramMemberTab.propTypes = {
  handleGetProgramMember: PropTypes.func.isRequired,
  handlePostProgramMember: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetProgramMemberSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetProgramMember: getProgramMember,
  handlePostProgramMember: postProgramMember,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetProgramMemberSelectorData: GetProgramMemberSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ProgramMemberTab));
