import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CDP } from '@config/app-config';
import PropTypes from 'prop-types';
import Table from './table';
import CdpStatCard from './cdp-stat-card';
import ViewDetails from './view-details';
import ManageColumns from './manage-columns';
import CustomPopover from "./error-popover";


const Details = props => {
  const { cdpDetailView, fetchType, programName } = props;

  const [popupData, setPopupData] = useState({});
  const [cdpData, setCdpData] = useState([]);
  const [activeCard, setActiveCard] = useState('');
  const [isErrorCardClass, setIsErrorCardClass] = useState(false);



  useEffect(() => {
    const totalRecords = [];
    // eslint-disable-next-line no-unused-expressions
    cdpDetailView?.records?.forEach(record => {
      if (record?.deletE_IND !== true) {
        totalRecords.push(record);
      }
    });
    setCdpData(totalRecords);
  }, [cdpDetailView]);



  const viewDetalsPopup = data => {
    setPopupData(data);
  };

  const getColumns = () =>
    ManageColumns({ fetchType, viewDetalsPopup, activeCard, programName });

  const kipClick = (rows, key) => {
    setActiveCard(key);
    setCdpData(rows);
    if (key === "3") {
      setIsErrorCardClass(true);
    } else {
      setIsErrorCardClass(false);
    }
  };

  return (
    <>
      <div className="container">
        <CdpStatCard
          rows={cdpDetailView}
          kipClick={kipClick}
          fetchType={fetchType}
        />
      </div>
      <div className={`cdp-details-table ${isErrorCardClass ? 'invalid-tab' : 'valid-tab'}`}>
        <Table
          rows={cdpData}
          headers={getColumns()}
          showCount
          noRecordMessage="No record to show"
          title={
            programName === CDP.PROGRAM_NAMES.DPP
              ? CDP.TITLES.SOLERA_DETAILS_NEW
              : CDP.TITLES.SOLERA_DETAILS_EXISTING
          }
        />
        {<CustomPopover />}
      </div>
      <ViewDetails popupTitle="Record Details" popupData={popupData} />

    </>
  );
};



Details.propTypes = {
  cdpDetailView: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchType: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired
};

export default connect()(Details);
