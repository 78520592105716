// eslint-disable-next-line import/prefer-default-export
export const GetCdpAssociateDashboardSelector = ({
  CdpAssociateDashboardStore: { CdpAssociateDashboard }
}) => CdpAssociateDashboard;

export const GetCdpRedCapRegistrationViewDetailsSelector = ({
  CdpRedCapRegistrationViewDetailStore: { CdpRedCapRegistrationViewDetails }
}) => CdpRedCapRegistrationViewDetails;

export const GetCdpRedCapVisitsViewDetailsSelector = ({
  CdpRedCapVisitsViewDetailStore: { CdpRedCapVisitsViewDetails }
}) => CdpRedCapVisitsViewDetails;

export const GetCdpRedCapOrgCodeViewDetailsSelector = ({
  CdpRedCapOrgCodeViewDetailStore: { CdpRedCapOrgCodeViewDetails }
}) => CdpRedCapOrgCodeViewDetails;

export const GetCdpRedCapCoachViewDetailsSelector = ({
  CdpRedCapCoachViewDetailStore: { CdpRedCapCoachViewDetails }
}) => CdpRedCapCoachViewDetails;

export const GetCdpSoleraViewDetailsSelector = ({
  CdpSoleraViewDetailStore: { CdpSoleraViewDetails }
}) => CdpSoleraViewDetails;

export const PostRedCapVisitsSelector = ({
  RedCapVisitsStore: { RedCapVisitsObj }
}) => RedCapVisitsObj;

export const getCdpAssociationData = ({ cdpAssociateDashboardStore }) => {
  return {
    associationData: cdpAssociateDashboardStore.associationData
  };
};

export const getCdpAssociationDueDateData = ({
  cdpAssociateDashboardStore
}) => {
  return {
    associationData: cdpAssociateDashboardStore
  };
};
