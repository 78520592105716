import {
  GET_ANNUAL_REPORT_SITE_INFORMATION,
  ANNUAL_REPORT_SITE_INFORMATION
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSiteinformationList = AssociationId => (dispatch, getState) =>
  asyncRequest({
    url: GET_ANNUAL_REPORT_SITE_INFORMATION(AssociationId),
    method: 'GET',
    successAction: data => ({
      type: types.SITEINFORMATION_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SITEINFORMATION_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSiteinformationData = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: ANNUAL_REPORT_SITE_INFORMATION(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.SITEINFORMATION_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SITEINFORMATION_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });
