export const GET_CDP_ADMIN_DASHBOARD_SUCCESS =
  'GET_CDP_ADMIN_DASHBOARD_SUCCESS';
export const GET_CDP_ADMIN_DASHBOARD_FAILURE =
  'GET_CDP_ADMIN_DASHBOARD_FAILURE';

export const GET_CDP_ADMIN_DASHBOARD_KPI_SUCCESS =
  'GET_CDP_ADMIN_DASHBOARD_KPI_SUCCESS';
export const GET_CDP_ADMIN_DASHBOARD_KPI_FAILURE =
  'GET_CDP_ADMIN_DASHBOARD_KPI_FAILURE';

export const GET_ASSOCIATION_BY_NUMBER_SUCCESS =
  'GET_ASSOCIATION_BY_NUMBER_SUCCESS';
export const GET_ASSOCIATION_BY_NUMBER_FAILURE =
  'GET_ASSOCIATION_BY_NUMBER_FAILURE';
