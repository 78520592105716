/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import {
  getAnnualReportCollabAmongYsApi,
  PostAnnualReportCollabAmongYsApi
} from '@redux/annual-report-collaboration/actions';
import { Formik, Form } from 'formik';
import { CollabAmongYsSelector } from '@redux/annual-report-collaboration/selectors';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const CollaborationAmoungYs = props => {
  const {
    handleGetARCollabAmongYsApi,
    setLoader,
    handlePostARCollabAmongYsApi,
    selectedAsso,
    selectedBranch,
    setSuccessPopup,
    setErrorPopup,
    setErrorMesssage,
    status,
    role,
    handleGetAnnualReportsDashboard,
    LoginUserData,
    selectedbranchName
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Collaborations';

  const [initialValues, setInitialValues] = useState({});
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const formikref = useRef();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const fieldsObj = [
    {
      key: 'Our Y Staff or volunteers meet with staff from other Ys to share knowledge',
      value: 'meetWithStaffOtherYsToShareKnowledge'
    },
    {
      key: 'We share facilities or equipment',
      value: 'sharedFacilityEquipment'
    },
    {
      key: 'We jointly deliver a program',
      value: 'deliverProgram'
    },
    {
      key: 'We jointly research and/or plan for how to meet community needs',
      value: 'researchAndPlanNeed'
    },
    {
      key: 'We partner for media space/time',
      value: 'mediaSpaceTime'
    },
    {
      key: 'We share an office system(s) or function(s)',
      value: 'officeSystem'
    },
    {
      key: 'We Share a staff or volunteer person',
      value: 'staffVolunteerPerson'
    },
    {
      key: 'We plan and coordinate for fundraising',
      value: 'coordinateForFundraising'
    },
    {
      key: 'We have a formal written agreement for shared leadership',
      value: 'agreementSharedLeadership'
    },
    {
      key: 'We have a formal written agreement for shared services',
      value: 'agreementSharedService'
    },
    {
      key: 'We are assessing potential for merging',
      value: 'assessingPotentialForMerging'
    }
  ];
  const [instructions, setInstructions] = useState('');
  useEffect(() => {
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'collaborations-among-ys'
      ]?.Instruction
    );
  }, []);
  const getARCollabAmongYs = () => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetARCollabAmongYsApi(selectedAsso, selectedBranch).then(res => {
        if (res?.data?.isSuccess) {
          setInitialValues(res?.data?.data);
          setLoader(false);
        } else {
          setLoader(false);
          setErrorMesssage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        }
      });
    }
  };

  useEffect(() => {
    getARCollabAmongYs();
    formikref?.current?.resetForm();
  }, [selectedAsso, selectedBranch]);

  const onSubmit = values => {
    const checkValidation = fieldsObj.filter(
      element =>
        (values[element.Other] && !values[element.OtherDescription]) || false
    );
    if (checkValidation.length === 0 || values.noneOfTheOrganizations) {
      setSuccessPopup(false);
      setLoader(true);
      const data = { ...values };
      data.branchNumber = selectedBranch;
      data.branchName = selectedbranchName;
      handlePostARCollabAmongYsApi(data).then(res => {
        if (res?.data?.isSuccess) {
          handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
            dashData => {
              dashData?.data?.data?.dashboardDTOs.forEach(record => {
                if (
                  record.name === sectionName &&
                  record.status === 'Completed' &&
                  record.rating === null &&
                  selectedYear >= '2023' &&
                  role !== APP_LABELS.YUSA_DATA_STEWARD &&
                  role !== APP_LABELS.YESS_DATA_STEWARD
                ) {
                  setFeedbackPopup(true);
                  setFormId(record?.formId);
                }
              });
            }
          );
          setSuccessPopup(true);
          getARCollabAmongYs();
        } else {
          setErrorMesssage(res?.message);
          setErrorPopup(true);
        }
        setLoader(false);
      });
    }
  };

  const handleNonofTheAbove = (allValues, value, setValues) => {
    const updateData = { ...allValues };
    fieldsObj.forEach(data => {
      Object.keys(data).forEach(key => {
        if (key === 'value') {
          updateData[data[key]] = false;
        }
      });
    });
    if (value) {
      updateData.noneOfTheOrganizations = true;
    } else {
      updateData.noneOfTheOrganizations = false;
    }

    setValues(updateData);
  };

  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty, setValues }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-program">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                    <div className="ymca-instructionBoxes">
                      <h4 className="ymca-instructHeadline">
                        {APP_LABELS.INSTRUCTIONS}
                      </h4>
                      <p className="ymca-instructTitle">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: instructions
                          }}
                        />
                      </p>
                    </div>
                  </div>

                  <div className="ymca-covidRelif py-0">
                    <h4 className="ymca-programActivities d-flex ms-0">
                      <ul className="ymca-mainCheckBTN me-2">
                        <li className="ymca-listCheckBTN">
                          <input
                            className="ymca-checkboxStyled"
                            id="noneOfTheOrganizations"
                            type="checkbox"
                            onChange={e =>
                              handleNonofTheAbove(
                                values,
                                e.target.checked,
                                setValues
                              )
                            }
                            value={values.noneOfTheOrganizations}
                            checked={values.noneOfTheOrganizations}
                          />
                          <label
                            htmlFor="noneOfTheOrganizations"
                            className="ymca-text ymca-textContent"
                          >
                            &nbsp;
                          </label>
                        </li>
                      </ul>
                      <span> {APP_LABELS.NONE_OF_THE_BELOW_APPLY} </span>
                    </h4>
                    <p className="ymca-textTitle pt-2">
                      Now Consider the way your YMCA collaborates specifically
                      with other YMCAs (not branches within your association,
                      but rather other YMCA associations), to develop and
                      deliver services and programs that respond to commuity
                      needs.
                    </p>
                    <p className="ymca-textTitle">
                      Please check the statment(s) which are true of
                      collaborations you have with other Ys (outside your
                      association):
                    </p>

                    <div
                      className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3 ${
                        values.noneOfTheOrganizations && 'ymca-disabled-section'
                      }`}
                    />

                    {fieldsObj?.map(element => (
                      <div
                        key={element.key}
                        className={`ymca-checkedTexts d-flex mb-3 ${
                          values.noneOfTheOrganizations &&
                          'ymca-disabled-section'
                        }`}
                      >
                        <ul className="ymca-mainCheckBTN me-2">
                          <li className="ymca-listCheckBTN">
                            <input
                              className="ymca-checkboxStyled"
                              id={element.value}
                              type="checkbox"
                              onChange={e =>
                                setFieldValue(element.value, e.target.checked)
                              }
                              value={values[element.value]}
                              name={values[element.value]}
                              checked={values[element.value]}
                              disabled={values.noneOfTheOrganizations}
                            />
                            <label
                              htmlFor={element.value}
                              className="ymca-text ymca-textContent"
                            >
                              &nbsp;
                            </label>
                          </li>
                        </ul>
                        <h4 className="ymca-programActivities d-flex mt-0">
                          {element.key}
                        </h4>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={initialValues?.updatedByName}
                  Time={initialValues?.updatedDatetime}
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </>
  );
};

CollaborationAmoungYs.propTypes = {
  handleGetARCollabAmongYsApi: PropTypes.func.isRequired,
  handlePostARCollabAmongYsApi: PropTypes.func.isRequired,
  setLoader: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedAsso: PropTypes.string.isRequired,
  selectedBranch: PropTypes.string.isRequired,
  setSuccessPopup: PropTypes.func.isRequired,
  setErrorPopup: PropTypes.func.isRequired,
  setErrorMesssage: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedbranchName: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetARCollabAmongYsApi: getAnnualReportCollabAmongYsApi,
  handlePostARCollabAmongYsApi: PostAnnualReportCollabAmongYsApi,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

const mapStateToProps = state => ({
  CollabAmongYsData: CollabAmongYsSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CollaborationAmoungYs));
