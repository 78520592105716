export const FetchFileTemplateURLSelector = ({
  fetchtemplate: { FetchFileTemplateURL }
}) => FetchFileTemplateURL;

export const FetchFileTemplateSelector = ({
  fetchtemplate: { FetchFileTemplate }
}) => FetchFileTemplate;

export const UploadFileSelector = ({ fetchtemplate: { UploadFile } }) =>
  UploadFile;
export const GetTermsAndConditionSelector = ({
  fetchtemplate: { TermsAndConditions }
}) => TermsAndConditions;
