const InvalidColumns = [
  {
    Header: 'Branch',
    accessor: 'branchName',
    className: 'notranslate'
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'notranslate'
  },
  {
    Header: 'Errors Found',
    accessor: 'errorSummary'
  },
  {
    Header: '',
    accessor: 'viewDetails',
    disableSortBy: true
  }
];

export default InvalidColumns;
