import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import crossiconblue from '@static/images/cross-icon-blue.svg';

const ApprovePopup = props => {
  const { showApprovePopup, setShowApprovePopup, handleApprove } = props;
  const defaultDate = moment(new Date()).format('YYYY-MM-DD');
  const [expiryDate, setExpiryDate] = useState(defaultDate);

  const modal = {
    background: 'rgb(0 0 0 / 0.5)'
  };
  const button = {
    width: '25px',
    heigt: '25px'
  };
  const showHideClassName = showApprovePopup
    ? 'modal d-flex align-items-center'
    : 'modal d-none';
  return (
    <div
      className={showHideClassName}
      style={modal}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ymca-modal view-details-modal-dialog">
        <div className="modal-content p-3">
          <div className="modal-header py-1 border-0">
            <div className="ymca-black-sub-heading">Approve</div>
            <button
              type="button"
              className="btn-close"
              style={button}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowApprovePopup(false)}
            >
              <img
                src={crossiconblue}
                alt="cross-icon-blue"
                className="close-icon img-fluid"
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="col-lg-auto ymca-label pe-0 ">
              <span> Expiry Date</span>
              <div>
                <input
                  type="date"
                  value={expiryDate}
                  onChange={e => {
                    setExpiryDate(e.target.value);
                  }}
                  className="Custom fieldSet mt-1 insurence-approve-popup"
                  min={new Date().toISOString().split('T')[0]}
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            <br />
            <br />

            <button
              className="btn text-info px-0 approve-text"
              type="button"
              onClick={() => {
                setShowApprovePopup(false);
                handleApprove(expiryDate);
              }}
            >
              Add
            </button>
            <button
              className="btn px-3 approve-text"
              type="button"
              onClick={() => {
                setExpiryDate(moment(new Date()).format('YYYY-MM-DD'));
                setShowApprovePopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ApprovePopup.propTypes = {
  showApprovePopup: PropTypes.bool,
  setShowApprovePopup: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired
};

ApprovePopup.defaultProps = {
  showApprovePopup: false
};

export default ApprovePopup;
