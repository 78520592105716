/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Formik, Form, Field } from 'formik';
import {
  getDonors,
  postDonors,
  resetReduxState
} from '@redux/constituency-report/donors/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetDonorsSelector } from '@redux/constituency-report/donors/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import PropTypes from 'prop-types';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const handleChange = (e, setFieldValue, name) => {
  const result = e.target.value.replace(/\D+/g, '');
  setFieldValue(name, result);
};

const FacilityMembers = (errors, touched, setFieldValue) => (
  <div className="ymca-tableResponsive">
    <table className="table ymca-table table-striped ymca-autoLength ">
      <thead>
        <tr className="ymca-cuttingData">
          <th className="text-transform">Facility Members</th>
          <th>Individual Donors</th>
          <th>Organizational Donors</th>
          <th>Unknown</th>
          {/* <th>Total</th> */}
        </tr>
      </thead>
      <tbody className="ymca-cuttingBody ymca-lengths">
        <tr>
          <td className="txt-mandatory">
            {'How many donors are facility members?'}
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="totIndivdMembersMinOneDonation"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.totIndivdMembersMinOneDonation &&
                touched.totIndivdMembersMinOneDonation
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'totIndivdMembersMinOneDonation')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="totOrgMembersMinOneDonation"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.totOrgMembersMinOneDonation &&
                touched.totOrgMembersMinOneDonation
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'totOrgMembersMinOneDonation')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="totUnknownMembersMinOneDonation"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.totUnknownMembersMinOneDonation &&
                touched.totUnknownMembersMinOneDonation
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(
                  e,
                  setFieldValue,
                  'totUnknownMembersMinOneDonation'
                )
              }
            />
          </td>
        </tr>
        <tr>
          <td className="txt-mandatory">
            How many facility members gave to your annual campaign?
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="totAnnualCampIndividDonors"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.totAnnualCampIndividDonors &&
                touched.totAnnualCampIndividDonors
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'totAnnualCampIndividDonors')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="totAnnualCampOrgDonors"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.totAnnualCampOrgDonors && touched.totAnnualCampOrgDonors
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'totAnnualCampOrgDonors')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="totAnnualCampUnknownDonors"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.totAnnualCampUnknownDonors &&
                touched.totAnnualCampUnknownDonors
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'totAnnualCampUnknownDonors')
              }
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const AnnualCampaign = (errors, touched, setFieldValue) => (
  <div className="ymca-tableResponsive">
    <table className="table ymca-table table-striped ymca-autoLength">
      <thead>
        <tr className="ymca-cuttingData">
          <th className="text-transform">Annual Campaign</th>
          <th>Individual Donors</th>
          <th>Organizational Donors</th>
          <th>Unknown</th>
        </tr>
      </thead>
      <tbody className="ymca-cuttingBody ymca-lengths">
        <tr>
          <td className="txt-mandatory">
            {'How many donors gave to your annual campaign?'}
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="annualCampDonorsIndividual"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.annualCampDonorsIndividual &&
                touched.annualCampDonorsIndividual
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'annualCampDonorsIndividual')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="annualCampDonorsOrg"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.annualCampDonorsOrg && touched.annualCampDonorsOrg
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'annualCampDonorsOrg')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="annualCampDonorsUnknown"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.annualCampDonorsUnknown &&
                touched.annualCampDonorsUnknown
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'annualCampDonorsUnknown')
              }
            />
          </td>
        </tr>
        <tr>
          <td className="txt-mandatory">
            How many donors are program members?
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="annualCampDonorsIndividPrgMem"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.annualCampDonorsIndividPrgMem &&
                touched.annualCampDonorsIndividPrgMem
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'annualCampDonorsIndividPrgMem')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="annualCampDonorsOrgPrgMem"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.annualCampDonorsOrgPrgMem &&
                touched.annualCampDonorsOrgPrgMem
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'annualCampDonorsOrgPrgMem')
              }
            />
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="annualCampDonorsUnknownPrgMem"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.annualCampDonorsUnknownPrgMem &&
                touched.annualCampDonorsUnknownPrgMem
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e =>
                handleChange(e, setFieldValue, 'annualCampDonorsUnknownPrgMem')
              }
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const PlannedElement = (errors, touched, setFieldValue) => (
  <div className="ymca-tableResponsive">
    <table className="table ymca-table table-striped">
      <thead>
        <tr className="ymca-cuttingData">
          <th>Planned Giving</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody className="ymca-cuttingBody">
        <tr>
          <td className="txt-mandatory">
            People in Planned Giving Society (e.g. Herigate Club)
          </td>
          <td className="ymca-fieldLine">
            <Field
              name="heritageClub"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.heritageClub && touched.heritageClub
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e => handleChange(e, setFieldValue, 'heritageClub')}
            />
          </td>
          <td className="ymca-defaultSpace">&nbsp;</td>
          <td className="ymca-defaultSpace">&nbsp;</td>
        </tr>
        <tr>
          <td className="txt-mandatory">How many were added this year?</td>
          <td className="ymca-fieldLine">
            <Field
              name="addedDonors"
              type="text"
              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
              className={
                errors.addedDonors && touched.addedDonors
                  ? 'ymca-field invalid'
                  : 'ymca-field'
              }
              onChange={e => handleChange(e, setFieldValue, 'addedDonors')}
            />
          </td>
          <td className="ymca-defaultSpace">&nbsp;</td>
          <td className="ymca-defaultSpace">&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const FacilityMembersField = (errors, touched, setFieldValue) => (
  <tr>
    <td className="txt-mandatory">How many donors are facility members?</td>
    <td className="ymca-fieldLine">
      <Field
        name="totIndivdMembersMinOneDonation"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.totIndivdMembersMinOneDonation &&
          touched.totIndivdMembersMinOneDonation
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e =>
          handleChange(e, setFieldValue, 'totIndivdMembersMinOneDonation')
        }
      />
    </td>
    <td className="ymca-fieldLine">
      <Field
        name="totOrgMembersMinOneDonation"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.totOrgMembersMinOneDonation &&
          touched.totOrgMembersMinOneDonation
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e =>
          handleChange(e, setFieldValue, 'totOrgMembersMinOneDonation')
        }
      />
    </td>
    <td className="ymca-fieldLine">
      <Field
        name="totUnknownMembersMinOneDonation"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.totUnknownMembersMinOneDonation &&
          touched.totUnknownMembersMinOneDonation
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e =>
          handleChange(e, setFieldValue, 'totUnknownMembersMinOneDonation')
        }
      />
    </td>
  </tr>
);

const ProgramMembersField = (errors, touched, setFieldValue) => (
  <tr>
    <td className="txt-mandatory">How many donors are program members?</td>
    <td className="ymca-fieldLine">
      <Field
        name="annualCampDonorsIndividPrgMem"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.annualCampDonorsIndividPrgMem &&
          touched.annualCampDonorsIndividPrgMem
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e =>
          handleChange(e, setFieldValue, 'annualCampDonorsIndividPrgMem')
        }
      />
    </td>
    <td className="ymca-fieldLine">
      <Field
        name="annualCampDonorsOrgPrgMem"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.annualCampDonorsOrgPrgMem && touched.annualCampDonorsOrgPrgMem
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e =>
          handleChange(e, setFieldValue, 'annualCampDonorsOrgPrgMem')
        }
      />
    </td>
    <td className="ymca-fieldLine">
      <Field
        name="annualCampDonorsUnknownPrgMem"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.annualCampDonorsUnknownPrgMem &&
          touched.annualCampDonorsUnknownPrgMem
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e =>
          handleChange(e, setFieldValue, 'annualCampDonorsUnknownPrgMem')
        }
      />
    </td>
  </tr>
);

const FirsttimeDonorsField = (errors, touched, setFieldValue) => (
  <tr>
    <td className="txt-mandatory">
      How many of your donors are first-time donors?
    </td>
    <td className="ymca-fieldLine">
      <Field
        name="firstTimeIndividDonors"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.firstTimeIndividDonors && touched.firstTimeIndividDonors
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e => handleChange(e, setFieldValue, 'firstTimeIndividDonors')}
      />
    </td>
    <td className="ymca-fieldLine">
      <Field
        name="firstTimeOrgDonors"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.firstTimeOrgDonors && touched.firstTimeOrgDonors
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e => handleChange(e, setFieldValue, 'firstTimeOrgDonors')}
      />
    </td>
    <td className="ymca-fieldLine">
      <Field
        name="firstTimeUnknownDonors"
        type="text"
        maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
        className={
          errors.firstTimeUnknownDonors && touched.firstTimeUnknownDonors
            ? 'ymca-field invalid'
            : 'ymca-field'
        }
        onChange={e => handleChange(e, setFieldValue, 'firstTimeUnknownDonors')}
      />
    </td>
  </tr>
);

const DonorsElement = (errors, touched, setFieldValue) => (
  <div className="ymca-tableResponsive">
    <table className="table ymca-table table-striped ymca-autoLength ">
      <thead>
        <tr className="ymca-cuttingData">
          <th className="text-transform" />
          <th>Individual Donors</th>
          <th>Organizational Donors</th>
          <th>Unknown</th>
          {/* <th>Total</th> */}
        </tr>
      </thead>
      <tbody className="ymca-cuttingBody ymca-lengths">
        {FacilityMembersField(errors, touched, setFieldValue)}
        {ProgramMembersField(errors, touched, setFieldValue)}
        {FirsttimeDonorsField(errors, touched, setFieldValue)}
      </tbody>
    </table>
  </div>
);

const DonorsTab = props => {
  const {
    setLoader,
    handleGetDonors,
    handlePostDonors,
    GetDonorsSelectorData,
    LoginUserData,
    status,
    handleGetAnnualReportsDashboard,
    handleResetRedux
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [help, setHelp] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [resStatus, setResStatus] = useState();

  const sectionName = 'Constituency Report';
  const formikref = useRef();
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = success => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetDonors(associationsNum).then(res => {
      if (res.status === 200) {
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.donors?.Instruction
    );
    return () => {
      handleResetRedux('GetDonors');
    };
  }, []);

  const initialValues = {
    totNoOfDonors: '',
    percentageDonationBoardMembers: '',
    totIndivdMembersMinOneDonation: '',
    totOrgMembersMinOneDonation: '',
    totUnknownMembersMinOneDonation: '',
    totAnnualCampIndividDonors: '',
    totAnnualCampOrgDonors: '',
    totAnnualCampUnknownDonors: '',
    firstTimeIndividDonors: '',
    firstTimeOrgDonors: '',
    firstTimeUnknownDonors: '',
    annualCampDonorsIndividual: '',
    annualCampDonorsOrg: '',
    annualCampDonorsUnknown: '',
    annualCampDonorsIndividPrgMem: '',
    annualCampDonorsOrgPrgMem: '',
    annualCampDonorsUnknownPrgMem: '',
    heritageClub: '',
    addedDonors: '',

    totalMembersMinOneDonation: ''
  };

  const editValues = {
    totNoOfDonors: GetDonorsSelectorData?.data?.totNoOfDonors,
    percentageDonationBoardMembers:
      GetDonorsSelectorData?.data?.percentageDonationBoardMembers,
    totIndivdMembersMinOneDonation:
      GetDonorsSelectorData?.data?.totIndivdMembersMinOneDonation,
    totOrgMembersMinOneDonation:
      GetDonorsSelectorData?.data?.totOrgMembersMinOneDonation,
    totUnknownMembersMinOneDonation:
      GetDonorsSelectorData?.data?.totUnknownMembersMinOneDonation,
    totAnnualCampIndividDonors:
      GetDonorsSelectorData?.data?.totAnnualCampIndividDonors,
    totAnnualCampOrgDonors: GetDonorsSelectorData?.data?.totAnnualCampOrgDonors,
    totAnnualCampUnknownDonors:
      GetDonorsSelectorData?.data?.totAnnualCampUnknownDonors,
    firstTimeIndividDonors: GetDonorsSelectorData?.data?.firstTimeIndividDonors,
    firstTimeOrgDonors: GetDonorsSelectorData?.data?.firstTimeOrgDonors,
    firstTimeUnknownDonors: GetDonorsSelectorData?.data?.firstTimeUnknownDonors,
    annualCampDonorsIndividual:
      GetDonorsSelectorData?.data?.annualCampDonorsIndividual,
    annualCampDonorsOrg: GetDonorsSelectorData?.data?.annualCampDonorsOrg,
    annualCampDonorsUnknown:
      GetDonorsSelectorData?.data?.annualCampDonorsUnknown,
    annualCampDonorsIndividPrgMem:
      GetDonorsSelectorData?.data?.annualCampDonorsIndividPrgMem,
    annualCampDonorsOrgPrgMem:
      GetDonorsSelectorData?.data?.annualCampDonorsOrgPrgMem,
    annualCampDonorsUnknownPrgMem:
      GetDonorsSelectorData?.data?.annualCampDonorsUnknownPrgMem,
    heritageClub: GetDonorsSelectorData?.data?.heritageClub,
    addedDonors: GetDonorsSelectorData?.data?.addedDonors,
    totalMembersMinOneDonation: ''
  };

  const validationSchema = Yup.object({
    totNoOfDonors: Yup.string().nullable(),
    percentageDonationBoardMembers: Yup.string().nullable(),
    totIndivdMembersMinOneDonation: Yup.string().nullable(),
    totOrgMembersMinOneDonation: Yup.string().nullable(),
    totUnknownMembersMinOneDonation: Yup.string().nullable(),
    totAnnualCampIndividDonors: Yup.string().nullable(),
    totAnnualCampOrgDonors: Yup.string().nullable(),
    totAnnualCampUnknownDonors: Yup.string().nullable(),
    firstTimeIndividDonors: Yup.string().nullable(),
    firstTimeOrgDonors: Yup.string().nullable(),
    firstTimeUnknownDonors: Yup.string().nullable(),
    annualCampDonorsIndividual: Yup.string().nullable(),
    annualCampDonorsOrg: Yup.string().nullable(),
    annualCampDonorsUnknown: Yup.string().nullable(),
    annualCampDonorsIndividPrgMem: Yup.string().nullable(),
    annualCampDonorsOrgPrgMem: Yup.string().nullable(),
    annualCampDonorsUnknownPrgMem: Yup.string().nullable(),
    heritageClub: Yup.string().nullable(),
    addedDonors: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? value : null);

  const onSubmit = values => {
    setLoader(true);
    const payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetDonorsSelectorData?.data?.createdByName,
      createdByPersonId: GetDonorsSelectorData?.data?.createdByPersonId,
      createdDatetime: GetDonorsSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      donorId: GetDonorsSelectorData?.data?.donorId,
      reportBatchId: GetDonorsSelectorData?.data?.reportBatchId,
      recordStatus: GetDonorsSelectorData?.data?.recordStatus,
      totNoOfDonors: convertedValue(values.totNoOfDonors),
      percentageDonationBoardMembers: convertedValue(
        values.percentageDonationBoardMembers
      ),
      totIndivdMembersMinOneDonation: convertedValue(
        values.totIndivdMembersMinOneDonation
      ),
      totOrgMembersMinOneDonation: convertedValue(
        values.totOrgMembersMinOneDonation
      ),
      totUnknownMembersMinOneDonation: convertedValue(
        values.totUnknownMembersMinOneDonation
      ),
      totAnnualCampIndividDonors: convertedValue(
        values.totAnnualCampIndividDonors
      ),
      totAnnualCampOrgDonors: convertedValue(values.totAnnualCampOrgDonors),
      totAnnualCampUnknownDonors: convertedValue(
        values.totAnnualCampUnknownDonors
      ),
      annualCampDonorsIndividual: convertedValue(
        values.annualCampDonorsIndividual
      ),
      annualCampDonorsOrg: convertedValue(values.annualCampDonorsOrg),
      annualCampDonorsUnknown: convertedValue(values.annualCampDonorsUnknown),
      annualCampDonorsIndividPrgMem: convertedValue(
        values.annualCampDonorsIndividPrgMem
      ),
      annualCampDonorsOrgPrgMem: convertedValue(
        values.annualCampDonorsOrgPrgMem
      ),
      annualCampDonorsUnknownPrgMem: convertedValue(
        values.annualCampDonorsUnknownPrgMem
      ),
      heritageClub: convertedValue(values.heritageClub),
      addedDonors: convertedValue(values.addedDonors),

      firstTimeIndividDonors: convertedValue(values.firstTimeIndividDonors),
      firstTimeOrgDonors: convertedValue(values.firstTimeOrgDonors),
      firstTimeUnknownDonors: convertedValue(values.firstTimeUnknownDonors)
    };

    setSuccessPopup(false);
    handlePostDonors(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall(true);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>

      <div className="ymca-covidRelif px-0 py-3">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
          <div className="ymca-instructionBoxes">
            <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
            <p className="ymca-instructTitle">
              <div
                dangerouslySetInnerHTML={{
                  __html: instructions
                }}
              />
            </p>
          </div>
        </div>
        {GetDonorsSelectorData?.data?.recordStatus?.toUpperCase() ===
          APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
        {selectedYear === '2022' ? (
          <Formik
            innerRef={formikref}
            initialValues={editValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ setFieldValue, errors, touched, dirty }) => {
              sessionStorage.setItem('dirty', dirty);
              return (
                <Form autoComplete="off">
                  <div className="ymca-tableResponsive">
                    <table className="table ymca-table ymca-lastTable">
                      <tbody className="ymca-cuttingBody ymca-nostripeBody">
                        <tr>
                          <td className="ymca-fieldLine txt-mandatory">
                            <span>Total Number of Donors:</span>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totNoOfDonors"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                errors.totNoOfDonors && touched.totNoOfDonors
                                  ? 'ymca-field invalid'
                                  : 'ymca-field'
                              }
                              onChange={e =>
                                handleChange(e, setFieldValue, 'totNoOfDonors')
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpaces">&nbsp;</td>
                        </tr>
                        <tr>
                          <td className="ymca-fieldLine txt-mandatory">
                            <span>
                              Percent of Board Members Who Gave a Donation:
                            </span>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="percentageDonationBoardMembers"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                errors.percentageDonationBoardMembers &&
                                touched.percentageDonationBoardMembers
                                  ? 'ymca-field invalid'
                                  : 'ymca-field'
                              }
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'percentageDonationBoardMembers'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpaces">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {FacilityMembers(errors, touched, setFieldValue)}

                  {AnnualCampaign(errors, touched, setFieldValue)}

                  <div className="ymca-tableResponsive">
                    <table className="table ymca-table table-striped">
                      <thead>
                        <tr className="ymca-cuttingData">
                          <th>Planned Giving</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody className="ymca-cuttingBody">
                        <tr>
                          <td className="txt-mandatory">
                            People in Planned Giving Society (e.g. Herigate
                            Club)
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="heritageClub"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                errors.heritageClub && touched.heritageClub
                                  ? 'ymca-field invalid'
                                  : 'ymca-field'
                              }
                              onChange={e =>
                                handleChange(e, setFieldValue, 'heritageClub')
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td className="txt-mandatory">
                            How many were added this year?
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="addedDonors"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                errors.addedDonors && touched.addedDonors
                                  ? 'ymca-field invalid'
                                  : 'ymca-field'
                              }
                              onChange={e =>
                                handleChange(e, setFieldValue, 'addedDonors')
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="ymca-submitHighlighter">
                    <LastUpdateName
                      Username={GetDonorsSelectorData?.data?.updatedByName}
                      Time={GetDonorsSelectorData?.data?.updatedDatetime}
                    />
                  </div>
                  {role !== APP_LABELS.YUSA_DATA_VIEWER && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightUntitledUnder float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Formik
            initialValues={editValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ setFieldValue, errors, touched, dirty }) => {
              sessionStorage.setItem('dirty', dirty);
              return (
                <Form autoComplete="off">
                  <div className="ymca-tableResponsive">
                    <table className="table ymca-table ymca-lastTable">
                      <tbody className="ymca-cuttingBody ymca-nostripeBody">
                        <tr>
                          <td className="ymca-fieldLine txt-mandatory">
                            <span> Total Number of Donors :</span>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="totNoOfDonors"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                errors.totNoOfDonors && touched.totNoOfDonors
                                  ? 'ymca-field invalid'
                                  : 'ymca-field'
                              }
                              onChange={e =>
                                handleChange(e, setFieldValue, 'totNoOfDonors')
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpaces">&nbsp;</td>
                        </tr>
                        <tr>
                          <td className="ymca-fieldLine txt-mandatory">
                            <span>
                              Percent of Board Members Who Gave a Donation:
                            </span>
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="percentageDonationBoardMembers"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                errors.percentageDonationBoardMembers &&
                                touched.percentageDonationBoardMembers
                                  ? 'ymca-field invalid'
                                  : 'ymca-field'
                              }
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'percentageDonationBoardMembers'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpaces">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {DonorsElement(errors, touched, setFieldValue)}

                  {PlannedElement(errors, touched, setFieldValue)}

                  <div className="ymca-submitHighlighter">
                    <LastUpdateName
                      Username={GetDonorsSelectorData?.data?.updatedByName}
                      Time={GetDonorsSelectorData?.data?.updatedDatetime}
                    />
                  </div>
                  {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                    role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                      <button
                        type="submit"
                        className="btn ymca-savingBTN ymca-btnRightUntitledUnder float-end"
                        onClick={() => scrollToErrors(errors)}
                        disabled={
                          status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                        }
                      >
                        Save
                      </button>
                    )}
                </Form>
              );
            }}
          </Formik>
        )}
      </div>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {help && <Help hidePopup={hideShowHelp} keyValue="donors" />}
    </div>
  );
};

DonorsTab.propTypes = {
  handleGetDonors: PropTypes.func.isRequired,
  handlePostDonors: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetDonorsSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDonors: getDonors,
  handlePostDonors: postDonors,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetDonorsSelectorData: GetDonorsSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DonorsTab));
