import * as types from './types';

const initialState = {
  BranchData: {},
  BranchList: {},
  CountiresList: {}
  // , CrmProviderList:{}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_BRANCH_DETAILS_SUCCESS: {
      return {
        ...state,
        BranchData: action.payload
      };
    }
    case types.GET_BRANCH_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_LIST_OF_BRANCHES_SUCCESS: {
      return {
        ...state,
        BranchList: action.payload
      };
    }
    case types.GET_LIST_OF_BRANCHES_FAILURE:
      return { ...state, error: action.payload };

    case types.SET_BRANCH_DETAILS_SUCCESS: {
      return {
        ...state,
        BranchData: action.payload
      };
    }
    case types.SET_BRANCH_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_LIST_OF_COUNTRIES_SUCCESS: {
      return {
        ...state,
        CountiresList: action.payload
      };
    }
    case types.GET_LIST_OF_COUNTRIES_FAILURE:
      return { ...state, error: action.payload };

    // case types.GET_CRM_PROVIDER_LIST_SUCCESS: {
    //   return {
    //     ...state,
    //     CrmProviderList: action.payload
    //   };
    // }
    // case types.GET_CRM_PROVIDER_LIST_FAILURE:
    //   return { ...state, error: action.payload };

    default:
      return state;
  }
}
