import * as types from './types';

const initialState = {
  UserManagementUploadFile: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_MANAGEMENT_UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        UserManagementUploadFile: action.payload
      };
    }
    case types.USER_MANAGEMENT_UPLOAD_FILE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
