import React, { useEffect, useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useRowSelect,
  useMountedLayoutEffect
} from 'react-table';
import PropTypes from 'prop-types';
import PreviousIcon from '@static/images/previous-icon.svg';
import NextIcon from '@static/images/Next-icon.svg';
import SortUpIcon from '@static/images/sorting-icon-up.svg';
import SortDownIcon from '@static/images/sorting-icon-down.svg';

const Table = props => {
  const {
    datarows,
    headers,
    onSelectedRowsChange,
    selectedRows,
    onSelectedRowsChangeStaff,
    selectedRowsChangeStaff
  } = props;
  const columns = useMemo(() => headers, []);
  const data = useMemo(() => datarows, [datarows]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    prepareRow,
    toggleAllRowsSelected,
    state,
    selectedFlatRows,
    selectedFlatRowsChnageStaff
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: selectedRows,
        selectedRowIdsChangeStaff: selectedRowsChangeStaff,
        pageSize: 25
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const { pageIndex, selectedRowIds, selectedRowIdsChangeStaff } = state;

  useMountedLayoutEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if(onSelectedRowsChange){
      onSelectedRowsChange(selectedFlatRows)
    }
  }, [onSelectedRowsChange, selectedRowIds]);

  useMountedLayoutEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if(onSelectedRowsChange){
      onSelectedRowsChange(selectedFlatRowsChnageStaff)
    }
  }, [onSelectedRowsChangeStaff, selectedRowIdsChangeStaff]);

  useEffect(() => {
    toggleAllRowsSelected();
  }, []);
  const getShortButton = column => {
    let sortButton;

    if (column.isSorted) {
      if (column.isSortedDesc) {
        sortButton = (
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortUpIcon} className="img-fluid" alt="sorting-icon" />
          </button>
        );
      } else {
        sortButton = (
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortDownIcon} className="img-fluid" alt="sorting-icon" />
          </button>
        );
      }
    } else if (
      column.Header === 'Branch' ||
      column.Header === 'Name' ||
      column.Header === 'Staff Type' ||
      column.Header === 'Current Unique Job Title' ||
      column.Header === 'Record Type'
    ) {
      sortButton = (
        <>
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortUpIcon} className="img-fluid" alt="sorting-icon-up" />
          </button>
          <button type="button" className="btn btn-link p-0 custom-align">
            <img
              src={SortDownIcon}
              className="img-fluid"
              alt="sorting-icon-down"
            />
          </button>
        </>
      );
    } else {
      sortButton = null;
    }
    return sortButton;
  };
  return (
      <section className="dashboard-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive custom-scrollbar">
                <table
                  className="table ymca-table ymca-tableHeight"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups?.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup?.headers?.map(column => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render('Header')}
                            <div className="sorting-icon-wrapper ms-2">
                              {getShortButton(column)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page?.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row?.cells?.map(cell => (
                            <td
                              {...cell.getCellProps()}
                              className={`${cell.column.className ?? ''}`}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <nav aria-label="Page navigation" className="ymca-pagination">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <button
                  type="button"
                  className="page-link"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <img src={PreviousIcon} className="pe-1" alt="" />
                  {'Previous'}
                </button>
              </li>
              {[...Array(pageCount).keys()].map(count => {
                if (
                  count >= (pageIndex > 25 ? pageIndex - 25 : 0) &&
                  count <= (pageIndex < 25 ? 24 : pageIndex)
                ) {
                  return (
                    <li className="page-item" key={count}>
                      <button
                        type="button"
                        className={`page-link ${
                          count === pageIndex && 'active'
                        }`}
                        onClick={() => gotoPage(count)}
                      >
                        {count + 1}
                      </button>
                    </li>
                  );
                }
                return null;
              })}
              <li className="page-item">
                <button
                  type="button"
                  className="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {'Next '}
                  <img src={NextIcon} className="pl-1 ms-1" alt="" />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </section>
  );
};

Table.propTypes = {
  datarows: PropTypes.arrayOf(PropTypes.any),
  headers: PropTypes.arrayOf(PropTypes.any),
  checkbox: PropTypes.bool,
  onSelectedRowsChange: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.any),

  onSelectedRowsChangeStaff: PropTypes.func.isRequired,
  selectedRowsChangeStaff: PropTypes.arrayOf(PropTypes.any)
};

Table.defaultProps = {
  datarows: [],
  headers: [],
  checkbox: false,
  selectedRows: [],
  selectedRowsChangeStaff: []
};

export default Table;
