import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';
import OrganizationalCommitmentTab from './organizational-commitment';
import MembershipKnowYourCommunityTab from './membership-know-your-community';
import LeadershipStaffDevelopmentTab from './leadership-staff-development';
import ProgramInnovationTab from './program-innovation';
import StrategicRelationshipsTab from './strategic-relationships';
import ResourceMobilizationTab from './resource-mobilization';
import BrandVisibilityTab from './brand-visibility';

const DiversityGlobalTab = props => {
  const { setLoader, status } = props;
  const [activeTab, setActiveTab] = useState('Organizational Commitment');
  const [showPopup, setShowPopup] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [lastActiveTab, setLastActiveTab] = useState('');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onActive = value => {
    if (value !== activeTab && sessionStorage.getItem('dirty') === 'true') {
      setShowPopup(true);
    } else {
      setActiveTab(value);
    }
    setCurrentTab(value);
    setLastActiveTab(activeTab);
  };

  const handleOk = () => {
    setActiveTab(currentTab);
    setShowPopup(false);
  };

  const handleCancel = () => {
    const activetab = lastActiveTab;
    document.getElementById(activetab).click();
    setActiveTab(activetab);
    setShowPopup(false);
  };

  return (
    <div>
      <div className="ymca-completeBox">
        <div className="ymca-directorInfo ymca-globalMetrics">
          <ul
            className="nav nav-tabs ymca-navTabs ymca-eightBoxes"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks active"
                id="Organizational Commitment"
                data-bs-toggle="tab"
                data-bs-target="#orgCommitement"
                type="button"
                role="tab"
                aria-controls="orgCommitement"
                aria-selected="true"
                onClick={() => {
                  onActive('Organizational Commitment');
                }}
              >
                Organizational Commitment
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Membership: Know Your Community"
                data-bs-toggle="tab"
                data-bs-target="#knowYourCommunity"
                type="button"
                role="tab"
                aria-controls="knowYourCommunity"
                aria-selected="false"
                onClick={() => {
                  onActive('Membership: Know Your Community');
                }}
              >
                Membership: Know Your Community
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Leadership/Staff Development"
                data-bs-toggle="tab"
                data-bs-target="#leadership"
                type="button"
                role="tab"
                aria-controls="leadership"
                aria-selected="false"
                onClick={() => {
                  onActive('Leadership/Staff Development');
                }}
              >
                Leadership / Staff Development
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Program Innovation"
                data-bs-toggle="tab"
                data-bs-target="#programInnovation"
                type="button"
                role="tab"
                aria-controls="programInnovation"
                aria-selected="false"
                onClick={() => {
                  onActive('Program Innovation');
                }}
              >
                Program Innovation
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Strategic Relationships"
                data-bs-toggle="tab"
                data-bs-target="#strategicRelationShips"
                type="button"
                role="tab"
                aria-controls="strategicRelationShips"
                aria-selected="false"
                onClick={() => {
                  onActive('Strategic Relationships');
                }}
              >
                Strategic Relationships
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Resource Mobilization"
                data-bs-toggle="tab"
                data-bs-target="#resourceMobilization"
                type="button"
                role="tab"
                aria-controls="resourceMobilization"
                aria-selected="false"
                onClick={() => {
                  onActive('Resource Mobilization');
                }}
              >
                Resource Mobilization
              </button>
            </li>
            <li className="nav-item ymca-navItems" role="tab">
              <button
                className="nav-link ymca-navLinks"
                id="Brand Visibility"
                data-bs-toggle="tab"
                data-bs-target="#brandVisiblity"
                type="button"
                role="tab"
                aria-controls="brandVisiblity"
                aria-selected="false"
                onClick={() => {
                  onActive('Brand Visibility');
                }}
              >
                Brand Visibility
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane ymca-tabPane fade show active"
              id="orgCommitement"
              role="tabpanel"
              aria-labelledby="Organizational Commitment"
            >
              {activeTab === 'Organizational Commitment' && (
                <OrganizationalCommitmentTab
                  setLoader={setLoader}
                  status={status}
                />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="knowYourCommunity"
              role="tabpanel"
              aria-labelledby="Membership: Know Your Community"
            >
              {activeTab === 'Membership: Know Your Community' && (
                <MembershipKnowYourCommunityTab
                  setLoader={setLoader}
                  status={status}
                />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="leadership"
              role="tabpanel"
              aria-labelledby="Leadership/Staff Development"
            >
              {activeTab === 'Leadership/Staff Development' && (
                <LeadershipStaffDevelopmentTab
                  setLoader={setLoader}
                  status={status}
                />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="programInnovation"
              role="tabpanel"
              aria-labelledby="Program Innovation"
            >
              {activeTab === 'Program Innovation' && (
                <ProgramInnovationTab setLoader={setLoader} status={status} />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="strategicRelationShips"
              role="tabpanel"
              aria-labelledby="Strategic Relationships"
            >
              {activeTab === 'Strategic Relationships' && (
                <StrategicRelationshipsTab
                  setLoader={setLoader}
                  status={status}
                />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="resourceMobilization"
              role="tabpanel"
              aria-labelledby="Resource Mobilization"
            >
              {activeTab === 'Resource Mobilization' && (
                <ResourceMobilizationTab
                  setLoader={setLoader}
                  status={status}
                />
              )}
            </div>
            <div
              className="tab-pane ymca-tabPaneInner fade"
              id="brandVisiblity"
              role="tabpanel"
              aria-labelledby="Brand Visibility"
            >
              {activeTab === 'Brand Visibility' && (
                <BrandVisibilityTab setLoader={setLoader} status={status} />
              )}
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        show={showPopup}
        type={APP_LABELS.WARNING}
        message="You have some unsaved changes. If you leave before saving, your
                changes will be lost."
        submitButtonName="Ok"
        onSubmit={handleOk}
        cancelButtonName="Cancel"
        onCancel={handleCancel}
      />
    </div>
  );
};

DiversityGlobalTab.propTypes = {
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired
};

export default React.memo(DiversityGlobalTab);
