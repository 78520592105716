import {
  POST_VOLUNTEER_DETAILS,
  GET_VOLUNTEER_COUNTRIES_LIST
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const postVolunteerRegistrationData =
  payloadBody => (dispatch, getState) =>
    asyncRequest({
      url: POST_VOLUNTEER_DETAILS(),
      method: 'POST',
      data: payloadBody,
      successAction: data => ({
        type: types.VOLUNTEER_REGISTRATION_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.VOLUNTEER_REGISTRATION_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCountriesList = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_VOLUNTEER_COUNTRIES_LIST(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_LIST_COUNTRIES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_LIST_COUNTRIES_FAILURE,
      payload: error
    }),
    dispatch
  });
