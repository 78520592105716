import moment from 'moment';

const documentTitleType = {
  BackgroundScreening: 'Background Screenings ',
  AthleteProtectionTraining: 'Athlete Protection Training ',
  SwimOfficialCertification: 'Swim Official Certification ',
  VolunteerAttestation: 'Volunteer Attestation '
};

export const getDocumentTitle = documentTypeValue => documentTitleType[documentTypeValue]
  ? documentTitleType[documentTypeValue]
  : '-';

export const getFileExtension = filename => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext === null ? '' : ext[1];
};

export const getModule = (loggedInVolunteerUserModuleRoles, moduleName) => {
  if (loggedInVolunteerUserModuleRoles.length > 0) {
    const moduleData = loggedInVolunteerUserModuleRoles.find(module =>
      module?.moduleName?.includes(moduleName)
    );
    return moduleData;
  }
  return null;
};

export const checkDateValidation = dateToCompare => {
  const currentDate = new Date();
  return moment(new Date(dateToCompare).toLocaleDateString()).isBefore(
    currentDate.toLocaleDateString()
  );
};
