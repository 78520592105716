import { SUBMIT_FEEDBACK_AR } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const postFeedback = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: SUBMIT_FEEDBACK_AR(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_FEEDBACK_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_FEEDBACK_FAILURE,
      payload: error
    }),
    dispatch
  });

export default postFeedback;
