import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { APP_LABELS, DATE_ARRAY, STORAGE_KEYS } from '@config/app-config';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import moment from 'moment';
import axios from 'axios';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import SwimBranches from '@static/images/swimRegistration/swim-safety-branches.svg';
import BlueUserIcon from '@static/images/swimRegistration/swim-safety-profiles-dark.svg';
import SwimIIcon from '@static/images/swimRegistration/swim-safety-swim-icon.svg';
import Associationhover from '@static/images/swimRegistration/swim-safety-associations.svg';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import {
  getSwimSafetyDashboardCountList,
  getSwimSafetyAssociationList,
  getSwimSafetyDeleteAssociation
} from '@redux/siwm-safety/dashboard/actions';
import { GET_SWIMSAFETY_EXPORT_ASSOCIATION_API } from '@config/api-config';
import CustomModal from '@utilities/CustomModal';
import { SwimSafetyAssocListSelector } from '@redux/siwm-safety/dashboard/selectors';
import get from 'lodash.get';
import Table from './table';
import SWIM_ASSOCIATION_COLUMNS from './columns';
import SecureStorage from '../../../../utilities/SecureStorage';

const Dashboard = props => {
  const {
    handleGetDashBoardCount,
    handlegetAssociationList,
    handleDeleteAssociation,
    LoginUserData
  } = props;

  const defaultStartDate = moment().subtract(90, 'd').format('MM-DD-YYYY');
  const defaultEndDate = moment().format('MM-DD-YYYY');
  const Offset = new Date().getTimezoneOffset();
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [countData, setCountData] = useState({});
  const [associationList, setAssociationList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteAssociation, setDeleteAssociation] = useState(null);
  const [selectedRange, setSelectedRange] = useState(90);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [includeAllYs, setIncludeAllYs] = useState(true);
  const authToken = JSON.parse(sessionStorage.getItem('token'));

  const role = get(LoginUserData, 'user.role[0]');

  const filterDate = days => {
    if (days === 'Custom') {
      setStartDate('');
      setEndDate('');
    } else if (days === '1') {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
    } else {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(defaultEndDate);
    }
  };

  const handleDelete = associationDetails => {
    setDeleteAssociation(associationDetails);
    setShowAttentionPopup(true);
  };
  const handleCloseAttentionPopup = () => {
    setDeleteAssociation(null);
    setShowAttentionPopup(false);
  };

  const handleList = (clearStartDate, clearEndDate, include) => {
    setLoader(true);
    handlegetAssociationList(
      clearStartDate || startDate,
      clearEndDate || endDate,
      Offset,
      include || includeAllYs
    )
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setAssociationList(result?.data?.data);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleCountCall = (clearStartDate, clearEndDate, include) => {
    handleGetDashBoardCount(
      clearStartDate || startDate,
      clearEndDate || endDate,
      Offset,
      include || includeAllYs
    ).then(result => {
      if (result?.data?.data) {
        setCountData(result?.data?.data);
      }
    });
  };

  const deleteAPIcall = () => {
    setLoader(true);
    handleDeleteAssociation(deleteAssociation.associationId)
      .then(() => {
        setLoader(false);
        setDeleteAssociation(null);
        setShowAttentionPopup(false);
        handleList();
        handleCountCall();
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const checkRoleGetData = () => {
    setLoader(true);
    handleCountCall();
    handleList();
  };

  const handleViewDetails = assdata => {
    sessionStorage.setItem('yusa_selected_associate', JSON.stringify(assdata));
    sessionStorage.setItem('swim_Program_selected_tab', 'Branches');
  };

  const newAssociationChangeLogData = associationList?.map(data => {
    const location = {
      pathname: '/swim/association/dashboard',
      state: data
    };

    return {
      ...data,
      associationName: `${data.associationName} (${data.associationNumber})`,
      lastUpdatedDate: get(data, 'lastUpdatedDate')
        ? convertUtcToLocal(get(data, 'lastUpdatedDate'), 'DD MMM YYYY')
        : '-',
      details: (
        <>
          <Link
            to={location}
            className="table-link"
            onClick={() => handleViewDetails(data)}
          >
            {APP_LABELS.DASH_VIEW_DETAILS}
          </Link>

          {!APP_LABELS.VIEWER_PERMISSION_ROLES.includes(role) && (
            <>
              <span className=" swim-safety-action-saperator">|</span>
              <button
                type="button"
                className="swim-safety-action"
                data-bs-toggle="modal"
                data-bs-target="#rejectModal"
                onClick={() => handleDelete(data)}
              >
                <span className="ymca-delete-text">Delete</span>
              </button>
            </>
          )}
        </>
      )
    };
  });

  useEffect(async () => {
    SecureStorage.setItem(STORAGE_KEYS.DASHBOARD_URL, '/swim/dashboard');
    window.scroll(0, 0);
    setLoader(true);
    await handleCountCall();
    handleList();
  }, []);

  const downLoadAssocitions = () => {
    setLoader(true);
    axios
      .get(
        GET_SWIMSAFETY_EXPORT_ASSOCIATION_API(
          startDate,
          endDate,
          Offset,
          includeAllYs
        ),
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'blob',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': window.location.origin,
            'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
            Authorization: `Bearer ${get(authToken, 'token', '')}`
          }
        }
      )
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'YMCA-Associations.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  return (
    <>
      <BlockUi
        blocking={loader}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <div className="ymca-wrapper">
          <main className="page-container">
            <div className="ymca-data-wrapper ">
              <div className="container">
                <section className="dashboard-header ">
                  <div className="row align-items-end mt-3 ">
                    <div className="col-lg-9 col-md-9 col-sm-12">
                      <div className="ymca-associate-info-wrapper">
                        <h2 className="ymca-sub-heading mb-2">
                          {APP_LABELS.NAV_DASHBOARD}
                        </h2>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="row my-4">
                  <div className="col-lg-3 ymca-label d-flex align-items-center">
                    <div>
                      <span className="txt-mandatory"> Range Selection</span>
                      <select
                        className="fieldSetSelect mt-1"
                        value={selectedRange}
                        onChange={e => {
                          setSelectedRange(e.target.value);
                          filterDate(e.target.value);
                        }}
                        defaultValue={90}
                      >
                        {DATE_ARRAY.map(items => (
                          <option key={items.id} value={items.value}>
                            {items.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className="ps-4 fs-14 mt-3">Or</span>
                  </div>

                  <div className="col-lg-auto ymca-label pe-0 ">
                    <span className="txt-mandatory"> From Date</span>
                    <div>
                      <input
                        type="date"
                        onChange={e => {
                          setStartDate(e.target.value);
                          setEndDate('');
                        }}
                        value={startDate}
                        disabled={selectedRange !== APP_LABELS.CUSTOM}
                        className={`${
                          selectedRange === APP_LABELS.CUSTOM
                            ? ''
                            : 'cursor-not-allowed ymca-disabled-section'
                        } fieldSet mt-1`}
                        min={moment(new Date('1/1/1901 12:00:00')).format(
                          'YYYY-MM-DD'
                        )}
                        onKeyDown={e => {
                          e.preventDefault();
                        }}
                        max={moment(new Date())
                          .subtract(1, 'd')
                          .format('YYYY-MM-DD')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 ymca-label">
                    <span className="txt-mandatory"> To Date</span>
                    <div>
                      <input
                        type="date"
                        onChange={e => {
                          setEndDate(e.target.value);
                        }}
                        onKeyDown={e => {
                          e.preventDefault();
                        }}
                        disabled={
                          selectedRange !== APP_LABELS.CUSTOM ||
                          startDate === ''
                        }
                        className={`${
                          selectedRange !== APP_LABELS.CUSTOM ||
                          startDate === ''
                            ? 'cursor-not-allowed ymca-disabled-section'
                            : ''
                        } fieldSet mt-1`}
                        value={endDate}
                        min={
                          selectedRange === APP_LABELS.CUSTOM
                            ? moment(new Date(startDate))
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                            : ''
                        }
                        max={
                          moment(new Date(startDate))
                            .add(2, 'Y')
                            .format('YYYY-MM-DD') <
                          moment(new Date()).format('YYYY-MM-DD')
                            ? moment(new Date(startDate))
                                .add(2, 'Y')
                                .format('YYYY-MM-DD')
                            : moment(new Date()).format('YYYY-MM-DD')
                        }
                      />
                    </div>
                  </div>

                  <div className="col-lg-auto ymca-label me-3">
                    <span className="txt-mandatory"> Associations</span>
                    <div className="mt-1 d-flex align-items-center ">
                      <input
                        type="checkbox"
                        className="ymca-checkboxStyled mt-2 me-2"
                        checked={includeAllYs}
                        onClick={e => {
                          setIncludeAllYs(e.target.checked);
                        }}
                      />
                      <span className="mt-2 ymca-cachet-normal-dark-text ">
                        Include all
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-1 mt-1 ps-0">
                    <button
                      type="button"
                      onClick={() => {
                        checkRoleGetData();
                      }}
                      disabled={
                        (startDate === '' || endDate === '') &&
                        selectedRange === APP_LABELS.CUSTOM
                      }
                      className="btn ymca-primary-btn apply-btn custom-align"
                    >
                      <span> Apply</span>
                    </button>
                  </div>
                  <div className="col-lg-1 mt-1">
                    <button
                      type="button"
                      onClick={() => {
                        setStartDate(defaultStartDate);
                        setEndDate(defaultEndDate);
                        setSelectedRange(90);
                        setIncludeAllYs(true);
                        handleList(defaultStartDate, defaultEndDate, true);
                        handleCountCall(defaultStartDate, defaultEndDate, true);
                      }}
                      className="btn  ymc-outlineBtn apply-btn custom-align"
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <section className="stats-data">
                  <div className="row swim-cursor-remove">
                    <div
                      role="button"
                      tabIndex="0"
                      className="col-lg-3 col-md-3 col-sm-12 first-card"
                    >
                      <div className="swim-uploadDataCards swim-safety-associations">
                        <div className="d-flex align-items-center h-100">
                          <div className="card-icon">
                            <img
                              src={Associationhover}
                              className="dark-icon"
                              alt="icon"
                            />
                          </div>

                          <div className="card-title">
                            <h4>{countData?.associationsCount || 0}</h4>
                            <p>Number of Associations</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="button"
                      tabIndex="0"
                      className="col-lg-3 col-md-3 col-sm-12 "
                    >
                      <div className="swim-uploadDataCards swim-safety-branches ">
                        <div className="d-flex align-items-center h-100">
                          <div className="card-icon">
                            <img
                              src={SwimBranches}
                              className="dark-icon"
                              alt="icon"
                            />
                          </div>
                          <div className="card-title">
                            <h4>{countData?.branchesCount || 0}</h4>
                            <p>Number of Branches</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="button"
                      tabIndex="0"
                      className="col-lg-3 col-md-3 col-sm-12 "
                    >
                      <div className="swim-uploadDataCards swim-saftey-programs">
                        <div className="d-flex align-items-center h-100">
                          <div className="card-icon">
                            <img
                              src={SwimIIcon}
                              className="dark-icon"
                              alt="icon"
                            />
                            <img
                              src={SwimIIcon}
                              className="light-icon"
                              alt="icon"
                            />
                          </div>

                          <div className="card-title">
                            <h4>{countData?.swimProgramsCount || 0}</h4>
                            <p>Number of Swim Programs </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="button"
                      tabIndex="0"
                      className="col-lg-3 col-md-3 col-sm-12 "
                    >
                      <div className="swim-uploadDataCards swim-safety-profiles ">
                        <div className="d-flex align-items-center h-100">
                          <div className="card-icon">
                            <img
                              src={BlueUserIcon}
                              className="dark-icon"
                              alt="icon"
                            />
                          </div>
                          <div className="card-title">
                            <h4>{countData?.profilesCount || 0}</h4>
                            <p>Number of Profiles</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <hr className="custom-hr mt-0" />

                <Table
                  rows={newAssociationChangeLogData}
                  headers={SWIM_ASSOCIATION_COLUMNS()}
                  title="Associations"
                  showCount={newAssociationChangeLogData?.length}
                  downLoadAssocitions={downLoadAssocitions}
                  role={role}
                />
              </div>
            </div>
          </main>
        </div>
      </BlockUi>
      {/* Attention Modal */}
      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message={`Are you sure want to delete association '${deleteAssociation?.associationName}'?`}
        submitButtonName="Yes"
        onSubmit={deleteAPIcall}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={handleCloseAttentionPopup}
      />
    </>
  );
};

Dashboard.propTypes = {
  handleGetDashBoardCount: PropTypes.func.isRequired,
  handlegetAssociationList: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleDeleteAssociation: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationList: SwimSafetyAssocListSelector(state)
});

const mapDispatchToProps = {
  handleGetDashBoardCount: getSwimSafetyDashboardCountList,
  handlegetAssociationList: getSwimSafetyAssociationList,
  handleDeleteAssociation: getSwimSafetyDeleteAssociation
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
