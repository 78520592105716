export const ANNUALREPORT_COLLAB_API_FAILURE =
  'ANNUALREPORT_COLLAB_API_FAILURE';
export const ANNUALREPORT_COLLAB_NON_PROFIT_ORG =
  'ANNUALREPORT_COLLAB_NON_PROFIT_ORG';
export const ANNUALREPORT_COLLAB_EDUCATION_ORG =
  'ANNUALREPORT_COLLAB_EDUCATION_ORG';
export const ANNUALREPORT_COLLAB_GOVT_ORG_AFFILIATES =
  'ANNUALREPORT_COLLAB_GOVT_ORG_AFFILIATES';
export const ANNUALREPORT_COLLAB_OTHER_COMMUNITY_ORG =
  'ANNUALREPORT_COLLAB_OTHER_COMMUNITY_ORG';
export const ANNUALREPORT_COLLAB_PRIVATE_ORG_BUSINESS =
  'ANNUALREPORT_COLLAB_PRIVATE_ORG_BUSINESS';
export const ANNUALREPORT_COLLAB_ORATIONS_AMOUNG_YS =
  'ANNUALREPORT_COLLAB_ORATIONS_AMOUNG_YS';
export const ANNUALREPORT_COLLAB_FAILURE = 'ANNUALREPORT_COLLAB_FAILURE;';
