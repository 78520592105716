import * as types from './types';

const initialState = {
  AssociationDashboardSummary: {},
  AssociationDashboardChangeLog: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
  case types.ASSOCIATION_DASHBOARD_SUMMARY_SUCCESS: {
    return {
      ...state,
      AssociationDashboardSummary: action.payload
    };
  }
  case types.ASSOCIATION_DASHBOARD_SUMMARY_FAILURE:
    return { ...state, error: action.payload };

  case types.ASSOCIATION_DASHBOARD_CHANGE_LOG_SUCCESS: {
    return {
      ...state,
      AssociationDashboardChangeLog: action.payload
    };
  }
  case types.ASSOCIATION_DASHBOARD_CHANGE_LOG_FAILURE:
    return { ...state, error: action.payload };

  default:
    return state;
  }
}
