import { GET_VOLUNTEER_DETAILS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const getVolunteerRegistrationPreview = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_VOLUNTEER_DETAILS(),
    method: 'GET',
    successAction: data => ({
      type: types.VOLUNTEER_REGISTRATION_PREVIEW_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.VOLUNTEER_REGISTRATION_PREVIEW_FAILURE,
      payload: error
    }),
    dispatch
  });

export default getVolunteerRegistrationPreview;
