import * as types from './types';

const initialState = {
  AssociAdminLogin: {},
  AssociAdminLoginDetails:{}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
  case types.ASSOCIATION_ADMIN_LOGIN_SUCCESS: {
    return {
      ...state,
      AssociAdminLogin: action.payload
    };
  }
  case types.ASSOCIATION_ADMIN_LOGIN_FAILURE:
    return { ...state, error: action.payload };
   case types.ASSOCIATION_ADMIN_DETAILS_SUCCESS: {
      return {
        ...state,
        AssociAdminLoginDetails: action.payload
      };
    }
    case types.ASSOCIATION_ADMIN_DETAILS_FAILURE:
      return { ...state, error: action.payload };
  default:
    return state;
  }
}
