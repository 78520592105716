import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import commonAxios from '@utilities/CommonAxios';
import FeedbackPages from './feedback';

const Feedbackandsupport = () => {
  const [feedbackdata, setFeedBackData] = useState('');
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setFeedBackData(FeedbackPages?.data[0]);
    setLoader(true);
    commonAxios({
      url: '/feedback-and-support',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('strapitoken')}`
      }
    })
      .then(res => {
        setFeedBackData(res?.data?.data[0]);
        setLoader(false);
      })
      .catch(() => {
        setFeedBackData(FeedbackPages?.data[0]);
        setLoader(false);
      });
  }, []);
  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="container pb-3">
        <div
          className="feedback-support-title mb-3  mt-3"
          dangerouslySetInnerHTML={{
            __html: feedbackdata?.attributes?.Title
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: feedbackdata?.attributes?.Data_Collection
          }}
        />
      </div>
    </BlockUi>
  );
};
export default Feedbackandsupport;
