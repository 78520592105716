import { GET_MODULES_CONFIG } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const getModulesConfigData = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_MODULES_CONFIG(),
    successAction: data => ({
      type: types.GET_MODULES_CONFIG_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_MODULES_CONFIG_FAILURE,
      payload: error
    }),
    dispatch
  });

export default getModulesConfigData;
