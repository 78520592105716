import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { APP_LABELS } from '@config/app-config';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import {
  getAssociationList,
  getYearList
} from '@redux/annual-yusa-dashboard/actions';
import {
  YusaAssociationListSelector,
  YusaYearListSelector
} from '@redux/annual-yusa-dashboard/selectors';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import AssociationsTotal from '@static/images/Association.svg';
import Associationhover from '@static/images/Association-hover.svg';
import NotStarted from '@static/images/NotStarted.svg';
import NotStartedhover from '@static/images/NotStarted-hover.svg';
import inporgress from '@static/images/inporgress.svg';
import inporgresshover from '@static/images/inporgress-hover.svg';
import completed from '@static/images/completed.svg';
import completedhover from '@static/images/completed-hover.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import GetModulesConfigSelector from '@redux/module-config/selectors';
import Table from './table';
import Table2022 from './table2022';
import ASSOCIATION_COLUMNS from './association_columns';
import ASSOCIATION_COLUMNS_2022 from './association_columns2022';

const YusaAnnualDashboard = props => {
  const { ModulesConfigData } = props;

  const year = get(ModulesConfigData, 'data')?.find(
    o => o?.moduleName === 'Annual Reports'
  )?.year;

  const {
    LoginUserData,
    AssociationList,
    handleToGetAssociationList,
    handleGetYearList,
    YearList
  } = props;

  const [loader, setLoader] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [associationType, setAssociationType] = useState('totalData');
  const [selectedYear, setSelectedYear] = useState(year);
  const [yearListLoader, setYearListLoader] = useState(false);
  const [associationListLoader, setAssociationListLoader] = useState(false);
  const [associationData, setAssociationData] = useState({
    totalData: [],
    completed: [],
    NotStarted: [],
    inProgress: []
  });

  useEffect(() => {
    setLoader(true);
    setYearListLoader(false);
    handleGetYearList().then(res => {
      if (res.status === 200) {
        setYearListLoader(true);
      } else {
        setYearListLoader(true);
        setErrorPopup(true);
      }
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    setAssociationListLoader(false);
    window.scrollTo(0, 0);

    handleToGetAssociationList(selectedYear).then(res => {
      if (res.status === 200) {
        setAssociationListLoader(true);
      } else {
        setAssociationListLoader(true);
        setErrorPopup(true);
      }
    });
  }, [selectedYear]);

  useEffect(() => {
    if (associationListLoader && yearListLoader) {
      setLoader(false);
    }
  }, [associationListLoader, yearListLoader]);
  useEffect(() => {
    if (AssociationList?.data?.length === 0) {
      setAssociationData({
        totalData: [],
        completed: [],
        NotStarted: [],
        inProgress: []
      });
    }
    if (AssociationList && AssociationList?.data?.length > 0) {
      setAssociationData({
        totalData: AssociationList?.data,
        completed: AssociationList?.data?.filter(
          data => data?.status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED
        ),
        NotStarted: AssociationList?.data?.filter(
          data => data?.status?.toUpperCase() === APP_LABELS.STATUS_NOT_STARTED
        ),
        inProgress: AssociationList?.data?.filter(
          data => data?.status?.toUpperCase() === APP_LABELS.STATUS_IN_PROGRESS
        )
      });
    }
  }, [AssociationList]);

  const setAssociationinfo = data => {
    sessionStorage.setItem('yusa_selected_associate', JSON.stringify(data));
  };
  const getAssociationListForTable = type =>
    associationData[type]?.map(data => {
      const location = {
        pathname: '/annual/report/dashboard',
        state: data
      };

      return {
        AssociationName: `${get(data, 'associationName', '-')} (${get(
          data,
          'associationNumber',
          '-'
        )})`,
        MemberGroup: get(data, 'memberGroup', '-'),
        UpdatedDateTime: get(data, 'updatedDateTime', null)
          ? convertUtcToLocal(get(data, 'updatedDateTime'))
          : '-',
        ContactEmail: get(data, 'email', '-'),
        ContactNumber: get(data, 'phoneNumber', '-'),
        Status: get(data, 'status', '-'),
        PledgeStatement: get(data, 'pledgeStatement', '-'),
        Progress: get(data, 'completedReports', '-'),
        DetailPage: (
          <Link
            to={location}
            className="table-link"
            onClick={() => setAssociationinfo(data)}
          >
            {APP_LABELS.DASH_VIEW_DETAILS}
          </Link>
        )
      };
    });

  const getAssociationListForTable2022 = type =>
    associationData[type]?.map(data => {
      const location = {
        pathname: '/annual/report/dashboard',
        state: data
      };

      return {
        AssociationName: `${get(data, 'associationName', '-')} (${get(
          data,
          'associationNumber',
          '-'
        )})`,
        MemberGroup: get(data, 'memberGroup', '-'),
        UpdatedDateTime: get(data, 'updatedDateTime', null)
          ? convertUtcToLocal(get(data, 'updatedDateTime'))
          : '-',
        ContactEmail: get(data, 'email', '-'),
        ContactNumber: get(data, 'phoneNumber', '-'),
        Status: get(data, 'status', '-'),
        DetailPage: (
          <Link
            to={location}
            className="table-link"
            onClick={() => {
              sessionStorage.setItem(
                'yusa_selected_associate',
                JSON.stringify(data)
              );
            }}
          >
            {APP_LABELS.DASH_VIEW_DETAILS}
          </Link>
        )
      };
    });

  const handleSelectedYear = selectedValue => {
    setSelectedYear(selectedValue);
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper dashboard-wrapper">
          <div className="container">
            <section className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-lg-11 col-md-6 col-sm-12">
                  <div className="user-name custom-username">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>{APP_LABELS.WELCOME}</h6>
                        <h2 className="ymca-sub-heading mb-0 notranslate">
                          {`${get(
                            LoginUserData,
                            'user.firstName',
                            'User'
                          )} ${get(LoginUserData, 'user.lastName', '')}`}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="select-date ms-auto">
                  <span>Select Year:</span>
                  <select
                    className="form-select form-select-sm year-picker"
                    aria-label=".form-select-sm example"
                    name="selectYear"
                    defaultValue={selectedYear}
                    value={selectedYear}
                    onChange={e => handleSelectedYear(e.target.value)}
                  >
                    {YearList?.data &&
                      YearList?.data?.sort()?.map(data => (
                        <option key={data} value={data}>
                          {data}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </section>

            <section className="stats-data">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <button
                    type="button"
                    className="card stats-card associations ymca-associate w-100"
                    onClick={() => setAssociationType('totalData')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={AssociationsTotal}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={Associationhover}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {AssociationList ? AssociationList?.data?.length : 0}
                        </h4>
                        <p> No.of Associations</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <button
                    type="button"
                    className="card stats-card branches ymca-completed w-100"
                    onClick={() => setAssociationType('completed')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img src={completed} className="dark-icon" alt="icon" />
                        <img
                          src={completedhover}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {AssociationList
                            ? AssociationList?.data?.filter(
                                data =>
                                  data?.status?.toUpperCase() ===
                                  APP_LABELS.STATUS_COMPLETED
                              )?.length
                            : 0}
                        </h4>
                        <p>Completed</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <button
                    type="button"
                    className="card stats-card profiles ymca-progress w-100"
                    onClick={() => setAssociationType('inProgress')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={inporgress}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={inporgresshover}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {AssociationList
                            ? AssociationList?.data?.filter(
                                data =>
                                  data?.status?.toUpperCase() ===
                                  APP_LABELS.STATUS_IN_PROGRESS
                              )?.length
                            : 0}
                        </h4>
                        <p>In Progress</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <button
                    type="button"
                    className="card stats-card programs ymca-notStarted w-100"
                    onClick={() => setAssociationType('NotStarted')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={NotStarted}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={NotStartedhover}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {AssociationList
                            ? AssociationList?.data?.filter(
                                data =>
                                  data?.status?.toUpperCase() ===
                                  APP_LABELS.STATUS_NOT_STARTED
                              )?.length
                            : 0}
                        </h4>
                        <p>Not Started</p>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </section>
          </div>

          <hr className="custom-hr mt-0" />
          {selectedYear === '2022' ? (
            <Table2022
              rows={getAssociationListForTable2022(associationType)}
              headers={ASSOCIATION_COLUMNS_2022}
              associationType={associationType}
              setLoader={setLoader}
              year={selectedYear}
            />
          ) : (
            <Table
              rows={getAssociationListForTable(associationType)}
              headers={ASSOCIATION_COLUMNS}
              associationType={associationType}
              setLoader={setLoader}
              year={selectedYear}
            />
          )}
        </div>
      </main>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={APP_LABELS.COMMON_ERROR_MESSAGE}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

YusaAnnualDashboard.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationList: PropTypes.objectOf(PropTypes.any).isRequired,
  YearList: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetYearList: PropTypes.func.isRequired,
  handleToGetAssociationList: PropTypes.func.isRequired,
  ModulesConfigData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleToGetAssociationList: getAssociationList,
  handleGetYearList: getYearList
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationList: YusaAssociationListSelector(state),
  YearList: YusaYearListSelector(state),
  ModulesConfigData: GetModulesConfigSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YusaAnnualDashboard);
