import {
  ASSOCIATION_ADMIN_LOGIN,
  ASSOCIATION_ADMIN_DETAILS
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getAssociationAdminLogin = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: ASSOCIATION_ADMIN_LOGIN(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.ASSOCIATION_ADMIN_LOGIN_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ASSOCIATION_ADMIN_LOGIN_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getAssociationAdminDetails =
  (objtoken) => (dispatch, getState) =>
    asyncRequest({
      url: ASSOCIATION_ADMIN_DETAILS(),
      method: 'GET',
      token: `Bearer ${objtoken}`,
      successAction: data => ({
        type: types.ASSOCIATION_ADMIN_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_ADMIN_DETAILS_FAILURE,
        payload: error
      }),
      dispatch
    });
