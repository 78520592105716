import { AR_EMPLOYEE_UPDATE_GETAPI } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getARemployeeUpdateApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: AR_EMPLOYEE_UPDATE_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.FETCH_AR_EMPLOYEE_UPDATE_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.FETCH_AR_EMPLOYEE_API_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostARemployeeUpdateApi = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: AR_EMPLOYEE_UPDATE_GETAPI(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.FETCH_AR_EMPLOYEE_UPDATE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.FETCH_AR_EMPLOYEE_API_FAILURE,
      payload: error
    }),
    dispatch
  });
