const USER_TABLE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'notranslate'
  },
  {
    Header: 'Email Id',
    accessor: 'emailAddress',
    className: 'notranslate'
  },
  {
    Header: 'Module',
    accessor: 'module',
    className: 'notranslate'
  },
  {
    Header: 'Role',
    accessor: 'Role',
    className: 'notranslate'
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'notranslate'
  },
  {
    Header: '',
    accessor: 'DetailPage'
  }
];

export default USER_TABLE_COLUMNS;
