const Columns = [
  {
    Header: 'Upload Date & Time',
    accessor: 'UploadDateTime',
    className: 'notranslate'
  },
  {
    Header: 'Name',
    accessor: 'Name',
    className: 'notranslate'
  },
  {
    Header: 'Staff Type',
    accessor: 'StaffType',
    className: 'notranslate'
  },
  {
    Header: 'Current Unique Job Title',
    accessor: 'CurrentUniqueJobTitle',
    className: 'notranslate'
  },
  {
    Header: '',
    accessor: 'viewDetails',
    disableSortBy: true
  }
];

export default Columns;
