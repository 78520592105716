import React from 'react';
import PropTypes from 'prop-types';

const PreviewPopup = props => {
  const { popupTital, displayValue, modelTargetValue } = props;

  return (
    <div
      className="modal fade"
      id={modelTargetValue}
      tabIndex="-1"
      aria-labelledby="BackgroundScreeningModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog file-upload-popup">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="BackgroundScreeningModalLabel">
              {popupTital}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {displayValue && (
              <iframe
                className="ymca-iframe"
                title={displayValue.name}
                src={URL.createObjectURL(displayValue)}
                width="100%"
                height="500px"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewPopup.propTypes = {
  popupTital: PropTypes.string.isRequired,
  modelTargetValue: PropTypes.string.isRequired,
  displayValue: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PreviewPopup;
