import React from 'react';
import PropTypes from 'prop-types';
import convertUtcToLocal from '@utilities/ConvertTimezone';

const LastUpdateName = props => {
  const { Username, Time } = props;

  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);

  if (Username && Time)
    return (
      <div className="ymca-endListing" key={crypto.getRandomValues(array)}>
        <div className="ymca-subject">Last Updated By:</div>
        <span className="ymca-nameTimings mx-1">{Username} </span>
        <div className="ymca-subject"> On:</div>
        <span className="ymca-nameTimings mx-1">{convertUtcToLocal(Time)}</span>
      </div>
    );

  return null;
};

LastUpdateName.propTypes = {
  Username: PropTypes.string.isRequired,
  Time: PropTypes.string.isRequired
};

export default React.memo(LastUpdateName);
