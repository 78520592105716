import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postVolunteerDocumentApprovalData } from '@redux/swim-registration/volunteer-admin-preview/actions';
import { VolunteerAdminDocumentApprovalSelector } from '@redux/swim-registration/volunteer-admin-preview/selectors';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import crossicon from '@static/images/cross-icon-blue.svg';
import { checkDateValidation } from '@state/utils/utilities';

const ApproveModal = props => {
  const {
    handlePostVolunteerDocumentApproval,
    documentDetail,
    volunteerID,
    setLoader,
    handleVolunteerAdminPreview,
    editDecision
  } = props;
  const [expiryDateTime, setExpiryDateTime] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const [errorPopup, setErrorPopup] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const minDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split('T')[0];
  const maxDate = new Date(
    new Date(9999, 11, 31).getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split('T')[0];

  useEffect(() => {
    const expiryDateTime1 = documentDetail?.expiryDate
      ? moment(documentDetail?.expiryDate).format('YYYY-MM-DD')
      : new Date().toISOString().substring(0, 10);

    setExpiryDateTime(expiryDateTime1);
  }, [documentDetail]);

  const handleExpiryDateTimeChange = e => {
    if (e.target.value) {
      setErrorFlag(false);
    }
    setExpiryDateTime(e.target.value);
  };

  const onSubmit = () => {
    setLoader(true);
    if (expiryDateTime === '') {
      setErrorFlag(true);
      setLoader(false);
      return;
    }
    if (checkDateValidation(expiryDateTime)) {
      setLoader(false);
      return;
    }

    const objectPayload = {
      volunteerDocumentId: documentDetail.volunteerDocumentId,
      documentTypeId: documentDetail.documentTypeId,
      volunteerId: volunteerID,
      status: 'Approved',
      expiryDate: expiryDateTime
    };
    setErrorFlag(false);
    handlePostVolunteerDocumentApproval(objectPayload).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setLoader(false);
        handleVolunteerAdminPreview(true);
        editDecision(false);
        document.getElementById('approveModal').click();
        setExpiryDateTime(new Date().toISOString().substring(0, 10));
      } else {
        setLoader(false);
        editDecision(false);
        document.getElementById('approveModal').click();
        setExpiryDateTime(new Date().toISOString().substring(0, 10));
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };
  const handleCancel = () => {
    document.getElementById('approveModal').click();
    setErrorFlag(false);
    setExpiryDateTime(new Date().toISOString().substring(0, 10));
  };

  // THIS BELOW CODE IS FOR DISABLE DATE INPUT FIELD
  // const disableDateInput = () => {
  //   let disablFlag = false;
  //   if (
  //     documentDetail?.documentTypeString === 'BackgroundScreening' &&
  //     documentDetail?.status === 'Success'
  //   ) {
  //     disablFlag = true;
  //   }
  //   return disablFlag;
  // };

  return (
    <>
      <div
        className="modal fade approve-modal"
        id="approveModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="approveModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div
                className="modal-title ymca-section-heading"
                id="approveModalLabel"
              >
                Approve
              </div>
              <button
                type="button"
                className="close border-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              >
                <img
                  src={crossicon}
                  alt="cross-icon-blue"
                  className="close-icon img-fluid"
                />
              </button>
            </div>
            <div className="modal-body">
              <form className="ymca-registeration-form">
                <label htmlFor="date" className="mb-2">
                  Expired Date
                </label>
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control"
                    value={expiryDateTime}
                    onChange={handleExpiryDateTimeChange}
                    id="expiry-on-date"
                    name="expiryOnDate"
                    min={minDate}
                    max={maxDate}
                    // disabled={disableDateInput()}
                  />
                  {errorFlag && (
                    <div className="errorNote">
                      * Please select expired date.
                    </div>
                  )}
                  {checkDateValidation(expiryDateTime) && (
                    <div className="errorNote">
                      * Please select valid expired date.
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div className="modal-footer mt-2">
              <button
                type="button"
                className="btn list-group-item border-0 p-0 me-2 ymca-link text-decoration-none"
                onClick={() => onSubmit()}
              >
                Add
              </button>
              <button
                type="button"
                className="list-group-item border-0 p-0 ymca-grey-link text-decoration-none"
                data-bs-dismiss="modal"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
    </>
  );
};

ApproveModal.propTypes = {
  volunteerID: PropTypes.string.isRequired,
  documentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleVolunteerAdminPreview: PropTypes.func.isRequired,
  handlePostVolunteerDocumentApproval: PropTypes.func.isRequired,
  editDecision: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handlePostVolunteerDocumentApproval: postVolunteerDocumentApprovalData
};

const mapStateToProps = state => ({
  VolunteerAdminDocumentApprovalSelectorData:
    VolunteerAdminDocumentApprovalSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ApproveModal));
