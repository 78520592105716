const SWIM_ASSOCIATION_COLUMNS = () => [
  {
    Header: 'Association Name',
    accessor: 'associationName',
    className: 'notranslate'
  },
  {
    Header: 'No.Of Branches',
    accessor: 'branchesCount',
    className: 'notranslate'
  },

  {
    Header: 'No.Of Swim Programs',
    accessor: 'programsCount',
    className: 'notranslate'
  },
  {
    Header: 'No.Of Profiles',
    accessor: 'profilesCount',
    className: 'notranslate '
  },
  {
    Header: 'Last Updated',
    accessor: 'lastUpdatedDate',
    className: 'notranslate ymca-word-break '
  },
  {
    Header: 'Actions',
    accessor: 'details',
    className: 'notranslate'
  }
];

export default SWIM_ASSOCIATION_COLUMNS;
