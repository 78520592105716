import {
  GET_YUSA_ASSOCIATION_LIST,
  GET_YUSA_YEAR_LIST
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getAssociationList = year => (dispatch, getState) =>
  asyncRequest({
    url: GET_YUSA_ASSOCIATION_LIST(year),
    successAction: data => ({
      type: types.YUSA_ASSOCIATION_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.YUSA_ASSOCIATION_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getYearList = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_YUSA_YEAR_LIST(),
    successAction: data => ({
      type: types.YUSA_YEAR_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.YUSA_YEAR_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });
