import * as types from './types';

const initialState = {
  GetArmedService: {},
  ArmedService: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ARMED_SERVICE_GET_SUCCESS: {
      return {
        ...state,
        GetArmedService: action.payload
      };
    }
    case types.ARMED_SERVICE_GET_FAILURE:
      return { ...state, error: action.payload };
    case types.ARMED_SERVICE_SUCCESS: {
      return {
        ...state,
        ArmedService: action.payload
      };
    }
    case types.ARMED_SERVICE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
