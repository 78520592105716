import {
  GET_SWIMSAFETY_DASHBOARD_COUNT_API,
  GET_SWIMSAFETY_ASSOCIATION_API,
  GET_SWIMSAFETY_ADD_UPDATE_ASSOCIATION_API,
  GET_SWIMSAFETY_ASSOCIATION_DELETE_API,
  GET_SWIM_ASSOCIATION_LIST,
  SAVE_SWIM_ASSOCIATION,
  GET_SWIMSAFETY_ASSOCIATION_BY_FILTERS_API,
  GET_SPECIAL_GRANT_LIST,
  GET_SWIM_ASSOCIATION_DROPDOWN_API
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSwimSafetyDashboardCountList =
  (startDate, endDate, Offset, includeAllYs) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_DASHBOARD_COUNT_API(
        startDate,
        endDate,
        Offset,
        includeAllYs
      ),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_DASHBOARD_COUNT_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_DASHBOARD_COUNT_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyAssociationList =
  (startDate, endDate, Offset, includeAllYs) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_ASSOCIATION_API(
        startDate,
        endDate,
        Offset,
        includeAllYs
      ),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_ASSOCIATION_LIST_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_ASSOCIATION_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyAssociationByFilters =
  (startDate, endDate, includeAllYs, Offset) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_ASSOCIATION_BY_FILTERS_API(
        startDate,
        endDate,
        includeAllYs,
        Offset
      ),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_ASSOCIATION_LIST_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_ASSOCIATION_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyAssociationDropdown = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIM_ASSOCIATION_LIST(),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_ASSOCIATION_DROPDOWN_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_ASSOCIATION_DROPDOWN_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSpecialGrantDropdown = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_SPECIAL_GRANT_LIST(),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_GRANT_DROPDOWN_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_GRANT_DROPDOWN_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimSafetyAddORupdateAssociation =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_ADD_UPDATE_ASSOCIATION_API(AssociationId),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_ADD_ASSOCIATION_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_DASHBOARD_COUNT_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyDeleteAssociation =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_ASSOCIATION_DELETE_API(AssociationId),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_DELETE_ASSOCIATION_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_DELETE_ASSOCIATION_FAILURE,
        payload: error
      }),
      dispatch
    });

export const saveAssociaton = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: SAVE_SWIM_ASSOCIATION(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.SWIMSAFETY_SAVE_ASSOCIATION_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_SAVE_ASSOCIATION_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimAssociationDropdown = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIM_ASSOCIATION_DROPDOWN_API(),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_EXCLUDE_ASSOCIATION_DROPDOWN_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_EXCLUDE_ASSOCIATION_DROPDOWN_FAILURE,
      payload: error
    }),
    dispatch
  });
