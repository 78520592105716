import {
  GET_SWIMSAFETY_PROGRAMS_LIST_API,
  GET_SWIMSAFETY_PROGRAMS_DETAILS_API,
  GET_SWIMSAFETY_ADD_OR_UPDATE_API,
  GET_SWIMSAFETY_DELETE_API,
  GET_SWIMSAFETY_PROGRAMS_BY_BRANCH_API,
  GET_SWIMSAFETY_PROGRAMS_BY_FILTERS_API
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSwimSafetyProgramsList =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_PROGRAMS_LIST_API(AssociationId),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_PROGRAMS_LIST_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_PROGRAMS_API_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyProgramsDetails =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_PROGRAMS_DETAILS_API(AssociationId),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_PROGRAMS_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_PROGRAMS_DETAILS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyProgramsAddORupdate =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_ADD_OR_UPDATE_API(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.SWIMSAFETY_PROGRAMS_UPDATE_OR_ADD_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_PROGRAMS_UPDATE_OR_ADD_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyProgramsDelete = DeleteId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_DELETE_API(DeleteId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_PROGRAMS_DELETE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_PROGRAMS_API_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimProgramsByBranchId = BranchId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_PROGRAMS_BY_BRANCH_API(BranchId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_PROGRAMS_BY_BRANCHES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_PROGRAMS_API_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimSafetyProgramsByFilters =
  (startDate, endDate, Offset, ShowAll, associationNumber) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_PROGRAMS_BY_FILTERS_API(
        startDate,
        endDate,
        Offset,
        ShowAll,
        associationNumber
      ),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_PROGRAMS_BY_FILTERS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_PROGRAMS_BY_FILTERS_FAILURE,
        payload: error
      }),
      dispatch
    });
