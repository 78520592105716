import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import Breadcrumb from '@static/images/breadcrumb-icon.svg';
import Select from 'react-select';
import { Formik, Form, ErrorMessage } from 'formik';
import { APP_LABELS } from '@config/app-config';
import TextError from '@modules/shared/components/reusable-components/text-error';
import { Multiselect } from 'multiselect-react-dropdown';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import PropTypes from 'prop-types';
import {
  getSwimSafetyAssociationDropdown,
  saveAssociaton
} from '@redux/siwm-safety/dashboard/actions';
import BackIcon from '@static/images/back-icon.svg';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import CustomModal from '@utilities/CustomModal';

const AddAssociation = props => {
  const { handlegetAssociationList, handleSaveAssociation } = props;

  const [addBranch, setAddBranch] = useState(false);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [selectedbranches, setSelectedbranches] = useState([]);
  const [loader, setLoader] = useState(false);
  const [associationList, setAssociationList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const validationObj = {
    association: Yup.string()
      .nullable()
      .required('* Association Name is required.')
  };
  const validationSchema = Yup.object(validationObj);

  const initialValues = {
    association: '',
    branches: []
  };

  useEffect(async () => {
    setLoader(true);

    await handlegetAssociationList()
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          const data = result?.data?.data?.map(res => ({
            ...res,
            label: res?.associationName,
            value: res?.associationNumber
          }));
          setAssociationList(data);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const handleOnBranchSelect = selectedList => {
    setSelectedbranches(selectedList);
  };

  const handleOnBranchRemove = selectedList => {
    setSelectedbranches(selectedList);
  };

  const prepareBrachList = associationNumber => {
    const associationDetails = associationList.find(
      asso => asso.associationNumber === associationNumber
    );
    setBranchDropdown(associationDetails.branchs);
  };

  const getAssociationByAN = associationNumber =>
    associationList.find(asso => asso.associationNumber === associationNumber);

  const onSubmit = formData => {
    const associationDetails = getAssociationByAN(formData.association);

    const branchesData = selectedbranches?.map(data => ({
      // id: data?.branchId,
      branchNumber: data?.branchNumber,
      name: data?.branchName,
      address: data?.branchAddress,
      contactNumber: data?.branchPhoneNumber,
      branchId: data?.branchId
    }));

    const payload = {
      associationId: associationDetails.associationId,
      associationNumber: associationDetails.associationNumber,
      name: associationDetails.associationName,
      address: associationDetails.associationAddress,
      email: associationDetails.associationEmail,
      contactNumber: associationDetails.associationPhoneNumber,
      swimSafetyBranches: branchesData
    };
    setLoader(true);
    handleSaveAssociation(payload)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          setSuccessPopup(true);
          setSuccessMessage(APP_LABELS.ASSOCIATION_SUCCESS_MESSAGE);
        } else {
          setLoader(false);
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <div className="container">
              <section className="dashboard-header">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb ymca-breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/swim/dashboard">
                            {APP_LABELS.NAV_DASHBOARD}
                          </Link>
                          <img
                            src={Breadcrumb}
                            className="breadcrumb-icon"
                            alt="double-right-arrow"
                          />
                        </li>
                        <li className="breadcrumb-item active">
                          {APP_LABELS.ADD_ASSOCIATION}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                    <Link className="ymca-title-link" to="/swim/dashboard">
                      <img
                        src={BackIcon}
                        className="ymca-back-link"
                        alt="back-icon"
                      />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-lg-7 col-md-6 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading mb-2">
                        {APP_LABELS.ADD_ASSOCIATION}
                      </h2>
                    </div>
                  </div>
                </div>
              </section>
              <hr className="custom-hr mt-0" />
              <div className="container">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({ setFieldValue }) => (
                    <Form
                      autoComplete="off"
                      className="ymca-registeration-form"
                    >
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="Association"
                            className="form-label text-black fw-bolder"
                          >
                            {APP_LABELS.ASSOCIATION_NAME}
                            <span className="text-mandatory ms-1">*</span>
                          </label>

                          <Select
                            name="association"
                            className="swim-add-association-dropdown notranslate"
                            value={selectedOption}
                            placeholder="Select"
                            onChange={value => {
                              setSelectedbranches([]);
                              setFieldValue(
                                'association',
                                value?.associationNumber
                              );
                              setSelectedOption(value);
                              prepareBrachList(value?.associationNumber);
                            }}
                            options={associationList}
                            isClearable
                          />

                          <ErrorMessage
                            component={TextError}
                            name="association"
                          />
                        </div>
                      </div>

                      {!addBranch && (
                        <div className="col-md-12">
                          <span className="mb-2 required fw-bold text-black">
                            Branch List
                          </span>
                          <div className="my-3">
                            <button
                              type="button"
                              className="btn swim-addbranch-btn"
                              onClick={() => setAddBranch(true)}
                              disabled={branchDropdown.length === 0}
                            >
                              {APP_LABELS.ADD_BRANCH}
                            </button>
                            <span className="mb-2 required fw-bold text-black">
                              {APP_LABELS.ADD_BRANCH_TEXT}
                            </span>
                          </div>
                        </div>
                      )}

                      {addBranch && (
                        <div className="col-md-6">
                          <span className="mb-2 required fw-bold text-black">
                            {APP_LABELS.DETAIL_BRANCH_NAME}
                          </span>

                          <Multiselect
                            className="swim-saftey-multi-branch notranslate"
                            name="branches"
                            showCheckbox
                            onSelect={handleOnBranchSelect}
                            onRemove={handleOnBranchRemove}
                            selectionLimit={50}
                            options={branchDropdown}
                            selectedValues={selectedbranches}
                            displayValue="branchName"
                            hidePlaceholder
                          />
                        </div>
                      )}

                      <hr className="custom-hr mt-3" />

                      <div className="">
                        <button
                          className="btn ymca-primary-btn mt-0 swim-addbranch-save-btn"
                          type="submit"
                        >
                          {APP_LABELS.SAVE}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </div>

      {redirect && <Redirect to="/swim/dashboard" />}
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {successPopup && (
        <CustomModal
          show={successPopup}
          type={APP_LABELS.SUCCESS}
          message={successMessage}
          submitButtonName="Ok"
          onSubmit={() => {
            setRedirect(true);
          }}
        />
      )}
    </BlockUi>
  );
};

AddAssociation.propTypes = {
  handlegetAssociationList: PropTypes.func.isRequired,
  handleSaveAssociation: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handlegetAssociationList: getSwimSafetyAssociationDropdown,
  handleSaveAssociation: saveAssociaton
};

export default connect(null, mapDispatchToProps)(AddAssociation);
