import {
  GET_VOLUNTEER_DETAILS_BY_PERSON_ID,
  POST_VOLUNTEER_APPROVAL,
  POST_VOLUNTEER_DOCUMENT_APPROVAL,
  POST_VOLUNTEER_INITIATE_BACKGROUND_SCREENING,
  POST_VOLUNTEER_CANCEL_INITIATED_BACKGROUND_SCREENING
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getVolunteerAdminPreviewList =
  volunteerId => (dispatch, getState) =>
    asyncRequest({
      url: GET_VOLUNTEER_DETAILS_BY_PERSON_ID(volunteerId),
      method: 'GET',
      successAction: data => ({
        type: types.VOLUNTEER_ADMIN_PREVIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.VOLUNTEER_ADMIN_PREVIEW_FAILURE,
        payload: error
      }),
      dispatch
    });

export const postVolunteerApprovalData = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: POST_VOLUNTEER_APPROVAL(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.VOLUNTEER_ADMIN_APPROVAL_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.VOLUNTEER_ADMIN_APPROVAL_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postVolunteerDocumentApprovalData =
  payloadBody => (dispatch, getState) =>
    asyncRequest({
      url: POST_VOLUNTEER_DOCUMENT_APPROVAL(),
      method: 'POST',
      data: payloadBody,
      successAction: data => ({
        type: types.VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_FAILURE,
        payload: error
      }),
      dispatch
    });
export const postVolunteerInitiateBackgroundScreening =
  payloadBody => (dispatch, getState) =>
    asyncRequest({
      url: POST_VOLUNTEER_INITIATE_BACKGROUND_SCREENING(),
      method: 'POST',
      data: payloadBody,
      successAction: data => ({
        type: types.VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_FAILURE,
        payload: error
      }),
      dispatch
    });
  export const postVolunteerCancelInitiatedBackgroundScreening =
    payloadBody => (dispatch, getState) =>
      asyncRequest({
        url:  POST_VOLUNTEER_CANCEL_INITIATED_BACKGROUND_SCREENING(),
        method: 'POST',
        data: payloadBody,
        successAction: data => ({
          type: types.VOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_SUCCESS,
          async: true,
          payload: data,
          state: getState()
        }),
        failureAction: error => ({
          type: types.VOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_FAILURE,
          payload: error
        }),
        dispatch
      });
  