/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import {
  getResourceMobilization,
  postResourceMobilization,
  resetReduxState
} from '@redux/diversity-inclusion-&-global-metrics/resource-mobilization/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetResourceMobilizationSelector } from '@redux/diversity-inclusion-&-global-metrics/resource-mobilization/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import Help from '@modules/shared/components/reusable-components/help';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import helpicon from '@static/images/caretdoubleright.svg';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  yRecruitsCommunicates: Yup.string().nullable(),
  yengagesDiverseStakeholdersDescription: Yup.string().nullable(),
  ySeeksResources: Yup.string().nullable(),
  diginFundraisingDescription: Yup.string().nullable(),
  fundingSourcesForDIGEfforts: Yup.array().nullable()
});

const ResourceMobilizationTab = props => {
  const {
    handleGetResourceMobilization,
    handlePostResourceMobilization,
    handleResetRedux,
    GetResourceMobilizationSelectorData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard
  } = props;
  const formikref = useRef();
  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Diversity Inclusion & Global Metrics';

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [help, setHelp] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [successRes, setSuccessRes] = useState('');

  const [
    yengagesDiverseStakeholdersDescriptionCount,
    setYengagesDiverseStakeholdersDescriptionCount
  ] = useState(
    GetResourceMobilizationSelectorData?.data
      ?.yengagesDiverseStakeholdersDescription?.length || 0
  );
  const [
    diginFundraisingDescriptionCount,
    setDiginFundraisingDescriptionCount
  ] = useState(
    GetResourceMobilizationSelectorData?.data?.diginFundraisingDescription
      ?.length || 0
  );

  const [
    yengagesDiverseStakeholdersDescription,
    setYengagesDiverseStakeholdersDescription
  ] = useState(
    GetResourceMobilizationSelectorData?.data
      ?.yengagesDiverseStakeholdersDescription
  );
  const [diginFundraisingDescription, setDiginFundraisingDescription] =
    useState(
      GetResourceMobilizationSelectorData?.data?.diginFundraisingDescription
    );

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = () => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetResourceMobilization(associationsNum).then(res => {
      if (res.status === 200) {
          setYengagesDiverseStakeholdersDescriptionCount(res?.data?.data?.yengagesDiverseStakeholdersDescription?.length || 0);
          setYengagesDiverseStakeholdersDescription(res?.data?.data?.yengagesDiverseStakeholdersDescription);
          setDiginFundraisingDescriptionCount(res?.data?.data?.diginFundraisingDescription?.length || 0);
          setDiginFundraisingDescription(res?.data?.data?.diginFundraisingDescription);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'resource-mobilization'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetResourceMobilization');
    };
  }, []);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const initialValues = {
    yRecruitsCommunicates: '',
    ySeeksResources: '',
    fundingSourcesForDIGEfforts: [],
    digeffortsFoundationList: '',
    digeffortsFederalGovernmentAgencyList: '',
    digeffortsOtherList: ''
  };

  const FundingSourcesForDIGEffortsData =
    GetResourceMobilizationSelectorData?.data?.ysDIGefforts;
  const fundingSourcesForDIGEffortsData =
    GetResourceMobilizationSelectorData?.data?.fundingSourcesForDIGEfforts;

  const finalFundingSourcesForDIGEfforts =
    FundingSourcesForDIGEffortsData?.filter(item => item.value)?.map(
      item => item.label
    );

  const textareadData = FundingSourcesForDIGEffortsData?.map(item => item.text);
  const finalArrayData = fundingSourcesForDIGEffortsData?.concat(
    finalFundingSourcesForDIGEfforts
  );

  const editValues = {
    yRecruitsCommunicates:
      GetResourceMobilizationSelectorData?.data?.yRecruitsCommunicates[0],

    ySeeksResources:
      GetResourceMobilizationSelectorData?.data?.ySeeksResources[0],

    fundingSourcesForDIGEfforts: finalArrayData,

    digeffortsFoundationList: get(textareadData, '[0]', ''),
    digeffortsFederalGovernmentAgencyList: get(textareadData, '[1]', ''),
    digeffortsOtherList: get(textareadData, '[2]', '')
  };

  const onSubmit = values => {
    setLoader(true);
    const fundingSourcesForDIGEffortsArray = [];
    const staticFundingSourcesForDIGEffortsArray = [
      'digeffortsOperationFunds',
      'digeffortsAnnualCampaign',
      'digeffortsGlobalFundraisingCampaign',
      'digeffortsLocalGovernment',
      'digeffortsSocialMedia',
      'digeffortsCrowdsourcing'
    ];

    const data = values.fundingSourcesForDIGEfforts;

    staticFundingSourcesForDIGEffortsArray.forEach(Ditem => {
      if (data.includes(Ditem)) {
        fundingSourcesForDIGEffortsArray.push(Ditem);
      }
      // return fundingSourcesForDIGEffortsArray;
    });

    const ysDIGeffortsArray = [];
    const staticysDIGeffortsArray = [
      'digeffortsFoundation',
      'digeffortsFederalGovernmentAgency',
      'digeffortsOther'
    ];

    const CheckboxTextareData = values.fundingSourcesForDIGEfforts;
    let obj = {};
    staticysDIGeffortsArray.forEach(Ditem => {
      if (CheckboxTextareData.includes(Ditem)) {
        if (Ditem === 'digeffortsFoundation') {
          obj = {
            label: Ditem,
            value: !!CheckboxTextareData.includes(Ditem),
            text: values.digeffortsFoundationList
          };
        } else if (Ditem === 'digeffortsFederalGovernmentAgency') {
          obj = {
            label: Ditem,
            value: !!CheckboxTextareData.includes(Ditem),
            text: values.digeffortsFederalGovernmentAgencyList
          };
        } else if (Ditem === 'digeffortsOther') {
          obj = {
            label: Ditem,
            value: !!CheckboxTextareData.includes(Ditem),
            text: values.digeffortsOtherList
          };
        }

        ysDIGeffortsArray.push(obj);
      }
      // return ysDIGeffortsArray;
    });

    const payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetResourceMobilizationSelectorData?.data?.createdByName,
      createdByPersonId:
        GetResourceMobilizationSelectorData?.data?.createdByPersonId,
      createdDatetime:
        GetResourceMobilizationSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      resourceMobilizationId:
        GetResourceMobilizationSelectorData?.data?.resourceMobilizationId,
      reportBatchId: GetResourceMobilizationSelectorData?.data?.reportBatchId,
      recordStatus: GetResourceMobilizationSelectorData?.data?.recordStatus,
      yRecruitsCommunicates: values.yRecruitsCommunicates
        ? [values.yRecruitsCommunicates]
        : [],
      yengagesDiverseStakeholdersDescription,
      ySeeksResources: values.ySeeksResources ? [values.ySeeksResources] : [],
      diginFundraisingDescription,
      fundingSourcesForDIGEfforts: fundingSourcesForDIGEffortsArray,
      ysDIGefforts: ysDIGeffortsArray
    };
    setSuccessPopup(false);
    handlePostResourceMobilization(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setSuccessRes(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall();
        setLoader(false);
        setSuccessPopup(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handelCount = (value, textareaName) => {
    if (textareaName === 'yengagesDiverseStakeholdersDescription') {
      setYengagesDiverseStakeholdersDescriptionCount(value?.length || 0);
      setYengagesDiverseStakeholdersDescription(value);
    } else if (textareaName === 'diginFundraisingDescription') {
      setDiginFundraisingDescriptionCount(value?.length || 0);
      setDiginFundraisingDescription(value);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, dirty, errors }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-covidRelif">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <p className="ymca-instructTitle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: instructions
                        }}
                      />
                    </p>
                  </div>
                </div>
                {GetResourceMobilizationSelectorData?.data?.recordStatus?.toUpperCase() ===
                  APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                <h4 className="ymca-headingTitle txt-mandatory">
                  {
                    'Our Y recruits, communicates with and involves diverse stakeholders (a variety of people and organizations from a variety of racial and ethnic backgrounds, ages, abilities, faiths, gender, sexual orientations, income levels) in our philanthropic efforts.'
                  }
                </h4>

                <div className="ymca-tableResponsive">
                  <table className="table ymca-table ymca-lastTable">
                    <tbody className="ymca-cuttingBody ymca-nostripeBody">
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yRecruitsCommunicates"
                              value="diverseStakeholdersInstitutionalized"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'We actively do so and have institutionalized this (e.g., in our strategic plan, board agenda, fundraising planning)'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yRecruitsCommunicates"
                              value="diverseStakeholdersOpportunities"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'We do this primarily when opportunities present themselves (i.e., not an institutionalized approach)'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yRecruitsCommunicates"
                              value="diverseStakeholdersInvited"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'We do this primarily when invited by other stakeholders'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yRecruitsCommunicates"
                              value="diverseStakeholdersNotYetDo"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              We do not yet do this
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  Please provide a brief description of how your Y engages
                  diverse stakeholders.
                </h4>
                <textarea
                  name="yengagesDiverseStakeholdersDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={yengagesDiverseStakeholdersDescription}
                  defaultValue={
                    GetResourceMobilizationSelectorData?.data
                      ?.yengagesDiverseStakeholdersDescription
                  }
                  onChange={e =>
                    handelCount(
                      e.target.value,
                      'yengagesDiverseStakeholdersDescription'
                    )
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>
                      {`(${yengagesDiverseStakeholdersDescriptionCount}
                      /8000)`}
                    </p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle txt-mandatory">
                  Our Y seeks resources for our diversity, inclusion, and global
                  efforts by highlighting the Ys global impact and commitment to
                  greater diversity and inclusion, etc.
                </h4>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table ymca-lastTable">
                    <tbody className="ymca-cuttingBody ymca-nostripeBody">
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="ySeeksResources"
                              value="yglobalImpactInstitutionalized"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'We actively do so and have institutionalized this (e.g., in our strategic plan, board agenda, fundraising planning)'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="ySeeksResources"
                              value="yglobalImpactOpportunities"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'We do this primarily when opportunities present themselves (i.e., not an institutionalized approach)'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="ySeeksResources"
                              value="yglobalImpactInvited"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'We do this primarily when invited by funders and other stakeholders'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="ySeeksResources"
                              value="yglobalImpactNotYetDo"
                              className="ymca-fieldRadio"
                            />
                            <span className="ymca-texting mt-0">
                              We do not yet do this
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  Please provide a brief description of how your Y highlights
                  DIG in fundraising.
                </h4>
                <textarea
                  name="diginFundraisingDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={diginFundraisingDescription}
                  defaultValue={
                    GetResourceMobilizationSelectorData?.data
                      ?.diginFundraisingDescription
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'diginFundraisingDescription')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${diginFundraisingDescriptionCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle txt-mandatory">
                  Please list funding sources for your Ys DIG efforts.
                </h4>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table ymca-lastTable">
                        <tbody className="ymca-cuttingBody ymca-nostripeBody">
                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <input
                                  type="checkbox"
                                  className="ymca-checkBox"
                                />
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsOperationFunds"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsOperationFunds"
                                />
                                <label htmlFor="digeffortsOperationFunds" />
                                <span className="ymca-texting">
                                  Operating funds
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsAnnualCampaign"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsAnnualCampaign"
                                />
                                <label htmlFor="digeffortsAnnualCampaign" />
                                <span className="ymca-texting">
                                  Annual Campaign
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsGlobalFundraisingCampaign"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsGlobalFundraisingCampaign"
                                />
                                <label htmlFor="digeffortsGlobalFundraisingCampaign" />
                                <span className="ymca-texting">
                                  {
                                    ' Diversity, inclusion, or global fundraising campaign'
                                  }
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsLocalGovernment"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsLocalGovernment"
                                />
                                <label htmlFor="digeffortsLocalGovernment" />
                                <span className="ymca-texting">
                                  Local government
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsSocialMedia"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsSocialMedia"
                                />
                                <label htmlFor="digeffortsSocialMedia" />
                                <span className="ymca-texting">
                                  Social media
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsCrowdsourcing"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsCrowdsourcing"
                                />
                                <label htmlFor="digeffortsCrowdsourcing" />
                                <span className="ymca-texting">
                                  Crowdfunding or crowdsourcing
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table ymca-lastTable">
                        <tbody className="ymca-cuttingBody ymca-nostripeBody">
                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsFoundation"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsFoundation"
                                />
                                <label htmlFor="digeffortsFoundation" />
                                <span className="ymca-texting">
                                  Foundation(s),please list:
                                </span>
                              </div>

                              <Field
                                as="textarea"
                                name="digeffortsFoundationList"
                                maxLength={1000}
                                className={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsFoundation'
                                  )
                                    ? 'ymca-areaTextWidth ymca-70 ymca-nomarginAreas ymca-fieldDisabled'
                                    : 'ymca-areaTextWidth ymca-70 ymca-nomarginAreas'
                                }
                                disabled={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsFoundation'
                                  )
                                }
                                value={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsFoundation'
                                  )
                                    ? ''
                                    : values.digeffortsFoundationList
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsFederalGovernmentAgency"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsFederalGovernmentAgency"
                                />
                                <label htmlFor="digeffortsFederalGovernmentAgency" />
                                <span className="ymca-texting">
                                  Federal government agency, please list:
                                </span>
                              </div>

                              <Field
                                as="textarea"
                                name="digeffortsFederalGovernmentAgencyList"
                                maxLength={1000}
                                className={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsFederalGovernmentAgency'
                                  )
                                    ? 'ymca-areaTextWidth ymca-70 ymca-nomarginAreas ymca-fieldDisabled'
                                    : 'ymca-areaTextWidth ymca-70 ymca-nomarginAreas'
                                }
                                disabled={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsFederalGovernmentAgency'
                                  )
                                }
                                value={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsFederalGovernmentAgency'
                                  )
                                    ? ''
                                    : values.digeffortsFederalGovernmentAgencyList
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap ymca-checkboxers">
                              <div className="d-flex ymca-listingERA">
                                <Field
                                  name="fundingSourcesForDIGEfforts"
                                  id="digeffortsOther"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="digeffortsOther"
                                />
                                <label htmlFor="digeffortsOther" />
                                <span className="ymca-texting">
                                  Other, please list:
                                </span>
                              </div>

                              <Field
                                as="textarea"
                                name="digeffortsOtherList"
                                maxLength={1000}
                                className={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsOther'
                                  )
                                    ? 'ymca-areaTextWidth ymca-70 ymca-nomarginAreas ymca-fieldDisabled'
                                    : 'ymca-areaTextWidth ymca-70 ymca-nomarginAreas'
                                }
                                disabled={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsOther'
                                  )
                                }
                                value={
                                  !values.fundingSourcesForDIGEfforts?.includes(
                                    'digeffortsOther'
                                  )
                                    ? ''
                                    : values.digeffortsOtherList
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={
                    GetResourceMobilizationSelectorData?.data?.updatedByName
                  }
                  Time={
                    GetResourceMobilizationSelectorData?.data?.updatedDatetime
                  }
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={successRes} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      {help && (
        <Help hidePopup={hideShowHelp} keyValue="resource-mobilization" />
      )}
    </div>
  );
};

ResourceMobilizationTab.propTypes = {
  handleGetResourceMobilization: PropTypes.func.isRequired,
  handlePostResourceMobilization: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetResourceMobilizationSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetResourceMobilization: getResourceMobilization,
  handlePostResourceMobilization: postResourceMobilization,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetResourceMobilizationSelectorData: GetResourceMobilizationSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ResourceMobilizationTab));
