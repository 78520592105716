export const FETCH_FILE_TEMPLATE_URL_SUCCESS =
  'FETCH_FILE_TEMPLATE_URL_SUCCESS';
export const FETCH_FILE_TEMPLATE_URL_FAILURE =
  'FETCH_FILE_TEMPLATE_URL_FAILURE';

export const FETCH_FILE_TEMPLATE_SUCCESS = 'FETCH_FILE_TEMPLATE_SUCCESS';
export const FETCH_FILE_TEMPLATE_FAILURE = 'FETCH_FILE_TEMPLATE_FAILURE';

export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const UPLOAD_FILE_DROPDOWN_SUCCESS = 'UPLOAD_FILE_DROPDOWN_SUCCESS';
export const UPLOAD_FILE_DROPDOWN_FAILURE = 'UPLOAD_FILE_DROPDOWN_FAILURE';

export const GET_TERMS_AND_CONDITIONS_SUCCESS =
  'GET_TERMS_AND_CONDITIONS_SUCCESS';
export const GET_TERMS_AND_CONDITIONS_FAILURE =
  'GET_TERMS_AND_CONDITIONS_FAILURE';
export const POST_TERMS_AND_CONDITIONS_SUCCESS =
  'POST_TERMS_AND_CONDITIONS_SUCCESS';
export const POST_TERMS_AND_CONDITIONS_FAILURE =
  'POST_TERMS_AND_CONDITIONS_FAILURE';
