/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import get from 'lodash.get';
import {
  getProgramActivities,
  programActivities
} from '@redux/program-activities/programactivities/actions';
import { connect } from 'react-redux';
import { GetProgramActivitiesSelector } from '@redux/program-activities/programactivities/selectors';
import PropTypes from 'prop-types';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS } from '@config/app-config';
import TextError from '@modules/shared/components/reusable-components/text-error';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const ProgramActivitiesTab = props => {
  const {
    handleGetProgramActivities,
    GetProgramActivitiesSelectorData,
    setLoader,
    handleProgramActivities,
    selectedAsso,
    selectedBranch,
    selectedBranchName,
    setErrorPopup,
    setErrorMessage,
    status,
    role,
    handleGetAnnualReportsDashboard,
    LoginUserData
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Program and Activities';

  const [isSuccess, setIsSuccess] = useState(false);
  const [resStatus, setResStatus] = useState();
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const formikref = useRef();
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handleGetProgramActivitiesData = successpopup => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetProgramActivities(selectedAsso, selectedBranch).then(res => {
        if (res.status === 200) {
          setLoader(false);
          if (successpopup) {
            setIsSuccess(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        }
      });
    }
  };

  useEffect(() => {
    handleGetProgramActivitiesData();
    formikref?.current?.resetForm();
  }, [selectedAsso, selectedBranch]);

  const initialValues = {
    yofferPrograms2021: 'true',
    youthHeadStartProgram: [],
    youth21CommunityLearnProgram: [],
    youthEarlyChildhoodProgram: [],
    youthPreschoolCareProgram: [],
    youthBfrAftrShcoolProgram: [],
    youthCorpChildCareProgram: [],
    youthChildwatchBabysitProgram: [],
    youthDayCampProgram: [],
    youthOverNightResidentCampProgram: [],
    teenLeadersClubProgram: [],
    teenAchieversProgam: [],
    teenCollegeCareerReadyProgram: [],
    teenCivicEngagementProgram: [],
    teenYouthGovtProgram: [],
    teenWorkforceDevProgram: [],
    sportsYouthSportsProgram: [],
    sportsMartialArtsProgram: [],
    sportsAdultProgram: [],
    sportsGymnasticsProgram: [],
    sportsInfantToddlerSwimProgram: [],
    sportsPreschoolSwimProgram: [],
    sportsSchoolAgeSwimProgram: [],
    sportsTeenAdultSwimProgram: [],
    sportsSafetyWaterProgram: [],
    sportsCompetitiveSwimProgram: [],
    sportsLifeGuardTrainingProgram: [],
    sportsOpenWaterActivitiesProgram: [],
    sportsDiverseAbilityInclusionProgram: [],
    sportsPickleball: [],
    healthGroupExerciseProgam: [],
    healthWeightManagementProgam: [],
    healthCorporateWellnessProgam: [],
    healthChronicDeseaseProgram: [],
    healthWaterFitnessClassesProgram: [],
    communityCaseManagementProgam: [],
    communityAbuseManagementProgram: [],
    communityDiverseIncluseGlobalProgram: [],
    communityFamilyStrengthProgram: [],
    communityMentalWellBeingProgram: []
  };

  const editValues = {
    yofferPrograms2021:
      `${GetProgramActivitiesSelectorData?.data?.yofferPrograms2021}` === 'null'
        ? 'true'
        : `${GetProgramActivitiesSelectorData?.data?.yofferPrograms2021}`,
    youthHeadStartProgram:
      GetProgramActivitiesSelectorData?.data?.youthHeadStartProgram,
    youth21CommunityLearnProgram:
      GetProgramActivitiesSelectorData?.data?.youth21CommunityLearnProgram,
    youthEarlyChildhoodProgram:
      GetProgramActivitiesSelectorData?.data?.youthEarlyChildhoodProgram,
    youthPreschoolCareProgram:
      GetProgramActivitiesSelectorData?.data?.youthPreschoolCareProgram,
    youthBfrAftrShcoolProgram:
      GetProgramActivitiesSelectorData?.data?.youthBfrAftrShcoolProgram,
    youthCorpChildCareProgram:
      GetProgramActivitiesSelectorData?.data?.youthCorpChildCareProgram,
    youthChildwatchBabysitProgram:
      GetProgramActivitiesSelectorData?.data?.youthChildwatchBabysitProgram,
    youthDayCampProgram:
      GetProgramActivitiesSelectorData?.data?.youthDayCampProgram,
    youthOverNightResidentCampProgram:
      GetProgramActivitiesSelectorData?.data?.youthOverNightResidentCampProgram,
    teenLeadersClubProgram:
      GetProgramActivitiesSelectorData?.data?.teenLeadersClubProgram,
    teenAchieversProgam:
      GetProgramActivitiesSelectorData?.data?.teenAchieversProgam,
    teenCollegeCareerReadyProgram:
      GetProgramActivitiesSelectorData?.data?.teenCollegeCareerReadyProgram,
    teenCivicEngagementProgram:
      GetProgramActivitiesSelectorData?.data?.teenCivicEngagementProgram,
    teenYouthGovtProgram:
      GetProgramActivitiesSelectorData?.data?.teenYouthGovtProgram,
    teenWorkforceDevProgram:
      GetProgramActivitiesSelectorData?.data?.teenWorkforceDevProgram,
    sportsYouthSportsProgram:
      GetProgramActivitiesSelectorData?.data?.sportsYouthSportsProgram,
    sportsMartialArtsProgram:
      GetProgramActivitiesSelectorData?.data?.sportsMartialArtsProgram,
    sportsAdultProgram:
      GetProgramActivitiesSelectorData?.data?.sportsAdultProgram,
    sportsGymnasticsProgram:
      GetProgramActivitiesSelectorData?.data?.sportsGymnasticsProgram,
    sportsInfantToddlerSwimProgram:
      GetProgramActivitiesSelectorData?.data?.sportsInfantToddlerSwimProgram,
    sportsPreschoolSwimProgram:
      GetProgramActivitiesSelectorData?.data?.sportsPreschoolSwimProgram,
    sportsSchoolAgeSwimProgram:
      GetProgramActivitiesSelectorData?.data?.sportsSchoolAgeSwimProgram,
    sportsTeenAdultSwimProgram:
      GetProgramActivitiesSelectorData?.data?.sportsTeenAdultSwimProgram,
    sportsSafetyWaterProgram:
      GetProgramActivitiesSelectorData?.data?.sportsSafetyWaterProgram,
    sportsCompetitiveSwimProgram:
      GetProgramActivitiesSelectorData?.data?.sportsCompetitiveSwimProgram,
    sportsLifeGuardTrainingProgram:
      GetProgramActivitiesSelectorData?.data?.sportsLifeGuardTrainingProgram,
    sportsOpenWaterActivitiesProgram:
      GetProgramActivitiesSelectorData?.data?.sportsOpenWaterActivitiesProgram,
    sportsDiverseAbilityInclusionProgram:
      GetProgramActivitiesSelectorData?.data
        ?.sportsDiverseAbilityInclusionProgram,
    sportsPickleball: GetProgramActivitiesSelectorData?.data?.sportsPickleball,
    healthGroupExerciseProgam:
      GetProgramActivitiesSelectorData?.data?.healthGroupExerciseProgam,
    healthWeightManagementProgam:
      GetProgramActivitiesSelectorData?.data?.healthWeightManagementProgam,
    healthCorporateWellnessProgam:
      GetProgramActivitiesSelectorData?.data?.healthCorporateWellnessProgam,
    healthChronicDeseaseProgram:
      GetProgramActivitiesSelectorData?.data?.healthChronicDeseaseProgram,
    healthWaterFitnessClassesProgram:
      GetProgramActivitiesSelectorData?.data?.healthWaterFitnessClassesProgram,
    communityCaseManagementProgam:
      GetProgramActivitiesSelectorData?.data?.communityCaseManagementProgam,
    communityAbuseManagementProgram:
      GetProgramActivitiesSelectorData?.data?.communityAbuseManagementProgram,
    communityDiverseIncluseGlobalProgram:
      GetProgramActivitiesSelectorData?.data
        ?.communityDiverseIncluseGlobalProgram,
    communityFamilyStrengthProgram:
      GetProgramActivitiesSelectorData?.data?.communityFamilyStrengthProgram,
    communityMentalWellBeingProgram:
      GetProgramActivitiesSelectorData?.data?.communityMentalWellBeingProgram
  };

  const validationSchema = Yup.object({
    yofferPrograms2021: Yup.string().nullable(),
    youthHeadStartProgram: Yup.array(),
    youth21CommunityLearnProgram: Yup.array(),
    youthEarlyChildhoodProgram: Yup.array(),
    youthPreschoolCareProgram: Yup.array(),
    youthBfrAftrShcoolProgram: Yup.array(),
    youthCorpChildCareProgram: Yup.array(),
    youthChildwatchBabysitProgram: Yup.array(),
    youthDayCampProgram: Yup.array(),
    youthOverNightResidentCampProgram: Yup.array(),
    teenLeadersClubProgram: Yup.array(),
    teenAchieversProgam: Yup.array(),
    teenCollegeCareerReadyProgram: Yup.array(),
    teenCivicEngagementProgram: Yup.array(),
    teenYouthGovtProgram: Yup.array(),
    teenWorkforceDevProgram: Yup.array(),
    sportsYouthSportsProgram: Yup.array(),
    sportsMartialArtsProgram: Yup.array(),
    sportsAdultProgram: Yup.array(),
    sportsGymnasticsProgram: Yup.array(),
    sportsInfantToddlerSwimProgram: Yup.array(),
    sportsPreschoolSwimProgram: Yup.array(),
    sportsSchoolAgeSwimProgram: Yup.array(),
    sportsTeenAdultSwimProgram: Yup.array(),
    sportsSafetyWaterProgram: Yup.array(),
    sportsCompetitiveSwimProgram: Yup.array(),
    sportsLifeGuardTrainingProgram: Yup.array(),
    sportsOpenWaterActivitiesProgram: Yup.array(),
    sportsDiverseAbilityInclusionProgram: Yup.array(),
    sportsPickleball: Yup.array(),
    healthGroupExerciseProgam: Yup.array(),
    healthWeightManagementProgam: Yup.array(),
    healthCorporateWellnessProgam: Yup.array(),
    healthChronicDeseaseProgram: Yup.array(),
    healthWaterFitnessClassesProgram: Yup.array(),
    communityCaseManagementProgam: Yup.array(),
    communityAbuseManagementProgram: Yup.array(),
    communityDiverseIncluseGlobalProgram: Yup.array(),
    communityFamilyStrengthProgram: Yup.array(),
    communityMentalWellBeingProgram: Yup.array()
  });

  const onSubmit = values => {
    setLoader(true);
    const payloadBody = {
      programActivitiesId:
        GetProgramActivitiesSelectorData?.data?.programActivitiesId,
      associationNumber: selectedAsso,
      year: sessionStorage.getItem('selectedBatchYear'),
      branchNumber: selectedBranch,
      branchName: selectedBranchName?.toString(),
      yofferPrograms2021: values.yofferPrograms2021.toLowerCase() === 'true',
      youthHeadStartProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youthHeadStartProgram
          : [],
      youth21CommunityLearnProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youth21CommunityLearnProgram
          : [],
      youthEarlyChildhoodProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youthEarlyChildhoodProgram
          : [],
      youthPreschoolCareProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youthPreschoolCareProgram
          : [],
      youthBfrAftrShcoolProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youthBfrAftrShcoolProgram
          : [],
      youthCorpChildCareProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youthCorpChildCareProgram
          : [],
      youthChildwatchBabysitProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youthChildwatchBabysitProgram
          : [],
      youthDayCampProgram:
        values.yofferPrograms2021 === 'true' ? values.youthDayCampProgram : [],
      youthOverNightResidentCampProgram:
        values.yofferPrograms2021 === 'true'
          ? values.youthOverNightResidentCampProgram
          : [],
      teenLeadersClubProgram:
        values.yofferPrograms2021 === 'true'
          ? values.teenLeadersClubProgram
          : [],
      teenAchieversProgam:
        values.yofferPrograms2021 === 'true' ? values.teenAchieversProgam : [],
      teenCollegeCareerReadyProgram:
        values.yofferPrograms2021 === 'true'
          ? values.teenCollegeCareerReadyProgram
          : [],
      teenCivicEngagementProgram:
        values.yofferPrograms2021 === 'true'
          ? values.teenCivicEngagementProgram
          : [],
      teenYouthGovtProgram:
        values.yofferPrograms2021 === 'true' ? values.teenYouthGovtProgram : [],
      teenWorkforceDevProgram:
        values.yofferPrograms2021 === 'true'
          ? values.teenWorkforceDevProgram
          : [],
      sportsYouthSportsProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsYouthSportsProgram
          : [],
      sportsMartialArtsProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsMartialArtsProgram
          : [],
      sportsAdultProgram:
        values.yofferPrograms2021 === 'true' ? values.sportsAdultProgram : [],
      sportsGymnasticsProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsGymnasticsProgram
          : [],
      sportsInfantToddlerSwimProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsInfantToddlerSwimProgram
          : [],
      sportsPreschoolSwimProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsPreschoolSwimProgram
          : [],
      sportsSchoolAgeSwimProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsSchoolAgeSwimProgram
          : [],
      sportsTeenAdultSwimProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsTeenAdultSwimProgram
          : [],
      sportsSafetyWaterProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsSafetyWaterProgram
          : [],
      sportsCompetitiveSwimProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsCompetitiveSwimProgram
          : [],
      sportsLifeGuardTrainingProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsLifeGuardTrainingProgram
          : [],
      sportsOpenWaterActivitiesProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsOpenWaterActivitiesProgram
          : [],
      sportsDiverseAbilityInclusionProgram:
        values.yofferPrograms2021 === 'true'
          ? values.sportsDiverseAbilityInclusionProgram
          : [],
      sportsPickleball:
        values.yofferPrograms2021 === 'true' ? values.sportsPickleball : [],
      healthGroupExerciseProgam:
        values.yofferPrograms2021 === 'true'
          ? values.healthGroupExerciseProgam
          : [],
      healthWeightManagementProgam:
        values.yofferPrograms2021 === 'true'
          ? values.healthWeightManagementProgam
          : [],
      healthCorporateWellnessProgam:
        values.yofferPrograms2021 === 'true'
          ? values.healthCorporateWellnessProgam
          : [],
      healthChronicDeseaseProgram:
        values.yofferPrograms2021 === 'true'
          ? values.healthChronicDeseaseProgram
          : [],
      healthWaterFitnessClassesProgram:
        values.yofferPrograms2021 === 'true'
          ? values.healthWaterFitnessClassesProgram
          : [],
      communityCaseManagementProgam:
        values.yofferPrograms2021 === 'true'
          ? values.communityCaseManagementProgam
          : [],
      communityAbuseManagementProgram:
        values.yofferPrograms2021 === 'true'
          ? values.communityAbuseManagementProgram
          : [],
      communityDiverseIncluseGlobalProgram:
        values.yofferPrograms2021 === 'true'
          ? values.communityDiverseIncluseGlobalProgram
          : [],
      communityFamilyStrengthProgram:
        values.yofferPrograms2021 === 'true'
          ? values.communityFamilyStrengthProgram
          : [],
      communityMentalWellBeingProgram:
        values.yofferPrograms2021 === 'true'
          ? values.communityMentalWellBeingProgram
          : [],
      status: GetProgramActivitiesSelectorData?.data?.status,
      reportBatchId: GetProgramActivitiesSelectorData?.data?.reportBatchId,
      createdByPersonId:
        GetProgramActivitiesSelectorData?.data?.createdByPersonId,
      createdByName: GetProgramActivitiesSelectorData?.data?.createdByName,
      createdDatetime: GetProgramActivitiesSelectorData?.data?.createdDatetime,
      updatedByPersonId:
        GetProgramActivitiesSelectorData?.data?.updatedByPersonId,
      updatedByName: GetProgramActivitiesSelectorData?.data?.updatedByName,
      updatedDatetime: GetProgramActivitiesSelectorData?.data?.updatedDatetime
    };

    setIsSuccess(false);
    handleProgramActivities(payloadBody).then(res => {
      handleGetProgramActivitiesData(true);
      if (res?.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };
  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };
  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, dirty, errors }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-program pt-1">
                <div className="mt-1">
                  {GetProgramActivitiesSelectorData?.data?.recordStatus?.toUpperCase() ===
                    APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                </div>

                <div className="ymca-covidRelif pt-0 ps-0">
                  <h4 className="ymca-headingTitle  txt-mandatory">
                    {`Did your Y offer any programs or services during any part of
                   ${selectedYear}?`}
                  </h4>
                  <div className="ymca-radioBTN d-flex pb-0">
                    <div className="me-5">
                      <Field
                        type="radio"
                        name="yofferPrograms2021"
                        className="me-2"
                        value="true"
                      />
                      <span>Yes</span>
                    </div>
                    <div className="">
                      <Field
                        type="radio"
                        name="yofferPrograms2021"
                        className="me-2"
                        value="false"
                      />
                      <span>No</span>
                    </div>
                  </div>
                </div>
                <ErrorMessage component={TextError} name="yofferPrograms2021" />
                {values.yofferPrograms2021 === 'true' && (
                  <div>
                    <h4 className="ymca-headingTitle">
                      {`Which of the following programs and services were offered by
                    your Y in-person, virtually, or both at any point during
                     ${selectedYear}?`}
                    </h4>
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th className="ps-0 w-500">Youth Development</th>
                            <th className="ps-0">Did not offer</th>
                            <th className="ps-0">In Person</th>
                            <th className="ps-0">Virtual</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Head Start and/or Early Head Start
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="youthHeadStartProgram"
                                  id="youthHeadStartDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthHeadStartDidNotOffer"
                                  disabled={
                                    values?.youthHeadStartProgram?.includes(
                                      'youthHeadStartInPerson'
                                    ) ||
                                    values?.youthHeadStartProgram?.includes(
                                      'youthHeadStartVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthHeadStartDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthHeadStartProgram"
                                  id="youthHeadStartInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthHeadStartInPerson"
                                  disabled={values?.youthHeadStartProgram?.includes(
                                    'youthHeadStartDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthHeadStartInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthHeadStartProgram"
                                  id="youthHeadStartVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthHeadStartVirtual"
                                  disabled={values?.youthHeadStartProgram?.includes(
                                    'youthHeadStartDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthHeadStartVirtual" />
                              </div>
                            </td>

                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              21st Century Community Learning Center
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youth21CommunityLearnProgram"
                                  id="youth21CommunityLearnDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youth21CommunityLearnDidNotOffer"
                                  disabled={
                                    values?.youth21CommunityLearnProgram?.includes(
                                      'youth21CommunityLearnInPerson'
                                    ) ||
                                    values?.youth21CommunityLearnProgram?.includes(
                                      'youth21CommunityLearnVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youth21CommunityLearnDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youth21CommunityLearnProgram"
                                  id="youth21CommunityLearnInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youth21CommunityLearnInPerson"
                                  disabled={values?.youth21CommunityLearnProgram?.includes(
                                    'youth21CommunityLearnDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youth21CommunityLearnInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youth21CommunityLearnProgram"
                                  id="youth21CommunityLearnVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youth21CommunityLearnVirtual"
                                  disabled={values?.youth21CommunityLearnProgram?.includes(
                                    'youth21CommunityLearnDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youth21CommunityLearnVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Early Childhood
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthEarlyChildhoodProgram"
                                  id="youthEarlyChildhoodDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthEarlyChildhoodDidNotOffer"
                                  disabled={
                                    values?.youthEarlyChildhoodProgram?.includes(
                                      'youthEarlyChildhoodInPerson'
                                    ) ||
                                    values?.youthEarlyChildhoodProgram?.includes(
                                      'youthEarlyChildhoodVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthEarlyChildhoodDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthEarlyChildhoodProgram"
                                  id="youthEarlyChildhoodInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthEarlyChildhoodInPerson"
                                  disabled={values?.youthEarlyChildhoodProgram?.includes(
                                    'youthEarlyChildhoodDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthEarlyChildhoodInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthEarlyChildhoodProgram"
                                  id="youthEarlyChildhoodVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthEarlyChildhoodVirtual"
                                  disabled={values?.youthEarlyChildhoodProgram?.includes(
                                    'youthEarlyChildhoodDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthEarlyChildhoodVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Preschool Care
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthPreschoolCareProgram"
                                  id="youthPreschoolCareDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthPreschoolCareDidNotOffer"
                                  disabled={
                                    values?.youthPreschoolCareProgram?.includes(
                                      'youthPreschoolCareInPerson'
                                    ) ||
                                    values?.youthPreschoolCareProgram?.includes(
                                      'youthPreschoolCareVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthPreschoolCareDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthPreschoolCareProgram"
                                  id="youthPreschoolCareInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthPreschoolCareInPerson"
                                  disabled={values?.youthPreschoolCareProgram?.includes(
                                    'youthPreschoolCareDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthPreschoolCareInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthPreschoolCareProgram"
                                  id="youthPreschoolCareVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthPreschoolCareVirtual"
                                  disabled={values?.youthPreschoolCareProgram?.includes(
                                    'youthPreschoolCareDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthPreschoolCareVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory ">
                              Before and/or Afterschool Program
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthBfrAftrShcoolProgram"
                                  id="youthBfrAftrSchoolDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthBfrAftrSchoolDidNotOffer"
                                  disabled={
                                    values?.youthBfrAftrShcoolProgram?.includes(
                                      'youthBfrAftrSchoolInPerson'
                                    ) ||
                                    values?.youthBfrAftrShcoolProgram?.includes(
                                      'youthBfrAftrSchoolVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthBfrAftrSchoolDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthBfrAftrShcoolProgram"
                                  id="youthBfrAftrSchoolInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthBfrAftrSchoolInPerson"
                                  disabled={values?.youthBfrAftrShcoolProgram?.includes(
                                    'youthBfrAftrSchoolDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthBfrAftrSchoolInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthBfrAftrShcoolProgram"
                                  id="youthBfrAftrSchoolVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthBfrAftrSchoolVirtual"
                                  disabled={values?.youthBfrAftrShcoolProgram?.includes(
                                    'youthBfrAftrSchoolDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthBfrAftrSchoolVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Corporate Childcare
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthCorpChildCareProgram"
                                  id="youthCorpChildCareDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthCorpChildCareDidNotOffer"
                                  disabled={
                                    values?.youthCorpChildCareProgram?.includes(
                                      'youthCorpChildCareInPerson'
                                    ) ||
                                    values?.youthCorpChildCareProgram?.includes(
                                      'youthCorpChildCareVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthCorpChildCareDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthCorpChildCareProgram"
                                  id="youthCorpChildCareInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthCorpChildCareInPerson"
                                  disabled={values?.youthCorpChildCareProgram?.includes(
                                    'youthCorpChildCareDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthCorpChildCareInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthCorpChildCareProgram"
                                  id="youthCorpChildCareVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthCorpChildCareVirtual"
                                  disabled={values?.youthCorpChildCareProgram?.includes(
                                    'youthCorpChildCareDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthCorpChildCareVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Child Watch/Babysitting
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthChildwatchBabysitProgram"
                                  id="youthChildBabySittingDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthChildBabySittingDidNotOffer"
                                  disabled={
                                    values?.youthChildwatchBabysitProgram?.includes(
                                      'youthChildBabySittingInPerson'
                                    ) ||
                                    values?.youthChildwatchBabysitProgram?.includes(
                                      'youthChildBabySittingVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthChildBabySittingDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthChildwatchBabysitProgram"
                                  id="youthChildBabySittingInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthChildBabySittingInPerson"
                                  disabled={values?.youthChildwatchBabysitProgram?.includes(
                                    'youthChildBabySittingDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthChildBabySittingInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthChildwatchBabysitProgram"
                                  id="youthChildBabySittingVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthChildBabySittingVirtual"
                                  disabled={values?.youthChildwatchBabysitProgram?.includes(
                                    'youthChildBabySittingDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthChildBabySittingVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Day Camp
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthDayCampProgram"
                                  id="youthDayCampDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthDayCampDidNotOffer"
                                  disabled={
                                    values?.youthDayCampProgram?.includes(
                                      'youthDayCampInPerson'
                                    ) ||
                                    values?.youthDayCampProgram?.includes(
                                      'youthDayCampVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthDayCampDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthDayCampProgram"
                                  id="youthDayCampInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthDayCampInPerson"
                                  disabled={values?.youthDayCampProgram?.includes(
                                    'youthDayCampDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthDayCampInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthDayCampProgram"
                                  id="youthDayCampVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthDayCampVirtual"
                                  disabled={values?.youthDayCampProgram?.includes(
                                    'youthDayCampDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthDayCampVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory ">
                              Overnight Resident Camp
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthOverNightResidentCampProgram"
                                  id="youthResidentCampDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthResidentCampDidNotOffer"
                                  disabled={
                                    values?.youthOverNightResidentCampProgram?.includes(
                                      'youthResidentCampInPerson'
                                    ) ||
                                    values?.youthOverNightResidentCampProgram?.includes(
                                      'youthResidentCampVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="youthResidentCampDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthOverNightResidentCampProgram"
                                  id="youthResidentCampInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthResidentCampInPerson"
                                  disabled={values?.youthOverNightResidentCampProgram?.includes(
                                    'youthResidentCampDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthResidentCampInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="youthOverNightResidentCampProgram"
                                  id="youthResidentCampVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="youthResidentCampVirtual"
                                  disabled={values?.youthOverNightResidentCampProgram?.includes(
                                    'youthResidentCampDidNotOffer'
                                  )}
                                />
                                <label htmlFor="youthResidentCampVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th className="ps-0 w-500 ">
                              Teen/Changemaker Programs
                            </th>
                            <th className="ps-0">Did not offer</th>
                            <th className="ps-0">In Person</th>
                            <th className="ps-0">Virtual</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Leaders Club
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenLeadersClubProgram"
                                  id="teenLeadersClubDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenLeadersClubDidNotOffer"
                                  disabled={
                                    values?.teenLeadersClubProgram?.includes(
                                      'teenLeadersClubInPerson'
                                    ) ||
                                    values?.teenLeadersClubProgram?.includes(
                                      'teenLeadersClubVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="teenLeadersClubDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenLeadersClubProgram"
                                  id="teenLeadersClubInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenLeadersClubInPerson"
                                  disabled={values?.teenLeadersClubProgram?.includes(
                                    'teenLeadersClubDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenLeadersClubInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenLeadersClubProgram"
                                  id="teenLeadersClubVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenLeadersClubVirtual"
                                  disabled={values?.teenLeadersClubProgram?.includes(
                                    'teenLeadersClubDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenLeadersClubVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Achievers
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenAchieversProgam"
                                  id="teenAchieversDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenAchieversDidNotOffer"
                                  disabled={
                                    values?.teenAchieversProgam?.includes(
                                      'teenAchieversInPerson'
                                    ) ||
                                    values?.teenAchieversProgam?.includes(
                                      'teenAchieversVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="teenAchieversDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenAchieversProgam"
                                  id="teenAchieversInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenAchieversInPerson"
                                  disabled={values?.teenAchieversProgam?.includes(
                                    'teenAchieversDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenAchieversInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenAchieversProgam"
                                  id="teenAchieversVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenAchieversVirtual"
                                  disabled={values?.teenAchieversProgam?.includes(
                                    'teenAchieversDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenAchieversVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              College and Career Readiness
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenCollegeCareerReadyProgram"
                                  id="teenCollegeCareerDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenCollegeCareerDidNotOffer"
                                  disabled={
                                    values?.teenCollegeCareerReadyProgram?.includes(
                                      'teenCollegeCareerInPerson'
                                    ) ||
                                    values?.teenCollegeCareerReadyProgram?.includes(
                                      'teenCollegeCareerVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="teenCollegeCareerDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenCollegeCareerReadyProgram"
                                  id="teenCollegeCareerInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenCollegeCareerInPerson"
                                  disabled={values?.teenCollegeCareerReadyProgram?.includes(
                                    'teenCollegeCareerDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenCollegeCareerInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenCollegeCareerReadyProgram"
                                  id="teenCollegeCareerVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenCollegeCareerVirtual"
                                  disabled={values?.teenCollegeCareerReadyProgram?.includes(
                                    'teenCollegeCareerDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenCollegeCareerVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Civics Engagement Programs
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenCivicEngagementProgram"
                                  id="teenCivicEngagementDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenCivicEngagementDidNotOffer"
                                  disabled={
                                    values?.teenCivicEngagementProgram?.includes(
                                      'teenCivicEngagementInPerson'
                                    ) ||
                                    values?.teenCivicEngagementProgram?.includes(
                                      'teenCivicEngagementVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="teenCivicEngagementDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenCivicEngagementProgram"
                                  id="teenCivicEngagementInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenCivicEngagementInPerson"
                                  disabled={values?.teenCivicEngagementProgram?.includes(
                                    'teenCivicEngagementDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenCivicEngagementInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenCivicEngagementProgram"
                                  id="teenCivicEngagementVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenCivicEngagementVirtual"
                                  disabled={values?.teenCivicEngagementProgram?.includes(
                                    'teenCivicEngagementDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenCivicEngagementVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Youth and Government
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenYouthGovtProgram"
                                  id="teenYouthGovDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenYouthGovDidNotOffer"
                                  disabled={
                                    values?.teenYouthGovtProgram?.includes(
                                      'teenYouthGovInPerson'
                                    ) ||
                                    values?.teenYouthGovtProgram?.includes(
                                      'teenYouthGovVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="teenYouthGovDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenYouthGovtProgram"
                                  id="teenYouthGovInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenYouthGovInPerson"
                                  disabled={values?.teenYouthGovtProgram?.includes(
                                    'teenYouthGovDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenYouthGovInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenYouthGovtProgram"
                                  id="teenYouthGovVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenYouthGovVirtual"
                                  disabled={values?.teenYouthGovtProgram?.includes(
                                    'teenYouthGovDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenYouthGovVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Workforce Development
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenWorkforceDevProgram"
                                  id="teenWorkForceDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenWorkForceDidNotOffer"
                                  disabled={
                                    values?.teenWorkforceDevProgram?.includes(
                                      'teenWorkForceInPerson'
                                    ) ||
                                    values?.teenWorkforceDevProgram?.includes(
                                      'teenWorkForceVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="teenWorkForceDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenWorkforceDevProgram"
                                  id="teenWorkForceInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenWorkForceInPerson"
                                  disabled={values?.teenWorkforceDevProgram?.includes(
                                    'teenWorkForceDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenWorkForceInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="teenWorkforceDevProgram"
                                  id="teenWorkForceVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="teenWorkForceVirtual"
                                  disabled={values?.teenWorkforceDevProgram?.includes(
                                    'teenWorkForceDidNotOffer'
                                  )}
                                />
                                <label htmlFor="teenWorkForceVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th className="w-500">Sports and Fitness </th>
                            <th>Did not offer</th>
                            <th>In Person</th>
                            <th>Virtual</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Youth Sports
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsYouthSportsProgram"
                                  id="sportsYouthDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsYouthDidNotOffer"
                                  disabled={
                                    values?.sportsYouthSportsProgram?.includes(
                                      'sportsYouthInPerson'
                                    ) ||
                                    values?.sportsYouthSportsProgram?.includes(
                                      'sportsYouthVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsYouthDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsYouthSportsProgram"
                                  id="sportsYouthInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsYouthInPerson"
                                  disabled={values?.sportsYouthSportsProgram?.includes(
                                    'sportsYouthDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsYouthInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsYouthSportsProgram"
                                  id="sportsYouthVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsYouthVirtual"
                                  disabled={values?.sportsYouthSportsProgram?.includes(
                                    'sportsYouthDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsYouthVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Martial Arts
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsMartialArtsProgram"
                                  id="sportsMartialArtsDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsMartialArtsDidNotOffer"
                                  disabled={
                                    values?.sportsMartialArtsProgram?.includes(
                                      'sportsMartialArtsInPerson'
                                    ) ||
                                    values?.sportsMartialArtsProgram?.includes(
                                      'sportsMartialArtsVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsMartialArtsDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsMartialArtsProgram"
                                  id="sportsMartialArtsInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsMartialArtsInPerson"
                                  disabled={values?.sportsMartialArtsProgram?.includes(
                                    'sportsMartialArtsDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsMartialArtsInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsMartialArtsProgram"
                                  id="sportsMartialArtsVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsMartialArtsVirtual"
                                  disabled={values?.sportsMartialArtsProgram?.includes(
                                    'sportsMartialArtsDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsMartialArtsVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Adult Sports
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsAdultProgram"
                                  id="sportsAdultDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsAdultDidNotOffer"
                                  disabled={
                                    values?.sportsAdultProgram?.includes(
                                      'sportsAdultInPerson'
                                    ) ||
                                    values?.sportsAdultProgram?.includes(
                                      'sportsAdultVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsAdultDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsAdultProgram"
                                  id="sportsAdultInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsAdultInPerson"
                                  disabled={values?.sportsAdultProgram?.includes(
                                    'sportsAdultDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsAdultInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsAdultProgram"
                                  id="sportsAdultVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsAdultVirtual"
                                  disabled={values?.sportsAdultProgram?.includes(
                                    'sportsAdultDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsAdultVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Gymnastics
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsGymnasticsProgram"
                                  id="sportsGymnasticsDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsGymnasticsDidNotOffer"
                                  disabled={
                                    values?.sportsGymnasticsProgram?.includes(
                                      'sportsGymnasticsInPerson'
                                    ) ||
                                    values?.sportsGymnasticsProgram?.includes(
                                      'sportsGymnasticsVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsGymnasticsDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsGymnasticsProgram"
                                  id="sportsGymnasticsInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsGymnasticsInPerson"
                                  disabled={values?.sportsGymnasticsProgram?.includes(
                                    'sportsGymnasticsDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsGymnasticsInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsGymnasticsProgram"
                                  id="sportsGymnasticsVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsGymnasticsVirtual"
                                  disabled={values?.sportsGymnasticsProgram?.includes(
                                    'sportsGymnasticsDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsGymnasticsVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Infant/Toddler Swim Lessons
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsInfantToddlerSwimProgram"
                                  id="sportsInfantToddlerSwimDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsInfantToddlerSwimDidNotOffer"
                                  disabled={
                                    values?.sportsInfantToddlerSwimProgram?.includes(
                                      'sportsInfantToddlerSwimInPerson'
                                    ) ||
                                    values?.sportsInfantToddlerSwimProgram?.includes(
                                      'sportsInfantToddlerSwimVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsInfantToddlerSwimDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsInfantToddlerSwimProgram"
                                  id="sportsInfantToddlerSwimInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsInfantToddlerSwimInPerson"
                                  disabled={values?.sportsInfantToddlerSwimProgram?.includes(
                                    'sportsInfantToddlerSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsInfantToddlerSwimInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsInfantToddlerSwimProgram"
                                  id="sportsInfantToddlerSwimVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsInfantToddlerSwimVirtual"
                                  disabled={values?.sportsInfantToddlerSwimProgram?.includes(
                                    'sportsInfantToddlerSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsInfantToddlerSwimVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Preschool Swim Lessons
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsPreschoolSwimProgram"
                                  id="sportsPreSchoolSwimDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsPreSchoolSwimDidNotOffer"
                                  disabled={
                                    values?.sportsPreschoolSwimProgram?.includes(
                                      'sportsPreSchoolSwimInPerson'
                                    ) ||
                                    values?.sportsPreschoolSwimProgram?.includes(
                                      'sportsPreSchoolSwimVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsPreSchoolSwimDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsPreschoolSwimProgram"
                                  id="sportsPreSchoolSwimInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsPreSchoolSwimInPerson"
                                  disabled={values?.sportsPreschoolSwimProgram?.includes(
                                    'sportsPreSchoolSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsPreSchoolSwimInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsPreschoolSwimProgram"
                                  id="sportsPreSchoolSwimVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsPreSchoolSwimVirtual"
                                  disabled={values?.sportsPreschoolSwimProgram?.includes(
                                    'sportsPreSchoolSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsPreSchoolSwimVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory ">
                              School Age Swim Lessons
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsSchoolAgeSwimProgram"
                                  id="sportsSchoolAgeSwimDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsSchoolAgeSwimDidNotOffer"
                                  disabled={
                                    values?.sportsSchoolAgeSwimProgram?.includes(
                                      'sportsSchoolAgeSwimInPerson'
                                    ) ||
                                    values?.sportsSchoolAgeSwimProgram?.includes(
                                      'sportsSchoolAgeSwimVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsSchoolAgeSwimDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap ">
                              <div>
                                <Field
                                  name="sportsSchoolAgeSwimProgram"
                                  id="sportsSchoolAgeSwimInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsSchoolAgeSwimInPerson"
                                  disabled={values?.sportsSchoolAgeSwimProgram?.includes(
                                    'sportsSchoolAgeSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsSchoolAgeSwimInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsSchoolAgeSwimProgram"
                                  id="sportsSchoolAgeSwimVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsSchoolAgeSwimVirtual"
                                  disabled={values?.sportsSchoolAgeSwimProgram?.includes(
                                    'sportsSchoolAgeSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsSchoolAgeSwimVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Teen/Adult Swim Lessons
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsTeenAdultSwimProgram"
                                  id="sportsTeenAdultSwimDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsTeenAdultSwimDidNotOffer"
                                  disabled={
                                    values?.sportsTeenAdultSwimProgram?.includes(
                                      'sportsTeenAdultSwimInPerson'
                                    ) ||
                                    values?.sportsTeenAdultSwimProgram?.includes(
                                      'sportsTeenAdultSwimVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsTeenAdultSwimDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsTeenAdultSwimProgram"
                                  id="sportsTeenAdultSwimInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsTeenAdultSwimInPerson"
                                  disabled={values?.sportsTeenAdultSwimProgram?.includes(
                                    'sportsTeenAdultSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsTeenAdultSwimInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsTeenAdultSwimProgram"
                                  id="sportsTeenAdultSwimVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsTeenAdultSwimVirtual"
                                  disabled={values?.sportsTeenAdultSwimProgram?.includes(
                                    'sportsTeenAdultSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsTeenAdultSwimVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Safety Around Water
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsSafetyWaterProgram"
                                  id="sportsSafetyWaterDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsSafetyWaterDidNotOffer"
                                  disabled={
                                    values?.sportsSafetyWaterProgram?.includes(
                                      'sportsSafetyWaterInPerson'
                                    ) ||
                                    values?.sportsSafetyWaterProgram?.includes(
                                      'sportsSafetyWaterVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsSafetyWaterDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsSafetyWaterProgram"
                                  id="sportsSafetyWaterInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsSafetyWaterInPerson"
                                  disabled={values?.sportsSafetyWaterProgram?.includes(
                                    'sportsSafetyWaterDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsSafetyWaterInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsSafetyWaterProgram"
                                  id="sportsSafetyWaterVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsSafetyWaterVirtual"
                                  disabled={values?.sportsSafetyWaterProgram?.includes(
                                    'sportsSafetyWaterDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsSafetyWaterVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Competitive Swimming
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsCompetitiveSwimProgram"
                                  id="sportsCompSwimDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsCompSwimDidNotOffer"
                                  disabled={
                                    values?.sportsCompetitiveSwimProgram?.includes(
                                      'sportsCompSwimInPerson'
                                    ) ||
                                    values?.sportsCompetitiveSwimProgram?.includes(
                                      'sportsCompSwimVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsCompSwimDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsCompetitiveSwimProgram"
                                  id="sportsCompSwimInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsCompSwimInPerson"
                                  disabled={values?.sportsCompetitiveSwimProgram?.includes(
                                    'sportsCompSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsCompSwimInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsCompetitiveSwimProgram"
                                  id="sportsCompSwimVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsCompSwimVirtual"
                                  disabled={values?.sportsCompetitiveSwimProgram?.includes(
                                    'sportsCompSwimDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsCompSwimVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Lifeguard Training
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsLifeGuardTrainingProgram"
                                  id="sportsLifeGuardDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsLifeGuardDidNotOffer"
                                  disabled={
                                    values?.sportsLifeGuardTrainingProgram?.includes(
                                      'sportsLifeGuardInPerson'
                                    ) ||
                                    values?.sportsLifeGuardTrainingProgram?.includes(
                                      'sportsLifeGuardVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsLifeGuardDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsLifeGuardTrainingProgram"
                                  id="sportsLifeGuardInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsLifeGuardInPerson"
                                  disabled={values?.sportsLifeGuardTrainingProgram?.includes(
                                    'sportsLifeGuardDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsLifeGuardInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsLifeGuardTrainingProgram"
                                  id="sportsLifeGuardVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsLifeGuardVirtual"
                                  disabled={values?.sportsLifeGuardTrainingProgram?.includes(
                                    'sportsLifeGuardDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsLifeGuardVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Open Water Activities
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsOpenWaterActivitiesProgram"
                                  id="sportsOpenWaterDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsOpenWaterDidNotOffer"
                                  disabled={
                                    values?.sportsOpenWaterActivitiesProgram?.includes(
                                      'sportsOpenWaterInPerson'
                                    ) ||
                                    values?.sportsOpenWaterActivitiesProgram?.includes(
                                      'sportsOpenWaterVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsOpenWaterDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsOpenWaterActivitiesProgram"
                                  id="sportsOpenWaterInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsOpenWaterInPerson"
                                  disabled={values?.sportsOpenWaterActivitiesProgram?.includes(
                                    'sportsOpenWaterDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsOpenWaterInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsOpenWaterActivitiesProgram"
                                  id="sportsOpenWaterVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsOpenWaterVirtual"
                                  disabled={values?.sportsOpenWaterActivitiesProgram?.includes(
                                    'sportsOpenWaterDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsOpenWaterVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Diverse Abilities/Inclusion
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsDiverseAbilityInclusionProgram"
                                  id="sportsDiverseAbilitiesDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsDiverseAbilitiesDidNotOffer"
                                  disabled={
                                    values?.sportsDiverseAbilityInclusionProgram?.includes(
                                      'sportsDiverseAbilitiesInPerson'
                                    ) ||
                                    values?.sportsDiverseAbilityInclusionProgram?.includes(
                                      'sportsDiverseAbilitiesVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsDiverseAbilitiesDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsDiverseAbilityInclusionProgram"
                                  id="sportsDiverseAbilitiesInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsDiverseAbilitiesInPerson"
                                  disabled={values?.sportsDiverseAbilityInclusionProgram?.includes(
                                    'sportsDiverseAbilitiesDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsDiverseAbilitiesInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsDiverseAbilityInclusionProgram"
                                  id="sportsDiverseAbilitiesVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsDiverseAbilitiesVirtual"
                                  disabled={values?.sportsDiverseAbilityInclusionProgram?.includes(
                                    'sportsDiverseAbilitiesDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsDiverseAbilitiesVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Pickleball
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsPickleball"
                                  id="sportsPickleballDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsPickleballDidNotOffer"
                                  disabled={
                                    values?.sportsPickleball?.includes(
                                      'sportsPickleballInPerson'
                                    ) ||
                                    values?.sportsPickleball?.includes(
                                      'sportsPickleballVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="sportsPickleballDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsPickleball"
                                  id="sportsPickleballInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsPickleballInPerson"
                                  disabled={values?.sportsPickleball?.includes(
                                    'sportsPickleballDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsPickleballInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="sportsPickleball"
                                  id="sportsPickleballVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="sportsPickleballVirtual"
                                  disabled={values?.sportsPickleball?.includes(
                                    'sportsPickleballDidNotOffer'
                                  )}
                                />
                                <label htmlFor="sportsPickleballVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th className="w-500">Health and Wellbeing </th>
                            <th>Did not offer</th>
                            <th>In Person</th>
                            <th>Virtual</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Group Exercise
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthGroupExerciseProgam"
                                  id="healthGroupExerciseDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthGroupExerciseDidNotOffer"
                                  disabled={
                                    values?.healthGroupExerciseProgam?.includes(
                                      'healthGroupExerciseInPerson'
                                    ) ||
                                    values?.healthGroupExerciseProgam?.includes(
                                      'healthGroupExerciseVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="healthGroupExerciseDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthGroupExerciseProgam"
                                  id="healthGroupExerciseInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthGroupExerciseInPerson"
                                  disabled={values?.healthGroupExerciseProgam?.includes(
                                    'healthGroupExerciseDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthGroupExerciseInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthGroupExerciseProgam"
                                  id="healthGroupExerciseVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthGroupExerciseVirtual"
                                  disabled={values?.healthGroupExerciseProgam?.includes(
                                    'healthGroupExerciseDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthGroupExerciseVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Weight Management
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthWeightManagementProgam"
                                  id="healthWgtMgmtDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthWgtMgmtDidNotOffer"
                                  disabled={
                                    values?.healthWeightManagementProgam?.includes(
                                      'healthWgtMgmtInPerson'
                                    ) ||
                                    values?.healthWeightManagementProgam?.includes(
                                      'healthWgtMgmtVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="healthWgtMgmtDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthWeightManagementProgam"
                                  id="healthWgtMgmtInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthWgtMgmtInPerson"
                                  disabled={values?.healthWeightManagementProgam?.includes(
                                    'healthWgtMgmtDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthWgtMgmtInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthWeightManagementProgam"
                                  id="healthWgtMgmtVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthWgtMgmtVirtual"
                                  disabled={values?.healthWeightManagementProgam?.includes(
                                    'healthWgtMgmtDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthWgtMgmtVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Corporate Wellness
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthCorporateWellnessProgam"
                                  id="healthCorpoWellnessDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthCorpoWellnessDidNotOffer"
                                  disabled={
                                    values?.healthCorporateWellnessProgam?.includes(
                                      'healthCorpoWellnessInPerson'
                                    ) ||
                                    values?.healthCorporateWellnessProgam?.includes(
                                      'healthCorpoWellnessVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="healthCorpoWellnessDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthCorporateWellnessProgam"
                                  id="healthCorpoWellnessInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthCorpoWellnessInPerson"
                                  disabled={values?.healthCorporateWellnessProgam?.includes(
                                    'healthCorpoWellnessDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthCorpoWellnessInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthCorporateWellnessProgam"
                                  id="healthCorpoWellnessVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthCorpoWellnessVirtual"
                                  disabled={values?.healthCorporateWellnessProgam?.includes(
                                    'healthCorpoWellnessDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthCorpoWellnessVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Chronic Disease Programs
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthChronicDeseaseProgram"
                                  id="healthChronicDeseaseDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthChronicDeseaseDidNotOffer"
                                  disabled={
                                    values?.healthChronicDeseaseProgram?.includes(
                                      'healthChronicDeseaseInPerson'
                                    ) ||
                                    values?.healthChronicDeseaseProgram?.includes(
                                      'healthChronicDeseaseVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="healthChronicDeseaseDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthChronicDeseaseProgram"
                                  id="healthChronicDeseaseInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthChronicDeseaseInPerson"
                                  disabled={values?.healthChronicDeseaseProgram?.includes(
                                    'healthChronicDeseaseDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthChronicDeseaseInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthChronicDeseaseProgram"
                                  id="healthChronicDeseaseVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthChronicDeseaseVirtual"
                                  disabled={values?.healthChronicDeseaseProgram?.includes(
                                    'healthChronicDeseaseDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthChronicDeseaseVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Water Fitness Classes
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthWaterFitnessClassesProgram"
                                  id="healthWaterFitnessDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthWaterFitnessDidNotOffer"
                                  disabled={
                                    values?.healthWaterFitnessClassesProgram?.includes(
                                      'healthWaterFitnessInPerson'
                                    ) ||
                                    values?.healthWaterFitnessClassesProgram?.includes(
                                      'healthWaterFitnessVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="healthWaterFitnessDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthWaterFitnessClassesProgram"
                                  id="healthWaterFitnessInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthWaterFitnessInPerson"
                                  disabled={values?.healthWaterFitnessClassesProgram?.includes(
                                    'healthWaterFitnessDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthWaterFitnessInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="healthWaterFitnessClassesProgram"
                                  id="healthWaterFitnessVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="healthWaterFitnessVirtual"
                                  disabled={values?.healthWaterFitnessClassesProgram?.includes(
                                    'healthWaterFitnessDidNotOffer'
                                  )}
                                />
                                <label htmlFor="healthWaterFitnessVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th className="w-500">
                              Community Development/Strong Communities
                            </th>
                            <th>Did not offer</th>
                            <th>In Person</th>
                            <th>Virtual</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Case Management
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityCaseManagementProgam"
                                  id="communityCaseMgmtDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityCaseMgmtDidNotOffer"
                                  disabled={
                                    values?.communityCaseManagementProgam?.includes(
                                      'communityCaseMgmtInPerson'
                                    ) ||
                                    values?.communityCaseManagementProgam?.includes(
                                      'communityCaseMgmtVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="communityCaseMgmtDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityCaseManagementProgam"
                                  id="communityCaseMgmtInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityCaseMgmtInPerson"
                                  disabled={values?.communityCaseManagementProgam?.includes(
                                    'communityCaseMgmtDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityCaseMgmtInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityCaseManagementProgam"
                                  id="communityCaseMgmtVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityCaseMgmtVirtual"
                                  disabled={values?.communityCaseManagementProgam?.includes(
                                    'communityCaseMgmtDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityCaseMgmtVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Substance Abuse Management
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityAbuseManagementProgram"
                                  id="communityAbuseMgmtDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityAbuseMgmtDidNotOffer"
                                  disabled={
                                    values?.communityAbuseManagementProgram?.includes(
                                      'communityAbuseMgmtInPerson'
                                    ) ||
                                    values?.communityAbuseManagementProgram?.includes(
                                      'communityAbuseMgmtVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="communityAbuseMgmtDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityAbuseManagementProgram"
                                  id="communityAbuseMgmtInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityAbuseMgmtInPerson"
                                  disabled={values?.communityAbuseManagementProgram?.includes(
                                    'communityAbuseMgmtDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityAbuseMgmtInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityAbuseManagementProgram"
                                  id="communityAbuseMgmtVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityAbuseMgmtVirtual"
                                  disabled={values?.communityAbuseManagementProgram?.includes(
                                    'communityAbuseMgmtDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityAbuseMgmtVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Diversity, Inclusion and Global Programs
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityDiverseIncluseGlobalProgram"
                                  id="communityDiversityInclusionDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityDiversityInclusionDidNotOffer"
                                  disabled={
                                    values?.communityDiverseIncluseGlobalProgram?.includes(
                                      'communityDiversityInclusionInPerson'
                                    ) ||
                                    values?.communityDiverseIncluseGlobalProgram?.includes(
                                      'communityDiversityInclusionVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="communityDiversityInclusionDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityDiverseIncluseGlobalProgram"
                                  id="communityDiversityInclusionInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityDiversityInclusionInPerson"
                                  disabled={values?.communityDiverseIncluseGlobalProgram?.includes(
                                    'communityDiversityInclusionDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityDiversityInclusionInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityDiverseIncluseGlobalProgram"
                                  id="communityDiversityInclusionVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityDiversityInclusionVirtual"
                                  disabled={values?.communityDiverseIncluseGlobalProgram?.includes(
                                    'communityDiversityInclusionDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityDiversityInclusionVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Family Strengthening
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityFamilyStrengthProgram"
                                  id="communityFamilyStrengthDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityFamilyStrengthDidNotOffer"
                                  disabled={
                                    values?.communityFamilyStrengthProgram?.includes(
                                      'communityFamilyStrengthInPerson'
                                    ) ||
                                    values?.communityFamilyStrengthProgram?.includes(
                                      'communityFamilyStrengthVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="communityFamilyStrengthDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityFamilyStrengthProgram"
                                  id="communityFamilyStrengthInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityFamilyStrengthInPerson"
                                  disabled={values?.communityFamilyStrengthProgram?.includes(
                                    'communityFamilyStrengthDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityFamilyStrengthInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityFamilyStrengthProgram"
                                  id="communityFamilyStrengthVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityFamilyStrengthVirtual"
                                  disabled={values?.communityFamilyStrengthProgram?.includes(
                                    'communityFamilyStrengthDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityFamilyStrengthVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="ymca-normalWrap txt-mandatory">
                              Mental Well Being
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityMentalWellBeingProgram"
                                  id="communityWellBeingDidNotOffer"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityWellBeingDidNotOffer"
                                  disabled={
                                    values?.communityMentalWellBeingProgram?.includes(
                                      'communityWellBeingInPerson'
                                    ) ||
                                    values?.communityMentalWellBeingProgram?.includes(
                                      'communityWellBeingVirtual'
                                    )
                                  }
                                />
                                <label htmlFor="communityWellBeingDidNotOffer" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityMentalWellBeingProgram"
                                  id="communityWellBeingInPerson"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityWellBeingInPerson"
                                  disabled={values?.communityMentalWellBeingProgram?.includes(
                                    'communityWellBeingDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityWellBeingInPerson" />
                              </div>
                            </td>
                            <td className="ymca-normalWrap">
                              <div>
                                <Field
                                  name="communityMentalWellBeingProgram"
                                  id="communityWellBeingVirtual"
                                  type="checkbox"
                                  className="styled-checkbox"
                                  value="communityWellBeingVirtual"
                                  disabled={values?.communityMentalWellBeingProgram?.includes(
                                    'communityWellBeingDidNotOffer'
                                  )}
                                />
                                <label htmlFor="communityWellBeingVirtual" />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <div className="ymca-submitHighlighter">
                  <LastUpdateName
                    Username={
                      GetProgramActivitiesSelectorData?.data?.updatedByName
                    }
                    Time={
                      GetProgramActivitiesSelectorData?.data?.updatedDatetime
                    }
                  />
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightUntitledUnderLay float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </div>
              {isSuccess && <SuccessToaster Status={resStatus} />}
            </Form>
          );
        }}
      </Formik>

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </>
  );
};

ProgramActivitiesTab.propTypes = {
  handleGetProgramActivities: PropTypes.func.isRequired,
  handleProgramActivities: PropTypes.func.isRequired,
  GetProgramActivitiesSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  setLoader: PropTypes.func.isRequired,
  selectedAsso: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranch: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranchName: PropTypes.objectOf(PropTypes.any).isRequired,
  setErrorPopup: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleProgramActivities: programActivities,
  handleGetProgramActivities: getProgramActivities,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

const mapStateToProps = state => ({
  GetProgramActivitiesSelectorData: GetProgramActivitiesSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ProgramActivitiesTab));
