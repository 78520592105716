import React from 'react';
import moment from 'moment';

import { APP_LABELS, CDP } from '@config/app-config';
import NotStarted from '@static/images/rejected-icon.svg';
import PendingIconDark from '@static/images/pending-status-icon.svg';
import SuccessModalIcon from '@static/images/approved-icon.svg';

const getViewDetailsLinkCell = ({ row }, viewDetalsPopup) => (
  <button
    type="button"
    onClick={() => viewDetalsPopup(row.original)}
    className="btn btn-link table-link p-0 custom-align"
  >
    {APP_LABELS.DASH_VIEW_DETAILS}
  </button>
);

const getStatusImage = statusValue => {
  switch (statusValue) {
    case CDP.DUE_DATE_STATUS.COMPLETED:
      return SuccessModalIcon;
    case CDP.DUE_DATE_STATUS.OVERDUE:
      return NotStarted;
    case CDP.DUE_DATE_STATUS.UPCOMING:
      return PendingIconDark;
    default:
      return '';
  }
};

const getStatusClass = statusValue => {
  switch (statusValue) {
    case CDP.DUE_DATE_STATUS.COMPLETED:
      return 'approved-status';
    case CDP.DUE_DATE_STATUS.OVERDUE:
      return 'rejected-status';
    case CDP.DUE_DATE_STATUS.UPCOMING:
      return 'pending-status';
    default:
      return '';
  }
};

const ADMIN_DASHBOARD_COLUMNS = viewDetalsPopup => [
  {
    Header: 'Association Name',
    accessor: (row) =>
      `${row?.associationName} (${row?.associationNumber})`,
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'Due Date',
    accessor: 'orgCodes',
    className: 'notranslate',
    Cell: (c) => {
      if (c?.value) {
        return (
          <div>
            {c.value?.map(val => (
              <>
                <div>
                  {val.orgCode === null && val.submittedDate === null
                    ? 'Paused Delivery'
                    : ''}
                  {val.orgCode !== null ? `${val.orgCode} -` : ''}
                  {val.submittedDate !== null
                    ? moment
                      .utc(val.submittedDate)
                      .local()
                      .format('DD MMM YYYY')
                    : ''}
                </div>
                <br />
              </>
            ))}
          </div>
        );
      }
      return (<span>Paused Delivery</span>);
    }
  },
  {
    Header: 'Status',
    accessor: row => (
      <div>
        {row?.orgCodes?.map(val => (
          <>
            <div className="d-flex align-items-center">
              <img
                className="img-fluid success-img me-2"
                src={getStatusImage(val.status)}
                alt=""
              />
              <span className={`${getStatusClass(val.status)} fw-bold`}>
                {val.status}
              </span>
            </div>
            {val.status === null && (
              <div className="d-flex align-items-center">
                <span>Paused Delivery</span>
              </div>
            )}
            <br />
          </>
        ))}
      </div>
    )
  },
  {
    Header: 'Last Updated On',
    accessor: 'lastUpdatedOn',
    className: 'notranslate ',
    Cell: ({ value }) => {
      if (value)
        return moment.utc(value).local().format('DD MMM YYYY, hh:mm a');
      return '-';
    }
  },
  {
    Header: 'Action',
    accessor: 'viewDetails',
    Cell: ({ row }) => getViewDetailsLinkCell({ row }, viewDetalsPopup)
  }
];

export default ADMIN_DASHBOARD_COLUMNS;
