import * as types from './types';

const initialState = {
  GetSoleraDataObj: {},
  PostSoleraImport: {},
  cdpData: undefined,
  programs: {},
  dataSystems: {},
  isImportedMsg: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CDP_IS_IMPORTED_MSG_SUCCESS: {
      return {
        ...state,
        isImportedMsg: action.payload
      };
    }
    case types.GET_CDP_IS_IMPORTED_MSG_FAILURE:
      return { ...state, error: action.payload };
    case types.POST_CDP_WELLD_FETCH_SUCCESS: {
      return {
        ...state,
        cdpData: action.payload
      };
    }
    case types.POST_CDP_WELLD_FETCH_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_SOLERA_FILE_SUCCESS: {
      return {
        ...state,
        cdpData: action.payload
      };
    }
    case types.POST_CDP_SOLERA_FILE_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_PROGRAM_SUCCESS:
      return {
        ...state,
        programs: action.payload
      };

    case types.GET_CDP_PROGRAM_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_DOL_TYPE_SUCCESS:
      return {
        ...state,
        dolTypes: action.payload
      };

    case types.GET_DOL_TYPE_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_DATASYSTEM_SUCCESS:
      return {
        ...state,
        dataSystems: action.payload
      };

    case types.GET_CDP_DATASYSTEM_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_REDCAP_REGISTRATION_SUCCESS: {
      return {
        ...state,
        cdpData: { ...state.cdpData, ...action.payload }
      };
    }
    case types.POST_CDP_REDCAP_REGISTRATION_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_REDCAP_VISITS_SUCCESS: {
      return {
        ...state,
        cdpData: { ...state.cdpData, ...action.payload }
      };
    }
    case types.POST_CDP_REDCAP_VISITS_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_REDCAP_ORGCODE_SUCCESS: {
      return {
        ...state,
        cdpData: { ...state.cdpData, ...action.payload }
      };
    }
    case types.POST_CDP_REDCAP_ORGCODE_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_REDCAP_COACH_SUCCESS: {
      return {
        ...state,
        cdpData: { ...state.cdpData, ...action.payload }
      };
    }
    case types.POST_CDP_REDCAP_COACH_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_REDCAP_FETCH_SUCCESS: {
      return {
        ...state,
        cdpData: { ...state.cdpData, ...action.payload }
      };
    }
    case types.POST_CDP_REDCAP_FETCH_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
