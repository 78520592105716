import {
  SWIMSAFETY_ASSOCIATION_DASHBOARD_COUNT_API,
  GET_SWIMSAFETY_BRANCHES_BY_FILTERS_API
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSwimAssociationDashboardCountList =
  (startDate, endDate, Offset, ShowAll, associationNumber) => (dispatch, getState) =>
    asyncRequest({
      url: SWIMSAFETY_ASSOCIATION_DASHBOARD_COUNT_API(
        startDate,
        endDate,
        Offset,
        ShowAll,
        associationNumber
      ),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_ASSOCIATION_DASHBOARD_COUNT_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_ASSOCIATION_DASHBOARD_COUNT_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyBranchesByFilters =
  (startDate, endDate, Offset,ShowAll) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_BRANCHES_BY_FILTERS_API(startDate, endDate, Offset,ShowAll),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_BRANCHES_BY_FILTERS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_BRANCHES_BY_FILTERS_FAILURE,
        payload: error
      }),
      dispatch
    });
