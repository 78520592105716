import * as types from './types';

const initialState = {
  ImportDataSuccess: {},
  selectedImportRows: []
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
  case types.IMPORT_DATA_SUCCESS: {
    return {
      ...state,
      ImportDataSuccess: action.payload
    };
  }
  case types.IMPORT_DATA_FAILURE:
    return { ...state, error: action.payload };

  default:
    return state;
  }
}
