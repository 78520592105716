const INSURANCE_ASSOCIATIONS_COLUMNS = () => [
  {
    Header: 'Association Name',
    accessor: 'associationName',
    className: 'notranslate'
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'notranslate'
  },
  {
    Header: 'Last Uploaded Date Time',
    accessor: 'lastUplodedDateTime',
    className: 'notranslate ymca-word-break '
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiryDate',
    className: 'notranslate'
  },
  {
    Header: 'Last Upload By',
    accessor: 'uplodedBy',
    className: 'notranslate '
  },
  {
    Header: '',
    accessor: 'details',
    className: 'notranslate'
  }
];

export default INSURANCE_ASSOCIATIONS_COLUMNS;
