import React, { useEffect, useState } from 'react';
import { APP_LABELS } from '@config/app-config';
import commonAxios from '@utilities/CommonAxios';
import YmcaLogo from '../../static/images/ymca-logo.svg';

const strapimockresponse =
  '<div class="raw-html-embed"><ul><li><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="icon"><a href="https://datacollectionhub.ymca.net/feedback/support" target="_self">Feedback &amp; Support</a></li><li><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="icon"><a href="https://link.ymca.net/mlink/file/NTEwNzc1" target="_blank">Employee Update Guide</a></li><li><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="icon"><a href="https://link.ymca.net/mlink/file/NDg1OTEx" target="_blank">Annual Report Nonfinancials Guide</a></li></ul><ul><li><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="icon"><a href="https://link.ymca.net/mlink/file/NDkyMjQz" target="_blank">Annual Report Nonfinancials Data Collection Template</a></li><li><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="icon"><a href="https://experience.arcgis.com/experience/cee808c0392b4d068ed30c35f823361c" target="_blank">Insights Hub</a></li><li><img src="https://datacollectionhub-cms.y.org/uploads/ymca_list_icon_e32ccb96db.svg" alt="icon"><a href="https://datacollectionhub-cms.y.org/uploads/Volunteer_Registration_System_Step_by_Step_41a7405627.pdf" target="_blank">Volunteer Registration Guide</a></li></ul></div>';

const footer = () => {
  const [feedbackStrapiData, setFeedbackStrapiData] = useState('');
  const [token, setToken] = useState('');
  useEffect(() => {
    setToken(sessionStorage.getItem('strapitoken'));
  }, [token]);
  useEffect(() => {
    commonAxios({
      url: '/footer-sections',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setFeedbackStrapiData(res?.data?.data[0].attributes.Data_Collection);
      })
      .catch(() => {
        setFeedbackStrapiData(strapimockresponse);
      });
  }, []);

  return (
    <>
      {window.location.href.includes('home') && (
        <footer className="ymca-primary-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-sm-6 d-flex align-items-center">
                <div className="ymca-footer-logo">
                  <img src={YmcaLogo} width="160px" height="55px" alt="logo" />
                </div>
              </div>
              <div className="col-lg-9 col-md-6 col-sm-6">
                <div className="row">
                  <div className="list-head">
                    <span>Resources and Quick Links</span>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: feedbackStrapiData
                    }}
                    className="ymca-footer-links"
                  />
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}

      <footer className="ymca-secondary-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-6 col-sm-12 text-lg-right text-md-end">
              <p>{APP_LABELS.FOOTER_COPYRIGHT}</p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  text-lg-right text-md-end">
              <a
                href="https://link.ymca.net/sites/home"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                className="footer-link"
              >
                {APP_LABELS.FOOTER_YMCA}
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
