const VOLUNTEER_TABLE_COLUMNS = [
  {
    Header: 'Volunteer Name',
    accessor: 'VolunteerName',
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'YMCA',
    accessor: 'YMCA',
    className: 'notranslate'
  },
  {
    Header: 'Registration For',
    accessor: 'RegistrationFor',
    className: 'notranslate'
  },
  {
    Header: 'Status',
    accessor: 'Status',
    className: 'notranslate statusShow'
  },
  {
    Header: 'Last Updated On',
    accessor: 'UpdatedDateTime',
    className: 'notranslate'
  },
  {
    Header: '',
    accessor: 'DetailPage'
  }
];

export default VOLUNTEER_TABLE_COLUMNS;
