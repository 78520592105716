import * as types from './types';

const initialState = {
  GetProgramEnrollments: {},
  PostProgramEnrollments: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_KEY':
      return {
        ...state,
        [action.payload.key]: {}
      };
    case types.GET_PROGRAM_ENROLLMENTS_SUCCESS: {
      return {
        ...state,
        GetProgramEnrollments: action.payload
      };
    }
    case types.GET_PROGRAM_ENROLLMENTS_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_PROGRAM_ENROLLMENTS_SUCCESS: {
      return {
        ...state,
        PostProgramEnrollments: action.payload
      };
    }
    case types.POST_PROGRAM_ENROLLMENTS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
