import React from 'react';
import PropTypes, { number } from 'prop-types';

const DetailsCard = props => {
  const { cardTitle, cardValue } = props;

  return (
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="card outline-card">
        <div className="card-title mb-1 fw-bold">{cardTitle}</div>
        <div className="card-title notranslate">{cardValue}</div>
      </div>
    </div>
  );
};

DetailsCard.propTypes = {
  cardTitle: PropTypes.string,
  cardValue: PropTypes.number
};

DetailsCard.defaultProps = {
  cardTitle: '',
  cardValue: number
};

export default DetailsCard;
