/* eslint-disable no-nested-ternary */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import get from 'lodash.get';
import { connect } from 'react-redux';
import {
  getAssociationNumber,
  getAssociationSummary,
  getAssociationSummaryByDateRange,
  getAssociationChangeLog,
  getAssociationList,
  getAssociationMoypChangeLog
} from '@redux/dashboard/actions';
import {
  AssociationSummarySelector,
  AssociationNumberSelector,
  AssociationChangeLogSelector,
  AssociationListSelector,
  AssociationMoypChangeLogSelector
} from '@redux/dashboard/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, DATE_ARRAY, STORAGE_KEYS } from '@config/app-config';
import moment from 'moment';
import UserIconvr from '@static/images/user-icon-vr.svg';
import UsersWhiteIcon from '@static/images/users-white-icon.svg';
import warningiconmedium from '@static/images/warning-icon-medium.svg';
import BuildingIconVoilet from '@static/images/building-icon-voilet.svg';
import BuildingIconWhite from '@static/images/building-icon-white.svg';
import UploadsBlueIcon from '@static/images/uploads-blue-icon.svg';
import UploadsWhiteIcon from '@static/images/uploads-white-icon.svg';
import UploadGreenIcon from '@static/images/upload-green-icon.svg';
import UploadPurpleIcon from '@static/images/upload-purple-icon.png';
import UploadWhiteGreenIcon from '@static/images/upload-white-green-icon.svg';
import successtickicon from '@static/images/success-tick-icon.svg';
import BlueUserIcon from '@static/images/blue-user-icon.png';
import AssociationIconVoilet from '@static/images/ymca-building-purple.svg';
import AssociationIconWhite from '@static/images/ymca-building-white.svg';
import BackIcon from '@static/images/back-icon.svg';
import OrangeIconVr from '@static/images/orange-icon-vr.png';
import YellowIcon from '@static/images/upload-yellow-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import SecureStorage from '@utilities/SecureStorage';
import Table from './table';
import COLUMNS from './columns';
import ASSOCIATION_COLUMNS from './association_columns';
import MOYPCOLUMNS from './moyp_columns';

const Dashboard = props => {
  const {
    LoginUserData,
    handleAssociationNumber,
    handleAssociationSummary,
    handleAssociationSummaryByDateRange,
    handleAssociationChangeLog,
    AssociationSummaryData,
    AssociationNumberData,
    AssociationChangeLogData,
    getAssociationListHandler,
    AssociationListData,
    handleAssociationMoypChangeLog,
    AssociationMoypChangeData
  } = props;
  const [loader, setLoader] = useState(false);
  const [columnData, setColumnData] = useState('changelogdetails');
  sessionStorage.setItem(
    'associationname',
    get(
      AssociationNumberData,
      'data.association.associationName',
      'Association Name'
    )
  );

  const [selectedRange, setSelectedRange] = useState(90);
  const defaultStartDate = moment().subtract(90, 'd').format('MM-DD-YYYY');
  const defaultEndDate = moment().format('MM-DD-YYYY');

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const Offset = new Date().getTimezoneOffset();
  const [summarySuccess, setSummarySuccess] = useState(false);
  const [changeLogSuccess, setChangeLogSuccess] = useState(false);
  const [associationDetailSuccess, setAssociationDetailSuccess] =
    useState(true);
  const [includeAllYs, setIncludeAllYs] = useState(true);
  const filterDate = days => {
    if (days === 'Custom') {
      setStartDate('');
      setEndDate('');
    } else if (days === '1') {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
    } else {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(defaultEndDate);
    }
  };
  const getEmployeeListByDateRange = (startDateValue, endDateValue) => {
    setLoader(true);
    setColumnData('changelogdetails');
    const association = get(LoginUserData, 'user.associations[0]', '0');
    const startdate = startDateValue
      ? moment(startDateValue).format('MM-DD-YYYY')
      : defaultStartDate;
    const enddate = endDateValue
      ? moment(endDateValue).format('MM-DD-YYYY')
      : defaultEndDate;
    setSummarySuccess(false);
    handleAssociationSummaryByDateRange(
      association,
      startdate,
      enddate,
      Offset
    ).then(res => {
      if (res) {
        setSummarySuccess(true);
      }
    });
    setChangeLogSuccess(false);
    handleAssociationChangeLog(association, startdate, enddate).then(res => {
      if (res) {
        setChangeLogSuccess(true);
      }
    });
  };
  useEffect(() => {
    if (summarySuccess && changeLogSuccess && associationDetailSuccess) {
      setLoader(false);
    }
  }, [summarySuccess, changeLogSuccess, associationDetailSuccess]);
  const yusaApplyFilterData = (
    startDateValue,
    endDateValue,
    includeAllYsValue
  ) => {
    setLoader(true);
    const startdate = startDateValue
      ? moment(startDateValue).format('MM-DD-YYYY')
      : startDateValue;
    const enddate = endDateValue
      ? moment(endDateValue).format('MM-DD-YYYY')
      : endDateValue;
    setChangeLogSuccess(false);
    getAssociationListHandler(
      startdate,
      enddate,
      includeAllYsValue,
      Offset
    ).then(res => {
      if (res) {
        setChangeLogSuccess(true);
      }
    });
    setSummarySuccess(false);
    handleAssociationSummary(startdate, enddate, Offset).then(res => {
      if (res) {
        setSummarySuccess(true);
      }
    });
  };
  useEffect(() => {
    setLoader(true);
    window.scrollTo(0, 0);
    SecureStorage.setItem(STORAGE_KEYS.DASHBOARD_URL, '/employee/dashboard');
    if (
      get(LoginUserData, 'user.role[0]', '0') !==
        APP_LABELS.YUSA_DATA_STEWARD &&
      get(LoginUserData, 'user.role[0]', '0') !==
        APP_LABELS.YESS_DATA_STEWARD &&
      get(LoginUserData, 'user.role[0]', '0') !== APP_LABELS.YUSA_DATA_VIEWER &&
      get(LoginUserData, 'user.role[0]', '0') !==
        APP_LABELS.ALLIANCE_DATA_STEWARD
    ) {
      getEmployeeListByDateRange(startDate, endDate);
      setAssociationDetailSuccess(false);
      handleAssociationNumber(
        get(LoginUserData, 'user.associations[0]', '0')
      ).then(res => {
        if (res) {
          setAssociationDetailSuccess(true);
        }
      });
    } else {
      yusaApplyFilterData(startDate, endDate, includeAllYs);
    }
  }, []);

  const newAssociationChangeLogData = AssociationChangeLogData?.data?.map(
    data => {
      const successMeg = (
        <span className="ymca-success-text">
          <img src={successtickicon} alt="" className="img-fluid success-img" />
          {APP_LABELS.DASH_SUCCESS}
        </span>
      );
      const faildMeg = (
        <span className="ymca-warning-text">
          <img
            src={warningiconmedium}
            alt=""
            className="img-fluid success-img"
          />
          {APP_LABELS.DASH_FAILED}
        </span>
      );

      const location = {
        pathname: '/employee/dashboard/detailView',
        state: data
      };

      return {
        BranchName: get(data, 'branchName', '-'),
        UploadedBy: get(data, 'uploadedBy', '-'),
        UploadedDateTime: get(data, 'uploadedDateTime')
          ? convertUtcToLocal(get(data, 'uploadedDateTime'))
          : '-',
        SourceSystem: get(data, 'sourceSystem', '-'),
        Summary: get(data, 'summary', '-')
          .split(',')
          .map(item => <div key={item}>{item}</div>),
        Status: get(data, 'status', '-') ? successMeg : faildMeg,
        DetailPage: (
          <Link to={location} className="table-link">
            {APP_LABELS.DASH_VIEW_DETAILS}
          </Link>
        )
      };
    }
  );

  const newAssociationMoypChangeLogData = AssociationMoypChangeData?.data?.map(
    data => {
      const successMeg = (
        <span className="ymca-success-text">
          <img src={successtickicon} alt="" className="img-fluid success-img" />
          {APP_LABELS.DASH_SUCCESS}
        </span>
      );
      const faildMeg = (
        <span className="ymca-warning-text">
          <img
            src={warningiconmedium}
            alt=""
            className="img-fluid success-img"
          />
          {APP_LABELS.DASH_FAILED}
        </span>
      );

      const location = {
        pathname: '/employee/moyp/detailView',
        state: data
      };

      return {
        UploadDate: get(data, 'lastUpdatedDate')
          ? moment(get(data, 'lastUpdatedDate')).format('D MMMM YYYY')
          : '-',
        SourceSystem: get(data, 'sourceSystem', '-'),
        Summary: get(data, 'summary', '-')
          .split(',')
          .map(item => <div key={item}>{item}</div>),
        Status: get(data, 'status', '-') ? successMeg : faildMeg,
        DetailPage: (
          <Link to={location} className="table-link">
            {APP_LABELS.DASH_VIEW_DETAILS}
          </Link>
        )
      };
    }
  );

  const associationListInfo = AssociationListData?.data?.map(data => {
    const location1 = {
      pathname: '/employee/association/dashboard',
      state: {data:data,fromDashboard:true}
    };
    return {
      AssociationName: `${get(data, 'associationName', '-')} (${get(
        data,
        'associationNumber',
        '-'
      )})`,
      ConatactName: get(data, 'contactName', '-'),
      ContactEmail: get(data, 'contactEmail', '-'),
      ContactNumber: get(data, 'contactNumber', '-'),
      MemberGroup: get(data, 'memberGroup', '-'),
      SourceSystem: get(data, 'sourceSystem', '-'),
      LastUpdatedOn: get(data, 'lastUpdatedDatetime')
        ? moment(get(data, 'lastUpdatedDatetime')).format('DD MMM YYYY hh:mm a')
        : '',
      DetailPage: (
        <Link to={location1} className="table-link">
          {APP_LABELS.DASH_VIEW_DETAILS}
        </Link>
      )
    };
  });

  const getMoypChangeLog = (startDateValue, endDateValue) => {
    setColumnData('moypchangelogdetails');
    setLoader(true);
    const association = get(LoginUserData, 'user.associations[0]', '0');
    const startdate = startDateValue
      ? moment(startDateValue).format('MM-DD-YYYY')
      : defaultStartDate;
    const enddate = endDateValue
      ? moment(endDateValue).format('MM-DD-YYYY')
      : defaultEndDate;
    handleAssociationMoypChangeLog(
      association,
      startdate,
      enddate,
      Offset
    ).then(() => {
      setLoader(false);
    });
  };
  const getChangeLogdetails = (startdate, enddate) => {
    setLoader(true);
    const association = get(LoginUserData, 'user.associations[0]', '0');
    handleAssociationChangeLog(association, startdate, enddate).then(() => {
      setLoader(false);
    });
  };
  const checkRoleGetData = (
    startDateValue,
    endDateValue,
    includeAllYsValue
  ) => {
    if (
      get(LoginUserData, 'user.role[0]') === APP_LABELS.YUSA_DATA_STEWARD ||
      get(LoginUserData, 'user.role[0]') === APP_LABELS.YUSA_DATA_VIEWER ||
      get(LoginUserData, 'user.role[0]') === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      get(LoginUserData, 'user.role[0]') === APP_LABELS.YESS_DATA_STEWARD
    ) {
      yusaApplyFilterData(startDateValue, endDateValue, includeAllYsValue);
    } else {
      getEmployeeListByDateRange(startDateValue, endDate);
    }
  };

  const getLastupDatedDate = () => {
    const lastUploadDatetime = get(
      AssociationSummaryData,
      'data.lastUploadDatetime'
    );
    const moypLastUpdatedDatetime = get(
      AssociationSummaryData,
      'data.moypLastUpdatedDatetime'
    );

    let renderedDate = '---';

    if (new Date(lastUploadDatetime) > new Date(moypLastUpdatedDatetime)) {
      renderedDate = convertUtcToLocal(lastUploadDatetime);
    } else if (moypLastUpdatedDatetime) {
      renderedDate = moment(moypLastUpdatedDatetime).format(
        'DD MMM YYYY hh:mm a'
      );
    }
    return renderedDate;
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper dashboard-wrapper">
          <div className="container">
            <section className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  {get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YUSA_DATA_STEWARD &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YESS_DATA_STEWARD ? (
                    <div className="user-name custom-username">
                      <div className="row">
                        <div className="col-lg-12">
                          <h6>{APP_LABELS.WELCOME}</h6>
                          <h2 className="ymca-sub-heading mb-0 notranslate">
                            {`${get(
                              LoginUserData,
                              'user.firstName',
                              'User'
                            )} ${get(LoginUserData, 'user.lastName', '')}`}
                          </h2>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="user-name">
                      <h6>{APP_LABELS.WELCOME}</h6>
                      <h2 className="ymca-sub-heading mb-0 notranslate">
                        {`${get(LoginUserData, 'user.firstName', 'User')} ${get(
                          LoginUserData,
                          'user.lastName',
                          ''
                        )}`}
                      </h2>
                    </div>
                  )}
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
                  {get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YUSA_DATA_STEWARD &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YUSA_DATA_VIEWER &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.ALLIANCE_DATA_STEWARD &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YESS_DATA_STEWARD ? (
                    <Link
                      className="btn ymca-primary-btn login-btn custom-align"
                      type="button"
                      to="/employee/dashboard/upload/file"
                    >
                      {APP_LABELS.DASH_UPLOAD_DATA}
                    </Link>
                  ) : (
                    <Link
                      className="ymca-title-link d-flex justify-content-end"
                      to="/home"
                    >
                      <img src={BackIcon} className="me-2" alt="" />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  )}
                </div>
              </div>
            </section>

            <div className=" mt-5">
              <h2 className="ymca-section-heading  mb-4">
                {APP_LABELS.EMPLOYEE_DATA_COLLECTION}
              </h2>
              <div className="row mb-2">
                <div className="col-lg-3 ymca-label ">
                  {' '}
                  <span className="txt-mandatory"> Range Selection</span>
                  <select
                    className="fieldSetSelect mt-1"
                    value={selectedRange}
                    onChange={e => {
                      setSelectedRange(e.target.value);
                      filterDate(e.target.value);
                    }}
                    defaultValue={90}
                  >
                    {DATE_ARRAY.map(items => (
                      <option key={items.id} value={items.value}>
                        {items.name}
                      </option>
                    ))}
                  </select>
                  <span className="ps-4 fs-14">Or</span>
                </div>

                <div className="col-lg-auto ymca-label pe-0 ">
                  <span className="txt-mandatory"> From Date</span>
                  <div>
                    <input
                      type="date"
                      onChange={e => {
                        setStartDate(e.target.value);
                        setEndDate('');
                      }}
                      value={startDate}
                      disabled={selectedRange !== APP_LABELS.CUSTOM}
                      className={`${
                        selectedRange ===  APP_LABELS.CUSTOM
                          ? ''
                          : 'cursor-not-allowed ymca-disabled-section'
                      } fieldSet mt-1`}
                      min={moment(new Date('1/1/1901 12:00:00')).format(
                        'YYYY-MM-DD'
                      )}
                      onKeyDown={e => {
                        e.preventDefault();
                      }}
                      max={moment(new Date())
                        .subtract(1, 'd')
                        .format('YYYY-MM-DD')}
                    />
                  </div>
                </div>
                <div className="col-lg-2 ymca-label">
                  <span className="txt-mandatory"> To Date</span>
                  <div>
                    <input
                      type="date"
                      onChange={e => {
                        setEndDate(e.target.value);
                      }}
                      onKeyDown={e => {
                        e.preventDefault();
                      }}
                      disabled={selectedRange !==  APP_LABELS.CUSTOM || startDate === ''}
                      className={`${
                        selectedRange !==  APP_LABELS.CUSTOM || startDate === ''
                          ? 'cursor-not-allowed ymca-disabled-section'
                          : ''
                      } fieldSet mt-1`}
                      value={endDate}
                      min={
                        selectedRange ===  APP_LABELS.CUSTOM
                          ? moment(new Date(startDate))
                              .add(1, 'days')
                              .format('YYYY-MM-DD')
                          : ''
                      }
                      max={
                        moment(new Date(startDate))
                          .add(2, 'Y')
                          .format('YYYY-MM-DD') <
                        moment(new Date()).format('YYYY-MM-DD')
                          ? moment(new Date(startDate))
                              .add(2, 'Y')
                              .format('YYYY-MM-DD')
                          : moment(new Date()).format('YYYY-MM-DD')
                      }
                    />
                  </div>
                </div>
                {get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YESS_DATA_STEWARD ? (
                  <div className="col-lg-auto ymca-label me-3">
                    <span> Associations</span>
                    <div className="mt-1 d-flex align-items-center ">
                      <input
                        type="checkbox"
                        className="ymca-checkboxStyled mt-2 me-2"
                        checked={includeAllYs}
                        onClick={e => {
                          setIncludeAllYs(e.target.checked);
                        }}
                      />
                      <span className="mt-2 ymca-cachet-normal-dark-text ">
                        Include all
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="col-lg-1 mt-1 ps-0">
                  <button
                    type="button"
                    onClick={() => {
                      checkRoleGetData(startDate, endDate, includeAllYs);
                    }}
                    disabled={
                      (startDate === '' || endDate === '') &&
                      selectedRange ===  APP_LABELS.CUSTOM
                    }
                    className="btn ymca-primary-btn apply-btn custom-align"
                  >
                    <span> Apply</span>
                  </button>
                </div>
                <div className="col-lg-1 mt-1">
                  <button
                    type="button"
                    onClick={() => {
                      setStartDate(defaultStartDate);
                      setEndDate(defaultEndDate);
                      setSelectedRange(90);
                      setIncludeAllYs(true);
                      checkRoleGetData(defaultStartDate, defaultEndDate, true);
                    }}
                    className="btn  ymc-outlineBtn apply-btn custom-align"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <section className="stats-data">
              <div className="row">
                {(get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_STEWARD ||
                  get(LoginUserData, 'user.role[0]') ===
                    APP_LABELS.YUSA_DATA_VIEWER ||
                  get(LoginUserData, 'user.role[0]') ===
                    APP_LABELS.ALLIANCE_DATA_STEWARD ||
                  get(LoginUserData, 'user.role[0]') ===
                    APP_LABELS.YESS_DATA_STEWARD) && (
                  <div className="col-lg-auto col-md-6 col-sm-12">
                    <div className="card stats-card associations min-width">
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={AssociationIconVoilet}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={AssociationIconWhite}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>

                        <div className="card-title">
                          <h4>
                            {get(
                              AssociationSummaryData,
                              'data.noOFAssociations',
                              '0'
                            )}
                          </h4>
                          <p>No.of</p>
                          <p>Associations</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-lg-auto col-md-6 col-sm-12">
                  <div
                    className={`card stats-card branches ${
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.YUSA_DATA_STEWARD ||
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.YUSA_DATA_VIEWER ||
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.ALLIANCE_DATA_STEWARD ||
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.YESS_DATA_STEWARD
                        ? 'min-width'
                        : 'mw-180'
                    }`}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={BuildingIconVoilet}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={BuildingIconWhite}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {get(
                            AssociationSummaryData,
                            'data.noOFBranches',
                            '0'
                          )}
                        </h4>
                        {get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.YUSA_DATA_STEWARD ||
                        get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.YUSA_DATA_VIEWER ||
                        get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.ALLIANCE_DATA_STEWARD ||
                        get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.YESS_DATA_STEWARD ? (
                          <>
                            <p> No.of </p>
                            <p>Branches</p>
                          </>
                        ) : (
                          <p>No.of Branches</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12`">
                  <div
                    className={`${
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.YUSA_DATA_STEWARD ||
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.YUSA_DATA_VIEWER ||
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.ALLIANCE_DATA_STEWARD ||
                      get(LoginUserData, 'user.role[0]') ===
                        APP_LABELS.YESS_DATA_STEWARD
                        ? 'stats-card  min-width profiles'
                        : 'stats-card mw-180 associations'
                    } card `}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={
                            get(LoginUserData, 'user.role[0]') ===
                              APP_LABELS.YUSA_DATA_STEWARD ||
                            get(LoginUserData, 'user.role[0]') ===
                              APP_LABELS.YUSA_DATA_VIEWER ||
                            get(LoginUserData, 'user.role[0]') ===
                              APP_LABELS.ALLIANCE_DATA_STEWARD ||
                            get(LoginUserData, 'user.role[0]') ===
                              APP_LABELS.YESS_DATA_STEWARD
                              ? UserIconvr
                              : BlueUserIcon
                          }
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={UsersWhiteIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {get(
                            AssociationSummaryData,
                            'data.activeEmployees',
                            '0'
                          )}
                        </h4>
                        <p>
                          {get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.YUSA_DATA_STEWARD ||
                          get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.YUSA_DATA_VIEWER ||
                          get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.ALLIANCE_DATA_STEWARD ||
                          get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.YESS_DATA_STEWARD ? (
                            <>
                              <p>Employees</p>
                              <p>in System</p>
                            </>
                          ) : (
                            'Active Employees'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-auto col-md-6 col-sm-12">
                  <div
                    aria-hidden="true"
                    onClick={() => {
                      if (
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YUSA_DATA_STEWARD &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YUSA_DATA_VIEWER &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.ALLIANCE_DATA_STEWARD &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YESS_DATA_STEWARD
                      ) {
                        setColumnData('changelogdetails');
                        getChangeLogdetails(startDate, endDate);
                      }
                    }}
                    className={`card stats-card staff-assign min-width  ${
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YUSA_DATA_STEWARD &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YUSA_DATA_VIEWER &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.ALLIANCE_DATA_STEWARD &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YESS_DATA_STEWARD &&
                      columnData === 'changelogdetails'
                        ? ' active'
                        : ''
                    }${
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YUSA_DATA_STEWARD &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YUSA_DATA_VIEWER &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.ALLIANCE_DATA_STEWARD &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YESS_DATA_STEWARD
                        ? ' c-pointer'
                        : ''
                    } `}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={OrangeIconVr}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={UploadsWhiteIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        {get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YUSA_DATA_STEWARD &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YUSA_DATA_VIEWER &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.ALLIANCE_DATA_STEWARD &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YESS_DATA_STEWARD ? (
                          <h4>
                            {get(
                              AssociationSummaryData,
                              'data.noOFEmployees',
                              '0'
                            )}
                          </h4>
                        ) : (
                          <h4>
                            {get(
                              AssociationSummaryData,
                              'data.hubTotalUpdates',
                              '0'
                            )}
                          </h4>
                        )}                 
                          {get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.YUSA_DATA_STEWARD ||
                          get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.YUSA_DATA_VIEWER ||
                          get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.ALLIANCE_DATA_STEWARD ||
                          get(LoginUserData, 'user.role[0]') ===
                            APP_LABELS.YESS_DATA_STEWARD ? (
                            <>
                              <p> Employees </p>
                              <p>Updated in Hub</p>
                            </>
                          ) : (
                            <>
                              <p>No.of Employees updated </p>
                              <p>in Hub</p>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-auto col-md-6 col-sm-12 ">
                  <div
                    aria-hidden="true"
                    onClick={() => {
                      if (
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YUSA_DATA_STEWARD &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YUSA_DATA_VIEWER &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.ALLIANCE_DATA_STEWARD &&
                        get(LoginUserData, 'user.role[0]') !==
                          APP_LABELS.YESS_DATA_STEWARD
                      ) {
                        getMoypChangeLog(startDate, endDate);
                      }
                    }}
                    className={`card stats-card programs ${
                      columnData === 'moypchangelogdetails' ? 'active' : ''
                    } ${
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YUSA_DATA_STEWARD &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YUSA_DATA_VIEWER &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.ALLIANCE_DATA_STEWARD &&
                      get(LoginUserData, 'user.role[0]') !==
                        APP_LABELS.YESS_DATA_STEWARD
                        ? 'c-pointer mw-180'
                        : ' min-width'
                    }`}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={UploadsBlueIcon}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={UploadsWhiteIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>
                      <div className="card-title">
                        <h4>
                          {get(
                            AssociationSummaryData,
                            'data.moypTotalUpdates',
                            '0'
                          )}
                        </h4>
                        {get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.YUSA_DATA_STEWARD ||
                        get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.YUSA_DATA_VIEWER ||
                        get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.ALLIANCE_DATA_STEWARD ||
                        get(LoginUserData, 'user.role[0]') ===
                          APP_LABELS.YESS_DATA_STEWARD ? (
                          <>
                            <p>Employees </p>
                            <p>Updated in MOYP</p>
                          </>
                        ) : (
                          <p>MOYP Updates</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YESS_DATA_STEWARD ? (
                  <div className="col-lg-auto col-md-6 col-sm-12 pe-1">
                    <div className="card stats-card new-staff min-width">
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={YellowIcon}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={UploadsWhiteIcon}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>

                        <div className="card-title">
                          <h4>
                            {get(
                              AssociationSummaryData,
                              'data.totalUploads',
                              '0'
                            )}
                          </h4>
                          <p>Updated </p>
                          <p>Associations</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {get(LoginUserData, 'user.role[0]') !==
                  APP_LABELS.YUSA_DATA_STEWARD &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YUSA_DATA_VIEWER &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.ALLIANCE_DATA_STEWARD &&
                  get(LoginUserData, 'user.role[0]') !==
                    APP_LABELS.YESS_DATA_STEWARD && (
                    <div className="col-lg-auto col-md-6 col-sm-12">
                      <div
                        className={`${
                          new Date(
                            get(
                              AssociationSummaryData,
                              'data.lastUploadDatetime'
                            )
                          ) <
                          new Date(
                            get(
                              AssociationSummaryData,
                              'data.moypLastUpdatedDatetime',
                              ''
                            )
                          )
                            ? ' stats-card upload-green'
                            : 'stats-card mw-180 upload-purple'
                        } card `}
                      >
                        <div className="d-flex align-items-center h-100">
                          <div className="card-icon">
                            <img
                              src={
                                new Date(
                                  get(
                                    AssociationSummaryData,
                                    'data.lastUploadDatetime'
                                  )
                                ) <
                                new Date(
                                  get(
                                    AssociationSummaryData,
                                    'data.moypLastUpdatedDatetime',
                                    ''
                                  )
                                )
                                  ? UploadGreenIcon
                                  : UploadPurpleIcon
                              }
                              className="dark-icon"
                              alt="icon"
                            />
                            <img
                              src={UploadWhiteGreenIcon}
                              className="light-icon"
                              alt="icon"
                            />
                          </div>
                          <div className="card-title">
                            <h4>{getLastupDatedDate()}</h4>
                            <p>
                              {new Date(
                                get(
                                  AssociationSummaryData,
                                  'data.lastUploadDatetime'
                                )
                              ) >
                              new Date(
                                get(
                                  AssociationSummaryData,
                                  'data.moypLastUpdatedDatetime',
                                  ''
                                )
                              )
                                ? 'Last Updated in Hub'
                                : 'Last Updated in MOYP'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </section>
          </div>
          {get(LoginUserData, 'user.role[0]') ===
            APP_LABELS.YUSA_DATA_STEWARD ||
          get(LoginUserData, 'user.role[0]') === APP_LABELS.YUSA_DATA_VIEWER ||
          get(LoginUserData, 'user.role[0]') ===
            APP_LABELS.ALLIANCE_DATA_STEWARD ||
          get(LoginUserData, 'user.role[0]') ===
            APP_LABELS.YESS_DATA_STEWARD ? (
            <hr className="custom-hr mt-0" />
          ) : (
            ''
          )}

          {columnData === 'changelogdetails' ? (
            <Table
              rows={
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YESS_DATA_STEWARD
                  ? associationListInfo
                  : newAssociationChangeLogData
              }
              startDate={moment(startDate).format('MM-DD-YYYY')}
              endDate={moment(endDate).format('MM-DD-YYYY')}
              includeAllYs={includeAllYs}
              setLoader={setLoader}
              headers={
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YESS_DATA_STEWARD
                  ? ASSOCIATION_COLUMNS
                  : COLUMNS
              }
            />
          ) : (
            <Table
              rows={
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YESS_DATA_STEWARD
                  ? associationListInfo
                  : newAssociationMoypChangeLogData
              }
              includeAllYs={includeAllYs}
              startDate={moment(startDate).format('MM-DD-YYYY')}
              endDate={moment(endDate).format('MM-DD-YYYY')}
              setLoader={setLoader}
              headers={
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YUSA_DATA_VIEWER ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD ||
                get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.YESS_DATA_STEWARD
                  ? ASSOCIATION_COLUMNS
                  : MOYPCOLUMNS
              }
            />
          )}
        </div>
      </main>
    </BlockUi>
  );
};

Dashboard.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationSummaryData: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationChangeLogData: PropTypes.objectOf(PropTypes.any).isRequired,
  AssociationListData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAssociationNumber: PropTypes.func.isRequired,
  handleAssociationSummary: PropTypes.func.isRequired,
  handleAssociationSummaryByDateRange: PropTypes.func.isRequired,
  handleAssociationChangeLog: PropTypes.func.isRequired,
  getAssociationListHandler: PropTypes.func.isRequired,
  AssociationMoypChangeData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAssociationMoypChangeLog: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleAssociationNumber: getAssociationNumber,
  handleAssociationSummary: getAssociationSummary,
  handleAssociationSummaryByDateRange: getAssociationSummaryByDateRange,
  handleAssociationChangeLog: getAssociationChangeLog,
  getAssociationListHandler: getAssociationList,
  handleAssociationMoypChangeLog: getAssociationMoypChangeLog
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationSummaryData: AssociationSummarySelector(state),
  AssociationNumberData: AssociationNumberSelector(state),
  AssociationChangeLogData: AssociationChangeLogSelector(state),
  AssociationListData: AssociationListSelector(state),
  AssociationMoypChangeData: AssociationMoypChangeLogSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
