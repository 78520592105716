const ASSOCIATION_COLUMNS = [
  {
    Header: 'Association Name',
    accessor: 'AssociationName',
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'Member Group',
    accessor: 'MemberGroup',
    className: 'notranslate'
  },
  // {
  //   Header: 'Contact Email',
  //   accessor: 'ContactEmail',
  //   className: 'notranslate'
  // },
  // {
  //   Header: 'Contact Number',
  //   accessor: 'ContactNumber',
  //   className: 'notranslate'
  // },
  {
    Header: 'Overall Status',
    accessor: 'Status',
    className: 'notranslate statusShow'
  },
  {
    Header: 'Pledge Statment Status',
    accessor: 'PledgeStatement',
    className: 'notranslate statusShow'
  },
  {
    Header: 'Progress',
    accessor: 'Progress',
    className: 'notranslate'
  },
  {
    Header: 'Last Updated Date Time',
    accessor: 'UpdatedDateTime',
    className: 'notranslate text-center'
  },
  {
    Header: '',
    accessor: 'DetailPage'
  }
];

export default ASSOCIATION_COLUMNS;
