import React from 'react';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';
import attentionicon from '../static/images/attention-icon.svg';
import successmodalicon from '../static/images/success-modal-icon.png';
import erroricon from '../static/images/error-icon.svg';
import crossicon from '../static/images/cross-icon-blue.svg';

const CustomModal = props => {
  const {
    show,
    type,
    message,
    name,
    jobTitle,
    email,
    icon,
    submitButtonName,
    onSubmit,
    onCancel,
    cancelButtonName,
    showAllSeperatedPartTimeStaff
  } = props;

  const modal = {
    background: 'rgb(0 0 0 / 0.5)'
  };

  const showHideClassName = show ? 'modal d-block' : 'modal d-none';

  const getIcon = () => {
    let image = '';
    switch (type) {
      case APP_LABELS.INFORMATION:
        image = attentionicon;
        break;

      case APP_LABELS.ERROR:
        image = erroricon;
        break;

      case APP_LABELS.SUCCESS:
        image = successmodalicon;
        break;

      default:
        image = attentionicon;
        break;
    }
    return image;
  };

  return (
    <div className={showHideClassName} style={modal}>
      <div className="modal-dialog ymc-pointerEvent modal-dialog-centered justify-content-center">
        <div className="d-flex bg-white py-4 px-4 rounded-2 position-relative pb-4 pt-5 YMC-newChange">
          {cancelButtonName && (
            <div className="YMC-absoluteRight py-3">
              <button
                type="button"
                className="btn btn-transfer"
                onClick={() => {
                  onCancel();
                }}
              >
                <img
                  src={crossicon}
                  className="img-fluid YMC-closeIcon"
                  alt="wait-icon"
                />
              </button>
            </div>
          )}

          <div className="YMC-icons me-3 mt-3">
            <img
              src={icon || getIcon()}
              className="img-fluid ymc-noAlert"
              alt="wait-icon"
            />
          </div>
          <div className="YMC-textBody">
            {(jobTitle !== 'CEO' || jobTitle !== 'CVO') && (
              <h2 className="ymca-sub-heading">{type}</h2>
            )}
            <p
              className={`${
                jobTitle === 'CEO' || jobTitle === 'CVO'
                  ? 'ymca-textTitle'
                  : 'ymca-bold-heading'
              }  ${showAllSeperatedPartTimeStaff?'mb-3':'mb-4'}`}
            >
              {message}
            </p>
            {showAllSeperatedPartTimeStaff?<p class="ymca-emp-warning-text">{APP_LABELS.EMPLOYEE_WARNING_TEXT}</p>:''}
            {(jobTitle === 'CEO' || jobTitle === 'CVO') && (
              <>
                <p className="py-0 my-0">
                  <span className="ymca-headingTitle">Name: </span>
                  <span className="ymca-textTitle">{name}</span>
                </p>
                <p className="py-0 my-0">
                  <span className="ymca-headingTitle">Job Title: </span>
                  <span className="ymca-textTitle">
                    {jobTitle === 'CVO'
                      ? 'Chief Volunteer Officer/Board Chair'
                      : jobTitle}
                  </span>
                </p>
                <p className="py-0 my-0">
                  <span className="ymca-headingTitle">Email: </span>
                  <span className="ymca-textTitle">{email}</span>
                </p>
              </>
            )}

            {(submitButtonName || cancelButtonName) && (
              <div className="modal-footer p-0 border-0">
                {cancelButtonName && (
                  <button
                    type="button"
                    className="btn ymc-outlineBtn btn-outline-primary custom-align mx-2"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    {cancelButtonName}
                  </button>
                )}

                <button
                  type="button"
                  className="btn ymca-primary-btn custom-align"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {submitButtonName}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showAllSeperatedPartTimeStaff:PropTypes.bool.isRequired,
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  icon: PropTypes.string,
  submitButtonName: PropTypes.string,
  onSubmit: PropTypes.func,
  cancelButtonName: PropTypes.string,
  onCancel: PropTypes.func
};

CustomModal.defaultProps = {
  icon: '',
  submitButtonName: '',
  onSubmit: () => {},
  cancelButtonName: '',
  type: APP_LABELS.INFORMATION,
  onCancel: () => {}
};

export default CustomModal;
