import {
  ANNUAL_REPORT_GET_HOUSING,
  ANNUAL_REPORT_HOUSING
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getHousing =
  (AssociationNumber, BranchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_HOUSING(AssociationNumber, BranchNumber),
      successAction: data => ({
        type: types.HOUSING_GET_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.HOUSING_GET_FAILURE,
        payload: error
      }),
      dispatch
    });
export const housing = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: ANNUAL_REPORT_HOUSING(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.HOUSING_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.HOUSING_FAILURE,
      payload: error
    }),
    dispatch
  });
