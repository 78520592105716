import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import getUserManagementImportData from '@redux/user-management/user-file-import-data/actions';
import UserManagementUploadFileSelector from '@redux/user-management/user-file-upload/selectors';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import KpiCard from '@modules/shared/components/reusable-components/kpi-card';
import { APP_LABELS } from '@config/app-config';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import totalrecordpurpleicon from '@static/images/total-staff-purple-icon.svg';
import totalrecordwhiteicon from '@static/images/total-staff-white-icon.svg';
import totalrecordvoileticon from '@static/images/total-staff-voilet-icon.svg';
import errorrecordvoileticon from '@static/images/invalid-record-voilet-icon.svg';
import existingrecordpinkicon from '@static/images/un-changed-pink-icon.svg';
import existingrecordwhiteicon from '@static/images/Un-Changed-white-icon.svg';
import newrecordyellowicon from '@static/images/new-staff-yellow-icon.svg';
import newrecordwhiteicon from '@static/images/new-staff-white-icon.svg';
import CustomModal from '@utilities/CustomModal';
import warningiconmedium from '@static/images/warning-icon-medium.svg';
import TotalUsersTableColumns from './total-users-columns';
import TableColumns from './table-columns';
import Table from './table';
import ErrorColumns from './error-columns';

const UploadData = props => {
  const { UploadStaffData, handleImportData } = props;
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [enableButton, setEnableButton] = useState(true);
  const [selectedRowsChangeStaff] = useState();
  const [key, setKey] = useState('newrecord');
  const [newRecordsDetails, setNewRecordsDetails] = useState();
  const [existingRecordsDetails, setExistingRecordsDetails] = useState();
  const [totalRecordsDetails, setTotalRecordsDetails] = useState();
  const [errorRecordsDetails, setErrorRecordsDetails] = useState();
  const [redirect, setRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [showTotalRecords, setShowTotalRecords] = useState('show active');
  const [showNewRecords, setShowNewRecords] = useState('');
  const [showErrorRecords, setShowErrorRecords] = useState('');
  const [showExistingRecords, setShowExistingRecords] = useState('');
  const [activeTotalRecords, setActiveTotalRecords] = useState('active');
  const [activeNewRecords, setActiveNewRecords] = useState('');
  const [activeErrorRecords, setActiveErrorRecords] = useState('');
  const [activeExistingRecords, setActiveExistingRecords] = useState('');
  const [instructions, setInstructions] = useState(false);

  useEffect(() => {
    const NewRecordsDetailRow = get(
      UploadStaffData,
      'data.segregatedList.NewUsers',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`
    }));
    setNewRecordsDetails(NewRecordsDetailRow);

    const ExistingRecordsDetailRow = get(
      UploadStaffData,
      'data.segregatedList.ExistedUsers',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`
    }));
    setExistingRecordsDetails(ExistingRecordsDetailRow);

    const ErrorRecordsDetailsRow = get(
      UploadStaffData,
      'data.segregatedList.Error',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName ? v.lastName : ''}`,
      errorSummary: v.errorSummary
        .split(',')
        .map(item => <div key={item}>{item}</div>)
    }));
    setErrorRecordsDetails(ErrorRecordsDetailsRow);

    const TotalRecordsDetailsRow = get(
      UploadStaffData,
      'data.segregatedList.TotalUsers',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName ? v.lastName : ''}`,
      recordType: (
        <span
          className={`${
            v.recordType === 'Existing User' ? 'warning-badge' : ''
          }${v.recordType === 'New User' ? 'success-badge' : ''} ${
            v.recordType === 'Error Record' ? 'error-badge' : ''
          }`}
        >
          {v.recordType}{' '}
        </span>
      )
    }));

    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'user-update-review'
      ]?.Instruction
    );

    setTotalRecordsDetails(TotalRecordsDetailsRow);
  }, []);

  useEffect(() => {
    if (key === 'totalrecords') {
      setShowTotalRecords('show active');
      setShowNewRecords('');
      setShowErrorRecords('');
      setShowExistingRecords('');
      setActiveTotalRecords('active');
      setActiveNewRecords('');
      setActiveErrorRecords('');
      setActiveExistingRecords('');
    } else if (key === 'newrecord') {
      setShowTotalRecords('');
      setShowNewRecords('show active');
      setShowErrorRecords('');
      setShowExistingRecords('');
      setActiveNewRecords('active');
      setActiveTotalRecords('');
      setActiveErrorRecords('');
      setActiveExistingRecords('');
    } else if (key === 'errorrecords') {
      setShowTotalRecords('');
      setShowNewRecords('');
      setShowErrorRecords('show active');
      setShowExistingRecords('');
      setActiveNewRecords('');
      setActiveTotalRecords('');
      setActiveErrorRecords('active');
      setActiveExistingRecords('');
    } else if (key === 'existingrecords') {
      setShowTotalRecords('');
      setShowNewRecords('');
      setShowErrorRecords('');
      setShowExistingRecords('show active');
      setActiveNewRecords('');
      setActiveTotalRecords('');
      setActiveErrorRecords('');
      setActiveExistingRecords('active');
    }
  }, [key]);

  const redirectToDashBoard = () => {
    setRedirectUrl('/userManagement/dashboard');
    setRedirect(true);
  };

  const postImportDataFile = () => {
    setEnableButton(true);
    setLoader(true);
    const finalData = [
      ...get(UploadStaffData, 'data.segregatedList.NewUsers', [])
    ];
    const obj = {
      usersImport: finalData
    };

    handleImportData(obj).then(res => {
      if (res.status === 200) {
        if (res.data?.success === 'Success') {
          setshowSuccessPopup(true);
          setShowMessage(res.data?.message);
        } else {
          setShowErrorPopup(true);
          setShowMessage(res.data?.message);
        }
      } else {
        setShowErrorPopup(true);
        setShowMessage(res?.message);
      }
      setLoader(false);
    });
  };

  const handelCheckBox = event => {
    setCheckBox(event.target.checked);

    if (event.target.checked) {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setRedirectUrl('/userManagement/upload/file');
    setRedirect(true);
  };
  const onkipclick = type => {
    setKey(type);
  };

  return (
    <>
      <BlockUi
        blocking={loader}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <main className="page-container">
          <div className="ymca-data-wrapper ymca-upload-data-wrapper">
            <div className="container">
              <div className="row mb-3 align-items-center">
                <div className="col-11">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ymca-breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/userManagement/dashboard">
                          {APP_LABELS.NAV_DASHBOARD}
                        </a>
                        <img
                          src={BreadcrumbIcon}
                          className="breadcrumb-icon"
                          alt=""
                        />
                      </li>
                      <li className="breadcrumb-item active">
                        {APP_LABELS.DETAIL_UPLOAD_DATA}
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-1 text-end">
                  <Link
                    type="button"
                    to="/userManagement/upload/file"
                    className="ymca-title-link d-flex align-items-center"
                  >
                    <img src={BackIcon} className="me-1" alt="back-icon" />
                    {APP_LABELS.DASH_BACK}
                  </Link>
                </div>
              </div>
              <div className="user-name bg-transparent d-flex align-items-center">
                <h2 className="ymca-sub-heading mt-3">User Update Review</h2>
              </div>
              <div className="ymca-instructionBoxes mb-3">
                <h4 className="ymca-instructHeadline">
                  {APP_LABELS.INSTRUCTIONS}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: instructions
                  }}
                />
              </div>
            </div>

            <hr className="custom-hr" />

            <section className="stats-data">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <KpiCard
                      id="totalrecords-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#totalrecords"
                      role="tab"
                      ariacontrols="totalrecords"
                      ariaselected="true"
                      activeClass={activeTotalRecords}
                      darkIcon={totalrecordpurpleicon}
                      lightIcon={totalrecordwhiteicon}
                      cardClass="col-lg-3 "
                      cardHoverClass="w-auto associations c-pointer"
                      onkipclick={() => onkipclick('totalrecords')}
                      cardName="Total Users"
                      cardValue={get(
                        UploadStaffData,
                        'data.fileUploadSummary.totalUsers',
                        'Total Users'
                      )}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <KpiCard
                      activeClass={activeNewRecords}
                      id="newrecord-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#newrecord"
                      role="tab"
                      ariacontrols="newrecord"
                      ariaselected="true"
                      darkIcon={newrecordyellowicon}
                      lightIcon={newrecordwhiteicon}
                      onkipclick={() => onkipclick('newrecord')}
                      cardClass="col-lg-3"
                      cardHoverClass="w-auto new-staff c-pointer"
                      cardName="New Users"
                      cardValue={get(
                        UploadStaffData,
                        'data.fileUploadSummary.newUsers',
                        'New Users'
                      )}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <KpiCard
                      id="existingrecords-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#existingrecords"
                      role="tab"
                      ariacontrols="existingrecords"
                      ariaselected="true"
                      activeClass={activeExistingRecords}
                      darkIcon={existingrecordpinkicon}
                      lightIcon={existingrecordwhiteicon}
                      onkipclick={() => onkipclick('existingrecords')}
                      cardClass="col-lg-3 "
                      cardHoverClass="w-auto profiles c-pointer"
                      cardName="Existing Users"
                      cardValue={get(
                        UploadStaffData,
                        'data.fileUploadSummary.existedUsers',
                        'Existing Users'
                      )}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <KpiCard
                      activeClass={activeErrorRecords}
                      id="errorrecords-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#errorrecords"
                      role="tab"
                      ariacontrols="errorrecords"
                      ariaselected="true"
                      darkIcon={totalrecordvoileticon}
                      lightIcon={errorrecordvoileticon}
                      onkipclick={() => onkipclick('errorrecords')}
                      cardClass="col-lg-3"
                      cardHoverClass="w-auto branches c-pointer"
                      cardName="Error Records"
                      cardValue={get(
                        UploadStaffData,
                        'data.fileUploadSummary.error',
                        'Error Records'
                      )}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="ymca-tabs">
              <div className="container">
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade ${showTotalRecords}`}
                    id="totalrecords"
                    role="tabpanel"
                    aria-labelledby="totalrecords-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          Total Users
                        </h3>
                      </div>

                      {totalRecordsDetails !== undefined &&
                      totalRecordsDetails.length !== 0 ? (
                        <>
                          <div className="col-lg-12 col-sm-12">
                            <Table
                              datarows={totalRecordsDetails}
                              headers={TotalUsersTableColumns}
                              defaultSorted={[
                                {
                                  id: 'name',
                                  desc: false
                                }
                              ]}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${showNewRecords}`}
                    id="newrecord"
                    role="tabpanel"
                    aria-labelledby="newrecord-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          New Users
                        </h3>
                      </div>

                      {newRecordsDetails !== undefined &&
                      newRecordsDetails.length !== 0 ? (
                        <>
                          <div className="col-lg-12 col-sm-12">
                            <Table
                              datarows={newRecordsDetails}
                              headers={TableColumns}
                              defaultSorted={[
                                {
                                  id: 'name',
                                  desc: false
                                }
                              ]}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${showErrorRecords}`}
                    id="errorrecords"
                    role="tabpanel"
                    aria-labelledby="errorrecords-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          Error Records
                        </h3>
                      </div>

                      {errorRecordsDetails !== undefined &&
                      errorRecordsDetails.length !== 0 ? (
                        <>
                          <div className="col-lg-6 col-md-6">
                            <div className="ymca-warning-text text-lg-end text-sm-start mt-4 me-4">
                              <img
                                className="img-fluid"
                                alt="warning-icon"
                                src={warningiconmedium}
                              />{' '}
                              <span>
                                User details with data discrepancies will not
                                proceed.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <Table
                              datarows={errorRecordsDetails}
                              headers={ErrorColumns}
                              selectedRows={selectedRowsChangeStaff}
                              defaultSorted={[
                                {
                                  id: 'name',
                                  desc: true
                                }
                              ]}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${showExistingRecords}`}
                    id="existingrecords-tab"
                    role="tabpanel"
                    aria-labelledby="existingrecords-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          Existing Users
                        </h3>
                      </div>

                      {existingRecordsDetails !== undefined &&
                      existingRecordsDetails.length !== 0 ? (
                        <>
                          <div className="col-lg-6 col-md-6">
                            <div className="ymca-warning-text text-lg-end text-sm-start mt-4 me-4">
                              <img
                                className="img-fluid"
                                alt="warning-icon"
                                src={warningiconmedium}
                              />{' '}
                              <span>
                                Already exist user details will not proceed.
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <Table
                              datarows={existingRecordsDetails}
                              headers={TableColumns}
                              defaultSorted={[
                                {
                                  id: 'name',
                                  desc: false
                                }
                              ]}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="ymca-form mt-3">
              <div className="container">
                <div className="form-group">
                  <div className="form-check ymca-check-box my-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      defaultChecked={checkBox}
                      onChange={handelCheckBox}
                    />
                    <span
                      className="form-check-label ymca-cachet-normal-dark-text"
                      htmlFor="flexCheckDefault"
                    >
                      {APP_LABELS.UPLOAD_DATA_CONFRIM}
                    </span>
                  </div>
                </div>
                <button
                  className="btn ymca-primary-outline-btn login-btn mt-0"
                  type="button"
                  onClick={redirectToDashBoard}
                >
                  {APP_LABELS.UPLOAD_DATA_CANCEL}
                </button>
                <button
                  onClick={postImportDataFile}
                  className="btn ms-3 ymca-primary-btn login-btn mt-0 custom-align"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#upload-success-modal"
                  disabled={
                    enableButton ||
                    UploadStaffData?.data?.segregatedList?.NewUsers?.length ===
                      0
                  }
                >
                  {APP_LABELS.UPLOAD_DATA_IMPORT_DATA}
                </button>
              </div>
            </section>
            {redirect && <Redirect to={redirectUrl} />}
          </div>
        </main>
      </BlockUi>

      {/* Success Modal */}
      <CustomModal
        show={showSuccessPopup}
        type={APP_LABELS.SUCCESS}
        message={showMessage}
        submitButtonName="Ok"
        onSubmit={redirectToDashBoard}
      />

      {/* Error Modal */}
      <ErrorPopup
        handleCloseErrorPopup={handleCloseErrorPopup}
        errorMessage={showMessage}
        showErrorPopup={showErrorPopup}
      />
    </>
  );
};

UploadData.propTypes = {
  handleImportData: PropTypes.func.isRequired,
  UploadStaffData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleImportData: getUserManagementImportData
};

const mapStateToProps = state => ({
  UploadStaffData: UserManagementUploadFileSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadData);
