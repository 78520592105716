/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  getBranchDetails,
  getBranchList,
  getCountriesList,
  saveBranchDetails
} from '@redux/directory-information/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import {
  BranchListSelector,
  BranchDetailsSelector
} from '@redux/directory-information/selectors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { APP_LABELS } from '@config/app-config';
import Help from '@modules/shared/components/reusable-components/help';
import CustomModal from '@utilities/CustomModal';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import AssociationDropdown from '../associaction-drop-down/index';
import LastUpdateName from '../last-update-name';
import CvoContactDetails from '../08-pledge-statement/cvoContactDetails/index';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const YMCADirectoryInformationTab = props => {
  const {
    LoginUserData,
    BranchList,
    status,
    handleGetBranchDetails,
    handleGetBranchList,
    handleToSetBranchDetails,
    setLoader,
    handleToGetCountries,
    handleGetAnnualReportsDashboard,
    BranchDetailsData
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'YMCA Directory Information';
  const getBranchIdFromOverview = sessionStorage.getItem('branchSelected');
  const [associonOptions, setAssocionOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedAsso, setSelectedAsso] = useState(null);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [selectedBranch, setSelectedBranch] = useState(
    getBranchIdFromOverview || null
  );
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [countriesResponse, setCountriesResponse] = useState(null);
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const [physicalAddressId, setPhysicalAddressId] = useState();
  const [mailingAddressId, setMailingAddressId] = useState();
  const [addressCheckboxValue, setAddressCheckboxValue] = useState(false);
  const [branchDetails, setBranchDetails] = useState(null);
  const [resStatus, setResStatus] = useState();

  const [activeTab, setActiveTab] = useState('ymca-directory-information');
  const [currentTab, setCurrentTab] = useState('');
  const [lastActiveTab, setLastActiveTab] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [keyValue, setKeyValue] = useState('');
  const [help, setHelp] = useState(false);
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const [instructions, setInstructions] = useState(false);

  useEffect(() => {
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))[
        'ymca-directory-information'
      ]?.Instruction
    );

    window.scroll(0, 0);
    setLoader(true);
    handleGetBranchList(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    )
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
    handleToGetCountries().then(result => {
      setCountriesResponse(result?.data?.data);
    });
    sessionStorage.removeItem('branchSelected');

    return () => {
      setSelectedAsso(null);
      setSelectedBranch(null);
    };
  }, []);

  const defaultInitialValues = {
    branchName: '',
    branchPhoneNumber: '',
    physicalAddress1: '',
    physicalAddress2: '',
    physicalCity: '',
    physicalState: '',
    physicalZipcode: '',
    physicalCountry: '',
    mailingAddress1: '',
    mailingAddress2: '',
    mailingCity: '',
    mailingState: '',
    mailingZipcode: '',
    mailingCountry: '',
    addressCheckbox: false
  };

  const handleGetYmcaDirectoryInfoDetails = successpopup => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetBranchDetails(selectedAsso, selectedBranch)
        .then(result => {
          setPhysicalAddressId(result?.data?.data?.physicalAddressId);
          setMailingAddressId(result?.data?.data?.mailingAddressId);
          if (
            result.data.data.mailingZipcode.trim().length === 6 &&
            result.data.data.mailingZipcode.trim().includes('-')
          ) {
            result.data.data.mailingZipcode = result?.data?.data?.mailingZipcode
              .replace('-', '')
              .trim();
          }

          if (
            result?.data?.data?.physicalZipcode.trim().length === 6 &&
            result?.data?.data?.physicalZipcode.trim().includes('-')
          ) {
            result.data.data.physicalZipcode =
              result?.data?.data?.physicalZipcode.replace('-', '').trim();
          }

          setBranchDetails(result?.data?.data);
          if (successpopup) {
            setSuccessPopup(true);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          setErrorPopup(true);
        });
      setLoader(true);
    }
  };

  const formik = useFormik({
    initialValues: branchDetails || defaultInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      branchName: Yup.string().nullable(),
      branchPhoneNumber: Yup.string()
        .nullable()
        .matches(
          /^(1\s?)?(\d{3}|\(\d{3}\))-?\d{3}-?\d{4}$/g,
          'Phone number must be valid'
        ),
      physicalAddress1: Yup.string().nullable(),
      physicalCity: Yup.string().nullable(),
      physicalCountry: Yup.string().nullable(),
      physicalState: Yup.string().nullable(),
      physicalZipcode: Yup.string()
        .nullable()
        .matches(/^((\d{5}(?:-\d{4})?$)|(\d{9}))*$/g, 'Zipcode must be valid'),
      mailingAddress1: Yup.string().nullable(),
      mailingCity: Yup.string().nullable(),
      mailingCountry: Yup.string().nullable(),
      mailingState: Yup.string().nullable(),
      mailingZipcode: Yup.string()
        .nullable()
        .matches(/^((\d{5}(?:-\d{4})?$)|(\d{9}))*$/g, 'Zipcode must be valid')
    }),

    onSubmit: values => {
      setSuccessPopup(false);
      setLoader(true);
      const data = { ...values };
      data.physicalAddressId = physicalAddressId;
      data.mailingAddressId = mailingAddressId;
      handleToSetBranchDetails(data)
        .then(res => {
          if (res?.data?.isSuccess) {
            setResStatus(res.data.data?.recordStatus);
            setLoader(true);
            handleGetAnnualReportsDashboard(
              associationNumber,
              selectedYear
            ).then(dashData => {
              dashData?.data?.data?.dashboardDTOs.forEach(record => {
                if (
                  record.name === sectionName &&
                  record.status === 'Completed' &&
                  record.rating === null &&
                  selectedYear >= '2023' &&
                  role !== APP_LABELS.YUSA_DATA_STEWARD &&
                  role !== APP_LABELS.YESS_DATA_STEWARD
                ) {
                  setFeedbackPopup(true);
                  setFormId(record?.formId);
                }
              });
            });
            sessionStorage.setItem('dirty', false);
            handleGetYmcaDirectoryInfoDetails(true);
            setLoader(false);
          } else {
            setLoader(false);
            setErrorMessage(res?.message);
            setErrorPopup(true);
          }
        })
        .catch(() => {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        });
    }
  });

  useEffect(() => {
    sessionStorage.setItem('dirty', formik.dirty);
  }, [formik.dirty]);

  useEffect(() => {
    formik.resetForm({ values: defaultInitialValues });
    handleGetYmcaDirectoryInfoDetails();
    if (activeTab === 'ymca-directory-information') {
      setKeyValue('ymca-directory-information');
    } else if (activeTab === 'cvocontactdetails-tab') {
      setKeyValue('current-and-incoming-cvo-information');
    }
  }, [selectedAsso, selectedBranch, activeTab]);

  useEffect(() => {
    if (
      mailingAddressId !== null &&
      physicalAddressId !== null &&
      mailingAddressId === physicalAddressId
    ) {
      setAddressCheckboxValue(true);
    } else setAddressCheckboxValue(false);
  }, [physicalAddressId, mailingAddressId]);

  const onPhysicalAddressChange = () => {
    setAddressCheckboxValue(false);
    setPhysicalAddressId(null);
  };

  useEffect(() => {
    const isEmpty = Object.keys(BranchList).length === 0;
    if (!isEmpty) {
      const assoNumber = get(BranchList, 'data.associationNumber');
      const assoName = get(BranchList, 'data.associationName');
      setAssocionOptions([{ id: assoNumber, value: assoName }]);
      setSelectedAsso(assoNumber);

      const branchList = get(BranchList, 'data.branches')?.map(branch => ({
        id: branch.branchNumber,
        value: branch.branchName
      }));
      setBranchOptions(branchList);
      if (
        !selectedBranch ||
        (selectedAsso !== null && assoNumber !== selectedAsso)
      )
        setSelectedBranch(get(BranchList, 'data.branches[0].branchNumber'));
    }
  }, [BranchList]);

  const countryChangeHandler = e => {
    onPhysicalAddressChange();
    if (e.target.name === 'physicalCountry') {
      setPhysicalAddressId(null);
    } else {
      setMailingAddressId(null);
    }
    const selectedCountry = e.target.value;
    const selectedStateType =
      e.target.name === 'physicalCountry' ? 'physicalState' : 'mailingState';

    formik.setFieldValue(e.target.name, selectedCountry);
    const findState = countriesResponse.find(
      element => element.name === selectedCountry
    );
    formik.setFieldValue(selectedStateType, findState.states[0].name);
  };

  const stateChangeHandler = e => {
    onPhysicalAddressChange();
    if (e.target.name === 'physicalState') {
      setPhysicalAddressId(null);
    } else {
      setMailingAddressId(null);
    }
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const getCountries = () => countriesResponse.map(obj => obj.name);

  const getStates = countryWithType => {
    const findState = countriesResponse.find(
      element => element.name === formik.values[countryWithType]
    );
    return findState.states.map(obj => obj.name);
  };

  const handleChangeCheckbox = () => {
    setAddressCheckboxValue(!addressCheckboxValue);

    if (!addressCheckboxValue) {
      formik.setFieldValue('mailingAddress1', formik.values.physicalAddress1);
      formik.setFieldValue('mailingAddress2', formik.values.physicalAddress2);
      formik.setFieldValue('mailingCity', formik.values.physicalCity);
      formik.setFieldValue('mailingCountry', formik.values.physicalCountry);
      formik.setFieldValue('mailingState', formik.values.physicalState);
      formik.setFieldValue('mailingZipcode', formik.values.physicalZipcode);
      setMailingAddressId(physicalAddressId);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  const getClassNames = (errorsValue, touchedValue, checkboxValue) => {
    let className = 'ymca-fieldSet';

    if (errorsValue && touchedValue) {
      className = 'ymca-fieldSet invalid';
    } else if (checkboxValue) {
      className = 'ymca-fieldSet ymca-fieldDisabled';
    }
    return className;
  };

  const onActive = value => {
    if (value !== activeTab && sessionStorage.getItem('dirty') === 'true') {
      setShowPopup(true);
    } else {
      setActiveTab(value);
    }
    setCurrentTab(value);
    setLastActiveTab(activeTab);
  };

  const handleOk = () => {
    setActiveTab(currentTab);
    setShowPopup(false);
  };

  const handleCancel = () => {
    const activetab = lastActiveTab;
    document.getElementById(activetab).click();
    setActiveTab(activetab);
    setShowPopup(false);
  };

  const getErrorClass = (errors, touched) => {
    if (errors && touched) {
      return 'ymca-fieldSet invalid';
    }
    return 'ymca-fieldSet';
  };
  const getSelectClassName = disabled => {
    if (disabled) {
      return 'ymca-select ymca-customArrow ymca-fieldDisabled';
    }
    return 'ymca-select ymca-customArrow';
  };
  return (
    <>
      <div className="ymca-completeBox">
        <button
          type="button"
          className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
          onClick={handleShowHelp}
        >
          HELP{/* */}
          <img src={helpicon} className="img-fluid" alt="caret-icon" />
        </button>

        <div className="ymca-directorInfo ymca-collabration">
          <ul className="nav nav-tabs ymca-navTabs" id="myTab" role="tablist">
            {selectedYear !== '2022' && (
              <li className="nav-item ymca-navItems" role="tab">
                <button
                  className="nav-link ymca-navLinks active"
                  id="ymca-directory-information"
                  data-bs-toggle="tab"
                  data-bs-target="#ymcaDirectoryInformation"
                  type="button"
                  role="tab"
                  aria-controls="ymcaDirectoryInformation"
                  aria-selected="true"
                  onClick={() => {
                    onActive('ymca-directory-information');
                  }}
                >
                  Association Details
                </button>
              </li>
            )}
            {selectedYear !== '2022' &&
              role !== APP_LABELS.BRANCH_DATA_STEWARD && (
                <li className="nav-item ymca-navItems" role="tab">
                  <button
                    className="nav-link ymca-navLinks"
                    id="cvocontactdetails-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#cvocontactdetails"
                    type="button"
                    role="tab"
                    aria-controls="cvocontactdetails"
                    aria-selected="false"
                    onClick={() => {
                      onActive('cvocontactdetails-tab');
                    }}
                  >
                    Current & Incoming CVO Information
                  </button>
                </li>
              )}
          </ul>
          <div
            className="tab-pane ymca-tabPane fade show active"
            id="ymcaDirectoryInformation"
            role="tabpanel"
            aria-labelledby="ymca-directory-information"
          >
            {activeTab === 'ymca-directory-information' && (
              <form onSubmit={formik.handleSubmit}>
                <div className="ymca-completeBox">
                  <div className="ymca-directorInfo">
                    <div className="tab-content" id="myTabContent">
                      <div className="ymca-program ymca-bgPower pb-0">
                        <div className="row pb-3">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h4 className="ymca-programActivities ms-0">
                              {APP_LABELS.DETAIL_ASSOCIATION_DETAIL}
                            </h4>
                          </div>
                          <AssociationDropdown
                            setSelectedAsso={setSelectedAsso}
                            selectedAsso={selectedAsso}
                            associonOptions={associonOptions}
                            setSelectedBranch={setSelectedBranch}
                            selectedBranch={selectedBranch}
                            branchOptions={branchOptions}
                          />
                        </div>
                      </div>

                      <div className="ymca-program mb-4">
                        <div className="row">
                          {/* instructions box  */}
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                            <div className="ymca-instructionBoxes">
                              <h4 className="ymca-instructHeadline">
                                {APP_LABELS.INSTRUCTIONS}
                              </h4>
                              <p className="ymca-instructTitle">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: instructions
                                  }}
                                />
                              </p>
                            </div>
                          </div>

                          {BranchDetailsData?.data?.recordStatus?.toUpperCase() ===
                            APP_LABELS.STATUS_PARTIAL && (
                            <PartialSaveWarningPopup />
                          )}

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h4 className="ymca-programActivities ms-0">
                              Association or Branch Information
                            </h4>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText">
                                Name
                              </div>
                              <input
                                name="branchName"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.branchName}
                                className="ymca-fieldSet ymca-fieldDisabled"
                                disabled
                              />
                              {formik.touched.branchName &&
                              formik.errors.branchName ? (
                                <div className="errorNote">
                                  {formik.errors.branchName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory ">
                                Phone Number
                              </div>
                              <input
                                name="branchPhoneNumber"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.branchPhoneNumber}
                                className={
                                  selectedYear === '2022'
                                    ? getErrorClass(
                                        formik.errors.branchPhoneNumber,
                                        formik.touched.branchPhoneNumber
                                      )
                                    : 'ymca-fieldSet ymca-fieldDisabled'
                                }
                              />
                              {formik.touched.branchPhoneNumber &&
                              formik.errors.branchPhoneNumber ? (
                                <div className="errorNote">
                                  {formik.errors.branchPhoneNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                            <h4 className="ymca-programActivities ms-0 mt-1">
                              Physical address
                            </h4>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                Address 1
                              </div>
                              <input
                                name="physicalAddress1"
                                type="text"
                                onKeyUp={onPhysicalAddressChange}
                                onKeyDown={() => setPhysicalAddressId(null)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.physicalAddress1}
                                className={
                                  selectedYear >= '2023'
                                    ? 'ymca-fieldSet ymca-fieldDisabled'
                                    : 'ymca-fieldSet'
                                }
                                maxLength={500}
                              />
                              {formik.touched.physicalAddress1 &&
                              formik.errors.physicalAddress1 ? (
                                <div className="errorNote">
                                  {formik.errors.physicalAddress1}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText">
                                Address 2
                              </div>
                              <input
                                name="physicalAddress2"
                                type="text"
                                onKeyUp={onPhysicalAddressChange}
                                onKeyDown={() => setPhysicalAddressId(null)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={
                                  formik?.values?.physicalAddress2
                                    ? formik?.values?.physicalAddress2
                                    : ''
                                }
                                className={
                                  selectedYear >= '2023'
                                    ? 'ymca-fieldSet ymca-fieldDisabled'
                                    : 'ymca-fieldSet'
                                }
                                maxLength={500}
                              />
                              {formik.touched.physicalAddress2 &&
                              formik.errors.physicalAddress2 ? (
                                <div className="errorNote">
                                  {formik.errors.physicalAddress2}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                City
                              </div>
                              <input
                                name="physicalCity"
                                type="text"
                                onKeyUp={onPhysicalAddressChange}
                                onKeyDown={() => setPhysicalAddressId(null)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.physicalCity}
                                className={
                                  selectedYear >= '2023'
                                    ? 'ymca-fieldSet ymca-fieldDisabled'
                                    : 'ymca-fieldSet'
                                }
                                maxLength={100}
                              />
                              {formik.touched.physicalCity &&
                              formik.errors.physicalCity ? (
                                <div className="errorNote">
                                  {formik.errors.physicalCity}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                Country
                              </div>
                              <select
                                name="physicalCountry"
                                className={
                                  selectedYear >= '2023'
                                    ? 'ymca-select ymca-customArrow notranslate ymca-fieldDisabled'
                                    : 'ymca-select ymca-customArrow notranslate'
                                }
                                onChange={countryChangeHandler}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.physicalCountry}
                              >
                                {countriesResponse &&
                                  getCountries().map(data => (
                                    <option key={data} value={data}>
                                      {data}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.physicalCountry &&
                              formik.errors.physicalCountry ? (
                                <div className="errorNote">
                                  {formik.errors.physicalCountry}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                State
                              </div>
                              <select
                                name="physicalState"
                                className={
                                  selectedYear >= '2023'
                                    ? 'ymca-select ymca-customArrow notranslate ymca-fieldDisabled'
                                    : 'ymca-select ymca-customArrow notranslate'
                                }
                                onChange={stateChangeHandler}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.physicalState}
                              >
                                {countriesResponse &&
                                  formik?.values?.physicalCountry &&
                                  getStates('physicalCountry').map(data => (
                                    <option key={data} value={data}>
                                      {data}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.physicalState &&
                              formik.errors.physicalState ? (
                                <div className="errorNote">
                                  {formik.errors.physicalState}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                Zipcode
                              </div>
                              <input
                                name="physicalZipcode"
                                type="text"
                                onKeyUp={onPhysicalAddressChange}
                                onKeyDown={() => setPhysicalAddressId(null)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.physicalZipcode}
                                className={
                                  selectedYear === '2022'
                                    ? getErrorClass(
                                        formik.errors.physicalZipcode,
                                        formik.touched.physicalZipcode
                                      )
                                    : 'ymca-fieldSet ymca-fieldDisabled'
                                }
                              />
                              {formik.touched.physicalZipcode &&
                              formik.errors.physicalZipcode ? (
                                <div className="errorNote">
                                  {formik.errors.physicalZipcode}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="d-flex ymca-listingERA mt-4">
                            <input
                              className={
                                selectedYear >= '2023'
                                  ? 'styled-checkbox ymca-fieldDisabled'
                                  : ' styled-checkbox'
                              }
                              disabled={selectedYear >= '2023'}
                              name="addressCheckbox"
                              type="checkbox"
                              id="addressCheckbox"
                              checked={addressCheckboxValue}
                              onChange={handleChangeCheckbox}
                            />
                            <label
                              htmlFor="addressCheckbox"
                              className="ymca-cachet-normal-dark-text ms-2"
                            >
                              Same as above Physical Address
                            </label>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                            <h4 className="ymca-programActivities ms-0 mt-1">
                              Mailing address
                            </h4>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                Address 1
                              </div>
                              <input
                                name="mailingAddress1"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.mailingAddress1}
                                onKeyDown={() => setMailingAddressId(null)}
                                className={
                                  selectedYear === '2022'
                                    ? getClassNames(
                                        formik.errors.mailingAddress1,
                                        formik.touched.mailingAddress2,
                                        addressCheckboxValue
                                      )
                                    : 'ymca-fieldSet ymca-fieldDisabled'
                                }
                                disabled={addressCheckboxValue}
                                maxLength={500}
                              />
                              {formik.touched.mailingAddress1 &&
                              formik.errors.mailingAddress1 ? (
                                <div className="errorNote">
                                  {formik.errors.mailingAddress1}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText ">
                                Address 2
                              </div>
                              <input
                                name="mailingAddress2"
                                type="text"
                                onChange={formik.handleChange}
                                onKeyDown={() => setMailingAddressId(null)}
                                onBlur={formik.handleBlur}
                                value={
                                  formik?.values?.mailingAddress2
                                    ? formik?.values?.mailingAddress2
                                    : ''
                                }
                                className={
                                  selectedYear === '2022'
                                    ? getClassNames(
                                        formik.errors.mailingAddress2,
                                        formik.touched.mailingAddress2,
                                        addressCheckboxValue
                                      )
                                    : 'ymca-fieldSet ymca-fieldDisabled'
                                }
                                disabled={addressCheckboxValue}
                                maxLength={500}
                              />
                              {formik.touched.mailingAddress2 &&
                              formik.errors.mailingAddress2 ? (
                                <div className="errorNote">
                                  {formik.errors.mailingAddress2}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                City
                              </div>
                              <input
                                name="mailingCity"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.mailingCity}
                                onKeyDown={() => setMailingAddressId(null)}
                                className={
                                  selectedYear === '2022'
                                    ? getClassNames(
                                        formik.errors.mailingCity,
                                        formik.touched.mailingCity,
                                        addressCheckboxValue
                                      )
                                    : 'ymca-fieldSet ymca-fieldDisabled'
                                }
                                disabled={addressCheckboxValue}
                                maxLength={100}
                              />
                              {formik.touched.mailingCity &&
                              formik.errors.mailingCity ? (
                                <div className="errorNote">
                                  {formik.errors.mailingCity}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                Country
                              </div>
                              <select
                                name="mailingCountry"
                                className={
                                  selectedYear === '2022'
                                    ? getSelectClassName(addressCheckboxValue)
                                    : 'ymca-select ymca-customArrow ymca-fieldDisabled'
                                }
                                onChange={countryChangeHandler}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.mailingCountry}
                                disabled={addressCheckboxValue}
                              >
                                {countriesResponse &&
                                  getCountries().map(data => (
                                    <option key={data} value={data}>
                                      {data}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.mailingCountry &&
                              formik.errors.mailingCountry ? (
                                <div className="errorNote">
                                  {formik.errors.mailingCountry}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                State
                              </div>
                              <select
                                name="mailingState"
                                className={
                                  selectedYear === '2022'
                                    ? getSelectClassName(addressCheckboxValue)
                                    : 'ymca-select ymca-customArrow ymca-fieldDisabled'
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.mailingState}
                                disabled={addressCheckboxValue}
                              >
                                {countriesResponse &&
                                  formik?.values?.mailingCountry &&
                                  getStates('mailingCountry').map(data => (
                                    <option key={data} value={data}>
                                      {data}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.mailingState &&
                              formik.errors.mailingState ? (
                                <div className="errorNote">
                                  {formik.errors.mailingState}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="ymca-selectWrap">
                              <div className="ymca-labelling ymca-dullText txt-mandatory">
                                Zipcode
                              </div>
                              <input
                                name="mailingZipcode"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.mailingZipcode}
                                onKeyDown={() => setMailingAddressId(null)}
                                className={
                                  selectedYear === '2022'
                                    ? getClassNames(
                                        formik.errors.mailingZipcode,
                                        formik.touched.mailingZipcode,
                                        addressCheckboxValue
                                      )
                                    : 'ymca-fieldSet ymca-fieldDisabled'
                                }
                                disabled={addressCheckboxValue}
                              />
                              {formik.touched.mailingZipcode &&
                              formik.errors.mailingZipcode ? (
                                <div className="errorNote">
                                  {formik.errors.mailingZipcode}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ymca-submitHighlighter">
                    <LastUpdateName
                      Username={formik?.values?.updatedByName}
                      Time={formik?.values?.updatedDatetime}
                    />
                  </div>
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
                  selectedYear < '2023' && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightTitle float-end"
                      onClick={() => scrollToErrors(formik?.errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </form>
            )}
          </div>
          {selectedYear !== '2022' && (
            <div
              className={`${
                role !== APP_LABELS.CEO_DATA_STEWARD &&
                role !== APP_LABELS.CVO_DATA_STEWARD &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.ASSOCIATION_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
                  ? 'disable-input-role no-cursor'
                  : ''
              } tab-pane ymca-tabPaneInner fade `}
              id="cvocontactdetails"
              role="tabpanel"
              aria-labelledby="cvocontactdetails"
            >
              {activeTab === 'cvocontactdetails-tab' && (
                <CvoContactDetails setLoader={setLoader} status={status} />
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        {successPopup && <SuccessToaster Status={resStatus} />}

        <ErrorPopup
          handleCloseErrorPopup={() => {
            setErrorPopup(false);
          }}
          errorMessage={errorMessage}
          showErrorPopup={errorPopup}
        />

        <FeedbackModal
          handleCloseFeedbackPopup={() => {
            setFeedbackPopup(false);
          }}
          showFeedbackPopup={feedbackPopup}
          sectionName={sectionName}
          selectedYear={selectedYear}
          associationNumber={associationNumber}
          formId={formId}
        />

        {help && <Help hidePopup={hideShowHelp} keyValue={keyValue} />}

        <CustomModal
          show={showPopup}
          type="Warning!"
          message="You have some unsaved changes. If you leave before saving, your
                changes will be lost."
          submitButtonName="Ok"
          onSubmit={handleOk}
          cancelButtonName="Cancel"
          onCancel={handleCancel}
        />
      </div>
    </>
  );
};

YMCADirectoryInformationTab.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  BranchList: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  handleGetBranchDetails: PropTypes.func.isRequired,
  handleGetBranchList: PropTypes.func.isRequired,
  handleToSetBranchDetails: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  handleToGetCountries: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  BranchDetailsData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  BranchList: BranchListSelector(state),
  BranchDetailsData: BranchDetailsSelector(state)
});

const mapDispatchToProps = {
  handleGetBranchDetails: getBranchDetails,
  handleGetBranchList: getBranchList,
  handleToSetBranchDetails: saveBranchDetails,
  handleToGetCountries: getCountriesList,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(YMCADirectoryInformationTab));
