export const UserRegistrationSelector = ({
  userRegistrationStore: { UserRegistrationData }
}) => UserRegistrationData;

export const UserRegistrationDetailsSelector = ({
  userRegistrationDetailsStore: { UserRegistrationDetails }
}) => UserRegistrationDetails;

export const ModuleListSelector = ({ moduleListStore: { ModuleList } }) =>
  ModuleList;
