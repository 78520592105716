/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import {
  getMemberDemographics,
  postMemberDemographics
} from '@redux/constituency-report/member-demographics/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetMembershipsSelector } from '@redux/constituency-report/memberships/selectors';
import { GetMemberDemographicsSelector } from '@redux/constituency-report/member-demographics/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import TextError from '@modules/shared/components/reusable-components/text-error';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const FacilityMembershipInformationInput = () => (
  <div className="ymca-program pt-0 mt-4">
    <h6 className="ymca-programActivities p-0">
      {`Please self-report your facility membership information below. If you do not offer facility memberships, please select the checkbox below and skip to the Program Members Section.`}
    </h6>
    <div className="d-flex ymca-listingERA mt-4">
      <Field
        className="styled-checkbox"
        name="doNotOfferFacilityMemberships"
        type="checkbox"
      />
      <label
        htmlFor="doNotOfferFacilityMemberships"
        className="ymca-cachet-normal-dark-text ms-2"
      >
        I do not offer facility memberships.
      </label>
    </div>
  </div>
);

const MembersDemographicsTab = props => {
  const {
    setLoader,
    LoginUserData,
    handleGetMemberDemographics,
    handlePostMemberDemographics,
    GetMembershipsSelectorData,
    GetMemberDemographicsSelectorData,
    handleGetAnnualReportsDashboard,
    status
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Constituency Report';
  const formikref = useRef();
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [resStatus, setResStatus] = useState();

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationsNum =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', '0');

  const handelGetCall = success => {
    setLoader(true);

    handleGetMemberDemographics(associationsNum).then(res => {
      if (res.status === 200) {
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))[
        'members-demographics'
      ]?.Instruction
    );
  }, []);

  const initialValues = {
    doNotOfferFacilityMemberships: false,
    nativeAmericanAlaskanNative: '',
    nativeAmericanAlaskanNativeNotCollected: false,

    asian: '',
    asianNotCollected: false,

    blackOrAfricanAmerican: '',
    blackOrAfricanAmericanNotCollected: false,

    hispanicLatino: '',
    hispanicLatinoNotCollected: false,

    middleEastNorthAfrican: '',
    middleEastNorthAfricanNotCollected: false,

    nativeHawaiOtherPacificIslander: '',
    nativeHawaiOtherPacificIslanderNotCollected: false,

    white: '',
    whiteNotCollected: false,

    twoOrMoreRaces: '',
    twoOrMoreRacesNotCollected: false,

    other: '',
    otherNotCollected: false,

    additionalIdentities: '',
    additionalIdentitiesNotCollected: false,

    unknown: '',
    unknownNotCollected: false,

    total: ''
  };

  const editValues = {
    doNotOfferFacilityMemberships:
      GetMemberDemographicsSelectorData?.data?.doNotOfferFacilityMemberships,

    nativeAmericanAlaskanNative:
      GetMemberDemographicsSelectorData?.data?.nativeAmericanAlaskanNative ===
      null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.nativeAmericanAlaskanNative,
    nativeAmericanAlaskanNativeNotCollected:
      GetMemberDemographicsSelectorData?.data
        ?.nativeAmericanAlaskanNativeNotCollected,

    asian:
      GetMemberDemographicsSelectorData?.data?.asian === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.asian,
    asianNotCollected:
      GetMemberDemographicsSelectorData?.data?.asianNotCollected,

    blackOrAfricanAmerican:
      GetMemberDemographicsSelectorData?.data?.blackOrAfricanAmerican === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.blackOrAfricanAmerican,
    blackOrAfricanAmericanNotCollected:
      GetMemberDemographicsSelectorData?.data
        ?.blackOrAfricanAmericanNotCollected,

    hispanicLatino:
      GetMemberDemographicsSelectorData?.data?.hispanicLatino === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.hispanicLatino,
    hispanicLatinoNotCollected:
      GetMemberDemographicsSelectorData?.data?.hispanicLatinoNotCollected,

    middleEastNorthAfrican:
      GetMemberDemographicsSelectorData?.data?.middleEastNorthAfrican === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.middleEastNorthAfrican,
    middleEastNorthAfricanNotCollected:
      GetMemberDemographicsSelectorData?.data
        ?.middleEastNorthAfricanNotCollected,

    nativeHawaiOtherPacificIslander:
      GetMemberDemographicsSelectorData?.data
        ?.nativeHawaiOtherPacificIslander === null
        ? ''
        : GetMemberDemographicsSelectorData?.data
            ?.nativeHawaiOtherPacificIslander,
    nativeHawaiOtherPacificIslanderNotCollected:
      GetMemberDemographicsSelectorData?.data
        ?.nativeHawaiOtherPacificIslanderNotCollected,

    white:
      GetMemberDemographicsSelectorData?.data?.white === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.white,
    whiteNotCollected:
      GetMemberDemographicsSelectorData?.data?.whiteNotCollected,

    twoOrMoreRaces:
      GetMemberDemographicsSelectorData?.data?.twoOrMoreRaces === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.twoOrMoreRaces,
    twoOrMoreRacesNotCollected:
      GetMemberDemographicsSelectorData?.data?.twoOrMoreRacesNotCollected,

    other:
      GetMemberDemographicsSelectorData?.data?.other === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.other,
    otherNotCollected:
      GetMemberDemographicsSelectorData?.data?.otherNotCollected,

    additionalIdentities:
      GetMemberDemographicsSelectorData?.data?.additionalIdentities === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.additionalIdentities,
    additionalIdentitiesNotCollected:
      GetMemberDemographicsSelectorData?.data?.additionalIdentitiesNotCollected,

    unknown:
      GetMemberDemographicsSelectorData?.data?.unknown === null
        ? ''
        : GetMemberDemographicsSelectorData?.data?.unknown,
    unknownNotCollected:
      GetMemberDemographicsSelectorData?.data?.unknownNotCollected,

    total: ''
  };

  const validationSchema = Yup.object({
    nativeAmericanAlaskanNative: Yup.string().nullable(),

    asian: Yup.string().nullable(),
    blackOrAfricanAmerican: Yup.string().nullable(),
    hispanicLatino: Yup.string().nullable(),
    middleEastNorthAfrican: Yup.string().nullable(),
    nativeHawaiOtherPacificIslander: Yup.string().nullable(),
    white: Yup.string().nullable(),
    twoOrMoreRaces: Yup.string().nullable(),
    unknown: Yup.string().nullable(),
    other: Yup.string().nullable(),
    additionalIdentities: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? Number(value) : null);

  function handelTotalMemberDemographics(MemberDemographicsValue) {
    const total =
      MemberDemographicsValue?.nativeAmericanAlaskanNative +
      MemberDemographicsValue?.asian +
      MemberDemographicsValue?.blackOrAfricanAmerican +
      MemberDemographicsValue?.hispanicLatino +
      MemberDemographicsValue?.middleEastNorthAfrican +
      MemberDemographicsValue?.nativeHawaiOtherPacificIslander +
      MemberDemographicsValue?.white +
      MemberDemographicsValue?.twoOrMoreRaces +
      MemberDemographicsValue?.other +
      MemberDemographicsValue?.additionalIdentities +
      MemberDemographicsValue?.unknown;

    return total;
  }

  const TotalMembersDemographics = handelTotalMemberDemographics(
    GetMemberDemographicsSelectorData?.data
  );

  let membersTotal =
    Number(GetMembershipsSelectorData?.data?.male1To5) +
    Number(GetMembershipsSelectorData?.data?.female1To5) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentities1To5) +
    Number(GetMembershipsSelectorData?.data?.unknown1To5) +
    Number(GetMembershipsSelectorData?.data?.male6To11) +
    Number(GetMembershipsSelectorData?.data?.female6To11) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentities6To11) +
    Number(GetMembershipsSelectorData?.data?.unknown6To11) +
    Number(GetMembershipsSelectorData?.data?.male12To17) +
    Number(GetMembershipsSelectorData?.data?.female12To17) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentities12To17) +
    Number(GetMembershipsSelectorData?.data?.unknown12To17) +
    Number(GetMembershipsSelectorData?.data?.male18To29) +
    Number(GetMembershipsSelectorData?.data?.female18To29) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentities18To29) +
    Number(GetMembershipsSelectorData?.data?.unknown18To29) +
    Number(GetMembershipsSelectorData?.data?.male30To54) +
    Number(GetMembershipsSelectorData?.data?.female30To54) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentities30To54) +
    Number(GetMembershipsSelectorData?.data?.unknown30To54) +
    Number(GetMembershipsSelectorData?.data?.male55To64) +
    Number(GetMembershipsSelectorData?.data?.female55To64) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentities55To64) +
    Number(GetMembershipsSelectorData?.data?.unknown55To64) +
    Number(GetMembershipsSelectorData?.data?.male65Plus) +
    Number(GetMembershipsSelectorData?.data?.female65Plus) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentities65Plus) +
    Number(GetMembershipsSelectorData?.data?.unknown65Plus) +
    Number(GetMembershipsSelectorData?.data?.maleUnknown) +
    Number(GetMembershipsSelectorData?.data?.femaleUnknown) +
    Number(GetMembershipsSelectorData?.data?.additionalIdentitiesUnknown) +
    Number(GetMembershipsSelectorData?.data?.unknownUnknown);

  if (selectedYear === '2022') {
    membersTotal -=
      GetMembershipsSelectorData?.maleUnknown +
      GetMembershipsSelectorData?.femaleUnknown +
      GetMembershipsSelectorData?.additionalIdentitiesUnknown +
      GetMembershipsSelectorData?.unknownUnknown;
  }

  const onSubmit = values => {
    setLoader(true);

    let payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetMemberDemographicsSelectorData?.data?.createdByName,
      createdByPersonId:
        GetMemberDemographicsSelectorData?.data?.createdByPersonId,
      createdDatetime: GetMemberDemographicsSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      memberDemographicId:
        GetMemberDemographicsSelectorData?.data?.memberDemographicId,
      reportBatchId: GetMemberDemographicsSelectorData?.data?.reportBatchId,
      recordStatus: GetMemberDemographicsSelectorData?.data?.recordStatus,
      doNotOfferFacilityMemberships: values.doNotOfferFacilityMemberships,

      nativeAmericanAlaskanNative:
        values.nativeAmericanAlaskanNativeNotCollected ||
        values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.nativeAmericanAlaskanNative),
      nativeAmericanAlaskanNativeNotCollected:
        values.doNotOfferFacilityMemberships
          ? false
          : values.nativeAmericanAlaskanNativeNotCollected,

      asian:
        values.asianNotCollected || values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.asian),
      asianNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.asianNotCollected,

      blackOrAfricanAmerican:
        values.blackOrAfricanAmericanNotCollected ||
        values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.blackOrAfricanAmerican),
      blackOrAfricanAmericanNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.blackOrAfricanAmericanNotCollected,

      hispanicLatino:
        values.hispanicLatinoNotCollected ||
        values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.hispanicLatino),
      hispanicLatinoNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.hispanicLatinoNotCollected,

      middleEastNorthAfrican:
        values.middleEastNorthAfricanNotCollected ||
        values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.middleEastNorthAfrican),
      middleEastNorthAfricanNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.middleEastNorthAfricanNotCollected,

      nativeHawaiOtherPacificIslander:
        values.nativeHawaiOtherPacificIslanderNotCollected ||
        values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.nativeHawaiOtherPacificIslander),
      nativeHawaiOtherPacificIslanderNotCollected:
        values.doNotOfferFacilityMemberships
          ? false
          : values.nativeHawaiOtherPacificIslanderNotCollected,

      white:
        values.whiteNotCollected || values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.white),
      whiteNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.whiteNotCollected,

      twoOrMoreRaces:
        values.twoOrMoreRacesNotCollected ||
        values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.twoOrMoreRaces),
      twoOrMoreRacesNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.twoOrMoreRacesNotCollected,

      other:
        values.otherNotCollected || values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.other),
      otherNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.otherNotCollected,

      additionalIdentities:
        values.additionalIdentitiesNotCollected ||
        values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.additionalIdentities),
      additionalIdentitiesNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.additionalIdentitiesNotCollected,

      unknown:
        values.unknownNotCollected || values.doNotOfferFacilityMemberships
          ? null
          : convertedValue(values.unknown),
      unknownNotCollected: values.doNotOfferFacilityMemberships
        ? false
        : values.unknownNotCollected,

      totalMembersCount: membersTotal
    };

    const totalMembersDemographics = handelTotalMemberDemographics(payloadBody);

    payloadBody = {
      ...payloadBody,
      totalMembersDemographicsCount: totalMembersDemographics
    };
    setSuccessPopup(false);
    handlePostMemberDemographics(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationsNum, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall(true);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const totalCount = (
    value1 = 0,
    value2 = 0,
    value3 = 0,
    value4 = 0,
    value5 = 0,
    value6 = 0,
    value7 = 0,
    value8 = 0,
    value9 = 0,
    value10 = 0,
    value11 = 0
  ) =>
    Number(value1) +
    Number(value2) +
    Number(value3) +
    Number(value4) +
    Number(value5) +
    Number(value6) +
    Number(value7) +
    Number(value8) +
    Number(value9) +
    Number(value10) +
    Number(value11);

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  const getClassNames = (errorsValue, touchedValue, checkboxValue) => {
    let className = 'ymca-field';

    if (errorsValue && touchedValue) {
      className = 'ymca-field invalid';
    } else if (checkboxValue) {
      className = 'ymca-field ymca-fieldDisabled';
    }
    return className;
  };

  const note = () => (
    <div className="d-flex flex-column">
      {Number.isNaN(TotalMembersDemographics) === false &&
        Number.isNaN(membersTotal) === false &&
        membersTotal !== TotalMembersDemographics && (
          <div className="errorNote position-static fw-bold">
            * Total count of Facility Members and Facility Members Demographics
            must be equal.
          </div>
        )}
    </div>
  );

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP {/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>

      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 pb-3">
        <div className="ymca-instructionBoxes">
          <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
          <p className="ymca-instructTitle">
            <div
              dangerouslySetInnerHTML={{
                __html: instructions
              }}
            />
          </p>
        </div>
      </div>

      {GetMemberDemographicsSelectorData?.data?.recordStatus?.toUpperCase() ===
        APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              {FacilityMembershipInformationInput()}

              {!values.doNotOfferFacilityMemberships && (
                <div className="ymca-covidRelif ps-0 pt-0">
                  <div className="ymca-tableResponsive">
                    <span className="ymca-program p-0">
                      <h6 className="ymca-programActivities">
                        Please click in the checkbox if this race/ethnic
                        category is not collected
                      </h6>
                    </span>

                    <table className="table ymca-table table-striped ymca-autoLength">
                      <thead>
                        <tr className="ymca-cuttingData">
                          <th>&nbsp;</th>
                          <th>No. of Members</th>
                          <th>Not Collected</th>
                          <th className="ymca-defaultSpace">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody className="ymca-cuttingBody ymca-lengths">
                        <tr>
                          <td className="txt-mandatory">Asian</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="asian"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={
                                // eslint-disable-next-line no-nested-ternary
                                getClassNames(
                                  errors.asian,
                                  touched.asian,
                                  values.asianNotCollected
                                )
                              }
                              onChange={e =>
                                handleChange(e, setFieldValue, 'asian')
                              }
                              value={
                                values.asianNotCollected ? '' : values.asian
                              }
                              disabled={values.asianNotCollected}
                            />
                            <ErrorMessage component={TextError} name="asian" />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field name="asianNotCollected" type="checkbox" />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Black or African American
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="blackOrAfricanAmerican"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.blackOrAfricanAmerican,
                                touched.blackOrAfricanAmerican,
                                values.blackOrAfricanAmericanNotCollected
                              )}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'blackOrAfricanAmerican'
                                )
                              }
                              value={
                                values.blackOrAfricanAmericanNotCollected
                                  ? ''
                                  : values.blackOrAfricanAmerican
                              }
                              disabled={
                                values.blackOrAfricanAmericanNotCollected
                              }
                            />
                            <ErrorMessage
                              component={TextError}
                              name="blackOrAfricanAmerican"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="blackOrAfricanAmericanNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">Hispanic / Latino</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="hispanicLatino"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.hispanicLatino,
                                touched.hispanicLatino,
                                values.hispanicLatinoNotCollected
                              )}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'hispanicLatino')
                              }
                              value={
                                values.hispanicLatinoNotCollected
                                  ? ''
                                  : values.hispanicLatino
                              }
                              disabled={values.hispanicLatinoNotCollected}
                            />
                            <ErrorMessage
                              component={TextError}
                              name="hispanicLatino"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="hispanicLatinoNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Middle Eastern or Northern African
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="middleEastNorthAfrican"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.middleEastNorthAfrican,
                                touched.middleEastNorthAfrican,
                                values.middleEastNorthAfricanNotCollected
                              )}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'middleEastNorthAfrican'
                                )
                              }
                              value={
                                values.middleEastNorthAfricanNotCollected
                                  ? ''
                                  : values.middleEastNorthAfrican
                              }
                              disabled={
                                values.middleEastNorthAfricanNotCollected
                              }
                            />
                            <ErrorMessage
                              component={TextError}
                              name="middleEastNorthAfrican"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="middleEastNorthAfricanNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Native American or Alaskan Native
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="nativeAmericanAlaskanNative"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.nativeAmericanAlaskanNative,
                                touched.nativeAmericanAlaskanNative,
                                values.nativeAmericanAlaskanNativeNotCollected
                              )}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'nativeAmericanAlaskanNative'
                                )
                              }
                              value={
                                values.nativeAmericanAlaskanNativeNotCollected
                                  ? ''
                                  : values.nativeAmericanAlaskanNative
                              }
                              disabled={
                                values.nativeAmericanAlaskanNativeNotCollected
                              }
                            />
                            <ErrorMessage
                              component={TextError}
                              name="nativeAmericanAlaskanNative"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="nativeAmericanAlaskanNativeNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Native Hawaiian or Other Pacific Islander
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="nativeHawaiOtherPacificIslander"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.nativeHawaiOtherPacificIslander,
                                touched.nativeHawaiOtherPacificIslander,
                                values.nativeHawaiOtherPacificIslanderNotCollected
                              )}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'nativeHawaiOtherPacificIslander'
                                )
                              }
                              value={
                                values.nativeHawaiOtherPacificIslanderNotCollected
                                  ? ''
                                  : values.nativeHawaiOtherPacificIslander
                              }
                              disabled={
                                values.nativeHawaiOtherPacificIslanderNotCollected
                              }
                            />
                            <ErrorMessage
                              component={TextError}
                              name="nativeHawaiOtherPacificIslander"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="nativeHawaiOtherPacificIslanderNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">White</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="white"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.white,
                                touched.white,
                                values.whiteNotCollected
                              )}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'white')
                              }
                              value={
                                values.whiteNotCollected ? '' : values.white
                              }
                              disabled={values.whiteNotCollected}
                            />
                            <ErrorMessage component={TextError} name="white" />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field name="whiteNotCollected" type="checkbox" />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Two or More Races / Ethnicities
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="twoOrMoreRaces"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.twoOrMoreRaces,
                                touched.twoOrMoreRaces,
                                values.twoOrMoreRacesNotCollected
                              )}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'twoOrMoreRaces')
                              }
                              value={
                                values.twoOrMoreRacesNotCollected
                                  ? ''
                                  : values.twoOrMoreRaces
                              }
                              disabled={values.twoOrMoreRacesNotCollected}
                            />
                            <ErrorMessage
                              component={TextError}
                              name="twoOrMoreRaces"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="twoOrMoreRacesNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">Other</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="other"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.other,
                                touched.other,
                                values.otherNotCollected
                              )}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'other')
                              }
                              value={
                                values.otherNotCollected ? '' : values.other
                              }
                              disabled={values.otherNotCollected}
                            />
                            <ErrorMessage component={TextError} name="other" />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field name="otherNotCollected" type="checkbox" />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">
                            Additional Identities
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="additionalIdentities"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.additionalIdentities,
                                touched.additionalIdentities,
                                values.additionalIdentitiesNotCollected
                              )}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'additionalIdentities'
                                )
                              }
                              value={
                                values.additionalIdentitiesNotCollected
                                  ? ''
                                  : values.additionalIdentities
                              }
                              disabled={values.additionalIdentitiesNotCollected}
                            />
                            <ErrorMessage
                              component={TextError}
                              name="additionalIdentities"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="additionalIdentitiesNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="txt-mandatory">Unknown</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="unknown"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className={getClassNames(
                                errors.unknown,
                                touched.unknown,
                                values.unknownNotCollected
                              )}
                              onChange={e =>
                                handleChange(e, setFieldValue, 'unknown')
                              }
                              value={
                                values.unknownNotCollected ? '' : values.unknown
                              }
                              disabled={values.unknownNotCollected}
                            />
                            <ErrorMessage
                              component={TextError}
                              name="unknown"
                            />
                          </td>
                          <td className="ymca-normalWrap">
                            <div className="ymca-areaBox mb-0">
                              <Field
                                name="unknownNotCollected"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>

                        <tr>
                          <td>Total Members</td>
                          <td className="ymca-fieldLine">
                            <Field
                              name="total"
                              type="text"
                              maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                              className="ymca-field ymca-fieldDisabled"
                              disabled
                              value={totalCount(
                                !values.nativeAmericanAlaskanNativeNotCollected
                                  ? values.nativeAmericanAlaskanNative
                                  : 0,
                                !values.asianNotCollected ? values.asian : 0,
                                !values.blackOrAfricanAmericanNotCollected
                                  ? values.blackOrAfricanAmerican
                                  : 0,
                                !values.hispanicLatinoNotCollected
                                  ? values.hispanicLatino
                                  : 0,
                                !values.middleEastNorthAfricanNotCollected
                                  ? values.middleEastNorthAfrican
                                  : 0,
                                !values.nativeHawaiOtherPacificIslanderNotCollected
                                  ? values.nativeHawaiOtherPacificIslander
                                  : 0,
                                !values.whiteNotCollected ? values.white : 0,
                                !values.twoOrMoreRacesNotCollected
                                  ? values.twoOrMoreRaces
                                  : 0,
                                !values.otherNotCollected ? values.other : 0,
                                !values.additionalIdentitiesNotCollected
                                  ? values.additionalIdentities
                                  : 0,
                                !values.unknownNotCollected ? values.unknown : 0
                              )}
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                    {note()}
                  </div>
                </div>
              )}

              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={
                    GetMemberDemographicsSelectorData?.data?.updatedByName
                  }
                  Time={
                    GetMemberDemographicsSelectorData?.data?.updatedDatetime
                  }
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightUntitled float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={status?.toUpperCase() === 'SUBMITTED'}
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationsNum}
        formId={formId}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {help && (
        <Help hidePopup={hideShowHelp} keyValue="members-demographics" />
      )}
    </div>
  );
};

MembersDemographicsTab.propTypes = {
  handleGetMemberDemographics: PropTypes.func.isRequired,
  handlePostMemberDemographics: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetMemberDemographicsSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  GetMembershipsSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetMemberDemographics: getMemberDemographics,
  handlePostMemberDemographics: postMemberDemographics,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

const mapStateToProps = state => ({
  GetMemberDemographicsSelectorData: GetMemberDemographicsSelector(state),
  GetMembershipsSelectorData: GetMembershipsSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(MembersDemographicsTab));
