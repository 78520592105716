import * as types from './types';

const initialState = {
  FetchOverViewData: {},
  error: {},
  YmcaBranchList: [],
  CollabNonProfitOrg: [],
  CollabEducationOrg: [],
  CollabGovtOrgAndAffiliates: [],
  CollabOtherCommunityOrg: [],
  CollabPrivateOrgAndBusinesses: [],
  CollaborationsAmongYs: [],
  ProgramActivities: [],
  ProgramActivityHousing: [],
  ProgramActivityFoodProgram: [],
  ProgramActivityArmedService: [],
  ProgramActivityOlderAdult: [],
  DashboardOverview: []
};

export default function AnnualReportOverviewReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.ANNUAL_REPORT_DASHBOARD_OVERVIEW_SUCCESS: {
      return {
        ...state,
        DashboardOverview: action.payload
      };
    }
    case types.ANNUAL_REPORT_DASHBOARD_OVERVIEW_FAILURE:
      return { ...state, error: action.payload };

    case types.FETCH_ANNUAL_REPORT_OVERVIEW_SUCCESS: {
      return {
        ...state,
        FetchOverViewData: action.payload
      };
    }
    case types.FETCH_ANNUAL_REPORT_OVERVIEW_FAILURE:
      return { ...state, error: action.payload };

    case types.YMCA_BRANCHES_LIST_SUCCESS: {
      return {
        ...state,
        YmcaBranchList: action.payload
      };
    }
    case types.YMCA_BRANCHES_LIST_FAILURE:
      return { ...state, error: action.payload };

    case types.COLLAB_NON_PROFIT_ORG:
      return {
        ...state,
        CollabNonProfitOrg: action.payload
      };
    case types.COLLAB_EDUCATION_ORG:
      return {
        ...state,
        CollabEducationOrg: action.payload
      };
    case types.COLLAB_GOVT_ORG_AFFILIATES:
      return {
        ...state,
        CollabGovtOrgAndAffiliates: action.payload
      };

    case types.COLLAB_OTHER_COMMUNITY_ORG:
      return {
        ...state,
        CollabOtherCommunityOrg: action.payload
      };

    case types.COLLAB_PRIVATE_ORG_BUSINESS:
      return {
        ...state,
        CollabPrivateOrgAndBusinesses: action.payload
      };
    case types.COLLAB_ORATIONS_AMOUNG_YS:
      return {
        ...state,
        CollaborationsAmongYs: action.payload
      };

    case types.PROGRAM_ACTIVITIES:
      return {
        ...state,
        ProgramActivities: action.payload
      };
    case types.PROGRAM_ACTIVITIES_HOUSING:
      return {
        ...state,
        ProgramActivityHousing: action.payload
      };

    case types.PROGRAM_ACTIVITIES_FOODPROGRAMS:
      return {
        ...state,
        ProgramActivityFoodProgram: action.payload
      };

    case types.PROGRAM_ACTIVITIES_ARMED_SERVICES:
      return {
        ...state,
        ProgramActivityArmedService: action.payload
      };
    case types.PROGRAM_ACTIVITIES_OLDER_ADULTS:
      return {
        ...state,
        ProgramActivityOlderAdult: action.payload
      };

    default:
      return state;
  }
}
