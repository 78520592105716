import React from 'react';
import { Link } from 'react-router-dom';
import { APP_LABELS } from '@config/app-config';
import Breadcrumb from '@static/images/breadcrumb-icon.svg';
import Table from './table';
import ADMIN_DASHBOARD_COLUMNS from './columns';

const Profiles = () => (
  <div className="ymca-wrapper">
    <main className="page-container">
      <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
        <div className="container">
          <section className="dashboard-header">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb ymca-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/home">{APP_LABELS.NAV_DASHBOARD}</Link>
                      <img
                        src={Breadcrumb}
                        className="breadcrumb-icon"
                        alt="double-right-arrow"
                      />
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/program/details">
                        {APP_LABELS.SWIM_PROGRAM_DETAILS}
                      </Link>
                      <img
                        src={Breadcrumb}
                        className="breadcrumb-icon"
                        alt="double-right-arrow"
                      />
                    </li>
                    <li className="breadcrumb-item active">List of Programs</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <div className="ymca-associate-info-wrapper">
                  <h2 className="ymca-sub-heading mb-2">
                    Multi-Branch Programs
                  </h2>
                  <h6>3201 Watts Lane Richmond, VA 23223</h6>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
                <Link
                  className="btn ymca-primary-btn login-btn"
                  to="/swim/safety/addprogram"
                >
                  Edit
                </Link>
              </div>
            </div>
          </section>
          <hr className="custom-hr mt-0" />

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card swim-safety-profile-cards">
                <div className="card-body">
                  <div className="ymca-dark-para-4">Parent Association</div>
                  <div className="ymca-dark-para-3">
                    YMCA of Greater Richmond
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card swim-safety-profile-cards">
                <div className="card-body">
                  <div className="ymca-dark-para-4">
                    Parent Association Address
                  </div>
                  <div className="ymca-dark-para-3">
                    Frank J. Thornton YMCA Aquatic Center
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card swim-safety-profile-cards">
                <div className="card-body">
                  <div className="ymca-dark-para-4">Branch Contact Number</div>
                  <div className="ymca-dark-para-3">(804) 918-7433</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card swim-safety-profile-cards">
                <div className="card-body">
                  <div className="ymca-dark-para-4">Program Name</div>
                  <div className="ymca-dark-para-3">
                    Baker Elementary School Tuesd
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card swim-safety-profile-cards">
                <div className="card-body">
                  <div className="ymca-dark-para-4">Start Date</div>
                  <div className="ymca-dark-para-3">2022-04-12</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card swim-safety-profile-cards">
                <div className="card-body">
                  <div className="ymca-dark-para-4">End Date</div>
                  <div className="ymca-dark-para-3">2022-05-31</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card swim-safety-profile-cards">
                <div className="card-body">
                  <div className="ymca-dark-para-4">Instructor Name</div>
                  <div className="ymca-dark-para-3">Jill Finney</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center justify-content-end my-3">
            <div className="col-lg-8 col-md-8 col-sm-12 text-lg-end text-md-end">
              <button type="button" className="btn ymca-primary-btn login-btn">
                {/* <img
                  src={excelicon}
                  alt="download-template-icon"
                  className="img-fluid me-2 template-icon"
                /> */}
                Download Template
              </button>
              <button
                type="button"
                className="btn ymca-primary-btn login-btn mx-3"
              >
                + Add Profiles From Excel
              </button>
              <Link
                className="btn ymca-primary-btn login-btn"
                to="/swim/safety/add/profiles"
              >
                + Add Profile
              </Link>
            </div>
          </div>
          <Table
            rows={[]}
            headers={ADMIN_DASHBOARD_COLUMNS()}
            title="Collected Profiles"
          />
        </div>
      </div>
    </main>
  </div>
);

export default Profiles;
