import React from 'react';
import PropTypes, { number } from 'prop-types';

const KpiCard = props => {
  const {
    darkIcon,
    lightIcon,
    cardClass,
    cardHoverClass,
    cardName,
    cardValue,
    onkipclick,
    id,
    databstoggle,
    databstarget,
    role,
    ariacontrols,
    ariaselected,
    activeClass
  } = props;

  return (
    <button
      className={`btn btn-link text-start text-decoration-none w-100 ${activeClass} c-auto p-0`}
      type="button"
      id={id}
      data-bs-toggle={databstoggle}
      data-bs-target={databstarget}
      role={role}
      aria-controls={ariacontrols}
      aria-selected={ariaselected}
    >
      <div
        className={`card stats-card ${cardHoverClass}`}
        role="button"
        onClick={onkipclick}
        tabIndex="0"
        aria-hidden="true"
      >
        <div className="d-flex align-items-center h-100">
          <div className="card-icon">
            <img src={darkIcon} className="dark-icon" alt="icon" />

            <img src={lightIcon} className="light-icon" alt="icon" />
          </div>
          <div className="card-title">
            <h4>{cardValue}</h4>
            <p>{cardName}</p>
          </div>
        </div>
      </div>
    </button>
  );
};

KpiCard.propTypes = {
  darkIcon: PropTypes.string,
  lightIcon: PropTypes.string,
  cardClass: PropTypes.string,
  cardHoverClass: PropTypes.string,
  cardName: PropTypes.string,
  cardValue: PropTypes.number,
  onkipclick: PropTypes.func.isRequired,

  id: PropTypes.string,
  databstoggle: PropTypes.string,
  databstarget: PropTypes.string,
  role: PropTypes.string,
  ariacontrols: PropTypes.string,
  ariaselected: PropTypes.string,
  activeClass: PropTypes.string
};

KpiCard.defaultProps = {
  darkIcon: '',
  lightIcon: '',
  cardClass: '',
  cardHoverClass: '',
  cardName: '',
  cardValue: number,

  id: '',
  databstoggle: '',
  databstarget: '',
  role: '',
  ariacontrols: '',
  ariaselected: '',
  activeClass: ''
};

export default KpiCard;
