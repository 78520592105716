/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import {
  getFoodProgram,
  foodProgram
} from '@redux/program-activities/foodprogram/actions';
import { connect } from 'react-redux';
import { GetFoodProgramSelector } from '@redux/program-activities/foodprogram/selectors';
import PropTypes from 'prop-types';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';

import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import TextError from '@modules/shared/components/reusable-components/text-error';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const FoodProgramsTab = props => {
  const {
    handleGetFoodProgram,
    GetFoodProgramSelectorData,
    handleFoodProgram,
    selectedAsso,
    selectedBranch,
    selectedBranchName,
    setLoader,
    setErrorPopup,
    setErrorMessage,
    status,
    role,
    handleGetAnnualReportsDashboard,
    LoginUserData
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Program and Activities';

  const [isSuccess, setIsSuccess] = useState(false);
  const [resStatus, setResStatus] = useState();
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const formikref = useRef();
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const getProgramData = success => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetFoodProgram(selectedAsso, selectedBranch).then(res => {
        if (res.status === 200) {
          setLoader(false);
          if (success) {
            setIsSuccess(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        }
      });
    }
  };
  useEffect(() => {
    getProgramData();
    formikref?.current?.resetForm();
  }, [selectedAsso, selectedBranch]);

  const initialValues = {
    didYofferFoodServiceDuringYear: 'true',
    didYofferFoodSnack18YearsAndYoung: 'true',
    uniqueYouthServedInUsdareimbersment: '',
    uniqueYouthServedInNonUsdareimbersment: '',
    totMealsSnacksServedInUsdareimbersement: '',
    totMealsSnacksServedInNonUsdareimbersement: ''
  };

  const editValues = {
    didYofferFoodServiceDuringYear:
      `${GetFoodProgramSelectorData?.data?.didYofferFoodServiceDuringYear}` ===
      'null'
        ? 'true'
        : `${GetFoodProgramSelectorData?.data?.didYofferFoodServiceDuringYear}`,

    didYofferFoodSnack18YearsAndYoung:
      `${GetFoodProgramSelectorData?.data?.didYofferFoodSnack18YearsAndYoung}` ===
      'null'
        ? 'true'
        : `${GetFoodProgramSelectorData?.data?.didYofferFoodSnack18YearsAndYoung}`,

    uniqueYouthServedInUsdareimbersment:
      GetFoodProgramSelectorData?.data?.uniqueYouthServedInUsdareimbersment ===
      null
        ? ''
        : GetFoodProgramSelectorData?.data?.uniqueYouthServedInUsdareimbersment,
    uniqueYouthServedInNonUsdareimbersment:
      GetFoodProgramSelectorData?.data
        ?.uniqueYouthServedInNonUsdareimbersment === null
        ? ''
        : GetFoodProgramSelectorData?.data
            ?.uniqueYouthServedInNonUsdareimbersment,
    totMealsSnacksServedInUsdareimbersement:
      GetFoodProgramSelectorData?.data
        ?.totMealsSnacksServedInUsdareimbersement === null
        ? ''
        : GetFoodProgramSelectorData?.data
            ?.totMealsSnacksServedInUsdareimbersement,
    totMealsSnacksServedInNonUsdareimbersement:
      GetFoodProgramSelectorData?.data
        ?.totMealsSnacksServedInNonUsdareimbersement === null
        ? ''
        : GetFoodProgramSelectorData?.data
            ?.totMealsSnacksServedInNonUsdareimbersement
  };

  const totalCount = (value1, value2) => value1 + value2;

  const validationSchema = Yup.object({
    didYofferFoodServiceDuringYear: Yup.string().nullable(),
    didYofferFoodSnack18YearsAndYoung: Yup.string().nullable(),
    uniqueYouthServedInUsdareimbersment: Yup.string().nullable(),
    uniqueYouthServedInNonUsdareimbersment: Yup.string().nullable(),
    totMealsSnacksServedInUsdareimbersement: Yup.string().nullable(),
    totMealsSnacksServedInNonUsdareimbersement: Yup.string().nullable()
  });

  const maxLength = 9;
  const convertedValue = value => (value === 0 || value ? value : null);
  const onSubmit = values => {
    setLoader(true);

    values.didYofferFoodSnack18YearsAndYoung =
      values.didYofferFoodServiceDuringYear !== 'true'
        ? 'false'
        : values.didYofferFoodSnack18YearsAndYoung;
    const payloadBody = {
      programActivityFoodProgramId:
        GetFoodProgramSelectorData?.data?.programActivityFoodProgramId,
      associationNumber: selectedAsso,
      year: sessionStorage.getItem('selectedBatchYear'),
      branchNumber: selectedBranch,
      branchName: selectedBranchName?.toString(),

      didYofferFoodServiceDuringYear:
        values.didYofferFoodServiceDuringYear.toLowerCase() === 'true',

      didYofferFoodSnack18YearsAndYoung:
        values.didYofferFoodServiceDuringYear === 'true'
          ? values.didYofferFoodSnack18YearsAndYoung.toLowerCase() === 'true'
          : false,

      uniqueYouthServedInUsdareimbersment:
        values.didYofferFoodSnack18YearsAndYoung === 'true'
          ? convertedValue(values.uniqueYouthServedInUsdareimbersment)
          : null,

      uniqueYouthServedInNonUsdareimbersment:
        values.didYofferFoodSnack18YearsAndYoung === 'true'
          ? convertedValue(values.uniqueYouthServedInNonUsdareimbersment)
          : null,

      totMealsSnacksServedInUsdareimbersement:
        values.didYofferFoodSnack18YearsAndYoung === 'true'
          ? convertedValue(values.totMealsSnacksServedInUsdareimbersement)
          : null,

      totMealsSnacksServedInNonUsdareimbersement:
        values.didYofferFoodSnack18YearsAndYoung === 'true'
          ? convertedValue(values.totMealsSnacksServedInNonUsdareimbersement)
          : null,

      status: GetFoodProgramSelectorData?.data?.status,
      reportBatchId: GetFoodProgramSelectorData?.data?.reportBatchId,
      createdByPersonId: GetFoodProgramSelectorData?.data?.createdByPersonId,
      createdByName: GetFoodProgramSelectorData?.data?.createdByName,
      createdDatetime: GetFoodProgramSelectorData?.data?.createdDatetime,
      updatedByPersonId: GetFoodProgramSelectorData?.data?.updatedByPersonId,
      updatedByName: GetFoodProgramSelectorData?.data?.updatedByName,
      updatedDatetime: GetFoodProgramSelectorData?.data?.updatedDatetime
    };
    setIsSuccess(false);
    handleFoodProgram(payloadBody).then(res => {
      if (res?.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        getProgramData(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-programs">
                <div className="ymc-alert mt-1">
                  {GetFoodProgramSelectorData?.data?.recordStatus?.toUpperCase() ===
                    APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                </div>
                <div className="ymca-covidRelif pt-0">
                  <h4 className="ymca-headingTitle txt-mandatory">
                    In {selectedYear} did your Y offer food programs?
                  </h4>
                  <div className="ymca-radioBTN d-flex">
                    <div className=" me-5">
                      <Field
                        type="radio"
                        name="didYofferFoodServiceDuringYear"
                        className="me-2"
                        value="true"
                      />
                      <span>Yes</span>
                    </div>
                    <div className="">
                      <Field
                        type="radio"
                        name="didYofferFoodServiceDuringYear"
                        className="me-2"
                        value="false"
                      />
                      <span>No</span>
                    </div>
                  </div>
                  <ErrorMessage
                    component={TextError}
                    name="didYofferFoodServiceDuringYear"
                  />

                  {values.didYofferFoodServiceDuringYear === 'true' && (
                    <div>
                      <h4 className="ymca-headingTitle txt-mandatory">
                        In {selectedYear}, did your Y offer or partner with
                        another organization to offer food, meal, or snack
                        programs for youth 18years and younger?
                      </h4>
                      <div className="ymca-radioBTN d-flex">
                        <div className=" me-5">
                          <Field
                            type="radio"
                            name="didYofferFoodSnack18YearsAndYoung"
                            className="me-2"
                            value="true"
                          />
                          <span>Yes</span>
                        </div>
                        <div className="">
                          <Field
                            type="radio"
                            name="didYofferFoodSnack18YearsAndYoung"
                            className="me-2"
                            value="false"
                          />
                          <span>No</span>
                        </div>
                      </div>
                      <ErrorMessage
                        component={TextError}
                        name="didYofferFoodSnack18YearsAndYoung"
                      />
                      {values.didYofferFoodSnack18YearsAndYoung === 'true' && (
                        <div className="ymca-tableResponsive">
                          <table className="table ymca-table table-striped ymca-autoLength">
                            <thead>
                              <tr className="ymca-cuttingData">
                                <th> &nbsp; </th>
                                <th>
                                  USDA Reimbursed Programs (SFSP,SSFP,CACFP)
                                </th>
                                <th>Non-USDA Reimbursed Programs</th>
                                <th>Total</th>
                                <th className="ymca-defaultSpace">&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody className="ymca-cuttingBody ymca-lengths">
                              <tr>
                                <td className="ymca-normalWrap txt-mandatory">
                                  Unique number of youth served
                                </td>
                                <td className="ymca-fieldLine">
                                  <Field
                                    type="text"
                                    className="ymca-field"
                                    name="uniqueYouthServedInUsdareimbersment"
                                    maxLength={maxLength}
                                    onChange={e =>
                                      handleChange(
                                        e,
                                        setFieldValue,
                                        'uniqueYouthServedInUsdareimbersment'
                                      )
                                    }
                                  />
                                </td>
                                <td className="ymca-fieldLine">
                                  <Field
                                    type="text"
                                    className={`ymca-field ${
                                      errors.uniqueYouthServedInNonUsdareimbersment &&
                                      touched.uniqueYouthServedInNonUsdareimbersment
                                        ? 'ymca-field invalid'
                                        : 'ymca-field'
                                    }`}
                                    name="uniqueYouthServedInNonUsdareimbersment"
                                    maxLength={maxLength}
                                    onChange={e =>
                                      handleChange(
                                        e,
                                        setFieldValue,
                                        'uniqueYouthServedInNonUsdareimbersment'
                                      )
                                    }
                                  />
                                </td>
                                <td className="ymca-fieldLine">
                                  <Field
                                    type="text"
                                    className="ymca-field ymca-fieldDisabled"
                                    maxLength={maxLength}
                                    value={totalCount(
                                      Number(
                                        values.uniqueYouthServedInUsdareimbersment
                                      ),
                                      Number(
                                        values.uniqueYouthServedInNonUsdareimbersment
                                      )
                                    )}
                                    disabled
                                  />
                                </td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                              </tr>
                              <tr>
                                <td className="ymca-normalWrap txt-mandatory">
                                  Total number of meals or snacks served
                                </td>
                                <td className="ymca-fieldLine">
                                  <Field
                                    type="text"
                                    className="ymca-field"
                                    name="totMealsSnacksServedInUsdareimbersement"
                                    maxLength={maxLength}
                                    onChange={e =>
                                      handleChange(
                                        e,
                                        setFieldValue,
                                        'totMealsSnacksServedInUsdareimbersement'
                                      )
                                    }
                                  />
                                </td>
                                <td className="ymca-fieldLine">
                                  <Field
                                    type="text"
                                    className="ymca-field"
                                    name="totMealsSnacksServedInNonUsdareimbersement"
                                    maxLength={maxLength}
                                    onChange={e =>
                                      handleChange(
                                        e,
                                        setFieldValue,
                                        'totMealsSnacksServedInNonUsdareimbersement'
                                      )
                                    }
                                  />
                                </td>
                                <td className="ymca-fieldLine">
                                  <Field
                                    type="text"
                                    className="ymca-field ymca-fieldDisabled"
                                    value={totalCount(
                                      Number(
                                        values.totMealsSnacksServedInUsdareimbersement
                                      ),
                                      Number(
                                        values.totMealsSnacksServedInNonUsdareimbersement
                                      )
                                    )}
                                    disabled
                                  />
                                </td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="ymca-submitHighlighter">
                  <LastUpdateName
                    Username={GetFoodProgramSelectorData?.data?.updatedByName}
                    Time={GetFoodProgramSelectorData?.data?.updatedDatetime}
                  />
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </div>
              {isSuccess && <SuccessToaster Status={resStatus} />}
            </Form>
          );
        }}
      </Formik>

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </>
  );
};
FoodProgramsTab.propTypes = {
  handleGetFoodProgram: PropTypes.func.isRequired,
  handleFoodProgram: PropTypes.func.isRequired,
  GetFoodProgramSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedAsso: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranch: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  selectedBranchName: PropTypes.objectOf(PropTypes.any).isRequired,
  setErrorPopup: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapDispatchToProps = {
  handleGetFoodProgram: getFoodProgram,
  handleFoodProgram: foodProgram,
  // handleGetAnnualReportsDashboard: getAnnualReportsDashboardData
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};
const mapStateToProps = state => ({
  GetFoodProgramSelectorData: GetFoodProgramSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(FoodProgramsTab));
