const SWIM_ASSOCIATION_COLUMNS = () => [
  {
    Header: 'Reference ID',
    accessor: 'profileId',
    className: 'notranslate'
  },
  {
    Header: 'Birth Date',
    accessor: 'childBirthDate',
    className: 'notranslate'
  },

  {
    Header: 'Gender',
    accessor: 'gender',
    className: 'notranslate'
  },
  {
    Header: 'Ethnicity',
    accessor: 'ethnicity',
    className: 'notranslate '
  },

  {
    Header: 'Actions',
    accessor: 'details',
    className: 'notranslate'
  }
];

export default SWIM_ASSOCIATION_COLUMNS;
