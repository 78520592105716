import {
  GET_SWIMSAFETY_BRANCH_LIST_API,
  GET_SWIMSAFETY_BRANCH_DETAILS_API,
  GET_SWIMSAFETY_ADD_OR_UPDATE_BARNCH_API,
  GET_SWIMSAFETY_BRANCH_DELETE_API,
  GET_SWIMSAFETY_BRANCHES_BY_FILTERS_API
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSwimSafetyBranchList = AssociationId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_BRANCH_LIST_API(AssociationId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_BRANCH_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_BRANCH_API_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimSafetyBranchDetails =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_BRANCH_DETAILS_API(AssociationId),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_BRANCH_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_BRANCH_API_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyBranchAddORupdate =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_ADD_OR_UPDATE_BARNCH_API(AssociationId),
      method: 'POST',
      successAction: data => ({
        type: types.SWIMSAFETY_BRANCH_UPDATE_OR_ADD_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_BRANCH_API_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getSwimSafetyBranchDelete = DeleteId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SWIMSAFETY_BRANCH_DELETE_API(DeleteId),
    method: 'GET',
    successAction: data => ({
      type: types.SWIMSAFETY_BRANCH_DELETE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SWIMSAFETY_BRANCH_DELETE_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getSwimSafetyBranchesByFilters =
  (startDate, endDate, Offset,ShowAll, associationNumber) => (dispatch, getState) =>
    asyncRequest({
      url: GET_SWIMSAFETY_BRANCHES_BY_FILTERS_API(
        startDate,
        endDate,
        Offset,
        ShowAll,
        associationNumber
      ),
      method: 'GET',
      successAction: data => ({
        type: types.SWIMSAFETY_BRANCHES_BY_FILTERS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.SWIMSAFETY_BRANCHES_BY_FILTERS_FAILURE,
        payload: error
      }),
      dispatch
    });
