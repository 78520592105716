export const GET_BRANCH_DETAILS_SUCCESS = 'GET_BRANCH_DETAILS_SUCCESS';
export const GET_BRANCH_DETAILS_FAILURE = 'GET_BRANCH_DETAILS_FAILURE';

export const SET_BRANCH_DETAILS_SUCCESS = 'SET_BRANCH_DETAILS_SUCCESS';
export const SET_BRANCH_DETAILS_FAILURE = 'SET_BRANCH_DETAILS_FAILURE';

export const GET_LIST_OF_BRANCHES_SUCCESS = 'GET_LIST_OF_BRANCHES_SUCCESS';
export const GET_LIST_OF_BRANCHES_FAILURE = 'GET_LIST_OF_BRANCHES_FAILURE';

export const GET_LIST_OF_COUNTRIES_SUCCESS = 'GET_LIST_OF_COUNTRIES_SUCCESS';
export const GET_LIST_OF_COUNTRIES_FAILURE = 'GET_LIST_OF_COUNTRIES_FAILURE';

// export const GET_CRM_PROVIDER_LIST_SUCCESS ='GET_CRM_PROVIDER_LIST_SUCCESS';
// export const GET_CRM_PROVIDER_LIST_FAILURE = 'GET_CRM_PROVIDER_LIST_FAILURE';
