import * as types from './types';

const initialState = {
  AnnualReportDashboardData: []
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ANNUAL_REPORT_DASHBOARD_SUCCESS: {
      return {
        ...state,
        AnnualReportDashboardData: action.payload
      };
    }
    case types.ANNUAL_REPORT_DASHBOARD_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
