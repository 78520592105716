import * as types from './types';

const initialState = {
  GetMoypDetailView: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.MOYP_DETAIL_VIEW_SUCCESS: {
      return {
        ...state,
        GetMoypDetailView: action.payload
      };
    }
    case types.MOYP_DETAIL_VIEW_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
