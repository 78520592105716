import {
  MEMBERSHIP_NOW_YOUR_COMMUNITY_GET_URL,
  MEMBERSHIP_NOW_YOUR_COMMUNITY_POST_URL
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getMembershipNowYourCommunity =
  associationsNum => (dispatch, getState) =>
    asyncRequest({
      url: MEMBERSHIP_NOW_YOUR_COMMUNITY_GET_URL(associationsNum),
      successAction: data => ({
        type: types.GET_MEMBERSHIP_NOW_YOUR_COMMUNITY_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_MEMBERSHIP_NOW_YOUR_COMMUNITY_FAILURE,
        payload: error
      }),
      dispatch
    });

export const postMembershipNowYourCommunity =
  payloadBody => (dispatch, getState) =>
    asyncRequest({
      url: MEMBERSHIP_NOW_YOUR_COMMUNITY_POST_URL(),
      method: 'POST',
      data: payloadBody,
      successAction: data => ({
        type: types.POST_MEMBERSHIP_NOW_YOUR_COMMUNITY_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.POST_MEMBERSHIP_NOW_YOUR_COMMUNITY_FAILURE,
        payload: error
      }),
      dispatch
    });
export const resetReduxState = key => ({
  type: 'RESET_KEY',
  payload: { key }
});
