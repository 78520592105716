import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from './page-header';

const PageContainer = props => {
  const { title, pathName, onBackClick } = props;
  const [instructions, setInstructions] = useState('');

  useEffect(() => {
    if (window.location.pathname.includes('upload')) {
      setInstructions(
        JSON.parse(sessionStorage.getItem('allinstructions'))?.[
          'chronic-disease-program'
        ]?.Instruction
      );
    } else {
      setInstructions(
        JSON.parse(sessionStorage.getItem('allinstructions'))?.[
          'chronic-disease-program-view-details'
        ]?.Instruction
      );
    }
  }, []);

  return (
    <div className="container">
      <PageHeader pathName={pathName} onBackClick={onBackClick} />
      <h2 className="ymca-sub-heading mb-2">{title}</h2>
      <div className="alert ymca-instruction-alert" role="alert">
        <div className="ymca-cdp-instruction-title mb-2">Instructions</div>
        <div className="ymca-cdp-instruction-para mb-1">
          <div
            dangerouslySetInnerHTML={{
              __html: instructions
            }}
          />
        </div>
      </div>
    </div>
  );
};
PageContainer.propTypes = {
  title: PropTypes.objectOf(PropTypes.any).isRequired,
  pathName: PropTypes.objectOf(PropTypes.any).isRequired,
  onBackClick: PropTypes.func.isRequired
}
export default PageContainer;
