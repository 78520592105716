import * as types from './types';

const initialState = {
  SiteinformationList: {},
  SiteinformationSuccess: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SITEINFORMATION_LIST_SUCCESS: {
      return {
        ...state,
        SiteinformationList: action.payload
      };
    }
    case types.SITEINFORMATION_LIST_FAILURE:
      return { ...state, error: action.payload };
    case types.SITEINFORMATION_DATA_SUCCESS: {
      return {
        ...state,
        SiteinformationSuccess: action.payload
      };
    }
    case types.SITEINFORMATION_DATA_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
