import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Provider } from 'react-redux';
import App from './app';
import configureStore from './state/store';
import styles from './app.scss';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga4';
import { getGaTrackingId } from '@config/api-config';

const { store, persistor } = configureStore();
const ApplicationRoot = document.getElementById('app-root');

const TRACKING_ID = getGaTrackingId();
ReactGA.initialize(TRACKING_ID);
String.format = function () {
  var s = arguments[0];
  for (var i = 0; i < arguments.length - 1; i++) {
    var reg = new RegExp("\\{" + i + "\\}", "gm");
    s = s.replace(reg, arguments[i + 1]);
  }
  return s;
}
if (ApplicationRoot) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    ApplicationRoot
  );
} else {
  console.error('React: Unable to mount application');
}

if (process.env.NODE_ENV === 'development') {
  module.hot.accept();
}
