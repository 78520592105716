import * as types from './types';

const initialState = {
  ReviewSubmitInfo: {},
  FinalSubmit: {},
  ReviewSubmitData: []
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_REVIEW_SUBMIT_INFO_SUCCESS: {
      return {
        ...state,
        ReviewSubmitInfo: action.payload
      };
    }
    case types.GET_REVIEW_SUBMIT_INFO_FAILURE:
      return { ...state, error: action.payload };

    case types.FINAL_SUBMIT_SUCCESS: {
      return {
        ...state,
        BranchList: action.payload
      };
    }
    case types.FINAL_SUBMIT_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_REVIEW_SUBMIT_DATA_SUCCESS: {
      return {
        ...state,
        ReviewSubmitData: action.payload
      };
    }
    case types.POST_REVIEW_SUBMIT_DATA_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
