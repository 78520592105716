/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import {
  getArmedService,
  armedService
} from '@redux/program-activities/armedservice/actions';
import { connect } from 'react-redux';
import { GetArmedServiceSelector } from '@redux/program-activities/armedservice/selectors';
import PropTypes from 'prop-types';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import TextError from '@modules/shared/components/reusable-components/text-error';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const ArmedServicesTab = props => {
  const {
    handleGetArmedServices,
    GetArmedServiceSelectorData,
    setLoader,
    handleArmedServices,
    selectedAsso,
    selectedBranch,
    selectedBranchName,
    setErrorPopup,
    setErrorMessage,
    status,
    role,
    handleGetAnnualReportsDashboard,
    LoginUserData
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Program and Activities';

  const [isSuccess, setIsSuccess] = useState(false);
  const [resStatus, setResStatus] = useState();
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const formikref = useRef();
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handleGetArmedServiceData = success => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetArmedServices(selectedAsso, selectedBranch).then(res => {
        if (res.status === 200) {
          setLoader(false);
          if (success) {
            setIsSuccess(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        }
      });
    }
  };

  useEffect(() => {
    handleGetArmedServiceData();
    formikref?.current?.resetForm();
  }, [selectedAsso, selectedBranch]);

  const initialValues = {
    didYofferServiceToDutyReserveRetdMilitaryPersons: 'true',
    totIndividualMilitaryPersonServed2022: '',
    offerChildcarePreschoolBabysittingDuringYear: '',
    offerDeploymentSupportDuringYear: '',
    offerFoodDistributionDuringYear: '',
    offerAdultEducationDuringYear: '',
    offerJobTrainingSearchDuringYear: '',
    offerPhysicalMentalHealthDuringYear: '',
    offerDayCampsOrResidentCamps: '',
    offerMemberDiscount: ''
  };

  const editValues = {
    didYofferServiceToDutyReserveRetdMilitaryPersons:
      `${GetArmedServiceSelectorData?.didYofferServiceToDutyReserveRetdMilitaryPersons}` ===
      'null'
        ? 'true'
        : `${GetArmedServiceSelectorData?.didYofferServiceToDutyReserveRetdMilitaryPersons}`,
    totIndividualMilitaryPersonServed2022:
      GetArmedServiceSelectorData?.totIndividualMilitaryPersonServed2022 ===
      null
        ? ''
        : GetArmedServiceSelectorData?.totIndividualMilitaryPersonServed2022,
    offerChildcarePreschoolBabysittingDuringYear: `${GetArmedServiceSelectorData?.offerChildcarePreschoolBabysittingDuringYear}`,
    offerDeploymentSupportDuringYear: `${GetArmedServiceSelectorData?.offerDeploymentSupportDuringYear}`,
    offerFoodDistributionDuringYear: `${GetArmedServiceSelectorData?.offerFoodDistributionDuringYear}`,
    offerAdultEducationDuringYear: `${GetArmedServiceSelectorData?.offerAdultEducationDuringYear}`,
    offerJobTrainingSearchDuringYear: `${GetArmedServiceSelectorData?.offerJobTrainingSearchDuringYear}`,
    offerPhysicalMentalHealthDuringYear: `${GetArmedServiceSelectorData?.offerPhysicalMentalHealthDuringYear}`,
    offerDayCampsOrResidentCamps: `${GetArmedServiceSelectorData?.offerDayCampsOrResidentCamps}`,
    offerMemberDiscount: `${GetArmedServiceSelectorData?.offerMemberDiscount}`
  };

  const validationSchema = Yup.object({
    didYofferServiceToDutyReserveRetdMilitaryPersons: Yup.string().nullable(),
    totIndividualMilitaryPersonServed2022: Yup.string().nullable(),
    offerChildcarePreschoolBabysittingDuringYear: Yup.string().nullable(),
    offerDeploymentSupportDuringYear: Yup.string().nullable(),
    offerFoodDistributionDuringYear: Yup.string().nullable(),
    offerAdultEducationDuringYear: Yup.string().nullable(),
    offerJobTrainingSearchDuringYear: Yup.string().nullable(),
    offerPhysicalMentalHealthDuringYear: Yup.string().nullable(),
    offerDayCampsOrResidentCamps: Yup.string().nullable(),
    offerMemberDiscount: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? value : null);
  const checkValue = value =>
    value !== 'null' ? value.toLowerCase() === 'true' : null;

  const onSubmit = values => {
    setLoader(true);
    const didYofferService =
      values.didYofferServiceToDutyReserveRetdMilitaryPersons.toLowerCase() ===
      'true';
    const payloadBody = {
      programActivityArmedServicesId:
        GetArmedServiceSelectorData?.programActivityArmedServicesId,
      associationNumber: selectedAsso,
      year: sessionStorage.getItem('selectedBatchYear'),
      branchNumber: selectedBranch,
      branchName: selectedBranchName?.toString(),
      didYofferServiceToDutyReserveRetdMilitaryPersons: didYofferService,
      totIndividualMilitaryPersonServed2022: didYofferService
        ? convertedValue(values.totIndividualMilitaryPersonServed2022)
        : null,
      offerChildcarePreschoolBabysittingDuringYear: didYofferService
        ? checkValue(values.offerChildcarePreschoolBabysittingDuringYear)
        : null,
      offerDeploymentSupportDuringYear: didYofferService
        ? checkValue(values.offerDeploymentSupportDuringYear)
        : null,
      offerFoodDistributionDuringYear: didYofferService
        ? checkValue(values.offerFoodDistributionDuringYear)
        : null,
      offerAdultEducationDuringYear: didYofferService
        ? checkValue(values.offerAdultEducationDuringYear)
        : null,
      offerJobTrainingSearchDuringYear: didYofferService
        ? checkValue(values.offerJobTrainingSearchDuringYear)
        : null,
      offerPhysicalMentalHealthDuringYear: didYofferService
        ? checkValue(values.offerPhysicalMentalHealthDuringYear)
        : null,
      offerDayCampsOrResidentCamps: didYofferService
        ? checkValue(values.offerDayCampsOrResidentCamps)
        : null,
      offerMemberDiscount: didYofferService
        ? checkValue(values.offerMemberDiscount)
        : null,
      status: GetArmedServiceSelectorData?.status,
      reportBatchId: GetArmedServiceSelectorData?.reportBatchId,
      createdByPersonId: GetArmedServiceSelectorData?.createdByPersonId,
      createdByName: GetArmedServiceSelectorData?.createdByName,
      createdDatetime: GetArmedServiceSelectorData?.createdDatetime,
      updatedByPersonId: GetArmedServiceSelectorData?.updatedByPersonId,
      updatedByName: GetArmedServiceSelectorData?.updatedByName,
      updatedDatetime: GetArmedServiceSelectorData?.updatedDatetime
    };
    setIsSuccess(false);
    handleArmedServices(payloadBody).then(res => {
      handleGetArmedServiceData(true);
      if (res?.status === 200) {
        setResStatus(res.data.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };
  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };
  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-programs">
                <div className="ymc-alert mt-1">
                  {GetArmedServiceSelectorData?.recordStatus?.toUpperCase() ===
                    APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                </div>
                <div className="ymca-covidRelif pt-0">
                  <h4 className="ymca-headingTitle  txt-mandatory">
                    In {selectedYear}, did your Y offer programs and services
                    geared towards active duty, reserve, or retired military
                    personnel and their families?
                  </h4>
                  <div className="ymca-radioBTN d-flex">
                    <div className="me-5">
                      <Field
                        type="radio"
                        name="didYofferServiceToDutyReserveRetdMilitaryPersons"
                        className="me-2"
                        value="true"
                      />
                      <span>Yes</span>
                    </div>
                    <div className="">
                      <Field
                        type="radio"
                        name="didYofferServiceToDutyReserveRetdMilitaryPersons"
                        className="me-2"
                        value="false"
                      />
                      <span>No</span>
                    </div>
                  </div>
                  <ErrorMessage
                    component={TextError}
                    name="didYofferServiceToDutyReserveRetdMilitaryPersons"
                  />
                  {values.didYofferServiceToDutyReserveRetdMilitaryPersons ===
                    'true' && (
                    <div>
                      <div className="row">
                        <div className="col-xl-8 co-lg-8 col-md-8 col-sm-6 col-12">
                          <h4 className="ymca-headingTitle">
                            How many individual military personnel and family
                            members did you serve in {selectedYear}?
                          </h4>
                        </div>
                        <div className="col-xl-4 co-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                          <div className="ymca-selectWrap">
                            <Field
                              type="text"
                              className="ymca-fieldSet"
                              name="totIndividualMilitaryPersonServed2022"
                              maxLength="9"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totIndividualMilitaryPersonServed2022'
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="ymca-tableResponsive">
                        <table className="table ymca-table table-striped">
                          <thead>
                            <tr className="ymca-cuttingData">
                              <th className="ps-0">
                                Please select the type of programs and services
                                offered during the year
                              </th>
                              <th>Yes</th>
                              <th>No</th>
                              <th className="ymca-defaultSpace">&nbsp;</th>
                              <th className="ymca-defaultSpace">&nbsp;</th>
                              <th className="ymca-defaultSpace">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody className="ymca-cuttingBody">
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Child care include preschool, before and/or
                                after school, babysitting
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerChildcarePreschoolBabysittingDuringYear"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerChildcarePreschoolBabysittingDuringYear"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Deployment support
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerDeploymentSupportDuringYear"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerDeploymentSupportDuringYear"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Food Distribution
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerFoodDistributionDuringYear"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerFoodDistributionDuringYear"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Adult Education
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerAdultEducationDuringYear"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerAdultEducationDuringYear"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Job training and search assistance
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerJobTrainingSearchDuringYear"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerJobTrainingSearchDuringYear"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Physical and mental health and wellness
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerPhysicalMentalHealthDuringYear"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerPhysicalMentalHealthDuringYear"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Day Camp or Resident Camps
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerDayCampsOrResidentCamps"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerDayCampsOrResidentCamps"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                            <tr>
                              <td className="ymca-normalWrap txt-mandatory">
                                Member Discount
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerMemberDiscount"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="offerMemberDiscount"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div className="ymca-submitHighlighter">
                  <LastUpdateName
                    Username={GetArmedServiceSelectorData?.updatedByName}
                    Time={GetArmedServiceSelectorData?.updatedDatetime}
                  />
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </div>
              {isSuccess && <SuccessToaster Status={resStatus} />}
            </Form>
          );
        }}
      </Formik>

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </>
  );
};
ArmedServicesTab.propTypes = {
  handleGetArmedServices: PropTypes.func.isRequired,
  handleArmedServices: PropTypes.func.isRequired,
  GetArmedServiceSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  selectedAsso: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranch: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranchName: PropTypes.objectOf(PropTypes.any).isRequired,
  setErrorPopup: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapDispatchToProps = {
  handleGetArmedServices: getArmedService,
  handleArmedServices: armedService,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};
const mapStateToProps = state => ({
  GetArmedServiceSelectorData: GetArmedServiceSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ArmedServicesTab));
