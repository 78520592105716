import {
  COLLAB_NON_PROFIT_ORG_GETAPI,
  COLLAB_EDUCATION_ORG_GETAPI,
  COLLAB_GOVT_ORG_AFFILIATES_GETAPI,
  COLLAB_OTHER_COMMUNITY_ORG_GETAPI,
  COLLAB_PRIVATE_ORG_BUSINESS_GETAPI,
  COLLAB_ORATIONS_AMOUNG_YS_GETAPI
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getAnnualReportCollabNonProfitOrgApi =
  (AssociationNumber, branchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_NON_PROFIT_ORG_GETAPI(AssociationNumber, branchNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_NON_PROFIT_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostAnnualReportCollabNonProfitOrgApi =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_NON_PROFIT_ORG_GETAPI(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_NON_PROFIT_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAnnualReportCollabEduOrgApi =
  (AssociationNumber, branchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_EDUCATION_ORG_GETAPI(AssociationNumber, branchNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_EDUCATION_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostAnnualReportCollabEduOrgApi =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_EDUCATION_ORG_GETAPI(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_EDUCATION_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAnnualReportCollabGovtOrgApi =
  (AssociationNumber, branchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_GOVT_ORG_AFFILIATES_GETAPI(AssociationNumber, branchNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_GOVT_ORG_AFFILIATES,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostAnnualReportCollabGovtOrgApi =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_GOVT_ORG_AFFILIATES_GETAPI(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_GOVT_ORG_AFFILIATES,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAnnualReportCollabOtherCommunityOrgApi =
  (AssociationNumber, branchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_OTHER_COMMUNITY_ORG_GETAPI(AssociationNumber, branchNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_OTHER_COMMUNITY_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostAnnualReportCollabOtherCommunityOrgApi =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_OTHER_COMMUNITY_ORG_GETAPI(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_OTHER_COMMUNITY_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAnnualReportCollabPrivateOrgApi =
  (AssociationNumber, branchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_PRIVATE_ORG_BUSINESS_GETAPI(AssociationNumber, branchNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_PRIVATE_ORG_BUSINESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostAnnualReportCollabPrivateOrgApi =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_PRIVATE_ORG_BUSINESS_GETAPI(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_PRIVATE_ORG_BUSINESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAnnualReportCollabAmongYsApi =
  (AssociationNumber, branchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_ORATIONS_AMOUNG_YS_GETAPI(AssociationNumber, branchNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_ORATIONS_AMOUNG_YS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostAnnualReportCollabAmongYsApi =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_ORATIONS_AMOUNG_YS_GETAPI(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.ANNUALREPORT_COLLAB_ORATIONS_AMOUNG_YS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_COLLAB_FAILURE,
        payload: error
      }),
      dispatch
    });
