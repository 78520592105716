import React from 'react';
import PropTypes from 'prop-types';
import { ENUMS } from '@config/app-config';
import RejectedIcon from '@static/images/swimRegistration/rejected-icon.svg';
import PendingStatusIcon from '@static/images/swimRegistration/pending-status-icon.svg';
import ApprovedIcon from '@static/images/swimRegistration/approved-icon.svg';
import NewRegisterStatus from '@static/images/swimRegistration/new-register-status-icon.svg';
import InitiateStatusIcon from '@static/images/swimRegistration/initiate-icon.svg';
import DeniedStatusIcon from '@static/images/swimRegistration/denied-icon.svg';
import UnderReviewStatusIcon from '@static/images/swimRegistration/under-review-icon.svg';

const RenderStatusIcon = props => {
  const { statusValue, isAdminPreview } = props;
  const setIcon = status => {
    switch (status) {
      case ENUMS.NEW:
        return NewRegisterStatus;
      case ENUMS.REJECTED:
        return RejectedIcon;
      case ENUMS.DENIED:
        return DeniedStatusIcon;
      case ENUMS.APPROVED:
      case ENUMS.SUCCESS:
        return ApprovedIcon;
      case ENUMS.PENDING:
        return PendingStatusIcon;
      case ENUMS.INITIATED:
        return InitiateStatusIcon;
      case ENUMS.UNDER_REVIEW:
        return UnderReviewStatusIcon;
      default:
        return PendingStatusIcon;
    }
  };

  const setClassName = className => {
    switch (className) {
      case ENUMS.NEW:
        return 'new-register-status';
      case ENUMS.REJECTED:
      case ENUMS.DENIED:
        return 'rejected-status';
      case ENUMS.APPROVED:
      case ENUMS.SUCCESS:
        return 'approved-status';
      case ENUMS.PENDING:
        return 'pending-status';
      case ENUMS.INITIATED:
      case ENUMS.UNDER_REVIEW:
        return 'initiated-status';
      default:
        return 'pending-status';
    }
  };

  const setStatusText = () => {
    if (isAdminPreview && statusValue === ENUMS.NEW) {
      return ENUMS.NEW_REGISTER;
    }
    if (!isAdminPreview && statusValue === ENUMS.NEW) {
      return ENUMS.SUBMITTED;
    }
    return statusValue;
  };

  return (
    <span className={setClassName(statusValue)}>
      <img
        src={setIcon(statusValue)}
        className="img-fluid success-img ms-3 mb-2"
        alt={setClassName(statusValue)}
      />
      {setStatusText()}
    </span>
  );
};

RenderStatusIcon.propTypes = {
  statusValue: PropTypes.string.isRequired,
  isAdminPreview: PropTypes.bool.isRequired
};

export default RenderStatusIcon;
