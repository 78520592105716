
import { default as get } from 'lodash.get';
import MockAdapter from 'axios-mock-adapter';
import {
  MOCK_MODE,
  MOCK_PATH,
  MOCK_RESPONSE_DELAY,
  REGEX
} from './app-config';
import { getServicePath } from './api-config.js';

export function transformApiURLToDot(url) {
  const uri = url.replace(getServicePath(), ''); // Stips base api url
  let uriNoParams = uri.replace(REGEX.NUMERIC, ''); // removes numeric params
  uriNoParams = uriNoParams.replace('undefined/', ''); // removes any params that are undefined
  const urlNoLeadingSlash = uriNoParams.replace(REGEX.LEADING_SLASH, ''); // removes leading slash
  let uriDotNotated = urlNoLeadingSlash.replace(REGEX.ALL_SLASHES, '.'); // converts slashes to dots

  if (MOCK_MODE === 'failure') {
    uriDotNotated = uriDotNotated.concat('-fail');
  }

  return uriDotNotated;
}

export const configureMocks = (axios) => {
  const mockAdapter = new MockAdapter(axios, { delayResponse: MOCK_RESPONSE_DELAY });
  mockAdapter.onGet(/[\s\S]+/).reply((config) => {
    const { url } = config;
    const uriDotNotated = transformApiURLToDot(url);
    let mockResponse = get(mocks, uriDotNotated);

    if (mockResponse) {
      return [200, mockResponse];
    }

    console.error('error', `: no mock response found in mocks: ${uriDotNotated} \n url: ${url}`);
    return [200, {}]; // 404 maybe? 
  });
};
