/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useDropzone } from 'react-dropzone';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import getUserManagementUploadFile from '@redux/user-management/user-file-upload/actions';
import { GET_USER_BLANK_TEMPLATE_DOWNLOAD_PATH } from '@config/api-config';
import { APP_LABELS } from '@config/app-config';
import waiticon from '@static/images/wait-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import excelicon from '@static/images/excel-icon.svg';
import CustomModal from '@utilities/CustomModal';
import ErrorPopup from './error-popup';

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const UploadFile = props => {
  const { handleGetUserManagementUploadFile } = props;
  const authToken = JSON.parse(sessionStorage.getItem('token'));
  let isError = false;
  const [redirect, setRedirect] = useState(false);
  const [showDownloadWaitPopup, setShowDownloadWaitPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [instructions, setInstructions] = useState(false);

  const handleEmptyDownloadTemplate = () => {
    setLoader(true);
    axios
      .get(GET_USER_BLANK_TEMPLATE_DOWNLOAD_PATH(), {
        responseType: 'blob',
        headers: {
          'Content-Type': 'blob',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': '3c92bec8b5df41c99f31bd7fc2057dd3',
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Upload-Users-Template.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  const onDrop = async acceptedFiles => {
    if (isError) {
      return;
    }
    const [file] = acceptedFiles;
    const UploadFiledata = new FormData();
    UploadFiledata.append('UploadedFile', file);

    if (file === undefined) {
      setShowErrorMessage(
        'File could not be uploaded. Only Files with following extensions are allowed:.xlsx'
      );
      setShowErrorPopup(true);
      return;
    }
    setShowDownloadWaitPopup(true);
    await handleGetUserManagementUploadFile(UploadFiledata).then(response => {
      if (response.status === 200) {
        setShowDownloadWaitPopup(false);
        if (response.status === 200 && response?.data?.status?.status === 0) {
          setRedirect(true);
        } else {
          setShowErrorPopup(true);
          setShowErrorMessage(response?.data?.status?.message);
        }
      } else {
        setShowDownloadWaitPopup(false);
        setShowErrorPopup(true);
        setShowErrorMessage(APP_LABELS.ERROR_MESSAGE);
      }
    });
  };
  const fileValidator = file => {
    if (file.size > 10000000) {
      isError = true;
      setShowErrorMessage(APP_LABELS.MAX_UPLOAD_FILE_ERROR_MESSAGE);
      setShowErrorPopup(true);
    }
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      validator: fileValidator,
      onDrop,
      accept: {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx'
        ]
      }
    });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleCloseErrorPopup = () => setShowErrorPopup(false);

  useEffect(() => {
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'upload-user-data'
      ]?.Instruction
    );
  }, []);

  return (
    <>
      <BlockUi
        blocking={loader}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <main className="">
          <div className="ymca-data-wrapper">
            <div className="container">
              <div className="row mb-3 align-items-center">
                <div className="col-8">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ymca-breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/userManagement/dashboard">
                          {APP_LABELS.NAV_DASHBOARD}
                        </a>
                        <img
                          src={BreadcrumbIcon}
                          className="breadcrumb-icon"
                          alt=""
                        />
                      </li>
                      <li className="breadcrumb-item active">
                        {APP_LABELS.DETAIL_UPLOAD_DATA}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <div className="col-lg-11 col-md-8 col-sm-12">
                  <div className="user-name bg-transparent d-flex align-items-center">
                    <h2 className="ymca-sub-heading mt-3">
                      {APP_LABELS.UPLOAD_USER_MANAGEMENT_DATA}
                    </h2>
                  </div>
                </div>
                <div className="col-lg-1 col-md-4 col-sm-12 text-lg-end text-md-end">
                  <Link
                    type="button"
                    to="/userManagement/dashboard"
                    className="ymca-title-link d-flex align-items-center"
                  >
                    <img src={BackIcon} className="me-1" alt="back-icon" />
                    {APP_LABELS.DASH_BACK}
                  </Link>
                </div>
                <div className="col-lg-12">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: instructions
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="custom-hr" />
            </div>
            <section className="data-upload-wrapper">
              <div className="container">
                <form>
                  <div className="row">
                    <div className="col-lg-10 col-md-12">
                      <div className="ymca-bold-heading mb-2">
                        Step 1 : Download blank template
                      </div>
                      <div className="card outline-card ymca-form px-3">
                        <div className="row align-items-center justify-content-center mt-3">
                          <div className="col-md-auto col-sm-12">
                            <button
                              type="button"
                              className="btn ymca-primary-outline-btn px-2 py-2 custom-align"
                              onClick={handleEmptyDownloadTemplate}
                            >
                              <img
                                src={excelicon}
                                alt="download-template-icon"
                                className="img-fluid me-2 template-icon"
                              />
                              {APP_LABELS.UPLOAD_DOWNLOAD_FILE}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-12 mb-3">
                      <div className="ymca-bold-heading mb-2">
                        Step 2 : Upload the file for user data.
                      </div>
                      <div className="card outline-card ymca-form px-3">
                        <div className="col-sm-6 col-md-12 text-center mt-3">
                          <div>
                            <button
                              className="btn ymca-primary-btn login-btn custom-align"
                              type="button"
                              {...getRootProps({ style })}
                            >
                              <input
                                {...getInputProps()}
                                className="form-control file-input-control"
                              />
                              {APP_LABELS.DETAIL_UPLOAD_DATA}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
            {redirect && <Redirect to="/userManagement/upload/data" />}
          </div>
        </main>
      </BlockUi>

      {/* Upload wait Modal */}
      <CustomModal
        show={showDownloadWaitPopup}
        type={APP_LABELS.UPLOAD_FILE_PLEASE_WAIT}
        icon={waiticon}
        message={APP_LABELS.UPLOAD_FILE_HEADING}
      />

      {/* Error Modal */}
      <ErrorPopup
        handleCloseErrorPopup={handleCloseErrorPopup}
        errorMessage={showErrorMessage}
        showErrorPopup={showErrorPopup}
      />
    </>
  );
};

UploadFile.propTypes = {
  handleGetUserManagementUploadFile: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetUserManagementUploadFile: getUserManagementUploadFile
};

export default connect(null, mapDispatchToProps)(UploadFile);
