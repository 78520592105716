import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import BreadcrumbIcon from '../../static/images/breadcrumb-icon.svg';

const Breadcrumbs = props => {
  const { pathName } = props;
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb ymca-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/cdp/dashboard">
            <span>Dashboard</span>
          </Link>
          <img src={BreadcrumbIcon} className="breadcrumb-icon" alt="" />
        </li>
        <li className="breadcrumb-item active">{pathName}</li>
      </ol>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  pathName: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Breadcrumbs;
