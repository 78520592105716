import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => (
  <div className="error-page">
    <h2>404: Not found</h2>
    <Link to="reservations">Goto Home Page</Link>
  </div>
);

export default ErrorPage;
