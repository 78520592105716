export const SWIMSAFETY_DASHBOARD_COUNT_SUCCESS =
  'SWIMSAFETY_DASHBOARD_COUNT_SUCCESS';
export const SWIMSAFETY_DASHBOARD_COUNT_FAILURE =
  'SWIMSAFETY_DASHBOARD_COUNT_FAILURE';
export const SWIMSAFETY_ADD_ASSOCIATION_SUCCESS =
  'SWIMSAFETY_ADD_ASSOCIATION_SUCCESS';

export const SWIMSAFETY_DELETE_ASSOCIATION_SUCCESS =
  'SWIMSAFETY_DELETE_ASSOCIATION_SUCCESS';
export const SWIMSAFETY_DELETE_ASSOCIATION_FAILURE = 'SWIMSAFETY_DELETE_ASSOCIATION_FAILURE'

export const SWIMSAFETY_ASSOCIATION_LIST_SUCCESS =
  'SWIMSAFETY_ASSOCIATION_LIST_SUCCESS';
export const SWIMSAFETY_ASSOCIATION_LIST_FAILURE =
  'SWIMSAFETY_ASSOCIATION_LIST_FAILURE';

export const SWIMSAFETY_ASSOCIATION_DROPDOWN_SUCCESS =
  'SWIMSAFETY_ASSOCIATION_DROPDOWN_SUCCESS';
export const SWIMSAFETY_ASSOCIATION_DROPDOWN_FAILURE =
  'SWIMSAFETY_ASSOCIATION_DROPDOWN_FAILURE';

export const SWIMSAFETY_EXCLUDE_ASSOCIATION_DROPDOWN_SUCCESS =
  'SWIMSAFETY_EXCLUDE_ASSOCIATION_DROPDOWN_SUCCESS';
export const SWIMSAFETY_EXCLUDE_ASSOCIATION_DROPDOWN_FAILURE =
  'SWIMSAFETY_EXCLUDE_ASSOCIATION_DROPDOWN_FAILURE';

export const SWIMSAFETY_SAVE_ASSOCIATION_SUCCESS =
  'SWIMSAFETY_SAVE_ASSOCIATION_SUCCESS';
export const SWIMSAFETY_SAVE_ASSOCIATION_FAILURE =
  'SWIMSAFETY_SAVE_ASSOCIATION_FAILURE';

export const SWIMSAFETY_GRANT_DROPDOWN_SUCCESS =
  'SWIMSAFETY_GRANT_DROPDOWN_SUCCESS';
export const SWIMSAFETY_GRANT_DROPDOWN_FAILURE =
  'SWIMSAFETY_GRANT_DROPDOWN_FAILURE';
