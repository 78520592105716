import { IMPORT_DATA } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getImportData = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: IMPORT_DATA(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.IMPORT_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.IMPORT_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });

export const pushData = () => dispatch => {
  dispatch({
    type: types.PUSH_DATA
  });
};
