/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import authentication from 'react-azure-b2c';
import { APP_LABELS, ENUMS, STORAGE_KEYS } from '@config/app-config';
import PropTypes from 'prop-types';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getAssociationNumber } from '@redux/dashboard/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { getAuthConfig } from '@config/api-config';
import ReactGA from 'react-ga4';
import YMCALogo from '../../static/images/ymca-logo-light.svg';
import profileicon from '../../static/images/profile.svg';
import downarrowicon from '../../static/images/dropdown-arrow-white.svg';
import SecureStorage from '../../utilities/SecureStorage';

const Header = props => {
  const [dashboardURL, setDashboardURL] = useState('');
  const [reRender, setRerender] = useState(false);
  // eslint-disable-next-line no-restricted-globals
  const locationPath = location;

  const path = locationPath.pathname.split('/')[1];
  const mainMenuPath = path.split('-')[0];
  const nextPath = path.split('-')[1];

  const {
    AssociationNumberData,
    handleAssociationNumber,
    LoginUserData,
    Path,
    ActualPath
  } = props;
  const actualPath = ActualPath;

  // const personid = sessionStorage.getItem('personid');
  const personid = SecureStorage.getItem(STORAGE_KEYS.PERSON_ID);
  const token = JSON.parse(sessionStorage.getItem('token'));
  const [associationName, setAssociationName] = useState('');
  const role = get(LoginUserData, 'user.role[0]');
  const branches = get(LoginUserData, 'user.branches', null);
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const dashboardEndpoint = SecureStorage.getItem(STORAGE_KEYS.DASHBOARD_URL);
  const handelDashboardURL = () => {
    if (mainMenuPath === 'employee') {
      setDashboardURL('/employee/dashboard');
    } else if (mainMenuPath === 'annual') {
      setDashboardURL('/annual/report/dashboard');
    } else if (mainMenuPath === 'volunteer') {
      setDashboardURL('/volunteer/dashboard');
    } else if (mainMenuPath === 'cdp') {
      setDashboardURL('/cdp/dashboard');
    } else if (mainMenuPath === 'swim') {
      setDashboardURL(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          APP_LABELS.VIEWER_PERMISSION_ROLES.includes(role)
          ? '/swim/dashboard'
          : '/swim/association/dashboard'
      );
    } else if (mainMenuPath === 'insurance') {
      setDashboardURL(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          APP_LABELS.COI_PERMISSION_ROLES.includes(role)
          ? '/insurance/dashboard'
          : '/insurance/association/dashboard'
      );
    } else {
      setDashboardURL(dashboardEndpoint);
    }
  };

  // eslint-disable-next-line no-undef
  $(document).on('change', '.goog-te-combo', e => {
    sessionStorage.setItem(`${personid}`, e.target.value);
    document.querySelectorAll('.VIpgJd-ZVi9od-ORHb-OEVmcd').forEach(el => {
      el.style.display = 'none';
    });
  });
  function setCookie(key, value) {
    document.cookie = `${key}=${value}`;
  }

  function loadGoogleTranslate() {
    const language = sessionStorage.getItem(personid);
    let ckDomain;
    for (
      ckDomain = window.location.hostname.split('.');
      ckDomain.length > 2;

    ) {
      ckDomain.shift();
    }
    ckDomain = `;domain=${ckDomain.join('.')}`;
    if (language === undefined || language === null || language === 'en') {
      document.cookie = `googtrans='';max-age=0;path=/; `;
      document.cookie = `googtrans='';max-age=0;path=/; domain=${ckDomain}`;
    } else {
      setCookie('googtrans', `/en/${language}`);
    }

    // eslint-disable-next-line no-new
    new window.google.translate.TranslateElement(
      { includedLanguages: 'de,en,es,fr' },
      'google_element'
    );
    document.querySelectorAll('.VIpgJd-ZVi9od-l4eHX-hSRGPd').forEach(el => {
      el.style.display = 'none';
    });
    document.querySelectorAll('.VIpgJd-ZVi9od-aZ2wEe-wOHMyf').forEach(el => {
      el.style.display = 'none';
    });
    const divsToRemove = document?.getElementsByClassName('goog-te-combo');
    if (divsToRemove.length > 1)
      for (let i = 0; i < divsToRemove?.length; i++) {
        divsToRemove[i]?.remove();
      }
  }

  useEffect(() => {
    handelDashboardURL();
  });

  useEffect(() => {
    authentication.initialize(getAuthConfig());

    if (
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
    ) {
      if (get(associateData, 'associationNumber')) {
        handleAssociationNumber(get(associateData, 'associationNumber', ''));
      }
    } else if (
      get(LoginUserData, 'user.associations[0]') &&
      role !== undefined
    ) {
      handleAssociationNumber(get(LoginUserData, 'user.associations[0]', '0'));
    }

    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=loadGoogleTranslate'
    );
    document.body.appendChild(addScript);
    window.loadGoogleTranslate = loadGoogleTranslate;
  }, []);

  useEffect(() => {
    setRerender(!reRender);
    ReactGA.send(window.location.pathname);
  }, [Path, ActualPath]);

  useEffect(() => {
    if (
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
    ) {
      setAssociationName(get(associateData, 'associationName', ''));
    } else {
      setAssociationName(
        get(AssociationNumberData, 'data.association.associationName', '')
      );
    }
  }, [AssociationNumberData, associateData]);

  const logout = () => {
    SecureStorage.removeItem(STORAGE_KEYS.FETCH_ID);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('pledgeassociationname');
    sessionStorage.removeItem('associationname');
    sessionStorage.removeItem('strapitoken');
    sessionStorage.removeItem('dirty');
    sessionStorage.removeItem('persist:root');
    sessionStorage.removeItem('yusa_selected_associate');
    sessionStorage.removeItem('swim_Program_selected_tab');
    sessionStorage.clear();
    SecureStorage.clear();
    authentication.signOut();
  };

  const setFeedbackPath = () => '/feedback/support';

  const setTitleValue = pathValue => {
    switch (pathValue) {
      case 'employee':
        return APP_LABELS.EMPLOYEE_DATA_COLLECTION;
      case 'annual':
        return APP_LABELS.ANNUAL_REPORTS;
      case 'volunteer':
        return ENUMS.VOLUNTEER_REGISTRATION;
      case 'cdp':
        return APP_LABELS.CDP_TITLE;
      case 'swim':
        return APP_LABELS.SWIM_TITLE;
      case 'insurance':
        return APP_LABELS.INSURANCE_TITLE;
      default:
        return '';
    }
  };

  return (
    <header className="header">
      <nav className="navbar navbar-expand-sm ymca-navbar" id="navbar">
        <div className="container">
          <h5 className="brand-logo notranslate">{APP_LABELS.TOP_HEADING}</h5>
          <div
            id="google_element"
            className="ymca-google-translate me-auto ms-4"
          />
          {path !== 'home' && path !== 'feedback-support' && (
            <h5 className="brand-logo">{setTitleValue(mainMenuPath)}</h5>
          )}
        </div>
      </nav>

      <div className="ymca-menubar d-flex align-items-center">
        <div className="container ">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <nav className="navbar navbar-expand-lg py-0">
                <Link className="navbar-brand" to="/home">
                  <img src={YMCALogo} alt="ymca-logo" />
                </Link>
                <div className="ymca-association-name ms-4 notranslate">
                  {actualPath === 'employee/association/dashboard' ||
                  (actualPath === 'employee/dashboard' &&
                    token?.user?.role[0] !== APP_LABELS.YUSA_DATA_STEWARD &&
                    token?.user?.role[0] !== APP_LABELS.YESS_DATA_STEWARD &&
                    token?.user?.role[0] !== APP_LABELS.YUSA_DATA_VIEWER &&
                    token?.user?.role[0] !==
                      APP_LABELS.ALLIANCE_DATA_STEWARD) ||
                  actualPath === 'employee/dashboard/upload/file' ||
                  actualPath === 'employee/dashboard/detailView' ||
                  actualPath === 'employee/dashboard/upload/data' ||
                  actualPath === 'annual/report/dashboard' ||
                  actualPath === 'annual/report' ||
                  actualPath === 'cdp/associate/dashboard' ||
                  actualPath === 'cdp/upload/data' ||
                  actualPath === 'cdp/details' ||
                  actualPath === 'employee/moyp/detailView' ||
                  actualPath === 'swim/association/dashboard' ||
                  actualPath === 'swim/safety/add/branch' ||
                  actualPath === 'swim/safety/addprogram' ||
                  actualPath === 'swim/safety/add/profiles' ||
                  actualPath === 'swim/upload/profile' ||
                  actualPath === 'swim/upload/data' ||
                  actualPath === 'swim/safety/program/details' ||
                  actualPath === 'insurance/association/dashboard' ? (
                    <div> {associationName} </div>
                  ) : (
                    ''
                  )}
                  {actualPath === 'pledge-statement' && (
                    <div className="mw-100">
                      {sessionStorage.getItem('pledgeassociationname')}
                    </div>
                  )}
                </div>
                <button
                  className="navbar-toggler ms-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarContent"
                  aria-controls="navbarContent"
                  aria-expanded="true"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon text-white">
                    <i className="fa fa-bars" aria-hidden="true" />
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse ymca-newNav"
                  id="navbarContent"
                >
                  <ul className="nav navbar-nav main-header ms-auto">
                    {nextPath !== 'error' && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            mainMenuPath === 'home' ? APP_LABELS.NAV_ACTIVE : ''
                          }`}
                          to="/home"
                        >
                          {APP_LABELS.NAV_HOME}
                        </Link>
                      </li>
                    )}

                    {actualPath !== 'home' &&
                      actualPath !== 'annual/yusa/dashboard' &&
                      actualPath !== 'volunteer/registration/preview' &&
                      actualPath !== 'volunteer/registration' &&
                      dashboardEndpoint !== '' &&
                      (branches?.length === 0 &&
                      role === APP_LABELS.BRANCH_DATA_STEWARD ? (
                        ''
                      ) : (
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${
                              nextPath === 'dashboard' ||
                              nextPath === 'association' ||
                              actualPath === 'employee/dashboard' ||
                              actualPath === 'annual/report/dashboard' ||
                              actualPath === 'volunteer/dashboard' ||
                              actualPath === 'cdp/dashboard' ||
                              actualPath ===
                                (role === APP_LABELS.YUSA_DATA_STEWARD ||
                                APP_LABELS.VIEWER_PERMISSION_ROLES.includes(
                                  role
                                )
                                  ? 'swim/dashboard'
                                  : 'swim/association/dashboard') ||
                              actualPath ===
                                (role === APP_LABELS.YUSA_DATA_STEWARD ||
                                APP_LABELS.COI_PERMISSION_ROLES.includes(role)
                                  ? 'insurance/dashboard'
                                  : 'insurance/association/dashboard')
                                ? APP_LABELS.NAV_ACTIVE
                                : ''
                            }`}
                            to={dashboardURL}
                          >
                            {APP_LABELS.NAV_DASHBOARD}
                          </Link>
                        </li>
                      ))}
                    {nextPath !== 'error' && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            path.includes('feedback')
                              ? APP_LABELS.NAV_ACTIVE
                              : ''
                          }`}
                          to={setFeedbackPath()}
                        >
                          {APP_LABELS.FEEDBACK_SUPPORT}
                        </Link>
                      </li>
                    )}

                    {nextPath !== 'error' &&
                      role === APP_LABELS.YUSA_DATA_STEWARD && (
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${
                              path.includes('user') ? APP_LABELS.NAV_ACTIVE : ''
                            }`}
                            to="/userManagement/dashboard"
                          >
                            {APP_LABELS.USER_MANAGEMENT}
                          </Link>
                        </li>
                      )}
                    <div className="dropdown ymca-dropdown d-flex">
                      <button
                        className="btn btn-secondary logout-btn p-0 notranslate"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {token?.user?.firstName} {token?.user?.lastName}
                        <img alt="profile" src={profileicon} className="px-2" />
                        <img alt="downarrow" src={downarrowicon} />
                      </button>
                      <ul
                        className="dropdown-menu ymca-dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <button
                            type="button"
                            className="dropdown-item logoutButton"
                            onClick={logout}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 16"
                            >
                              <path d="M8.9165 3.08333L8.094 3.90583L9.599 5.41667H3.6665V6.58333H9.599L8.094 8.08833L8.9165 8.91667L11.8332 6L8.9165 3.08333ZM1.33317 1.91667H5.99984V0.75H1.33317C0.691504 0.75 0.166504 1.275 0.166504 1.91667V10.0833C0.166504 10.725 0.691504 11.25 1.33317 11.25H5.99984V10.0833H1.33317V1.91667Z" />
                            </svg>
                            <span className="ms-1">Logout</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAssociationNumber: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  Path: PropTypes.string.isRequired,
  ActualPath: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationNumberData: AssociationNumberSelector(state)
});

const mapDispatchToProps = {
  handleAssociationNumber: getAssociationNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Header));
