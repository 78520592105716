import { GET_USER_DETAILS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const getUserManagementTableList = AssociationId => (dispatch, getState) =>
  asyncRequest({
    url: GET_USER_DETAILS(AssociationId),
    method: 'GET',
    successAction: data => ({
      type: types.USER_MANAGEMENT_TABLE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.USER_MANAGEMENT_TABLE_FAILURE,
      payload: error
    }),
    dispatch
  });

export default getUserManagementTableList;
