/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import get from 'lodash.get';
import { connect } from 'react-redux';
import Help from '@modules/shared/components/reusable-components/help';
import helpicon from '@static/images/caretdoubleright.svg';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import moment from 'moment';

import { APP_LABELS, REGEX, ENUMS } from '@config/app-config';
import { GET_DOWNLOAD_FILE_PREVIEW } from '@config/api-config';
import getVolunteerRegistrationPreview from '@redux/swim-registration/volunteer-registeration-preview/actions';
import { VolunteerRegistrationPreviewSelector } from '@redux/swim-registration/volunteer-registeration-preview/selectors';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import BackIcon from '@static/images/back-icon.svg';
import InfoIcon from '@static/images/swimRegistration/info-icon.svg';
import ViewIcon from '@static/images/swimRegistration/view-icon.svg';
import EditPencilIcon from '@static/images/swimRegistration/edit-pencil-icon.svg';
import exclamationicon from '@static/images/exclamation.png';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import { getDocumentTitle } from '@state/utils/utilities';
import VolunteerAttestationModal from './volunteer-attestation-modal';
import PreviewModal from './preview-modal';
import RenderStatusIcon from './renderStatusIcon';

const { DATE_FORMAT_FOR_DOCUMENT, PAGE_DATE_FORMAT, EXPIRY_DATE_FORMAT } =
  REGEX;

const VolunteerRegistrationPreview = props => {
  const {
    handleGetVolunteerRegistrationPreview,
    VolunteerRegistrationPreviewSelectorData
  } = props;
  const [fileURI, setFileURI] = useState();
  const [help, setHelp] = useState(false);
  const [documentTitle, setDocumentTitle] = useState();
  const [isPdfViewer, setIsPdfViewer] = useState(true);
  const authToken = JSON.parse(sessionStorage.getItem('token'));
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    setLoader(true);
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'volunteer-registration'
      ]?.Instruction
    );
    handleGetVolunteerRegistrationPreview().then(res => {
      if (res.status === 200) {
        if (res?.data?.data?.volunteerId === 0) {
          setRedirect(true);
        }
        setPopup(
          res?.data?.data?.volunteerDocuments?.some(
            item => item.status === 'Rejected'
          )
        );
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  }, []);

  const setExpiryDateFormat = expiryDate =>
    moment(expiryDate).format(EXPIRY_DATE_FORMAT);

  const getFileExtension = filename => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
  };

  const openPDF = async (fileName, documentTypeString) => {
    setDocumentTitle(documentTypeString);
    setLoader(true);
    const URI = `${GET_DOWNLOAD_FILE_PREVIEW(fileName)}`;
    const extn = getFileExtension(fileName)
      ? getFileExtension(fileName).toLowerCase()
      : '';
    let typeValue = 'application/pdf';
    // code for image files
    if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') {
      setIsPdfViewer(false);
      typeValue = `image/${extn}`;
    } else {
      setIsPdfViewer(true);
    }
    axios
      .get(URI, {
        responseType: 'arraybuffer',
        headers: {
          multipartFormData: false,
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(response => {
        const bytes = new Uint8Array(response.data); // use this if data is raw bytes else directly pass resData
        console.log('response.data', response.data);
        const blob = new window.Blob([bytes], { type: typeValue });
        const downloadUrl = window.URL.createObjectURL(blob);
        setFileURI(downloadUrl);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const getDocumentStatus = status => {
    if ([ENUMS.DENIED].includes(status)) {
      return ENUMS.UNDER_REVIEW;
    }
    if ([ENUMS.SUCCESS].includes(status)) {
      return ENUMS.INITIATED;
    }
    return status;
  };
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <button
          type="button"
          className="ymca-help-link text-decoration-none ymca-up-data-help-link  "
          onClick={handleShowHelp}
        >
          HELP{/* */}
          <img src={helpicon} className="img-fluid" alt="caret-icon" />
        </button>
        <div className="ymca-data-wrapper volunteer-details-wrapper">
          <div className="container">
            <section className="dashboard-header mb-4">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2 className="ymca-sub-heading mb-0">
                    Volunteer Registration
                  </h2>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                  <Link className="ymca-title-link" to="/home">
                    <img
                      src={BackIcon}
                      className="ymca-back-link mb-2"
                      alt="back-icon"
                    />
                    {/* */}
                    Back
                  </Link>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <div className="ymca-instructionBoxes">
                  <h4 className="ymca-instructHeadline">
                    {APP_LABELS.INSTRUCTIONS}
                  </h4>
                  <p className="ymca-instructTitle">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: instructions
                      }}
                    />
                  </p>
                </div>
              </div>
            </section>
          </div>
          {/* <hr className="custom-hr" /> */}

          <div className="container">
            <div className="volunteer-details-top-status">
              Registered Date & Time -{/* */}
              <span className="vd-date notranslate">
                &nbsp;
                {moment(
                  convertUtcToLocal(
                    get(
                      VolunteerRegistrationPreviewSelectorData,
                      'data.registrationDate'
                    )
                  )
                ).format(PAGE_DATE_FORMAT)}
              </span>
              <span className="table-divider me-3">|</span> Status
              <RenderStatusIcon
                statusValue={
                  VolunteerRegistrationPreviewSelectorData?.data?.status
                }
                isAdminPreview={false}
              />
              {/* {VolunteerRegistrationPreviewSelectorData?.data?.status ===
                'Rejected' && ( */}
              <Link
                className="ymca-title-link volunteer-edit text-end"
                to="/volunteer/registration"
              >
                <img
                  src={EditPencilIcon}
                  className="me-2 mb-1"
                  alt="edit-pencil-icon"
                />
                {/* */}
                edit volunteer details
              </Link>
              {/* // )} */}
            </div>
            {(get(
              VolunteerRegistrationPreviewSelectorData,
              'data.status',
              '-'
            ) === ENUMS.NEW ||
              get(
                VolunteerRegistrationPreviewSelectorData,
                'data.status',
                '-'
              ) === ENUMS.PENDING) && (
              <div className="alert ymca-wait-alert" role="alert">
                Waiting for the approval
              </div>
            )}
            {get(
              VolunteerRegistrationPreviewSelectorData,
              'data.status',
              '-'
            ) === ENUMS.REJECTED && (
              <div className="alert ymca-reject-alert" role="alert">
                {get(
                  VolunteerRegistrationPreviewSelectorData,
                  'data.adminComment',
                  '-'
                )}
              </div>
            )}
            <div className="card volunteer-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Name</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.firstName',
                        '-'
                      )}{' '}
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.lastName',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Date Of Birth</div>
                    <div className="ymca-dark-para-3">
                      {moment(
                        get(
                          VolunteerRegistrationPreviewSelectorData,
                          'data.birthDate',
                          '-'
                        )
                      ).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Email Address</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.emailAddress',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Phone Number</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.phoneNumber',
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Address 1</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.address1',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Address 2</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.address2',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">City</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.city',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">ZIP Code</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.zipCode',
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">State</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.state',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Country</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerRegistrationPreviewSelectorData,
                        'data.country',
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-3">
                    <div className="ymca-dark-para-4">Registration</div>
                    <div className="d-flex mt-2">
                      {VolunteerRegistrationPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_SWIMMING_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge pink-badge">
                          Swimming
                        </span>
                      )}

                      {VolunteerRegistrationPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_GYMNASTICS_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge voilet-badge">
                          Gymnastics
                        </span>
                      )}

                      {VolunteerRegistrationPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_DIVING_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge purple-badge">
                          Diving
                        </span>
                      )}
                      {VolunteerRegistrationPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_PICKLE_BALL_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge dark-purple-badge">
                          Pickleball
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {popup && (
                <div className="YMCCA-tostAlert overflow-hidden mt-3">
                  <div className="YMCCA-textList">
                    <img src={exclamationicon} alt="exclamation" />
                    <span>
                      One or more of your documents have been rejected or are
                      expired. Please click edit to upload a new document and
                      re-submit.
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="table-responsive custom-scrollbar mt-3">
              <table className="table ymca-table">
                <thead>
                  <tr>
                    <th scope="col">Document Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Uploaded On</th>
                    <th scope="col">Expiration Date</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments?.map(
                    row => (
                      <tr key={row?.documentTypeString}>
                        <td>
                          {getDocumentTitle(row?.documentTypeString)}
                          <span
                            title="Upload your verification of clearing a Level 2 or 
                                        YMCA of the USA administered Background Screening here. "
                          >
                            <img
                              src={InfoIcon}
                              className="img-fluid success-img"
                              alt="success-img"
                            />
                          </span>
                        </td>
                        <td>
                          <RenderStatusIcon
                            statusValue={getDocumentStatus(
                              get(row, 'status', '-')
                            )}
                            isAdminPreview={false}
                          />
                        </td>
                        <td>{row?.comment ? row?.comment : '-'}</td>
                        <td className="notranslate">
                          {moment(
                            convertUtcToLocal(get(row, 'uploadedOn'))
                          ).format(DATE_FORMAT_FOR_DOCUMENT)}
                        </td>
                        <td className="notranslate">
                          {row?.expiryDate
                            ? setExpiryDateFormat(row?.expiryDate)
                            : '-'}
                        </td>
                        <td>
                          {row?.documentTypeString ===
                          ENUMS.VOLUNTEER_ATTESTATION ? (
                            <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              data-bs-target="#submitModal"
                            >
                              <span className="volunteer-action-view" href="#">
                                <img
                                  src={ViewIcon}
                                  className="img-fluid mx-2"
                                  alt="view-icon-img"
                                />
                              </span>
                            </button>
                          ) : (
                            <>
                              {row?.url && row?.url !== '' && (
                                <button
                                  type="button"
                                  className="btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={() =>
                                    openPDF(row.url, row.documentTypeString)
                                  }
                                >
                                  <span
                                    className="volunteer-action-view"
                                    href="#"
                                  >
                                    <img
                                      src={ViewIcon}
                                      className="img-fluid mx-2"
                                      alt="view-icon-img"
                                    />
                                  </span>
                                </button>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="text-end">
              <Link
                to="/volunteer/registration"
                className="btn ymca-primary-btn login-btn mt-0 text-capitalize"
                type="button"
              >
                Edit volunteer details
              </Link>
            </div>
          </div>
        </div>
        {console.log('fileURI', fileURI)}
        <PreviewModal
          fileURL={fileURI}
          pdfViewerFlag={isPdfViewer}
          documentTitle={documentTitle}
        />
        <VolunteerAttestationModal
          acceptedName={get(
            VolunteerRegistrationPreviewSelectorData,
            'data.tandCacceptedName',
            '-'
          )}
          acceptedDateTime={get(
            VolunteerRegistrationPreviewSelectorData,
            'data.tandCacceptedDateTime',
            '-'
          )}
          acceptedTermsAndConditions={get(
            VolunteerRegistrationPreviewSelectorData,
            'data.termsAndCondition',
            false
          )}
        />
        {help && (
          <Help hidePopup={hideShowHelp} keyValue="volunteer-registration" />
        )}
      </main>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {redirect && <Redirect to="/volunteer/registration" />}
    </BlockUi>
  );
};

VolunteerRegistrationPreview.propTypes = {
  handleGetVolunteerRegistrationPreview: PropTypes.func.isRequired,
  VolunteerRegistrationPreviewSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired
};

const mapDispatchToProps = {
  handleGetVolunteerRegistrationPreview: getVolunteerRegistrationPreview
};

const mapStateToProps = state => ({
  VolunteerRegistrationPreviewSelectorData:
    VolunteerRegistrationPreviewSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VolunteerRegistrationPreview));
