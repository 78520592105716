import * as types from './types';

const initialState = {
  GetOlderAdults: {},
  OlderAdults: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.OLDER_ADULTS_GET_SUCCESS: {
      return {
        ...state,
        GetOlderAdults: action.payload
      };
    }
    case types.OLDER_ADULTS_GET_FAILURE:
      return { ...state, error: action.payload };
    case types.OLDER_ADULTS_SUCCESS: {
      return {
        ...state,
        OlderAdults: action.payload
      };
    }
    case types.OLDER_ADULTS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
