import { CDP } from '@config/app-config';
import {
  SOLERA_TABLE_COLUMNS,
  WELLD_TABLE_COLUMNS,
  DOL_PARTICIPANT_COLUMNS,
  DOL_PARTNERSHIP_COLUMNS,
  DOL_PL_COLUMNS,
  OCHI_BPSM_COLUMNS,
  EF_COLUMNS,
  LS_COLUMNS,
  HW_COLUMNS
} from '@modules/chronic-disease-program/cdp-table-columns.jsx';

const CDP_COLUMNS = event => [
  { DPRP: SOLERA_TABLE_COLUMNS(event) },
  { [CDP.DOL_TYPE_NAMES.ParticipantLevel]: DOL_PL_COLUMNS(event) },
  { [CDP.DOL_TYPE_NAMES.AggregateParticipant]: DOL_PARTICIPANT_COLUMNS(event) },
  { [CDP.DOL_TYPE_NAMES.AggregatePartnership]: DOL_PARTNERSHIP_COLUMNS(event) },
  { [CDP.DATA_SYSTEM_NAMES.DOL]: SOLERA_TABLE_COLUMNS(event) },
  { [CDP.DATA_SYSTEM_NAMES.SOLERA]: SOLERA_TABLE_COLUMNS(event) },
  { [CDP.DATA_SYSTEM_NAMES.OCHI]: SOLERA_TABLE_COLUMNS(event) },
  { [CDP.DATA_SYSTEM_NAMES.HABITNU]: SOLERA_TABLE_COLUMNS(event) },
  { [CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC]: SOLERA_TABLE_COLUMNS(event) },
  { [CDP.DATA_SYSTEM_NAMES.WELLD]: WELLD_TABLE_COLUMNS(event) },
  { BPSM_OCHI: OCHI_BPSM_COLUMNS(event) },
  { BPSM_DPRP: OCHI_BPSM_COLUMNS(event) },
  { EF: EF_COLUMNS(event) },
  { LS: LS_COLUMNS(event) },
  { HW: HW_COLUMNS(event) }
];

export default CDP_COLUMNS;
