export const SWIMSAFETY_UPLOAD_PROFILES_SUCCESS =
  'SWIMSAFETY_UPLOAD_PROFILES_SUCCESS';

export const SWIMSAFETY_SELECTED_PROGRAM_SUCCESS =
  'SWIMSAFETY_SELECTED_PROGRAM_SUCCESS';

export const SWIMSAFETY_IMPORT_PROFILES_SUCCESS =
  'SWIMSAFETY_IMPORT_PROFILES_SUCCESS';

export const SWIMSAFETY_PROFILES_API_FAILURE =
  'SWIMSAFETY_PROFILES_API_FAILURE';

export const SWIMSAFETY_PROFILES_LIST_SUCCESS =
  'SWIMSAFETY_PROFILES_LIST_SUCCESS';

export const SWIMSAFETY_PROFILES_LIST_FAILURE =
  'SWIMSAFETY_PROFILES_LIST_FAILURE';

export const SWIMSAFETY_PROFILE_DELETE_SUCCESS =
  'SWIMSAFETY_PROFILE_DELETE_SUCCESS';
export const SWIMSAFETY_PROFILE_DELETE_FAILURE =
  'SWIMSAFETY_PROFILE_DELETE_FAILURE';

export const SWIMSAFETY_PROFILE_SAVE_SUCCESS =
  'SWIMSAFETY_PROFILE_SAVE_SUCCESS';
export const SWIMSAFETY_PROFILE_SAVE_FAILURE =
  'SWIMSAFETY_PROFILE_SAVE_FAILURE';

export const SWIMSAFETY_PROFILE_DETAILS_SUCCESS =
  'SWIMSAFETY_PROFILE_DETAILS_SUCCESS';

export const SWIMSAFETY_PROFILE_DETAILS_FAILURE =
  'SWIMSAFETY_PROFILE_DETAILS_FAILURE';

export const SWIMSAFETY_PROFILE_BY_PROGRAM_ID_SUCCESS =
  'SWIMSAFETY_PROFILE_BY_PROGRAM_ID_SUCCESS';

export const SWIMSAFETY_PROFILE_BY_PROGRAM_ID_FAILURE =
  'SWIMSAFETY_PROFILE_BY_PROGRAM_ID_FAILURE';

export const SWIMSAFETY_PROFILES_BY_FILTERS_SUCCESS =
  'SWIMSAFETY_PROFILES_BY_FILTERS_SUCCESS';

export const SWIMSAFETY_PROFILES_BY_FILTERS_FAILURE =
  'SWIMSAFETY_PROFILES_BY_FILTERS_FAILURE';
