export const FetchOverViewDataSelector = ({
  annualReportOverview: { FetchOverViewData }
}) => FetchOverViewData;

export const YmcaBrachDataSelector = ({
  annualReportOverview: { YmcaBranchList }
}) => YmcaBranchList;

export const CollabNonProfitOrgSelector = ({
  annualReportOverview: { CollabNonProfitOrg }
}) => CollabNonProfitOrg;

export const CollabEducationOrgSelector = ({
  annualReportOverview: { CollabEducationOrg }
}) => CollabEducationOrg;

export const CollabGovtOrgAndAffiliatesSelector = ({
  annualReportOverview: { CollabGovtOrgAndAffiliates }
}) => CollabGovtOrgAndAffiliates;

export const CollabOtherCommunityOrgSelector = ({
  annualReportOverview: { CollabOtherCommunityOrg }
}) => CollabOtherCommunityOrg;

export const CollabPrivateOrgAndBusinessesSelector = ({
  annualReportOverview: { CollabPrivateOrgAndBusinesses }
}) => CollabPrivateOrgAndBusinesses;

export const CollabAmongYsSelector = ({
  annualReportOverview: { CollaborationsAmongYs }
}) => CollaborationsAmongYs;

export const DashboardOverviewSelector = ({
  annualReportOverview: { DashboardOverview }
}) => DashboardOverview;
