/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import Multiselect from 'multiselect-react-dropdown';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { useDropzone } from 'react-dropzone';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import {
  getFileTemplateURL,
  getUploadFile,
  getTermsandConditions
} from '@redux/upload-file/actions';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';
import {
  FetchFileTemplateURLSelector,
  FetchFileTemplateSelector
} from '@redux/upload-file/selectors';
import {
  GET_FILE_DOWNLOAD_PATH,
  GET_BLANK_FILE_DOWNLOAD_PATH
} from '@config/api-config';
import { APP_LABELS } from '@config/app-config';
import Help from '@modules/shared/components/reusable-components/help';
import waiticon from '@static/images/wait-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import excelicon from '@static/images/excel-icon.svg';
import warningiconmedium from '@static/images/warning-icon-medium.svg';
import helpicon from '@static/images/caretdoubleright.svg';
import CustomModal from '@utilities/CustomModal';

import ErrorPopup from './error-popup';

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const UploadFile = props => {
  const {
    LoginUserData,
    AssociationNumberData,
    handleGetFileTemplateURL,
    handleGetUploadFile,
    handleGetTermsandConditions
  } = props;
  const authToken = JSON.parse(sessionStorage.getItem('token'));
  let isError = false;
  const [redirect, setRedirect] = useState(false);
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [showDownloadWaitPopup, setShowDownloadWaitPopup] = useState(false);
  const [enableFetchButton, setEnableFetchButton] = useState(false);
  const [enableUploadButton, setEnableUploadButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [branchDropdown, setBranchDropdown] = useState(
    AssociationNumberData?.data ? AssociationNumberData?.data?.branches : ''
  );
  const [uploadDataBranchDropdown, setUploadDataBranchDropdown] = useState(
    AssociationNumberData?.data ? AssociationNumberData?.data?.branches : ''
  );
  const [
    uploadDataSelectedBranchDropDown,
    setUploadDataSelectedBranchDropDown
  ] = useState(Array(AssociationNumberData?.data?.branches[0]));
  const [
    uploadDataSelectedBranchDropDownList,
    setUploadDataSelectedBranchDropDownList
  ] = useState();
  const [selectedBranchDropDown, setSelectedBranchDropDown] = useState(
    Array(AssociationNumberData?.data?.branches[0])
  );
  const [selectedBranchDropDownList, setSelectedBranchDropDownList] =
    useState();

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');

  const [help, setHelp] = useState(false);
  const [localYdisclosureConsent, setLocalYdisclosureConsent] = useState(false);
  const [localYdisclosureConsentInitial, setLocalYdisclosureConsentInitial] =
    useState(false);
  const handleOnAssociationSelect = () => {
    setBranchDropdown(AssociationNumberData?.data?.branches);
    setSelectedBranchDropDown(Array(AssociationNumberData.data.branches[0]));
    setEnableFetchButton(false);
  };

  const handleOnAssociationRemove = () => {
    setSelectedBranchDropDown();
    setEnableFetchButton(true);
  };
  const handleOnUploadAssociationSelect = () => {
    setUploadDataBranchDropdown(AssociationNumberData?.data?.branches);
    setUploadDataSelectedBranchDropDown(
      Array(AssociationNumberData.data.branches[0])
    );
    setEnableUploadButton(false);
  };

  const handleOnUploadAssociationRemove = () => {
    setUploadDataSelectedBranchDropDown();
    setEnableUploadButton(true);
  };

  const handleOnBranchSelect = selectedList => {
    setSelectedBranchDropDownList(selectedList.map(data => data.branchNumber));
    setEnableFetchButton(false);
  };

  const handleOnBranchRemove = selectedList => {
    setSelectedBranchDropDownList(selectedList.map(data => data.branchNumber));
    if (selectedList.length === 0) {
      setEnableFetchButton(true);
    }
  };
  const handleOnUploadBranchSelect = selectedList => {
    setUploadDataSelectedBranchDropDownList(
      selectedList.map(data => data.branchNumber)
    );

    setEnableUploadButton(false);
  };

  const handleOnUploadBranchRemove = selectedList => {
    setUploadDataSelectedBranchDropDownList(
      selectedList.map(data => data.branchNumber)
    );

    if (selectedList.length === 0) {
      setEnableUploadButton(true);
    }
  };

  const handelGetDownloadFileURL = () => {
    setLoader(true);
    const fetchTempPayload = {
      associationNumber: get(
        AssociationNumberData,
        'data.association.associationNumber'
      ),
      branchNumbers:
      selectedBranchDropDownList ||
        selectedBranchDropDown?.map(branch => branch.branchNumber)
    };
    const handleDownloadTemplate = filePath => {
      setLoader(true);
      axios
        .get(GET_FILE_DOWNLOAD_PATH(filePath), {
          responseType: 'blob',
          headers: {
            'Content-Type': 'blob',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': window.location.origin,
            'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
            Authorization: `Bearer ${get(authToken, 'token', '')}`
          }
        })
        .then(response => {
          const associationName = get(
            AssociationNumberData,
            'data.association.associationName'
          );
          const fileName = `${associationName}-EmployeeData.xlsm`;

          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', fileName.replaceAll('"', ''));
          document.body.appendChild(link);
          link.click();
          link.remove();
          setLoader(false);
        });
    };

    handleGetFileTemplateURL(fetchTempPayload).then(res => {
      if (res?.status === 200) {
        if (res?.data?.status.status === 0) {
          handleDownloadTemplate(res?.data?.data);
        } else {
          setLoader(false);
          setShowErrorPopup(true);
          setShowErrorMessage(res?.data?.status?.message);
        }
      } else {
        setLoader(false);
        setShowErrorPopup(true);
        setShowErrorMessage(APP_LABELS.ERROR_MESSAGE);
      }
    });
  };

  const handleEmptyDownloadTemplate = () => {
    setLoader(true);
    const associationNumber = get(
      AssociationNumberData,
      'data.association.associationNumber'
    );

    axios
      .get(GET_BLANK_FILE_DOWNLOAD_PATH(associationNumber), {
        responseType: 'blob',
        headers: {
          'Content-Type': 'blob',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute(
          'download',
          'Y-Employee-Data-Collection-Template.xlsm'
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  const handleCloseAttentionPopup = () => setShowAttentionPopup(false);
  const handleDownloadEmptyTemplate = () => {
    handleEmptyDownloadTemplate();
    setShowAttentionPopup(false);
  };

  const handleShowAttentionPopup = () => setShowAttentionPopup(true);
  const AssociationArray = [
    get(AssociationNumberData, 'data.association.associationName', '')
  ];
  const UploadDataAssociationArray = [
    get(AssociationNumberData, 'data.association.associationName', '')
  ];
  async function handleUploadedFile(file, associationNumber, branchNumbers, consent) {
    const formData = new FormData();
    formData.append('UploadedFile', file);
    formData.append('AssociationNumber', associationNumber);
    for (let i = 0; i < branchNumbers.length; i++) {
      formData.append(`BranchNumbers[${i}]`, branchNumbers[i]);
    }
    formData.append('LocalYdisclosureConsent', consent===null?false:consent);
  
    const response = await handleGetUploadFile(formData);
  
    if (response.status === 200) {
      if (response.data?.status?.status === 0) {
        setRedirect(true);
      } else {
        setShowErrorMessage(response.data?.status?.message);
        setShowErrorPopup(true);
      }
    } else {
      setShowErrorMessage(APP_LABELS.ERROR_MESSAGE);
      setShowErrorPopup(true);
    }
  }
  const onDrop = acceptedFiles => {
    if (isError) {
      return;
    }
    const [file] = acceptedFiles;
    const AssociationNumber = get(
      AssociationNumberData,
      'data.association.associationNumber'
    );
    const BranchNumbers =
    uploadDataSelectedBranchDropDownList ||
      uploadDataSelectedBranchDropDown?.map(branch => branch.branchNumber);
    if (file === undefined) {
      setShowErrorMessage(
        'File could not be uploaded. Only Files with following extensions are allowed:.xlsm'
      );
      setShowErrorPopup(true);
      return;
    }
    setShowDownloadWaitPopup(true);
    handleUploadedFile(file, AssociationNumber, BranchNumbers, localYdisclosureConsent)
    .then(() => setShowDownloadWaitPopup(false))
    .catch(() => setShowDownloadWaitPopup(false));
  };

  const [instructions, setInstructions] = useState(false);
  const handleGetTermsandCondition = () => {
    setLoader(true);
    handleGetTermsandConditions(
      get(AssociationNumberData, 'data.association.associationNumber', '')
    ).then(res => {
      if (res?.status === 200 && res?.data?.status.status === 0) {
        setLocalYdisclosureConsent(res?.data?.data?.localYDisclosureConsent);
        setLocalYdisclosureConsentInitial(
          res?.data?.data?.localYDisclosureConsent
        );
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'upload-employee-data'
      ]?.Instruction
    );
    handleGetTermsandCondition();
  }, []);
  const fileValidator = file => {
    if (file.size > 10000000) {
      isError = true;
      setShowErrorMessage(APP_LABELS.MAX_UPLOAD_FILE_ERROR_MESSAGE);
      setShowErrorPopup(true);
    }
  };

  const yusaTermsAndCondition = true;
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      validator: fileValidator,
      onDrop,
      accept: {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsm'
        ]
      }
    });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleCloseErrorPopup = () => setShowErrorPopup(false);
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  const handleChange = e => {
    const checked = e.target.checked;
    setLocalYdisclosureConsent(checked);
  };
  return (
    <>
      <BlockUi
        blocking={loader}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <main className="">
          <button
            type="button"
            className="ymca-help-link text-decoration-none"
            onClick={handleShowHelp}
          >
            HELP{/* */}
            <img src={helpicon} className="img-fluid" alt="caret-icon" />
          </button>
          <div className="ymca-data-wrapper">
            <div className="container">
              <div className="row mb-3 align-items-center">
                <div className="col-8">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ymca-breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/employee/dashboard">
                          {APP_LABELS.NAV_DASHBOARD}
                        </a>
                        <img
                          src={BreadcrumbIcon}
                          className="breadcrumb-icon"
                          alt=""
                        />
                      </li>
                      <li className="breadcrumb-item active">
                        {APP_LABELS.DETAIL_UPLOAD_DATA}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <div className="col-lg-11 col-md-8 col-sm-12">
                  <div className="user-name bg-transparent d-flex align-items-center">
                    <h2 className="ymca-sub-heading mt-3">
                      {APP_LABELS.UPLOAD_EMPLOYEE_DATA}
                    </h2>
                  </div>
                </div>
                <div className="col-lg-1 col-md-4 col-sm-12 text-lg-end text-md-end">
                  <Link
                    className="ymca-title-link d-flex align-items-center c-pointer"
                    to={{pathname:get(LoginUserData, 'user.role[0]')===APP_LABELS.YUSA_DATA_STEWARD||get(LoginUserData, 'user.role[0]')===APP_LABELS.YESS_DATA_STEWARD||get(LoginUserData, 'user.role[0]')===APP_LABELS.YUSA_DATA_VIEWER||get(LoginUserData, 'user.role[0]')===APP_LABELS.ALLIANCE_DATA_STEWARD?"/employee/association/dashboard":"/employee/dashboard",state:{fromDashboard:false}}}
                  >
                    <img src={BackIcon} className="me-1" alt="back-icon" />
                    {APP_LABELS.DASH_BACK}
                  </Link>
                </div>
                <div className="col-lg-12">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: instructions
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="custom-hr" />
            </div>
            <section className="data-upload-wrapper">
              <div className="container">
                <form>
                  <div className="row">
                    <div className="col-lg-10 col-md-12">
                      <div className="ymca-bold-heading mb-2">
                        {APP_LABELS.STEP_3_TEXT}
                      </div>
                      <div className="card outline-card ymca-form px-3">
                        <p className="ymca-textTitle mt-2 mb-1">
                          {APP_LABELS.AR_EMPLOYEE_LOCAL_Y}
                        </p>

                        <p className="ymca-textTitle mb-3">
                          {`Local Y has obtained consent from `}
                          {APP_LABELS.AR_EMPLOYEE_ITS_EMPLOYEE}
                        </p>
                        <p className="ymca-textTitle mb-3">
                          {APP_LABELS.AR_EMPLOYEE_YUSA}
                        </p>

                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            className=""
                            name="termsAndCondition"
                            onChange={handleChange}
                            checked={localYdisclosureConsent}
                            disabled={localYdisclosureConsentInitial}
                          />
                          <span className="ymca-cachet-normal-dark-text ms-2">
                            Local Y attests that they have followed the above
                            terms and conditions will govern the Workplace
                            Diversity Dashboard Initiative
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <input
                            type="checkbox"
                            className=""
                            name="yusaTermsAndCondition"
                            value
                            checked={yusaTermsAndCondition}
                            readOnly
                          />
                          <span className="ymca-cachet-normal-dark-text ymca-textTitle ms-2">
                            Y-USA agrees that the above terms and conditions
                            will govern the Workplace Diversity Dashboard
                            Initiative
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-12">
                      <div className="ymca-bold-heading mb-2">
                        {APP_LABELS.STEP_1_TEXT}
                      </div>
                      <div className="card outline-card ymca-form px-3">
                        <div className="row mb-3">
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group form-label mb-3">
                              {APP_LABELS.UPLOAD_FILE_ASSOCIATIONS}
                              <span className="text-mandatory ms-1">*</span>
                            </div>
                            <div className="notranslate">
                              <Multiselect
                                isObject={false}
                                showCheckbox
                                onSelect={handleOnAssociationSelect}
                                onRemove={handleOnAssociationRemove}
                                selectionLimit={50}
                                options={AssociationArray}
                                selectedValues={AssociationArray}
                                disable
                                hidePlaceholder
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <div className="form-group form-label mb-3">
                              {APP_LABELS.UPLOAD_FILE_BRANCH}
                              <span className="text-mandatory ms-1">*</span>
                            </div>
                            <div className="notranslate">
                              <Multiselect
                                showCheckbox
                                onSelect={handleOnBranchSelect}
                                onRemove={handleOnBranchRemove}
                                selectionLimit={50}
                                options={branchDropdown}
                                selectedValues={selectedBranchDropDown}
                                displayValue="branchName"
                                hidePlaceholder
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mt-3">
                          <div className="col-md-auto col-sm-12">
                            <button
                              disabled={enableFetchButton}
                              className="btn ymca-primary-btn login-btn custom-align"
                              onClick={handelGetDownloadFileURL}
                              type="button"
                            >
                              {APP_LABELS.UPLOAD_FILE_FETCH_DATA}
                            </button>
                          </div>
                          <div className="col-auto px-md-0">
                            <span className="ymca-page-heading">OR</span>
                          </div>
                          <div className="col-md-auto col-sm-12">
                            <button
                              type="button"
                              className="btn ymca-primary-outline-btn px-2 py-2 custom-align"
                              onClick={handleShowAttentionPopup}
                            >
                              <img
                                src={excelicon}
                                alt="download-template-icon"
                                className="img-fluid me-2 template-icon"
                              />
                              {APP_LABELS.UPLOAD_DOWNLOAD_FILE}
                            </button>
                          </div>
                        </div>
                        <div className="mb-4 mt-2 text-center">
                          <img
                            src={warningiconmedium}
                            className="img-fluid me-1"
                            alt="warning-img"
                          />
                          <span className="ymca-warning-small-text">
                            {APP_LABELS.FETCH_DATA_WARNING}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-12 mb-3">
                      <div className="ymca-bold-heading mb-2">
                        {APP_LABELS.STEP_2_TEXT}
                      </div>
                      <div className="card outline-card ymca-form px-3">
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group form-label mb-3">
                              {APP_LABELS.UPLOAD_FILE_ASSOCIATIONS}
                              <span className="text-mandatory ms-1">*</span>
                            </div>
                            <div className="notranslate">
                              <Multiselect
                                isObject={false}
                                showCheckbox
                                onSelect={handleOnUploadAssociationSelect}
                                onRemove={handleOnUploadAssociationRemove}
                                selectionLimit={50}
                                options={UploadDataAssociationArray}
                                selectedValues={UploadDataAssociationArray}
                                disable
                                hidePlaceholder
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-3">
                              <div className="form-group form-label mb-3">
                                {APP_LABELS.UPLOAD_FILE_BRANCH}
                                <span className="text-mandatory ms-1">*</span>
                              </div>
                              <div className="notranslate">
                                <Multiselect
                                  showCheckbox
                                  onSelect={handleOnUploadBranchSelect}
                                  onRemove={handleOnUploadBranchRemove}
                                  selectionLimit={50}
                                  options={uploadDataBranchDropdown}
                                  selectedValues={
                                    uploadDataSelectedBranchDropDown
                                  }
                                  displayValue="branchName"
                                  hidePlaceholder
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-12 text-center mt-3">
                          <div>
                            <button
                              className="btn ymca-primary-btn login-btn custom-align"
                              type="button"
                              disabled={enableUploadButton}
                              {...getRootProps({ style })}
                            >
                              <input
                                {...getInputProps()}
                                className="form-control file-input-control"
                              />
                              {APP_LABELS.DETAIL_UPLOAD_DATA}
                            </button>
                          </div>
                        </div>
                        <div className="mb-4 mt-2 text-center">
                          <span className="ymca-warning-small-text ">
                            {APP_LABELS.UPLOAD_DATA_WARNING}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
            {redirect && <Redirect to="/employee/dashboard/upload/data" />}
            {help && (
              <Help hidePopup={hideShowHelp} keyValue="upload-employee-data" />
            )}
          </div>
        </main>
      </BlockUi>

      {/* Attention Modal */}
      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message={`${APP_LABELS.UPLOAD_FILE_ALL_EXISTING} ${APP_LABELS.UPLOAD_FILE_DATA}`}
        submitButtonName={APP_LABELS.UPLOAD_FILE_CONTINUE_TO_DOWNLOAD}
        onSubmit={handleDownloadEmptyTemplate}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={handleCloseAttentionPopup}
      />

      {/* Upload wait Modal */}
      <CustomModal
        show={showDownloadWaitPopup}
        type={APP_LABELS.UPLOAD_FILE_PLEASE_WAIT}
        icon={waiticon}
        message={APP_LABELS.UPLOAD_FILE_HEADING}
      />

      {/* Error Modal */}
      <ErrorPopup
        handleCloseErrorPopup={handleCloseErrorPopup}
        errorMessage={showErrorMessage}
        showErrorPopup={showErrorPopup}
      />
    </>
  );
};

UploadFile.propTypes = {
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  FetchTemplateURL: PropTypes.objectOf(PropTypes.any).isRequired,
  FetchTemplate: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetFileTemplateURL: PropTypes.func.isRequired,
  handleGetUploadFile: PropTypes.func.isRequired,
  handleGetTermsandConditions: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapDispatchToProps = {
  handleGetFileTemplateURL: getFileTemplateURL,
  handleGetUploadFile: getUploadFile,
  handleGetTermsandConditions: getTermsandConditions
};

const mapStateToProps = state => ({
  AssociationNumberData: AssociationNumberSelector(state),
  FetchTemplateURL: FetchFileTemplateURLSelector(state),
  FetchTemplate: FetchFileTemplateSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
