import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Help from '@modules/shared/components/reusable-components/help';
import helpicon from '@static/images/caretdoubleright.svg';
import CustomModal from '@utilities/CustomModal';
import CvoContactDetails from './cvoContactDetails/index';
import Pledge from './pledge/index';

const PledgeStatementTab = props => {
  const { setLoader, status } = props;
  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const [activeTab, setActiveTab] = useState('pledge-tab');
  const [help, setHelp] = useState(false);
  const [keyValue, setKeyValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [lastActiveTab, setLastActiveTab] = useState('');
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  const onActive = value => {
    if (value !== activeTab && sessionStorage.getItem('dirty') === 'true') {
      setShowPopup(true);
    } else {
      setActiveTab(value);
    }
    setCurrentTab(value);
    setLastActiveTab(activeTab);
  };
  const handleOk = () => {
    setActiveTab(currentTab);
    setShowPopup(false);
  };
  const handleCancel = () => {
    const activetab = lastActiveTab;
    document.getElementById(activetab).click();
    setActiveTab(activetab);
    setShowPopup(false);
  };
  useEffect(() => {
    if (activeTab === 'pledge-tab') {
      setKeyValue('pledge-statement');
    } else if (activeTab === 'cvocontactdetails-tab') {
      setKeyValue('current-and-incoming-cvo-information');
    }
  }, [activeTab]);
  return (
    <div>
      <div className="ymca-completeBox">
        <button
          type="button"
          className="ymca-help-link text-decoration-none annual-report-help"
          onClick={handleShowHelp}
        >
          HELP{/* */}
          <img src={helpicon} className="img-fluid" alt="caret-icon" />
        </button>
        <div className="ymca-directorInfo ymca-collabration">
          <ul className="nav nav-tabs ymca-navTabs" id="myTab" role="tablist">
            {selectedYear === '2022' && (
              <li className="nav-item ymca-navItems" role="tab">
                <button
                  className="nav-link ymca-navLinks active"
                  id="pledge-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pledge"
                  type="button"
                  role="tab"
                  aria-controls="pledge"
                  aria-selected="true"
                  onClick={() => onActive('pledge-tab')}
                >
                  Pledge
                </button>
              </li>
            )}
            {selectedYear === '2022' && (
              <li className="nav-item ymca-navItems" role="tab">
                <button
                  className="nav-link ymca-navLinks"
                  id="cvocontactdetails-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#cvocontactdetails"
                  type="button"
                  role="tab"
                  aria-controls="cvocontactdetails"
                  aria-selected="false"
                  onClick={() => onActive('cvocontactdetails-tab')}
                >
                  Current & Incoming CVO Information
                </button>
              </li>
            )}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane ymca-tabPane fade show active"
              id="pledge"
              role="tabpanel"
              aria-labelledby="pledge-tab"
            >
              {activeTab === 'pledge-tab' && (
                <Pledge setLoader={setLoader} status={status} />
              )}
            </div>
            {selectedYear === '2022' && (
              <div
                className="tab-pane ymca-tabPaneInner fade"
                id="cvocontactdetails"
                role="tabpanel"
                aria-labelledby="cvocontactdetails"
              >
                {activeTab === 'cvocontactdetails-tab' && (
                  <CvoContactDetails setLoader={setLoader} status={status} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {help && <Help hidePopup={hideShowHelp} keyValue={keyValue} />}

      <CustomModal
        show={showPopup}
        type="Warning!"
        message="You have some unsaved changes. If you leave before saving, your
                changes will be lost."
        submitButtonName="Ok"
        onSubmit={handleOk}
        cancelButtonName="Cancel"
        onCancel={handleCancel}
      />
    </div>
  );
};

PledgeStatementTab.propTypes = {
  setLoader: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired
};
export default React.memo(PledgeStatementTab);
