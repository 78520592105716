export const SWIMSAFETY_BRANCH_LIST_SUCCESS = 'SWIMSAFETY_BRANCH_LIST_SUCCESS';

export const SWIMSAFETY_BRANCH_DETAILS_SUCCESS =
  'SWIMSAFETY_BRANCH_DETAILS_SUCCESS';

export const SWIMSAFETY_BRANCH_UPDATE_OR_ADD_SUCCESS =
  'SWIMSAFETY_BRANCH_UPDATE_OR_ADD_SUCCESS';

export const SWIMSAFETY_BRANCH_API_FAILURE = 'SWIMSAFETY_BRANCH_API_FAILURE';

export const SWIMSAFETY_BRANCH_DELETE_SUCCESS =
  'SWIMSAFETY_BRANCH_DELETE_SUCCESS';

export const SWIMSAFETY_BRANCH_DELETE_FAILURE =
  'SWIMSAFETY_BRANCH_DELETE_FAILURE';

export const SWIMSAFETY_BRANCHES_BY_FILTERS_SUCCESS =
  'SWIMSAFETY_BRANCHES_BY_FILTERS_SUCCESS';

export const SWIMSAFETY_BRANCHES_BY_FILTERS_FAILURE =
  'SWIMSAFETY_BRANCHES_BY_FILTERS_FAILURE';
