import * as types from './types';

const initialState = {
  VolunteerAdminPreviewList: {},
  VolunteerAdminApprovalData: {},
  VolunteerAdminDocumentApprovalData: {},
  VolunteerInitiateBackgroundScreeningData: {},
  VolunteerCancelInitiatedBackgroundScreeningData: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.VOLUNTEER_ADMIN_PREVIEW_SUCCESS: {
      return {
        ...state,
        VolunteerAdminPreviewList: action.payload
      };
    }
    case types.VOLUNTEER_ADMIN_PREVIEW_FAILURE:
      return { ...state, error: action.payload };

    case types.VOLUNTEER_ADMIN_APPROVAL_SUCCESS: {
      return {
        ...state,
        VolunteerAdminApprovalData: action.payload
      };
    }
    case types.VOLUNTEER_ADMIN_APPROVAL_FAILURE:
      return { ...state, error: action.payload };

    case types.VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_SUCCESS: {
      return {
        ...state,
        VolunteerAdminDocumentApprovalData: action.payload
      };
    }
    case types.VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_FAILURE:
      return { ...state, error: action.payload };

    case types.VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_SUCCESS: {
      return {
        ...state,
        VolunteerInitiateBackgroundScreeningData: action.payload
      };
    }
    case types.VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_FAILURE:
      return { ...state, error: action.payload };

    case types.VOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_SUCCESS: {
        return {
          ...state,
          VolunteerCancelInitiatedBackgroundScreeningData: action.payload
        };
      }
    case types.VOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_FAILURE:
        return { ...state, error: action.payload };
    default:
      return state;
  }
}
