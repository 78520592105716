import * as types from './types';

const initialState = {
  GetFoodProgram: {},
  FoodProgram: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FOOD_PROGRAM_GET_SUCCESS: {
      return {
        ...state,
        GetFoodProgram: action.payload
      };
    }
    case types.FOOD_PROGRAM_GET_FAILURE:
      return { ...state, error: action.payload };
    case types.FOOD_PROGRAM_SUCCESS: {
      return {
        ...state,
        FoodProgram: action.payload
      };
    }
    case types.FOOD_PROGRAM_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
