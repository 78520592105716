import * as types from './types';

const initialState = {
  GetMemberDemographics: {},
  GetMemberDemographicsNWM: {},
  PostMemberDemographics: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    // case to reset specific state variable 
    case 'RESET_KEY':
      return {
        ...state,
        [action.payload.key]: {}
      };
    case types.GET_MEMBER_DEMOGRAPHICS_SUCCESS: {
      return {
        ...state,
        GetMemberDemographics: action.payload
      };
    }
    case types.GET_MEMBER_DEMOGRAPHICS_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_MEMBER_DEMOGRAPHICS_NWM_SUCCESS: {
      return {
        ...state,
        GetMemberDemographicsNWM: action.payload
      };
    }
    case types.GET_MEMBER_DEMOGRAPHICS_NWM_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_MEMBER_DEMOGRAPHICS_SUCCESS: {
      return {
        ...state,
        PostMemberDemographics: action.payload
      };
    }
    case types.POST_MEMBER_DEMOGRAPHICS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
