import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CrossIconBlue from '@static/images/cross-icon-blue.svg';

const VolunteerAttestationModal = props => {
  const { acceptedName, acceptedDateTime, acceptedTermsAndConditions } = props;

  const formattedDate = acceptedDateTime
    ? moment(acceptedDateTime).format('MM/DD/YYYY')
    : '-';

  return (
    <div
      className="modal fade submit-modal"
      id="submitModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="submitModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div
              className="modal-title ymca-section-heading"
              id="submitModalLabel"
            >
              Terms & conditions
            </div>
            <button
              type="button"
              className="close border-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={CrossIconBlue}
                alt="cross-icon-blue"
                className="close-icon img-fluid"
              />
            </button>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: sessionStorage.getItem('VolunteerTermConditionTerm')
            }}
          />
          <div className="modal-body">
            <div className="form-check ymca-check-box pb-0">
              <input
                className="form-check-input"
                type="checkbox"
                checked={acceptedTermsAndConditions}
                id="flexCheckChecked"
                disabled
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                <span className="table-link ymca-dark-para-6">
                  I have read and agree to the terms and conditions
                </span>
              </label>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form className="ymca-form">
                  <label className="ymca-dark-para-3 mb-2 required">Name</label>
                  <div className="form-group">
                    <input
                      className="form-control submit-modal-form"
                      type="text"
                      placeholder="Enter Name"
                      value={acceptedName}
                      disabled
                    />
                  </div>
                </form>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form className="ymca-form">
                  <label htmlFor="date" className="ymca-dark-para-3 mb-2">
                    Date
                  </label>
                  <div className="form-group">
                    <input
                      className="form-control submit-modal-form"
                      type="text"
                      value={formattedDate}
                      disabled
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VolunteerAttestationModal.propTypes = {
  acceptedName: PropTypes.string.isRequired,
  acceptedDateTime: PropTypes.string.isRequired,
  acceptedTermsAndConditions: PropTypes.bool.isRequired
};

export default VolunteerAttestationModal;
