import React, { useEffect, useState } from 'react';
import ApprovedIconDark from '@static/images/approved-icon-dark.svg';
import ApprovedIconlight from '@static/images/approved-icon-light.svg';
import RejectedDarkIcon from '@static/images/swimRegistration/rejected-icon-dark.svg';
import RejectedIconlight from '@static/images/rejected-icon-light.svg';
import DeleteIconDark from '@static/images/failed-icon-light-color-change.svg';
import DeleteIconlight from '@static/images/failed-icon-dark.svg';
import detailsdark from '@static/images/details-dark.svg';
import detailslight from '@static/images/details-light.svg';
import duplicateColor from '@static/images/Duplicate_Color.svg';
import duplicateWhite from '@static/images/Duplicate_white.svg';
import PropTypes from 'prop-types';

const CdpStatCard = props => {
  const { rows, redcapClass, kipClick, classes } = props;
  const [card, setCard] = useState('');
  const [cardData, setCardData] = useState([]);

  const initData = () => {
    const totalRecords = [];

    for (let index = 0; index < rows?.records?.length; index += 1) {
      const record = rows?.records[index];
      if (record?.deletE_IND !== true) {
        totalRecords.push(record);
      }
    }
    const data = [];
    data.push(
      {
        title: rows.validRecords?.length + rows.invalidRecords?.length,
        subTitle: 'Total Records',
        lightIcon: detailslight,
        darkIcon: detailsdark,
        hoverClass: 'volunteers',
        data: totalRecords,
        key: '1'
      },
      {
        title: rows.validRecords?.length,
        subTitle: 'Valid Records',
        lightIcon: ApprovedIconlight,
        darkIcon: ApprovedIconDark,
        hoverClass: 'approved',
        data: rows.validRecords,
        key: '2'
      },
      {
        title: rows.invalidRecords?.length,
        subTitle: 'Invalid Records',
        darkIcon: RejectedDarkIcon,
        lightIcon: RejectedIconlight,
        hoverClass: 'rejected',
        data: rows.invalidRecords,
        key: '3'
      },
      {
        title: rows.deletedRecords?.length,
        subTitle: 'Deleted Records',
        darkIcon: DeleteIconDark,
        lightIcon: DeleteIconlight,
        hoverClass: 'deleted',
        data: rows.deletedRecords,
        key: '4'
      },
      {
        title: rows.duplicateRecords?.length,
        subTitle: 'Duplicate Records',
        darkIcon: duplicateColor,
        lightIcon: duplicateWhite,
        hoverClass: 'deleted new-staff',
        data: rows.duplicateRecords,
        key: '5'
      }
    );

    setCardData(data);
    setCard('1');
  };

  useEffect(() => {
    if (rows) {
      initData();
    }
  }, [rows]);

  const setActive = stat => {
    setCard(stat.key);
    kipClick(stat.data, stat.key);
  };

  const checkActive = stat => {
    if (stat.key === card) {
      return 'active';
    }
    return '';
  };

  return (
    <section className="stats-data">
      <div className={`row ${classes} mt-5`}>
        {cardData.map(stat => (
          <div
            key={stat.key}
            className="col-lg-auto volunteer-stats-card-spacing first-card"
          >
            <button
              type="button"
              className={`card stats-card chronic-upload-stats ${checkActive(
                stat
              )} ${stat.hoverClass} ${redcapClass}`}
              onClick={() => setActive(stat)}
            >
              <div className="d-flex align-items-center h-100">
                <div className="card-icon">
                  <img src={stat.darkIcon} className="dark-icon" alt="icon" />

                  {stat.lightIcon && (
                    <img
                      src={stat.lightIcon}
                      className="light-icon"
                      alt="icon"
                    />
                  )}
                </div>

                <div className="card-title">
                  <h4>{stat.title}</h4>
                  <p>{stat.subTitle}</p>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};
CdpStatCard.propTypes = {
  rows: PropTypes.objectOf(PropTypes.any).isRequired,
  redcapClass: PropTypes.objectOf(PropTypes.any).isRequired,
  kipClick: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired
}
export default CdpStatCard;
