import { USER_MANAGEMENT_IMPORT_DATA } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const getUserManagementImportData = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: USER_MANAGEMENT_IMPORT_DATA(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.USER_MANAGEMENT_IMPORT_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.USER_MANAGEMENT_IMPORT_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });

export default getUserManagementImportData;
