import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import helpicon from '@static/images/caretdoubleright.svg';
import CustomModal from '@utilities/CustomModal';
import NonProfitOrg from './01-non-profit-org';
import EducationalOrg from './02-educational-org';
import GovtOrgAffiliates from './03-govt-org-affiliates';
import OtherCommunityOrg from './04-other-community-org';
import PrivateOrgBusiness from './05-private-org-business';
import CollaborationAmoungYs from './06-collaboration-amoung-ys';

const BranchTabs = props => {
  const {
    setLoader,
    selectedAsso,
    selectedBranch,
    status,
    role,
    selectedbranchName
  } = props;

  const [activeTab, setActiveTab] = useState(
    'Other Not-for-Profit Organizations'
  );

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [help, setHelp] = useState(false);
  const [keyValue, setKeyValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [lastActiveTab, setLastActiveTab] = useState('');

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const onActive = value => {
    if (value !== activeTab && sessionStorage.getItem('dirty') === 'true') {
      setShowPopup(true);
    } else {
      setActiveTab(value);
    }
    setCurrentTab(value);
    setLastActiveTab(activeTab);
  };

  const handleOk = () => {
    setActiveTab(currentTab);
    setShowPopup(false);
  };

  const handleCancel = () => {
    const activetab = lastActiveTab;
    document.getElementById(activetab).click();
    setActiveTab(activetab);
    setShowPopup(false);
  };

  useEffect(() => {
    if (activeTab === 'Other Not-for-Profit Organizations') {
      setKeyValue('other-not-for-profit-organizations');
    } else if (activeTab === 'Educational Organizations') {
      setKeyValue('educational-organizations');
    } else if (activeTab === 'Government Organizations & Their Affiliates') {
      setKeyValue('government-organizations-and-their-affiliates');
    } else if (activeTab === 'Other Community Organizations') {
      setKeyValue('other-community-organizations');
    } else if (activeTab === 'Private Organizations and Businesses') {
      setKeyValue('private-organizations-and-businesses');
    } else if (activeTab === 'Collabrations Among YS') {
      setKeyValue('collaborations-among-ys');
    }
  }, [activeTab]);

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <div className="ymca-directorInfo ymca-collabration">
        <ul
          className="nav nav-tabs ymca-navTabs ymca-sixRows"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks active"
              id="Other Not-for-Profit Organizations"
              data-bs-toggle="tab"
              data-bs-target="#nonProfitOrg"
              type="button"
              role="tab"
              aria-controls="nonProfitOrg"
              aria-selected="true"
              onClick={() => {
                onActive('Other Not-for-Profit Organizations');
              }}
            >
              Other Not-for-Profit Organizations
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Educational Organizations"
              data-bs-toggle="tab"
              data-bs-target="#educationalOrg"
              type="button"
              role="tab"
              aria-controls="educationalOrg"
              aria-selected="false"
              onClick={() => {
                onActive('Educational Organizations');
              }}
            >
              Educational Organizations
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Government Organizations & Their Affiliates"
              data-bs-toggle="tab"
              data-bs-target="#govtOrg"
              type="button"
              role="tab"
              aria-controls="govtOrg"
              aria-selected="false"
              onClick={() => {
                onActive('Government Organizations & Their Affiliates');
              }}
            >
              Government Organizations & Their Affiliates
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Other Community Organizations"
              data-bs-toggle="tab"
              data-bs-target="#otherOrg"
              type="button"
              role="tab"
              aria-controls="otherOrg"
              aria-selected="false"
              onClick={() => {
                onActive('Other Community Organizations');
              }}
            >
              Other Community Organizations
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Private Organizations and Businesses"
              data-bs-toggle="tab"
              data-bs-target="#pvtOrg"
              type="button"
              role="tab"
              aria-controls="pvtOrg"
              aria-selected="false"
              onClick={() => {
                onActive('Private Organizations and Businesses');
              }}
            >
              Private Organizations and Businesses
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Collabrations Among YS"
              data-bs-toggle="tab"
              data-bs-target="#collabration"
              type="button"
              role="tab"
              aria-controls="collabration"
              aria-selected="false"
              onClick={() => {
                onActive('Collabrations Among YS');
              }}
            >
              Collaborations Among Ys
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane ymca-tabPane fade show active"
            id="nonProfitOrg"
            role="tabpanel"
            aria-labelledby="Other Not-for-Profit Organizations"
          >
            {activeTab === 'Other Not-for-Profit Organizations' && (
              <NonProfitOrg
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                setSuccessPopup={setSuccessPopup}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
                selectedbranchName={selectedbranchName}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="educationalOrg"
            role="tabpanel"
            aria-labelledby="educationalOrg"
          >
            {activeTab === 'Educational Organizations' && (
              <EducationalOrg
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                setSuccessPopup={setSuccessPopup}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
                selectedbranchName={selectedbranchName}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="govtOrg"
            role="tabpanel"
            aria-labelledby="govtOrg"
          >
            {activeTab === 'Government Organizations & Their Affiliates' && (
              <GovtOrgAffiliates
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                setSuccessPopup={setSuccessPopup}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
                selectedbranchName={selectedbranchName}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="otherOrg"
            role="tabpanel"
            aria-labelledby="otherOrg"
          >
            {activeTab === 'Other Community Organizations' && (
              <OtherCommunityOrg
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                setSuccessPopup={setSuccessPopup}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
                selectedbranchName={selectedbranchName}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="pvtOrg"
            role="tabpanel"
            aria-labelledby="pvtOrg"
          >
            {activeTab === 'Private Organizations and Businesses' && (
              <PrivateOrgBusiness
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                setSuccessPopup={setSuccessPopup}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
                selectedbranchName={selectedbranchName}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="collabration"
            role="tabpanel"
            aria-labelledby="collabration"
          >
            {activeTab === 'Collabrations Among YS' && (
              <CollaborationAmoungYs
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                setSuccessPopup={setSuccessPopup}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
                selectedbranchName={selectedbranchName}
              />
            )}
          </div>
        </div>
        {help && <Help hidePopup={hideShowHelp} keyValue={keyValue} />}
        <ErrorPopup
          handleCloseErrorPopup={() => {
            setErrorPopup(false);
          }}
          errorMessage={errorMessage}
          showErrorPopup={errorPopup}
        />
        {successPopup && <SuccessToaster Status="Completed" />}
      </div>

      <CustomModal
        show={showPopup}
        type="Warning!"
        message="You have some unsaved changes. If you leave before saving, your
                changes will be lost."
        submitButtonName="Ok"
        onSubmit={handleOk}
        cancelButtonName="Cancel"
        onCancel={handleCancel}
      />
    </div>
  );
};

BranchTabs.propTypes = {
  setLoader: PropTypes.func.isRequired,
  selectedAsso: PropTypes.string.isRequired,
  selectedBranch: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired.isRequired,
  selectedbranchName: PropTypes.string.isRequired
};

export default BranchTabs;
