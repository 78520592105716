import React from 'react';
import { Link } from 'react-router-dom';
import { APP_LABELS } from '@config/app-config';
import Breadcrumb from '@static/images/breadcrumb-icon.svg';
import Table from './table';
import ADMIN_DASHBOARD_COLUMNS from './columns';

const Dashboard = () => (
  <div className="ymca-wrapper">
    <main className="page-container">
      <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
        <div className="container">
          <section className="dashboard-header">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb ymca-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/home">{APP_LABELS.NAV_DASHBOARD}</Link>
                      <img
                        src={Breadcrumb}
                        className="breadcrumb-icon"
                        alt="double-right-arrow"
                      />
                    </li>
                    <li className="breadcrumb-item active">
                      {APP_LABELS.SWIM_PROGRAM}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <div className="ymca-associate-info-wrapper">
                  <h2 className="ymca-sub-heading mb-2">
                    {APP_LABELS.SWIM_PROGRAM}
                  </h2>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
                <Link
                  className="btn ymca-primary-btn login-btn"
                  to="/swim/safety/addprogram"
                >
                  + {APP_LABELS.ADD_SWIM_PROGRAM}
                </Link>
              </div>
            </div>
          </section>
          <hr className="custom-hr mt-0" />
          <Table rows={[]} headers={ADMIN_DASHBOARD_COLUMNS()} title="" />
        </div>
      </div>
    </main>
  </div>
);

export default Dashboard;
