import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import PropTypes from 'prop-types';
import { BranchListSelector } from '@redux/directory-information/selectors';
import { getBranchList } from '@redux/directory-information/actions';
import Help from '@modules/shared/components/reusable-components/help';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import helpicon from '@static/images/caretdoubleright.svg';
import BranchTabs from './branchTabs';
import AssociationDropdown from '../associaction-drop-down/index';

const CollaborationsTab = props => {
  const { setLoader, BranchList, handleGetBranchList, LoginUserData, status } =
    props;
  const getBranchIdFromOverview = sessionStorage.getItem('branchSelected');
  const [associonOptions, setAssocionOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedAsso, setSelectedAsso] = useState(null);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(
    getBranchIdFromOverview || null
  );
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  useEffect(() => {
    window.scroll(0, 0);
    handleGetBranchList(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    );
    sessionStorage.removeItem('branchSelected');
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'collaboration-title'
      ]?.Instruction
    );
  }, []);

  useEffect(() => {
    const isEmpty = Object.keys(BranchList).length === 0;
    if (!isEmpty) {
      const assoNumber = get(BranchList, 'data.associationNumber');
      const assoName = get(BranchList, 'data.associationName');
      setAssocionOptions([{ id: assoNumber, value: assoName }]);
      setSelectedAsso(assoNumber);

      const branchList = get(BranchList, 'data.branches')?.map(branch => ({
        id: branch.branchNumber,
        value: branch.branchName
      }));
      setBranchOptions(branchList);
      if (
        !selectedBranch ||
        (selectedAsso !== null && assoNumber !== selectedAsso)
      )
        setSelectedBranch(get(BranchList, 'data.branches[0].branchNumber'));
      setSelectedBranchName(get(BranchList, 'data.branches[0].branchName'));
    }
  }, [BranchList]);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  return (
    <div className="ymca-completeBox">
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <div className="ymca-program">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
          <div className="ymca-instructionBoxes">
            <h4 className="ymca-instructHeadline">
              This is a Branch Level Section:
            </h4>
            <p className="ymca-instructTitle">
              <div
                dangerouslySetInnerHTML={{
                  __html: instructions
                }}
              />
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <h4 className="ymca-programActivities ms-0 ">
              {APP_LABELS.DETAIL_ASSOCIATION_DETAIL}
            </h4>
          </div>
          <AssociationDropdown
            setSelectedAsso={setSelectedAsso}
            selectedAsso={selectedAsso}
            associonOptions={associonOptions}
            setSelectedBranch={setSelectedBranch}
            selectedBranch={selectedBranch}
            branchOptions={branchOptions}
            setSelectedBranchName={setSelectedBranchName}
          />
        </div>
      </div>

      <BranchTabs
        setLoader={setLoader}
        selectedAsso={selectedAsso}
        selectedBranch={selectedBranch}
        status={status}
        role={role}
        selectedbranchName={selectedBranchName}
      />
      {help && (
        <Help
          hidePopup={hideShowHelp}
          keyValue="other-not-for-profit-organizations"
        />
      )}
    </div>
  );
};

CollaborationsTab.propTypes = {
  setLoader: PropTypes.func.isRequired,
  handleGetBranchList: PropTypes.func.isRequired,
  BranchList: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  BranchList: BranchListSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

const mapDispatchToProps = {
  handleGetBranchList: getBranchList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CollaborationsTab));
