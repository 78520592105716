import * as types from './types';

const initialState = {
  AssociationNumber: {},
  AssociationSummary: {},
  AssociationChangeLog: {},
  AssociationMoypChangeLog: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ASSOCIATION_NUMBER_SUCCESS: {
      return {
        ...state,
        AssociationNumber: action.payload
      };
    }
    case types.ASSOCIATION_NUMBER_FAILURE:
      return { ...state, error: action.payload };

    case types.ASSOCIATION_SUMMARY_SUCCESS: {
      return {
        ...state,
        AssociationSummary: action.payload
      };
    }
    case types.ASSOCIATION_SUMMARY_FAILURE:
      return { ...state, error: action.payload };

    case types.ASSOCIATION_CHANGE_LOG_SUCCESS: {
      return {
        ...state,
        AssociationChangeLog: action.payload
      };
    }
    case types.ASSOCIATION_CHANGE_LOG_FAILURE:
      return { ...state, error: action.payload };

    case types.ASSOCIATION_LIST_SUCCESS: {
      return {
        ...state,
        AssociationList: action.payload
      };
    }
    case types.ASSOCIATION_LIST_FAILURE:
      return { ...state, error: action.payload };
    case types.ASSOCIATION_MOYP_CHANGE_LOG_SUCCESS: {
      return {
        ...state,
        AssociationMoypChangeLog: action.payload
      };
    }
    case types.ASSOCIATION_MOYP_CHANGE_LOG_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
