import {
  PROGRAM_INNOVATION_GET_URL,
  PROGRAM_INNOVATION_POST_URL
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getProgramInnovation = associationsNum => (dispatch, getState) =>
  asyncRequest({
    url: PROGRAM_INNOVATION_GET_URL(associationsNum),
    successAction: data => ({
      type: types.GET_PROGRAM_INNOVATION_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_PROGRAM_INNOVATION_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postProgramInnovation = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: PROGRAM_INNOVATION_POST_URL(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_PROGRAM_INNOVATION_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_PROGRAM_INNOVATION_FAILURE,
      payload: error
    }),
    dispatch
  });
export const resetReduxState = key => ({
  type: 'RESET_KEY',
  payload: { key }
});
