import React from 'react';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';

const AssociationDropdown = props => {
  const {
    setSelectedAsso,
    selectedAsso,
    associonOptions,
    setSelectedBranch,
    selectedBranch,
    branchOptions,
    setSelectedBranchName
  } = props;
  return (
    <>
      <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="ymca-selectWrap">
          <span className="ymca-labelling txt-mandatory">
            {APP_LABELS.UPLOAD_FILE_ASSOCIATIONS}
          </span>
          <select
            className="ymca-select ymca-customArrow ymca-fieldDisabled pointer-initial notranslate"
            onChange={e => {
              setSelectedAsso(e.target.value);
            }}
            disabled
            value={selectedAsso}
          >
            {associonOptions?.map(asso => (
              <option key={asso.id} value={asso.id}>
                {asso.value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="ymca-selectWrap">
          <span className="ymca-labelling txt-mandatory">
            {APP_LABELS.UPLOAD_FILE_BRANCH}
          </span>
          <select
            className="ymca-select ymca-customArrow pointer-initial notranslate"
            onChange={e => {
              setSelectedBranch(e.target.value);
              setSelectedBranchName(
                branchOptions
                  .filter(x => x.id === e.target.value)
                  .map(x => x.value)[0]
              );
            }}
            value={selectedBranch}
          >
            {branchOptions?.map(branch => (
              <option key={branch.id} value={branch.id}>
                {branch.value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

AssociationDropdown.propTypes = {
  setSelectedAsso: PropTypes.func.isRequired,
  selectedAsso: PropTypes.string.isRequired,
  associonOptions: PropTypes.func.isRequired,
  setSelectedBranch: PropTypes.func.isRequired,
  selectedBranch: PropTypes.string.isRequired,
  branchOptions: PropTypes.func.isRequired,
  setSelectedBranchName: PropTypes.func.isRequired
};

export default React.memo(AssociationDropdown);
