const ADMIN_DASHBOARD_COLUMNS = () => [
  {
    Header: 'SWIM PROGRAM',
    accessor: 'yName',
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'YEAR',
    accessor: 'associationNumber',
    className: 'notranslate'
  },
  {
    Header: 'START DATE',
    accessor: 'physicalCity',
    className: 'notranslate'
  },
  {
    Header: 'END DATE',
    accessor: 'physicalState',
    className: 'notranslate'
  },
  {
    Header: 'INSTRUCTOR NAME',
    accessor: 'lastUploadedDate',
    className: 'notranslate '
  },
  {
    Header: 'ASSOCIATION NAME',
    accessor: '',
    className: 'notranslate'
  },
  {
    Header: 'NO. OF SESSIONS',
    accessor: '',
    className: 'notranslate'
  }
];

export default ADMIN_DASHBOARD_COLUMNS;
