import React from 'react';
import PropTypes from 'prop-types';
import crossiconblue from '@static/images/cross-icon-blue.svg';

const Modal = props => {
  const { id, classNames, popupTitle, showClose, children } = props;

  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-hidden="true">
      <div
        className={`modal-dialog ymca-modal view-details-modal-dialog ${classNames}`}
      >
        <div className="modal-content py-sm-0">
          <div className="modal-header">
            <div className="ymca-black-sub-heading">{popupTitle}</div>
            {showClose && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img
                  src={crossiconblue}
                  alt="cross-icon-blue"
                  className="close-icon img-fluid"
                />
              </button>
            )}
          </div>
          <div className="modal-body px-0">
            <div className="row px-3">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  popupTitle: PropTypes.string,
  showClose: PropTypes.bool,
  classNames: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node.isRequired
};

Modal.defaultProps = {
  popupTitle: '',
  showClose: true,
  classNames: '',
  id: 'exampleModal'
};
export default Modal;
