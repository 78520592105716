import { GET_BRANCH_SUMMARY_LOGS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const getBranchSummaryAndLogs = (ImportId, BranchId) => (dispatch, getState) =>
  asyncRequest({
    url: GET_BRANCH_SUMMARY_LOGS(ImportId, BranchId),
    successAction: data => ({
      type: types.BRANCH_DETAIL_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.BRANCH_DETAIL_FAILURE,
      payload: error
    }),
    dispatch
  });

export default getBranchSummaryAndLogs;
