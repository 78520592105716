import * as types from './types';

const initialState = {
  VolunteerRegistrationPreviewData: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.VOLUNTEER_REGISTRATION_PREVIEW_SUCCESS: {
      return {
        ...state,
        VolunteerRegistrationPreviewData: action.payload
      };
    }
    case types.VOLUNTEER_REGISTRATION_PREVIEW_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
