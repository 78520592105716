export const cdpFetchSelector = (state) => ({
    cdpFecthResult: state.cdpDetailsStore.cdpFecthResult
})

export const detailPageTypeSelector = ({ persistedUploadDataStore }) => ({
    detailPageType: persistedUploadDataStore.detailPageType
})

export const getCdpPopoverSelector = (state) => {
    return {
        popoverDetails: state.cdpDetailsStore.popoverDetails
    };
};