import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  getVolunteerAdminPreviewList,
  postVolunteerApprovalData,
  postVolunteerInitiateBackgroundScreening,
  postVolunteerCancelInitiatedBackgroundScreening
} from '@redux/swim-registration/volunteer-admin-preview/actions';
import { VolunteerAdminPreviewSelector } from '@redux/swim-registration/volunteer-admin-preview/selectors';
import { GET_DOWNLOAD_FILE_PREVIEW } from '@config/api-config';
import { APP_LABELS, ENUMS, REGEX } from '@config/app-config';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import CustomModal from '@utilities/CustomModal';
import BackIcon from '@static/images/back-icon.svg';
import ViewIcon from '@static/images/swimRegistration/view-icon.svg';
import DownloadIconArrow from '@static/images/swimRegistration/download-icon-arrow.svg';
import EditPencilIcon from '@static/images/swimRegistration/edit-pencil-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import { getDocumentTitle } from '@state/utils/utilities';
import InitiateIcon from '@static/images/swimRegistration/initiate-icon.svg';
import RejectModal from './reject-modal';
import ApproveModal from './approve-modal';
import PreviewModal from '../volunteer-registeration-preview/preview-modal';
import RenderStatusIcon from '../volunteer-registeration-preview/renderStatusIcon';
import VolunteerAttestationModal from '../volunteer-registeration-preview/volunteer-attestation-modal';

const { PAGE_DATE_FORMAT, DATE_FORMAT_FOR_DOCUMENT, EXPIRY_DATE_FORMAT } =
  REGEX;

const VolunteerAdminPreview = props => {
  const {
    handleGetVolunteerAdminPreview,
    handlePostVolunteerApprovalPreview,
    handlePostVolunteerInitiateBackgroundScreening,
    VolunteerAdminPreviewSelectorData,
    handlePostVolunteerCancelInitiatedBackgroundScreening,
    computedMatch
  } = props;

  const [loader, setLoader] = useState(false);
  const [revertAction, setRevertAction] = useState(false);
  const [isEditDecisionSelected, setIsEditDecisionSelected] = useState(false);
  const [documentType, setDocumentType] = useState();
  const [fileURI, setFileURI] = useState();
  const [documentTitle, setDocumentTitle] = useState();
  const [isPdfViewer, setIsPdfViewer] = useState(true);
  const [addComment, setAddComment] = useState(null);
  const [commentError, setCommentError] = useState(false);
  const [formButtonDisable, setFormButtonDisable] = useState(true);
  const [selectedDocumentDetail, setSelectedDocumentDetail] = useState();
  const [redirect, setRedirect] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const authToken = JSON.parse(sessionStorage.getItem('token'));

  const [initiateSuccessPopup, setInitiateSuccessPopup] = useState(false);
  const [initiateCancelSuccessPopup, setInitiateCancelSuccessPopup] =
    useState(false);
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [showCancelAttentionPopup, setShowCancelAttentionPopup] =
    useState(false);
  const handleCloseAttentionPopup = () => setShowAttentionPopup(false);
  const handleShowAttentionPopup = () => setShowAttentionPopup(true);

  const handleCloseCancelAttentionPopup = () =>
    setShowCancelAttentionPopup(false);
  const handleShowCancelAttentionPopup = () =>
    setShowCancelAttentionPopup(true);

  const handleFormSubmissionButton = data => {
    const status = data?.volunteerDocuments?.map(row => row.status);
    if (status.includes(ENUMS.REJECTED)) {
      setFormButtonDisable(true);
    }
    if (status.every(x => x === ENUMS.APPROVED)) {
      setFormButtonDisable(false);
    } else {
      setFormButtonDisable(true);
    }
  };

  const handleVolunteerAdminPreview = () => {
    setLoader(true);
    handleGetVolunteerAdminPreview(
      get(computedMatch, 'params.volunteerID')
    ).then(res => {
      if (res.status === 200) {
        handleFormSubmissionButton(res.data.data);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
    handleVolunteerAdminPreview();
    setIsEditDecisionSelected(false);
  }, []);

  const getFileExtension = filename => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
  };

  const openPDF = async (fileName, documentTypeString) => {
    setDocumentTitle(documentTypeString);
    setLoader(true);
    const URI = `${GET_DOWNLOAD_FILE_PREVIEW(fileName)}`;
    const extn = getFileExtension(fileName)
      ? getFileExtension(fileName).toLowerCase()
      : '';
    let typeValue = 'application/pdf';
    // code for image files
    if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') {
      setIsPdfViewer(false);
      typeValue = `image/${extn}`;
    } else {
      setIsPdfViewer(true);
    }
    axios
      .get(URI, {
        responseType: 'arraybuffer',
        headers: {
          multipartFormData: false,
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(response => {
        const bytes = new Uint8Array(response.data); // use this if data is raw bytes else directly pass resData
        const blob = new window.Blob([bytes], { type: typeValue });
        const downloadUrl = window.URL.createObjectURL(blob);
        setFileURI(downloadUrl);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const handleDownloadFile = filePath => {
    const extractFileName = filePath.split('/');
    setLoader(true);
    axios
      .get(GET_DOWNLOAD_FILE_PREVIEW(filePath), {
        responseType: 'blob',
        headers: {
          'Content-Type': 'blob',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(response => {
        const fileName = extractFileName[extractFileName.length - 1];
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const handleSelectedDocumentDetails = documentDetail => {
    setSelectedDocumentDetail(documentDetail);
  };

  const handleFormSubmission = status => {
    setLoader(true);
    if (addComment === null && status === 'Rejected') {
      setCommentError(true);
      setLoader(false);
      return;
    }

    const payload = {
      volunteerId: VolunteerAdminPreviewSelectorData?.data?.volunteerId,
      comment: addComment,
      status
    };

    handlePostVolunteerApprovalPreview(payload).then(res => {
      if (res.status === 200) {
        setLoader(false);
        setSuccessPopup(true);
        if (status === 'Rejected') {
          setSuccessMessage('Documents have been rejected successfully!');
        }
        if (status === 'Approved') {
          setSuccessMessage('Documents have been approved successfully! ');
        }
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleAddComment = e => {
    setAddComment(e.target.value);
  };

  const setExpiryDateFormat = expiryDate =>
    moment(expiryDate).format(EXPIRY_DATE_FORMAT);

  const editDecision = flag => {
    setIsEditDecisionSelected(flag);
    setDocumentType();
  };

  const showApproveReject = (isEdit, documentTypeValue) => {
    setIsEditDecisionSelected(isEdit);
    setDocumentType(documentTypeValue);
  };

  const handlePostVolunteerInitiateAction = () => {
    setLoader(true);
    const payload = {
      initiateBackgroundScreening: true,
      volunteerId: VolunteerAdminPreviewSelectorData?.data?.volunteerId,
      personId: VolunteerAdminPreviewSelectorData?.data?.personId,
      firstName: VolunteerAdminPreviewSelectorData?.data?.firstName,
      lastName: VolunteerAdminPreviewSelectorData?.data?.lastName,
      dob: VolunteerAdminPreviewSelectorData?.data?.birthDate,
      email: VolunteerAdminPreviewSelectorData?.data?.emailAddress,
      state: VolunteerAdminPreviewSelectorData?.data?.state,
      country: VolunteerAdminPreviewSelectorData?.data?.country
    };
    handlePostVolunteerInitiateBackgroundScreening(payload).then(res => {
      if (res?.status === 200) {
        if (res?.data?.status === 200) {
          setLoader(false);
          handleVolunteerAdminPreview();
          editDecision(false);
          setInitiateSuccessPopup(true);
        } else {
          setLoader(false);
          setErrorMessage(res?.data?.message);
          setErrorPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(res?.message ? res?.message : res?.title);
        setErrorPopup(true);
      }
    });
  };

  const handlePostCancelVolunteerInitiateAction = () => {
    setLoader(true);
    const payload = {
      volunteerId: VolunteerAdminPreviewSelectorData?.data?.volunteerId,
      documentTypeId:
        VolunteerAdminPreviewSelectorData?.data?.volunteerDocuments[0]
          .documentTypeId,
      status: 'New'
    };
    handlePostVolunteerCancelInitiatedBackgroundScreening(payload).then(res => {
      if (res?.status === 200) {
        if (res?.data?.isSuccess === true) {
          setLoader(false);
          handleVolunteerAdminPreview();
          editDecision(false);
          setInitiateCancelSuccessPopup(true);
        } else {
          setLoader(false);
          setErrorMessage(res?.data?.message);
          setErrorPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(res?.message ? res?.message : res?.title);
        setErrorPopup(true);
      }
    });
  };

  const handleInitiatePopup = () => {
    handlePostVolunteerInitiateAction();
    setShowAttentionPopup(false);
  };
  const handleCancelInitiatePopup = () => {
    handlePostCancelVolunteerInitiateAction();
    setShowCancelAttentionPopup(false);
  };
  const renderApproveButton = row => (
    <button
      type="button"
      className="btn volunteer-action"
      data-bs-toggle="modal"
      data-bs-target="#approveModal"
      onClick={() => handleSelectedDocumentDetails(row)}
    >
      <span className="ymca-approve-text">Approve</span>
    </button>
  );

  const renderRejectButton = row => (
    <button
      type="button"
      className="btn volunteer-action"
      data-bs-toggle="modal"
      data-bs-target="#rejectModal"
      onClick={() => handleSelectedDocumentDetails(row)}
    >
      <span className="ymca-delete-text">Reject</span>
    </button>
  );

  const BackgroundScreeningCheck = row =>
    ENUMS.BACKGROUND_SCREENING === row?.documentTypeString;

  // THIS BELOW CODE IS COMMENTED ONLY FOR PHASE-I DEVELOPMENT PURPOSE

  const renderInitiateButton = row => {
    const validStatusValues = [
      ENUMS.APPROVED,
      ENUMS.REJECTED,
      ENUMS.PENDING,
      ENUMS.NEW
    ];
    return (
      ENUMS.BACKGROUND_SCREENING === row?.documentTypeString &&
      validStatusValues.includes(row?.status) && (
        <>
          {/* <span className="table-divider">|</span> */}

          <button
            type="button"
            className="btn volunteer-action"
            onClick={handleShowAttentionPopup}
            // disabled // THIS CODE IS ONLY FOR PHASE-I DEVELOPMENT PURPOSE
          >
            <span className="ymca-initiate-text">
              <img
                src={InitiateIcon}
                className="img-fluid ms-3 mb-2"
                alt="initiate-icon"
              />{' '}
              Initiate
            </span>
          </button>
        </>
      )
    );
  };

  const renderCancelInitiateButton = row => {
    const validStatusValues = [ENUMS.INITIATED];
    return (
      ENUMS.BACKGROUND_SCREENING === row?.documentTypeString &&
      validStatusValues.includes(row?.status) && (
        <button
          type="button"
          className="btn view-link-blue link-sm"
          onClick={handleShowCancelAttentionPopup}
          // disabled // THIS CODE IS ONLY FOR PHASE-I DEVELOPMENT PURPOSE
        >
          Cancel
        </button>
      )
    );
  };
  const renderEditButton = row => {
    const validStatusValues = [ENUMS.APPROVED, ENUMS.REJECTED];

    return (
      validStatusValues.includes(row?.status) &&
      documentType !== row?.documentTypeString && (
        <button
          type="button"
          className="btn volunteer-action ymca-card-link"
          onClick={() => showApproveReject(true, row?.documentTypeString)}
        >
          <img
            src={EditPencilIcon}
            alt="edit-pencil-icon"
            className="me-2 volunteer-edit-doc img-fluid"
          />
          <span className="">Edit</span>
        </button>
      )
    );
  };

  const renderPreviewButton = () => (
    <button
      type="button"
      className="btn volunteer-action-view p-0 pe-1 me-5"
      data-bs-toggle="modal"
      data-bs-target="#submitModal"
    >
      <img src={ViewIcon} className="img-fluid mx-3" alt="view-icon-img" />
    </button>
  );

  const renderPreviewAndDownloadButton = row => (
    <>
      <button
        type="button"
        className="btn volunteer-action-view p-0"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        onClick={() => openPDF(row.url, row.documentTypeString)}
      >
        <img src={ViewIcon} className="img-fluid mx-3" alt="view-icon-img" />
      </button>
      <button
        type="button"
        className="btn volunteer-action p-0"
        onClick={() => handleDownloadFile(row.url)}
      >
        <img
          src={DownloadIconArrow}
          className="img-fluid mx-3"
          alt="success-img"
        />
      </button>
    </>
  );

  const isFormStatusApprovedOrRejected = [
    ENUMS.APPROVED,
    ENUMS.REJECTED
  ].includes(VolunteerAdminPreviewSelectorData?.data?.status);
  const isFormStatusNotApprovedOrRejected = ![
    ENUMS.APPROVED,
    ENUMS.REJECTED
  ].includes(VolunteerAdminPreviewSelectorData?.data?.status);

  const checkDocumentTypeStatusAndEditAction = row => {
    const isStatusInvalid = ![
      ENUMS.APPROVED,
      ENUMS.REJECTED,
      ENUMS.INITIATED
    ].includes(row?.status);

    const isDocumentTypeMatch =
      isEditDecisionSelected && documentType === row?.documentTypeString;

    return isStatusInvalid || isDocumentTypeMatch;
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper volunteer-details-wrapper">
          <div className="container">
            <section className="dashboard-header mb-4">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2 className="ymca-sub-heading mb-0">Volunteer Details</h2>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                  <Link className="ymca-title-link" to="/volunteer/dashboard">
                    <img
                      src={BackIcon}
                      className="ymca-back-link"
                      alt="back-icon"
                    />
                    {/* */}
                    Back
                  </Link>
                </div>
              </div>
            </section>
          </div>
          <hr className="custom-hr" />
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 col-md-6 col-sm-12 volunteer-details-top-status">
                Registered Date & Time -{/* */}
                <span className="vd-date notranslate">
                  &nbsp;
                  {moment(
                    convertUtcToLocal(
                      get(
                        VolunteerAdminPreviewSelectorData,
                        'data.registrationDate'
                      )
                    )
                  ).format(PAGE_DATE_FORMAT)}
                </span>
                <span className="table-divider me-3">|</span> Status
                <RenderStatusIcon
                  // statusValue={VolunteerAdminPreviewSelectorData?.data?.status}
                  statusValue={get(
                    VolunteerAdminPreviewSelectorData,
                    'data.status',
                    '-'
                  )}
                  isAdminPreview
                />
              </div>
              <div className=" col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                {isFormStatusApprovedOrRejected && (
                  <>
                    {!revertAction && (
                      <button
                        type="button"
                        className="btn volunteer-action ymca-card-link"
                        onClick={() => setRevertAction(true)}
                      >
                        <img
                          src={EditPencilIcon}
                          alt="edit-pencil-icon"
                          className="me-2 volunteer-edit-doc img-fluid"
                        />
                        <span className="">Edit action</span>
                      </button>
                    )}
                    {revertAction && (
                      <button
                        type="button"
                        className="btn volunteer-action ymca-card-link"
                        onClick={() => setRevertAction(false)}
                      >
                        <span className="">Cancel</span>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            {get(VolunteerAdminPreviewSelectorData, 'data.status', '-') ===
              ENUMS.REJECTED && (
              <div className="alert ymca-reject-alert" role="alert">
                {get(
                  VolunteerAdminPreviewSelectorData,
                  'data.adminComment',
                  '-'
                )}
              </div>
            )}
            <div className="card volunteer-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Name</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.firstName',
                        '-'
                      )}{' '}
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.lastName',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Date Of Birth</div>
                    <div className="ymca-dark-para-3">
                      {moment(
                        get(
                          VolunteerAdminPreviewSelectorData,
                          'data.birthDate',
                          '-'
                        )
                      ).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Email Address</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.emailAddress',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Phone Number</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.phoneNumber',
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Address 1</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.address1',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Address 2</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {VolunteerAdminPreviewSelectorData?.data?.address2
                        ? VolunteerAdminPreviewSelectorData?.data?.address2
                        : '-'}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">City</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(VolunteerAdminPreviewSelectorData, 'data.city', '-')}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">ZIP Code</div>
                    <div className="ymca-dark-para-3">
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.zipCode',
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">State</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.state',
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="ymca-dark-para-4">Country</div>
                    <div className="ymca-dark-para-3 notranslate">
                      {get(
                        VolunteerAdminPreviewSelectorData,
                        'data.country',
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-3">
                    <div className="ymca-dark-para-4">Registration</div>
                    <div className="d-flex mt-2">
                      {VolunteerAdminPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_SWIMMING_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge pink-badge">
                          Swimming
                        </span>
                      )}

                      {VolunteerAdminPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_GYMNASTICS_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge voilet-badge">
                          Gymnastics
                        </span>
                      )}

                      {VolunteerAdminPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_DIVING_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge purple-badge">
                          Diving
                        </span>
                      )}
                      {VolunteerAdminPreviewSelectorData?.data?.registrationIds?.includes(
                        ENUMS.IS_PICKLE_BALL_REGISTRATION
                      ) && (
                        <span className="badge ymca-volunteer-badge dark-purple-badge">
                          Pickleball
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive custom-scrollbar mt-3">
              <table className="table ymca-table">
                <thead>
                  <tr>
                    <th scope="col">Document Type</th>
                    <th scope="col">Uploaded On</th>
                    <th scope="col">Expiry Date</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {VolunteerAdminPreviewSelectorData?.data?.volunteerDocuments?.map(
                    row => (
                      <tr key={row?.documentTypeString}>
                        <td>{getDocumentTitle(row?.documentTypeString)}</td>
                        <td className="notranslate">
                          {moment(
                            convertUtcToLocal(get(row, 'uploadedOn'))
                          ).format(DATE_FORMAT_FOR_DOCUMENT)}
                        </td>
                        <td className="notranslate">
                          {row?.expiryDate
                            ? setExpiryDateFormat(row?.expiryDate)
                            : '-'}
                        </td>
                        <td>{row?.comment ? row?.comment : '-'}</td>
                        <td>
                          <RenderStatusIcon
                            statusValue={get(row, 'status', '-')}
                            isAdminPreview={false}
                          />
                        </td>

                        <td>
                          {row?.documentTypeString ===
                          ENUMS.VOLUNTEER_ATTESTATION ? (
                            renderPreviewButton()
                          ) : (
                            <>
                              {row?.url &&
                                row?.url !== '' &&
                                renderPreviewAndDownloadButton(row)}
                            </>
                          )}

                          {(isFormStatusNotApprovedOrRejected ||
                            revertAction) && (
                            <>
                              {/* <span className="table-divider">|</span> */}
                              {checkDocumentTypeStatusAndEditAction(row) && (
                                <>
                                  {BackgroundScreeningCheck(row) ? (
                                    // <>
                                    //   {row?.url && (
                                    //     <>
                                    //       <span className="table-divider">
                                    //         |
                                    //       </span>
                                    //       {row?.status !== ENUMS.DENIED &&
                                    //         renderApproveButton(row)}
                                    //       {renderRejectButton(row)}
                                    //     </>
                                    //   )}
                                    // </>
                                    // -------------------------------
                                    <>
                                      {row?.url && (
                                        <span className="table-divider">|</span>
                                      )}
                                      {row?.status !== ENUMS.DENIED &&
                                        renderApproveButton(row)}
                                      {renderRejectButton(row)}
                                    </>
                                  ) : (
                                    // -------------------------------
                                    <>
                                      <span className="table-divider">|</span>
                                      {renderApproveButton(row)}
                                      {renderRejectButton(row)}
                                    </>
                                  )}
                                  {/* // THIS BELOW CODE IS COMMENTED ONLY FOR PHASE-I DEVELOPMENT PURPOSE */}
                                  {renderInitiateButton(row)}
                                </>
                              )}
                              {renderCancelInitiateButton(row)}
                              {renderEditButton(row)}
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            {((VolunteerAdminPreviewSelectorData?.data?.status !==
              ENUMS.APPROVED &&
              VolunteerAdminPreviewSelectorData?.data?.status !==
                ENUMS.REJECTED) ||
              revertAction) && (
              <>
                <span className="btn download-link-btn ps-0">Comments</span>

                <div className="volunteer-details-add-comment-section">
                  <div className="row">
                    <div className="col-lg-7 col-sm-12 col-md-12">
                      <div className="ymca-form">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Add comments"
                            onChange={handleAddComment}
                            value={addComment}
                          />
                          {commentError && (
                            <span className="errorNote">
                              * please enter comments
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-end">
                  <button
                    className="btn ymca-primary-outline-btn login-btn mt-0 me-3"
                    type="button"
                    onClick={() => handleFormSubmission('Rejected')}
                  >
                    Reject
                  </button>
                  <button
                    className="btn ymca-primary-btn login-btn volunteer-approve-btn"
                    type="button"
                    onClick={() => handleFormSubmission('Approved')}
                    disabled={formButtonDisable}
                  >
                    Approve
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <PreviewModal
          fileURL={fileURI}
          pdfViewerFlag={isPdfViewer}
          documentTitle={documentTitle}
        />
        <ApproveModal
          documentDetail={selectedDocumentDetail}
          volunteerID={computedMatch?.params?.volunteerID}
          setLoader={setLoader}
          handleVolunteerAdminPreview={handleVolunteerAdminPreview}
          editDecision={editDecision}
        />
        <RejectModal
          documentDetail={selectedDocumentDetail}
          volunteerID={computedMatch?.params?.volunteerID}
          setLoader={setLoader}
          handleVolunteerAdminPreview={handleVolunteerAdminPreview}
          editDecision={editDecision}
        />
        <VolunteerAttestationModal
          acceptedName={get(
            VolunteerAdminPreviewSelectorData,
            'data.tandCacceptedName',
            '-'
          )}
          acceptedDateTime={get(
            VolunteerAdminPreviewSelectorData,
            'data.tandCacceptedDateTime',
            '-'
          )}
          acceptedTermsAndConditions={get(
            VolunteerAdminPreviewSelectorData,
            'data.termsAndCondition',
            true
          )}
        />
        {redirect && <Redirect to="/volunteer/dashboard" />}
        <ErrorPopup
          handleCloseErrorPopup={() => {
            setErrorPopup(false);
          }}
          errorMessage={errorMessage}
          showErrorPopup={errorPopup}
        />
        {successPopup && (
          <CustomModal
            show={successPopup}
            type={APP_LABELS.SUCCESS}
            message={successMessage}
            submitButtonName="Ok"
            onSubmit={() => {
              setRedirect(true);
            }}
          />
        )}
        {/* Attention Modal */}
        <CustomModal
          show={showAttentionPopup}
          type={APP_LABELS.UPLOAD_FILE_ATTENTION}
          message={`${APP_LABELS.INITIATE_MESSAGE}`}
          submitButtonName="Yes"
          onSubmit={() => handleInitiatePopup()}
          cancelButtonName="No"
          onCancel={handleCloseAttentionPopup}
        />
        {/* Cancel Attention Modal */}
        <CustomModal
          show={showCancelAttentionPopup}
          type={APP_LABELS.UPLOAD_FILE_ATTENTION}
          message={`${APP_LABELS.CANCEL_INITIATE_MESSAGE}`}
          submitButtonName="Yes"
          onSubmit={() => handleCancelInitiatePopup()}
          cancelButtonName="No"
          onCancel={handleCloseCancelAttentionPopup}
        />
        {initiateSuccessPopup && (
          <CustomModal
            show={initiateSuccessPopup}
            type={APP_LABELS.SUCCESS}
            message={APP_LABELS.INITIATE_SUCCESS_MESSAGE}
            submitButtonName="Ok"
            onSubmit={() => {
              setInitiateSuccessPopup(false);
            }}
          />
        )}
        {initiateCancelSuccessPopup && (
          <CustomModal
            show={initiateCancelSuccessPopup}
            type={APP_LABELS.SUCCESS}
            message={APP_LABELS.CANCEL_INITIATED_SUCCESS_MESSAGE}
            submitButtonName="Ok"
            onSubmit={() => {
              setInitiateCancelSuccessPopup(false);
            }}
          />
        )}
      </main>
    </BlockUi>
  );
};

VolunteerAdminPreview.propTypes = {
  handleGetVolunteerAdminPreview: PropTypes.func.isRequired,
  handlePostVolunteerApprovalPreview: PropTypes.func.isRequired,
  handlePostVolunteerInitiateBackgroundScreening: PropTypes.func.isRequired,
  handlePostVolunteerCancelInitiatedBackgroundScreening:
    PropTypes.func.isRequired,
  VolunteerAdminPreviewSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  computedMatch: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetVolunteerAdminPreview: getVolunteerAdminPreviewList,
  handlePostVolunteerApprovalPreview: postVolunteerApprovalData,
  handlePostVolunteerInitiateBackgroundScreening:
    postVolunteerInitiateBackgroundScreening,
  handlePostVolunteerCancelInitiatedBackgroundScreening:
    postVolunteerCancelInitiatedBackgroundScreening
};

const mapStateToProps = state => ({
  VolunteerAdminPreviewSelectorData: VolunteerAdminPreviewSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VolunteerAdminPreview));
