import React from 'react';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';
import erroricon from '@static/images/error-icon.svg';
import crossicon from '@static/images/cross-icon-blue.svg';

const ErrorPopup = props => {
  const { handleCloseErrorPopup, errorMessage, showErrorPopup } = props;

  const modal = {
    background: 'rgb(0 0 0 / 0.5)'
  };

  const showHideClassName = showErrorPopup ? 'modal d-block' : 'modal d-none';

  return (
    <div className={showHideClassName} style={modal}>
      <div className="modal-dialog ymc-pointerEvent modal-dialog-centered justify-content-center">
        <div className="d-flex bg-white py-4 px-4 rounded-2 position-relative pb-4 pt-5">
          <div className="YMC-absoluteRight py-3">
            <button
              type="button"
              className="btn btn-transfer"
              onClick={() => {
                handleCloseErrorPopup();
              }}
            >
              <img
                src={crossicon}
                className="img-fluid YMC-closeIcon"
                alt="wait-icon"
              />
            </button>
          </div>
          <div className="YMC-icons me-3 mt-3 ps-2">
            <img
              src={erroricon}
              className="img-fluid ymc-noAlert"
              alt="wait-icon"
            />
          </div>
          <div className="col-10 pe-0">
            <h2 className="ymca-sub-heading">{APP_LABELS.ERROR}</h2>
            <p className="ymca-bold-heading mb-4">
              {errorMessage !== ''
                ? errorMessage
                : APP_LABELS.COMMON_ERROR_MESSAGE}
            </p>
            <div className="modal-footer p-0 border-0">
              <button
                type="button"
                className="btn ymca-primary-btn custom-align"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleCloseErrorPopup();
                }}
              >
                {APP_LABELS.CLOSE}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPopup.propTypes = {
  handleCloseErrorPopup: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  showErrorPopup: PropTypes.bool
};

ErrorPopup.defaultProps = {
  errorMessage: '',
  showErrorPopup: false
};

export default ErrorPopup;
