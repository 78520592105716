import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BackIcon from '@static/images/back-icon.svg';
import Breadcrumbs from './breadcrumb';

const PageHeader = props => {
  const { pathName, onBackClick } = props;
  return (
    <section className="dashboard-header">
      <div className="row align-items-center">
        <div className="col-lg-7 col-md-6 col-sm-12">
          <Breadcrumbs pathName={pathName} />
        </div>
        <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
          <Link
            className="ymca-title-link"
            to="/cdp/associate/dashboard"
            onClick={e => onBackClick(e)} >
            <img src={BackIcon} className="me-1 mb-2" alt="back-icon" /> Back
          </Link>
        </div>
      </div>
    </section>
  );
};

PageHeader.propTypes = {
  pathName: PropTypes.objectOf(PropTypes.any).isRequired,
  onBackClick: PropTypes.func.isRequired
};

export default PageHeader;
