import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import moment from 'moment';
import get from 'lodash.get';
import BackIcon from '@static/images/back-icon.svg';
import {
  getSwimProfilesByProgramId,
  getSwimSafetyProfileDetails,
  getSwimSafetyProfileDelete
} from '@redux/siwm-safety/swim-profiles/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { SwimSelectedProgramSelector } from '@redux/siwm-safety/swim-profiles/selectors';
import CustomModal from '@utilities/CustomModal';
import Table from './table';
import SWIM_ASSOCIATION_COLUMNS from './columns';
import CardsList from '../upload-data/cardsList';
import ViewDetailsPopup from '../upload-data/view-details-popup';

const Dashboard = props => {
  const {
    handleSwimProfilesByProgramId,
    getSwimProfile,
    handleGetProfileDelete,
    FetchSelectedProgramSelector,
    LoginUserData
  } = props;
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);

  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const [profileData, setProfileData] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [programDeletId, setProgramDeletId] = useState('');
  const handleCloseAttentionPopup = () => setShowAttentionPopup(false);
  const role = get(LoginUserData, 'user.role[0]');
  const selectedProfileData = data => {
    sessionStorage.setItem('selected_profile', JSON.stringify(data));
    sessionStorage.setItem(
      'swim_profile_redirect_url',
      '/swim/safety/program/details'
    );
  };

  const getSwimProfileDetails = profileId => {
    setLoader(true);
    getSwimProfile(profileId).then(result => {
      if (result?.data?.data) {
        setSelectedProfile(result?.data?.data[0]);
        setLoader(false);
        setShowPopup(true);
      }
    });
  };

  const profileListAPICall = () => {
    handleSwimProfilesByProgramId(FetchSelectedProgramSelector?.program?.id)
      .then(res => {
        setProfileData(res?.data?.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const deletePrograms = () => {
    setLoader(true);
    handleGetProfileDelete(programDeletId)
      .then(() => {
        setProgramDeletId('');
        setShowAttentionPopup(false);
        profileListAPICall();
      })
      .catch(() => {
        setLoader(false);
        setProgramDeletId('');
        setShowAttentionPopup(false);
      });
  };

  const handleDeletePopup = id => {
    setProgramDeletId(id);
    setShowAttentionPopup(true);
  };

  const newAssociationChangeLogData = profileData?.map(data => {
    const addSwimLocation = {
      pathname: '/swim/safety/add/profiles',
      state: data
    };
    return {
      ...data,
      childBirthDate: get(data, 'childBirthDate')
        ? moment(get(data, 'childBirthDate')).format('DD MMM YYYY')
        : '-',
      details: (
        <>
          <button
            type="button"
            onClick={() => {
              getSwimProfileDetails(data?.id);
            }}
            className="table-link swim-viewdetails-btn btn"
          >
            {APP_LABELS.DASH_VIEW_DETAILS}
          </button>
          {!APP_LABELS.VIEWER_PERMISSION_ROLES.includes(role) && (
            <>
              <span className=" swim-safety-action-saperator">|</span>
              <Link
                to={addSwimLocation}
                className="table-link"
                onClick={() => selectedProfileData(data)}
              >
                Edit
              </Link>
              <span className=" swim-safety-action-saperator">|</span>
              <button
                type="button"
                className="swim-safety-action"
                data-bs-toggle="modal"
                data-bs-target="#rejectModal"
                onClick={() => handleDeletePopup(data.id)}
              >
                <span className="ymca-delete-text">Delete</span>
              </button>
            </>
          )}
        </>
      )
    };
  });

  useEffect(async () => {
    setLoader(true);
    setCardDetails(FetchSelectedProgramSelector);
    profileListAPICall();
  }, []);

  return (
    <>
      <BlockUi
        blocking={loader}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <div className="ymca-wrapper">
          <main className="page-container">
            <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
              <div className="container">
                <div className="row align-items-center justify-content-end mb-4">
                  <div className="col-lg-4 col-md-14 col-sm-12 text-lg-end text-md-end">
                    <Link
                      className="ymca-title-link"
                      to="/swim/association/dashboard"
                    >
                      <img
                        src={BackIcon}
                        className="ymca-back-link"
                        alt="back-icon"
                      />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  </div>
                </div>
                <CardsList cardDetails={cardDetails} />
                <hr className="custom-hr mt-0" />

                <Table
                  rows={newAssociationChangeLogData}
                  headers={SWIM_ASSOCIATION_COLUMNS()}
                  title=""
                />
              </div>
            </div>
          </main>
        </div>
      </BlockUi>
      {/* Attention Modal */}
      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message="Are you Sure want to delete?"
        submitButtonName="Yes"
        onSubmit={deletePrograms}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={handleCloseAttentionPopup}
      />
      <ViewDetailsPopup
        showViewDetailsPopup={showPopup}
        handleCloseNewStaffPopup={() => setShowPopup(false)}
        popupTitle="Profile Details"
        popupData={{
          profileDetails: selectedProfile,
          programDeatils: cardDetails
        }}
      />
    </>
  );
};

Dashboard.propTypes = {
  handleSwimProfilesByProgramId: PropTypes.func.isRequired,
  getSwimProfile: PropTypes.func.isRequired,
  handleGetProfileDelete: PropTypes.func.isRequired,
  FetchSelectedProgramSelector: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  FetchSelectedProgramSelector: SwimSelectedProgramSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

const mapDispatchToProps = {
  handleSwimProfilesByProgramId: getSwimProfilesByProgramId,
  getSwimProfile: getSwimSafetyProfileDetails,
  handleGetProfileDelete: getSwimSafetyProfileDelete
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
