import {
  GET_COLI_DASHBOARD_COUNT_API,
  GET_COLI_DASHBOARD_ACCOCIATION_API,
  GET_COLI_FILE_UPLOAD_API,
  GET_COLI_ASSOCIATION_DETAILS_API,
  GET_COLI_FILE_DOWNLOAD_API,
  COLI_APPROVE_REJECT_DOCUMENT
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getCOLIdashboardCount = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_COLI_DASHBOARD_COUNT_API(),
    method: 'GET',
    successAction: data => ({
      type: types.COLI_DASHBOARD_COUNT_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.COLI_DASHBOARD_COUNT_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCOLIAssociationList = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_COLI_DASHBOARD_ACCOCIATION_API(),
    method: 'GET',
    successAction: data => ({
      type: types.COLI_DASHBOARD_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.COLI_DASHBOARD_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCOLIuplaodFiles = uploadFileData => (dispatch, getState) =>
  asyncRequest({
    url: GET_COLI_FILE_UPLOAD_API(),
    method: 'POST',
    data: uploadFileData,
    successAction: data => ({
      type: types.COIL_UPLOAD_FILE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.COIL_UPLOAD_FILE_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCOLIAssociationDetails =
  associationNumber => (dispatch, getState) =>
    asyncRequest({
      url: GET_COLI_ASSOCIATION_DETAILS_API(associationNumber),
      method: 'GET',
      successAction: data => ({
        type: types.COLI_ASSOCIATION_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.COLI_ASSOCIATION_DETAILS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCOLIFileDownload = fileName => (dispatch, getState) =>
  asyncRequest({
    url: GET_COLI_FILE_DOWNLOAD_API(fileName),
    method: 'GET',
    successAction: data => ({
      type: types.COIL_DOWNLOAD_FILE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.COIL_DOWNLOAD_FILE_FAILURE,
      payload: error
    }),
    dispatch
  });

export const setApproveRejectStatus = uploadFileData => (dispatch, getState) =>
  asyncRequest({
    url: COLI_APPROVE_REJECT_DOCUMENT(),
    method: 'POST',
    data: uploadFileData,
    successAction: data => ({
      type: types.COLI_APPROVE_REJECT_STATUS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.COLI_APPROVE_REJECT_STATUS_FAILURE,
      payload: error
    }),
    dispatch
  });
