export const ANNUALREPORT_PLEDGE_STATEMENT_FAILURE =
  'ANNUALREPORT_PLEDGE_STATEMENT_FAILURE';
export const ANNUALREPORT_CVO_CONTACT_DATA = 'ANNUALREPORT_CVO_CONTACT_DATA';
export const ANNUALREPORT_PLEDGE_STATEMENT_DATA =
  'ANNUALREPORT_PLEDGE_STATEMENT_DATA';

export const GET_AR_INCOMING_CONTACT_DETAILS_SUCCESS =
  'GET_AR_INCOMING_CONTACT_DETAILS_SUCCESS';
export const GET_AR_INCOMING_CONTACT_DETAILS_FAILURE =
  'GET_AR_INCOMING_CONTACT_DETAILS_FAILURE';

export const POST_AR_INCOMING_CONTACT_DETAILS_SUCCESS =
  'POST_AR_INCOMING_CONTACT_DETAILS_SUCCESS';
export const POST_AR_INCOMING_CONTACT_DETAILS_FAILURE =
  'POST_AR_INCOMING_CONTACT_DETAILS_FAILURE';

export const GET_INCOMING_CONTACT_DETAILS_SUCCESS =
  'GET_INCOMING_CONTACT_DETAILS_SUCCESS';
export const GET_INCOMING_CONTACT_DETAILS_FAILURE =
  'GET_INCOMING_CONTACT_DETAILS_FAILURE';

export const SEND_CEO_CVO_EMAIL_REQUEST_SUCCESS =
  'SEND_CEO_CVO_EMAIL_REQUEST_SUCCESS';
export const SEND_CEO_CVO_EMAIL_REQUEST_FAILURE =
  'SEND_CEO_CVO_EMAIL_REQUEST_FAILURE';

export const GET_CEO_CVO_DETAILS_SUCCESS = 'GET_CEO_CVO_DETAILS_SUCCESS';
export const GET_CEO_CVO_DETAILS_FAILURE = 'GET_CEO_CVO_DETAILS_FAILURE';
