import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import { withRouter } from 'react-router';

import {
  getProgramsAction,
  getDataSystemsAction,
  postCdpFileAction,
  detailPageTypeAction,
  saveFetchTypeAction,
  postCdpRedcapFetchAction,
  getDolTypesAction,
  getIsImportedMsgAction
} from '@redux/chronic-disease-program/upload-data/actions';

import { getCdpAssociationDueDateData } from '@redux/chronic-disease-program/associate-dashboard/selectors';
import { CDP_UPLOAD_URLS } from '@config/api-config';
import { MESSAGES, CDP, APP_LABELS, STORAGE_KEYS } from '@config/app-config';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';

import PageContainer from '@modules/chronic-disease-program/page-container';
import { formatResponse } from '@modules/chronic-disease-program/responseHandler';
import CustomModal from '@utilities/CustomModal';
import userService from '@utilities/user-service';
import SecureStorage from '@utilities/SecureStorage';
import useSessionStorage from '@hooks/useSessionStorage';
import DownloadBlueIcon from '@static/images/download-icon-blue.svg';
import YmcaInfoIcon from '@static/images/ymca-info-icon.svg';
import CrossIconBlue from '@static/images/cross-icon-blue.svg';
import YmcaListGrayIcon from '@static/images/ymca-list-gray-icon.svg';
import DueDate from '../due-date';

const CdpUploadData = props => {
  const {
    handelGetDolTypesAction,
    handelGetProgramsAction,
    handelGetDataSystemsAction,
    handelPostCdpFileAction,
    handelDetailPageTypeAction,
    handelSaveFetchTypeAction,
    handelPostCdpRedcapFetchAction,
    associationSelectorData,
    getIsImportedMsgFetch
  } = props;

  const [types, setTypes] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [buttonName, setButtonName] = useState('Fetch');
  const [dataSystems, setDataSystems] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedDataSystem, setSelectedDataSystem] = useState();
  const [fetchType, setFetchType] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [selectedDataSystemText, setSelectedDataSystemText] = useState('');
  const [selectProgramText, setSelectProgramText] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedTypeText, setSelectedTypeText] = useState('');
  const [showMessage, setShowMessage] = useState({
    status: '',
    type: '',
    message: ''
  });
  const [loadingMessage, setLoadingMessage] = useState(
    MESSAGES.DEFAULT_LOADING_MESSAGE
  );
  const [toastStatus, setToastStatus] = useState({ status: '', message: '' });

  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [learn, setLearn] = useState(false);
  const [isImportedMsg, setIsImportedMsg] = useState(true);
  const [programDueDate, setProgramDueDate] = useState();

  const [association] = useSessionStorage(
    CDP.STORAGE_KEYS.ASSOCIATION_DATA,
    {}
  );

  const activeKPICard = JSON.parse(sessionStorage.getItem('selectedKPI')) || {
    programName: CDP.PROGRAM_NAMES.DPP
  };

  const getProgramDueDate = (programName) => {
    let data = associationSelectorData?.associationData?.CdpAssociateDashboard?.data?.summeray;
    if (data) {
      sessionStorage.setItem("selectedProgramData", JSON.stringify(data));
    } else {
      data = JSON.parse(sessionStorage.getItem("selectedProgramData") || undefined) || undefined;
    }
    if (data) {
      const dueDate = data?.filter(c => c.programName === programName);
      setProgramDueDate(dueDate);
    }
  }

  useEffect(() => {
    if (selectedDataSystem !== '-Select-' && selectedProgram !== '-Select-') {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [selectedDataSystem, selectedProgram]);

  const handleShowLearn = () => {
    setLearn(true);
  };
  const hideShowLearn = () => {
    setLearn(false);
  };

  const loadPrograms = async () => {
    setLoader(true);
    const response = await handelGetProgramsAction(
      association?.associationNumber
    );
    if (response?.status === 200) {
      const limitedProgrmas = [];
      // eslint-disable-next-line no-unused-expressions
      response?.data?.forEach(program => {
        limitedProgrmas.push(program);
      });
      setPrograms(limitedProgrmas);
    } else {
      setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
      setErrorPopup(true);
    }
    setLoader(false);
  };

  useEffect(async () => {
    if (association?.associationNumber) {
      setLoader(true);
      await Promise.allSettled([loadPrograms()]);
      getProgramDueDate(activeKPICard?.programName);
      setLoader(false);
    } else {
      props.history.push({
        pathname: '/cdp/dashboard'
      });
    }
  }, []);

  const loadDataSystems = async Program => {
    setLoader(true);
    const response = await handelGetDataSystemsAction(
      Program.getAttribute('attr')
    );
    if (response && response.status === 200) {
      setDataSystems(response.data);
    } else {
      setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
      setErrorPopup(true);
    }
    setLoader(false);
  };

  const loadDolTypes = async () => {
    setLoader(true);
    const response = await handelGetDolTypesAction();
    if (response && response.status === 200) {
      setTypes(response.data);
    } else {
      setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
      setErrorPopup(true);
    }
    setLoader(false);
  };

  const redirectToDetail = cdpFetchId => {
    if (selectedTypeText === CDP.DOL_TYPE_NAMES.NarrativeReport) {
      props.history.push({
        pathname: '/cdp/associate/dashboard',
        state: { cdpDataFetchId: cdpFetchId }
      });
    } else {
      props.history.push({
        pathname: '/cdp/details',
        state: { cdpDataFetchId: cdpFetchId }
      });
    }
  };

  const processSolera = async event => {
    if (
      selectedTypeText === CDP.DOL_TYPE_NAMES.NarrativeReport &&
      /\.(doc|docx|pdf)$/i.test(event.target.files[0].name) === false
    ) {
      setShowMessage({
        status: true,
        type: APP_LABELS.ERROR,
        message: CDP.MESSAGES.FileUploadErrorDoc
      });
      return;
    }

    if (
      /\.(csv)$/i.test(event.target.files[0].name) === false &&
      selectedTypeText !== CDP.DOL_TYPE_NAMES.NarrativeReport
    ) {
      setShowMessage({
        status: true,
        type: APP_LABELS.ERROR,
        message: CDP.MESSAGES.FileUploadErrorCSV
      });
      return;
    }
    setLoadingMessage(MESSAGES.CDP_UPLOAD_LOADING);
    setLoader(true);
    const formData = new FormData();
    formData.append('AssociationNumber', association?.associationNumber);
    formData.append('DataSystemId', `${selectedDataSystem}`);
    formData.append('ProgramId', `${selectedProgram}`);
    formData.append('UploadedFile', event.target.files[0]);
    formData.append('DataSystem', CDP.DATA_SYSTEMS_ENUM[fetchType]);
    formData.append('CdpType', CDP.CDP_TYPE_ENUM[selectedTypeText] || 0);
    formData.append('programenum', CDP.PROGRAM_ABBR[selectProgramText]);

    const response = await handelPostCdpFileAction(formData);
    const result = formatResponse(response);
    if (result.success) {
      SecureStorage.setItem(STORAGE_KEYS.FETCH_ID, result.data.cdpDataFetchId);
      let seconds = 5;
      if (selectedTypeText === CDP.DOL_TYPE_NAMES.NarrativeReport) {
        const countdownInterval = setInterval(() => {
          seconds -= 1;
          setToastStatus({
            status: APP_LABELS.SUCCESS,
            message: String.format(CDP.MESSAGES.importSuccessFile)
          });
          if (seconds === 0) {
            clearInterval(countdownInterval);
            redirectToDetail(response.data.cdpDataFetchId);
          }
        }, 1000);
      } else {
        redirectToDetail(response.data.cdpDataFetchId);
      }
      setLoader(false);
    } else {
      setShowMessage({
        status: true,
        type: APP_LABELS.ERROR,
        message: result.errors
      });
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
    setLoader(false);
    setLoadingMessage(MESSAGES.DEFAULT_LOADING_MESSAGE);
  };

  const processRedcap = async () => {
    setLoadingMessage(MESSAGES.CDP_FETCH_LOADING);
    setLoader(true);
    const payload = {
      associationNumber: association?.associationNumber,
      dataSystemId: selectedDataSystem,
      programId: selectedProgram,
      programenum: CDP.PROGRAM_ABBR[selectProgramText]
    };
    const response = await handelPostCdpRedcapFetchAction(payload);
    const result = formatResponse(response);
    if (result.success && result?.data?.records?.length > 0) {
      SecureStorage.setItem(STORAGE_KEYS.FETCH_ID, result.data.cdpDataFetchId);
      redirectToDetail(response.data.cdpDataFetchId);
    } else {
      setShowMessage({
        status: true,
        type: APP_LABELS.ERROR,
        message: result.errors || CDP.MESSAGES.NoDataMessage
      });
    }
    setLoader(false);
    setLoadingMessage(MESSAGES.DEFAULT_LOADING_MESSAGE);
  };

  const downloadTemplate = file => {
    setLoader(true);
    axios
      .get(CDP_UPLOAD_URLS.DOWNLOAD_FILE(file), {
        responseType: 'blob',
        headers: {
          'Content-Type': 'blob',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
          Authorization: `Bearer ${userService.getToken()}`
        }
      })
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  const onDownloadClick = () => {
    if (
      selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC &&
      selectProgramText === CDP.PROGRAM_FULL_NAME.DPP
    ) {
      downloadTemplate(CDP.DOL_TEMPLATES[selectedDataSystemText]);
    } else if (
      selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC &&
      selectProgramText === CDP.PROGRAM_FULL_NAME.BPSM
    ) {
      downloadTemplate('BPSM Other - Third Party Upload Template.csv');
    } else if (
      selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC &&
      selectProgramText === CDP.PROGRAM_FULL_NAME.EF
    ) {
      downloadTemplate('EF Other - Third Party Upload Template.csv');
    } else if (
      selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC &&
      selectProgramText === CDP.PROGRAM_FULL_NAME.LS
    ) {
      downloadTemplate('LS Other - Third Party Upload Template.csv');
    }
    else if (
      selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC &&
      selectProgramText === CDP.PROGRAM_FULL_NAME.HW
    ) {
      downloadTemplate('HW Other - Third Party Upload Template.csv');
    }
    else {
      downloadTemplate(CDP.DOL_TEMPLATES[selectedTypeText]);
    }
  };

  const onFetchClick = () => {
    handelDetailPageTypeAction(CDP.DETAIL_PAGE_TYPE.FETCH);
    switch (selectedDataSystemText.toLowerCase()) {
      case CDP.DATA_SYSTEM_NAMES.REDCAP.toLowerCase():
        processRedcap();
        break;
      case CDP.DATA_SYSTEM_NAMES.SOLERA.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.DOL.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.OCHI.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.HABITNU.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC.toLocaleLowerCase():
      case CDP.DATA_SYSTEM_NAMES.WELLD.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.ODES.toLowerCase():
        document.getElementById('file-upload-input').click();
        break;
      default:
        break;
    }
  };

  const onDataSystemChange = async event => {
    setSelectedDataSystem(event.target.value);
    if (!event.target.value) {
      setSelectedProgram('');
    }

    const selectedText = event.target.options[event.target.selectedIndex].text;

    if (selectedText === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC) {
      setSelectedType('');
    }

    if (selectedText === CDP.DATA_SYSTEM_NAMES.DOL) {
      await loadDolTypes();
    }

    setSelectedDataSystemText(selectedText);

    switch (selectedText.toLowerCase()) {
      case CDP.DATA_SYSTEM_NAMES.OCHI.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.HABITNU.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.DOL.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.SOLERA.toLowerCase():
      case CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC.toLocaleLowerCase():
      case CDP.DATA_SYSTEM_NAMES.WELLD.toLocaleLowerCase():
      case CDP.DATA_SYSTEM_NAMES.ODES.toLocaleLowerCase():
        setFetchType(selectedText);
        setButtonName('Upload File');
        handelSaveFetchTypeAction(selectedText);
        break;

      case CDP.DATA_SYSTEM_NAMES.REDCAP.toLowerCase():
        setFetchType(CDP.DATA_SYSTEM_NAMES.REDCAP);
        setButtonName('Fetch Data');
        handelSaveFetchTypeAction(CDP.DATA_SYSTEM_NAMES.REDCAP);
        break;

      default:
        break;
    }

    if (selectedProgram && event?.target?.value) {
      setLoader(true);
      const response = await getIsImportedMsgFetch(
        event?.target?.value,
        selectedProgram,
        association?.associationNumber
      );
      setIsImportedMsg(response?.data);
    }
    setLoader(false);
  };

  const onProgramChange = async event => {
    setSelectedProgram(event.target.value);
    const selectedPro = event.target.options[event.target.selectedIndex];
    const selectedProFullName =
      event.target.options[event.target.selectedIndex].text;

    if (selectedProFullName !== '-Select-') {
      loadDataSystems(selectedPro);
    }
    setSelectProgramText(selectedProFullName);
    setSelectedDataSystem('');
    setSelectedDataSystemText('');
    setIsImportedMsg(true);
    const dropdownProgram = programs.find(c => c.programId.toString() === event.target.value);
    getProgramDueDate(dropdownProgram?.programName);
  };

  const onTypeChange = event => {
    setSelectedType(event.target.value);
    const typeText = event.target.options[event.target.selectedIndex].text;
    setSelectedTypeText(typeText === CDP.SELECT_DEFAULT ? '' : typeText);
  };

  const onModalOk = () => {
    setShowMessage({
      status: '',
      type: '',
      message: ''
    });
  };

  const onOkError = () => {
    if (toastStatus.status === APP_LABELS.SUCCESS) {
      props.history.push('/cdp/associate/dashboard');
    } else {
      setToastStatus({});
    }
  };

  const getColValue = () => {
    if (
      selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.DOL &&
      selectProgramText === CDP.PROGRAM_FULL_NAME.YWP
    ) {
      return '4';
    }
    return '6';
  };

  const disableButtonClick = () => {
    if (selectedDataSystem && selectedProgram) {
      if (selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.DOL) {
        return !selectedType;
      }
      return false;
    }
    return true;
  };

  const acceptedFileType = () => {
    if (selectedTypeText === CDP.DOL_TYPE_NAMES.NarrativeReport) {
      return '.doc,.docx,.pdf';
    }
    return '.csv';
  };

  const canShowDownloadTemplate = () => {
    if (selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC) {
      switch (selectProgramText) {
        case CDP.PROGRAM_FULL_NAME.DPP:
        case CDP.PROGRAM_FULL_NAME.BPSM:
        case CDP.PROGRAM_FULL_NAME.EF:
        case CDP.PROGRAM_FULL_NAME.LS:
        case CDP.PROGRAM_FULL_NAME.HW:
          return true;
        case CDP.PROGRAM_FULL_NAME.YWP:
          if (selectedTypeText !== CDP.DOL_TYPE_NAMES.NarrativeReport && selectedType) {
            return true;
          }
          return false;
        default:
          return false;
      }
    }
    return false;
  }
  return (
    <BlockUi
      blocking={loader}
      message={loadingMessage}
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
          <PageContainer
            pathName="Upload Data"
            title="Chronic Disease Program Data"
          />
          <section className="dashboard-list">
            <div className="container">
              {programDueDate?.length > 0 && (
                <DueDate
                  DPPDueDateData={programDueDate}
                />
              )}
              <hr className="custom-hr mt-0" />
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <h2 className="ymca-section-heading">
                    Submit Chronic Disease Program Data
                  </h2>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 text-end" />
              </div>
              <form className="program-data-form ymca-form">
                <div className="row">
                  <div className={`col-md-${getColValue()} col-sm-12`}>
                    <div className="form-group mb-3">
                      <label htmlFor="Association" className="form-label">
                        Program<span className="text-mandatory ms-1">*</span>
                      </label>
                      <select
                        className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper"
                        aria-label="Default select example"
                        value={selectedProgram}
                        onChange={e => onProgramChange(e)}
                      >
                        <option value="" selected>
                          {CDP.SELECT_DEFAULT}
                        </option>
                        {programs?.map(data => {
                          const allowedAttrs = { attr: data.programName }
                          return (
                            <option
                              className="notranslate"
                              key={data.programId}
                              value={data.programId}
                              {...allowedAttrs}
                            >
                              {`${data.programDescription}`}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>

                  <div className={`col-md-${getColValue()} col-sm-12`}>
                    <div className="form-group mb-3">
                      <label htmlFor="Association" className="form-label">
                        Data System
                        {''}
                        <span className="text-mandatory ms-1">*</span>
                      </label>
                      <select
                        className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper"
                        aria-label="Default select example"
                        value={selectedDataSystem}
                        onChange={e => onDataSystemChange(e)}
                      >
                        <option value="" selected>
                          {CDP.SELECT_DEFAULT}
                        </option>
                        {dataSystems?.map(data => (
                          <option
                            className="notranslate"
                            key={data.sourceSystemId}
                            value={data.sourceSystemId}
                          >
                            {data.sourceSystemDisplayName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedDataSystemText ===
                      CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC && (
                        <button
                          onClick={handleShowLearn}
                          className="btn ymca-card-link"
                          type="button"
                        >
                          <img
                            src={YmcaInfoIcon}
                            className="img-fluid me-1"
                            alt="info-icon" />
                          {''}
                          Learn More
                        </button>
                      )}
                  </div>

                  {selectedDataSystemText === CDP.DATA_SYSTEM_NAMES.DOL &&
                    selectProgramText === CDP.PROGRAM_FULL_NAME.YWP && (
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group mb-3">
                          <label htmlFor="Association" className="form-label">
                            Type<span className="text-mandatory ms-1">*</span>
                          </label>
                          <select
                            className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper"
                            value={selectedType}
                            onChange={e => onTypeChange(e)}
                          >
                            <option value="" selected>
                              {CDP.SELECT_DEFAULT}
                            </option>
                            {types?.map(data => (
                              <option
                                className="notranslate"
                                key={data.typeId}
                                value={data.typeId}
                              >
                                {`${data.typeName}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                </div>

                {!isImportedMsg && (
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-center">
                      <span className="ymca-warning-small-text">
                        {CDP.MESSAGES.importPendingWaring}
                      </span>
                    </div>
                  </div>
                )}

                <div className="row align-items-center mt-5">
                  {canShowDownloadTemplate() && (
                    <>
                      <div className="col-lg-5 text-end">
                        <div className="ymca-text-primary">
                          <button
                            disabled={disableButton}
                            className="btn btn-link  fw-bold"
                            type="button"
                            onClick={() => onDownloadClick()}
                          >
                            <img src={DownloadBlueIcon} alt="DownloadBlueIcon" className="me-1" />
                            {String.format(
                              CDP.LABELS.DOL_DOWNLOAD_TEMPLATE,
                              selectedTypeText
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center ymca-para">Or</div>
                    </>
                  )}

                  <div
                    className={
                      (canShowDownloadTemplate())
                        ? 'col-lg-5 text-start'
                        : 'col text-center'
                    }
                  >
                    <input className="d-none" id="file-upload-input" accept={acceptedFileType()}
                      type="file"
                      onChange={e => processSolera(e)} />
                    <button
                      disabled={disableButtonClick()}
                      className="btn ymca-primary-btn login-btn"
                      type="button"
                      onClick={() => onFetchClick()}
                    >
                      {buttonName || 'Fetch Data'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>

        {learn && (
          <div className="ymca-learn-more">
            <div className="ymca-learn-more-header">
              <h5 className="title mb-0">Instructions</h5>
              <button
                onClick={hideShowLearn}
                type="button"
                className="btn btn-link p-0"
              >
                <img src={CrossIconBlue} className="img-fluid" alt="cross-icon" />
              </button>
            </div>
            <div className="ymca-learn-more-content">
              <ul className="ymca-list p-0">
                <li>
                  <img src={YmcaListGrayIcon} className="img-fluid me-2" alt="ymca-list-gray-icon" />
                  {""}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  <img src={YmcaListGrayIcon} className="img-fluid me-2" alt="ymca-list-gray-icon" />
                  {""}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  <img src={YmcaListGrayIcon} className="img-fluid me-2" alt="ymca-list-gray-icon" />
                  {""}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  <img src={YmcaListGrayIcon} className="img-fluid me-2" alt="ymca-list-gray-icon" />
                  {""}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  <img src={YmcaListGrayIcon} className="img-fluid me-2" alt="ymca-list-gray-icon" />
                  {""}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
              </ul>
            </div>
          </div>
        )}
      </main>
      <CustomModal
        show={showMessage.status}
        type={showMessage.type}
        message={showMessage.message}
        submitButtonName="Ok"
        onSubmit={onModalOk}
      />
      <CustomModal
        show={toastStatus.status}
        type={toastStatus.status}
        message={toastStatus.message}
        submitButtonName={
          toastStatus.status === APP_LABELS.SUCCESS ? 'Redirect Now' : 'Ok'
        }
        onSubmit={onOkError}
      />
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
}

CdpUploadData.propTypes = {
  handelGetDolTypesAction: PropTypes.func.isRequired,
  handelGetProgramsAction: PropTypes.func.isRequired,
  handelGetDataSystemsAction: PropTypes.func.isRequired,
  handelPostCdpFileAction: PropTypes.func.isRequired,
  handelDetailPageTypeAction: PropTypes.func.isRequired,
  handelSaveFetchTypeAction: PropTypes.func.isRequired,
  handelPostCdpRedcapFetchAction: PropTypes.func.isRequired,
  getIsImportedMsgFetch: PropTypes.func.isRequired,
  associationSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handelGetProgramsAction: getProgramsAction,
  handelGetDataSystemsAction: getDataSystemsAction,
  handelPostCdpFileAction: postCdpFileAction,
  handelDetailPageTypeAction: detailPageTypeAction,
  handelSaveFetchTypeAction: saveFetchTypeAction,
  handelGetDolTypesAction: getDolTypesAction,
  handelPostCdpRedcapFetchAction: postCdpRedcapFetchAction,
  getIsImportedMsgFetch: getIsImportedMsgAction
};

const mapStateToProps = state => ({
  associationSelectorData: getCdpAssociationDueDateData(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CdpUploadData));
