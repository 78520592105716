export const COLI_ASS_DASHBOARD_COUNT_SUCCESS =
  'COLI_ASS_DASHBOARD_COUNT_SUCCESS';
export const COLI_ASS_DASHBOARD_COUNT_FAILURE =
  'COLI_ASS_DASHBOARD_COUNT_FAILURE';
export const COLI_ASS_DASHBOARD_LIST_SUCCESS =
  'COLI_ASS_DASHBOARD_LIST_SUCCESS';
export const COLI_ASS_DASHBOARD_LIST_FAILURE =
  'COLI_ASS_DASHBOARD_LIST_FAILURE';
export const COLI_ASSOCIATION_DETAILS_SUCCESS =
  'COLI_ASSOCIATION_DETAILS_SUCCESS';
export const COLI_ASSOCIATION_DETAILS_FAILURE =
  'COLI_ASSOCIATION_DETAILS_FAILURE';
export const COIL_UPLOAD_FILE_SUCCESS = 'COIL_UPLOAD_FILE_SUCCESS';
export const COIL_UPLOAD_FILE_FAILURE = 'COIL_UPLOAD_FILE_FAILURE';
export const COIL_DOWNLOAD_FILE_SUCCESS = 'COIL_DOWNLOAD_FILE_SUCCESS';
export const COIL_DOWNLOAD_FILE_FAILURE = 'COIL_DOWNLOAD_FILE_FAILURE';

export const COLI_APPROVE_REJECT_STATUS_FAILURE =
  'COLI_APPROVE_REJECT_STATUS_FAILURE';
export const COLI_APPROVE_REJECT_STATUS_SUCCESS =
  'COLI_APPROVE_REJECT_STATUS_SUCCESS';