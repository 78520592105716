import React, { useState, useEffect } from 'react';
import { CDP } from '@config/app-config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCdpPopoverSelector } from '@redux/chronic-disease-program/details/selectors';



const CustomPopover = (props) => {
  const { popoverDetails } = props;

  const [showPopover, setShowPopover] = useState(false);
  const [popoverData, setPopoverData] = useState(undefined);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    setPopoverData(popoverDetails.popoverDetails?.errors);
    setShowPopover(popoverDetails.popoverDetails?.show);
    setPopoverPosition(popoverDetails.popoverDetails?.position);
  }, [popoverDetails]);



  const popoverStyle = {
    position: 'absolute',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '10px',
    zIndex: 1052,
    width: '400px !important',
    left: popoverPosition?.left || 0,
    top: popoverPosition?.top || 0,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
    right: '100%',
    display: showPopover ? 'block' : 'none'
  };




  return (
    <div className={`cdp-error-summary-ul error-popover  left ${showPopover ? 'popover-show' : 'popover-hide'}`} style={popoverStyle}>
      <div className="popover-content">
        {
          <table className='popover-error-table table-striped'>
            {popoverData && Object.keys(popoverData)
              .filter(c => c !== CDP.ErrorRowIndexField)
              .map(er => (
                <tr className='popover-error-table-tr' key={er}>
                  <td className='popover-error-table-td'>[{er}] :</td>
                  <td className='popover-error-table-td-msg'>{popoverData[er]}</td>
                </tr>
              ))}
          </table>
        }
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  popoverDetails: getCdpPopoverSelector(state)
});

CustomPopover.propTypes = {
  popoverDetails: PropTypes.objectOf(PropTypes.any).isRequired
}

export default connect(mapStateToProps)(CustomPopover);
