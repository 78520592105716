import {
  FETCH_FILE_TEMPLATE_URL,
  FETCH_FILE_TEMPLATE,
  UPLOAD_FILE,
  UPLOAD_FILE_DROPDOWN,
  GET_TERMS_AND_CONDITIONS,
  POST_TERMS_AND_CONDITIONS
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getFileTemplateURL = fetchTempPayload => (dispatch, getState) =>
  asyncRequest({
    url: FETCH_FILE_TEMPLATE_URL(),
    method: 'POST',
    data: fetchTempPayload,
    successAction: data => ({
      type: types.FETCH_FILE_TEMPLATE_URL_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.FETCH_FILE_TEMPLATE_URL_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getFileTemplate = URL => (dispatch, getState) =>
  asyncRequest({
    url: FETCH_FILE_TEMPLATE(URL),

    successAction: data => ({
      type: types.FETCH_FILE_TEMPLATE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.FETCH_FILE_TEMPLATE_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getUploadFile = uploadFileData => (dispatch, getState) =>
  asyncRequest({
    url: UPLOAD_FILE(),
    method: 'POST',
    data: uploadFileData,
    successAction: data => ({
      type: types.UPLOAD_FILE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.UPLOAD_FILE_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getUploaFileDropdown = () => (dispatch, getState) =>
  asyncRequest({
    url: UPLOAD_FILE_DROPDOWN(),
    successAction: data => ({
      type: types.UPLOAD_FILE_DROPDOWN_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.UPLOAD_FILE_DROPDOWN_FAILURE,
      payload: error
    }),
    dispatch
  });
export const getTermsandConditions =
  associationNumber => (dispatch, getState) =>
    asyncRequest({
      url: GET_TERMS_AND_CONDITIONS(associationNumber),
      successAction: data => ({
        type: types.GET_TERMS_AND_CONDITIONS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_TERMS_AND_CONDITIONS_FAILURE,
        payload: error
      }),
      dispatch
    });
export const postTermsAndConditions = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: POST_TERMS_AND_CONDITIONS(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.POST_TERMS_AND_CONDITIONS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_TERMS_AND_CONDITIONS_FAILURE,
      payload: error
    }),
    dispatch
  });
