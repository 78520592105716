export const ADMIN_DASHBOARD_COLUMNS = () => [
  {
    Header: 'Profile ID',
    accessor: 'profileId',
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'Birth Date',
    accessor: 'childBirthDate',
    className: 'notranslate'
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    className: 'notranslate'
  },
  {
    Header: 'Ethnicity',
    accessor: 'ethnicity',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'details',
    className: 'notranslate'
  }
];

export const INVALID_DASHBOARD_COLUMNS = () => [
  {
    Header: 'Profile ID',
    accessor: 'profileId',
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'Birth Date',
    accessor: 'childBirthDate',
    className: 'notranslate'
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    className: 'notranslate'
  },
  {
    Header: 'Ethnicity',
    accessor: 'ethnicity',
    className: 'notranslate'
  },
  {
    Header: 'Errors',
    accessor: 'errorSummary',
    className: 'notranslate text-danger'
  },
  {
    Header: 'Actions',
    accessor: 'details',
    className: 'notranslate'
  }
];
