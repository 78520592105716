import {
  GET_COLI_DASHBOARD_COUNT_API,
  GET_COLI_DASHBOARD_ACCOCIATION_API
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getCOLIdashboardCount = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_COLI_DASHBOARD_COUNT_API(),
    method: 'GET',
    successAction: data => ({
      type: types.COLI_DASHBOARD_COUNT_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.COLI_DASHBOARD_COUNT_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCOLIAssociationList = (status) => (dispatch, getState) =>
  asyncRequest({
    url: GET_COLI_DASHBOARD_ACCOCIATION_API(status),
    method: 'GET',
    successAction: data => ({
      type: types.COLI_DASHBOARD_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.COLI_DASHBOARD_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });
