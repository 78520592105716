export const selectedDataSystemSelector = ({ persistedUploadDataStore }) => {
  return {
    dataSystem: persistedUploadDataStore.dataSystem
  };
};

export const selectedProgramSelector = ({ persistedUploadDataStore }) => {
  return {
    program: persistedUploadDataStore.program
  };
};

export const isImportedSelector = ({ persistedUploadDataStore }) => {
  return {
    progrisImportedMsgam: persistedUploadDataStore.isImportedMsg
  };
};

export const cdpDataSelector = ({ uploadDataStore }) => {
  return {
    cdpData: uploadDataStore.cdpData
  };
};

export const cdpDataFetchIdSelector = ({ persistedUploadDataStore }) => {
  return {
    cdpFetchId: persistedUploadDataStore.cdpFetchId
  };
};

export const cdpDataFetchTypeSelector = ({ persistedUploadDataStore }) => {
  return {
    fetchType: persistedUploadDataStore.fetchType
  };
};

export const cdpWelldFetchSelector = ({ uploadDataStore }) => {
  return {
    cdpwelldfetch: uploadDataStore.cdpwelldfetch
  };
};
