import * as types from './types';

const initialState = {
  Errors: [],
  CollabNonProfitOrg: [],
  CollabEducationOrg: [],
  CollabGovtOrgAndAffiliates: [],
  CollabOtherCommunityOrg: [],
  CollabPrivateOrgAndBusinesses: [],
  CollaborationsAmongYs: []
};

export default function AnnualReportCollaborationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.ANNUALREPORT_COLLAB_API_FAILURE:
      return { ...state, error: action.payload };

    case types.ANNUALREPORT_COLLAB_NON_PROFIT_ORG: {
      return {
        ...state,
        CollabNonProfitOrg: action.payload
      };
    }
    case types.ANNUALREPORT_COLLAB_EDUCATION_ORG:
      return {
        ...state,
        CollabEducationOrg: action.payload
      };
    case types.ANNUALREPORT_COLLAB_GOVT_ORG_AFFILIATES: {
      return {
        ...state,
        CollabGovtOrgAndAffiliates: action.payload
      };
    }
    case types.ANNUALREPORT_COLLAB_OTHER_COMMUNITY_ORG:
      return {
        ...state,
        CollabOtherCommunityOrg: action.payload
      };

    case types.ANNUALREPORT_COLLAB_PRIVATE_ORG_BUSINESS: {
      return {
        ...state,
        CollabPrivateOrgAndBusinesses: action.payload
      };
    }
    case types.ANNUALREPORT_COLLAB_ORATIONS_AMOUNG_YS:
      return {
        ...state,
        CollaborationsAmongYs: action.payload
      };
    case types.ANNUALREPORT_COLLAB_FAILURE:
      return {
        ...state,
        Errors: action.payload
      };

    default:
      return state;
  }
}
