export const VOLUNTEER_ADMIN_PREVIEW_SUCCESS =
  'VOLUNTEER_ADMIN_PREVIEW_SUCCESS';
export const VOLUNTEER_ADMIN_PREVIEW_FAILURE =
  'VOLUNTEER_ADMIN_PREVIEW_FAILURE';
export const VOLUNTEER_ADMIN_APPROVAL_SUCCESS =
  'VOLUNTEER_ADMIN_APPROVAL_SUCCESS';
export const VOLUNTEER_ADMIN_APPROVAL_FAILURE =
  'VOLUNTEER_ADMIN_APPROVAL_FAILURE';
export const VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_SUCCESS =
  'VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_SUCCESS';
export const VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_FAILURE =
  'VOLUNTEER_ADMIN_DOCUMENT_APPROVAL_FAILURE';
export const VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_SUCCESS =
  'VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_SUCCESS';
export const VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_FAILURE =
  'VOLUNTEER_ADMIN_INITIATE_BACKGROUND_SCREENING_APPROVAL_FAILURE';
  export const VOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_SUCCESS =
  'VOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_SUCCESS';
export const VOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_FAILURE =
  'VVOLUNTEER_ADMIN_CANCEL_INITIATED_BACKGROUND_SCREENING_APPROVAL_FAILURE';