import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CDP } from '@config/app-config';
import NotStarted from '@static/images/rejected-icon.svg';
import UploadedIconDark from '@static/images/upload-icon-yellow.svg';
import SuccessModalIcon from '@static/images/success-tick-icon.svg';

const successHtml = () => (
  <div className="d-flex align-items-center">
    <img
      className="img-fluid success-img me-2"
      src={SuccessModalIcon}
      alt="SuccessModalIcon"
    />
    <span className="ymca-text-success">{CDP.DASHBOARD.success}</span>
  </div>
);

const errorHtml = () => (
  <div className="d-flex align-items-center">
    <img
      className="img-fluid success-img me-2"
      src={NotStarted}
      alt="NotStarted"
    />
    <span className="ymca-text-error">{CDP.DASHBOARD.error}</span>
  </div>
);

const processHtml = () => (
  <div className="d-flex align-items-center">
    <img
      className="img-fluid success-img me-2"
      src={UploadedIconDark}
      alt="UploadedIconDark"
    />
    <span className="pending-status fw-bold">{CDP.DASHBOARD.inProgress}</span>
  </div>
);

const getStatusHtml = statusValue => {
  switch (statusValue) {
    case CDP.DASHBOARD.success:
      return successHtml();
    case CDP.DASHBOARD.error:
      return errorHtml();
    case CDP.DASHBOARD.inProgress:
      return processHtml();
    default:
      return <></>;
  }
};

const dateTimeSort = (rowA, rowB, columnId) => {
  const dateA = new Date(rowA.values[columnId]);
  const dateB = new Date(rowB.values[columnId]);
  return dateA - dateB;
};

const ASSOCIATE_DASHBOARD_COLUMNS = (downloadFile, onLinkClick, onShowHideClick) => [
  {
    Header: '',
    accessor: 'cdpDataFetchId',
    disableSortBy: true,
    Cell: (v) => {
      if (
        v.row.original.typeId?.toString() !==
        CDP.CDP_TYPE_ENUM['Narrative Report']
      ) {
        const location = {
          pathname: '/cdp/details',
          state: v.row?.original
        };

        return (
          <Link
            to={location}
            className="table-link "
            onClick={() => onLinkClick(v.row)}
          >
            View Details
          </Link>
        );
      }
      return <>Not applicable</>;
    }
  },
  {
    Header: 'Data System',
    accessor: 'dataSystemName',
    className: 'notranslate ymca-word-break ymca-ellipse ymca-text-primary'
  },
  {
    Header: 'Program',
    className: 'notranslate',
    accessor: (row) => {
      if (row?.programName === 'YWP') {
        return `${row?.programDisplayName} - ${row?.typeName}`;
      }
      return row?.programDisplayName;
    }
  },
  {
    Header: 'Created By',
    className: 'notranslate',
    accessor: (row) =>
      row.createdByName ? row.createdByName : row.createdBy
  },
  {
    Header: 'Created Date & Time',
    accessor: (row) => moment.utc(row.createdDate).local().format('DD MMM YYYY, hh:mm a'),
    className: 'notranslate',
    sortType: dateTimeSort
  },
  {
    Header: 'Status',
    className: '',
    accessor: 'statusName',
    Cell: (c) => <>{getStatusHtml(c.value)}</>
  },
  {
    Header: 'File',
    accessor: 'fileName',
    className: 'ymca-text-primary',
    disableSortBy: true,
    Cell: (v) => {
      if (v.value) {
        return (
          <button
            type="button"
            onClick={() => downloadFile(v.value, v.row)}
            className="btn btn-link table-link p-0 custom-align d-flex align-items-center text-decoration-none">
            <i className="fa fa-download me-1" aria-hidden="true" /> Download File
          </button>
        );
      }
      return <div className="notranslate black-color">Not applicable</div>;
    }
  },
  {
    Header: 'Action',
    accessor: "isHidden",

    Cell: (v) => {
      const canHidden = v.row.original.status === "E" || v.row.original.status === "P";
      if (canHidden) {
        return (
          <button type='button'
            onClick={() => onShowHideClick(v.row.original)}
            className={`btn btn-link text-decoration-none show-button-hidden-row ${v.value ? 'text-info' : 'text-danger'}`}>
            {v.value ? <><i className="fa fa-eye" /> Show</>
              : <><i className="fa fa-eye-slash" /> Hide </>}
          </button>
        );
      }
      return <></>;
    }
  }
];

export default ASSOCIATE_DASHBOARD_COLUMNS;
