export const SWIMSAFETY_PROGRAMS_LIST_SUCCESS =
  'SWIMSAFETY_PROGRAMS_LIST_SUCCESS';

export const SWIMSAFETY_PROGRAMS_DETAILS_SUCCESS =
  'SWIMSAFETY_PROGRAMS_DETAILS_SUCCESS';

export const SWIMSAFETY_PROGRAMS_DETAILS_FAILURE =
  'SWIMSAFETY_PROGRAMS_DETAILS_FAILURE';

export const SWIMSAFETY_PROGRAMS_UPDATE_OR_ADD_SUCCESS =
  'SWIMSAFETY_PROGRAMS_UPDATE_OR_ADD_SUCCESS';

export const SWIMSAFETY_PROGRAMS_UPDATE_OR_ADD_FAILURE =
  'SWIMSAFETY_PROGRAMS_UPDATE_OR_ADD_FAILURE';

export const SWIMSAFETY_PROGRAMS_DELETE_SUCCESS =
  'SWIMSAFETY_PROGRAMS_DELETE_SUCCESS';

export const SWIMSAFETY_PROGRAMS_BY_BRANCHES_SUCCESS =
  'SWIMSAFETY_PROGRAMS_BY_BRANCHES_SUCCESS';

export const SWIMSAFETY_PROGRAMS_API_FAILURE =
  'SWIMSAFETY_PROGRAMS_API_FAILURE';

export const SWIMSAFETY_PROGRAMS_BY_FILTERS_SUCCESS =
  'SWIMSAFETY_PROGRAMS_BY_FILTERS_SUCCESS';

export const SWIMSAFETY_PROGRAMS_BY_FILTERS_FAILURE =
  'SWIMSAFETY_PROGRAMS_BY_FILTERS_FAILURE';
