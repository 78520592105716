/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy
} from 'react-table';
import PropTypes from 'prop-types';

import SearchIcon from '@static/images/search-icon.svg';
import PreviousIcon from '@static/images/previous-icon.svg';
import NextIcon from '@static/images/Next-icon.svg';
import SortUpIcon from '@static/images/sorting-icon-up.svg';
import SortDownIcon from '@static/images/sorting-icon-down.svg';
import FirstIcon from '@static/images/first-icon.svg';
import LastIcon from '@static/images/Last-icon.svg';

const Table = props => {
  const { rows, headers, hiddenColumns, noRecordMessage } = props;
  const columns = headers;
  const data = rows || [];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    rows: filteredRows
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      defaultCanSort: true,
      initialState: {
        pageSize: 25,
        hiddenColumns: hiddenColumns || []
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const caluclatePageEntries = () => {
    if (data?.length > 0) return pageSize * (pageIndex + 1) - pageSize + 1;
    return 0;
  };

  const caluclatePageToEntries = () => {
    let toCount = data.length > 0 ? pageSize * (pageIndex + 1) : 0;
    if (filteredRows.length < toCount) {
      toCount = filteredRows.length;
    }
    return toCount;
  };

  const caluclatePageOfEntries = () => {
    let retrunValue = filteredRows?.length;
    if (globalFilter) {
      retrunValue = `${retrunValue} (filtered)`;
    }
    return retrunValue;
  };
  const getSortedDesc = isSortedDesc =>
    isSortedDesc ? (
      <button type="button" className="btn btn-link p-0 custom-align">
        <img src={SortUpIcon} className="img-fluid" alt="sorting-icon" />
      </button>
    ) : (
      <button type="button" className="btn btn-link p-0 custom-align">
        <img src={SortDownIcon} className="img-fluid" alt="sorting-icon" />
      </button>
    );
  return (
    <section className="dashboard-list">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h2 className="ymca-section-heading"></h2>
          </div>
          <div className="col-lg-6">
            <div className="search-form-wrapper">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={SearchIcon} alt="SearchIcon" />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control search-form-control"
                  placeholder="Search for a user...."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={globalFilter}
                  onChange={e => setGlobalFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive custom-scrollbar">
              <table
                className="table ymca-table ymca-tableHeight"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups?.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map(column => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <div className="sorting-icon-wrapper ms-2">
                            {column.isSorted ? (
                              getSortedDesc(column?.isSortedDesc)
                            ) : (
                              <>
                                {column.Header !== 'Summary' &&
                                column.Header !== '' ? (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-link p-0 custom-align"
                                    >
                                      <img
                                        src={SortUpIcon}
                                        className="img-fluid"
                                        alt="sorting-icon-up"
                                      />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-link p-0 custom-align"
                                    >
                                      <img
                                        src={SortDownIcon}
                                        className="img-fluid"
                                        alt="sorting-icon-down"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page?.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row?.cells?.map(cell => (
                          <td
                            {...cell.getCellProps()}
                            className={`${cell.column.className ?? ''}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {page.length === 0 && (
                <div className="text-center py-3 fw-bold ymca-warning-text">
                  {noRecordMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="d-flex">
              <div className="mt-1">Show:</div>
              <div className="ps-2 pe-2">
                <select
                  id="sel-options"
                  className="form-select form-select-sm"
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 25, 50, 100].map(pSize => (
                    <option key={pSize} value={pSize}>
                      {pSize}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-1 d-none">Entries Per Page</div>
            </div>
          </div>
          <div className="col-4">
            <nav aria-label="Page navigation" className="ymca-pagination mt-0">
              <ul className="pagination justify-content-center pagination-sm">
                <li className="page-item">
                  <button
                    type="button"
                    className="page-link custom-align"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    <img src={FirstIcon} className="pe-1" alt="" />
                    {'First'}
                  </button>
                </li>
                <li className="page-item">
                  <button
                    type="button"
                    className="page-link custom-align"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <img src={PreviousIcon} className="pe-1" alt="" />
                    {'Previous'}
                  </button>
                </li>

                {[...Array(pageCount).keys()].map(count => {
                  if (
                    count >= (pageIndex > 10 ? pageIndex - 10 : 0) &&
                    count <= (pageIndex < 10 ? 9 : pageIndex)
                  ) {
                    return (
                      <li className="page-item" key={count + 1}>
                        <button
                          type="button"
                          className={`page-link custom-align ${
                            count === pageIndex && 'active'
                          }`}
                          onClick={() => gotoPage(count)}
                        >
                          {count + 1}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}

                <li className="page-item">
                  <button
                    type="button"
                    className="page-link custom-align"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {'Next '}
                    <img src={NextIcon} className="pl-1 ms-1" alt="" />
                  </button>
                </li>
                <li className="page-item">
                  <button
                    type="button"
                    className="page-link custom-align"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {'Last'}
                    <img src={LastIcon} className="pe-1" alt="" />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-4">
            <div className="b-400 float-end">
              Showing {caluclatePageEntries()} to {caluclatePageToEntries()} of{' '}
              {caluclatePageOfEntries()} entries
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
  headers: PropTypes.arrayOf(PropTypes.any),
  setLoader: PropTypes.func.isRequired
};

Table.defaultProps = {
  rows: [],
  headers: [],
  hiddenColumns: [],
  noRecordMessage: 'No records found!'
};
Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
  headers: PropTypes.arrayOf(PropTypes.any),
  hiddenColumns: PropTypes.arrayOf(PropTypes.any),
  noRecordMessage: PropTypes.string
};

export default Table;
