import React from 'react';
import PropTypes from 'prop-types';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import BranchIcon from '@static/images/swimRegistration/fall-river.svg';
import SwimGreen from '@static/images/swimRegistration/swim-green.svg';
import ProgramInstructor from '@static/images/swimRegistration/program-instructor.svg';
import ProgramDates from '@static/images/swimRegistration/program-dates.svg';

const CardsList = ({ cardDetails }) => (
  <section className="stats-data">
    <div className="row">
      <div
        role="button"
        tabIndex="0"
        className="col-lg-3 col-md-3 col-sm-12 first-card"
      >
        <div className="swim-uploadDataCards">
          <div className="d-flex align-items-center h-100">
            <div className="card-icon">
              <img src={BranchIcon} className="dark-icon" alt="icon" />
            </div>

            <div className="card-title">
              <h4 className="swim-upload-data-title notranslate">
                {cardDetails?.branch?.branchname ||
                  cardDetails?.branch?.branchName ||
                  ''}
              </h4>
              <p>Branch</p>
            </div>
          </div>
        </div>
      </div>
      <div
        role="button"
        tabIndex="0"
        className="col-lg-3 col-md-3 col-sm-12 volunteer-stats-card-spacing"
      >
        <div className="swim-uploadDataCards">
          <div className="d-flex align-items-center h-100">
            <div className="card-icon">
              <img src={SwimGreen} className="dark-icon" alt="icon" />
            </div>

            <div className="card-title">
              <h4 className="swim-upload-data-title notranslate">
                {cardDetails?.program?.programName || ''}
              </h4>
              <p>Program Name</p>
            </div>
          </div>
        </div>
      </div>
      <div
        role="button"
        tabIndex="0"
        className="col-lg-3 col-md-3 col-sm-12 volunteer-stats-card-spacing"
      >
        <div className="swim-uploadDataCards">
          <div className="d-flex align-items-center h-100">
            <div className="card-icon">
              <img src={ProgramInstructor} className="dark-icon" alt="icon" />
            </div>

            <div className="card-title">
              <h4 className="swim-upload-data-title notranslate">
                {cardDetails?.program?.instructorName || ''}
              </h4>
              <p>Program Instructor Name</p>
            </div>
          </div>
        </div>
      </div>
      <div
        role="button"
        tabIndex="0"
        className="col-lg-3 col-md-3 col-sm-12 volunteer-stats-card-spacing"
      >
        <div className="swim-uploadDataCards">
          <div className="d-flex align-items-center h-100">
            <div className="card-icon">
              <img src={ProgramDates} className="dark-icon" alt="icon" />
            </div>

            <div className="card-title">
              <h4 className="swim-upload-data-title">
                {cardDetails?.program?.startDate
                  ? convertUtcToLocal(
                      cardDetails?.program?.startDate,
                      'DD MMM YYYY'
                    )
                  : ''}{' '}
                |{' '}
                {cardDetails?.program?.endDate
                  ? convertUtcToLocal(
                      cardDetails?.program?.endDate,
                      'DD MMM YYYY'
                    )
                  : ''}
              </h4>
              <p>Program Start Date & End Date</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

CardsList.propTypes = {
  cardDetails: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CardsList;
