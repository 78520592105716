import * as types from './types';

const initialState = {
  cdpFecthResult: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CDP_FETCH_SUCCESS:
      return {
        ...state,
        cdpFecthResult: { ...state.cdpFecthResult, ...action.payload }
      };
    case types.GET_CDP_FETCH_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_DATA_FETCH_SUCCESS:
      return {
        ...state,
        cdpDataFetch: action.payload
      };
    case types.GET_CDP_DATA_FETCH_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_SOLERA_IMPORT_SUCCESS:
      return {
        ...state,
        soleraImport: action.payload
      };
    case types.POST_CDP_SOLERA_IMPORT_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_CDP_REDCAP_IMPORT_SUCCESS:
      return {
        ...state,
        redcapImport: action.payload
      };
    case types.POST_CDP_REDCAP_IMPORT_FAILURE:
      return { ...state, error: action.payload };

    case types.SET_POPOVER_DETAILS:
      return { ...state, popoverDetails: action.payload };

    default:
      return state;
  }
}
