/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  getVolunteerAdminDashboardList,
  getVolunteerAdminDashboardTableList
} from '@redux/swim-registration/volunteer-admin-dashboard/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { APP_LABELS, STORAGE_KEYS } from '@config/app-config';

import BackIcon from '@static/images/back-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';

import PurpleIconDark from '@static/images/swimRegistration/purple-icon-dark.svg';
import PurpleIconLight from '@static/images/swimRegistration/purple-icon-light.svg';
import NewVolunteerIconDark from '@static/images/swimRegistration/new-volunteer-icon-dark.svg';
import NewVolunteerIconLight from '@static/images/swimRegistration/new-volunteer-icon-light.svg';
import ApprovedIconDark from '@static/images/swimRegistration/approved-icon-dark.svg';
import ApprovedIconLight from '@static/images/swimRegistration/approved-icon-light.svg';
import PendingIconDark from '@static/images/swimRegistration/pending-icon-dark.svg';
import PendingIconLight from '@static/images/swimRegistration/pending-icon-light.svg';
import RejectedDarkIcon from '@static/images/swimRegistration/rejected-icon-dark.svg';
import RejectedLightIcon from '@static/images/swimRegistration/rejected-icon-light.svg';

import SecureStorage from '@utilities/SecureStorage';
import Table from './table';
import VOLUNTEER_TABLE_COLUMNS from './volunteer-table-column';

const VolunteerAdminDashboard = props => {
  const {
    handleGetVolunteerAdminDashboardTable,
    handleGetVolunteerAdminDashboard,
    LoginUserData
  } = props;
  const dateFormatForDocument = 'MMM DD, YYYY | hh:mm a';
  const [volunteerCount, setVolunteerCount] = useState([]);
  const [volunteerList, setVolunteerList] = useState({});

  const [noOfVolunteers, setNoOfVolunteers] = useState(false);
  const [newVolunteerData, setNewVolunteerData] = useState(false);
  const [approvedVolunteerData, setApprovedVolunteerData] = useState(false);
  const [rejectedVolunteerData, setRejectedVolunteerData] = useState(false);
  const [pendingVolunteerData, setPendingVolunteerData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    SecureStorage.setItem(STORAGE_KEYS.DASHBOARD_URL, '/volunteer/dashboard');
    setLoader(true);
    handleGetVolunteerAdminDashboard()
      .then(result => {
        if (result?.data?.isSuccess) {
          setVolunteerCount(result?.data?.data);
          setLoader(false);
        } else {
          setLoader(false);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorPopup(true);
      });
    setLoader(true);
    handleGetVolunteerAdminDashboardTable()
      .then(result => {
        if (result?.data?.isSuccess) {
          setVolunteerList(result?.data?.data);
          setLoader(false);
        } else {
          setLoader(false);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorPopup(true);
      });
  }, []);

  const handleKipClick = KpiTitle => {
    if (KpiTitle === 'No of Volunteers') {
      setNoOfVolunteers(true);
      setNewVolunteerData(false);
      setApprovedVolunteerData(false);
      setRejectedVolunteerData(false);
      setPendingVolunteerData(false);
    }

    if (KpiTitle === 'New Volunteers') {
      setNoOfVolunteers(false);
      setNewVolunteerData(true);
      setApprovedVolunteerData(false);
      setRejectedVolunteerData(false);
      setPendingVolunteerData(false);
    }

    if (KpiTitle === 'Approved') {
      setNoOfVolunteers(false);
      setNewVolunteerData(false);
      setApprovedVolunteerData(true);
      setRejectedVolunteerData(false);
      setPendingVolunteerData(false);
    }

    if (KpiTitle === 'Rejected') {
      setNoOfVolunteers(false);
      setNewVolunteerData(false);
      setApprovedVolunteerData(false);
      setRejectedVolunteerData(true);
      setPendingVolunteerData(false);
    }

    if (KpiTitle === 'Pending') {
      setNoOfVolunteers(false);
      setNewVolunteerData(false);
      setApprovedVolunteerData(false);
      setRejectedVolunteerData(false);
      setPendingVolunteerData(true);
    }
  };

  const getVolunteerListForTable = volunteerData => {
    const volunteerLists = {
      New: [],
      Approved: [],
      Rejected: [],
      Pending: []
    };

    if (volunteerData && volunteerData.length > 0) {
      volunteerData.map(record => {
        const { status } = record;
        volunteerLists[status].push(record);
      });
    }

    let tableData = volunteerData;
    if (noOfVolunteers) {
      tableData = volunteerData;
    }

    if (newVolunteerData) {
      tableData = volunteerLists.New;
    }

    if (approvedVolunteerData) {
      tableData = volunteerLists.Approved;
    }

    if (rejectedVolunteerData) {
      tableData = volunteerLists.Rejected;
    }

    if (pendingVolunteerData) {
      tableData = volunteerLists.Pending;
    }

    if (tableData && tableData.length > 0) {
      return tableData?.map(data => {
        const location = {
          pathname: `/volunteer/preview/${data.volunteerId}`,
          state: data
        };

        return {
          VolunteerName: `${get(data, 'firstName')} ${get(
            data,
            'lastName',
            '-'
          )}`,
          YMCA: data?.associationName ? data?.associationName : '-',
          RegistrationFor: (
            <>
              {data?.registrationIds?.includes('isSwimmingRegistration') && (
                <span className="badge ymca-volunteer-badge pink-badge">
                  Swimming&nbsp;
                </span>
              )}
              {data?.registrationIds?.includes('isGymnasticsRegistration') && (
                <span className="badge ymca-volunteer-badge voilet-badge">
                  Gymnastics&nbsp;
                </span>
              )}
              {data?.registrationIds?.includes('isDivingRegistration') && (
                <span className="badge ymca-volunteer-badge purple-badge">
                  Diving
                </span>
              )}
              {data?.registrationIds?.includes('isPickleballRegistration') && (
                <span className="badge ymca-volunteer-badge dark-purple-badge">
                  Pickleball
                </span>
              )}
            </>
          ),
          Status: get(data, 'status'),
          UpdatedDateTime: get(data, 'updatedDate', null)
            ? moment(convertUtcToLocal(get(data, 'updatedDate'))).format(
                dateFormatForDocument
              )
            : '-',

          DetailPage: (
            <Link to={location} className="table-link">
              View Details
            </Link>
          )
        };
      });
    }
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
          <div className="container">
            <section className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <div className="user-name custom-username">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>Welcome!</h6>
                        <h2 className="ymca-sub-heading mb-0">
                          {`${get(
                            LoginUserData,
                            'user.firstName',
                            'User'
                          )} ${get(LoginUserData, 'user.lastName', '')}`}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
                  <Link className="ymca-title-link" to="/home">
                    <img
                      src={BackIcon}
                      className="ymca-back-link"
                      alt="back-icon"
                    />
                    {/* */}
                    Back
                  </Link>
                </div>
              </div>
            </section>
            {/* <DashboardCards volunteerCount={volunteerCount} /> */}
            <section className="stats-data volunteer-admin-stats-card">
              <div className="row">
                <div className="col-lg-auto col-md-6 col-sm-12 volunteer-stats-card-spacing first-card">
                  <button
                    className="card stats-card volunteers"
                    onClick={() => handleKipClick('No of Volunteers')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={PurpleIconDark}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={PurpleIconLight}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4 className="text-sm-start">
                          {volunteerCount?.totalsVolunteers}
                        </h4>
                        <p>No. of Volunteers</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12 volunteer-stats-card-spacing">
                  <button
                    className="card stats-card new-volunteers"
                    onClick={() => handleKipClick('New Volunteers')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={NewVolunteerIconDark}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={NewVolunteerIconLight}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4 className="text-sm-start">
                          {volunteerCount?.newVolunteers}
                        </h4>
                        <p>New Volunteers</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12 volunteer-stats-card-spacing">
                  <button
                    className="card stats-card approved"
                    onClick={() => handleKipClick('Approved')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={ApprovedIconDark}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={ApprovedIconLight}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4 className="text-sm-start">
                          {volunteerCount?.approvedVolunteers}
                        </h4>
                        <p>Approved</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12 volunteer-stats-card-spacing">
                  <button
                    className="card stats-card pending"
                    onClick={() => handleKipClick('Pending')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={PendingIconDark}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={PendingIconLight}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4 className="text-sm-start">
                          {volunteerCount?.pendingVolunteers}
                        </h4>
                        <p>Pending</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-auto col-md-6 col-sm-12 volunteer-stats-card-spacing">
                  <button
                    className="card stats-card rejected"
                    onClick={() => handleKipClick('Rejected')}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={RejectedDarkIcon}
                          className="dark-icon"
                          alt="icon"
                        />
                        <img
                          src={RejectedLightIcon}
                          className="light-icon"
                          alt="icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4 className="text-sm-start">
                          {volunteerCount?.rejectedVolunteers}
                        </h4>
                        <p>Rejected</p>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </section>
          </div>

          <hr className="custom-hr mt-0" />

          <Table
            rows={getVolunteerListForTable(volunteerList)}
            headers={VOLUNTEER_TABLE_COLUMNS}
            setLoader={setLoader}
          />
        </div>
      </main>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={APP_LABELS.COMMON_ERROR_MESSAGE}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

VolunteerAdminDashboard.propTypes = {
  handleGetVolunteerAdminDashboard: PropTypes.func.isRequired,
  handleGetVolunteerAdminDashboardTable: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

const mapDispatchToProps = {
  handleGetVolunteerAdminDashboard: getVolunteerAdminDashboardList,
  handleGetVolunteerAdminDashboardTable: getVolunteerAdminDashboardTableList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VolunteerAdminDashboard));
