/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { APP_LABELS, STORAGE_KEYS } from '@config/app-config';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import axios from 'axios';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import AssociationActive from '@static/images/insurance/ins-active-association.svg';
import AssociationIcon from '@static/images/insurance/ins-association.svg';
import SubmissionIcon from '@static/images/insurance/submission.svg';
import ApprovedIcon from '@static/images/swimRegistration/approved-icon.svg';
import NewSubmissionIcon from '@static/images/insurance/new-submission-icon.png';
import PendingIcon from '@static/images/insurance/pending-icon.png';
import RejectedIcon from '@static/images/insurance/rejected-icon.png';
import ExpireIcon from '@static/images/insurance/expired-icon.png';
import SubmissionDark from '@static/images/insurance/submission-active.svg';
import ApprovedIconDark from '@static/images/insurance/approved-active.svg';
import ApprovedIconLight from '@static/images/insurance/approved.svg';
import PendingIconDark from '@static/images/insurance/pending-active.svg';
import PendingIconLight from '@static/images/insurance/pending.svg';
import RejectedDarkIcon from '@static/images/insurance/rejected-active.svg';
import RejectedLightIcon from '@static/images/insurance/rejected.svg';
import ExpiredDarkIcon from '@static/images/insurance/expired-active.svg';
import ExpiredIcon from '@static/images/insurance/expired.svg';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { GET_COLI_EXPORT_ASSOCIATION_API } from '@config/api-config';
import { SwimSafetyAssocListSelector } from '@redux/siwm-safety/dashboard/selectors';
import get from 'lodash.get';
import {
  getCOLIAssociationList,
  getCOLIdashboardCount
} from '@redux/certificate-of-liability/dashboard/actions';
import Table from './table';
import INSURANCE_ASSOCIATIONS_COLUMNS from './columns';
import SecureStorage from '../../../../utilities/SecureStorage';

const Dashboard = props => {
  const { handleGetDashBoardCount, handlegetAssociationList, LoginUserData } =
    props;
  const [countData, setCountData] = useState({});
  const [associationList, setAssociationList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const authToken = JSON.parse(sessionStorage.getItem('token'));
  const Offset = new Date().getTimezoneOffset();

  const role = get(LoginUserData, 'user.role[0]');

  const handleList = () => {
    handlegetAssociationList(selectedTab)
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setAssociationList(result?.data?.data);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleCountCall = () => {
    handleGetDashBoardCount().then(result => {
      if (result?.data?.data) {
        setCountData(result?.data?.data);
      }
    });
  };

  const getDesignedStatus = status => {
    let icon = '';
    let textColorCls = '';
    let text = '';

    switch (status) {
      case 'Approved':
        icon = ApprovedIcon;
        textColorCls = 'approved-color';
        text = 'Approved';
        break;
      case 'Rejected':
        icon = RejectedIcon;
        textColorCls = 'rejected-color';
        text = 'Rejected';
        break;
      case 'New':
        icon = NewSubmissionIcon;
        textColorCls = 'new-submission-color';
        text = 'New Upload';
        break;
      case 'Pending':
        icon = PendingIcon;
        textColorCls = 'pending-color';
        text = 'Pending';
        break;
      case 'Expired':
        icon = ExpireIcon;
        textColorCls = 'expired-color';
        text = 'Expired';
        break;
      default:
        break;
    }

    return (
      <div className="d-flex">
        <img src={icon} className="img-fluid me-2" alt={status} />
        <span className={`status-cls ${textColorCls}`}>{text}</span>
      </div>
    );
  };

  const handleViewDetails = assdata => {
    sessionStorage.setItem('yusa_selected_associate', JSON.stringify(assdata));
    sessionStorage.setItem('swim_Program_selected_tab', 'Branches');
  };

  const newAssociationChangeLogData = associationList?.map(data => {
    const location = {
      pathname: '/insurance/association/dashboard',
      state: data
    };

    return {
      ...data,
      associationName: `${data.associationName} (${data.associationNumber})`,
      lastUplodedDateTime: get(data, 'lastUplodedDateTime')
        ? convertUtcToLocal(get(data, 'lastUplodedDateTime'))
        : '-',
      expiryDate: get(data, 'expiryDate')
        ? convertUtcToLocal(get(data, 'expiryDate'), 'DD MMM YYYY')
        : '-',
      status: get(data, 'status')
        ? getDesignedStatus(get(data, 'status'))
        : '-',
      details: (
        <Link
          to={location}
          className="table-link"
          onClick={() => handleViewDetails(data)}
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </Link>
      )
    };
  });

  useEffect(async () => {
    SecureStorage.setItem(STORAGE_KEYS.DASHBOARD_URL, '/insurance/dashboard');
    window.scroll(0, 0);
    setLoader(true);
    handleCountCall();
    handleList();
  }, []);

  useEffect(() => {
    handleList();
  }, [selectedTab]);

  const downLoadAssocitions = () => {
    setLoader(true);
    axios
      .get(GET_COLI_EXPORT_ASSOCIATION_API(Offset), {
        responseType: 'blob',
        headers: {
          'Content-Type': 'blob',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'YMCA-Associations.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  const getTableTitle = () => {
    switch (selectedTab) {
      case 'new':
        return 'New Submissions';
      case 'approved':
        return 'Approved';
      case 'pending':
        return 'Pending';
      case 'rejected':
        return 'Rejected';
      case 'expired':
        return 'Expired';
      default:
        return 'YMCA Associations';
    }
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper ">
            <div className="container">
              <section className="dashboard-header ">
                <div className="row align-items-end mt-3 ">
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading mb-2">
                        {APP_LABELS.NAV_DASHBOARD}
                      </h2>
                    </div>
                  </div>
                </div>
              </section>

              <section className="stats-data mt-4">
                <div className="row swim-cursor-remove ">
                  <button
                    type="button"
                    tabIndex="0"
                    className="col-lg-2 col-md-2 col-sm-12 px-1 active-associations insurance-kpi-card "
                    onClick={() => {
                      setSelectedTab(null);
                    }}
                  >
                    <div
                      className={`insurance-yusa-cards insurance-associations ${
                        !selectedTab &&
                        'insurance-active-associations insurance-active-cards'
                      }
                      `}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={AssociationActive}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={AssociationIcon}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>

                        <div className="card-title">
                          <h4>{countData?.totalAssociations || 0}</h4>
                          <p>No. of Associations</p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    type="button"
                    tabIndex="0"
                    className="col-lg-2 col-md-2 col-sm-12 px-1 insurance-kpi-card"
                    onClick={() => {
                      setSelectedTab('new');
                    }}
                  >
                    <div
                      className={`insurance-yusa-cards insurance-newSubmissions  ${
                        selectedTab === 'new' &&
                        'insurance-active-newSubmissions insurance-active-cards'
                      }
                      `}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={SubmissionDark}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={SubmissionIcon}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>
                        <div className="card-title">
                          <h4>{countData?.newlySubmitted || 0}</h4>
                          <p>New Submissions</p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    type="button"
                    tabIndex="0"
                    className="col-lg-2 col-md-2 col-sm-12 px-1 insurance-kpi-card"
                    onClick={() => {
                      setSelectedTab('approved');
                    }}
                  >
                    <div
                      className={`insurance-yusa-cards insurance-approved  ${
                        selectedTab === 'approved' &&
                        'insurance-active-approved insurance-active-cards'
                      }
                      `}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={ApprovedIconDark}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={ApprovedIconLight}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>

                        <div className="card-title">
                          <h4>{countData?.approved || 0}</h4>
                          <p>Approved</p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    type="button"
                    tabIndex="0"
                    className="col-lg-2 col-md-2 col-sm-12 px-1 insurance-kpi-card"
                    onClick={() => {
                      setSelectedTab('pending');
                    }}
                  >
                    <div
                      className={`insurance-yusa-cards insurance-pending ${
                        selectedTab === 'pending' &&
                        'insurance-active-pending insurance-active-cards'
                      }
                      `}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={PendingIconDark}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={PendingIconLight}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>
                        <div className="card-title">
                          <h4>{countData?.pending || 0}</h4>
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    type="button"
                    tabIndex="0"
                    className="col-lg-2 col-md-2 col-sm-12 px-1 insurance-kpi-card"
                    onClick={() => {
                      setSelectedTab('rejected');
                    }}
                  >
                    <div
                      className={`insurance-yusa-cards insurance-rejected ${
                        selectedTab === 'rejected' &&
                        'insurance-active-rejected insurance-active-cards'
                      }
                      `}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={RejectedDarkIcon}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={RejectedLightIcon}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>
                        <div className="card-title">
                          <h4>{countData?.rejected || 0}</h4>
                          <p>Rejected</p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    type="button"
                    tabIndex="0"
                    className="col-lg-2 col-md-2 col-sm-12 px-1 insurance-kpi-card"
                    onClick={() => {
                      setSelectedTab('expired');
                    }}
                  >
                    <div
                      className={`insurance-yusa-cards insurance-expired  ${
                        selectedTab === 'expired' &&
                        'insurance-active-expired insurance-active-cards'
                      }
                      `}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          <img
                            src={ExpiredDarkIcon}
                            className="dark-icon"
                            alt="icon"
                          />
                          <img
                            src={ExpiredIcon}
                            className="light-icon"
                            alt="icon"
                          />
                        </div>
                        <div className="card-title">
                          <h4>{countData?.expired || 0}</h4>
                          <p>Expired</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </section>
              <hr className="custom-hr mt-0" />

              <Table
                rows={newAssociationChangeLogData}
                headers={INSURANCE_ASSOCIATIONS_COLUMNS()}
                title={getTableTitle()}
                downLoadAssocitions={downLoadAssocitions}
                role={role}
              />
            </div>
          </div>
        </main>
      </div>
    </BlockUi>
  );
};

Dashboard.propTypes = {
  handleGetDashBoardCount: PropTypes.func.isRequired,
  handlegetAssociationList: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AssociationList: SwimSafetyAssocListSelector(state)
});

const mapDispatchToProps = {
  handleGetDashBoardCount: getCOLIdashboardCount,
  handlegetAssociationList: getCOLIAssociationList
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
