import * as types from './types';

const initialState = {
  UserRegistrationData: {},
  UserRegistrationDetails: {},
  ModuleList: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        UserRegistrationData: action.payload
      };
    }
    case types.USER_REGISTRATION_FAILURE:
      return { ...state, error: action.payload };

    case types.USER_DETAILS_SUCCESS: {
      return {
        ...state,
        UserRegistrationDetails: action.payload
      };
    }
    case types.USER_DETAILS_FAILURE:
      return { ...state, error: action.payload };
    case types.RESET_USER_DETAILS:
      return { ...state, UserRegistrationDetails: {} };

    case types.MODULE_LIST_SUCCESS: {
      return {
        ...state,
        ModuleList: action.payload
      };
    }
    case types.MODULE_LIST_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
