/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import {
  getStrategicRelationships,
  postStrategicRelationships,
  resetReduxState
} from '@redux/diversity-inclusion-&-global-metrics/strategic-relationships/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetStrategicRelationshipsSelector } from '@redux/diversity-inclusion-&-global-metrics/strategic-relationships/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import Help from '@modules/shared/components/reusable-components/help';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import TextError from '@modules/shared/components/reusable-components/text-error';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  yEngagesPartnershipsCollaborations: Yup.string().nullable(),
  yengagesDiverseStakeholdersDescription: Yup.string().nullable(),
  yIntentionallyEngagesDiverseSuppliers: Yup.string().nullable(),
  ychallengesSupplierDiversityDescription: Yup.string().nullable()
});

const StrategicRelationshipsTab = props => {
  const {
    handleGetStrategicRelationships,
    handleResetRedux,
    handlePostStrategicRelationships,
    GetStrategicRelationshipsSelectorData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Diversity Inclusion & Global Metrics';
  const formikref = useRef();
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [resStatus, setResStatus] = useState('');
  const [
    yengagesDiverseStakeholdersDescriptionCount,
    setYengagesDiverseStakeholdersDescriptionCount
  ] = useState(
    GetStrategicRelationshipsSelectorData?.data
      ?.yengagesDiverseStakeholdersDescription?.length || 0
  );
  const [
    ychallengesSupplierDiversityDescriptionCount,
    setYchallengesSupplierDiversityDescriptionCount
  ] = useState(
    GetStrategicRelationshipsSelectorData?.data
      ?.ychallengesSupplierDiversityDescription?.length || 0
  );

  const [
    yengagesDiverseStakeholdersDescription,
    setYengagesDiverseStakeholdersDescription
  ] = useState(
    GetStrategicRelationshipsSelectorData?.data
      ?.yengagesDiverseStakeholdersDescription
  );
  const [
    ychallengesSupplierDiversityDescription,
    setYchallengesSupplierDiversityDescription
  ] = useState(
    GetStrategicRelationshipsSelectorData?.data
      ?.ychallengesSupplierDiversityDescription
  );

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = () => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetStrategicRelationships(associationsNum).then(res => {
      if (res.status === 200) {
          setYengagesDiverseStakeholdersDescriptionCount(res?.data?.data?.yengagesDiverseStakeholdersDescription?.length || 0);
          setYengagesDiverseStakeholdersDescription(res?.data?.data?.yengagesDiverseStakeholdersDescription);
          setYchallengesSupplierDiversityDescriptionCount(res?.data?.data?.ychallengesSupplierDiversityDescription?.length || 0);
          setYchallengesSupplierDiversityDescription(res?.data?.data?.ychallengesSupplierDiversityDescription);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'strategic-relationships'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetStrategicRelationships');
    };
  }, []);

  const initialValues = {
    yEngagesPartnershipsCollaborations: '',
    yIntentionallyEngagesDiverseSuppliers: ''
  };

  const editValues = {
    yEngagesPartnershipsCollaborations:
      GetStrategicRelationshipsSelectorData?.data
        ?.yEngagesPartnershipsCollaborations[0],

    yIntentionallyEngagesDiverseSuppliers:
      GetStrategicRelationshipsSelectorData?.data
        ?.yIntentionallyEngagesDiverseSuppliers[0]
  };

  const onSubmit = values => {
    setLoader(true);
    const payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetStrategicRelationshipsSelectorData?.data?.createdByName,
      createdByPersonId:
        GetStrategicRelationshipsSelectorData?.data?.createdByPersonId,
      createdDatetime:
        GetStrategicRelationshipsSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      strategicRelationshipsId:
        GetStrategicRelationshipsSelectorData?.data?.strategicRelationshipsId,
      reportBatchId: GetStrategicRelationshipsSelectorData?.data?.reportBatchId,
      recordStatus: GetStrategicRelationshipsSelectorData?.data?.recordStatus,
      yEngagesPartnershipsCollaborations: [
        values.yEngagesPartnershipsCollaborations
      ],

      yengagesDiverseStakeholdersDescription,

      yIntentionallyEngagesDiverseSuppliers: [
        values.yIntentionallyEngagesDiverseSuppliers
      ],

      ychallengesSupplierDiversityDescription
    };
    setSuccessPopup(false);
    handlePostStrategicRelationships(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall();
        setLoader(false);
        setSuccessPopup(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const handelCount = (value, textareaName) => {
    if (textareaName === 'yengagesDiverseStakeholdersDescription') {
      setYengagesDiverseStakeholdersDescriptionCount(value?.length || 0);
      setYengagesDiverseStakeholdersDescription(value);
    } else if (textareaName === 'ychallengesSupplierDiversityDescription') {
      setYchallengesSupplierDiversityDescriptionCount(value?.length || 0);
      setYchallengesSupplierDiversityDescription(value);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onKeyDown={() => {}}
        onClick={handleShowHelp}
        tabIndex="0"
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ dirty, errors }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-covidRelif">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <p className="ymca-instructTitle">
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html: instructions
                          }}
                        />
                      }
                    </p>
                  </div>
                </div>
                {GetStrategicRelationshipsSelectorData?.data?.recordStatus?.toUpperCase() ===
                  APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                <h4 className="ymca-headingTitle txt-mandatory">
                  {
                    'Our Y engages in partnerships, collaborations, and relationship-building efforts with a variety of diverse community and global organizations, exchanges, etc. to connect diverse stakeholders across the  community.'
                  }
                </h4>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table ymca-lastTable">
                    <tbody className="ymca-cuttingBody ymca-nostripeBody">
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yEngagesPartnershipsCollaborations"
                              value="diverseStakeholdersInstitutionalized"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            {
                              'We actively do so and have institutionalized this (e.g., in our strategic plan, board agenda, program planning, leadership/staff performance objectives)'
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yEngagesPartnershipsCollaborations"
                              value="diverseStakeholdersOpportunities"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            {
                              'We do this primarily when opportunities present  themselves (i.e., not an institutionalized approach)'
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yEngagesPartnershipsCollaborations"
                              value="diverseStakeholdersInvited"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            {
                              'We do this primarily when invited by other stakeholders'
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yEngagesPartnershipsCollaborations"
                              value="diverseStakeholdersNotYetDo"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            We do not yet do this
                          </span>
                        </td>
                      </tr>
                      <ErrorMessage
                        component={TextError}
                        name="yEngagesPartnershipsCollaborations"
                      />
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  Please provide a brief description of how your Y engages
                  diverse stakeholders.
                </h4>
                <textarea
                  name="yengagesDiverseStakeholdersDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={yengagesDiverseStakeholdersDescription}
                  defaultValue={
                    GetStrategicRelationshipsSelectorData?.data
                      ?.yengagesDiverseStakeholdersDescription
                  }
                  onChange={e =>
                    handelCount(
                      e.target.value,
                      'yengagesDiverseStakeholdersDescription'
                    )
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>
                      {`(${yengagesDiverseStakeholdersDescriptionCount}
                      /8000)`}
                    </p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle txt-mandatory">
                  Our Y intentionally engages diverse suppliers such as women-
                  and minority-owned businesses and tracks direct spending with
                  these business enterprises.
                </h4>

                <div className="ymca-tableResponsive">
                  <table className="table ymca-table ymca-lastTable">
                    <tbody className="ymca-cuttingBody ymca-nostripeBody">
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yIntentionallyEngagesDiverseSuppliers"
                              value="businessEnterprisesMaintain"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            {
                              'We have and actively maintain a supplier diversity program (i.e., structured processes designed to achieve spending targets with suppliers that are at least 51% women- or minority-owned)'
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yIntentionallyEngagesDiverseSuppliers"
                              value="businessEnterprisesRecruit"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            {
                              'We continually recruit and utilize diverse suppliers (i.e., not a formal program)'
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yIntentionallyEngagesDiverseSuppliers"
                              value="businessEnterprisesDiverseSuppliers"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            We use relatively few diverse suppliers
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN d-flex ymca-baselineArea">
                          <div className="ymca-setListing ps-0">
                            <Field
                              type="radio"
                              name="yIntentionallyEngagesDiverseSuppliers"
                              value="businessEnterprisesNotEngaged"
                              className="ymca-fieldRadio"
                            />
                          </div>
                          <span className="ymca-texting">
                            {
                              'We have not intentionally engaged diverse suppliers at this point'
                            }
                          </span>
                        </td>
                      </tr>
                      <ErrorMessage
                        component={TextError}
                        name="yIntentionallyEngagesDiverseSuppliers"
                      />
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  Please Describe your Ys challenges with supplier diversity.
                </h4>
                <textarea
                  name="ychallengesSupplierDiversityDescription"
                  className="ymca-areaTextWidth"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={ychallengesSupplierDiversityDescription}
                  defaultValue={
                    GetStrategicRelationshipsSelectorData?.data
                      ?.ychallengesSupplierDiversityDescription
                  }
                  onChange={e =>
                    handelCount(
                      e.target.value,
                      'ychallengesSupplierDiversityDescription'
                    )
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>
                      {`(${ychallengesSupplierDiversityDescriptionCount}
                      /8000)`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={
                    GetStrategicRelationshipsSelectorData?.data?.updatedByName
                  }
                  Time={
                    GetStrategicRelationshipsSelectorData?.data?.updatedDatetime
                  }
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      {help && (
        <Help hidePopup={hideShowHelp} keyValue="strategic-relationships" />
      )}
    </div>
  );
};

StrategicRelationshipsTab.propTypes = {
  handleGetStrategicRelationships: PropTypes.func.isRequired,
  handlePostStrategicRelationships: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetStrategicRelationshipsSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetStrategicRelationships: getStrategicRelationships,
  handlePostStrategicRelationships: postStrategicRelationships,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetStrategicRelationshipsSelectorData:
    GetStrategicRelationshipsSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(StrategicRelationshipsTab));
