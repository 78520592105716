import {
  ANNUAL_REPORT_GET_ARMED_SERVICES,
  ANNUAL_REPORT_ARMED_SERVICES
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getArmedService =
  (AssociationNumber, BranchNumber) => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_ARMED_SERVICES(
        AssociationNumber,
        BranchNumber
      ),
      successAction: data => ({
        type: types.ARMED_SERVICE_GET_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ARMED_SERVICE_GET_FAILURE,
        payload: error
      }),
      dispatch
    });
export const armedService = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: ANNUAL_REPORT_ARMED_SERVICES(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.ARMED_SERVICE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ARMED_SERVICE_FAILURE,
      payload: error
    }),
    dispatch
  });
