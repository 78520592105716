import React from 'react';
import PropTypes from 'prop-types';

import { Header, Footer } from '@components';
import ErrorBoundary from '@ErrorBoundary';

export default function BasePage(props) {
  const { children, includeHeader, includeFooter } = props;
  // eslint-disable-next-line no-restricted-globals
  const locationPath = location;
  const path = locationPath.pathname.split('/')[1];
  const actualPath = locationPath.pathname.replace(/^\//, '');
  return (
    <ErrorBoundary>
      <div className="ymca-wrapper">
        {includeHeader && <Header Path={path} ActualPath={actualPath} />}
        <div className="content-container">{children}</div>
        {includeFooter && <Footer />}
      </div>
    </ErrorBoundary>
  );
}

BasePage.propTypes = {
  children: PropTypes.node,
  includeHeader: PropTypes.bool,
  includeFooter: PropTypes.bool
};

BasePage.defaultProps = {
  children: [],
  includeHeader: true,
  includeFooter: true
};
