import {
  GET_AR_PLEDGE_LIST,
  GET_AR_CVO_CONTACT_LIST,
  GET_PLEDGE_CVO_CONTACT_LIST,
  GET_AR_INCOMING_CVO_CONTACT_LIST,
  GET_PLEDGE_INCOMING_CVO_CONTACT_LIST,
  POST_SEND_EMAIL_CEO_CVO_PLEDGE,
  GET_CEO_CVO_DETAILS
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getARPledgeListApi = AssociationNumber => (dispatch, getState) =>
  asyncRequest({
    url: GET_AR_PLEDGE_LIST(AssociationNumber),
    successAction: data => ({
      type: types.ANNUALREPORT_PLEDGE_STATEMENT_DATA,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ANNUALREPORT_PLEDGE_STATEMENT_FAILURE,
      payload: error
    }),
    dispatch
  });

export const PostARPledgeListApi = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: GET_AR_PLEDGE_LIST(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.ANNUALREPORT_PLEDGE_STATEMENT_DATA,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ANNUALREPORT_PLEDGE_STATEMENT_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getARContactDetailsApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: GET_AR_CVO_CONTACT_LIST(AssociationNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_CVO_CONTACT_DATA,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_PLEDGE_STATEMENT_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getPledgeContactDetailsApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: GET_PLEDGE_CVO_CONTACT_LIST(AssociationNumber),
      successAction: data => ({
        type: types.ANNUALREPORT_CVO_CONTACT_DATA,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUALREPORT_PLEDGE_STATEMENT_FAILURE,
        payload: error
      }),
      dispatch
    });

export const PostARContactDetailsApi = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: GET_AR_CVO_CONTACT_LIST(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.ANNUALREPORT_CVO_CONTACT_DATA,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ANNUALREPORT_PLEDGE_STATEMENT_FAILURE,
      payload: error
    }),
    dispatch
  });
export const getARIncomingContactDetailsApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: GET_AR_INCOMING_CVO_CONTACT_LIST(AssociationNumber),
      successAction: data => ({
        type: types.GET_AR_INCOMING_CONTACT_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_AR_INCOMING_CONTACT_DETAILS_FAILURE,
        payload: error
      }),
      dispatch
    });
export const postIncomingContactDetailsApi =
  bodyObject => (dispatch, getState) =>
    asyncRequest({
      url: GET_AR_INCOMING_CVO_CONTACT_LIST(),
      method: 'POST',
      data: bodyObject,
      successAction: data => ({
        type: types.POST_AR_INCOMING_CONTACT_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.POST_AR_INCOMING_CONTACT_DETAILS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getPledgeIncomingCvoContactDetailsApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: GET_PLEDGE_INCOMING_CVO_CONTACT_LIST(AssociationNumber),
      successAction: data => ({
        type: types.GET_INCOMING_CONTACT_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_INCOMING_CONTACT_DETAILS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const postSendEmailRequestCEOCVO = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: POST_SEND_EMAIL_CEO_CVO_PLEDGE(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.SEND_CEO_CVO_EMAIL_REQUEST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SEND_CEO_CVO_EMAIL_REQUEST_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCeoCvoDetails = AssociationNumber => (dispatch, getState) =>
  asyncRequest({
    url: GET_CEO_CVO_DETAILS(AssociationNumber),
    successAction: data => ({
      type: types.GET_CEO_CVO_DETAILS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CEO_CVO_DETAILS_FAILURE,
      payload: error
    }),
    dispatch
  });
