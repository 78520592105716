import * as types from './types';

const initialState = {
  SwimSafetyDashboardCount: {},
  SwimSafetyAssociationList: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SWIMSAFETY_DASHBOARD_COUNT_SUCCESS: {
      return {
        ...state,
        SwimSafetyDashboardCount: action.payload
      };
    }
    case types.SWIMSAFETY_DASHBOARD_COUNT_FAILURE:
      return { ...state, error: action.payload };

    case types.SWIMSAFETY_ASSOCIATION_LIST_SUCCESS: {
      return {
        ...state,
        SwimSafetyAssociationList: action.payload
      };
    }
    case types.SWIMSAFETY_ASSOCIATION_LIST_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
