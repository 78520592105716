import { GET_MOYP_DETAIL_VIEW } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const getMoypDetailView =
  (associationNumber, uploadeddate, Offset) => (dispatch, getState) =>
    asyncRequest({
      url: GET_MOYP_DETAIL_VIEW(associationNumber, uploadeddate, Offset),
      successAction: data => ({
        type: types.MOYP_DETAIL_VIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.MOYP_DETAIL_VIEW_FAILURE,
        payload: error
      }),
      dispatch
    });

export default getMoypDetailView;
