const ASSOCIATION_COLUMNS_2022 = [
  {
    Header: 'Association Name',
    accessor: 'AssociationName',
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'Member Group',
    accessor: 'MemberGroup',
    className: 'notranslate'
  },
  {
    Header: 'Contact Email',
    accessor: 'ContactEmail',
    className: 'notranslate'
  },
  {
    Header: 'Contact Number',
    accessor: 'ContactNumber',
    className: 'notranslate'
  },
  {
    Header: 'Status',
    accessor: 'Status',
    className: 'notranslate statusShow'
  },
  {
    Header: 'Last Updated Date Time',
    accessor: 'UpdatedDateTime',
    className: 'notranslate text-center'
  },
  {
    Header: '',
    accessor: 'DetailPage'
  }
];

export default ASSOCIATION_COLUMNS_2022;
