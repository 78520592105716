import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StarRatings from 'react-star-ratings';
import postFeedback from '@redux/feedback/actions';
import FeedbackDataSelector from '@redux/feedback/selectors';

const feedbackModal = props => {
  const {
    handleCloseFeedbackPopup,
    showFeedbackPopup,
    sectionName,
    selectedYear,
    handlePostFeedback,
    associationNumber,
    formId
  } = props;

  const [rating, setRating] = useState(0);

  const onStarClick = newRating => {
    setRating(newRating);
  };

  const submitFeedbcak = () => {
    const payload = {
      formId,
      rating,
      associationNumber,
      year: selectedYear
    };

    handlePostFeedback(payload).then(res => {
      handleCloseFeedbackPopup();
    });
  };

  const modal = {
    background: 'rgb(0 0 0 / 0.5)'
  };

  const showHideClassName = showFeedbackPopup
    ? 'modal d-block'
    : 'modal d-none';

  return (
    <div className={showHideClassName} style={modal}>
      <div className="modal-dialog ymc-pointerEvent modal-dialog-centered justify-content-center ymca-feedback-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Submission Experience Feedback
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setRating(0);
                handleCloseFeedbackPopup();
              }}
            />
          </div>
          <div className="modal-body text-center">
            <h1 className="feedback-message">
              How was your data submission experience for the{' '}
              <span className="fw-bold">{sectionName}</span> section?
            </h1>
            <div className="my-3">
              <StarRatings
                rating={rating}
                starRatedColor="gold"
                starEmptyColor="grey"
                starHoverColor="gold"
                starDimension="32px"
                changeRating={onStarClick}
                numberOfStars={5}
                name="rating"
              />
            </div>
            <button
              type="button"
              className="btn feedback-btn mt-2 mb-4"
              disabled={rating === 0}
              onClick={() => submitFeedbcak()}
            >
              Submit Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

feedbackModal.propTypes = {
  handleCloseFeedbackPopup: PropTypes.func.isRequired,
  handlePostFeedback: PropTypes.func.isRequired,
  showFeedbackPopup: PropTypes.bool,
  sectionName: PropTypes.string,
  selectedYear: PropTypes.string,
  associationNumber: PropTypes.string,
  formId: PropTypes.number
};

feedbackModal.defaultProps = {
  showFeedbackPopup: false,
  sectionName: '',
  selectedYear: '',
  associationNumber: '',
  formId: 0
};

const mapStateToProps = state => ({
  FeedbackDataSelectorData: FeedbackDataSelector(state)
});

const mapDispatchToProps = {
  handlePostFeedback: postFeedback
};

export default connect(mapStateToProps, mapDispatchToProps)(feedbackModal);
