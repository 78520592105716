export const GET_REVIEW_SUBMIT_INFO_SUCCESS = 'GET_REVIEW_SUBMIT_INFO_SUCCESS';
export const GET_REVIEW_SUBMIT_INFO_FAILURE = 'GET_REVIEW_SUBMIT_INFO_FAILURE';

export const POST_REVIEW_SUBMIT_DATA_SUCCESS =
  'POST_REVIEW_SUBMIT_DATA_SUCCESS';
export const POST_REVIEW_SUBMIT_DATA_FAILURE =
  'POST_REVIEW_SUBMIT_DATA_FAILURE';

export const FINAL_SUBMIT_SUCCESS = 'FINAL_SUBMIT_SUCCESS';
export const FINAL_SUBMIT_FAILURE = 'FINAL_SUBMIT_FAILURE';
