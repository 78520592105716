import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import PropTypes from 'prop-types';
import AnnualReportsDashboardDataSelector from '@redux/annual-reports-dashboard/selectors';
import { DashboardOverviewSelector } from '@redux/annualReport-overview/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import CustomModal from '@utilities/CustomModal';
import tabCheck from '@static/images/annualReports/tabCheck.svg';
import tabComponent from '@static/images/annualReports/tabComponent.svg';
import tabDesign from '@static/images/annualReports/tabDesign.svg';

const SidebarNav = props => {
  const {
    AnnualReportsDashboardData,
    DashboardOverviewSelectorData,
    setActiveTab,
    LoginUserData,
    setIsDataLoading
  } = props;

  const role = get(LoginUserData, 'user.role[0]');
  const [showPopup, setShowPopup] = useState(false);
  const [menuItems, setMenuItems] = useState(null);

  const handleSetActive = value => {
    sessionStorage.setItem('active', value);
    if (
      value !== sessionStorage.getItem('lastactivetab') &&
      sessionStorage.getItem('dirty') === 'true'
    ) {
      setShowPopup(true);
    } else {
      setActiveTab(value);
    }
  };
  const handleOk = () => {
    setActiveTab(sessionStorage.getItem('active'));
    setShowPopup(false);
  };
  const handleCancel = () => {
    const activetab = sessionStorage.getItem('lastactivetab');
    document.getElementById(activetab).click();
    setActiveTab(activetab);
    setShowPopup(false);
  };

  const navbarListIds = [
    {
      id: 'v-pills-Directory-tab',
      target: '#v-pills-Directory'
    },
    {
      id: 'v-pills-siteInformations-tab',
      target: '#v-pills-siteInformations'
    },
    {
      id: 'v-pills-updateEmployee-tab',
      target: '#v-pills-updateEmployee'
    },
    {
      id: 'v-pills-Constituency-tab',
      target: '#v-pills-Constituency'
    },
    {
      id: 'v-pills-globalMetrics-tab',
      target: '#v-pills-globalMetrics'
    },
    {
      id: 'v-pills-Collaborations-tab',
      target: '#v-pills-Collaborations'
    },
    {
      id: 'v-pills-Program-tab',
      target: '#v-pills-Program'
    },
    {
      id: 'v-pills-Pledge-tab',
      target: '#v-pills-Pledge'
    },
    {
      id: 'v-pills-ReviewSubmit-tab',
      target: '#v-pills-ReviewSubmit'
    }
  ];

  const getStatusIcons = (status, number) => {
    if (status === APP_LABELS.STATUS_IN_PROGRESS) {
      return (
        <span className="ymca-order">
          <div className="ymca-order-List">
            {`0${number}`}
            <img src={tabDesign} alt="" className="img-fluid ymca-design" />
          </div>
        </span>
      );
    }
    if (status === APP_LABELS.STATUS_COMPLETED) {
      return (
        <span className="ymca-order ymca-green">
          <div className="ymca-order-List ymca-orderLabel">
            {`0${number}`}
            <img src={tabComponent} alt="" className="img-fluid ymca-design" />
          </div>
        </span>
      );
    }
    return (
      <span className="ymca-order">
        <div className="ymca-order-List">{`0${number}`}</div>
      </span>
    );
  };

  useEffect(() => {
    const navItems = AnnualReportsDashboardData?.find(
      yearwise => yearwise.year === sessionStorage.getItem('selectedBatchYear')
    );
    setMenuItems(navItems);
  
  }, [AnnualReportsDashboardData]);

  useEffect(() => {
    if (window.location.pathname === '/annual/report') {
      setMenuItems(DashboardOverviewSelectorData?.data);
    }
    return () => { setIsDataLoading(false);};
  }, [DashboardOverviewSelectorData]);

  const isDisabled = (data, index) =>
    (navbarListIds[index]?.id === 'v-pills-Pledge-tab' &&
      role !== APP_LABELS.CVO_DATA_STEWARD &&
      role !== APP_LABELS.CEO_DATA_STEWARD &&
      role !== APP_LABELS.YUSA_DATA_STEWARD &&
      role !== APP_LABELS.YUSA_DATA_VIEWER &&
      role !== APP_LABELS.YESS_DATA_STEWARD &&
      role !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
      data?.year === '2022') ||
    ((navbarListIds[index]?.id === 'v-pills-siteInformations-tab' ||
      navbarListIds[index]?.id === 'v-pills-updateEmployee-tab' ||
      navbarListIds[index]?.id === 'v-pills-Constituency-tab' ||
      navbarListIds[index]?.id === 'v-pills-globalMetrics-tab' ||
      navbarListIds[index]?.id === 'v-pills-ReviewSubmit-tab' ||
      navbarListIds[index]?.id === 'v-pills-Pledge-tab') &&
      role === APP_LABELS.BRANCH_DATA_STEWARD);

  const isAuthorized = (data, index) =>
    (navbarListIds[index]?.id === 'v-pills-Pledge-tab' &&
      role !== APP_LABELS.CVO_DATA_STEWARD &&
      role !== APP_LABELS.CEO_DATA_STEWARD &&
      role !== APP_LABELS.YUSA_DATA_STEWARD &&
      role !== APP_LABELS.YUSA_DATA_VIEWER &&
      role !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
      role !== APP_LABELS.YESS_DATA_STEWARD &&
      data?.year === '2022') ||
    ((navbarListIds[index]?.id === 'v-pills-siteInformations-tab' ||
      navbarListIds[index]?.id === 'v-pills-updateEmployee-tab' ||
      navbarListIds[index]?.id === 'v-pills-Constituency-tab' ||
      navbarListIds[index]?.id === 'v-pills-globalMetrics-tab' ||
      navbarListIds[index]?.id === 'v-pills-ReviewSubmit-tab' ||
      navbarListIds[index]?.id === 'v-pills-Pledge-tab') &&
      role === APP_LABELS.BRANCH_DATA_STEWARD) ? (
      <span className="ymca-option-tooltip">
        You are not authorized to access this section
      </span>
    ) : (
      ''
    );

  return (
    <div
      className="nav flex-column nav-pills ymca-areaCover"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <button
        className="nav-link ymca-navLink active"
        id="v-pills-Overview-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-Overview"
        type="button"
        role="tab"
        aria-controls="v-pills-Overview"
        aria-selected="true"
        onClick={() => handleSetActive('v-pills-Overview-tab')}
      >
        <span className="ymca-order">
          <img src={tabCheck} alt="" className="img-fluid" />
        </span>
        <span className="ymca-text">Overview</span>
      </button>
      {menuItems?.dashboardDTOs &&
        menuItems.dashboardDTOs?.map((data, index) => (
          <button
            className="nav-link ymca-navLink"
            id={navbarListIds[index]?.id}
            key={navbarListIds[index]?.id}
            data-bs-toggle="pill"
            data-bs-target={navbarListIds[index]?.target}
            type="button"
            role="tab"
            aria-controls="v-pills-Directory"
            aria-selected="false"
            onClick={() => handleSetActive(navbarListIds[index]?.id)}
            disabled={isDisabled(data, index)}
          >
            {getStatusIcons(
              data?.status?.toUpperCase(),
              data?.year >= '2023' ? data?.sequenceNo : data?.formId
            )}
            <span className="ymca-text text-break ">{data?.name}</span>
            {isAuthorized(data, index)}
          </button>
        ))}

      <CustomModal
        show={showPopup}
        type="Warning!"
        message="You have some unsaved changes. If you leave before saving, your changes will be lost."
        submitButtonName="Ok"
        onSubmit={handleOk}
        cancelButtonName="Cancel"
        onCancel={handleCancel}
      />
    </div>
  );
};
SidebarNav.propTypes = {
  AnnualReportsDashboardData: PropTypes.objectOf(PropTypes.any).isRequired,
  DashboardOverviewSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  setActiveTab: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsDataLoading: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  AnnualReportsDashboardData: AnnualReportsDashboardDataSelector(state),
  DashboardOverviewSelectorData: DashboardOverviewSelector(state)
});
export default connect(mapStateToProps, null)(React.memo(SidebarNav));
