/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import {
  getVolunteers,
  postVolunteers,
  resetReduxState
} from '@redux/constituency-report/volunteers/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetVolunteersSelector } from '@redux/constituency-report/volunteers/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const VolunteersTab = props => {
  const {
    setLoader,
    handleGetVolunteers,
    handleResetRedux,
    handlePostVolunteers,
    GetVolunteersSelectorData,
    LoginUserData,
    handleGetAnnualReportsDashboard,
    status
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Constituency Report';

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [policyTotalMessage, setPolicyTotalMessage] = useState(false);
  const [policyRaceEthnicTotalMessage, setPolicyRaceEthnicTotalMessage] =
    useState(false);
  const [programTotalMessage, setProgramTotalMessage] = useState(false);
  const [programRaceEthnicTotalMessage, setProgramRaceEthnicTotalMessage] =
    useState(false);
  const [resStatus, setResStatus] = useState();
  const formikref = useRef();
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = success => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetVolunteers(associationsNum).then(res => {
      if (res.status === 200) {
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.volunteers
        ?.Instruction
    );
    return () => {
      handleResetRedux('GetVolunteers');
    };
  }, []);

  const initialValues = {
    totPolicyVolunteers: '',
    policyVolunteersHrs: '',

    policyMale0To29: '',
    policyFemale0To29: '',
    policyAdditionalIdentities0To29: '',
    policyUnknown0To29: '',

    policyMale30To54: '',
    policyFemale30To54: '',
    policyAdditionalIdentities30To54: '',
    policyUnknown30To54: '',

    policyMale55To64: '',
    policyFemale55To64: '',
    policyAdditionalIdentities55To64: '',
    policyUnknown55To64: '',

    policyMale65Plus: '',
    policyFemale65Plus: '',
    policyAdditionalIdentities65Plus: '',
    policyUnknown65Plus: '',

    policyMaleAgeUnknown: '',
    policyFemaleAgeUnknown: '',
    policyAdditionalIdentitiesAgeUnknown: '',
    policyUnknownAgeUnknown: '',

    policyAsian: '',
    policyAsianNotCollected: '',

    policyBlackOrAfricanAmerican: '',
    policyBlackOrAfricanAmericanNotCollected: false,

    policyHispanicLatino: '',
    policyHispanicLatinoNotCollected: false,

    policyMidEastNorthAfrican: '',
    policyMidEastNorthAfricanNotCollected: false,

    policyNativeAmericanAlaskanNative: '',
    policyNativeAmericanAlaskanNativeNotCollected: false,

    policyNatvHawaiOtherPacificIslander: '',
    policyNatvHawaiOtherPacificIslanderNotCollected: false,

    policyWhite: '',
    policyWhiteNotCollected: false,

    policyTwoOrMoreRaces: '',
    policyTwoOrMoreRacesNotCollected: false,

    policyUnknown: '',
    policyUnknownNotCollected: false,

    policyOther: '',
    policyOtherNotCollected: false,

    totProgVolunteers: '',
    programVolunteersHrs: '',

    programMale0To29: '',
    programFemale0To29: '',
    programAdditionalIdentities0To29: '',
    programUnknown0To29: '',

    programMale30To54: '',
    programFemale30To54: '',
    programAdditionalIdentities30To54: '',
    programUnknown30To54: '',

    programMale55To64: '',
    programFemale55To64: '',
    programAdditionalIdentities55To64: '',
    programUnknown55To64: '',

    programMale65Plus: '',
    programFemale65Plus: '',
    programAdditionalIdentities65Plus: '',
    programUnknown65Plus: '',

    programFemaleAgeUnknown: '',
    programMaleAgeUnknown: '',
    programAdditionalIdentitiesAgeUnknown: '',
    programUnknownAgeUnknown: '',

    programNativeAmericanAlaskanNative: '',
    programNativeAmericanAlaskanNativeNotCollected: false,

    programAsian: '',
    programAsianNotCollected: false,

    programBlackOrAfricanAmerican: '',
    programBlackOrAfricanAmericanNotCollected: false,

    programHispanicLatino: '',
    programHispanicLatinoNotCollected: false,

    programMidEastNorthAfrican: '',
    programMidEastNorthAfricanNotCollected: false,

    programNatvHawaiOtherPacificIslander: '',
    programNatvHawaiOtherPacificIslanderNotCollected: false,

    programWhite: '',
    programWhiteNotCollected: false,

    programTwoOrMoreRaces: '',
    programTwoOrMoreRacesNotCollected: false,

    programOther: '',
    programOtherNotCollected: false,

    programUnknown: '',
    programUnknownNotCollected: false
  };

  const editValues = {
    totPolicyVolunteers: GetVolunteersSelectorData?.data?.totPolicyVolunteers,
    policyVolunteersHrs: GetVolunteersSelectorData?.data?.policyVolunteersHrs,

    policyMale0To29: GetVolunteersSelectorData?.data?.policyMale0To29,
    policyFemale0To29: GetVolunteersSelectorData?.data?.policyFemale0To29,
    policyAdditionalIdentities0To29:
      GetVolunteersSelectorData?.data?.policyAdditionalIdentities0To29,
    policyUnknown0To29: GetVolunteersSelectorData?.data?.policyUnknown0To29,

    policyMale30To54: GetVolunteersSelectorData?.data?.policyMale30To54,
    policyFemale30To54: GetVolunteersSelectorData?.data?.policyFemale30To54,
    policyAdditionalIdentities30To54:
      GetVolunteersSelectorData?.data?.policyAdditionalIdentities30To54,
    policyUnknown30To54: GetVolunteersSelectorData?.data?.policyUnknown30To54,

    policyMale55To64: GetVolunteersSelectorData?.data?.policyMale55To64,
    policyFemale55To64: GetVolunteersSelectorData?.data?.policyFemale55To64,
    policyAdditionalIdentities55To64:
      GetVolunteersSelectorData?.data?.policyAdditionalIdentities55To64,
    policyUnknown55To64: GetVolunteersSelectorData?.data?.policyUnknown55To64,

    policyMale65Plus: GetVolunteersSelectorData?.data?.policyMale65Plus,
    policyFemale65Plus: GetVolunteersSelectorData?.data?.policyFemale65Plus,
    policyAdditionalIdentities65Plus:
      GetVolunteersSelectorData?.data?.policyAdditionalIdentities65Plus,
    policyUnknown65Plus: GetVolunteersSelectorData?.data?.policyUnknown65Plus,

    policyMaleAgeUnknown: GetVolunteersSelectorData?.data?.policyMaleAgeUnknown,
    policyFemaleAgeUnknown:
      GetVolunteersSelectorData?.data?.policyFemaleAgeUnknown,
    policyAdditionalIdentitiesAgeUnknown:
      GetVolunteersSelectorData?.data?.policyAdditionalIdentitiesAgeUnknown,
    policyUnknownAgeUnknown:
      GetVolunteersSelectorData?.data?.policyUnknownAgeUnknown,

    policyAsian: GetVolunteersSelectorData?.data?.policyAsian,
    policyAsianNotCollected:
      GetVolunteersSelectorData?.data?.policyAsianNotCollected,

    policyBlackOrAfricanAmerican:
      GetVolunteersSelectorData?.data?.policyBlackOrAfricanAmerican,
    policyBlackOrAfricanAmericanNotCollected:
      GetVolunteersSelectorData?.data?.policyBlackOrAfricanAmericanNotCollected,

    policyHispanicLatino: GetVolunteersSelectorData?.data?.policyHispanicLatino,
    policyHispanicLatinoNotCollected:
      GetVolunteersSelectorData?.data?.policyHispanicLatinoNotCollected,

    policyMidEastNorthAfrican:
      GetVolunteersSelectorData?.data?.policyMidEastNorthAfrican,
    policyMidEastNorthAfricanNotCollected:
      GetVolunteersSelectorData?.data?.policyMidEastNorthAfricanNotCollected,

    policyNativeAmericanAlaskanNative:
      GetVolunteersSelectorData?.data?.policyNativeAmericanAlaskanNative,
    policyNativeAmericanAlaskanNativeNotCollected:
      GetVolunteersSelectorData?.data
        ?.policyNativeAmericanAlaskanNativeNotCollected,

    policyNatvHawaiOtherPacificIslander:
      GetVolunteersSelectorData?.data?.policyNatvHawaiOtherPacificIslander,
    policyNatvHawaiOtherPacificIslanderNotCollected:
      GetVolunteersSelectorData?.data
        ?.policyNatvHawaiOtherPacificIslanderNotCollected,

    policyWhite: GetVolunteersSelectorData?.data?.policyWhite,
    policyWhiteNotCollected:
      GetVolunteersSelectorData?.data?.policyWhiteNotCollected,

    policyTwoOrMoreRaces: GetVolunteersSelectorData?.data?.policyTwoOrMoreRaces,
    policyTwoOrMoreRacesNotCollected:
      GetVolunteersSelectorData?.data?.policyTwoOrMoreRacesNotCollected,

    policyUnknown: GetVolunteersSelectorData?.data?.policyUnknown,
    policyUnknownNotCollected:
      GetVolunteersSelectorData?.data?.policyUnknownNotCollected,

    policyOther: GetVolunteersSelectorData?.data?.policyOther,
    policyOtherNotCollected:
      GetVolunteersSelectorData?.data?.policyOtherNotCollected,

    totProgVolunteers: GetVolunteersSelectorData?.data?.totProgVolunteers,
    programVolunteersHrs: GetVolunteersSelectorData?.data?.programVolunteersHrs,

    programMale0To29: GetVolunteersSelectorData?.data?.programMale0To29,
    programFemale0To29: GetVolunteersSelectorData?.data?.programFemale0To29,
    programAdditionalIdentities0To29:
      GetVolunteersSelectorData?.data?.programAdditionalIdentities0To29,
    programUnknown0To29: GetVolunteersSelectorData?.data?.programUnknown0To29,

    programMale30To54: GetVolunteersSelectorData?.data?.programMale30To54,
    programFemale30To54: GetVolunteersSelectorData?.data?.programFemale30To54,
    programAdditionalIdentities30To54:
      GetVolunteersSelectorData?.data?.programAdditionalIdentities30To54,
    programUnknown30To54: GetVolunteersSelectorData?.data?.programUnknown30To54,

    programMale55To64: GetVolunteersSelectorData?.data?.programMale55To64,
    programFemale55To64: GetVolunteersSelectorData?.data?.programFemale55To64,
    programAdditionalIdentities55To64:
      GetVolunteersSelectorData?.data?.programAdditionalIdentities55To64,
    programUnknown55To64: GetVolunteersSelectorData?.data?.programUnknown55To64,

    programMale65Plus: GetVolunteersSelectorData?.data?.programMale65Plus,
    programFemale65Plus: GetVolunteersSelectorData?.data?.programFemale65Plus,
    programAdditionalIdentities65Plus:
      GetVolunteersSelectorData?.data?.programAdditionalIdentities65Plus,
    programUnknown65Plus: GetVolunteersSelectorData?.data?.programUnknown65Plus,

    programFemaleAgeUnknown:
      GetVolunteersSelectorData?.data?.programFemaleAgeUnknown,
    programMaleAgeUnknown:
      GetVolunteersSelectorData?.data?.programMaleAgeUnknown,
    programAdditionalIdentitiesAgeUnknown:
      GetVolunteersSelectorData?.data?.programAdditionalIdentitiesAgeUnknown,
    programUnknownAgeUnknown:
      GetVolunteersSelectorData?.data?.programUnknownAgeUnknown,

    programAsian: GetVolunteersSelectorData?.data?.programAsian,
    programAsianNotCollected:
      GetVolunteersSelectorData?.data?.programAsianNotCollected,

    programBlackOrAfricanAmerican:
      GetVolunteersSelectorData?.data?.programBlackOrAfricanAmerican,
    programBlackOrAfricanAmericanNotCollected:
      GetVolunteersSelectorData?.data
        ?.programBlackOrAfricanAmericanNotCollected,

    programHispanicLatino:
      GetVolunteersSelectorData?.data?.programHispanicLatino,
    programHispanicLatinoNotCollected:
      GetVolunteersSelectorData?.data?.programHispanicLatinoNotCollected,

    programMidEastNorthAfrican:
      GetVolunteersSelectorData?.data?.programMidEastNorthAfrican,
    programMidEastNorthAfricanNotCollected:
      GetVolunteersSelectorData?.data?.programMidEastNorthAfricanNotCollected,

    programNatvHawaiOtherPacificIslander:
      GetVolunteersSelectorData?.data?.programNatvHawaiOtherPacificIslander,
    programNatvHawaiOtherPacificIslanderNotCollected:
      GetVolunteersSelectorData?.data
        ?.programNatvHawaiOtherPacificIslanderNotCollected,

    programWhite: GetVolunteersSelectorData?.data?.programWhite,
    programWhiteNotCollected:
      GetVolunteersSelectorData?.data?.programWhiteNotCollected,

    programTwoOrMoreRaces:
      GetVolunteersSelectorData?.data?.programTwoOrMoreRaces,
    programTwoOrMoreRacesNotCollected:
      GetVolunteersSelectorData?.data?.programTwoOrMoreRacesNotCollected,

    programOther: GetVolunteersSelectorData?.data?.programOther,
    programOtherNotCollected:
      GetVolunteersSelectorData?.data?.programOtherNotCollected,

    programUnknown: GetVolunteersSelectorData?.data?.programUnknown,
    programUnknownNotCollected:
      GetVolunteersSelectorData?.data?.programUnknownNotCollected,

    programNativeAmericanAlaskanNative:
      GetVolunteersSelectorData?.data?.programNativeAmericanAlaskanNative,
    programNativeAmericanAlaskanNativeNotCollected:
      GetVolunteersSelectorData?.data
        ?.programNativeAmericanAlaskanNativeNotCollected
  };

  const validationSchema = Yup.object({
    totPolicyVolunteers: Yup.string().nullable(),
    policyVolunteersHrs: Yup.string().nullable(),

    policyMale0To29: Yup.string().nullable(),
    policyFemale0To29: Yup.string().nullable(),
    policyAdditionalIdentities0To29: Yup.string().nullable(),
    policyUnknown0To29: Yup.string().nullable(),

    policyMale30To54: Yup.string().nullable(),
    policyFemale30To54: Yup.string().nullable(),
    policyAdditionalIdentities30To54: Yup.string().nullable(),
    policyUnknown30To54: Yup.string().nullable(),

    policyMale55To64: Yup.string().nullable(),
    policyFemale55To64: Yup.string().nullable(),
    policyAdditionalIdentities55To64: Yup.string().nullable(),
    policyUnknown55To64: Yup.string().nullable(),

    policyMale65Plus: Yup.string().nullable(),
    policyFemale65Plus: Yup.string().nullable(),
    policyAdditionalIdentities65Plus: Yup.string().nullable(),
    policyUnknown65Plus: Yup.string().nullable(),

    policyMaleAgeUnknown: Yup.string().nullable(),
    policyFemaleAgeUnknown: Yup.string().nullable(),
    policyAdditionalIdentitiesAgeUnknown: Yup.string().nullable(),
    policyUnknownAgeUnknown: Yup.string().nullable(),

    policyAsian: Yup.string().nullable(),

    policyBlackOrAfricanAmerican: Yup.string().nullable(),

    policyHispanicLatino: Yup.string().nullable(),

    policyMidEastNorthAfrican: Yup.string().nullable(),

    policyNativeAmericanAlaskanNative: Yup.string().nullable(),

    policyNatvHawaiOtherPacificIslander: Yup.string().nullable(),

    policyWhite: Yup.string().nullable(),

    policyTwoOrMoreRaces: Yup.string().nullable(),

    policyUnknown: Yup.string().nullable(),

    policyOther: Yup.string().nullable(),

    totProgVolunteers: Yup.string().nullable(),
    programVolunteersHrs: Yup.string().nullable(),

    programMale0To29: Yup.string().nullable(),
    programFemale0To29: Yup.string().nullable(),
    programAdditionalIdentities0To29: Yup.string().nullable(),
    programUnknown0To29: Yup.string().nullable(),

    programMale30To54: Yup.string().nullable(),
    programFemale30To54: Yup.string().nullable(),
    programAdditionalIdentities30To54: Yup.string().nullable(),
    programUnknown30To54: Yup.string().nullable(),

    programMale55To64: Yup.string().nullable(),
    programFemale55To64: Yup.string().nullable(),
    programAdditionalIdentities55To64: Yup.string().nullable(),
    programUnknown55To64: Yup.string().nullable(),

    programMale65Plus: Yup.string().nullable(),
    programFemale65Plus: Yup.string().nullable(),
    programAdditionalIdentities65Plus: Yup.string().nullable(),
    programUnknown65Plus: Yup.string().nullable(),

    programFemaleAgeUnknown: Yup.string().nullable(),
    programMaleAgeUnknown: Yup.string().nullable(),
    programAdditionalIdentitiesAgeUnknown: Yup.string().nullable(),
    programUnknownAgeUnknown: Yup.string().nullable(),

    programNativeAmericanAlaskanNative: Yup.string().nullable(),

    programAsian: Yup.string().nullable(),

    programBlackOrAfricanAmerican: Yup.string().nullable(),

    programHispanicLatino: Yup.string().nullable(),

    programMidEastNorthAfrican: Yup.string().nullable(),

    programNatvHawaiOtherPacificIslander: Yup.string().nullable(),

    programWhite: Yup.string().nullable(),

    programTwoOrMoreRaces: Yup.string().nullable(),

    programOther: Yup.string().nullable(),

    programUnknown: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? value : null);

  const onSubmit = values => {
    setLoader(true);
    const payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName: GetVolunteersSelectorData?.data?.createdByName,
      createdByPersonId: GetVolunteersSelectorData?.data?.createdByPersonId,
      createdDatetime: GetVolunteersSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      volunteerId: GetVolunteersSelectorData?.data?.volunteerId,
      reportBatchId: GetVolunteersSelectorData?.data?.reportBatchId,
      recordStatus: GetVolunteersSelectorData?.data?.recordStatus,
      totPolicyVolunteers: convertedValue(values.totPolicyVolunteers),
      policyVolunteersHrs: convertedValue(values.policyVolunteersHrs),
      policyMale0To29: convertedValue(values.policyMale0To29),
      policyFemale0To29: convertedValue(values.policyFemale0To29),
      policyAdditionalIdentities0To29: convertedValue(
        values.policyAdditionalIdentities0To29
      ),
      policyUnknown0To29: convertedValue(values.policyUnknown0To29),
      policyMale30To54: convertedValue(values.policyMale30To54),
      policyFemale30To54: convertedValue(values.policyFemale30To54),
      policyAdditionalIdentities30To54: convertedValue(
        values.policyAdditionalIdentities30To54
      ),
      policyUnknown30To54: convertedValue(values.policyUnknown30To54),
      policyMale55To64: convertedValue(values.policyMale55To64),
      policyFemale55To64: convertedValue(values.policyFemale55To64),
      policyAdditionalIdentities55To64: convertedValue(
        values.policyAdditionalIdentities55To64
      ),
      policyUnknown55To64: convertedValue(values.policyUnknown55To64),
      policyMale65Plus: convertedValue(values.policyMale65Plus),
      policyFemale65Plus: convertedValue(values.policyFemale65Plus),
      policyAdditionalIdentities65Plus: convertedValue(
        values.policyAdditionalIdentities65Plus
      ),
      policyUnknown65Plus: convertedValue(values.policyUnknown65Plus),
      policyMaleAgeUnknown: convertedValue(values.policyMaleAgeUnknown),
      policyFemaleAgeUnknown: convertedValue(values.policyFemaleAgeUnknown),
      policyAdditionalIdentitiesAgeUnknown: convertedValue(
        values.policyAdditionalIdentitiesAgeUnknown
      ),
      policyUnknownAgeUnknown: convertedValue(values.policyUnknownAgeUnknown),
      policyAsian: values.policyAsianNotCollected
        ? null
        : convertedValue(values.policyAsian),
      policyAsianNotCollected: values.policyAsianNotCollected,

      policyBlackOrAfricanAmerican:
        values.policyBlackOrAfricanAmericanNotCollected
          ? null
          : convertedValue(values.policyBlackOrAfricanAmerican),
      policyBlackOrAfricanAmericanNotCollected:
        values.policyBlackOrAfricanAmericanNotCollected,

      policyHispanicLatino: values.policyHispanicLatinoNotCollected
        ? null
        : convertedValue(values.policyHispanicLatino),
      policyHispanicLatinoNotCollected: values.policyHispanicLatinoNotCollected,

      policyMidEastNorthAfrican: values.policyMidEastNorthAfricanNotCollected
        ? null
        : convertedValue(values.policyMidEastNorthAfrican),
      policyMidEastNorthAfricanNotCollected:
        values.policyMidEastNorthAfricanNotCollected,

      policyNativeAmericanAlaskanNative:
        values.policyNativeAmericanAlaskanNativeNotCollected
          ? null
          : convertedValue(values.policyNativeAmericanAlaskanNative),
      policyNativeAmericanAlaskanNativeNotCollected:
        values.policyNativeAmericanAlaskanNativeNotCollected,

      policyNatvHawaiOtherPacificIslander:
        values.policyNatvHawaiOtherPacificIslanderNotCollected
          ? null
          : convertedValue(values.policyNatvHawaiOtherPacificIslander),
      policyNatvHawaiOtherPacificIslanderNotCollected:
        values.policyNatvHawaiOtherPacificIslanderNotCollected,

      policyWhite: values.policyWhiteNotCollected
        ? null
        : convertedValue(values.policyWhite),
      policyWhiteNotCollected: values.policyWhiteNotCollected,

      policyTwoOrMoreRaces: values.policyTwoOrMoreRacesNotCollected
        ? null
        : convertedValue(values.policyTwoOrMoreRaces),
      policyTwoOrMoreRacesNotCollected: values.policyTwoOrMoreRacesNotCollected,

      policyUnknown: values.policyUnknownNotCollected
        ? null
        : convertedValue(values.policyUnknown),
      policyUnknownNotCollected: values.policyUnknownNotCollected,

      policyOther: values.policyOtherNotCollected
        ? null
        : convertedValue(values.policyOther),
      policyOtherNotCollected: values.policyOtherNotCollected,

      totProgVolunteers: convertedValue(values.totProgVolunteers),
      programVolunteersHrs: convertedValue(values.programVolunteersHrs),

      programMale0To29: convertedValue(values.programMale0To29),
      programFemale0To29: convertedValue(values.programFemale0To29),
      programAdditionalIdentities0To29: convertedValue(
        values.programAdditionalIdentities0To29
      ),
      programUnknown0To29: convertedValue(values.programUnknown0To29),

      programMale30To54: convertedValue(values.programMale30To54),
      programFemale30To54: convertedValue(values.programFemale30To54),
      programAdditionalIdentities30To54: convertedValue(
        values.programAdditionalIdentities30To54
      ),
      programUnknown30To54: convertedValue(values.programUnknown30To54),

      programMale55To64: convertedValue(values.programMale55To64),
      programFemale55To64: convertedValue(values.programFemale55To64),
      programAdditionalIdentities55To64: convertedValue(
        values.programAdditionalIdentities55To64
      ),
      programUnknown55To64: convertedValue(values.programUnknown55To64),

      programMale65Plus: convertedValue(values.programMale65Plus),
      programFemale65Plus: convertedValue(values.programFemale65Plus),
      programAdditionalIdentities65Plus: convertedValue(
        values.programAdditionalIdentities65Plus
      ),
      programUnknown65Plus: convertedValue(values.programUnknown65Plus),

      programFemaleAgeUnknown: convertedValue(values.programFemaleAgeUnknown),
      programMaleAgeUnknown: convertedValue(values.programMaleAgeUnknown),
      programAdditionalIdentitiesAgeUnknown: convertedValue(
        values.programAdditionalIdentitiesAgeUnknown
      ),
      programUnknownAgeUnknown: convertedValue(values.programUnknownAgeUnknown),

      programAsian: values.programAsianNotCollected
        ? null
        : convertedValue(values.programAsian),
      programAsianNotCollected: values.programAsianNotCollected,

      programBlackOrAfricanAmerican:
        values.programBlackOrAfricanAmericanNotCollected
          ? null
          : convertedValue(values.programBlackOrAfricanAmerican),
      programBlackOrAfricanAmericanNotCollected:
        values.programBlackOrAfricanAmericanNotCollected,

      programHispanicLatino: values.programHispanicLatinoNotCollected
        ? null
        : convertedValue(values.programHispanicLatino),
      programHispanicLatinoNotCollected:
        values.programHispanicLatinoNotCollected,

      programMidEastNorthAfrican: values.programMidEastNorthAfricanNotCollected
        ? null
        : convertedValue(values.programMidEastNorthAfrican),
      programMidEastNorthAfricanNotCollected:
        values.programMidEastNorthAfricanNotCollected,

      programNatvHawaiOtherPacificIslander:
        values.programNatvHawaiOtherPacificIslanderNotCollected
          ? null
          : convertedValue(values.programNatvHawaiOtherPacificIslander),
      programNatvHawaiOtherPacificIslanderNotCollected:
        values.programNatvHawaiOtherPacificIslanderNotCollected,

      programWhite: values.programWhiteNotCollected
        ? null
        : convertedValue(values.programWhite),
      programWhiteNotCollected: values.programWhiteNotCollected,

      programTwoOrMoreRaces: values.programTwoOrMoreRacesNotCollected
        ? null
        : convertedValue(values.programTwoOrMoreRaces),
      programTwoOrMoreRacesNotCollected:
        values.programTwoOrMoreRacesNotCollected,

      programOther: values.programOtherNotCollected
        ? null
        : convertedValue(values.programOther),
      programOtherNotCollected: values.programOtherNotCollected,

      programUnknown: values.programUnknownNotCollected
        ? null
        : convertedValue(values.programUnknown),
      programUnknownNotCollected: values.programUnknownNotCollected,

      programNativeAmericanAlaskanNative:
        values.programNativeAmericanAlaskanNativeNotCollected
          ? null
          : convertedValue(values.programNativeAmericanAlaskanNative),
      programNativeAmericanAlaskanNativeNotCollected:
        values.programNativeAmericanAlaskanNativeNotCollected
    };

    const policyTotal =
      Number(values.policyMale0To29) +
      Number(values.policyFemale0To29) +
      Number(values.policyAdditionalIdentities0To29) +
      Number(values.policyUnknown0To29) +
      Number(values.policyMale30To54) +
      Number(values.policyFemale30To54) +
      Number(values.policyAdditionalIdentities30To54) +
      Number(values.policyUnknown30To54) +
      Number(values.policyMale55To64) +
      Number(values.policyFemale55To64) +
      Number(values.policyAdditionalIdentities55To64) +
      Number(values.policyUnknown55To64) +
      Number(values.policyMale65Plus) +
      Number(values.policyFemale65Plus) +
      Number(values.policyAdditionalIdentities65Plus) +
      Number(values.policyUnknown65Plus) +
      Number(values.policyMaleAgeUnknown) +
      Number(values.policyFemaleAgeUnknown) +
      Number(values.policyAdditionalIdentitiesAgeUnknown) +
      Number(values.policyUnknownAgeUnknown);

    const policyRaceEthnicTotal =
      Number(values.policyAsian) +
      Number(values.policyBlackOrAfricanAmerican) +
      Number(values.policyHispanicLatino) +
      Number(values.policyMidEastNorthAfrican) +
      Number(values.policyNativeAmericanAlaskanNative) +
      Number(values.policyNatvHawaiOtherPacificIslander) +
      Number(values.policyWhite) +
      Number(values.policyTwoOrMoreRaces) +
      Number(values.policyUnknown) +
      Number(values.policyOther);

    const programTotal =
      Number(values.programMale0To29) +
      Number(values.programFemale0To29) +
      Number(values.programAdditionalIdentities0To29) +
      Number(values.programUnknown0To29) +
      Number(values.programMale30To54) +
      Number(values.programFemale30To54) +
      Number(values.programAdditionalIdentities30To54) +
      Number(values.programUnknown30To54) +
      Number(values.programMale55To64) +
      Number(values.programFemale55To64) +
      Number(values.programAdditionalIdentities55To64) +
      Number(values.programUnknown55To64) +
      Number(values.programMale65Plus) +
      Number(values.programFemale65Plus) +
      Number(values.programAdditionalIdentities65Plus) +
      Number(values.programUnknown65Plus) +
      Number(values.programFemaleAgeUnknown) +
      Number(values.programMaleAgeUnknown) +
      Number(values.programAdditionalIdentitiesAgeUnknown) +
      Number(values.programUnknownAgeUnknown);

    const programRaceEthnicTotal =
      Number(values.programAsian) +
      Number(values.programBlackOrAfricanAmerican) +
      Number(values.programHispanicLatino) +
      Number(values.programMidEastNorthAfrican) +
      Number(values.programNatvHawaiOtherPacificIslander) +
      Number(values.programWhite) +
      Number(values.programTwoOrMoreRaces) +
      Number(values.programOther) +
      Number(values.programUnknown) +
      Number(values.programNativeAmericanAlaskanNative);

    if (policyTotal !== Number(values.totPolicyVolunteers)) {
      setPolicyTotalMessage(true);
    } else {
      setPolicyTotalMessage(false);
    }

    if (
      policyRaceEthnicTotal !== Number(values.totPolicyVolunteers) &&
      !values.policyAsianNotCollected &&
      !values.policyBlackOrAfricanAmericanNotCollected &&
      !values.policyHispanicLatinoNotCollected &&
      !values.policyMidEastNorthAfricanNotCollected &&
      !values.policyNativeAmericanAlaskanNativeNotCollected &&
      !values.policyNatvHawaiOtherPacificIslanderNotCollected &&
      !values.policyWhiteNotCollected &&
      !values.policyTwoOrMoreRacesNotCollected &&
      !values.policyUnknownNotCollected &&
      !values.policyOtherNotCollected
    ) {
      setPolicyRaceEthnicTotalMessage(true);
    } else {
      setPolicyRaceEthnicTotalMessage(false);
    }
    if (programTotal !== Number(values.totProgVolunteers)) {
      setProgramTotalMessage(true);
    } else {
      setProgramTotalMessage(false);
    }
    if (
      programRaceEthnicTotal !== Number(values.totProgVolunteers) &&
      !values.programAsianNotCollected &&
      !values.programNativeAmericanAlaskanNativeNotCollected &&
      !values.programBlackOrAfricanAmericanNotCollected &&
      !values.programHispanicLatinoNotCollected &&
      !values.programMidEastNorthAfricanNotCollected &&
      !values.programNatvHawaiOtherPacificIslanderNotCollected &&
      !values.programWhiteNotCollected &&
      !values.programTwoOrMoreRacesNotCollected &&
      !values.programOtherNotCollected &&
      !values.programUnknownNotCollected
    ) {
      setProgramRaceEthnicTotalMessage(true);
    } else {
      setProgramRaceEthnicTotalMessage(false);
    }

    setSuccessPopup(false);
    handlePostVolunteers(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res?.data?.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall(true);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const totalCount = (
    value1,
    value2,
    value3,
    value4,
    value5,
    value6,
    value7,
    value8,
    value9,
    value10
  ) =>
    value1 +
    value2 +
    value3 +
    value4 +
    value5 +
    value6 +
    value7 +
    value8 +
    value9 +
    value10;

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  const getClassNames = (errorsValue, touchedValue, checkboxValue) => {
    let className = 'ymca-field';

    if (errorsValue && touchedValue) {
      className = 'ymca-field invalid';
    } else if (checkboxValue) {
      className = 'ymca-field ymca-fieldDisabled';
    }
    return className;
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>

      <div className="ymca-covidRelif px-0 py-0">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3 pt-4">
          <div className="ymca-instructionBoxes ">
            <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
            <p className="ymca-instructTitle">
              <div
                dangerouslySetInnerHTML={{
                  __html: instructions
                }}
              />
            </p>
          </div>
        </div>
        {GetVolunteersSelectorData?.data?.recordStatus?.toUpperCase() ===
          APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
        <Formik
          innerRef={formikref}
          initialValues={editValues || initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue, errors, touched, dirty }) => {
            sessionStorage.setItem('dirty', dirty);
            return (
              <Form autoComplete="off">
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table ymca-lastTable">
                    <tbody className="ymca-cuttingBody ymca-nostripeBody">
                      <h4 className="ymca-headingTitle">Policy Volunteers</h4>
                      <tr>
                        <td className="ymca-fieldLine txt-mandatory">
                          <span>Total Number of Policy Volunteers:</span>
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="totPolicyVolunteers"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.totPolicyVolunteers &&
                              touched.totPolicyVolunteers
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'totPolicyVolunteers'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-defaultSpaces">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="ymca-fieldLine txt-mandatory">
                          <span>
                            Total (annual) Number of Policy Volunteer Hours:
                          </span>
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyVolunteersHrs"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyVolunteersHrs &&
                              touched.policyVolunteersHrs
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyVolunteersHrs'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-defaultSpaces">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped ymca-autoLength">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th className="ymca-th-nowrap">Ages Under 30</th>
                        <th>Ages 30-54</th>
                        <th>Ages 55-64</th>
                        <th>Ages 65+</th>
                        <th>Unknown</th>
                        <th>Total</th>
                        <th className="ymca-defaultSpace">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody ymca-lengths">
                      <tr>
                        <td className="txt-mandatory">Female</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyFemale0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyFemale0To29 &&
                              touched.policyFemale0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyFemale0To29'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyFemale30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyFemale30To54 &&
                              touched.policyFemale30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyFemale30To54'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyFemale55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyFemale55To64 &&
                              touched.policyFemale55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyFemale55To64'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyFemale65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyFemale65Plus &&
                              touched.policyFemale65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyFemale65Plus'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyFemaleAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyFemaleAgeUnknown &&
                              touched.policyFemaleAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyFemaleAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyFemale0To29) +
                              Number(values.policyFemale30To54) +
                              Number(values.policyFemale55To64) +
                              Number(values.policyFemale65Plus) +
                              Number(values.policyFemaleAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Male</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyMale0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyMale0To29 && touched.policyMale0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyMale0To29')
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyMale30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyMale30To54 &&
                              touched.policyMale30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyMale30To54')
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyMale55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyMale55To64 &&
                              touched.policyMale55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyMale55To64')
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyMale65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyMale65Plus &&
                              touched.policyMale65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyMale65Plus')
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyMaleAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyMaleAgeUnknown &&
                              touched.policyMaleAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyMaleAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyMale0To29) +
                              Number(values.policyMale30To54) +
                              Number(values.policyMale55To64) +
                              Number(values.policyMale65Plus) +
                              Number(values.policyMaleAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Additional Identities</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyAdditionalIdentities0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyAdditionalIdentities0To29 &&
                              touched.policyAdditionalIdentities0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyAdditionalIdentities0To29'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyAdditionalIdentities30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyAdditionalIdentities30To54 &&
                              touched.policyAdditionalIdentities30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyAdditionalIdentities30To54'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyAdditionalIdentities55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyAdditionalIdentities55To64 &&
                              touched.policyAdditionalIdentities55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyAdditionalIdentities55To64'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyAdditionalIdentities65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyAdditionalIdentities65Plus &&
                              touched.policyAdditionalIdentities65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyAdditionalIdentities65Plus'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyAdditionalIdentitiesAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyAdditionalIdentitiesAgeUnknown &&
                              touched.policyAdditionalIdentitiesAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyAdditionalIdentitiesAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyAdditionalIdentities0To29) +
                              Number(values.policyAdditionalIdentities30To54) +
                              Number(values.policyAdditionalIdentities55To64) +
                              Number(values.policyAdditionalIdentities65Plus) +
                              Number(
                                values.policyAdditionalIdentitiesAgeUnknown
                              )
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Unknown</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyUnknown0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyUnknown0To29 &&
                              touched.policyUnknown0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyUnknown0To29'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyUnknown30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyUnknown30To54 &&
                              touched.policyUnknown30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyUnknown30To54'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyUnknown55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyUnknown55To64 &&
                              touched.policyUnknown55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyUnknown55To64'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyUnknown65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyUnknown65Plus &&
                              touched.policyUnknown65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyUnknown65Plus'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyUnknownAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.policyUnknownAgeUnknown &&
                              touched.policyUnknownAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyUnknownAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyUnknown0To29) +
                              Number(values.policyUnknown30To54) +
                              Number(values.policyUnknown55To64) +
                              Number(values.policyUnknown65Plus) +
                              Number(values.policyUnknownAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyFemale0To29) +
                              Number(values.policyMale0To29) +
                              Number(values.policyAdditionalIdentities0To29) +
                              Number(values.policyUnknown0To29)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyFemale30To54) +
                              Number(values.policyMale30To54) +
                              Number(values.policyAdditionalIdentities30To54) +
                              Number(values.policyUnknown30To54)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyFemale55To64) +
                              Number(values.policyMale55To64) +
                              Number(values.policyAdditionalIdentities55To64) +
                              Number(values.policyUnknown55To64)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyFemale65Plus) +
                              Number(values.policyMale65Plus) +
                              Number(values.policyAdditionalIdentities65Plus) +
                              Number(values.policyUnknown65Plus)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.policyFemaleAgeUnknown) +
                              Number(values.policyMaleAgeUnknown) +
                              Number(
                                values.policyAdditionalIdentitiesAgeUnknown
                              ) +
                              Number(values.policyUnknownAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            disabled
                            value={
                              Number(values.policyFemale0To29) +
                              Number(values.policyMale0To29) +
                              Number(values.policyAdditionalIdentities0To29) +
                              Number(values.policyUnknown0To29) +
                              Number(values.policyFemale30To54) +
                              Number(values.policyMale30To54) +
                              Number(values.policyAdditionalIdentities30To54) +
                              Number(values.policyUnknown30To54) +
                              Number(values.policyFemale55To64) +
                              Number(values.policyMale55To64) +
                              Number(values.policyAdditionalIdentities55To64) +
                              Number(values.policyUnknown55To64) +
                              Number(values.policyFemale65Plus) +
                              Number(values.policyMale65Plus) +
                              Number(values.policyAdditionalIdentities65Plus) +
                              Number(values.policyUnknown65Plus) +
                              Number(values.policyFemaleAgeUnknown) +
                              Number(values.policyMaleAgeUnknown) +
                              Number(
                                values.policyAdditionalIdentitiesAgeUnknown
                              ) +
                              Number(values.policyUnknownAgeUnknown)
                            }
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <hr />

                <h4 className="ymca-headingTitle">
                  Please click in the checkbox if this race/ethnic category is
                  not collected.
                </h4>

                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped ymca-autoLength">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th className="ymca-fieldLine text-transform">
                          Number of Members
                        </th>
                        <th className="text-center">Not Collected</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-lengths">
                      <tr>
                        <td className="txt-mandatory">Asian</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyAsian"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyAsian,
                              touched.policyAsian,
                              values.policyAsianNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyAsian')
                            }
                            value={
                              values.policyAsianNotCollected
                                ? ''
                                : values.policyAsian
                            }
                            disabled={!!values.policyAsianNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center  d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyAsianNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Black or African American
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyBlackOrAfricanAmerican"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyBlackOrAfricanAmerican,
                              touched.policyBlackOrAfricanAmerican,
                              values.policyBlackOrAfricanAmericanNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyBlackOrAfricanAmerican'
                              )
                            }
                            value={
                              values.policyBlackOrAfricanAmericanNotCollected
                                ? ''
                                : values.policyBlackOrAfricanAmerican
                            }
                            disabled={
                              !!values.policyBlackOrAfricanAmericanNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyBlackOrAfricanAmericanNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Hispanic / Latino</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyHispanicLatino"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyHispanicLatino,
                              touched.policyHispanicLatino,
                              values.policyHispanicLatinoNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyHispanicLatino'
                              )
                            }
                            value={
                              values.policyHispanicLatinoNotCollected
                                ? ''
                                : values.policyHispanicLatino
                            }
                            disabled={!!values.policyHispanicLatinoNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyHispanicLatinoNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Middle Eastern or Northern African
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyMidEastNorthAfrican"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyMidEastNorthAfrican,
                              touched.policyMidEastNorthAfrican,
                              values.policyMidEastNorthAfricanNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyMidEastNorthAfrican'
                              )
                            }
                            value={
                              values.policyMidEastNorthAfricanNotCollected
                                ? ''
                                : values.policyMidEastNorthAfrican
                            }
                            disabled={
                              !!values.policyMidEastNorthAfricanNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyMidEastNorthAfricanNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Native American or Alaskan Native
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyNativeAmericanAlaskanNative"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyNativeAmericanAlaskanNative,
                              touched.policyNativeAmericanAlaskanNative,
                              values.policyNativeAmericanAlaskanNativeNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyNativeAmericanAlaskanNative'
                              )
                            }
                            value={
                              values.policyNativeAmericanAlaskanNativeNotCollected
                                ? ''
                                : values.policyNativeAmericanAlaskanNative
                            }
                            disabled={
                              !!values.policyNativeAmericanAlaskanNativeNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyNativeAmericanAlaskanNativeNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Native Hawaiian or Other Pacific Islander
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyNatvHawaiOtherPacificIslander"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyNatvHawaiOtherPacificIslander,
                              touched.policyNatvHawaiOtherPacificIslander,
                              values.policyNatvHawaiOtherPacificIslanderNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyNatvHawaiOtherPacificIslander'
                              )
                            }
                            value={
                              values.policyNatvHawaiOtherPacificIslanderNotCollected
                                ? ''
                                : values.policyNatvHawaiOtherPacificIslander
                            }
                            disabled={
                              !!values.policyNatvHawaiOtherPacificIslanderNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyNatvHawaiOtherPacificIslanderNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">White</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyWhite"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyWhite,
                              touched.policyWhite,
                              values.policyWhiteNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyWhite')
                            }
                            value={
                              values.policyWhiteNotCollected
                                ? ''
                                : values.policyWhite
                            }
                            disabled={!!values.policyWhiteNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyWhiteNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Two or More Races / Ethnicities
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyTwoOrMoreRaces"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyTwoOrMoreRaces,
                              touched.policyTwoOrMoreRaces,
                              values.policyTwoOrMoreRacesNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'policyTwoOrMoreRaces'
                              )
                            }
                            value={
                              values.policyTwoOrMoreRacesNotCollected
                                ? ''
                                : values.policyTwoOrMoreRaces
                            }
                            disabled={!!values.policyTwoOrMoreRacesNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyTwoOrMoreRacesNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Other</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyOther"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyOther,
                              touched.policyOther,
                              values.policyOtherNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyOther')
                            }
                            value={
                              values.policyOtherNotCollected
                                ? ''
                                : values.policyOther
                            }
                            disabled={!!values.policyOtherNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyOtherNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Unknown</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="policyUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.policyUnknown,
                              touched.policyUnknown,
                              values.policyUnknownNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'policyUnknown')
                            }
                            value={
                              values.policyUnknownNotCollected
                                ? ''
                                : values.policyUnknown
                            }
                            disabled={!!values.policyUnknownNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center d-block">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="policyUnknownNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={totalCount(
                              !values.policyAsianNotCollected
                                ? Number(values.policyAsian)
                                : 0,

                              !values.policyBlackOrAfricanAmericanNotCollected
                                ? Number(values.policyBlackOrAfricanAmerican)
                                : 0,

                              !values.policyHispanicLatinoNotCollected
                                ? Number(values.policyHispanicLatino)
                                : 0,

                              !values.policyMidEastNorthAfricanNotCollected
                                ? Number(values.policyMidEastNorthAfrican)
                                : 0,

                              !values.policyNativeAmericanAlaskanNativeNotCollected
                                ? Number(
                                    values.policyNativeAmericanAlaskanNative
                                  )
                                : 0,

                              !values.policyNatvHawaiOtherPacificIslanderNotCollected
                                ? Number(
                                    values.policyNatvHawaiOtherPacificIslander
                                  )
                                : 0,

                              !values.policyWhiteNotCollected
                                ? Number(values.policyWhite)
                                : 0,

                              !values.policyTwoOrMoreRacesNotCollected
                                ? Number(values.policyTwoOrMoreRaces)
                                : 0,

                              !values.policyOtherNotCollected
                                ? Number(values.policyOther)
                                : 0,

                              !values.policyUnknownNotCollected
                                ? Number(values.policyUnknown)
                                : 0
                            )}
                            disabled
                          />
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <hr />

                <h4 className="ymca-headingTitle"> Program Volunteers </h4>

                <div className="ymca-tableResponsive">
                  <table className="table ymca-table ymca-lastTable ymca-autoLength">
                    <tbody className="ymca-cuttingBody ymca-nostripeBody ymca-lengths">
                      <tr>
                        <td className="ymca-fieldLine txt-mandatory">
                          <span>Total Number of Program Volunteers:</span>
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="totProgVolunteers"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.totProgVolunteers &&
                              touched.totProgVolunteers
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'totProgVolunteers'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-defaultSpaces">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="ymca-fieldLine txt-mandatory">
                          <span>
                            Total (annual) Number of Program Volunteer Hours:
                          </span>
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programVolunteersHrs"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programVolunteersHrs &&
                              touched.programVolunteersHrs
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programVolunteersHrs'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-defaultSpaces">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped ymca-autoLength">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th className="ymca-th-nowrap">Ages Under 30</th>
                        <th>Ages 30-54</th>
                        <th>Ages 55-64</th>
                        <th>Ages 65+</th>
                        <th>Unknown</th>
                        <th>Total</th>
                        <th className="ymca-defaultSpace">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody ymca-lengths">
                      <tr>
                        <td className="txt-mandatory">Female</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programFemale0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programFemale0To29 &&
                              touched.programFemale0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programFemale0To29'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programFemale30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programFemale30To54 &&
                              touched.programFemale30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programFemale30To54'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programFemale55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programFemale55To64 &&
                              touched.programFemale55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programFemale55To64'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programFemale65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programFemale65Plus &&
                              touched.programFemale65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programFemale65Plus'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programFemaleAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programFemaleAgeUnknown &&
                              touched.programFemaleAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programFemaleAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programFemale0To29) +
                              Number(values.programFemale30To54) +
                              Number(values.programFemale55To64) +
                              Number(values.programFemale65Plus) +
                              Number(values.programFemaleAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Male</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programMale0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programMale0To29 &&
                              touched.programMale0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(e, setFieldValue, 'programMale0To29')
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programMale30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programMale30To54 &&
                              touched.programMale30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programMale30To54'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programMale55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programMale55To64 &&
                              touched.programMale55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programMale55To64'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programMale65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programMale65Plus &&
                              touched.programMale65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programMale65Plus'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programMaleAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programMaleAgeUnknown &&
                              touched.programMaleAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programMaleAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programMale0To29) +
                              Number(values.programMale30To54) +
                              Number(values.programMale55To64) +
                              Number(values.programMale65Plus) +
                              Number(values.programMaleAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Additional Identities</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programAdditionalIdentities0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programAdditionalIdentities0To29 &&
                              touched.programAdditionalIdentities0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programAdditionalIdentities0To29'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programAdditionalIdentities30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programAdditionalIdentities30To54 &&
                              touched.programAdditionalIdentities30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programAdditionalIdentities30To54'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programAdditionalIdentities55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programAdditionalIdentities55To64 &&
                              touched.programAdditionalIdentities55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programAdditionalIdentities55To64'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programAdditionalIdentities65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programAdditionalIdentities65Plus &&
                              touched.programAdditionalIdentities65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programAdditionalIdentities65Plus'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programAdditionalIdentitiesAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programAdditionalIdentitiesAgeUnknown &&
                              touched.programAdditionalIdentitiesAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programAdditionalIdentitiesAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programAdditionalIdentities0To29) +
                              Number(values.programAdditionalIdentities30To54) +
                              Number(values.programAdditionalIdentities55To64) +
                              Number(values.programAdditionalIdentities65Plus) +
                              Number(
                                values.programAdditionalIdentitiesAgeUnknown
                              )
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Unknown</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programUnknown0To29"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programUnknown0To29 &&
                              touched.programUnknown0To29
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programUnknown0To29'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programUnknown30To54"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programUnknown30To54 &&
                              touched.programUnknown30To54
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programUnknown30To54'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programUnknown55To64"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programUnknown55To64 &&
                              touched.programUnknown55To64
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programUnknown55To64'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programUnknown65Plus"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programUnknown65Plus &&
                              touched.programUnknown65Plus
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programUnknown65Plus'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programUnknownAgeUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={
                              errors.programUnknownAgeUnknown &&
                              touched.programUnknownAgeUnknown
                                ? 'ymca-field invalid'
                                : 'ymca-field'
                            }
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programUnknownAgeUnknown'
                              )
                            }
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programUnknown0To29) +
                              Number(values.programUnknown30To54) +
                              Number(values.programUnknown55To64) +
                              Number(values.programUnknown65Plus) +
                              Number(values.programUnknownAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programFemale0To29) +
                              Number(values.programMale0To29) +
                              Number(values.programAdditionalIdentities0To29) +
                              Number(values.programUnknown0To29)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programFemale30To54) +
                              Number(values.programMale30To54) +
                              Number(values.programAdditionalIdentities30To54) +
                              Number(values.programUnknown30To54)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programFemale55To64) +
                              Number(values.programMale55To64) +
                              Number(values.programAdditionalIdentities55To64) +
                              Number(values.programUnknown55To64)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programFemale65Plus) +
                              Number(values.programMale65Plus) +
                              Number(values.programAdditionalIdentities65Plus) +
                              Number(values.programUnknown65Plus)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={
                              Number(values.programFemaleAgeUnknown) +
                              Number(values.programMaleAgeUnknown) +
                              Number(
                                values.programAdditionalIdentitiesAgeUnknown
                              ) +
                              Number(values.programUnknownAgeUnknown)
                            }
                            disabled
                          />
                        </td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            disabled
                            value={
                              Number(values.programFemale0To29) +
                              Number(values.programMale0To29) +
                              Number(values.programAdditionalIdentities0To29) +
                              Number(values.programUnknown0To29) +
                              Number(values.programFemale30To54) +
                              Number(values.programMale30To54) +
                              Number(values.programAdditionalIdentities30To54) +
                              Number(values.programUnknown30To54) +
                              Number(values.programFemale55To64) +
                              Number(values.programMale55To64) +
                              Number(values.programAdditionalIdentities55To64) +
                              Number(values.programUnknown55To64) +
                              Number(values.programFemale65Plus) +
                              Number(values.programMale65Plus) +
                              Number(values.programAdditionalIdentities65Plus) +
                              Number(values.programUnknown65Plus) +
                              Number(values.programFemaleAgeUnknown) +
                              Number(values.programMaleAgeUnknown) +
                              Number(
                                values.programAdditionalIdentitiesAgeUnknown
                              ) +
                              Number(values.programUnknownAgeUnknown)
                            }
                          />
                        </td>
                        <td className="ymca-defaultSpace">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4 className="ymca-headingTitle">
                  Please click in the checkbox if this race/ethnic category is
                  not collected.
                </h4>

                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped ymca-autoLength">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th className="ymca-fieldLine text-transform">
                          Number of Members
                        </th>
                        <th>Not Collected</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-lengths">
                      <tr>
                        <td className="txt-mandatory">Asian</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programAsian"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programAsian,
                              touched.programAsian,
                              values.programAsianNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'programAsian')
                            }
                            value={
                              values.programAsianNotCollected
                                ? ''
                                : values.programAsian
                            }
                            disabled={!!values.programAsianNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programAsianNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Black or African American
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programBlackOrAfricanAmerican"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programBlackOrAfricanAmerican,
                              touched.programBlackOrAfricanAmerican,
                              values.programBlackOrAfricanAmericanNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programBlackOrAfricanAmerican'
                              )
                            }
                            value={
                              values.programBlackOrAfricanAmericanNotCollected
                                ? ''
                                : values.programBlackOrAfricanAmerican
                            }
                            disabled={
                              !!values.programBlackOrAfricanAmericanNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programBlackOrAfricanAmericanNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Hispanic / Latino</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programHispanicLatino"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programHispanicLatino,
                              touched.programHispanicLatino,
                              values.programHispanicLatinoNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programHispanicLatino'
                              )
                            }
                            value={
                              values.programHispanicLatinoNotCollected
                                ? ''
                                : values.programHispanicLatino
                            }
                            disabled={
                              !!values.programHispanicLatinoNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programHispanicLatinoNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Middle Eastern or Northern African
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programMidEastNorthAfrican"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programMidEastNorthAfrican,
                              touched.programMidEastNorthAfrican,
                              values.programMidEastNorthAfricanNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programMidEastNorthAfrican'
                              )
                            }
                            value={
                              values.programMidEastNorthAfricanNotCollected
                                ? ''
                                : values.programMidEastNorthAfrican
                            }
                            disabled={
                              !!values.programMidEastNorthAfricanNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programMidEastNorthAfricanNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>

                      <tr>
                        <td className="txt-mandatory">
                          Native American or Alaskan Native
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programNativeAmericanAlaskanNative"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programNativeAmericanAlaskanNative,
                              touched.programNativeAmericanAlaskanNative,
                              values.programNativeAmericanAlaskanNativeNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programNativeAmericanAlaskanNative'
                              )
                            }
                            value={
                              values.programNativeAmericanAlaskanNativeNotCollected
                                ? ''
                                : values.programNativeAmericanAlaskanNative
                            }
                            disabled={
                              !!values.programNativeAmericanAlaskanNativeNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programNativeAmericanAlaskanNativeNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Native Hawaiian or Other Pacific Islander
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programNatvHawaiOtherPacificIslander"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programNatvHawaiOtherPacificIslander,
                              touched.programNatvHawaiOtherPacificIslander,
                              values.programNatvHawaiOtherPacificIslanderNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programNatvHawaiOtherPacificIslander'
                              )
                            }
                            value={
                              values.programNatvHawaiOtherPacificIslanderNotCollected
                                ? ''
                                : values.programNatvHawaiOtherPacificIslander
                            }
                            disabled={
                              !!values.programNatvHawaiOtherPacificIslanderNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programNatvHawaiOtherPacificIslanderNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">White</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programWhite"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programWhite,
                              touched.programWhite,
                              values.programWhiteNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'programWhite')
                            }
                            value={
                              values.programWhiteNotCollected
                                ? ''
                                : values.programWhite
                            }
                            disabled={!!values.programWhiteNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programWhiteNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">
                          Two or More Races / Ethnicities
                        </td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programTwoOrMoreRaces"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programTwoOrMoreRaces,
                              touched.programTwoOrMoreRaces,
                              values.programTwoOrMoreRacesNotCollected
                            )}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                'programTwoOrMoreRaces'
                              )
                            }
                            value={
                              values.programTwoOrMoreRacesNotCollected
                                ? ''
                                : values.programTwoOrMoreRaces
                            }
                            disabled={
                              !!values.programTwoOrMoreRacesNotCollected
                            }
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programTwoOrMoreRacesNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Other</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programOther"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programOther,
                              touched.programOther,
                              values.programOtherNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'programOther')
                            }
                            value={
                              values.programOtherNotCollected
                                ? ''
                                : values.programOther
                            }
                            disabled={!!values.programOtherNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programOtherNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="txt-mandatory">Unknown</td>
                        <td className="ymca-fieldLine">
                          <Field
                            name="programUnknown"
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className={getClassNames(
                              errors.programUnknown,
                              touched.programUnknown,
                              values.programUnknownNotCollected
                            )}
                            onChange={e =>
                              handleChange(e, setFieldValue, 'programUnknown')
                            }
                            value={
                              values.programUnknownNotCollected
                                ? ''
                                : values.programUnknown
                            }
                            disabled={!!values.programUnknownNotCollected}
                          />
                        </td>
                        <td>
                          <ul className="ymca-mainCheckBTN text-center">
                            <li className="ymca-listCheckBTN">
                              <Field
                                name="programUnknownNotCollected"
                                type="checkbox"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="ymca-fieldLine">
                          <input
                            type="text"
                            maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                            className="ymca-field ymca-fieldDisabled"
                            value={totalCount(
                              !values.programNativeAmericanAlaskanNativeNotCollected
                                ? Number(
                                    values.programNativeAmericanAlaskanNative
                                  )
                                : 0,
                              !values.programAsianNotCollected
                                ? Number(values.programAsian)
                                : 0,
                              !values.programBlackOrAfricanAmericanNotCollected
                                ? Number(values.programBlackOrAfricanAmerican)
                                : 0,
                              !values.programHispanicLatinoNotCollected
                                ? Number(values.programHispanicLatino)
                                : 0,
                              !values.programMidEastNorthAfricanNotCollected
                                ? Number(values.programMidEastNorthAfrican)
                                : 0,
                              !values.programNatvHawaiOtherPacificIslanderNotCollected
                                ? Number(
                                    values.programNatvHawaiOtherPacificIslander
                                  )
                                : 0,
                              !values.programWhiteNotCollected
                                ? Number(values.programWhite)
                                : 0,
                              !values.programTwoOrMoreRacesNotCollected
                                ? Number(values.programTwoOrMoreRaces)
                                : 0,
                              !values.programOtherNotCollected
                                ? Number(values.programOther)
                                : 0,
                              !values.programUnknownNotCollected
                                ? Number(values.programUnknown)
                                : 0
                            )}
                            disabled
                          />
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="d-flex flex-column">
                    {policyTotalMessage && (
                      <div className="errorNote position-static fw-bold">
                        {` * Policy table matrix total must be equal to "Total
                      Number of Policy Volunteers" field value`}
                      </div>
                    )}
                    {policyRaceEthnicTotalMessage && (
                      <div className="errorNote mt-2 mb-2 position-static fw-bold">
                        {` * Policy race/ethnic total must be equal to "Total
                      Number of Policy Volunteers" field value`}
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-column mb-4">
                    {programTotalMessage && (
                      <div className="errorNote position-static fw-bold">
                        {` * Program table matrix total must be equal to 
                      "Program Volunteers" field value`}
                      </div>
                    )}
                    {programRaceEthnicTotalMessage && (
                      <div className="errorNote  mt-2 mb-2 position-static fw-bold">
                        {`* Program race/ethnic total must be equal to 
                      "Program Volunteers" field value`}
                      </div>
                    )}
                  </div>
                </div>
                <div className="ymca-submitHighlighter">
                  <LastUpdateName
                    Username={GetVolunteersSelectorData?.data?.updatedByName}
                    Time={GetVolunteersSelectorData?.data?.updatedDatetime}
                  />
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightUntitled float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </Form>
            );
          }}
        </Formik>
      </div>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {help && <Help hidePopup={hideShowHelp} keyValue="volunteers" />}
    </div>
  );
};

VolunteersTab.propTypes = {
  handleGetVolunteers: PropTypes.func.isRequired,
  handlePostVolunteers: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  GetVolunteersSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetVolunteers: getVolunteers,
  handlePostVolunteers: postVolunteers,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetVolunteersSelectorData: GetVolunteersSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VolunteersTab));
