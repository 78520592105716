export const AssociationSummarySelector = ({
  dashboard: { AssociationSummary }
}) => AssociationSummary;

export const AssociationChangeLogSelector = ({
  dashboard: { AssociationChangeLog }
}) => AssociationChangeLog;

export const AssociationNumberSelector = ({
  dashboard: { AssociationNumber }
}) => AssociationNumber;

export const AssociationListSelector = ({ dashboard: { AssociationList } }) =>
  AssociationList;

export const AssociationMoypChangeLogSelector = ({
  dashboard: { AssociationMoypChangeLog }
}) => AssociationMoypChangeLog;
