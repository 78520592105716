import * as types from './types';

const initialState = {
  GetDonors: {},
  PostDonors: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_KEY':
      return {
        ...state,
        [action.payload.key]: {}
      };
    case types.GET_DONORS_SUCCESS: {
      return {
        ...state,
        GetDonors: action.payload
      };
    }
    case types.GET_DONORS_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_DONORS_SUCCESS: {
      return {
        ...state,
        PostDonors: action.payload
      };
    }
    case types.POST_DONORS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
