import { DONORS_GET_URL, DONORS_POST_URL } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getDonors = associationsNum => (dispatch, getState) =>
  asyncRequest({
    url: DONORS_GET_URL(associationsNum),
    successAction: data => ({
      type: types.GET_DONORS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_DONORS_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postDonors = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: DONORS_POST_URL(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_DONORS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_DONORS_FAILURE,
      payload: error
    }),
    dispatch
  });
export const resetReduxState = key => ({
  type: 'RESET_KEY',
  payload: { key }
});
