import {
  ANNUAL_REPORT_GET_OLDER_ADULTS,
  ANNUAL_REPORT_OLDER_ADULTS
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getOlderAdults =
  (AssociationNumber, BranchNumber, year) => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_OLDER_ADULTS(
        AssociationNumber,
        BranchNumber,
        year
      ),
      successAction: data => ({
        type: types.OLDER_ADULTS_GET_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.OLDER_ADULTS_GET_FAILURE,
        payload: error
      }),
      dispatch
    });
export const olderAdults = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: ANNUAL_REPORT_OLDER_ADULTS(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.OLDER_ADULTS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.OLDER_ADULTS_FAILURE,
      payload: error
    }),
    dispatch
  });
