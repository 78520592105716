import React from 'react';
import PropTypes from 'prop-types';

const TextError = ({ children }) => <div className="errorNote">{children}</div>;

TextError.propTypes = {
  children: PropTypes.string.isRequired
};

export default TextError;
