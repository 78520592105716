import {
  GET_VOLUNTEER_DASHBOARD_DETAILS,
  GET_VOLUNTEER_DASHBOARD_TABLE_DETAILS
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getVolunteerAdminDashboardList =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_VOLUNTEER_DASHBOARD_DETAILS(AssociationId),
      method: 'GET',
      successAction: data => ({
        type: types.VOLUNTEER_ADMIN_DASHBOARD_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.VOLUNTEER_ADMIN_DASHBOARD_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getVolunteerAdminDashboardTableList =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: GET_VOLUNTEER_DASHBOARD_TABLE_DETAILS(AssociationId),
      method: 'GET',
      successAction: data => ({
        type: types.VOLUNTEER_ADMIN_DASHBOARD_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.VOLUNTEER_ADMIN_DASHBOARD_FAILURE,
        payload: error
      }),
      dispatch
    });
