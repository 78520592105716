const UserService = {
    getTokenStore: () => {
        const tokenString = sessionStorage.getItem("token");
        if (tokenString) {
            const token = JSON.parse(tokenString);
            return token;
        }
        return null;
    },

    getToken: () => {
        const data = UserService.getTokenStore();
        if (data) {
            const { token } = data;
            return token;
        }
        return null;
    },

    getUser: () => {
        const data = UserService.getTokenStore();
        if (data) {
            const { user } = data;
            return user;
        }
        return null;
    },

    getAssociation: () => {
        const data = UserService.getUser();
        if (data) {
            const { associations } = data;
            return associations;
        }
        return null;
    },

    getUserName: () => {
        const data = UserService.getUser();
        if (data) {
            const { userName } = data;
            return userName;
        }
        return null;
    },

    getFullName: () => {
        const data = UserService.getUser();
        if (data) {
            const fulName = `${data.firstName} ${data.lastName}`;
            return fulName;
        }
        return null;
    }
}

export default UserService;