import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import crossiconblue from '@static/images/cross-icon-blue.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import { connect } from 'react-redux';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';

const ViewDetailsPopup = props => {
  const {
    popupTitle,
    popupData,
    showViewDetailsPopup,
    handleCloseNewStaffPopup,
    AssociationNumberData
  } = props;

  const { profileDetails, programDeatils } = popupData;

  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const showHideClassName = showViewDetailsPopup
    ? 'modal d-block'
    : 'modal d-none';
  return (
    <div
      className={showHideClassName}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ymca-modal view-details-modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="ymca-black-sub-heading">{popupTitle}</div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseNewStaffPopup}
            >
              <img
                src={crossiconblue}
                alt="cross-icon-blue"
                className="close-icon img-fluid"
              />
            </button>
          </div>
          <div className="modal-body px-0">
            {/* <hr className="mt-0" /> */}

            <div className="ymca-modal-title">Program Details</div>
            <div className="row px-3">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.ASSOCIATION_NAME}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(associateData, 'associationName') ||
                    get(
                      AssociationNumberData,
                      'data.association.associationName'
                    )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Branch Name</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'branchName', '')
                    ? get(profileDetails, 'branchName', '')
                    : get(programDeatils?.branch, 'branchname', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Program Name</div>
                <div className="ymca-modal-content notranslate">
                  {get(programDeatils?.program, 'programName', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Start Date</div>
                <div className="ymca-modal-content notranslate">
                  {convertUtcToLocal(
                    get(programDeatils?.program, 'startDate', ''),
                    'DD MMM YYYY'
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">End Date</div>
                <div className="ymca-modal-content notranslate">
                  {convertUtcToLocal(
                    get(programDeatils?.program, 'endDate', ''),
                    'DD MMM YYYY'
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Instructor Name</div>
                <div className="ymca-modal-content notranslate">
                  {get(programDeatils?.program, 'instructorName', '')}
                </div>
              </div>
            </div>
            <hr />
            <div className="ymca-modal-title">Profile Details</div>
            <div className="row px-3">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Reference ID</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'profileId', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Student Birth Date</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'childBirthDate') &&
                  get(profileDetails, 'childBirthDate') !==
                    '0001-01-01T00:00:00'
                    ? convertUtcToLocal(
                        get(profileDetails, 'childBirthDate'),
                        'DD MMM YYYY'
                      )
                    : '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Gender</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'gender', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Ethnicity</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'ethnicity', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  How Did You Hear About This Program
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'howDidYouHear', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Is Your Student New To The Y
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'childNewToTheY', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  What Level Of SAW Student Enrolled
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'levelOfSAW', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Zip Code</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'zipCode', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Was Student Able To Submerge Their Face Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'submergeTheirFaceLesson1', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Was Student Able To Submerge Their Face Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'submergeTheirFaceLesson8', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Bob Independently Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'bobIndependentlyLesson1', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Bob Independently Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'bobIndependentlyLesson8', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Front Glide 5ft Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'frontGlide5ftLesson1', null) || '-'}
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Front Glide 5ft Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'frontGlide5ftLesson8', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Pool Lesson 1</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'poolLesson1', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Pool Lesson 8</div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'poolLesson8', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Float Swim 10ft Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'floatSwim10ftLesson1', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Float Swim 10ft Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'floatSwim10ftLesson8', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Jump Independently Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'jumpIndependentlyLesson1', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Jump Independently Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'jumpIndependentlyLesson8', null) || '-'}
                </div>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Independently Lesson 8 </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'floatSwim10ftLesson8', '')
                    ? 'YES'
                    : 'NO'}
                </div>
              </div> */}
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Grab Assisted Lesson 1 </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'grabAssistedLesson1', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Grab Assisted Lesson 8 </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'grabAssistedLesson8', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Grab Independently Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'grabIndependentlyLesson1', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Grab Independently Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'grabIndependentlyLesson8', null) || '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Can Your Student Jump Into The Water And Safely Exit The Pool
                  Without Help
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'safelyExitThePoolWithoutHelp', null) ||
                    '-'}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Has Your Student Ever Had Swim Lessons Before
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(profileDetails, 'safelyExitThePoolWithoutHelp', null) ||
                    '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewDetailsPopup.propTypes = {
  popupTitle: PropTypes.string,
  showViewDetailsPopup: PropTypes.bool,
  popupData: PropTypes.arrayOf(PropTypes.any),
  handleCloseNewStaffPopup: PropTypes.func.isRequired,
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired
};

ViewDetailsPopup.defaultProps = {
  popupTitle: '',
  popupData: [],
  showViewDetailsPopup: false
};

const mapStateToProps = state => ({
  AssociationNumberData: AssociationNumberSelector(state)
});

export default connect(mapStateToProps, null)(ViewDetailsPopup);
