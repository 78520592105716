import {
  GET_SUBMIT_INFO,
  FINAL_SUBMIT,
  POST_REVIEW_SUBMIT_DATA
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSubmitInfo = associationId => (dispatch, getState) =>
  asyncRequest({
    url: GET_SUBMIT_INFO(associationId),
    method: 'GET',
    successAction: data => ({
      type: types.GET_REVIEW_SUBMIT_INFO_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_REVIEW_SUBMIT_INFO_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postReviewSubmitData = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: POST_REVIEW_SUBMIT_DATA(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.POST_REVIEW_SUBMIT_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_REVIEW_SUBMIT_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });

export const finalSubmit = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: FINAL_SUBMIT(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.FINAL_SUBMIT_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.FINAL_SUBMIT_FAILURE,
      payload: error
    }),
    dispatch
  });
