import * as types from './types';

const initialState = {
  GetOrganizationCommitment: {},
  PostOrganizationCommitment: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_KEY':
      return {
        ...state,
        [action.payload.key]: {}
      };
    case types.GET_ORGANIZATIONAL_COMMITMENT_SUCCESS: {
      return {
        ...state,
        GetOrganizationCommitment: action.payload
      };
    }
    case types.GET_ORGANIZATIONAL_COMMITMENT_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_ORGANIZATIONAL_COMMITMENT_SUCCESS: {
      return {
        ...state,
        PostOrganizationCommitment: action.payload
      };
    }
    case types.POST_ORGANIZATIONAL_COMMITMENT_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
