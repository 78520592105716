import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { postVolunteerDocumentApprovalData } from '@redux/swim-registration/volunteer-admin-preview/actions';
import { VolunteerAdminDocumentApprovalSelector } from '@redux/swim-registration/volunteer-admin-preview/selectors';
import crossicon from '@static/images/cross-icon-blue.svg';

const RejectModal = props => {
  const {
    handlePostVolunteerDocumentApproval,
    documentDetail,
    volunteerID,
    setLoader,
    handleVolunteerAdminPreview,
    editDecision
  } = props;

  const [comment, setComment] = useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);

  useEffect(() => {
    const commentValue =
      documentDetail?.comment != null ? documentDetail?.comment : '';

    setComment(commentValue);
  }, [documentDetail]);

  const handleComments = e => {
    if (e.target.value) {
      setErrorFlag(false);
    }
    setComment(e.target.value);
  };

  const onSubmit = (commentsValue, documentDetailObject) => {
    setLoader(true);
    if (commentsValue === '') {
      setErrorFlag(true);
      setLoader(false);
      return;
    }
    const objectPayload = {
      volunteerDocumentId: documentDetailObject?.volunteerDocumentId,
      documentTypeId: documentDetailObject?.documentTypeId,
      volunteerId: volunteerID,
      comment: commentsValue,
      status: 'Rejected'
    };
    setErrorFlag(false);

    handlePostVolunteerDocumentApproval(objectPayload).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setComment('');
        setLoader(false);
        handleVolunteerAdminPreview(true);
        editDecision(false);
        document.getElementById('rejectModal').click();
      } else {
        setLoader(false);
        editDecision(false);
        document.getElementById('rejectModal').click();
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };
  const handleCancel = () => {
    setComment('');
    setErrorFlag(false);
  };

  // THIS BELOW CODE IS FOR DISABLE TEXT_AREA INPUT FIELD
  // const disableInput = () => {
  //   let disableFlag = false;
  //   if (
  //     documentDetail?.documentTypeString === 'BackgroundScreening' &&
  //     documentDetail?.status === 'Denied'
  //   ) {
  //     disableFlag = true;
  //   }
  //   return disableFlag;
  // };

  return (
    <>
      <div
        className="modal fade reject-modal"
        id="rejectModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="rejectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div
                className="modal-title ymca-section-heading"
                id="rejectModalLabel"
              >
                Add Comments for Rejection
              </div>
              <button
                type="button"
                className="close border-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              >
                <img
                  src={crossicon}
                  alt="cross-icon-blue"
                  className="close-icon img-fluid"
                />
              </button>
            </div>
            <div className="modal-body">
              <div className="ymca-form">
                <div className="form-group">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Comments
                  </label>
                  <textarea
                    name="comments"
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Add comments"
                    value={comment}
                    onChange={handleComments}
                    // disabled={disableInput()}
                  />
                  {errorFlag && (
                    <div className="errorNote">* Please enter comments.</div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer mt-2">
              <button
                type="button"
                className="btn list-group-item border-0 p-0 me-2 ymca-link reject"
                // disabled={comment === ''}
                onClick={() => onSubmit(comment, documentDetail)}
              >
                Reject
              </button>
              <button
                type="button"
                className="btn list-group-item border-0 p-0 ymca-grey-link"
                data-bs-dismiss="modal"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
    </>
  );
};

RejectModal.propTypes = {
  volunteerID: PropTypes.string.isRequired,
  documentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleVolunteerAdminPreview: PropTypes.func.isRequired,
  handlePostVolunteerDocumentApproval: PropTypes.func.isRequired,
  editDecision: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handlePostVolunteerDocumentApproval: postVolunteerDocumentApprovalData
};

const mapStateToProps = state => ({
  VolunteerAdminDocumentApprovalSelectorData:
    VolunteerAdminDocumentApprovalSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(RejectModal));
