const ADMIN_DASHBOARD_COLUMNS = () => [
  {
    Header: 'REFERENCE ID',
    accessor: 'yName',
    className: 'notranslate ymca-word-break ymca-text-primary'
  },
  {
    Header: 'GENDER',
    accessor: 'associationNumber',
    className: 'notranslate'
  },
  {
    Header: 'DOB',
    accessor: 'physicalCity',
    className: 'notranslate'
  },
  {
    Header: 'ETHNICITY',
    accessor: 'physicalState',
    className: 'notranslate'
  },
  {
    Header: 'COLLECTED BY',
    accessor: 'lastUploadedDate',
    className: 'notranslate '
  },
  {
    Header: 'ACTIIONS',
    accessor: '',
    className: 'notranslate'
  }
];

export default ADMIN_DASHBOARD_COLUMNS;
