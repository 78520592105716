export const CollabNonProfitOrgSelector = ({
  annualReportCollaboration: { CollabNonProfitOrg }
}) => CollabNonProfitOrg;

export const CollabEducationOrgSelector = ({
  annualReportCollaboration: { CollabEducationOrg }
}) => CollabEducationOrg;

export const CollabGovtOrgAndAffiliatesSelector = ({
  annualReportCollaboration: { CollabGovtOrgAndAffiliates }
}) => CollabGovtOrgAndAffiliates;

export const CollabOtherCommunityOrgSelector = ({
  annualReportCollaboration: { CollabOtherCommunityOrg }
}) => CollabOtherCommunityOrg;

export const CollabPrivateOrgAndBusinessesSelector = ({
  annualReportCollaboration: { CollabPrivateOrgAndBusinesses }
}) => CollabPrivateOrgAndBusinesses;

export const CollabAmongYsSelector = ({
  annualReportCollaboration: { CollaborationsAmongYs }
}) => CollaborationsAmongYs;
