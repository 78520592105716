export const POST_CDP_SOLERA_FILE_SUCCESS = 'POST_CDP_SOLERA_FILE_SUCCESS';
export const POST_CDP_SOLERA_FILE_FAILURE = 'POST_CDP_SOLERA_FILE_FAILURE';

export const POST_CDP_SOLERA_IMPORT_SUCCESS = 'POST_CDP_SOLERA_IMPORT_SUCCESS';
export const POST_CDP_SOLERA_IMPORT_FAILURE = 'POST_CDP_SOLERA_IMPORT_FAILURE';

export const POST_CDP_WELLD_IMPORT_SUCCESS = 'POST_CDP_WELLD_IMPORT_SUCCESS';
export const POST_CDP_WELLD_IMPORT_FAILURE = 'POST_CDP_WELLD_IMPORT_FAILURE';

export const GET_CDP_PROGRAM_SUCCESS = 'GET_CDP_PROGRAM_SUCCESS';
export const GET_CDP_PROGRAM_FAILURE = 'GET_CDP_PROGRAM_FAILURE';

export const GET_CDP_IS_IMPORTED_MSG_SUCCESS =
  'GET_CDP_IS_IMPORTED_MSG_SUCCESS';
export const GET_CDP_IS_IMPORTED_MSG_FAILURE =
  'GET_CDP_IS_IMPORTED_MSG_FAILURE';

export const GET_DOL_TYPE_SUCCESS = 'GET_DOL_TYPE_SUCCESS';
export const GET_DOL_TYPE_FAILURE = 'GET_DOL_TYPE_FAILURE';

export const GET_CDP_DATASYSTEM_SUCCESS = 'GET_CDP_DATASYSTEM_SUCCESS';
export const GET_CDP_DATASYSTEM_FAILURE = 'GET_CDP_DATASYSTEM_FAILURE';

export const SELECTED_DATA_SYSTEM = 'SELECTED_DATA_SYSTEM';
export const SELECTED_PROGRAM = 'SELECTED_PROGRAM';
export const DETAIL_PAGE_TYPE = 'DETAIL_PAGE_TYPE';
export const SAVE_CDP_FETCH_ID = 'SAVE_CDP_FETCH_ID';
export const SAVE_FETCH_TYPE = 'SAVE_FETCH_TYPE';

export const POST_CDP_REDCAP_REGISTRATION_SUCCESS =
  'POST_CDP_REDCAP_REGISTRATION_SUCCESS';
export const POST_CDP_REDCAP_REGISTRATION_FAILURE =
  'POST_CDP_REDCAP_REGISTRATION_FAILURE';

export const POST_CDP_REDCAP_FETCH_SUCCESS = 'POST_CDP_REDCAP_FETCH_SUCCESS';
export const POST_CDP_REDCAP_FETCH_FAILURE = 'POST_CDP_REDCAP_FETCH_FAILURE';

export const POST_CDP_REDCAP_VISITS_SUCCESS = 'POST_CDP_REDCAP_VISITS_SUCCESS';
export const POST_CDP_REDCAP_VISITS_FAILURE = 'POST_CDP_REDCAP_VISITS_FAILURE';

export const POST_CDP_REDCAP_ORGCODE_SUCCESS =
  'POST_CDP_REDCAP_ORGCODE_SUCCESS';
export const POST_CDP_REDCAP_ORGCODE_FAILURE =
  'POST_CDP_REDCAP_ORGCODE_FAILURE';

export const POST_CDP_REDCAP_COACH_SUCCESS = 'POST_CDP_REDCAP_COACH_SUCCESS';
export const POST_CDP_REDCAP_COACH_FAILURE = 'POST_CDP_REDCAP_COACH_FAILURE';

export const POST_CDP_WELLD_FETCH_SUCCESS = 'POST_CDP_WELLD_FETCH_SUCCESS';
export const POST_CDP_WELLD_FETCH_FAILURE = 'POST_CDP_WELLD_FETCH_FAILURE';


