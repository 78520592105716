import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import {
  getSwimSafetyBranchDelete,
  getSwimSafetyBranchesByFilters
} from '@redux/siwm-safety/swim-branches/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';
import { getSwimSafetyAssociationDropdown } from '@redux/siwm-safety/dashboard/actions';
import { BRANCHES_COLUMNS } from '../columns';
import Table from './table';

const BranchTable = props => {
  const {
    handleGetBranchDelete,
    setLoader,
    recallCount,
    handleBranchesByFilters,
    startDate,
    endDate,
    showAll,
    isFilterActive,
    isFilterClear,
    LoginUserData,
    handlegetAssociationList
  } = props;
  const Offset = new Date().getTimezoneOffset();
  const [branchList, setBranchList] = useState([]);
  const [branchDeletId, setBranchDeletId] = useState('');
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [isAssociationExist, setIsAssociationExist] = useState(true);
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const role = get(LoginUserData, 'user.role[0]');
  const associationNumber = get(associateData, 'associationNumber', '');

  const associationNum = get(associateData, 'associationNumber', '')
    ? get(associateData, 'associationNumber', '')
    : get(LoginUserData, 'user.associations[0]', '');

  const handleCloseAttentionPopup = () => setShowAttentionPopup(false);
  const handleDeletePopup = id => {
    setBranchDeletId(id);
    setShowAttentionPopup(true);
  };

  const branchListAPICall = async () => {
    setLoader(true);
    await handleBranchesByFilters(
      startDate,
      endDate,
      Offset,
      showAll,
      associationNumber
    )
      .then(result => {
        if (result?.data?.data) {
          setBranchList(result?.data?.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const checkAssociationAvailable = async () => {
    setLoader(true);
    await handlegetAssociationList().then(result => {
      if (result?.data?.data) {
        const findAssociation = result?.data?.data.find(
          data => data.associationNumber === associationNum
        );
        setIsAssociationExist(!!findAssociation);
      }
    });
  };

  const deletePrograms = () => {
    setLoader(true);
    handleGetBranchDelete(branchDeletId)
      .then(() => {
        setBranchDeletId('');
        setShowAttentionPopup(false);
        branchListAPICall();
        recallCount();
        checkAssociationAvailable();
      })
      .catch(() => {
        setLoader(false);
        setBranchDeletId('');
        setShowAttentionPopup(false);
      });
  };

  const updateBarnchList = branchList?.map(data => ({
    ...data,
    lastUpdatedDate: get(data, 'lastUpdatedDate')
      ? convertUtcToLocal(get(data, 'lastUpdatedDate'), 'DD MMM YYYY')
      : '-',
    actions: (
      <button
        type="button"
        className="swim-safety-action"
        data-bs-toggle="modal"
        data-bs-target="#rejectModal"
        onClick={() => handleDeletePopup(data.id)}
      >
        <span className="ymca-delete-text">Delete</span>
      </button>
    )
  }));

  useEffect(() => {
    checkAssociationAvailable();
    branchListAPICall();
  }, []);

  useEffect(() => {
    if (isFilterClear) {
      branchListAPICall();
    }
  }, [isFilterClear]);

  useEffect(() => {
    if (isFilterActive) {
      branchListAPICall();
    }
  }, [isFilterActive]);

  const columns =
    role === APP_LABELS.BRANCH_DATA_STEWARD ||
    APP_LABELS.VIEWER_PERMISSION_ROLES.includes(role)
      ? BRANCHES_COLUMNS().filter(data => data.accessor !== 'actions')
      : BRANCHES_COLUMNS();

  return (
    <>
      <Table
        rows={updateBarnchList}
        headers={columns}
        title="Branches"
        showCount
        role={role}
        showAddBranch={isAssociationExist}
      />
      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message="Are you sure want to delete?"
        submitButtonName="Yes"
        onSubmit={deletePrograms}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={handleCloseAttentionPopup}
      />
    </>
  );
};

BranchTable.propTypes = {
  handleGetBranchDelete: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  recallCount: PropTypes.func.isRequired,
  handleBranchesByFilters: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  showAll: PropTypes.bool.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  isFilterClear: PropTypes.bool.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handlegetAssociationList: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetBranchDelete: getSwimSafetyBranchDelete,
  handleBranchesByFilters: getSwimSafetyBranchesByFilters,
  handlegetAssociationList: getSwimSafetyAssociationDropdown
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchTable);
