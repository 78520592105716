import {
  ANNUAL_REPORT_OVERVIEW_GETAPI,
  YMCA_BRANCES_LIST_GETAPI,
  COLLAB_NON_PROFIT_ORG_GETAPI,
  COLLAB_EDUCATION_ORG_GETAPI,
  COLLAB_GOVT_ORG_AFFILIATES_GETAPI,
  COLLAB_OTHER_COMMUNITY_ORG_GETAPI,
  COLLAB_PRIVATE_ORG_BUSINESS_GETAPI,
  COLLAB_ORATIONS_AMOUNG_YS_GETAPI,
  ANNUAL_REPORT_GET_PROGRAM_ACTIVITIES,
  ANNUAL_REPORT_GET_HOUSING,
  ANNUAL_REPORT_GET_FOOD_PROGRAM,
  ANNUAL_REPORT_GET_ARMED_SERVICES,
  ANNUAL_REPORT_GET_OLDER_ADULTS,
  ANNUAL_REPORT_DASHBOARD_OVERVIEW
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getAnnualReportsDashboardOverviewData =
  (AssociationId, Year) => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_DASHBOARD_OVERVIEW(AssociationId, Year),
      successAction: data => ({
        type: types.ANNUAL_REPORT_DASHBOARD_OVERVIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUAL_REPORT_DASHBOARD_OVERVIEW_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAnnualReportOverviewData =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_OVERVIEW_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.FETCH_ANNUAL_REPORT_OVERVIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.FETCH_ANNUAL_REPORT_OVERVIEW_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getYmcaBranchesData = AssociationNumber => (dispatch, getState) =>
  asyncRequest({
    url: YMCA_BRANCES_LIST_GETAPI(AssociationNumber),
    successAction: data => ({
      type: types.YMCA_BRANCHES_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.YMCA_BRANCHES_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCollabNonProfitOrgApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_NON_PROFIT_ORG_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.COLLAB_NON_PROFIT_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCollabEducationOrgApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_EDUCATION_ORG_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.COLLAB_EDUCATION_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCollabGovtOrgAndAffiliatesApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_GOVT_ORG_AFFILIATES_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.COLLAB_GOVT_ORG_AFFILIATES,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCollabOtherCommunityOrgApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_OTHER_COMMUNITY_ORG_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.COLLAB_OTHER_COMMUNITY_ORG,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCollabPrivateOrgAndBusinessesApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_PRIVATE_ORG_BUSINESS_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.COLLAB_PRIVATE_ORG_BUSINESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCollaborationsAmongYsApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: COLLAB_ORATIONS_AMOUNG_YS_GETAPI(AssociationNumber),
      successAction: data => ({
        type: types.COLLAB_ORATIONS_AMOUNG_YS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getProgramActivitiesApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_PROGRAM_ACTIVITIES(AssociationNumber, null),
      successAction: data => ({
        type: types.PROGRAM_ACTIVITIES,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getProgramActivityHousingApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_HOUSING(AssociationNumber, null),
      successAction: data => ({
        type: types.PROGRAM_ACTIVITIES_HOUSING,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getProgramActivityFoodProgramApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_FOOD_PROGRAM(AssociationNumber, null),
      successAction: data => ({
        type: types.PROGRAM_ACTIVITIES_FOODPROGRAMS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getProgramActivityArmedServiceApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_ARMED_SERVICES(AssociationNumber, null),
      successAction: data => ({
        type: types.PROGRAM_ACTIVITIES_ARMED_SERVICES,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getProgramActivityOlderAdultApi =
  AssociationNumber => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_GET_OLDER_ADULTS(AssociationNumber, null),
      successAction: data => ({
        type: types.PROGRAM_ACTIVITIES_OLDER_ADULTS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.YMCA_BRANCHES_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });
