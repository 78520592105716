import * as types from './types';

const initialState = {
  SwimAssociationDashboardCount: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SWIMSAFETY_DASHBOARD_COUNT_SUCCESS: {
      return {
        ...state,
        SwimAssociationDashboardCount: action.payload
      };
    }
    case types.SWIMSAFETY_DASHBOARD_COUNT_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
