import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';
import {
  getSwimSafetyProfileDelete,
  getSwimSafetyProfileDetails,
  getSwimSafetyProfilesByFilters
} from '@redux/siwm-safety/swim-profiles/actions';
import { getSwimSafetyProgramsDetails } from '@redux/siwm-safety/swim-programs/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import CustomModal from '@utilities/CustomModal';
import { PROFILE_COLUMNS } from '../columns';
import Table from './table';
import ViewDetailsPopup from './view-details-popup';

const ProfileTable = props => {
  const {
    setLoader,
    handleGetProfileDelete,
    getSwimProfile,
    getSwimProgram,
    recallCount,
    startDate,
    endDate,
    showAll,
    isFilterActive,
    handleProfilesByFilters,
    isFilterClear,
    LoginUserData
  } = props;
  const Offset = new Date().getTimezoneOffset();
  const [profileList, setProfileList] = useState([]);
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [programDeletId, setProgramDeletId] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [programData, setProgramData] = useState({});
  const handleCloseAttentionPopup = () => setShowAttentionPopup(false);
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber = get(associateData, 'associationNumber', '');

  const getSwimProgramDetails = programId => {
    getSwimProgram(programId).then(result => {
      if (result?.data?.data) {
        setLoader(false);
        setProgramData({ program: result?.data?.data });
        setShowPopup(true);
      }
    });
  };

  const getSwimProfileDetails = profileId => {
    setLoader(true);
    getSwimProfile(profileId).then(result => {
      if (result?.data?.data) {
        const programId = result?.data?.data[0]?.programId;
        getSwimProgramDetails(programId);
        setSelectedProfile(result?.data?.data[0]);
      }
    });
  };

  const profileListAPICall = () => {
    setLoader(true);
    handleProfilesByFilters(
      startDate,
      endDate,
      Offset,
      showAll,
      associationNumber
    )
      .then(result => {
        if (result?.data?.data) {
          setProfileList(result?.data?.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleDeletePopup = id => {
    setProgramDeletId(id);
    setShowAttentionPopup(true);
  };

  const deletePrograms = () => {
    setLoader(true);
    handleGetProfileDelete(programDeletId)
      .then(() => {
        setProgramDeletId('');
        setShowAttentionPopup(false);
        profileListAPICall();
        recallCount();
      })
      .catch(() => {
        setLoader(false);
        setProgramDeletId('');
        setShowAttentionPopup(false);
      });
  };

  const profileData = data => {
    sessionStorage.setItem('selected_profile', JSON.stringify(data));
    sessionStorage.setItem(
      'swim_profile_redirect_url',
      '/swim/association/dashboard'
    );
  };

  const getProfileList = list =>
    list.map(data => {
      const addSwimLocation = {
        pathname: '/swim/safety/add/profiles',
        state: data
      };
      return {
        ...data,
        details: (
          <>
            <button
              type="button"
              onClick={() => {
                getSwimProfileDetails(data?.id);
              }}
              className="table-link swim-viewdetails-btn btn"
            >
              {APP_LABELS.DASH_VIEW_DETAILS}
            </button>
            {!APP_LABELS.VIEWER_PERMISSION_ROLES.includes(role) && (
              <>
                <span className="swim-safety-action-saperator">|</span>
                <Link
                  to={addSwimLocation}
                  className="table-link"
                  onClick={() => profileData(data)}
                >
                  Edit
                </Link>
                <span className="swim-safety-action-saperator">|</span>
                <button
                  type="button"
                  className="swim-safety-action"
                  data-bs-toggle="modal"
                  data-bs-target="#rejectModal"
                  onClick={() => handleDeletePopup(data.id)}
                >
                  <span className="ymca-delete-text">Delete</span>
                </button>
              </>
            )}
          </>
        )
      };
    });

  useEffect(() => {
    if (!isFilterActive) {
      profileListAPICall();
    }
  }, [isFilterClear]);

  useEffect(() => {
    if (isFilterActive) {
      setLoader(true);
      handleProfilesByFilters(
        startDate,
        endDate,
        Offset,
        showAll,
        associationNumber
      )
        .then(result => {
          if (result?.data?.data) {
            setLoader(false);
            setProfileList(result?.data?.data);
          }
          return setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [isFilterActive]);

  return (
    <>
      <Table
        rows={getProfileList(profileList)}
        headers={PROFILE_COLUMNS()}
        title="Profiles"
        showCount
        role={role}
      />
      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message="Are you sure want to delete?"
        submitButtonName="Yes"
        onSubmit={deletePrograms}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={handleCloseAttentionPopup}
      />
      <ViewDetailsPopup
        showViewDetailsPopup={showPopup}
        handleCloseNewStaffPopup={() => setShowPopup(false)}
        popupTitle="Profile Details"
        popupData={{
          profileDetails: selectedProfile,
          programDeatils: programData
        }}
      />
    </>
  );
};

ProfileTable.propTypes = {
  handleGetProfileDelete: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  getSwimProfile: PropTypes.func.isRequired,
  getSwimProgram: PropTypes.func.isRequired,
  recallCount: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  showAll: PropTypes.bool.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  handleProfilesByFilters: PropTypes.func.isRequired,
  isFilterClear: PropTypes.bool.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetProfileDelete: getSwimSafetyProfileDelete,
  getSwimProfile: getSwimSafetyProfileDetails,
  getSwimProgram: getSwimSafetyProgramsDetails,
  handleProfilesByFilters: getSwimSafetyProfilesByFilters
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTable);
