
export const GET_PROGRAM_ENROLLMENTS_SUCCESS =
  'GET_PROGRAM_ENROLLMENTS_SUCCESS';
export const GET_PROGRAM_ENROLLMENTS_FAILURE =
  'GET_PROGRAM_ENROLLMENTS_FAILURE';

export const POST_PROGRAM_ENROLLMENTS_SUCCESS =
  'POST_PROGRAM_ENROLLMENTS_SUCCESS';
export const POST_PROGRAM_ENROLLMENTS_FAILURE =
  'POST_PROGRAM_ENROLLMENTS_FAILURE';
