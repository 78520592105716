/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import {
  getProgramEnrollments,
  postProgramEnrollments,
  resetReduxState
} from '@redux/constituency-report/program-enrollments/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetProgramEnrollmentsSelector } from '@redux/constituency-report/program-enrollments/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import {
  APP_LABELS,
  VALIDATION_MESSAGES,
  FIELD_LENGTH
} from '@config/app-config';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import TextError from '@modules/shared/components/reusable-components/text-error';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const ProgramEnrollmentsTab = props => {
  const {
    setLoader,
    GetProgramEnrollmentsSelectorData,
    LoginUserData,
    handleGetProgramEnrollments,
    handlePostProgramEnrollments,
    status,
    handleGetAnnualReportsDashboard,
    handleResetRedux
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [resStatus, setResStatus] = useState();
  const formikref = useRef();
  const sectionName = 'Constituency Report';
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = success => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');

    handleGetProgramEnrollments(associationsNum).then(res => {
      if (res.status === 200) {
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))[
        'program-enrollments'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetProgramEnrollments');
    };
  }, []);

  const initialValues = {
    totChildBabySittingDuplicate: '',
    totChildBabySittingUnDuplicate: '',
    totCorporateChildCareDuplicate: '',
    totCorporateChildCareUnDuplicate: '',
    totPreschoolCareDuplicate: '',
    totPreschoolCareUnDuplicate: '',
    totHeadStartEarlyHeadStartDuplicate: '',
    totHeadStartEarlyHeadStartUnDuplicate: '',
    totEarlyChildhoodDuplicate: '',
    totEarlyChildhoodUnDuplicate: '',
    totBfrAftrShoolProgramDuplicate: '',
    totBfrAftrShoolProgramUnDuplicate: '',
    totSummerDayCampDuplicate: '',
    totSummerDayCampUnDuplicate: '',
    totNonSummerDayCampDuplicate: '',
    totNonSummerDayCampUnDuplicate: '',
    totOvrnghtSummerCampResidentDuplicate: '',
    totOvrnghtSummerCampResidentUnDuplicate: '',
    totNonSummerResidentOvrnghtCampDuplicate: '',
    totNonSummerResidentOvrnghtCampUnDuplicate: '',
    totSwimLessonsDuplicate: '',
    totSwimLessonsUnDuplicate: '',
    totYouthSportsDuplicate: '',
    totYouthSportsUnDuplicate: ''
  };

  const editValues = {
    totChildBabySittingDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totChildBabySittingDuplicate,
    totChildBabySittingUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totChildBabySittingUnDuplicate,
    totCorporateChildCareDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totCorporateChildCareDuplicate,
    totCorporateChildCareUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totCorporateChildCareUnDuplicate,
    totPreschoolCareDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totPreschoolCareDuplicate,
    totPreschoolCareUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totPreschoolCareUnDuplicate,
    totHeadStartEarlyHeadStartDuplicate:
      GetProgramEnrollmentsSelectorData?.data
        ?.totHeadStartEarlyHeadStartDuplicate,
    totHeadStartEarlyHeadStartUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data
        ?.totHeadStartEarlyHeadStartUnDuplicate,
    totEarlyChildhoodDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totEarlyChildhoodDuplicate,
    totEarlyChildhoodUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totEarlyChildhoodUnDuplicate,
    totBfrAftrShoolProgramDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totBfrAftrShoolProgramDuplicate,
    totBfrAftrShoolProgramUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data
        ?.totBfrAftrShoolProgramUnDuplicate,
    totSummerDayCampDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totSummerDayCampDuplicate,
    totSummerDayCampUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totSummerDayCampUnDuplicate,
    totNonSummerDayCampDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totNonSummerDayCampDuplicate,
    totNonSummerDayCampUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totNonSummerDayCampUnDuplicate,
    totOvrnghtSummerCampResidentDuplicate:
      GetProgramEnrollmentsSelectorData?.data
        ?.totOvrnghtSummerCampResidentDuplicate,
    totOvrnghtSummerCampResidentUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data
        ?.totOvrnghtSummerCampResidentUnDuplicate,
    totNonSummerResidentOvrnghtCampDuplicate:
      GetProgramEnrollmentsSelectorData?.data
        ?.totNonSummerResidentOvrnghtCampDuplicate,
    totNonSummerResidentOvrnghtCampUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data
        ?.totNonSummerResidentOvrnghtCampUnDuplicate,
    totSwimLessonsDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totSwimLessonsDuplicate,
    totSwimLessonsUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totSwimLessonsUnDuplicate,
    totYouthSportsDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totYouthSportsDuplicate,
    totYouthSportsUnDuplicate:
      GetProgramEnrollmentsSelectorData?.data?.totYouthSportsUnDuplicate
  };

  const validationSchema = Yup.object({
    totChildBabySittingDuplicate: Yup.string().nullable(),
    totChildBabySittingUnDuplicate: Yup.string().nullable(),
    totCorporateChildCareDuplicate: Yup.string().nullable(),
    totCorporateChildCareUnDuplicate: Yup.string().nullable(),
    totPreschoolCareDuplicate: Yup.string().nullable(),
    totPreschoolCareUnDuplicate: Yup.string().nullable(),
    totHeadStartEarlyHeadStartDuplicate: Yup.string().nullable(),
    totHeadStartEarlyHeadStartUnDuplicate: Yup.string().nullable(),
    totEarlyChildhoodDuplicate: Yup.string().nullable(),
    totEarlyChildhoodUnDuplicate: Yup.string().nullable(),
    totBfrAftrShoolProgramDuplicate: Yup.string().nullable(),
    totBfrAftrShoolProgramUnDuplicate: Yup.string().nullable(),
    totSummerDayCampDuplicate: Yup.string().nullable(),
    totSummerDayCampUnDuplicate: Yup.string().nullable(),
    totNonSummerDayCampDuplicate: Yup.string().nullable(),
    totNonSummerDayCampUnDuplicate: Yup.string().nullable(),
    totOvrnghtSummerCampResidentDuplicate: Yup.string().nullable(),
    totOvrnghtSummerCampResidentUnDuplicate: Yup.string().nullable(),
    totNonSummerResidentOvrnghtCampDuplicate: Yup.string().nullable(),
    totNonSummerResidentOvrnghtCampUnDuplicate: Yup.string().nullable(),
    totSwimLessonsDuplicate: Yup.string().nullable(),
    totSwimLessonsUnDuplicate: Yup.string().nullable(),
    totYouthSportsDuplicate: Yup.string().nullable(),
    totYouthSportsUnDuplicate: Yup.string().nullable()
  });

  let isValidForm = false;
  const CheckValidation = (values, setFieldError) => {
    if (
      values.totChildBabySittingDuplicate !== '' &&
      values.totChildBabySittingUnDuplicate !== '' &&
      values.totChildBabySittingDuplicate !== null &&
      values.totChildBabySittingUnDuplicate !== null
    ) {
      if (
        Number(values.totChildBabySittingDuplicate) !== 0 &&
        Number(values.totChildBabySittingDuplicate) <
          Number(values.totChildBabySittingUnDuplicate)
      ) {
        window.scrollTo(0, 600);
        isValidForm = true;
        setFieldError(
          'totChildBabySittingDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totCorporateChildCareDuplicate !== '' &&
      values.totCorporateChildCareUnDuplicate !== '' &&
      values.totCorporateChildCareDuplicate !== null &&
      values.totCorporateChildCareUnDuplicate !== null
    ) {
      if (
        Number(values.totCorporateChildCareDuplicate) !== 0 &&
        Number(values.totCorporateChildCareDuplicate) <
          Number(values.totCorporateChildCareUnDuplicate)
      ) {
        window.scrollTo(0, 600);
        isValidForm = true;
        setFieldError(
          'totCorporateChildCareDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totPreschoolCareDuplicate !== '' &&
      values.totPreschoolCareUnDuplicate !== '' &&
      values.totPreschoolCareDuplicate !== null &&
      values.totPreschoolCareUnDuplicate !== null
    ) {
      if (
        Number(values.totPreschoolCareDuplicate) !== 0 &&
        Number(values.totPreschoolCareDuplicate) <
          Number(values.totPreschoolCareUnDuplicate)
      ) {
        window.scrollTo(0, 600);
        isValidForm = true;
        setFieldError(
          'totPreschoolCareDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totHeadStartEarlyHeadStartDuplicate !== '' &&
      values.totHeadStartEarlyHeadStartUnDuplicate !== '' &&
      values.totHeadStartEarlyHeadStartDuplicate !== null &&
      values.totHeadStartEarlyHeadStartUnDuplicate !== null
    ) {
      if (
        Number(values.totHeadStartEarlyHeadStartDuplicate) !== 0 &&
        Number(values.totHeadStartEarlyHeadStartDuplicate) <
          Number(values.totHeadStartEarlyHeadStartUnDuplicate)
      ) {
        window.scrollTo(0, 1000);
        isValidForm = true;
        setFieldError(
          'totHeadStartEarlyHeadStartDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totEarlyChildhoodDuplicate !== '' &&
      values.totEarlyChildhoodUnDuplicate !== '' &&
      values.totEarlyChildhoodDuplicate !== null &&
      values.totEarlyChildhoodUnDuplicate !== null
    ) {
      if (
        Number(values.totEarlyChildhoodDuplicate) !== 0 &&
        Number(values.totEarlyChildhoodDuplicate) <
          Number(values.totEarlyChildhoodUnDuplicate)
      ) {
        window.scrollTo(0, 1000);
        isValidForm = true;
        setFieldError(
          'totEarlyChildhoodDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totBfrAftrShoolProgramDuplicate !== '' &&
      values.totBfrAftrShoolProgramUnDuplicate !== '' &&
      values.totBfrAftrShoolProgramDuplicate !== null &&
      values.totBfrAftrShoolProgramUnDuplicate !== null
    ) {
      if (
        Number(values.totBfrAftrShoolProgramDuplicate) !== 0 &&
        Number(values.totBfrAftrShoolProgramDuplicate) <
          Number(values.totBfrAftrShoolProgramUnDuplicate)
      ) {
        window.scrollTo(0, 1000);
        isValidForm = true;
        setFieldError(
          'totBfrAftrShoolProgramDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totSummerDayCampDuplicate !== '' &&
      values.totSummerDayCampUnDuplicate !== '' &&
      values.totSummerDayCampDuplicate !== null &&
      values.totSummerDayCampUnDuplicate !== null
    ) {
      if (
        Number(values.totSummerDayCampDuplicate) !== 0 &&
        Number(values.totSummerDayCampDuplicate) <
          Number(values.totSummerDayCampUnDuplicate)
      ) {
        window.scrollTo(0, 1550);
        isValidForm = true;
        setFieldError(
          'totSummerDayCampDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totNonSummerDayCampDuplicate !== '' &&
      values.totNonSummerDayCampUnDuplicate !== '' &&
      values.totNonSummerDayCampDuplicate !== null &&
      values.totNonSummerDayCampUnDuplicate !== null
    ) {
      if (
        Number(values.totNonSummerDayCampDuplicate) !== 0 &&
        Number(values.totNonSummerDayCampDuplicate) <
          Number(values.totNonSummerDayCampUnDuplicate)
      ) {
        window.scrollTo(0, 1550);
        isValidForm = true;
        setFieldError(
          'totNonSummerDayCampDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totOvrnghtSummerCampResidentDuplicate !== '' &&
      values.totOvrnghtSummerCampResidentUnDuplicate !== '' &&
      values.totOvrnghtSummerCampResidentDuplicate !== null &&
      values.totOvrnghtSummerCampResidentUnDuplicate !== null
    ) {
      if (
        Number(values.totOvrnghtSummerCampResidentDuplicate) !== 0 &&
        Number(values.totOvrnghtSummerCampResidentDuplicate) <
          Number(values.totOvrnghtSummerCampResidentUnDuplicate)
      ) {
        window.scrollTo(0, 1550);
        isValidForm = true;
        setFieldError(
          'totOvrnghtSummerCampResidentDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totNonSummerResidentOvrnghtCampDuplicate !== '' &&
      values.totNonSummerResidentOvrnghtCampUnDuplicate !== '' &&
      values.totNonSummerResidentOvrnghtCampDuplicate !== null &&
      values.totNonSummerResidentOvrnghtCampUnDuplicate !== null
    ) {
      if (
        Number(values.totNonSummerResidentOvrnghtCampDuplicate) !== 0 &&
        Number(values.totNonSummerResidentOvrnghtCampDuplicate) <
          Number(values.totNonSummerResidentOvrnghtCampUnDuplicate)
      ) {
        window.scrollTo(0, 1550);
        isValidForm = true;
        setFieldError(
          'totNonSummerResidentOvrnghtCampDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totSwimLessonsDuplicate !== '' &&
      values.totSwimLessonsUnDuplicate !== '' &&
      values.totSwimLessonsDuplicate !== null &&
      values.totSwimLessonsUnDuplicate !== null
    ) {
      if (
        Number(values.totSwimLessonsDuplicate) !== 0 &&
        Number(values.totSwimLessonsDuplicate) <
          Number(values.totSwimLessonsUnDuplicate)
      ) {
        window.scrollTo(0, 1900);
        isValidForm = true;
        setFieldError(
          'totSwimLessonsDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }

    if (
      values.totYouthSportsDuplicate !== '' &&
      values.totYouthSportsUnDuplicate !== '' &&
      values.totYouthSportsDuplicate !== null &&
      values.totYouthSportsUnDuplicate !== null
    ) {
      if (
        Number(values.totYouthSportsDuplicate) !== 0 &&
        Number(values.totYouthSportsDuplicate) <
          Number(values.totYouthSportsUnDuplicate)
      ) {
        window.scrollTo(0, 1900);
        isValidForm = true;
        setFieldError(
          'totYouthSportsDuplicate',
          VALIDATION_MESSAGES.PROGRAM_ENROLLMENTS
        );
      }
    }
  };

  let isNull = false;
  const CheckNull = values => {
    if (
      values.totChildBabySittingDuplicate === null ||
      values.totChildBabySittingUnDuplicate === null ||
      values.totCorporateChildCareDuplicate === null ||
      values.totCorporateChildCareUnDuplicate === null ||
      values.totPreschoolCareDuplicate === null ||
      values.totPreschoolCareUnDuplicate === null ||
      values.totHeadStartEarlyHeadStartDuplicate === null ||
      values.totHeadStartEarlyHeadStartUnDuplicate === null ||
      values.totEarlyChildhoodDuplicate === null ||
      values.totEarlyChildhoodUnDuplicate === null ||
      values.totBfrAftrShoolProgramDuplicate === null ||
      values.totBfrAftrShoolProgramUnDuplicate === null ||
      values.totSummerDayCampDuplicate === null ||
      values.totSummerDayCampUnDuplicate === null ||
      values.totNonSummerDayCampDuplicate === null ||
      values.totNonSummerDayCampUnDuplicate === null ||
      values.totOvrnghtSummerCampResidentDuplicate === null ||
      values.totOvrnghtSummerCampResidentUnDuplicate === null ||
      values.totNonSummerResidentOvrnghtCampDuplicate === null ||
      values.totNonSummerResidentOvrnghtCampUnDuplicate === null ||
      values.totSwimLessonsDuplicate === null ||
      values.totSwimLessonsUnDuplicate === null ||
      values.totYouthSportsDuplicate === null ||
      values.totYouthSportsUnDuplicat === null ||
      values.totChildBabySittingDuplicate === '' ||
      values.totChildBabySittingUnDuplicate === '' ||
      values.totCorporateChildCareDuplicate === '' ||
      values.totCorporateChildCareUnDuplicate === '' ||
      values.totPreschoolCareDuplicate === '' ||
      values.totPreschoolCareUnDuplicate === '' ||
      values.totHeadStartEarlyHeadStartDuplicate === '' ||
      values.totHeadStartEarlyHeadStartUnDuplicate === '' ||
      values.totEarlyChildhoodDuplicate === '' ||
      values.totEarlyChildhoodUnDuplicate === '' ||
      values.totBfrAftrShoolProgramDuplicate === '' ||
      values.totBfrAftrShoolProgramUnDuplicate === '' ||
      values.totSummerDayCampDuplicate === '' ||
      values.totSummerDayCampUnDuplicate === '' ||
      values.totNonSummerDayCampDuplicate === '' ||
      values.totNonSummerDayCampUnDuplicate === '' ||
      values.totOvrnghtSummerCampResidentDuplicate === '' ||
      values.totOvrnghtSummerCampResidentUnDuplicate === '' ||
      values.totNonSummerResidentOvrnghtCampDuplicate === '' ||
      values.totNonSummerResidentOvrnghtCampUnDuplicate === '' ||
      values.totSwimLessonsDuplicate === '' ||
      values.totSwimLessonsUnDuplicate === '' ||
      values.totYouthSportsDuplicate === '' ||
      values.totYouthSportsUnDuplicate === ''
    ) {
      isNull = true;
    }
  };

  const preparePayload = values => ({
    associationNumber: String(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    ),
    createdByName: GetProgramEnrollmentsSelectorData?.data?.createdByName,
    createdByPersonId:
      GetProgramEnrollmentsSelectorData?.data?.createdByPersonId,
    createdDatetime: GetProgramEnrollmentsSelectorData?.data?.createdDatetime,
    year: sessionStorage.getItem('selectedBatchYear'),
    programEnrollmentId:
      GetProgramEnrollmentsSelectorData?.data?.programEnrollmentId,
    reportBatchId: GetProgramEnrollmentsSelectorData?.data?.reportBatchId,
    recordStatus: GetProgramEnrollmentsSelectorData?.data?.recordStatus,
    totChildBabySittingDuplicate: Number(values.totChildBabySittingDuplicate),
    totChildBabySittingUnDuplicate: Number(
      values.totChildBabySittingUnDuplicate
    ),
    totCorporateChildCareDuplicate: Number(
      values.totCorporateChildCareDuplicate
    ),
    totCorporateChildCareUnDuplicate: Number(
      values.totCorporateChildCareUnDuplicate
    ),
    totPreschoolCareDuplicate: Number(values.totPreschoolCareDuplicate),
    totPreschoolCareUnDuplicate: Number(values.totPreschoolCareUnDuplicate),
    totHeadStartEarlyHeadStartDuplicate: Number(
      values.totHeadStartEarlyHeadStartDuplicate
    ),
    totHeadStartEarlyHeadStartUnDuplicate: Number(
      values.totHeadStartEarlyHeadStartUnDuplicate
    ),
    totEarlyChildhoodDuplicate: Number(values.totEarlyChildhoodDuplicate),
    totEarlyChildhoodUnDuplicate: Number(values.totEarlyChildhoodUnDuplicate),
    totBfrAftrShoolProgramDuplicate: Number(
      values.totBfrAftrShoolProgramDuplicate
    ),
    totBfrAftrShoolProgramUnDuplicate: Number(
      values.totBfrAftrShoolProgramUnDuplicate
    ),
    totSummerDayCampDuplicate: Number(values.totSummerDayCampDuplicate),
    totSummerDayCampUnDuplicate: Number(values.totSummerDayCampUnDuplicate),
    totNonSummerDayCampDuplicate: Number(values.totNonSummerDayCampDuplicate),
    totNonSummerDayCampUnDuplicate: Number(
      values.totNonSummerDayCampUnDuplicate
    ),
    totOvrnghtSummerCampResidentDuplicate: Number(
      values.totOvrnghtSummerCampResidentDuplicate
    ),
    totOvrnghtSummerCampResidentUnDuplicate: Number(
      values.totOvrnghtSummerCampResidentUnDuplicate
    ),
    totNonSummerResidentOvrnghtCampDuplicate: Number(
      values.totNonSummerResidentOvrnghtCampDuplicate
    ),
    totNonSummerResidentOvrnghtCampUnDuplicate: Number(
      values.totNonSummerResidentOvrnghtCampUnDuplicate
    ),
    totSwimLessonsDuplicate: Number(values.totSwimLessonsDuplicate),
    totSwimLessonsUnDuplicate: Number(values.totSwimLessonsUnDuplicate),
    totYouthSportsDuplicate: Number(values.totYouthSportsDuplicate),
    totYouthSportsUnDuplicate: Number(values.totYouthSportsUnDuplicate)
  });

  const setFormDetails = dashData =>
    dashData?.data?.data?.dashboardDTOs.forEach(record => {
      if (
        record.name === sectionName &&
        record.status === 'Completed' &&
        record.rating === null &&
        selectedYear >= '2023' &&
        role !== APP_LABELS.YUSA_DATA_STEWARD &&
        role !== APP_LABELS.YESS_DATA_STEWARD
      ) {
        setFeedbackPopup(true);
        setFormId(record?.formId);
      }
    });

  const onSubmit = (values, { setFieldError }) => {
    isValidForm = false;
    isNull = false;
    setLoader(true);
    const payloadBody = preparePayload(values);
    CheckValidation(values, setFieldError);
    CheckNull(values);
    setSuccessPopup(false);
    if (!isValidForm) {
      if (!isNull) {
        handlePostProgramEnrollments(payloadBody).then(res => {
          if (res.status === 200 && res?.data?.isSuccess) {
            setResStatus(res.data.data?.recordStatus);
            handleGetAnnualReportsDashboard(
              associationNumber,
              selectedYear
            ).then(dashData => {
              setFormDetails(dashData);
            });
            handelGetCall(true);
            setLoader(false);
          } else {
            setLoader(false);
            setErrorMessage(res?.message);
            setErrorPopup(true);
          }
        });
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 pb-0">
        <div className="ymca-instructionBoxes mb-3">
          <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
          <p className="ymca-instructTitle">
            <div
              dangerouslySetInnerHTML={{
                __html: instructions
              }}
            />
          </p>
        </div>
      </div>
      {GetProgramEnrollmentsSelectorData?.data?.recordStatus?.toUpperCase() ===
        APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
      <div className="row">
        <div className="col-xl-6 col-md-6 col-sm-12 col-12" />
        <div className="col-xl-6 col-md-6 col-sm-12 col-12">
          <div className="ymca-dataTransation">
            <table className="table ymca-table  ">
              <tbody>
                <tr>
                  <td colSpan="2">Total Enrollments</td>
                </tr>
                <tr>
                  <td>
                    Total Participants (Duplicated)
                    <br /> Member & Non-Member
                  </td>
                  <td>
                    Total Participants (Unduplicated)
                    <br /> Member & Non-Member
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, errors, touched, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-tableResponsive mb-4">
                <table className="table ymca-table table-striped ymca-autoLength">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Child Watch/Babysitting</th>
                      <th />
                      <th />
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody ymca-lengths">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Short - term child drop off service, usually offered
                        free to members and located onsite at a Y's Health &
                        Wellness center.
                      </td>
                      <td colSpan="3" className="ymca-fieldLine">
                        <Field
                          name="totChildBabySittingDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totChildBabySittingDuplicate &&
                            touched.totChildBabySittingDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totChildBabySittingDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totChildBabySittingDuplicate"
                        />
                      </td>
                      <td colSpan="3" className="ymca-fieldLine">
                        <Field
                          name="totChildBabySittingUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totChildBabySittingUnDuplicate &&
                            touched.totChildBabySittingUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totChildBabySittingUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totChildBabySittingUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData bg-white">
                      <th colSpan="5">Corporate Child Care</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Childcare sponsored by an employer
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totCorporateChildCareDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totCorporateChildCareDuplicate &&
                            touched.totCorporateChildCareDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totCorporateChildCareDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totCorporateChildCareDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totCorporateChildCareUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totCorporateChildCareUnDuplicate &&
                            touched.totCorporateChildCareUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totCorporateChildCareUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totCorporateChildCareUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData bg-white">
                      <th colSpan="5">Preschool Care</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        (Typically for ages 3-5) - Does not include early head
                        start or other FULLY subsidized state or federally
                        funded programs.
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totPreschoolCareDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totPreschoolCareDuplicate &&
                            touched.totPreschoolCareDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totPreschoolCareDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totPreschoolCareDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totPreschoolCareUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totPreschoolCareUnDuplicate &&
                            touched.totPreschoolCareUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totPreschoolCareUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totPreschoolCareUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Head Start and/or Early Head Start</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Government funded programming that serves infants,
                        toddlers, children and their families.
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totHeadStartEarlyHeadStartDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totHeadStartEarlyHeadStartDuplicate &&
                            touched.totHeadStartEarlyHeadStartDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totHeadStartEarlyHeadStartDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totHeadStartEarlyHeadStartDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totHeadStartEarlyHeadStartUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totHeadStartEarlyHeadStartUnDuplicate &&
                            touched.totHeadStartEarlyHeadStartUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totHeadStartEarlyHeadStartUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totHeadStartEarlyHeadStartUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Early Childhood</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        (Typically for ages (1-2) - Does not include early head
                        start or other FULLY subsidized state or federally
                        funded programs.
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totEarlyChildhoodDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totEarlyChildhoodDuplicate &&
                            touched.totEarlyChildhoodDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totEarlyChildhoodDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totEarlyChildhoodDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totEarlyChildhoodUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totEarlyChildhoodUnDuplicate &&
                            touched.totEarlyChildhoodUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totEarlyChildhoodUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totEarlyChildhoodUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Before and/or After school Program</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Programming offered where children are signed up to
                        participate in before and/or after school youth
                        activities traditionally held at the YMCA facility or in
                        another off-site location
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totBfrAftrShoolProgramDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totBfrAftrShoolProgramDuplicate &&
                            touched.totBfrAftrShoolProgramDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totBfrAftrShoolProgramDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totBfrAftrShoolProgramDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totBfrAftrShoolProgramUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totBfrAftrShoolProgramUnDuplicate &&
                            touched.totBfrAftrShoolProgramUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totBfrAftrShoolProgramUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totBfrAftrShoolProgramUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Summer Day Camp</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        {`Structured program occurring during summer months usually "June - August"; targeted towards youth and teen (this does not include any other structured programs that are offered during the summer session).`}
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totSummerDayCampDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totSummerDayCampDuplicate &&
                            touched.totSummerDayCampDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totSummerDayCampDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totSummerDayCampDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totSummerDayCampUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totSummerDayCampUnDuplicate &&
                            touched.totSummerDayCampUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totSummerDayCampUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totSummerDayCampUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Non-summer Daycamp</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        {`These day camps are usually offered "during out of school time"; during school hours (Often associated with holiday breaks, spring breaks, school closings, etc.).`}
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totNonSummerDayCampDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totNonSummerDayCampDuplicate &&
                            touched.totNonSummerDayCampDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totNonSummerDayCampDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totNonSummerDayCampDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totNonSummerDayCampUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totNonSummerDayCampUnDuplicate &&
                            touched.totNonSummerDayCampUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totNonSummerDayCampUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totNonSummerDayCampUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Overnight Camp/Summer Resident
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totOvrnghtSummerCampResidentDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totOvrnghtSummerCampResidentDuplicate &&
                            touched.totOvrnghtSummerCampResidentDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totOvrnghtSummerCampResidentDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totOvrnghtSummerCampResidentDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totOvrnghtSummerCampResidentUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totOvrnghtSummerCampResidentUnDuplicate &&
                            touched.totOvrnghtSummerCampResidentUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totOvrnghtSummerCampResidentUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totOvrnghtSummerCampResidentUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Non-Summer Resident/Overnight Camp - Conference Retreat
                        Participants
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totNonSummerResidentOvrnghtCampDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totNonSummerResidentOvrnghtCampDuplicate &&
                            touched.totNonSummerResidentOvrnghtCampDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totNonSummerResidentOvrnghtCampDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totNonSummerResidentOvrnghtCampDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totNonSummerResidentOvrnghtCampUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totNonSummerResidentOvrnghtCampUnDuplicate &&
                            touched.totNonSummerResidentOvrnghtCampUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totNonSummerResidentOvrnghtCampUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totNonSummerResidentOvrnghtCampUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Youth Swimming Lessons</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Formal aquatic lessons that usually consist of drowning
                        prevention, competitive swimming, private swimming
                        lessons, etc.
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totSwimLessonsDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totSwimLessonsDuplicate &&
                            touched.totSwimLessonsDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totSwimLessonsDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totSwimLessonsDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totSwimLessonsUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totSwimLessonsUnDuplicate &&
                            touched.totSwimLessonsUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totSwimLessonsUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totSwimLessonsUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table ymca-table table-striped">
                  <thead>
                    <tr className="ymca-cuttingData">
                      <th colSpan="5">Youth Sports</th>
                    </tr>
                  </thead>
                  <tbody className="ymca-cuttingBody">
                    <tr>
                      <td>&nbsp;</td>
                      <td className="ymca-normalWrap w-50 txt-mandatory">
                        Organized sports programs and/or Leagues usually offered
                        to youth and teens (Volleyball, Basketball, Soccer,
                        etc.) focused on skill and character development.
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totYouthSportsDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totYouthSportsDuplicate &&
                            touched.totYouthSportsDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totYouthSportsDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totYouthSportsDuplicate"
                        />
                      </td>
                      <td className="ymca-fieldLine">
                        <Field
                          name="totYouthSportsUnDuplicate"
                          type="text"
                          maxLength={FIELD_LENGTH.TEXTBOX_LENGTH}
                          className={
                            errors.totYouthSportsUnDuplicate &&
                            touched.totYouthSportsUnDuplicate
                              ? 'ymca-field invalid'
                              : 'ymca-field'
                          }
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              'totYouthSportsUnDuplicate'
                            )
                          }
                        />
                        <ErrorMessage
                          component={TextError}
                          name="totYouthSportsUnDuplicate"
                        />
                      </td>
                      <td className="ymca-defaultSpace">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={
                    GetProgramEnrollmentsSelectorData?.data?.updatedByName
                  }
                  Time={
                    GetProgramEnrollmentsSelectorData?.data?.updatedDatetime
                  }
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightUntitled float-end"
                    disabled={status?.toUpperCase() === 'SUBMITTED'}
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      {help && <Help hidePopup={hideShowHelp} keyValue="program-enrollments" />}
    </div>
  );
};
ProgramEnrollmentsTab.propTypes = {
  handleGetProgramEnrollments: PropTypes.func.isRequired,
  handlePostProgramEnrollments: PropTypes.func.isRequired,
  GetProgramEnrollmentsSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};
const mapDispatchToProps = {
  handleGetProgramEnrollments: getProgramEnrollments,
  handlePostProgramEnrollments: postProgramEnrollments,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};
const mapStateToProps = state => ({
  GetProgramEnrollmentsSelectorData: GetProgramEnrollmentsSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ProgramEnrollmentsTab));
