import * as types from './types';

const initialState = {
  GetHousing: {},
  Housing: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.HOUSING_GET_SUCCESS: {
      return {
        ...state,
        GetHousing: action.payload
      };
    }
    case types.HOUSING_GET_FAILURE:
      return { ...state, error: action.payload };
    case types.HOUSING_SUCCESS: {
      return {
        ...state,
        Housing: action.payload
      };
    }
    case types.HOUSING_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
