import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import { Link, Prompt, Redirect, withRouter } from 'react-router-dom';

import { CDP_DETAIL_URLS } from '@config/api-config';
import { APP_LABELS, CDP, MESSAGES, STORAGE_KEYS } from '@config/app-config';
import {
  cdpFetchSelector,
  detailPageTypeSelector
} from '@redux/chronic-disease-program/details/selectors';
import {
  cdpDataFetchTypeSelector,
  cdpDataSelector
} from '@redux/chronic-disease-program/upload-data/selectors';
import {
  getCdpFetchDetailsAction,
  getCdpDataFetchAction,
  postRedCapImportAction,
  postSoleraImportAction
} from '@state/redux/chronic-disease-program/details/actions';
import SecureStorage from '@utilities/SecureStorage';

import PageContainer from '@modules/chronic-disease-program/page-container';
import CustomModal from '@utilities/CustomModal';
import useSessionStorage from '@hooks/useSessionStorage';
import Download from '@static/images/swimRegistration/download-red.svg';
import RedcapDetails from './redcap-details';
import DolDetails from './dol-details';
import Details from './details';

const CdpDetails = props => {
  const {
    cdpDataSelectorData,
    cdpFetchSelectorData,
    detailPageTypeSelectorData,
    cdpDataFetchTypeSelectorData,
    handlePostSoleraImportAction,
    handelPostRedCapImportAction,
    handelGetCdpFetchDetailsAction,
    handelGetCdpDataFetchAction
  } = props;

  const { cdpData } = cdpDataSelectorData;
  const { fetchType } = cdpDataFetchTypeSelectorData;
  const { detailPageType } = detailPageTypeSelectorData;
  const [showPrompt, setShowPrompt] = useState(true);
  const [toastStatus, setToastStatus] = useState({ status: '', message: '' });
  const [redirect, setRedirect] = useState(false);
  const [isConfirmCheck, setIsConfirmCheck] = useState(false);
  const [showConfirmCheckMsg, setShowConfirmCheckMsg] = useState(false);
  const [cdpDataFetch, setCdpDataFetch] = useState({});
  const [loader, setLoader] = useState(false);

  const [cdpDetailView, setCdpDetailView] = useState([]);

  const [navBlocker, setNavBlocker] = useState(false);
  const [dol, setDol] = useState({});
  const [association] = useSessionStorage(
    CDP.STORAGE_KEYS.ASSOCIATION_DATA,
    {}
  );

  const loadingMessage = MESSAGES.DEFAULT_LOADING_MESSAGE;
  const Id = SecureStorage.getItem(STORAGE_KEYS.FETCH_ID);

  const getFetchDetails = async cdpdata => {
    setLoader(true);
    await handelGetCdpFetchDetailsAction(
      cdpdata,
      fetchType,
      CDP.DATA_SYSTEMS_ENUM[fetchType],
      CDP.CDP_TYPE_ENUM[cdpdata.cdpDolType.typeName] ?? 0,
      CDP.PROGRAM_ENUM[cdpdata.program?.programName]
    );
    setLoader(false);
  };

  const getCdpDataFetch = async () => {
    setLoader(true);
    const response = await handelGetCdpDataFetchAction(Id);
    if (response && response.status === 200) {
      setCdpDataFetch(response.data);
      if (response.data.cdpDolType) {
        setDol(response.data.cdpDolType);
      }
      if (response.data.isImported) {
        setShowPrompt(false);
        setNavBlocker(false);
      }
      if (detailPageType === CDP.DETAIL_PAGE_TYPE.VIEW || 
          (detailPageType === CDP.DETAIL_PAGE_TYPE.FETCH && !cdpData)) {
        await getFetchDetails(response.data);
      }
    }
    setLoader(false);
  };

  useEffect(async () => {
    if (association?.associationNumber) {
      if (!Id) {
        props.history.push({
          pathname: '/cdp/dashboard'
        });
      }
      await getCdpDataFetch();
      if (detailPageType === CDP.DETAIL_PAGE_TYPE.FETCH && cdpData) {
        setCdpDetailView(cdpData);
      }
    } else {
      props.history.push({
        pathname: '/cdp/dashboard'
      });
    }
  }, []);

  useEffect(() => {
    if (detailPageType === CDP.DETAIL_PAGE_TYPE.VIEW || 
        (detailPageType === CDP.DETAIL_PAGE_TYPE.FETCH && !cdpData)) {
      setCdpDetailView(cdpFetchSelectorData.cdpFecthResult);
    }
  }, [cdpFetchSelectorData]);

  const loadDolComponent = () => (
    <DolDetails
      cdpDetailView={cdpDetailView}
      fetchType={fetchType}
      cdpDataFetch={cdpDataFetch}
      detailPageType={detailPageType}
      cdpDolType={dol?.typeName}
    />
  );

  const loadRedcapComponent = () => (
    <RedcapDetails
      cdpDetailView={cdpDetailView}
      fetchType={fetchType}
      cdpDataFetch={cdpDataFetch}
      detailPageType={detailPageType}
      programName={cdpDataFetch.program?.programName}
    />
  );

  const loadSoleraComponent = () => (
    <Details
      cdpDetailView={cdpDetailView}
      fetchType={fetchType}
      detailPageType={detailPageType}
      programName={cdpDataFetch.program?.programName}
    />
  );

  const loadComponent = () => {
    switch (fetchType) {
      case CDP.DATA_SYSTEM_NAMES.REDCAP:
        return loadRedcapComponent();

      case CDP.DATA_SYSTEM_NAMES.DOL:
        return loadDolComponent();

      case CDP.DATA_SYSTEM_NAMES.SOLERA:
      case CDP.DATA_SYSTEM_NAMES.OCHI:
      case CDP.DATA_SYSTEM_NAMES.HABITNU:
      case CDP.DATA_SYSTEM_NAMES.WELLD:
      case CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC:
      case CDP.DATA_SYSTEM_NAMES.ODES:
        return loadSoleraComponent();

      default:
        return <></>;
    }
  };

  const showStatus = response => {
    if (response.status === 200 && response.data) {
      if (response.status === 200 && response.data) {
        setShowPrompt(false);
        let seconds = 5;
        setToastStatus({
          status: APP_LABELS.SUCCESS,
          message: String.format(CDP.MESSAGES.importSuccess)
        });
        const countdownInterval = setInterval(() => {
          seconds -= 1;
          setToastStatus({
            status: APP_LABELS.SUCCESS,
            message: String.format(CDP.MESSAGES.importSuccess)
          });
          if (seconds === 0) {
            clearInterval(countdownInterval);
            setRedirect(true);
          }
        }, 1000);
      }
      if (Object.hasOwn(response, 'isSuccess') && !response.isSuccess) {
        setToastStatus({
          status: APP_LABELS.ERROR,
          message: APP_LABELS.ERROR_MESSAGE
        });
      }
    }
  };

  const onImportClick = async () => {
    if (!isConfirmCheck) {
      setShowConfirmCheckMsg(true);
      return;
    }
    setLoader(true);
    const payload = {
      dataSystem: CDP.DATA_SYSTEMS_ENUM[fetchType],
      programenum: CDP.PROGRAM_ENUM[cdpDataFetch.program?.programName],
      cdpDataFetchId: Id,
      programId: cdpDataFetch?.program.programId,
      dataSystemId: cdpDataFetch?.dataSystem.sourceSystemId,
      associationNumber: association?.associationNumber,
      CDPType: CDP.CDP_TYPE_ENUM[cdpDataFetch?.cdpDolType?.typeName]
    };
    let response;

    if (fetchType === CDP.DATA_SYSTEM_NAMES.REDCAP) {
      response = await handelPostRedCapImportAction(payload);
    } else if (
      fetchType === CDP.DATA_SYSTEM_NAMES.SOLERA ||
      fetchType === CDP.DATA_SYSTEM_NAMES.OCHI ||
      fetchType === CDP.DATA_SYSTEM_NAMES.HABITNU ||
      fetchType === CDP.DATA_SYSTEM_NAMES.DOL ||
      fetchType === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC ||
      fetchType === CDP.DATA_SYSTEM_NAMES.WELLD ||
      fetchType === CDP.DATA_SYSTEM_NAMES.ODES
    ) {
      response = await handlePostSoleraImportAction(payload);
    }
    sessionStorage.setItem(
      'selectedKPI',
      JSON.stringify({
        programName: cdpDataFetch.program?.programName,
        programId: cdpDataFetch.program?.programId
      })
    );
    showStatus(response);
    setLoader(false);
  };

  const downloadErrorDetails = async () => {
    setLoader(true);
    const authToken = JSON.parse(sessionStorage.getItem('token'));
    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'blob',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': window.location.origin,
        'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
        Authorization: `Bearer ${authToken.token}`
      }
    };
    let url = CDP_DETAIL_URLS.CDP_DS_ERROR(
      Id,
      CDP.DATA_SYSTEMS_ENUM[fetchType],
      CDP.CDP_TYPE_ENUM[dol.typeName] ?? 0,
      CDP.PROGRAM_ENUM[cdpDataFetch.program?.programName]
    );
    if (fetchType === CDP.DATA_SYSTEM_NAMES.REDCAP) {
      url = CDP_DETAIL_URLS.REDCAP_ERROR(
        Id,
        CDP.PROGRAM_ENUM[cdpDataFetch.program?.programName]
      );
    }
    const response = await axios.get(url, config);
    if (response && response.status === 200) {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${fetchType}-Invalid-Records.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoader(false);
    }
  };

  const onBackClick = e => {
    if (
      detailPageType === CDP.DETAIL_PAGE_TYPE.FETCH &&
      cdpDataFetch.isImported === false
    ) {
      e.preventDefault();
      setShowPrompt(false);
      setNavBlocker(true);
    }
  };

  useEffect(() => {
    sessionStorage.setItem(
      'selectedKPI',
      JSON.stringify({
        programName: cdpDataFetch.program?.programName,
        programId: cdpDataFetch.program?.programId
      })
    );
  }, [cdpDataFetch]);

  const canImport = () => cdpDetailView?.invalidRecords?.length === 0;

  const onOkError = () => {
    if (toastStatus.status === APP_LABELS.SUCCESS) {
      setShowPrompt(false);
      props.history.push('/cdp/associate/dashboard');
    } else {
      setToastStatus({});
    }
  };

  const onOkRedirect = () => {
    setShowPrompt(false);
    props.history.push('/cdp/associate/dashboard');
  };

  const onCancelRedirect = () => {
    setNavBlocker(false);
  };

  return (
    <BlockUi
      blocking={loader}
      message={loadingMessage}
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <PageContainer
              pathName="Review"
              title="Chronic Disease Program Details"
              onBackClick={onBackClick}
            />
            <div className="container">
              <section className="ymca-program-details mb-4">
                <div className="row">
                  <div className="col-lg-3 col-md-auto col-12">
                    <div className="program-details-name notranslate">
                      Association
                    </div>
                    <div className="program-details-info">
                      {association?.yName}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-auto col-12">
                    <div className="program-details-name">data System </div>
                    <div className="program-details-info text-capitalize notranslate">
                      {cdpDataFetch?.dataSystem?.sourceSystemDisplayName}
                    </div>
                  </div>
                  <div className="col-lg-auto col-md-auto col-12">
                    <div className="program-details-name">Program</div>
                    <div className="program-details-info text-capitalize notranslate">
                      {cdpDataFetch?.program?.programDescription &&
                        `${cdpDataFetch?.program?.programDescription || ''}`}
                    </div>
                  </div>
                  {cdpDataFetch?.cdpDolType?.typeName && (
                    <div className="col-lg-auto col-md-auto col-12">
                      <div className="program-details-name">Type</div>
                      <div className="program-details-info text-capitalize">
                        {(cdpDataFetch?.cdpDolType?.typeName &&
                          cdpDataFetch?.cdpDolType?.typeName?.toLowerCase()) ||
                          ''}
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
            {loadComponent()}
            {!cdpDataFetch.isImported && (
              <section className="ymca-form mt-3">
                <div className="container">
                  <div className="form-group">
                    <div className="form-check ymca-check-box my-3">
                      <input
                        disabled={!canImport() || cdpDetailView?.isEnable}
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={e => setIsConfirmCheck(e.target.checked)}
                        value={isConfirmCheck}
                      />
                      <label
                        className="form-check-label ymca-cachet-normal-dark-text"
                        htmlFor="flexCheckDefault"
                      >
                        {CDP.MESSAGES.CheckBoxConfirmationMessage}
                      </label>
                      {showConfirmCheckMsg && (
                        <p className="errorNote">
                          {CDP.MESSAGES.ImportErrorMessage}
                        </p>
                      )}
                    </div>
                  </div>
                  <Link
                    to="/cdp/upload/data"
                    onClick={e => onBackClick(e)}
                    className="btn ymca-primary-outline-btn login-btn mt-0"
                  >
                    Cancel
                  </Link>
                  <button
                    disabled={!canImport() || cdpDetailView?.isEnable}
                    className="btn ms-3 ymca-primary-btn login-btn mt-0"
                    type="button"
                    onClick={onImportClick}
                  >
                    {APP_LABELS.UPLOAD_DATA_IMPORT_DATA}
                  </button>
                  {!canImport() && (
                    <button
                      className="btn ymca-cpd-import-data-download"
                      type="button"
                      onClick={downloadErrorDetails}
                    >
                      <img src={Download} alt="Download" />
                      {CDP.MESSAGES.DownloadErrorDetails}
                    </button>
                  )}
                </div>
              </section>
            )}
          </div>
        </main>
      </div>
      <Prompt
        when={detailPageType === CDP.DETAIL_PAGE_TYPE.FETCH && showPrompt}
        message={CDP.MESSAGES.ImprotWarningPopupMessage}
      />
      <CustomModal
        show={navBlocker}
        type="Warning!"
        message={CDP.MESSAGES.ImprotWarningPopupMessage}
        submitButtonName="Yes"
        onSubmit={onOkRedirect}
        cancelButtonName="No"
        onCancel={onCancelRedirect}
      />
      <CustomModal
        show={toastStatus.status}
        type={toastStatus.status}
        message={toastStatus.message}
        submitButtonName={
          toastStatus.status === APP_LABELS.SUCCESS ? 'Redirect Now' : 'Ok'
        }
        onSubmit={onOkError}
      />
      {redirect && <Redirect to="/cdp/associate/dashboard" />}
    </BlockUi>
  );
};
CdpDetails.propTypes = {
  handelGetCdpDataFetchAction: PropTypes.func.isRequired,
  handelPostRedCapImportAction: PropTypes.func.isRequired,
  handlePostSoleraImportAction: PropTypes.func.isRequired,
  handelGetCdpFetchDetailsAction: PropTypes.func.isRequired,
  cdpDataSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  cdpFetchSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  detailPageTypeSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  cdpDataFetchTypeSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handelGetCdpFetchDetailsAction: getCdpFetchDetailsAction,
  handelGetCdpDataFetchAction: getCdpDataFetchAction,
  handelPostRedCapImportAction: postRedCapImportAction,
  handlePostSoleraImportAction: postSoleraImportAction
};

const mapStateToProps = state => ({
  cdpDataSelectorData: cdpDataSelector(state),
  cdpFetchSelectorData: cdpFetchSelector(state),
  detailPageTypeSelectorData: detailPageTypeSelector(state),
  cdpDataFetchTypeSelectorData: cdpDataFetchTypeSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CdpDetails));
