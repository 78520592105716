import React from 'react';
import PropTypes from 'prop-types';
import FileViewer from 'react-file-viewer';

const PreviewModal = props => {
  const { fileURL, fileExt } = props;

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog file-upload-popup">
        <div
          className="modal-content"
          style={{ width: '100%', height: '500px', overflowY: 'scroll' }}
        >
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {fileExt?.toLocaleLowerCase()?.includes('pdf') ? (
              <iframe
                src={fileURL}
                title="pdf viewer"
                width="100%"
                height="500px"
              />
            ) : (
              <FileViewer key={fileURL} fileType="docx" filePath={fileURL} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewModal.propTypes = {
  fileURL: PropTypes.string.isRequired,
  fileExt: PropTypes.string.isRequired
};

export default PreviewModal;
