import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { BranchListSelector } from '@redux/directory-information/selectors';
import { getBranchList } from '@redux/directory-information/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import { APP_LABELS } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';
import helpicon from '@static/images/caretdoubleright.svg';
import ProgramActivitiesTab from './program-activities';
import HousingTab from './housing';
import FoodProgramsTab from './food-programs';
import ArmedServicesTab from './armed-services';
import OlderAdultsTab from './older-adults';
import AssociationDropdown from '../associaction-drop-down/index';

const ProgramActivitesTab = props => {
  const { setLoader, BranchList, LoginUserData, handleGetBranchList, status } =
    props;
  const getBranchIdFromOverview = sessionStorage.getItem('branchSelected');
  const [associonOptions, setAssocionOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedAsso, setSelectedAsso] = useState(null);
  const [activeTab, setActiveTab] = useState('Program & Activities');
  const [instructions, setInstructions] = useState('');
  const [subInstructions, setSubInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const role = get(LoginUserData, 'user.role[0]');
  const [keyValue, setKeyValue] = useState();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const [showPopup, setShowPopup] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [lastActiveTab, setLastActiveTab] = useState('');

  useEffect(() => {
    if (activeTab === 'Program & Activities') {
      setSubInstructions(
        JSON.parse(sessionStorage.getItem('allinstructions'))?.[
          'program-activities-sub'
        ]?.Instruction
      );
      setKeyValue('program-and-activities');
    } else if (activeTab === 'Housing') {
      setSubInstructions(
        JSON.parse(sessionStorage.getItem('allinstructions'))?.housing
          ?.Instruction
      );
      setKeyValue('housing');
    } else if (activeTab === 'Food Programs') {
      setSubInstructions(
        JSON.parse(sessionStorage.getItem('allinstructions'))?.['food-programs']
          ?.Instruction
      );
      setKeyValue('food-programs');
    } else if (activeTab === 'Armed Services') {
      setSubInstructions(
        JSON.parse(sessionStorage.getItem('allinstructions'))?.[
          'armed-services'
        ]?.Instruction
      );
      setKeyValue('armed-services');
    } else if (activeTab === 'Older Adults') {
      setSubInstructions(
        JSON.parse(sessionStorage.getItem('allinstructions'))?.['older-adults']
          ?.Instruction
      );
      setKeyValue('older-adults');
    }
  }, [activeTab]);

  useEffect(() => {
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'program-and-activities'
      ]?.Instruction
    );

    setLoader(true);
    handleGetBranchList(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    ).then(res => {
      if (res.status === 200) {
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
    sessionStorage.removeItem('branchSelected');
  }, []);

  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  const onActive = value => {
    if (value !== activeTab && sessionStorage.getItem('dirty') === 'true') {
      setShowPopup(true);
    } else {
      setActiveTab(value);
    }
    setCurrentTab(value);
    setLastActiveTab(activeTab);
  };
  const handleOk = () => {
    setActiveTab(currentTab);
    setShowPopup(false);
  };
  const handleCancel = () => {
    const activetab = lastActiveTab;
    document.getElementById(activetab).click();
    setActiveTab(activetab);
    setShowPopup(false);
  };

  const [selectedBranch, setSelectedBranch] = useState(
    getBranchIdFromOverview || null
  );
  useEffect(() => {
    const isEmpty = Object.keys(BranchList).length === 0;
    if (!isEmpty) {
      const assoNumber = get(BranchList, 'data.associationNumber');
      const assoName = get(BranchList, 'data.associationName');
      setAssocionOptions([{ id: assoNumber, value: assoName }]);
      setSelectedAsso(assoNumber);
      const branchList = get(BranchList, 'data.branches')?.map(branch => ({
        id: branch.branchNumber,
        value: branch.branchName
      }));
      setBranchOptions(branchList);
      if (
        !selectedBranch ||
        (selectedAsso !== null && assoNumber !== selectedAsso)
      )
        setSelectedBranch(get(BranchList, 'data.branches[0].branchNumber'));
      setSelectedBranchName(get(BranchList, 'data.branches[0].branchName'));
    }
  }, [BranchList]);
  return (
    <div className="ymca-completeBox">
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <div className="ymca-program">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
            <div className="ymca-instructionBoxes">
              <h4 className="ymca-instructHeadline">
                {APP_LABELS.INSTRUCTIONS}
              </h4>
              <p className="ymca-instructTitle">
                <div
                  dangerouslySetInnerHTML={{
                    __html: instructions
                  }}
                />
              </p>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <h4 className="ymca-programActivities">
              Select your Y using the dropdowns to enter responses for your
              corporate association and/or branch(es):
            </h4>
          </div>
          <AssociationDropdown
            setSelectedAsso={setSelectedAsso}
            selectedAsso={selectedAsso}
            associonOptions={associonOptions}
            setSelectedBranch={setSelectedBranch}
            setSelectedBranchName={setSelectedBranchName}
            selectedBranch={selectedBranch}
            branchOptions={branchOptions}
          />
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 pb-3">
          <div className="ymca-instructionBoxes">
            <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
            <p className="ymca-instructTitle">
              <div
                dangerouslySetInnerHTML={{
                  __html: subInstructions
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="ymca-directorInfo ymca-collabration">
        <ul
          className="nav nav-tabs ymca-navTabs ymca-sixRows"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks active"
              id="Program & Activities"
              data-bs-toggle="tab"
              data-bs-target="#activityProgram"
              type="button"
              role="tab"
              aria-controls="activityProgram"
              aria-selected="true"
              onClick={() => {
                onActive('Program & Activities');
              }}
            >
              Program & Activities
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Housing"
              data-bs-toggle="tab"
              data-bs-target="#housing"
              type="button"
              role="tab"
              aria-controls="housing"
              aria-selected="false"
              onClick={() => {
                onActive('Housing');
              }}
            >
              Housing
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Food Programs"
              data-bs-toggle="tab"
              data-bs-target="#foodProgram"
              type="button"
              role="tab"
              aria-controls="foodProgram"
              aria-selected="false"
              onClick={() => {
                onActive('Food Programs');
              }}
            >
              Food Programs
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Armed Services"
              data-bs-toggle="tab"
              data-bs-target="#armedServices"
              type="button"
              role="tab"
              aria-controls="armedServices"
              aria-selected="false"
              onClick={() => {
                onActive('Armed Services');
              }}
            >
              Armed Services
            </button>
          </li>
          <li className="nav-item ymca-navItems" role="tab">
            <button
              className="nav-link ymca-navLinks"
              id="Older Adults"
              data-bs-toggle="tab"
              data-bs-target="#olderAdults"
              type="button"
              role="tab"
              aria-controls="olderAdults"
              aria-selected="false"
              onClick={() => {
                onActive('Older Adults');
              }}
            >
              Older Adults
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane ymca-tabPaneInner fade show active"
            id="activityProgram"
            role="tabpanel"
            aria-labelledby="Program & Activities"
          >
            {activeTab === 'Program & Activities' && (
              <ProgramActivitiesTab
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                selectedBranchName={selectedBranchName}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                setLoader={setLoader}
                status={status}
                role={role}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="housing"
            role="tabpanel"
            aria-labelledby="housing"
          >
            {activeTab === 'Housing' && (
              <HousingTab
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                selectedBranchName={selectedBranchName}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                setLoader={setLoader}
                status={status}
                role={role}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="foodProgram"
            role="tabpanel"
            aria-labelledby="foodProgram"
          >
            {activeTab === 'Food Programs' && (
              <FoodProgramsTab
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranch={selectedBranch}
                selectedBranchName={selectedBranchName}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="armedServices"
            role="tabpanel"
            aria-labelledby="armedServices"
          >
            {activeTab === 'Armed Services' && (
              <ArmedServicesTab
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranchName={selectedBranchName}
                selectedBranch={selectedBranch}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
              />
            )}
          </div>
          <div
            className="tab-pane ymca-tabPaneInner fade"
            id="olderAdults"
            role="tabpanel"
            aria-labelledby="olderAdults"
          >
            {activeTab === 'Older Adults' && (
              <OlderAdultsTab
                setLoader={setLoader}
                selectedAsso={selectedAsso}
                selectedBranchName={selectedBranchName}
                selectedBranch={selectedBranch}
                setErrorPopup={setErrorPopup}
                setErrorMessage={setErrorMessage}
                status={status}
                role={role}
              />
            )}
          </div>
        </div>
      </div>

      <CustomModal
        show={showPopup}
        type="Warning!"
        message="You have some unsaved changes. If you leave before saving, your
                changes will be lost."
        submitButtonName="Ok"
        onSubmit={handleOk}
        cancelButtonName="Cancel"
        onCancel={handleCancel}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {help && <Help hidePopup={hideShowHelp} keyValue={keyValue} />}
    </div>
  );
};

ProgramActivitesTab.propTypes = {
  setLoader: PropTypes.objectOf(PropTypes.any).isRequired,
  BranchList: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetBranchList: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  BranchList: BranchListSelector(state)
});

const mapDispatchToProps = {
  handleGetBranchList: getBranchList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ProgramActivitesTab));
