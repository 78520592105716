/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  getOlderAdults,
  olderAdults
} from '@redux/program-activities/olderadults/actions';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { GetOlderAdultsSelector } from '@redux/program-activities/olderadults/selectors';
import PropTypes from 'prop-types';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS } from '@config/app-config';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import TextError from '@modules/shared/components/reusable-components/text-error';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const OlderAdultsTab = props => {
  const {
    handleGetOlderAdults,
    GetOlderAdultsSelectorData,
    handleOlderAdults,
    selectedAsso,
    selectedBranch,
    setLoader,
    selectedBranchName,
    setErrorPopup,
    setErrorMessage,
    status,
    role,
    handleGetAnnualReportsDashboard,
    LoginUserData
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Program and Activities';

  const isYear2023 = () => selectedYear !== '2022';
  const [isSuccess, setIsSuccess] = useState(false);
  const [resStatus, setResStatus] = useState();
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const formikref = useRef();

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handleGetOlderAdultsData = success => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetOlderAdults(selectedAsso, selectedBranch).then(res => {
        if (res.status === 200) {
          setLoader(false);
          if (success) {
            setIsSuccess(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        }
      });
    }
  };

  useEffect(() => {
    handleGetOlderAdultsData();
    formikref?.current?.resetForm();
  }, [selectedAsso, selectedBranch]);

  const initialValues = {
    totOlderAdultSelfPay: '',
    totOlderAdultThirdPartyPay: '',
    yofferMembershipOlderAdult: 'true',
    didYofferMembershipForOlderAdultViaThirdParty: 'true',
    thirdPartyRenewActive: '',
    thirdPartyPeerfit: '',
    thirdPartySilverfit: '',
    thirdPartySilverSneakers: '',
    thirdPartyOthers: '',
    foodProgramOffered: '',
    adultDayCareServicesOffered: '',
    socialGatheringOffered: '',
    physicalHealthOffered: '',
    cdlhOffered: '',
    mentalHealthOffered: '',
    caregiverSupportsOffered: '',
    othersNotListedOffered: ''
  };

  const editValues = {
    totOlderAdultSelfPay:
      GetOlderAdultsSelectorData?.data?.totOlderAdultSelfPay === null
        ? ''
        : GetOlderAdultsSelectorData?.data?.totOlderAdultSelfPay,
    totOlderAdultThirdPartyPay:
      GetOlderAdultsSelectorData?.data?.totOlderAdultThirdPartyPay === null
        ? ''
        : GetOlderAdultsSelectorData?.data?.totOlderAdultThirdPartyPay,
    yofferMembershipOlderAdult:
      `${GetOlderAdultsSelectorData?.data?.yofferMembershipOlderAdult}` ===
      'null'
        ? 'true'
        : `${GetOlderAdultsSelectorData?.data?.yofferMembershipOlderAdult}`,
    didYofferMembershipForOlderAdultViaThirdParty:
      `${GetOlderAdultsSelectorData?.data?.didYofferMembershipForOlderAdultViaThirdParty}` ===
      'null'
        ? 'true'
        : `${GetOlderAdultsSelectorData?.data?.didYofferMembershipForOlderAdultViaThirdParty}`,
    thirdPartyRenewActive: `${GetOlderAdultsSelectorData?.data?.thirdPartyRenewActive}`,
    thirdPartyPeerfit: `${GetOlderAdultsSelectorData?.data?.thirdPartyPeerfit}`,
    thirdPartySilverfit: `${GetOlderAdultsSelectorData?.data?.thirdPartySilverfit}`,
    thirdPartySilverSneakers: `${GetOlderAdultsSelectorData?.data?.thirdPartySilverSneakers}`,
    thirdPartyOthers: `${GetOlderAdultsSelectorData?.data?.thirdPartyOthers}`,
    foodProgramOffered: `${GetOlderAdultsSelectorData?.data?.foodProgramOffered}`,
    adultDayCareServicesOffered: `${GetOlderAdultsSelectorData?.data?.adultDayCareServicesOffered}`,
    socialGatheringOffered: `${GetOlderAdultsSelectorData?.data?.socialGatheringOffered}`,
    physicalHealthOffered: `${GetOlderAdultsSelectorData?.data?.physicalHealthOffered}`,
    cdlhOffered: `${GetOlderAdultsSelectorData?.data?.cdlhOffered}`,
    mentalHealthOffered: `${GetOlderAdultsSelectorData?.data?.mentalHealthOffered}`,
    caregiverSupportsOffered: `${GetOlderAdultsSelectorData?.data?.caregiverSupportsOffered}`,
    othersNotListedOffered: `${GetOlderAdultsSelectorData?.data?.othersNotListedOffered}`
  };

  const validationSchema = Yup.object({
    yofferMembershipOlderAdult: Yup.string().nullable(),
    totOlderAdultSelfPay: Yup.string().nullable(),
    totOlderAdultThirdPartyPay: Yup.string().nullable(),
    thirdPartyRenewActive: Yup.string().nullable(),
    thirdPartyPeerfit: Yup.string().nullable(),
    thirdPartySilverfit: Yup.string().nullable(),
    thirdPartySilverSneakers: Yup.string().nullable(),
    thirdPartyOthers: Yup.string().nullable(),
    foodProgramOffered: Yup.string().nullable(),
    adultDayCareServicesOffered: Yup.string().nullable(),
    socialGatheringOffered: Yup.string().nullable(),
    physicalHealthOffered: Yup.string().nullable(),
    cdlhOffered: Yup.string().nullable(),
    mentalHealthOffered: Yup.string().nullable(),
    caregiverSupportsOffered: Yup.string().nullable(),
    othersNotListedOffered: Yup.string().nullable(),
    didYofferMembershipForOlderAdultViaThirdParty: Yup.string().nullable()
  });

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  const convertedValue = value => (value === 0 || value ? value : null);

  const parseBooleanValue = value =>
    value !== 'null' ? value.toLowerCase() === 'true' : null;

  const checkMembershipValue = (mainCondition, subCondition) =>
    mainCondition === 'true' ? parseBooleanValue(subCondition) : null;

  const onSubmit = values => {
    setLoader(true);

    values.didYofferMembershipForOlderAdultViaThirdParty =
      values.yofferMembershipOlderAdult !== 'true'
        ? 'false'
        : values.didYofferMembershipForOlderAdultViaThirdParty;

    let payloadBody = {
      programActivityOlderAdultId:
        GetOlderAdultsSelectorData?.data?.programActivityOlderAdultId,
      associationNumber: selectedAsso,
      year: sessionStorage.getItem('selectedBatchYear'),
      branchNumber: selectedBranch,
      branchName: selectedBranchName?.toString(),
      totOlderAdultSelfPay:
        values.yofferMembershipOlderAdult.toLowerCase() === 'true'
          ? convertedValue(values.totOlderAdultSelfPay)
          : null,
      totOlderAdultThirdPartyPay:
        values.yofferMembershipOlderAdult.toLowerCase() === 'true'
          ? convertedValue(values.totOlderAdultThirdPartyPay)
          : null,

      yofferMembershipOlderAdult: parseBooleanValue(
        values.yofferMembershipOlderAdult
      ),

      didYofferMembershipForOlderAdultViaThirdParty: parseBooleanValue(
        values.didYofferMembershipForOlderAdultViaThirdParty
      ),
      thirdPartyRenewActive: checkMembershipValue(
        values.didYofferMembershipForOlderAdultViaThirdParty,
        values.thirdPartyRenewActive
      ),
      thirdPartyPeerfit: checkMembershipValue(
        values.didYofferMembershipForOlderAdultViaThirdParty,
        values.thirdPartyPeerfit
      ),
      thirdPartySilverfit: checkMembershipValue(
        values.didYofferMembershipForOlderAdultViaThirdParty,
        values.thirdPartySilverfit
      ),
      thirdPartySilverSneakers: checkMembershipValue(
        values.didYofferMembershipForOlderAdultViaThirdParty,
        values.thirdPartySilverSneakers
      ),
      thirdPartyOthers: checkMembershipValue(
        values.didYofferMembershipForOlderAdultViaThirdParty,
        values.thirdPartyOthers
      ),
      foodProgramOffered: checkMembershipValue(
        values.yofferMembershipOlderAdult,
        values.foodProgramOffered
      ),
      adultDayCareServicesOffered: checkMembershipValue(
        values.yofferMembershipOlderAdult,
        values.adultDayCareServicesOffered
      ),
      socialGatheringOffered: checkMembershipValue(
        values.yofferMembershipOlderAdult,
        values.socialGatheringOffered
      ),
      physicalHealthOffered: checkMembershipValue(
        values.yofferMembershipOlderAdult,
        values.physicalHealthOffered
      ),
      mentalHealthOffered: checkMembershipValue(
        values.yofferMembershipOlderAdult,
        values.mentalHealthOffered
      ),
      status: GetOlderAdultsSelectorData?.data?.status,
      reportBatchId: GetOlderAdultsSelectorData?.data?.reportBatchId,
      createdByPersonId: GetOlderAdultsSelectorData?.data?.createdByPersonId,
      createdByName: GetOlderAdultsSelectorData?.data?.createdByName,
      createdDatetime: GetOlderAdultsSelectorData?.data?.createdDatetime,
      updatedByPersonId: GetOlderAdultsSelectorData?.data?.updatedByPersonId,
      updatedByName: GetOlderAdultsSelectorData?.data?.updatedByName,
      updatedDatetime: GetOlderAdultsSelectorData?.data?.updatedDatetime
    };

    if (selectedYear !== '2022') {
      payloadBody = {
        ...payloadBody,
        caregiverSupportsOffered: checkMembershipValue(
          values.yofferMembershipOlderAdult,
          values.caregiverSupportsOffered
        ),
        othersNotListedOffered: checkMembershipValue(
          values.yofferMembershipOlderAdult,
          values.othersNotListedOffered
        ),
        cdlhOffered: checkMembershipValue(
          values.yofferMembershipOlderAdult,
          values.cdlhOffered
        )
      };
    } else {
      payloadBody = {
        ...payloadBody,
        caregiverSupportsOffered: null,
        othersNotListedOffered: null,
        cdlhOffered: null
      };
    }

    setIsSuccess(false);
    handleOlderAdults(payloadBody).then(res => {
      handleGetOlderAdultsData(true);
      if (res?.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-programs">
                <div className="ymc-alert mt-1">
                  {GetOlderAdultsSelectorData?.data?.recordStatus?.toUpperCase() ===
                    APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                </div>
                <div className="ymca-covidRelif pt-0">
                  <h4 className="ymca-headingTitle txt-mandatory">
                    In {selectedYear}, did your Y offer programs and services
                    geared towards older adults?
                  </h4>
                  <div className="ymca-radioBTN d-flex">
                    <div className="me-5">
                      <Field
                        type="radio"
                        name="yofferMembershipOlderAdult"
                        className="me-2"
                        value="true"
                      />
                      <span>Yes</span>
                    </div>
                    <div className="">
                      <Field
                        type="radio"
                        name="yofferMembershipOlderAdult"
                        className="me-2"
                        value="false"
                      />
                      <span>No</span>
                    </div>
                  </div>
                  <ErrorMessage
                    component={TextError}
                    name="yofferMembershipOlderAdult"
                  />

                  {values.yofferMembershipOlderAdult === 'true' && (
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th className="ps-0 txt-mandatory">
                              What type of older adult programs did you offer?
                            </th>
                            <th>Yes</th>
                            <th>No</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          <tr>
                            <td
                              className={`${
                                isYear2023() ? 'txt-mandatory' : ''
                              } ymca-normalWrap mt-2`}
                            >
                              Food Program
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="foodProgramOffered"
                                  value="true"
                                />
                              </div>
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="foodProgramOffered"
                                  value="false"
                                />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>

                          <tr>
                            <td
                              className={`${
                                isYear2023() ? 'txt-mandatory' : ''
                              } ymca-normalWrap `}
                            >
                              Adult Day Care Services
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="adultDayCareServicesOffered"
                                  value="true"
                                />
                              </div>
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="adultDayCareServicesOffered"
                                  value="false"
                                />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>

                          <tr>
                            <td
                              className={`${
                                isYear2023() ? 'txt-mandatory' : ''
                              } ymca-normalWrap `}
                            >
                              Social Gatherings
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="socialGatheringOffered"
                                  value="true"
                                />
                              </div>
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="socialGatheringOffered"
                                  value="false"
                                />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>

                          <tr>
                            <td
                              className={`${
                                isYear2023() ? 'txt-mandatory' : ''
                              } ymca-normalWrap `}
                            >
                              Physical Health (Group Ex, personal training,
                              etc.)
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="physicalHealthOffered"
                                  value="true"
                                />
                              </div>
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="physicalHealthOffered"
                                  value="false"
                                />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>

                          {selectedYear !== '2022' && (
                            <tr>
                              <td
                                className={`${
                                  isYear2023() ? 'txt-mandatory' : ''
                                } ymca-normalWrap `}
                              >
                                Chronic Disease/Lifestyle Health (Arthritis
                                management, hypertension management, etc.)
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="cdlhOffered"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="cdlhOffered"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                          )}

                          <tr>
                            <td
                              className={`${
                                isYear2023() ? 'txt-mandatory' : ''
                              } ymca-normalWrap `}
                            >
                              Mental Health
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="mentalHealthOffered"
                                  value="true"
                                />
                              </div>
                            </td>
                            <td className="ymca-radioBTN">
                              <div className="">
                                <Field
                                  type="radio"
                                  name="mentalHealthOffered"
                                  value="false"
                                />
                              </div>
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>

                          {selectedYear !== '2022' && (
                            <tr>
                              <td
                                className={`${
                                  isYear2023() ? 'txt-mandatory' : ''
                                } ymca-normalWrap `}
                              >
                                Caregiver Supports
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="caregiverSupportsOffered"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="caregiverSupportsOffered"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                          )}

                          {selectedYear !== '2022' && (
                            <tr>
                              <td
                                className={`${
                                  isYear2023() ? 'txt-mandatory' : ''
                                } ymca-normalWrap `}
                              >
                                Other(s) not listed
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="othersNotListedOffered"
                                    value="true"
                                  />
                                </div>
                              </td>
                              <td className="ymca-radioBTN">
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="othersNotListedOffered"
                                    value="false"
                                  />
                                </div>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <h4 className="ymca-headingTitle txt-mandatory">
                        Does the Y offer membership for older adults via third
                        parties?
                      </h4>
                      <div className="ymca-radioBTN d-flex">
                        <div className="me-5">
                          <Field
                            type="radio"
                            name="didYofferMembershipForOlderAdultViaThirdParty"
                            className="me-2"
                            value="true"
                          />
                          <span>Yes</span>
                        </div>
                        <div className="">
                          <Field
                            type="radio"
                            name="didYofferMembershipForOlderAdultViaThirdParty"
                            className="me-2"
                            value="false"
                          />
                          <span>No</span>
                        </div>
                      </div>
                      {values.didYofferMembershipForOlderAdultViaThirdParty ===
                        'true' && (
                        <div className="ymca-tableResponsive">
                          <table className="table ymca-table table-striped ymca-widthMin">
                            <thead>
                              <tr className="ymca-cuttingData">
                                <th className="ps-0 txt-mandatory ymca-noLinting">
                                  If yes, which third party?
                                </th>
                                <th>Yes</th>
                                <th>No</th>
                                <th className="ymca-defaultSpace">&nbsp;</th>
                                <th className="ymca-defaultSpace">&nbsp;</th>
                                <th className="ymca-defaultSpace">&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody className="ymca-cuttingBody">
                              <tr>
                                <td className="ymca-normalWrap">
                                  Renew Active
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartyRenewActive"
                                      value="true"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartyRenewActive"
                                      value="false"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                              </tr>
                              <tr>
                                <td className="ymca-normalWrap">Peerfit </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartyPeerfit"
                                      value="true"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartyPeerfit"
                                      value="false"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                              </tr>
                              <tr>
                                <td className="ymca-normalWrap">
                                  Silver & Fit
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartySilverfit"
                                      value="true"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartySilverfit"
                                      value="false"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                              </tr>
                              <tr>
                                <td className="ymca-normalWrap">
                                  Silver Sneakers
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartySilverSneakers"
                                      value="true"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartySilverSneakers"
                                      value="false"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                              </tr>
                              <tr>
                                <td className="ymca-normalWrap">
                                  Other(s) not listed
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartyOthers"
                                      value="true"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-radioBTN">
                                  <div className="">
                                    <Field
                                      type="radio"
                                      name="thirdPartyOthers"
                                      value="false"
                                    />
                                  </div>
                                </td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                                <td className="ymca-defaultSpace">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                  {values.yofferMembershipOlderAdult === 'true' && (
                    <div className="ymca-tableResponsive">
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th>&nbsp;</th>
                            <th>Self pay including financial assistance</th>
                            <th>Third party pay</th>
                            <th className="ymca-defaultSpace">Total</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          <tr>
                            <td className="ymca-normalWrap">
                              How many of your older adult members are
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                type="text"
                                maxLength="9"
                                className="ymca-field"
                                name="totOlderAdultSelfPay"
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'totOlderAdultSelfPay'
                                  )
                                }
                              />
                              <ErrorMessage
                                component={TextError}
                                name="totOlderAdultSelfPay"
                              />
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                type="text"
                                maxLength="9"
                                className="ymca-field"
                                name="totOlderAdultThirdPartyPay"
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'totOlderAdultThirdPartyPay'
                                  )
                                }
                              />
                              <ErrorMessage
                                component={TextError}
                                name="totOlderAdultThirdPartyPay"
                              />
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                type="text"
                                className="ymca-field ymca-fieldDisabled"
                                value={
                                  Number(values.totOlderAdultSelfPay) +
                                  Number(values.totOlderAdultThirdPartyPay)
                                }
                                disabled
                              />
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="ymca-submitHighlighter">
                  <LastUpdateName
                    Username={GetOlderAdultsSelectorData?.data?.updatedByName}
                    Time={GetOlderAdultsSelectorData?.data?.updatedDatetime}
                  />
                </div>
                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </div>
              {isSuccess && <SuccessToaster Status={resStatus} />}
            </Form>
          );
        }}
      </Formik>

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </>
  );
};

OlderAdultsTab.propTypes = {
  handleGetOlderAdults: PropTypes.func.isRequired,
  handleOlderAdults: PropTypes.func.isRequired,
  GetOlderAdultsSelectorData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  selectedAsso: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranch: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBranchName: PropTypes.objectOf(PropTypes.any).isRequired,
  setErrorPopup: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetOlderAdults: getOlderAdults,
  handleOlderAdults: olderAdults,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

const mapStateToProps = state => ({
  GetOlderAdultsSelectorData: GetOlderAdultsSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OlderAdultsTab));
