// eslint-disable-next-line filenames/match-regex
import React, { useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy
} from 'react-table';
import PropTypes from 'prop-types';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { connect } from 'react-redux';
import PreviousIcon from '@static/images/previous-icon.svg';
import NextIcon from '@static/images/Next-icon.svg';
import SortUpIcon from '@static/images/sorting-icon-up.svg';
import SortDownIcon from '@static/images/sorting-icon-down.svg';

const Table = props => {
  const { rows, headers } = props;
  const columns = useMemo(() => headers, [headers]);
  const data = useMemo(() => rows, [rows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    prepareRow,
    state
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      defaultCanSort: true,
      initialState: {
        // Use your own data structure and add as many columns that need sorting by default
        sortBy: [{ uploadedDateTime: data.uploadedDateTime, desc: true }],
        pageSize: 25
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;
  const getShortButton = column => {
    let sortButton;

    if (column.isSorted) {
      if (column.isSortedDesc) {
        sortButton = (
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortUpIcon} className="img-fluid" alt="sorting-icon" />
          </button>
        );
      } else {
        sortButton = (
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortDownIcon} className="img-fluid" alt="sorting-icon" />
          </button>
        );
      }
    } else if (
      column.Header !== 'Summary' &&
      column.Header !== 'Status' &&
      column.Header !== ''
    ) {
      sortButton = (
        <>
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortUpIcon} className="img-fluid" alt="sorting-icon-up" />
          </button>
          <button type="button" className="btn btn-link p-0 custom-align">
            <img
              src={SortDownIcon}
              className="img-fluid"
              alt="sorting-icon-down"
            />
          </button>
        </>
      );
    } else {
      sortButton = null;
    }
    return sortButton;
  };
  return (
    <section className="dashboard-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive custom-scrollbar">
              <table
                className="table ymca-table ymca-tableHeight"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups?.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map(column => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <div className="sorting-icon-wrapper ms-2">
                            {getShortButton(column)}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page?.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row?.cells?.map(cell => (
                          <td
                            {...cell.getCellProps()}
                            className={`${cell.column.className ?? ''}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {page.length === 0 && (
                <div className="text-center py-3 fw-bold ymca-warning-text">
                  No records found!
                </div>
              )}
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation" className="ymca-pagination">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                type="button"
                className="page-link custom-align"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img src={PreviousIcon} className="pe-1" alt="" />
                {'Previous'}
              </button>
            </li>

            {[...Array(pageCount).keys()].map(count => {
              if (
                count >= (pageIndex > 25 ? pageIndex - 25 : 0) &&
                count <= (pageIndex < 25 ? 24 : pageIndex)
              ) {
                return (
                  <li className="page-item" key={count}>
                    <button
                      type="button"
                      className={`page-link custom-align ${
                        count === pageIndex && 'active'
                      }`}
                      onClick={() => gotoPage(count)}
                    >
                      {count + 1}
                    </button>
                  </li>
                );
              }
              return null;
            })}

            <li className="page-item">
              <button
                type="button"
                className="page-link custom-align"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'Next '}
                <img src={NextIcon} className="pl-1 ms-1" alt="" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
  headers: PropTypes.arrayOf(PropTypes.any)
};

Table.defaultProps = {
  rows: [],
  headers: []
};
Table.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
export default connect(mapStateToProps, null)(Table);
