// eslint-disable-next-line import/prefer-default-export
export const GetMemberDemographicsSelector = ({
  memberDemographics: { GetMemberDemographics }
}) => GetMemberDemographics;

export const GetMemberDemographicsNWMSelector = ({
  memberDemographics: { GetMemberDemographicsNWM }
}) => GetMemberDemographicsNWM;

export const PostMemberDemographicsSelector = ({
  memberDemographics: { PostMemberDemographics }
}) => PostMemberDemographics;
