import React, { useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy
} from 'react-table';
import PropTypes from 'prop-types';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { connect } from 'react-redux';
import { APP_LABELS, ENUMS } from '@config/app-config';
import axios from 'axios';
import get from 'lodash.get';
import { getServicePath } from '@config/api-config';
import SearchIcon from '@static/images/search-icon.svg';
import PreviousIcon from '@static/images/previous-icon.svg';
import NextIcon from '@static/images/Next-icon.svg';
import SortUpIcon from '@static/images/sorting-icon-up.svg';
import SortDownIcon from '@static/images/sorting-icon-down.svg';

import RejectedIcon from '@static/images/swimRegistration/rejected-icon.svg';
import PendingStatusIcon from '@static/images/swimRegistration/pending-status-icon.svg';
import ApprovedIcon from '@static/images/swimRegistration/approved-icon.svg';
import NewRegisterStatus from '@static/images/swimRegistration/new-register-status-icon.svg';

const Table = props => {
  const { rows, headers, setLoader } = props;
  const columns = useMemo(() => headers, []);
  const data = useMemo(() => rows, [rows]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      defaultCanSort: true,
      initialState: {
        // Use your own data structure and add as many columns that need sorting by default
        sortBy: [{ uploadedDateTime: data.uploadedDateTime, desc: true }],
        pageSize: 25
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;
  const authToken = JSON.parse(sessionStorage.getItem('token'));

  // eslint-disable-next-line consistent-return
  const getRowStatus = status => {
    if (typeof status === 'string' && status === ENUMS.NEW) {
      return (
        <span className="status-icon new-register-status d-flex align-items-center">
          <img
            src={NewRegisterStatus}
            alt="status-icon"
            className="img-fluid me-2"
          />
          {/* */}
          New Register
        </span>
      );
    }
    if (typeof status === 'string' && status === ENUMS.APPROVED) {
      return (
        <span className="status-icon approved-status d-flex align-items-center">
          <img
            src={ApprovedIcon}
            alt="status-icon"
            className="img-fluid me-2"
          />
          {ENUMS.APPROVED}
        </span>
      );
    }

    if (typeof status === 'string' && status === ENUMS.REJECTED) {
      return (
        <span className="status-icon rejected-status d-flex align-items-center">
          <img
            src={RejectedIcon}
            alt="status-icon"
            className="img-fluid me-2"
          />
          {ENUMS.REJECTED}
        </span>
      );
    }

    if (typeof status === 'string' && status === ENUMS.PENDING) {
      return (
        <span className="status-icon pending-status d-flex align-items-center">
          <img
            src={PendingStatusIcon}
            alt="status-icon"
            className="img-fluid me-2"
          />
          {ENUMS.PENDING}
        </span>
      );
    }
  };

  const handleExportFile = () => {
    setLoader(true);
    axios
      .get(
        `${getServicePath()}/Volunteer-Registration/api/VolunteerDashboard/ExportVolunterrs`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'blob',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': window.location.origin,
            'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
            Authorization: `Bearer ${get(authToken, 'token', '')}`
          }
        }
      )
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Volunteer-List.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  const setColumnSorting = columnData => {
    if (columnData.isSorted) {
      if (columnData.isSortedDesc) {
        return (
          <button type="button" className="btn btn-link p-0 custom-align">
            <img src={SortUpIcon} className="img-fluid" alt="sorting-icon" />
          </button>
        );
      }
      return (
        <button type="button" className="btn btn-link p-0 custom-align">
          <img src={SortDownIcon} className="img-fluid" alt="sorting-icon" />
        </button>
      );
    }
    return (
      <>
        {columnData.Header !== 'Summary' && columnData.Header !== '' ? (
          <>
            <button type="button" className="btn btn-link p-0 custom-align">
              <img
                src={SortUpIcon}
                className="img-fluid"
                alt="sorting-icon-up"
              />
            </button>
            <button type="button" className="btn btn-link p-0 custom-align">
              <img
                src={SortDownIcon}
                className="img-fluid"
                alt="sorting-icon-down"
              />
            </button>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <section className="dashboard-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="ymca-section-heading mt-3">
              Volunteers ({data?.length})
            </h2>
          </div>
          <div className="col-lg-4 pe-0">
            <div className="search-form-wrapper">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={SearchIcon} alt="SearchIcon" />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control search-form-control"
                  placeholder="Search for a volunteers...."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={globalFilter}
                  onChange={e => setGlobalFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 text-end ps-0">
            <button
              className="btn ymca-primary-btn volunteer-export-btn  mb-2 custom-align"
              type="button"
              onClick={handleExportFile}
            >
              Export
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive custom-scrollbar">
              <table
                className="table ymca-table ymca-tableHeight"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups?.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map(column => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <div className="sorting-icon-wrapper ms-2">
                            {setColumnSorting(column)}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page?.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row?.cells?.map(cell => (
                          <td
                            {...cell.getCellProps()}
                            className={`${cell.column.className ?? ''}`}
                          >
                            {cell.render('Cell')}
                            {cell.column.Header === 'Status' &&
                              getRowStatus(cell.value)}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {page.length === 0 && (
                <div className="text-center py-3 fw-bold ymca-warning-text">
                  No records found!
                </div>
              )}
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation" className="ymca-pagination">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                type="button"
                className="page-link custom-align"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img src={PreviousIcon} className="pe-1" alt="" />
                {'Previous'}
              </button>
            </li>

            {[...Array(pageCount).keys()].map(count => {
              if (
                count >= (pageIndex > 25 ? pageIndex - 25 : 0) &&
                count <= (pageIndex < 25 ? 24 : pageIndex)
              ) {
                return (
                  <li className="page-item" key={count + 1}>
                    <button
                      type="button"
                      className={`page-link custom-align ${
                        count === pageIndex && 'active'
                      }`}
                      onClick={() => gotoPage(count)}
                    >
                      {count + 1}
                    </button>
                  </li>
                );
              }
              return null;
            })}

            <li className="page-item">
              <button
                type="button"
                className="page-link custom-align"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'Next '}
                <img src={NextIcon} className="pl-1 ms-1" alt="" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
  headers: PropTypes.arrayOf(PropTypes.any),
  setLoader: PropTypes.func.isRequired
};

Table.defaultProps = {
  rows: [],
  headers: []
};
Table.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
export default connect(mapStateToProps, null)(Table);
