import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CDP } from '@config/app-config';
import PropTypes from 'prop-types';
import Table from './table';
import CdpStatCard from './cdp-stat-card';
import ViewDetails from './view-details';
import ManageColumns from './manage-columns';
import CustomPopover from "./error-popover";

const RedcapDetails = props => {
  const { cdpDetailView, fetchType, programName } = props;
  const [popupData, setPopupData] = useState({});
  const [redcapData, setRedcapData] = useState([]);
  const [activeCard, setActiveCard] = useState('');
  const [isErrorCardClass, setIsErrorCardClass] = useState(false);


  useEffect(() => {
    setRedcapData(cdpDetailView?.records);
  }, [cdpDetailView]);

  const viewDetalsPopup = data => {
    setPopupData(data);
  };

  const populateTable = () => {
    const columns = ManageColumns({
      fetchType: 'DPRP',
      dataSystem: CDP.DATA_SYSTEM_NAMES.REDCAP,
      viewDetalsPopup,
      activeCard,
      programName
    });
    return (
      redcapData && (
        <div className={`cdp-details-table ${isErrorCardClass ? 'invalid-tab' : 'valid-tab'}`}>
          <Table
            rows={redcapData}
            headers={columns}
            showCount
            noRecordMessage="No record to show"
            title={
              programName === CDP.PROGRAM_NAMES.DPP
                ? CDP.TITLES.SOLERA_DETAILS_NEW
                : CDP.TITLES.SOLERA_DETAILS_EXISTING
            }
          />
          {<CustomPopover />}
        </div>
      )
    );
  };

  const kipClick = (data, key) => {
    setActiveCard(key);
    setRedcapData(data);
    if (key === "3") {
      setIsErrorCardClass(true);
    } else {
      setIsErrorCardClass(false);
    }
  };

  return (
    <>
      <div className="container">
        <CdpStatCard
          rows={cdpDetailView}
          kipClick={kipClick}
          fetchType={fetchType}
        />
      </div>
      <div className="container">
        <section className="ymca-program-details mb-4">
          {populateTable()}
          <ViewDetails
            popupTitle="Record Details"
            dataSystem={CDP.DATA_SYSTEM_NAMES.REDCAP}
            popupData={popupData}
            programName={programName}
          />
        </section>
      </div>
    </>
  );
};

RedcapDetails.propTypes = {
  cdpDetailView: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchType: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired
};

export default connect()(RedcapDetails);
