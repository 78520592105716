import * as types from './types';

const initialState = {
  VolunteerRegistrationData: {},
  CountriesList: {}
};
export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.VOLUNTEER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        VolunteerRegistrationData: action.payload
      };
    }
    case types.VOLUNTEER_REGISTRATION_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_LIST_COUNTRIES_SUCCESS: {
      return {
        ...state,
        CountriesList: action.payload
      };
    }
    case types.GET_LIST_COUNTRIES_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
