import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CDP } from '@config/app-config';
import Table from './table';
import CdpStatCard from './cdp-stat-card';
import ViewDetails from './view-details';
import ManageColumns from './manage-columns';


const DolDetails = props => {
  const { cdpDetailView, fetchType, cdpDolType } = props;
  const [popupData, setPopupData] = useState({});
  const [activeCard, setActiveCard] = useState('');
  const [dolData, setDolData] = useState([]);

  useEffect(() => {
    setDolData(cdpDetailView?.records);
  }, [cdpDetailView]);

  const viewDetalsPopup = data => {
    setPopupData(data);
  };

  const populateTable = () => {
    const columns = ManageColumns({
      fetchType: cdpDolType,
      viewDetalsPopup,
      activeCard,
      dataSystem: CDP.DATA_SYSTEM_NAMES.DOL
    });
    return (
      <>
        {cdpDetailView && (
          <Table
            rows={dolData}
            headers={columns}
            showCount
            noRecordMessage="No record to show"
            title={CDP.TITLES.DOL_DETAILS}
          />
        )}
      </>
    );
  };

  const kipClick = (data, key) => {
    setActiveCard(key);
    setDolData(data);
  };

  return (
    <>
      <div className="container">
        <CdpStatCard
          rows={cdpDetailView}
          kipClick={kipClick}
          fetchType={fetchType}
        />
      </div>
      <div className="container">
        <section className="ymca-program-details mb-4">
          {populateTable()}
          <ViewDetails
            popupTitle="Record Details"
            dataSystem={CDP.DATA_SYSTEM_NAMES.DOL}
            popupData={popupData}
          />
        </section>
      </div>
    </>
  );
};

DolDetails.propTypes = {
  cdpDetailView: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchType: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
  cdpDolType: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired
};

export default DolDetails;
