import {
  MEMBER_DEMOGRAPHICS_GET_URL,
  MEMBER_DEMOGRAPHICS_POST_URL,
  MEMBER_DEMOGRAPHICS_NWM_GET_URL
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getMemberDemographics =
  (associationsNum) => (dispatch, getState) =>
    asyncRequest({
      url: MEMBER_DEMOGRAPHICS_GET_URL(associationsNum),
      successAction: data => ({
        type: types.GET_MEMBER_DEMOGRAPHICS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_MEMBER_DEMOGRAPHICS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getMemberDemographicsNWM =
  (associationsNum) => (dispatch, getState) =>
    asyncRequest({
      url: MEMBER_DEMOGRAPHICS_NWM_GET_URL(associationsNum),
      successAction: data => ({
        type: types.GET_MEMBER_DEMOGRAPHICS_NWM_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_MEMBER_DEMOGRAPHICS_NWM_FAILURE,
        payload: error
      }),
      dispatch
    });

export const postMemberDemographics = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: MEMBER_DEMOGRAPHICS_POST_URL(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_MEMBER_DEMOGRAPHICS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_MEMBER_DEMOGRAPHICS_FAILURE,
      payload: error
    }),
    dispatch
  });

// action to reset redux state objects, here 'key' is dynamic
export const resetReduxState = (key) => ({
  type: 'RESET_KEY',
  payload: { key }
});
