export const ReviewSubmitInfoSelector = ({
  submitReviewInfo: { ReviewSubmitInfo }
}) => ReviewSubmitInfo;

export const FinalSubmitSelector = ({ submitReviewInfo: { FinalSubmit } }) =>
  FinalSubmit;

export const ReviewSubmitDataSelector = ({
  submitReviewData: { ReviewSubmitData }
}) => ReviewSubmitData;
