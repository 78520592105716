import React, { useState } from 'react';
import exclamationicon from '@static/images/exclamation.png';
import closeicon from '@static/images/close.svg';

const PartialSaveWarningPopup = () => {
  const [popup, setShowPopup] = useState(true);
  return (
    <div>
      {popup && (
        <div className="YMCCA-tostAlert overflow-hidden">
          <div className="YMCCA-textList">
            <img src={exclamationicon} alt="exclamation" />
            <span>
              This form is partially saved, please fill out remaining required
              information to complete.
            </span>
          </div>
          <button
            type="button"
            className="YMCCA-close bg-transparent border-0 outline-0 pe-2"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            <img src={closeicon} alt="close" />
          </button>
        </div>
      )}
    </div>
  );
};
export default PartialSaveWarningPopup;
