/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useMemo } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useDropzone } from 'react-dropzone';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import {
  getFileTemplateURL,
  getTermsandConditions
} from '@redux/upload-file/actions';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';
import { getSwimSafetyBranchList } from '@redux/siwm-safety/swim-branches/actions';
import {
  FetchFileTemplateURLSelector,
  FetchFileTemplateSelector
} from '@redux/upload-file/selectors';
import { getSwimAssociationDropdown } from '@redux/siwm-safety/dashboard/actions';
import {
  getSwimSafetyUplaodProfiles,
  getSelectedBranchAndProgram
} from '@redux/siwm-safety/swim-profiles/actions';
import { getSwimProgramsByBranchId } from '@redux/siwm-safety/swim-programs/actions';
import { SwimProfileSelector } from '@redux/siwm-safety/swim-profiles/selectors';
import { GET_SWIMSAFETY_BLANK_TEMPLATE } from '@config/api-config';
import { APP_LABELS } from '@config/app-config';
import waiticon from '@static/images/wait-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import excelicon from '@static/images/excel-icon.svg';
import CustomModal from '@utilities/CustomModal';

import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import ErrorPopup from './error-popup';

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const UploadFile = props => {
  const {
    AssociationNumberData,
    handlegetAssociationList,
    handleUplaodProfiles,
    handlegetProgramsByBranchId,
    handleSelectedBranchAndProgram,
    handleSwimSafetyBranchList,
    LoginUserData
  } = props;
  const authToken = JSON.parse(sessionStorage.getItem('token'));
  const [redirect, setRedirect] = useState(false);
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [showDownloadWaitPopup, setShowDownloadWaitPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [associationList, setAssociationList] = useState([]);
  const [instructions, setInstructions] = useState('');
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [programDropDown, setProgramDropDown] = useState([]);
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  let isError = false;
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const handleCloseAttentionPopup = () => setShowAttentionPopup(false);

  const handleCloseErrorPopup = () => setShowErrorPopup(false);

  const handleEmptyDownloadTemplate = () => {
    setLoader(true);
    const associationNumber = get(
      AssociationNumberData,
      'data.association.associationNumber'
    );

    axios
      .get(GET_SWIMSAFETY_BLANK_TEMPLATE(associationNumber), {
        responseType: 'blob',
        headers: {
          'Content-Type': 'blob',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(async response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'SwimSafetyProfileData.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
        setSuccessPopup(true);
        setSuccessMessage(APP_LABELS.DOWNLOAD_TEMPLATE_SUCCESS);
      });
  };

  const handleDownloadEmptyTemplate = () => {
    handleEmptyDownloadTemplate();
    setShowAttentionPopup(false);
  };

  useEffect(() => {
    setLoader(true);
    handlegetAssociationList()
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setAssociationList(result?.data?.data);
          setSelectedAssociation(
            get(associateData, 'associationNumber') ||
              get(LoginUserData, 'user.associations[0]', '')
          );
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });

    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'upload-profile-data'
      ]?.Instruction
    );
  }, []);

  useEffect(async () => {
    setLoader(true);
    handleSwimSafetyBranchList(get(associateData, 'associationNumber', ''))
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setBranchDropdown(result?.data?.data?.branches);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  }, [selectedAssociation]);

  useEffect(() => {
    if (selectedBranch) {
      setLoader(true);
      handlegetProgramsByBranchId(selectedBranch)
        .then(result => {
          if (result?.data?.data) {
            setLoader(false);
            setProgramDropDown(result?.data?.data);
          } else {
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [selectedBranch]);

  const fileValidator = file => {
    if (file.size > 10000000) {
      isError = true;
      setShowErrorMessage(APP_LABELS.MAX_UPLOAD_FILE_ERROR_MESSAGE);
      setShowErrorPopup(true);
    }
  };
  async function handleUploadedProfile(fileData, program) {
    const response = await handleUplaodProfiles(fileData, program);
    if (response.status === 200) {
      handleSelectedBranchAndProgram({
        branch: branchDropdown.filter(
          data => data.branchid === Number(selectedBranch)
        )?.[0],
        program: programDropDown.filter(
          data => data.id === Number(selectedProgram)
        )?.[0]
      });
      setShowDownloadWaitPopup(false);
      if (response.status === 200 && response.data?.status?.status === 0) {
        setRedirect(true);
      } else {
        setLoader(false);
        setShowErrorPopup(true);
        setShowErrorMessage(response?.data?.status?.message);
      }
    } else {
      setShowDownloadWaitPopup(false);
      setShowErrorPopup(true);
      setShowErrorMessage(APP_LABELS.ERROR_MESSAGE);
    }
  }
  const onDrop = acceptedFiles => {
    if (isError) {
      return;
    }
    const [file] = acceptedFiles;
    const UploadFiledata = new FormData();
    UploadFiledata.append('UploadedFile', file);

    if (file === undefined) {
      setShowErrorMessage(
        'File could not be uploaded. Only Files with following extensions are allowed:.xlsx'
      );
      setShowErrorPopup(true);
      return;
    }
    setShowDownloadWaitPopup(true);
    handleUploadedProfile(UploadFiledata, selectedProgram);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      validator: fileValidator,
      onDrop,
      accept: {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx'
        ]
      }
    });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleBranchDropdown = value => {
    setSelectedBranch(value);
    if (selectedProgram) {
      setSelectedProgram('');
      setProgramDropDown([]);
    }
  };

  return (
    <>
      <BlockUi
        blocking={loader}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <main className="">
          <div className="ymca-data-wrapper">
            <div className="container">
              <div className="row mb-3 align-items-center">
                <div className="col-8">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ymca-breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/swim/association/dashboard">
                          {APP_LABELS.NAV_DASHBOARD}
                        </a>
                        <img
                          src={BreadcrumbIcon}
                          className="breadcrumb-icon"
                          alt=""
                        />
                      </li>
                      <li className="breadcrumb-item active">
                        Upload Profiles
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="row align-items-center mb-4">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="user-name bg-transparent d-flex align-items-center">
                    <h2 className="ymca-sub-heading mt-3">Upload Profiles</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-lg-end text-md-end">
                  <Link
                    className="ymca-title-link"
                    to="/swim/association/dashboard"
                  >
                    <img
                      src={BackIcon}
                      className="ymca-back-link"
                      alt="back-icon"
                    />
                    {APP_LABELS.DASH_BACK}
                  </Link>
                </div>
                <div className="col-lg-12">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: instructions
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <section className="data-upload-wrapper">
              <div className="container">
                <form>
                  <div className="row">
                    <div className="col-md-8 ymca-bold-heading mb-2">
                      Download Blank Template or Upload Profiles
                    </div>
                    <div className="col-md-4 text-end">
                      <button
                        type="button"
                        className="btn ymca-primary-outline-btn px-2 py-2 custom-align"
                        onClick={handleEmptyDownloadTemplate}
                      >
                        <img
                          src={excelicon}
                          alt="download-template-icon"
                          className="img-fluid me-2 template-icon"
                        />
                        {APP_LABELS.UPLOAD_DOWNLOAD_FILE}
                      </button>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="col-md-12 mt-3 card outline-card ymca-form px-3">
                        <div className="row mb-3">
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="Association"
                                className="form-label"
                              >
                                {APP_LABELS.ASSOCIATION_NAME}
                                <span className="text-mandatory ms-1">*</span>
                              </label>
                              <select
                                name="association"
                                className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                                aria-label="Default select example"
                                value={selectedAssociation}
                                disabled
                              >
                                {associationList?.map(data => (
                                  <option
                                    key={data.associationNumber}
                                    value={data.associationNumber}
                                  >
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-4">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="Association"
                                className="form-label"
                              >
                                Branch{/* */}
                                <span className="text-mandatory ms-1">*</span>
                              </label>
                              <select
                                name="association"
                                className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                                aria-label="Default select example"
                                value={selectedBranch}
                                onChange={e =>
                                  handleBranchDropdown(e.target.value)
                                }
                              >
                                <option value="">Select Branch</option>
                                {branchDropdown?.map(
                                  data =>
                                    data.branchname && (
                                      <option
                                        value={data.branchid}
                                        key={data.branchid}
                                      >
                                        {data.branchname}
                                      </option>
                                    )
                                )}
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-4">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="Association"
                                className="form-label"
                              >
                                Programs{/* */}
                                <span className="text-mandatory ms-1">*</span>
                              </label>
                              <select
                                name="association"
                                className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper notranslate"
                                aria-label="Default select example"
                                disabled={!selectedBranch}
                                value={selectedProgram}
                                onChange={e =>
                                  setSelectedProgram(e.target.value)
                                }
                              >
                                <option value="">Select Program</option>
                                {programDropDown?.map(data => (
                                  <option value={data.id} key={data.id}>
                                    {data.programName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mt-3">
                          <div className="col-md-auto col-sm-12">
                            <button
                              className="btn ymca-primary-btn login-btn custom-align"
                              type="button"
                              disabled={!selectedProgram}
                              {...getRootProps({ style })}
                            >
                              <input
                                {...getInputProps()}
                                className="form-control file-input-control"
                              />
                              {APP_LABELS.DETAIL_UPLOAD_DATA}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
            {redirect && <Redirect to="/swim/upload/data" />}
          </div>
        </main>
      </BlockUi>

      {/* Attention Modal */}
      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message={`${APP_LABELS.UPLOAD_FILE_ALL_EXISTING} ${APP_LABELS.UPLOAD_FILE_DATA}`}
        submitButtonName={APP_LABELS.UPLOAD_FILE_CONTINUE_TO_DOWNLOAD}
        onSubmit={handleDownloadEmptyTemplate}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={handleCloseAttentionPopup}
      />

      {/* Upload wait Modal */}
      <CustomModal
        show={showDownloadWaitPopup}
        type={APP_LABELS.UPLOAD_FILE_PLEASE_WAIT}
        icon={waiticon}
        message={APP_LABELS.UPLOAD_FILE_HEADING}
      />

      <CustomModal
        show={successPopup}
        type={APP_LABELS.SUCCESS}
        message={successMessage}
        submitButtonName="Ok"
        onSubmit={() => {
          setSuccessPopup(false);
        }}
      />

      {/* Error Modal */}
      <ErrorPopup
        handleCloseErrorPopup={handleCloseErrorPopup}
        errorMessage={showErrorMessage}
        showErrorPopup={showErrorPopup}
      />
    </>
  );
};

UploadFile.propTypes = {
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  FetchSwimProfileData: PropTypes.objectOf(PropTypes.any).isRequired,
  handlegetAssociationList: PropTypes.func.isRequired,
  handleGetFileTemplateURL: PropTypes.func.isRequired,
  handleUplaodProfiles: PropTypes.func.isRequired,
  handleGetTermsandConditions: PropTypes.func.isRequired,
  handlegetProgramsByBranchId: PropTypes.func.isRequired,
  handleSelectedBranchAndProgram: PropTypes.func.isRequired,
  handleSwimSafetyBranchList: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetFileTemplateURL: getFileTemplateURL,
  handleUplaodProfiles: getSwimSafetyUplaodProfiles,
  handleGetTermsandConditions: getTermsandConditions,
  handlegetAssociationList: getSwimAssociationDropdown,
  handlegetProgramsByBranchId: getSwimProgramsByBranchId,
  handleSelectedBranchAndProgram: getSelectedBranchAndProgram,
  handleSwimSafetyBranchList: getSwimSafetyBranchList
};

const mapStateToProps = state => ({
  AssociationNumberData: AssociationNumberSelector(state),
  FetchTemplateURL: FetchFileTemplateURLSelector(state),
  FetchTemplate: FetchFileTemplateSelector(state),
  FetchSwimProfileData: SwimProfileSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
