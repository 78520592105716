const VIEWONLYCOLUMNS = [
  {
    Header: 'Branch Name',
    accessor: 'BranchName',
    className: 'notranslate'
  },
  {
    Header: 'Uploaded By',
    accessor: 'UploadedBy',
    className: 'notranslate'
  },
  {
    Header: 'Upload Date & Time',
    accessor: 'UploadedDateTime',
    className: 'notranslate'
  },
  {
    Header: 'Summary',
    accessor: 'Summary',
    disableSortBy: true
  },
  {
    Header: 'Status',
    accessor: 'Status',
    disableSortBy: true
  }
];

export default VIEWONLYCOLUMNS;
