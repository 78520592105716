/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Help from '@modules/shared/components/reusable-components/help';
import helpicon from '@static/images/caretdoubleright.svg';
import {
  APP_LABELS,
  REGEX,
  FIELD_LENGTH,
  VALIDATION_MESSAGES,
  ENUMS,
  STORAGE_KEYS
} from '@config/app-config';

import {
  postVolunteerRegistrationData,
  getCountriesList
} from '@redux/swim-registration/volunteer-registeration/actions';
import {
  VolunteerRegistrationSelector,
  VolunteerCountriesListSelector
} from '@redux/swim-registration/volunteer-registeration/selectors';

import getVolunteerRegistrationPreview from '@redux/swim-registration/volunteer-registeration-preview/actions';
import { VolunteerRegistrationPreviewSelector } from '@redux/swim-registration/volunteer-registeration-preview/selectors';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import CustomModal from '@utilities/CustomModal';
import BackIcon from '@static/images/back-icon.svg';
import CrossIconBlue from '@static/images/cross-icon-blue.svg';
import InfoIcon from '@static/images/swimRegistration/info-icon.svg';
import ViewIcon from '@static/images/swimRegistration/view-icon.svg';
import DeleteIcon from '@static/images/swimRegistration/delete-icon.svg';
import ApprovedIcon from '@static/images/swimRegistration/approved-icon.svg';
import UploadIconBlue from '@static/images/swimRegistration/upload-icon-blue.svg';
import EditPencilIcon from '@static/images/swimRegistration/edit-pencil-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import TextError from '@modules/shared/components/reusable-components/text-error';
import SecureStorage from '@utilities/SecureStorage';
import PreviewPopup from './preview-popup';
import RenderStatusIcon from '../volunteer-registeration-preview/renderStatusIcon';

const { ALPHABETS, ALPHABETSWITHHYPHEN, PHONE, ZIP, DATE_FORMAT_FOR_DOCUMENT } =
  REGEX;
const { VOLUNTEER_TEXTBOX, ADDRESS_LENGTH, EMAIL_LENGTH } = FIELD_LENGTH;

const VolunteerRegisteration = props => {
  const {
    handleToGetCountries,
    handleVolunteerRegistrationData,
    handlegetVolunteerRegistrationPreview,
    VolunteerRegistrationPreviewSelectorData
  } = props;

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  const SwimOfficialCertificationRef = useRef(null);
  const AthleticProtectionTrainingRef = useRef(null);
  const BackgroundScreeningRef = useRef(null);

  const [loader, setLoader] = useState(false);
  const [help, setHelp] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [tandCacceptedName, setTandCacceptedName] = useState();
  const [termsAndCondition, setTermsAndCondition] = useState();
  const [tandCacceptedDateTime, setTandCacceptedDateTime] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const tandCacceptedDateTimeValue = new Date().toISOString();
  const [backgroundScreeningError, setBackgroundScreeningError] = useState('');
  const [athleticProtectionTrainingError, setAthleticProtectionTrainingError] =
    useState('');
  const [swimOfficialCertificationError, setSwimOfficialCertificationError] =
    useState('');

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectPath, setRedirectPath] = useState(
    '/volunteer/registration/preview'
  );
  const [backgroundScreeningSelected, setBackgroundScreeningSelected] =
    useState(false);
  const [
    athleticProtectionTrainingSelected,
    setAthleticProtectionTrainingSelected
  ] = useState(false);
  const [
    swimOfficialCertificationSelected,
    setSwimOfficialCertificationSelected
  ] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [nameErrorFlag, setNameErrorFlag] = useState(false);

  const [requestBackgroundScreening, setRequestBackgroundScreening] =
    useState(false);

  const [successMessage, setSuccessMessage] = useState(
    'Data has been submitted successfully!'
  );

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .nullable()
      .required('* First name is required.')
      .min(2, '* Please enter more than 1 characters.')
      .matches(
        ALPHABETSWITHHYPHEN,
        '* Please enter only characters and hyphens.'
      ),
    lastName: Yup.string()
      .nullable()
      .required('* Last name is required.')
      .min(2, '* Please enter more than 1 characters.')
      .matches(ALPHABETS, '* Please enter only characters.'),
    phoneNumber: Yup.string()
      .nullable()
      .matches(PHONE, '* Please enter valid Phone number.')
      .required('* Phone number is required.'),
    emailAddress: Yup.string()
      .nullable()
      .email('* Invalid email format.')
      .required('* Email is required.'),
    dateOfBirth: Yup.date()
      .required('* Date of birth is required.')
      .max(eighteenYearsAgo, '* You must be at least 18 years old.'),
    addressOne: Yup.string()
      .nullable()
      .required('* Address 1 is required.')
      .min(5, '* Please enter more than 4 characters.'),
    addressTwo: Yup.string()
      .nullable()
      .min(5, '* Please enter more than 4 characters.'),
    city: Yup.string()
      .nullable()
      .required('* City is required.')
      .min(3, '* Please enter more than 2 characters.'),
    country: Yup.string().nullable().required('* Country is required'),
    state: Yup.string().nullable().required('* State is required'),
    zipCode: Yup.string()
      .matches(ZIP, '* Enter valid Zip code.')
      .required('* Zip code is required.'),
    registerationFor: Yup.array()
      .nullable()
      .min(1, '* Please select minimum one option.')
      .required('* Please select minimum one option.'),
    isCertifiedSwimOfficial: Yup.string().when('registerationFor', {
      is: registerationFor =>
        registerationFor.includes('isSwimmingRegistration'),
      then: Yup.string().required('* Field is required.')
    })
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
    registerationFor: [],
    isCertifiedSwimOfficial: '',
    SwimOfficialCertification: '',
    AthleticProtectionTraining: '',
    BackgroundScreening: '',
    dateOfBirth: ''
  };

  const editValues = {
    firstName: VolunteerRegistrationPreviewSelectorData?.data?.firstName,
    lastName: VolunteerRegistrationPreviewSelectorData?.data?.lastName,
    phoneNumber: VolunteerRegistrationPreviewSelectorData?.data?.phoneNumber,
    emailAddress: VolunteerRegistrationPreviewSelectorData?.data?.emailAddress,
    dateOfBirth: moment(
      VolunteerRegistrationPreviewSelectorData?.data?.birthDate
    ).format('YYYY-MM-DD'),
    addressOne: VolunteerRegistrationPreviewSelectorData?.data?.address1,
    addressTwo: VolunteerRegistrationPreviewSelectorData?.data?.address2,
    city: VolunteerRegistrationPreviewSelectorData?.data?.city,
    zipCode: VolunteerRegistrationPreviewSelectorData?.data?.zipCode,
    country: VolunteerRegistrationPreviewSelectorData?.data?.country,
    state: VolunteerRegistrationPreviewSelectorData?.data?.state,
    registerationFor:
      VolunteerRegistrationPreviewSelectorData?.data?.registrationIds,
    isCertifiedSwimOfficial: `${VolunteerRegistrationPreviewSelectorData?.data?.isCertifiedSwimOfficial}`
  };

  useEffect(() => {
    window.scroll(0, 0);
    setLoader(true);
    handleToGetCountries().then(res => {
      if (res.status === 200) {
        setCountries(res?.data?.data);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
    setLoader(true);
    handlegetVolunteerRegistrationPreview().then(res => {
      if (res.status === 200) {
        setTandCacceptedName(
          res?.data?.data?.tandCacceptedName
            ? res?.data?.data?.tandCacceptedName
            : ''
        );
        setTermsAndCondition(
          res?.data?.data?.termsAndCondition
            ? res?.data?.data?.termsAndCondition
            : false
        );
        setRequestBackgroundScreening(
          res?.data?.data?.requestedBackgroundScreening
            ? res?.data?.data?.requestedBackgroundScreening
            : false
        );
        if (res?.data?.data?.volunteerId === 0) {
          setRedirectPath('/home');
        }
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });

    setSelectedCountry(VolunteerRegistrationPreviewSelectorData?.data?.country);
  }, []);

  useEffect(() => {
    const selectedCountryData = countries?.find(
      country => country.name === selectedCountry
    );
    if (selectedCountryData) {
      setStates(selectedCountryData.states);
    }
  }, [selectedCountry, countries]);
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  const handletandCacceptedNameChange = e => {
    if (e.target.value) {
      setNameErrorFlag(false);
    }
    setTandCacceptedName(e.target.value);
  };

  const handleTermsAndConditionChange = e => {
    if (e.target.checked) {
      setErrorFlag(false);
    }
    setTermsAndCondition(e.target.checked);
  };

  const handleRequestBackgroundScreeningChange = e => {
    if (e.target.checked) {
      setErrorFlag(false);
      setBackgroundScreeningError('');
    }
    setRequestBackgroundScreening(e.target.checked);
  };

  const handletandCacceptedDateTimeChange = e => {
    setTandCacceptedDateTime(e.target.value);
  };

  const volunteerAttestationObject =
    VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments?.find(
      item => item.documentTypeString === 'VolunteerAttestation'
    );
  const swimOfficialCertificationObject =
    VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments?.find(
      item => item.documentTypeString === 'SwimOfficialCertification'
    );

  const backgroundScreeningObject =
    VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments?.find(
      item => item.documentTypeString === ENUMS.BACKGROUND_SCREENING
    );

  const getDocumentStatus = () => {
    const documentTypes = {
      BackgroundScreening: null,
      AthleteProtectionTraining: null,
      SwimOfficialCertification: null
    };
    if (
      VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments
        ?.lenght !== 0
    ) {
      const rowsValue =
        VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments?.map(
          row => {
            const { documentTypeString, status } = row;
            documentTypes[documentTypeString] = status;
            return false;
          }
        );
    }
    return documentTypes;
  };

  const validateFileSize = (file, maxSize) => file && file.size > maxSize;

  const validateFileType = (file, allowedTypes) =>
    file && !allowedTypes.includes(file.type);

  const checkBackgroundScreeningEdit = () => {
    const volunteerDocumentStatus =
      VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments[0]
        ?.status;

    const validStatusValues = [
      ENUMS.APPROVED,
      ENUMS.REJECTED,
      ENUMS.NEW,
      ENUMS.PENDING
    ];

    return validStatusValues.includes(volunteerDocumentStatus);
  };

  const validateDocument = (
    value,
    documentStatus,
    errorSetter,
    errorMessageValue
  ) => {
    if (!value || typeof value !== 'object') {
      if (!documentStatus || documentStatus === 'Rejected') {
        errorSetter(errorMessageValue);
        return false;
      }
    }
    return true;
  };

  const checkDocumentValidations = values => {
    let isFormValid = true;

    const documentStatus = getDocumentStatus();

    if (
      agreementAccepted === false &&
      (!volunteerAttestationObject ||
        volunteerAttestationObject?.status === 'Rejected')
    ) {
      setFormSubmit(true);
      isFormValid = false;
    }

    //* **** BACK-GROUND SCREENING  *****/

    const hasBackgroundScreening =
      requestBackgroundScreening ||
      values.BackgroundScreening ||
      (backgroundScreeningObject?.url !== undefined &&
        backgroundScreeningObject.url !== '' &&
        backgroundScreeningObject.url !== null);

    if (!hasBackgroundScreening) {
      setBackgroundScreeningError(
        VALIDATION_MESSAGES.FILE_UPLOAD_OR_REQUEST_BS
      );
      isFormValid = false;
    }

    if (isFormValid) {
      if (validateFileSize(values.BackgroundScreening, 2097152)) {
        setBackgroundScreeningError(VALIDATION_MESSAGES.MAX_FILE_SIZE);
        isFormValid = false;
      }

      if (
        validateFileType(values.BackgroundScreening, [
          'image/png',
          'image/jpeg',
          'application/pdf'
        ])
      ) {
        setBackgroundScreeningError(VALIDATION_MESSAGES.UPLOAD_VALID_FILE);
        isFormValid = false;
      }

      if (!requestBackgroundScreening) {
        if (
          !validateDocument(
            values.BackgroundScreening,
            documentStatus.BackgroundScreening,
            setBackgroundScreeningError,
            VALIDATION_MESSAGES.FILE_UPLOAD
          )
        ) {
          isFormValid = false;
        }
      }
    }

    //* **** ATHLETIC PROTECTION TRAINING  *****/

    if (validateFileSize(values.AthleticProtectionTraining, 2097152)) {
      setAthleticProtectionTrainingError(VALIDATION_MESSAGES.MAX_FILE_SIZE);
      isFormValid = false;
    }
    if (
      validateFileType(values.AthleticProtectionTraining, [
        'image/png',
        'image/jpeg',
        'application/pdf'
      ])
    ) {
      setAthleticProtectionTrainingError(VALIDATION_MESSAGES.UPLOAD_VALID_FILE);
      isFormValid = false;
    }

    if (
      !validateDocument(
        values.AthleticProtectionTraining,
        documentStatus.AthleteProtectionTraining,
        setAthleticProtectionTrainingError,
        VALIDATION_MESSAGES.FILE_UPLOAD
      )
    ) {
      isFormValid = false;
    }

    //* **** SWIM OFFICIAL CERTIFICATION  *****/

    if (
      validateFileSize(values.SwimOfficialCertification, 2097152) &&
      values.isCertifiedSwimOfficial === 'true'
    ) {
      setSwimOfficialCertificationError(VALIDATION_MESSAGES.MAX_FILE_SIZE);
      isFormValid = false;
    }

    if (
      validateFileType(values.SwimOfficialCertification, [
        'image/png',
        'image/jpeg',
        'application/pdf'
      ]) &&
      values.isCertifiedSwimOfficial === 'true'
    ) {
      setSwimOfficialCertificationError(VALIDATION_MESSAGES.UPLOAD_VALID_FILE);
      isFormValid = false;
    }
    if (
      !validateDocument(
        values.SwimOfficialCertification,
        documentStatus.SwimOfficialCertification,
        setSwimOfficialCertificationError,
        VALIDATION_MESSAGES.FILE_UPLOAD
      ) &&
      values.isCertifiedSwimOfficial === 'true'
    ) {
      isFormValid = false;
    }

    // Repeat the same pattern for other document types

    return isFormValid;
  };

  const setFormDataValues = values => {
    const formData = new FormData();
    const {
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      dateOfBirth,
      addressOne,
      addressTwo,
      city,
      zipCode,
      state,
      country,
      registerationFor,
      isCertifiedSwimOfficial
    } = values;

    formData.append('personId', SecureStorage.getItem(STORAGE_KEYS.PERSON_ID));
    formData.append(
      'volunteerId',
      VolunteerRegistrationPreviewSelectorData?.data?.volunteerId
    );
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('phoneNumber', phoneNumber);
    formData.append('emailAddress', emailAddress);
    formData.append('birthDate', dateOfBirth);
    formData.append('address1', addressOne);
    formData.append('address2', addressTwo || '');
    formData.append('city', city);
    formData.append('zipCode', zipCode);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('birthDate', dateOfBirth);

    registerationFor.forEach((value, index) =>
      formData.append(`registrationIds[${index}]`, value)
    );

    formData.append(
      'isCertifiedSwimOfficial',
      isCertifiedSwimOfficial.toLowerCase() === 'true'
    );
    formData.append('tandCacceptedName', tandCacceptedName);
    formData.append('termsAndCondition', termsAndCondition);
    formData.append('tandCacceptedDateTime', tandCacceptedDateTimeValue);
    formData.append('requestedBackgroundScreening', requestBackgroundScreening);
    return formData;
  };

  const onSubmit = values => {
    if (!values?.registerationFor?.includes('isSwimmingRegistration')) {
      values.isCertifiedSwimOfficial = 'false';
    }

    if (!checkDocumentValidations(values)) return;

    setLoader(true);

    const formData = setFormDataValues(values);

    let i = 0;

    if (
      typeof values.BackgroundScreening === 'object' &&
      values.BackgroundScreening
    ) {
      formData.append(
        `volunteerDocuments[${i}].DocumentTypeString`,
        'BackgroundScreening'
      );
      formData.append(
        `volunteerDocuments[${i}].File`,
        values.BackgroundScreening
      );
      i += 1;
    }

    if (
      typeof values.AthleticProtectionTraining === 'object' &&
      values.AthleticProtectionTraining
    ) {
      formData.append(
        `volunteerDocuments[${i}].DocumentTypeString`,
        'AthleteProtectionTraining'
      );
      formData.append(
        `volunteerDocuments[${i}].File`,
        values.AthleticProtectionTraining
      );
      i += 1;
    }

    if (
      typeof values.SwimOfficialCertification === 'object' &&
      values.SwimOfficialCertification &&
      values.isCertifiedSwimOfficial === 'true'
    ) {
      formData.append(
        `volunteerDocuments[${i}].DocumentTypeString`,
        'SwimOfficialCertification'
      );
      formData.append(
        `volunteerDocuments[${i}].File`,
        values.SwimOfficialCertification
      );
      i += 1;
    }

    if (VolunteerRegistrationPreviewSelectorData?.data?.volunteerId !== 0) {
      setSuccessMessage('Data has been updated successfully!');
    }

    handleVolunteerRegistrationData(formData).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setLoader(false);
        setSuccessPopup(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const showUploadFile = (isEdit, documentTypeValue) => {
    if (documentTypeValue === ENUMS.ATHLETE_PROTECTION_TRAINING)
      setAthleticProtectionTrainingSelected(isEdit);
    if (documentTypeValue === ENUMS.BACKGROUND_SCREENING)
      setBackgroundScreeningSelected(isEdit);
    if (documentTypeValue === ENUMS.SWIM_OFFICIAL_CERTIFICATION)
      setSwimOfficialCertificationSelected(isEdit);
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  const handleSetCountries = (e, setFieldValue) => {
    setSelectedCountry(e.target.value);
    setFieldValue('country', e.target.value);
  };

  const handleAgreement = () => {
    if (!termsAndCondition) {
      setErrorFlag(true);
    }
    if (tandCacceptedName === '') {
      setNameErrorFlag(true);
    }
    if (tandCacceptedName && termsAndCondition && tandCacceptedDateTime) {
      setAgreementAccepted(true);
      setFormSubmit(false);
      document.getElementById('submitModal').click();
    }
  };

  const handleBackgroundScreening = (event, setFieldValue) => {
    setFieldValue('BackgroundScreening', event.target.files[0]);
    setBackgroundScreeningError('');
  };

  const handleAthleticProtectionTraining = (event, setFieldValue) => {
    setFieldValue('AthleticProtectionTraining', event.target.files[0]);
    setAthleticProtectionTrainingError('');
  };

  const handleSwimOfficialCertification = (event, setFieldValue) => {
    setFieldValue('SwimOfficialCertification', event.target.files[0]);
    setSwimOfficialCertificationError('');
  };

  const renderUploadFile = (
    editSelectedFlag,
    documentTypeRef,
    setFieldValue,
    documentTypeError,
    handleEventMethod,
    handleDocumentSelectedFlag
  ) => (
    <>
      <input
        id="multifile"
        hidden
        ref={documentTypeRef}
        type="file"
        accept="application/pdf,image/jpeg,image/png"
        onChange={event => {
          handleEventMethod(event, setFieldValue);
        }}
      />
      <button
        className="btn view-link-blue link-sm"
        type="button"
        onClick={() => {
          documentTypeRef.current.click();
        }}
      >
        <img
          src={UploadIconBlue}
          className="img-fluid upload-img me-2 mb-1"
          alt="upload-img"
        />
        Upload File{/* */}
      </button>

      {editSelectedFlag && (
        <button
          className="btn view-link-blue link-sm"
          type="button"
          onClick={() => {
            handleDocumentSelectedFlag(false);
          }}
        >
          Cancel
        </button>
      )}
    </>
  );

  const renderErrorNote = documentTypeError => (
    <div className="errorNote">{documentTypeError}</div>
  );
  const handleCancel = () => {
    setTandCacceptedName(
      VolunteerRegistrationPreviewSelectorData?.data?.tandCacceptedName
    );
    setTermsAndCondition(
      VolunteerRegistrationPreviewSelectorData?.data?.termsAndCondition
    );
    setErrorFlag(false);
  };

  const getBackgroundScreeningStatus = () => {
    const volunteerDocumentStatus =
      VolunteerRegistrationPreviewSelectorData?.data?.volunteerDocuments[0]
        ?.status;

    if ([ENUMS.DENIED].includes(volunteerDocumentStatus)) {
      return ENUMS.UNDER_REVIEW;
    }

    if ([ENUMS.SUCCESS].includes(volunteerDocumentStatus)) {
      return ENUMS.INITIATED;
    }
    return volunteerDocumentStatus;
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <button
          type="button"
          className="ymca-help-link text-decoration-none ymca-up-data-help-link  "
          onClick={handleShowHelp}
        >
          HELP{/* */}
          <img src={helpicon} className="img-fluid" alt="caret-icon" />
        </button>
        <div className="ymca-data-wrapper volunteer-registeration-wrapper">
          <div className="container">
            <section className="dashboard-header mb-4">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2 className="ymca-sub-heading mb-0">
                    Volunteer Registration
                  </h2>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                  <Link className="ymca-title-link" to={redirectPath}>
                    <img
                      src={BackIcon}
                      className="ymca-back-link mb-2"
                      alt="back-icon"
                    />
                    {/* */}
                    Back
                  </Link>
                </div>
              </div>
            </section>
          </div>
          <hr className="custom-hr" />
          <div className="container">
            <div className="ymca-dark-para-2 volunteer-details">
              Volunteer Details
            </div>
            <Formik
              initialValues={editValues || initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, setFieldValue, errors }) => (
                <Form autoComplete="off" className="ymca-registeration-form">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ymca-dark-para-2">Personal Details</div>
                      <div className="row">
                        <div className="col-md-6">
                          <span className="mb-2 required">
                            First Name{' '}
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              maxLength={VOLUNTEER_TEXTBOX}
                              name="firstName"
                              className="form-control"
                              type="text"
                              placeholder="Enter First Name"
                            />
                            <ErrorMessage
                              component={TextError}
                              name="firstName"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <span className="mb-2 required">
                            Last Name{' '}
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              maxLength={VOLUNTEER_TEXTBOX}
                              name="lastName"
                              className="form-control"
                              type="text"
                              placeholder="Enter Last Name"
                            />
                            <ErrorMessage
                              component={TextError}
                              name="lastName"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <span className="mb-2 required">
                            Date Of Birth{' '}
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              type="date"
                              name="dateOfBirth"
                              className="form-control"
                              min={moment(new Date('1/1/1901 12:00:00')).format(
                                'YYYY-MM-DD'
                              )}
                              max={moment(new Date()).format('YYYY-MM-DD')}
                            />
                            <ErrorMessage
                              component={TextError}
                              name="dateOfBirth"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="ymca-dark-para-2">Contact Details</div>
                      <div className="row">
                        <div className="col-md-6">
                          <span className="mb-2 required">
                            Phone Number{' '}
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              name="phoneNumber"
                              className="form-control"
                              type="text"
                              placeholder="Enter Phone Number"
                            />
                            <ErrorMessage
                              component={TextError}
                              name="phoneNumber"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <span className="mb-2 required">
                            Email Address{' '}
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              maxLength={EMAIL_LENGTH}
                              name="emailAddress"
                              className="form-control"
                              type="text"
                              placeholder="Enter Email Address"
                            />
                            <ErrorMessage
                              component={TextError}
                              name="emailAddress"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ymca-dark-para-2">Contact Details</div>
                  <div className="row">
                    <div className="col-lg-6">
                      <span className="mb-2 required">
                        Address 1 <span className="text-mandatory">&#42;</span>
                      </span>
                      <div className="form-group">
                        <Field
                          maxLength={ADDRESS_LENGTH}
                          name="addressOne"
                          className="form-control"
                          type="text"
                          placeholder="Enter Address 1"
                        />
                        <ErrorMessage component={TextError} name="addressOne" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <span className="mb-2 required">Address 2</span>
                      <div className="form-group">
                        <Field
                          maxLength={ADDRESS_LENGTH}
                          name="addressTwo"
                          className="form-control"
                          type="text"
                          placeholder="Enter Address 2"
                        />
                        <ErrorMessage component={TextError} name="addressTwo" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <span className="mb-2 required">
                        City <span className="text-mandatory">&#42;</span>
                      </span>
                      <div className="form-group">
                        <Field
                          maxLength={VOLUNTEER_TEXTBOX}
                          name="city"
                          className="form-control"
                          type="text"
                          placeholder="Enter City"
                        />
                        <ErrorMessage component={TextError} name="city" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <span className="mb-2 required">
                        ZIP Code <span className="text-mandatory">&#42;</span>
                      </span>
                      <div className="form-group">
                        <Field
                          name="zipCode"
                          className="form-control"
                          type="text"
                          placeholder="Enter ZIP Code"
                        />
                        <ErrorMessage component={TextError} name="zipCode" />
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <span className="mb-2 required">
                        Country <span className="text-mandatory">&#42;</span>
                      </span>
                      <div className="form-group">
                        <Field
                          name="country"
                          className="form-select form-control notranslate"
                          as="select"
                          onChange={e => handleSetCountries(e, setFieldValue)}
                        >
                          <option value="">Select a country</option>
                          {countries?.map(country => (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage component={TextError} name="country" />
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <span className="mb-2 required">
                        State <span className="text-mandatory">&#42;</span>
                      </span>
                      <div className="form-group">
                        <Field
                          className="form-select form-control notranslate"
                          name="state"
                          as="select"
                        >
                          <option value="">Select a state</option>
                          {states.map(state => (
                            <option key={state.stateId} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage component={TextError} name="state" />
                      </div>
                    </div>
                  </div>
                  <div className="ymca-modal-content mb-1">
                    Registration <span className="text-mandatory">&#42;</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4">
                      <div className="form-check ymca-check-box pb-0">
                        <Field
                          name="registerationFor"
                          className="form-check-input"
                          type="checkbox"
                          value="isSwimmingRegistration"
                          id="flexCheckChecked"
                        />
                        <span
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          <span className="table-link">Swimming</span>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                      <div className="form-check ymca-check-box pb-0">
                        <Field
                          name="registerationFor"
                          className="form-check-input"
                          type="checkbox"
                          value="isGymnasticsRegistration"
                          id="flexCheckChecked"
                        />
                        <span
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          <span className="table-link">Gymnastics</span>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                      <div className="form-check ymca-check-box pb-0">
                        <Field
                          name="registerationFor"
                          className="form-check-input"
                          type="checkbox"
                          value="isDivingRegistration"
                          id="flexCheckChecked"
                        />
                        <span
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          <span className="table-link">Diving</span>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                      <div className="form-check ymca-check-box pb-0">
                        <Field
                          name="registerationFor"
                          className="form-check-input"
                          type="checkbox"
                          value="isPickleballRegistration"
                          id="flexCheckChecked"
                        />
                        <span
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          <span className="table-link">Pickleball</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage component={TextError} name="registerationFor" />

                  {values?.registerationFor?.includes(
                    'isSwimmingRegistration'
                  ) && (
                    <div>
                      <div className="ymca-modal-content mt-2">
                        Are you a certified swim official?
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-6 col-sm-6">
                          <Field
                            name="isCertifiedSwimOfficial"
                            value="true"
                            type="radio"
                          />
                          <span className="ms-2">Yes</span>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                          <Field
                            name="isCertifiedSwimOfficial"
                            value="false"
                            type="radio"
                          />
                          <span className="ms-2">No</span>
                        </div>
                      </div>
                      <ErrorMessage
                        component={TextError}
                        name="isCertifiedSwimOfficial"
                      />
                    </div>
                  )}

                  <div className="ymca-dark-para-2 mt-3">Documents</div>
                  <div className="table-responsive custom-scrollbar">
                    <table className="table ymca-table">
                      <thead>
                        <tr>
                          <th scope="col">Document Type</th>
                          <th scope="col">Uploaded On</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                          <th />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Background Screening{/* */}
                            <span
                              href="#"
                              className="ms-2"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title="Upload your verification of clearing a Level 2 or 
                                    YMCA of the USA administered Background Screening here. "
                            >
                              <img
                                src={InfoIcon}
                                className="img-fluid success-img"
                                alt="success-img"
                              />
                            </span>
                          </td>

                          {!values.BackgroundScreening ? (
                            <td className="notranslate">
                              {VolunteerRegistrationPreviewSelectorData?.data
                                ?.volunteerDocuments[0]?.uploadedOn
                                ? moment(
                                    convertUtcToLocal(
                                      VolunteerRegistrationPreviewSelectorData
                                        ?.data?.volunteerDocuments[0]
                                        ?.uploadedOn
                                    )
                                  ).format(DATE_FORMAT_FOR_DOCUMENT)
                                : '-'}
                            </td>
                          ) : (
                            <td className="notranslate">
                              {moment(new Date()).format(
                                DATE_FORMAT_FOR_DOCUMENT
                              )}
                            </td>
                          )}

                          <td>
                            {VolunteerRegistrationPreviewSelectorData?.data
                              ?.volunteerDocuments[0]?.status ? (
                              <RenderStatusIcon
                                statusValue={getBackgroundScreeningStatus()}
                                isAdminPreview={false}
                              />
                            ) : (
                              '-'
                            )}
                          </td>

                          {/* {!values.BackgroundScreening ? (
                            <>
                              <td>
                                {VolunteerRegistrationPreviewSelectorData?.data
                                  ?.volunteerId === 0 ? (
                                  <>
                                    {renderUploadFile(
                                      backgroundScreeningSelected,
                                      BackgroundScreeningRef,
                                      setFieldValue,
                                      backgroundScreeningError,
                                      handleBackgroundScreening,
                                      setBackgroundScreeningSelected,
                                      ENUMS.BACKGROUND_SCREENING
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {VolunteerRegistrationPreviewSelectorData
                                      ?.data?.volunteerDocuments[0]?.status ===
                                      ENUMS.INITIATED && (
                                      <>
                                        {!backgroundScreeningSelected && (
                                          <button
                                            className="btn volunteer-action ymca-card-link d-flex align-items-center"
                                            type="button"
                                            onClick={() =>
                                              showUploadFile(
                                                true,
                                                VolunteerRegistrationPreviewSelectorData
                                                  ?.data?.volunteerDocuments[0]
                                                  ?.documentTypeString
                                              )
                                            }
                                          >
                                            <img
                                              src={EditPencilIcon}
                                              alt="edit-pencil-icon"
                                              className="me-2 img-fluid"
                                            />
                                            Edit
                                          </button>
                                        )}
                                        {backgroundScreeningSelected && (
                                          <>
                                            {renderUploadFile(
                                              backgroundScreeningSelected,
                                              BackgroundScreeningRef,
                                              setFieldValue,
                                              backgroundScreeningError,
                                              handleBackgroundScreening,
                                              setBackgroundScreeningSelected,
                                              VolunteerRegistrationPreviewSelectorData
                                                ?.data?.volunteerDocuments[0]
                                                ?.documentTypeString
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                {renderErrorNote(backgroundScreeningError)}
                              </td>
                            </>
                          ) : (
                            <td>
                              <span
                                className="volunteer-action-view"
                                data-bs-toggle="modal"
                                data-bs-target="#backgroundScreeningModal"
                              >
                                <button
                                  type="button"
                                  className="btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#BackgroundScreeningModalUploadFile"
                                >
                                  <img
                                    src={ViewIcon}
                                    className="img-fluid volunteer-view-delete success-img"
                                    alt="success-img"
                                  />
                                </button>
                              </span>
                              |
                              <button
                                onClick={() => {
                                  setFieldValue('BackgroundScreening', '');
                                  setBackgroundScreeningError('');
                                }}
                                className="btn"
                                type="button"
                              >
                                <span className="volunteer-action">
                                  <img
                                    src={DeleteIcon}
                                    className="img-fluid volunteer-view-delete success-img"
                                    alt="success-img"
                                  />
                                  <span className="ymca-delete-text">
                                    Delete File
                                  </span>
                                </span>
                              </button>
                              <div className="errorNote">
                                {backgroundScreeningError}
                              </div>
                            </td>
                          )} */}

                          <td>
                            {VolunteerRegistrationPreviewSelectorData?.data
                              ?.volunteerId === 0 ? (
                              <div className="d-flex align-items-center">
                                {!values.BackgroundScreening ? (
                                  <div className="">
                                    <>
                                      <input
                                        id="multifile"
                                        hidden
                                        ref={BackgroundScreeningRef}
                                        type="file"
                                        accept="application/pdf,image/jpeg,image/png"
                                        onChange={event => {
                                          handleBackgroundScreening(
                                            event,
                                            setFieldValue
                                          );
                                        }}
                                      />
                                      <button
                                        className="btn view-link-blue link-sm"
                                        type="button"
                                        onClick={() => {
                                          BackgroundScreeningRef.current.click();
                                        }}
                                      >
                                        <img
                                          src={UploadIconBlue}
                                          className="img-fluid upload-img me-2 mb-1"
                                          alt="upload-img"
                                        />
                                        {/* */}
                                        Upload File
                                      </button>
                                    </>
                                  </div>
                                ) : (
                                  <div>
                                    <span
                                      className="volunteer-action-view"
                                      data-bs-toggle="modal"
                                      data-bs-target="#backgroundScreeningModal"
                                    >
                                      <button
                                        type="button"
                                        className="btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#BackgroundScreeningModalUploadFile"
                                      >
                                        <img
                                          src={ViewIcon}
                                          className="img-fluid volunteer-view-delete success-img"
                                          alt="success-img"
                                        />
                                      </button>
                                    </span>
                                    {/* */}|
                                    <button
                                      onClick={() => {
                                        setFieldValue(
                                          'BackgroundScreening',
                                          ''
                                        );
                                        setBackgroundScreeningError('');
                                      }}
                                      className="btn"
                                      type="button"
                                    >
                                      <span className="volunteer-action">
                                        <img
                                          src={DeleteIcon}
                                          className="img-fluid volunteer-view-delete success-img"
                                          alt="success-img"
                                        />
                                        <span className="ymca-delete-text">
                                          Delete File
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                )}
                                <div className="">
                                  <span className="view-link-blue link-sm">
                                    Or
                                  </span>
                                </div>
                                <div className="ms-4">
                                  <div className="form-check ymca-check-box pb-0">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={requestBackgroundScreening}
                                      onChange={
                                        handleRequestBackgroundScreeningChange
                                      }
                                      id="requestBackgroundScreening"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="requestBackgroundScreening"
                                    >
                                      <span className="view-link-blue link-sm">
                                        Request Background Screening
                                      </span>
                                    </label>
                                    <span
                                      href="#"
                                      className="ms-2"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="right"
                                      title={APP_LABELS.INITIATE_INFO_MESSAGE}
                                    >
                                      <img
                                        src={InfoIcon}
                                        className="img-fluid success-img"
                                        alt="success-img"
                                      />
                                    </span>
                                    {errorFlag && (
                                      <div className="errorNote">
                                        * Please check request.
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                {checkBackgroundScreeningEdit() && (
                                  <>
                                    {!backgroundScreeningSelected && (
                                      <button
                                        className="btn volunteer-action ymca-card-link d-flex align-items-center"
                                        type="button"
                                        onClick={() =>
                                          showUploadFile(
                                            true,
                                            VolunteerRegistrationPreviewSelectorData
                                              ?.data?.volunteerDocuments[0]
                                              ?.documentTypeString
                                          )
                                        }
                                      >
                                        <img
                                          src={EditPencilIcon}
                                          alt="edit-pencil-icon"
                                          className="me-2 img-fluid"
                                        />
                                        {/* */}
                                        Edit
                                      </button>
                                    )}
                                    {backgroundScreeningSelected && (
                                      <div className="d-flex align-items-center">
                                        {!values.BackgroundScreening ? (
                                          <div className="">
                                            <input
                                              id="multifile"
                                              hidden
                                              ref={BackgroundScreeningRef}
                                              type="file"
                                              accept="application/pdf,image/jpeg,image/png"
                                              onChange={event => {
                                                handleBackgroundScreening(
                                                  event,
                                                  setFieldValue
                                                );
                                              }}
                                            />
                                            <button
                                              className="btn view-link-blue link-sm"
                                              type="button"
                                              onClick={() => {
                                                BackgroundScreeningRef.current.click();
                                              }}
                                            >
                                              <img
                                                src={UploadIconBlue}
                                                className="img-fluid upload-img me-2 mb-1"
                                                alt="upload-img"
                                              />
                                              {/* */}
                                              Upload File
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="">
                                            <button
                                              type="button"
                                              className="btn"
                                              data-bs-toggle="modal"
                                              data-bs-target="#BackgroundScreeningModalUploadFile"
                                            >
                                              <img
                                                src={ViewIcon}
                                                className="img-fluid volunteer-view-delete success-img"
                                                alt="success-img"
                                              />
                                            </button>
                                            <span className="table-divider">
                                              |
                                            </span>

                                            <button
                                              onClick={() => {
                                                setFieldValue(
                                                  'BackgroundScreening',
                                                  ''
                                                );
                                                setBackgroundScreeningError('');
                                              }}
                                              className="btn"
                                              type="button"
                                            >
                                              <img
                                                src={DeleteIcon}
                                                className="img-fluid volunteer-view-delete success-img"
                                                alt="success-img"
                                              />
                                              <span className="ymca-delete-text">
                                                Delete File
                                              </span>
                                            </button>
                                          </div>
                                        )}
                                        <div className="">
                                          <span className=" text-black view-link-blue link-sm ">
                                            Or
                                          </span>
                                        </div>
                                        <div className="ms-4">
                                          <div className="form-check ymca-check-box pb-0 mb-0">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={
                                                requestBackgroundScreening
                                              }
                                              onChange={
                                                handleRequestBackgroundScreeningChange
                                              }
                                              id="requestBackgroundScreening"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="requestBackgroundScreening"
                                            >
                                              <span className="view-link-blue link-sm">
                                                Request Background Screening
                                              </span>
                                            </label>
                                            <span
                                              href="#"
                                              className="ms-2"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="right"
                                              title={
                                                APP_LABELS.INITIATE_INFO_MESSAGE
                                              }
                                            >
                                              <img
                                                src={InfoIcon}
                                                className="img-fluid success-img"
                                                alt="success-img"
                                              />
                                            </span>
                                            {errorFlag && (
                                              <div className="errorNote">
                                                * Please check request.
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {renderErrorNote(backgroundScreeningError)}
                          </td>
                          <td>
                            {backgroundScreeningSelected && (
                              <div className="row">
                                {backgroundScreeningSelected && (
                                  <button
                                    className="btn view-link-blue link-sm"
                                    type="button"
                                    onClick={() => {
                                      setBackgroundScreeningSelected(false);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                )}
                              </div>
                            )}
                          </td>
                          <td />
                        </tr>

                        <tr>
                          <td>
                            Athlete Protection Training{/* */}
                            <span
                              href="#"
                              className="ms-2"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title="Upload your completion certificate for one of the approved Child/
                                    Athlete Protection training. If there are multiple certificates, scan them into 
                                    1 pdf and upload that pdf."
                            >
                              <img
                                src={InfoIcon}
                                className="img-fluid success-img"
                                alt="success-img"
                              />
                            </span>
                          </td>

                          {!values.AthleticProtectionTraining ? (
                            <td className="notranslate">
                              {VolunteerRegistrationPreviewSelectorData?.data
                                ?.volunteerDocuments[1]?.uploadedOn
                                ? moment(
                                    convertUtcToLocal(
                                      VolunteerRegistrationPreviewSelectorData
                                        ?.data?.volunteerDocuments[1]
                                        ?.uploadedOn
                                    )
                                  ).format(DATE_FORMAT_FOR_DOCUMENT)
                                : '-'}
                            </td>
                          ) : (
                            <td className="notranslate">
                              {moment(new Date()).format(
                                DATE_FORMAT_FOR_DOCUMENT
                              )}
                            </td>
                          )}

                          <td>
                            {VolunteerRegistrationPreviewSelectorData?.data
                              ?.volunteerDocuments[1]?.status ? (
                              <RenderStatusIcon
                                statusValue={
                                  VolunteerRegistrationPreviewSelectorData?.data
                                    ?.volunteerDocuments[1]?.status
                                }
                                isAdminPreview={false}
                              />
                            ) : (
                              '-'
                            )}
                          </td>

                          {!values.AthleticProtectionTraining ? (
                            <>
                              <td>
                                {VolunteerRegistrationPreviewSelectorData?.data
                                  ?.volunteerId === 0 ? (
                                  renderUploadFile(
                                    athleticProtectionTrainingSelected,
                                    AthleticProtectionTrainingRef,
                                    setFieldValue,
                                    athleticProtectionTrainingError,
                                    handleAthleticProtectionTraining,
                                    setAthleticProtectionTrainingSelected
                                  )
                                ) : (
                                  <>
                                    {!athleticProtectionTrainingSelected && (
                                      <button
                                        className="btn volunteer-action ymca-card-link d-flex align-items-center"
                                        type="button"
                                        onClick={() =>
                                          showUploadFile(
                                            true,
                                            VolunteerRegistrationPreviewSelectorData
                                              ?.data?.volunteerDocuments[1]
                                              ?.documentTypeString
                                          )
                                        }
                                      >
                                        <img
                                          src={EditPencilIcon}
                                          alt="edit-pencil-icon"
                                          className="me-2 img-fluid"
                                        />
                                        {/* */}
                                        Edit
                                      </button>
                                    )}
                                    {athleticProtectionTrainingSelected && (
                                      <>
                                        {renderUploadFile(
                                          athleticProtectionTrainingSelected,
                                          AthleticProtectionTrainingRef,
                                          setFieldValue,
                                          athleticProtectionTrainingError,
                                          handleAthleticProtectionTraining,
                                          setAthleticProtectionTrainingSelected
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                {renderErrorNote(
                                  athleticProtectionTrainingError
                                )}
                              </td>
                            </>
                          ) : (
                            <td>
                              <span
                                className="volunteer-action-view"
                                data-bs-toggle="modal"
                                data-bs-target="#backgroundScreeningModal"
                              >
                                <button
                                  type="button"
                                  className="btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#AthleticProtectionTrainingModalUploadFile"
                                >
                                  <img
                                    src={ViewIcon}
                                    className="img-fluid volunteer-view-delete success-img"
                                    alt="success-img"
                                  />
                                </button>
                              </span>
                              {/* */}|
                              <button
                                onClick={() => {
                                  setFieldValue(
                                    'AthleticProtectionTraining',
                                    ''
                                  );
                                  setAthleticProtectionTrainingError('');
                                }}
                                className="btn"
                                type="button"
                              >
                                <span className="volunteer-action">
                                  <img
                                    src={DeleteIcon}
                                    className="img-fluid volunteer-view-delete success-img"
                                    alt="success-img"
                                  />
                                  <span className="ymca-delete-text">
                                    Delete File
                                  </span>
                                </span>
                              </button>
                              <div className="errorNote">
                                {athleticProtectionTrainingError}
                              </div>
                            </td>
                          )}

                          <td />
                          <td />
                        </tr>

                        <tr>
                          <td>
                            Volunteer Attestation{/* */}
                            <span
                              href="#"
                              className="ms-2"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title="This document includes policies to read, acknowledge and sign including Photo/Video 
                                    Release, Code of Conduct, General Liability Release, COVID Notice and Disclaimer. Type 
                                    your name and the date of acknowledgement at the end of the document."
                            >
                              <img
                                src={InfoIcon}
                                className="img-fluid success-img"
                                alt="success-img"
                              />
                            </span>
                          </td>

                          {!agreementAccepted ? (
                            <td className="notranslate">
                              {volunteerAttestationObject?.uploadedOn
                                ? moment(
                                    convertUtcToLocal(
                                      volunteerAttestationObject?.uploadedOn
                                    )
                                  ).format(DATE_FORMAT_FOR_DOCUMENT)
                                : '-'}
                            </td>
                          ) : (
                            <td className="notranslate">
                              {moment(new Date()).format(
                                DATE_FORMAT_FOR_DOCUMENT
                              )}
                            </td>
                          )}
                          <td>
                            {volunteerAttestationObject?.status ? (
                              <RenderStatusIcon
                                statusValue={volunteerAttestationObject?.status}
                                isAdminPreview={false}
                              />
                            ) : (
                              '-'
                            )}
                          </td>

                          {!agreementAccepted ? (
                            <td>
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#submitModal"
                                className={
                                  VolunteerRegistrationPreviewSelectorData?.data
                                    ?.volunteerId === 0
                                    ? 'btn view-link-blue link-sm'
                                    : 'btn volunteer-action ymca-card-link '
                                }
                              >
                                {VolunteerRegistrationPreviewSelectorData?.data
                                  ?.volunteerId === 0 ? (
                                  'click to submit'
                                ) : (
                                  <>
                                    <img
                                      src={EditPencilIcon}
                                      alt="edit-pencil-icon"
                                      className="me-2 mb-1 img-fluid"
                                    />
                                    {/* */}
                                    Edit
                                  </>
                                )}
                              </button>
                              {formSubmit && (
                                <div className="errorNote">
                                  * Please accept Volunteer Attestation
                                </div>
                              )}
                            </td>
                          ) : (
                            <td className="volunteer-table-status approved-status">
                              <button className="btn" type="button">
                                <img
                                  src={ApprovedIcon}
                                  className="img-fluid success-img"
                                  alt="approved-img"
                                />
                                {/* */}
                                Successfully Submitted
                              </button>
                              <span className="table-divider">|</span>
                              <button
                                className="btn volunteer-action ymca-card-link "
                                data-bs-toggle="modal"
                                data-bs-target="#submitModal"
                                type="button"
                              >
                                <img
                                  src={EditPencilIcon}
                                  alt="edit-pencil-icon"
                                  className="me-2 mb-1 img-fluid"
                                />
                                {/* */}
                                Edit
                              </button>
                            </td>
                          )}

                          <td />
                          <td />
                        </tr>

                        {values?.registerationFor?.includes(
                          'isSwimmingRegistration'
                        ) &&
                          values.isCertifiedSwimOfficial === 'true' && (
                            <tr>
                              <td>
                                Swim Official Certification{/* */}
                                <span
                                  href="#"
                                  className="ms-2"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="Upload your completion certificate for one of the approved Child/
                              Athlete Protection training. If there are multiple certificates, scan 
                              them into 1 pdf and upload that pdf."
                                >
                                  <img
                                    src={InfoIcon}
                                    className="img-fluid success-img"
                                    alt="success-img"
                                  />
                                </span>
                              </td>

                              {!values.SwimOfficialCertification ? (
                                <td className="notranslate">
                                  {swimOfficialCertificationObject?.uploadedOn
                                    ? moment(
                                        convertUtcToLocal(
                                          swimOfficialCertificationObject?.uploadedOn
                                        )
                                      ).format(DATE_FORMAT_FOR_DOCUMENT)
                                    : '-'}
                                </td>
                              ) : (
                                <td className="notranslate">
                                  {moment(new Date()).format(
                                    DATE_FORMAT_FOR_DOCUMENT
                                  )}
                                </td>
                              )}

                              <td>
                                {swimOfficialCertificationObject?.status ? (
                                  <RenderStatusIcon
                                    statusValue={
                                      swimOfficialCertificationObject?.status
                                    }
                                    isAdminPreview={false}
                                  />
                                ) : (
                                  '-'
                                )}
                              </td>

                              {!values.SwimOfficialCertification ? (
                                <>
                                  <td>
                                    {VolunteerRegistrationPreviewSelectorData
                                      ?.data?.volunteerId === 0 ? (
                                      <>
                                        {renderUploadFile(
                                          swimOfficialCertificationSelected,
                                          SwimOfficialCertificationRef,
                                          setFieldValue,
                                          swimOfficialCertificationError,
                                          handleSwimOfficialCertification,
                                          setSwimOfficialCertificationSelected
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {!swimOfficialCertificationSelected &&
                                          swimOfficialCertificationObject?.documentTypeString && (
                                            <button
                                              className="btn volunteer-action ymca-card-link  d-flex align-items-center"
                                              type="button"
                                              onClick={() =>
                                                showUploadFile(
                                                  true,
                                                  swimOfficialCertificationObject?.documentTypeString
                                                )
                                              }
                                            >
                                              <img
                                                src={EditPencilIcon}
                                                alt="edit-pencil-icon"
                                                className="me-2 img-fluid"
                                              />
                                              {/* */}
                                              Edit
                                            </button>
                                          )}
                                        {(swimOfficialCertificationSelected ||
                                          swimOfficialCertificationObject ===
                                            undefined) && (
                                          <>
                                            {renderUploadFile(
                                              swimOfficialCertificationSelected,
                                              SwimOfficialCertificationRef,
                                              setFieldValue,
                                              swimOfficialCertificationError,
                                              handleSwimOfficialCertification,
                                              setSwimOfficialCertificationSelected
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                    {renderErrorNote(
                                      swimOfficialCertificationError
                                    )}
                                  </td>
                                </>
                              ) : (
                                <td>
                                  <span
                                    className="volunteer-action-view"
                                    data-bs-toggle="modal"
                                    data-bs-target="#backgroundScreeningModal"
                                  >
                                    <button
                                      className="btn"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#SwimModalUploadFile"
                                    >
                                      <img
                                        src={ViewIcon}
                                        className="img-fluid volunteer-view-delete success-img"
                                        alt="success-img"
                                      />
                                    </button>
                                  </span>
                                  {/* */}|
                                  <button
                                    onClick={() => {
                                      setFieldValue(
                                        'SwimOfficialCertification',
                                        ''
                                      );
                                      setSwimOfficialCertificationError('');
                                    }}
                                    className="btn"
                                    type="button"
                                  >
                                    <span className="volunteer-action">
                                      <img
                                        src={DeleteIcon}
                                        className="img-fluid volunteer-view-delete success-img"
                                        alt="success-img"
                                      />
                                      <span className="ymca-delete-text">
                                        Delete File
                                      </span>
                                    </span>
                                  </button>
                                  <div className="errorNote">
                                    {swimOfficialCertificationError}
                                  </div>
                                </td>
                              )}

                              <td />
                              <td />
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-end">
                    <button
                      className="btn ymca-primary-btn login-btn mt-0 me-3 align-top "
                      type="submit"
                      onClick={() => scrollToErrors(errors)}
                    >
                      Submit
                    </button>
                    <Link
                      to={redirectPath}
                      className="btn ymca-primary-outline-btn login-btn mt-0 me-3"
                      type="button"
                    >
                      Cancel
                    </Link>
                  </div>

                  {/* Background Screening */}
                  <PreviewPopup
                    popupTital="Background Screening"
                    displayValue={values.BackgroundScreening}
                    modelTargetValue="BackgroundScreeningModalUploadFile"
                  />

                  {/* Athlete Protection Training */}
                  <PreviewPopup
                    popupTital="Athlete Protection Training"
                    displayValue={values.AthleticProtectionTraining}
                    modelTargetValue="AthleticProtectionTrainingModalUploadFile"
                  />

                  {/* Swim Official Certification */}
                  <PreviewPopup
                    popupTital="Swim Official Certification"
                    displayValue={values.SwimOfficialCertification}
                    modelTargetValue="SwimModalUploadFile"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {/* Terms & conditions modal */}
        <div
          className="modal fade submit-modal"
          id="submitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="submitModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="modal-title ymca-section-heading"
                  id="submitModalLabel"
                >
                  Terms & conditions
                </div>
                <button
                  type="button"
                  className="close border-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCancel}
                >
                  <img
                    src={CrossIconBlue}
                    alt="cross-icon-blue"
                    className="close-icon img-fluid"
                  />
                </button>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: sessionStorage.getItem('VolunteerTermConditionTerm')
                }}
              />
              <div className="modal-body">
                <div className="form-check ymca-check-box pb-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={termsAndCondition}
                    onChange={handleTermsAndConditionChange}
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    <span className="table-link ymca-dark-para-6">
                      I have read and agree to the terms and conditions
                    </span>
                  </label>
                  {errorFlag && (
                    <div className="errorNote">
                      * Please accept our terms and conditions.
                    </div>
                  )}
                </div>
                <div className="row mt-2">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <form className="ymca-form">
                      <label htmlFor="name" className="mb-2 required">
                        Name
                      </label>
                      <div className="form-group">
                        <input
                          className="form-control submit-modal-form"
                          type="text"
                          placeholder="Enter Name"
                          value={tandCacceptedName}
                          onChange={handletandCacceptedNameChange}
                        />
                        {nameErrorFlag && (
                          <div className="errorNote">* Please enter name.</div>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <form className="ymca-form">
                      <label htmlFor="date" className="mb-2">
                        Date
                      </label>
                      <div className="form-group">
                        <input
                          className="form-control submit-modal-form"
                          type="date"
                          value={tandCacceptedDateTime}
                          onChange={handletandCacceptedDateTimeChange}
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    className="btn ymca-primary-outline-btn login-btn mt-0 me-3"
                    type="button"
                    data-bs-dismiss="modal"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn ymca-primary-btn login-btn align-top"
                    type="button"
                    // data-bs-dismiss="modal"
                    onClick={handleAgreement}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {help && (
          <Help hidePopup={hideShowHelp} keyValue="volunteer-registration" />
        )}
      </main>
      {successPopup && (
        <CustomModal
          show={successPopup}
          type={APP_LABELS.SUCCESS}
          message={successMessage}
          submitButtonName="Ok"
          onSubmit={() => {
            setRedirect(true);
          }}
        />
      )}
      {redirect && <Redirect to="/volunteer/registration/preview" />}
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

VolunteerRegisteration.propTypes = {
  handleToGetCountries: PropTypes.func.isRequired,
  handleVolunteerRegistrationData: PropTypes.func.isRequired,
  handlegetVolunteerRegistrationPreview: PropTypes.func.isRequired,
  VolunteerRegistrationPreviewSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired
};

const mapDispatchToProps = {
  handleToGetCountries: getCountriesList,
  handleVolunteerRegistrationData: postVolunteerRegistrationData,
  handlegetVolunteerRegistrationPreview: getVolunteerRegistrationPreview
};

const mapStateToProps = state => ({
  VolunteerCountriesListSelectorData: VolunteerCountriesListSelector(state),
  VolunteerRegistrationSelectorData: VolunteerRegistrationSelector(state),
  VolunteerRegistrationPreviewSelectorData:
    VolunteerRegistrationPreviewSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VolunteerRegisteration));
