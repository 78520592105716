import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  APP_LABELS,
  STORAGE_KEYS,
  VALIDATION_MESSAGES
} from '@config/app-config';
import { GET_COLI_FILE_DOWNLOAD_API } from '@config/api-config';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import axios from 'axios';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import ApprovedIcon from '@static/images/swimRegistration/approved-icon.svg';
import AssociationNameIcon from '@static/images/insurance/ass-name.svg';
import AssociationNumIcon from '@static/images/insurance/ass-number.svg';
import UpdatedByIcon from '@static/images/insurance/updatedBy.svg';
import UpdatedOnIcon from '@static/images/insurance/updated-on.svg';
import BackIcon from '@static/images/back-icon.svg';
import ViewIcon from '@static/images/swimRegistration/view-icon.svg';
import DownloadIconArrow from '@static/images/swimRegistration/download-icon-arrow.svg';
import NewSubmissionIcon from '@static/images/insurance/new-submission-icon.png';
import PendingIcon from '@static/images/insurance/pending-icon.png';
import RejectedIcon from '@static/images/insurance/rejected-icon.png';
import ExpireIcon from '@static/images/insurance/expired-icon.png';
import UploadIconBlue from '@static/images/swimRegistration/upload-icon-blue.svg';
import { Link } from 'react-router-dom';
import {
  getCOLIuplaodFiles,
  getCOLIAssociationDetails,
  setApproveRejectStatus
} from '@redux/certificate-of-liability/association-dashboard/actions';
import EditPencilIcon from '@static/images/swimRegistration/edit-pencil-icon.svg';
import DeleteIcon from '@static/images/swimRegistration/delete-icon.svg';
import CustomModal from '@utilities/CustomModal';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import SecureStorage from '../../../../utilities/SecureStorage';
import PreviewModal from './preview-modal';
import ApprovePopup from './approve_popup';
import RejectPopup from './reject_popup';

const Dashboard = props => {
  const {
    LoginUserData,
    handleUplaodFiles,
    handleAssociationDetails,
    handleToSetStatus
  } = props;

  const UploadFileRef = useRef(null);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [fileURI, setFileURI] = useState();
  const [isPdfViewer, setIsPdfViewer] = useState(true);
  const [loader, setLoader] = useState(false);
  const [fileExt, setFileExt] = useState('');
  const [instructions, setInstructions] = useState('');
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [associationDetails, setAssociationDetails] = useState({});
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [fileExceedMessage, setFileExceedMessage] = useState('');
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const authToken = JSON.parse(sessionStorage.getItem('token'));
  const associationNumber = get(associateData, 'associationNumber', '')
    ? get(associateData, 'associationNumber', '')
    : get(LoginUserData, 'user.associations[0]', '');

  const fileUrl = get(associationDetails, 'activeDocument.fileUrl', '');

  const activeStatus = get(associationDetails, 'activeDocument.status', '');

  const uplodedDateTime = get(
    associationDetails,
    'activeDocument.uplodedDateTime',
    null
  );

  const getDesignedStatus = status => {
    let icon = '';
    let textColorCls = '';
    let text = '';

    switch (status) {
      case 'Approved':
        icon = ApprovedIcon;
        textColorCls = 'approved-color';
        text = 'Approved';
        break;
      case 'Rejected':
        icon = RejectedIcon;
        textColorCls = 'rejected-color';
        text = 'Rejected';
        break;
      case 'New':
        icon = NewSubmissionIcon;
        textColorCls = 'new-submission-color';
        text = 'New Upload';
        break;
      case 'Pending':
        icon = PendingIcon;
        textColorCls = 'pending-color';
        text = 'Pending';
        break;
      case 'Expired':
        icon = ExpireIcon;
        textColorCls = 'expired-color';
        text = 'Expired';
        break;
      default:
        break;
    }

    return (
      <div className="d-flex align-items-center">
        <img src={icon} className="img-fluid me-2" alt={status} />
        <span className={`status-cls ${textColorCls}`}>{text}</span>
      </div>
    );
  };

  const getAssociationDetails = () => {
    setLoader(true);
    handleAssociationDetails(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        APP_LABELS.COI_PERMISSION_ROLES.includes(role)
        ? associationNumber
        : null
    )
      .then(res => {
        setAssociationDetails(res.data.data);
        setUploadFileData(null);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleUploadFile = acceptedFiles => {
    setLoader(true);

    const UploadFiledata = new FormData();
    UploadFiledata.append('UploadFile', acceptedFiles);
    UploadFiledata.append(
      'DocumentTypeId',
      get(associationDetails, 'activeDocument.documentTypeId', '')
    );

    handleUplaodFiles(UploadFiledata)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          setSuccessPopup(true);
          setSuccessMessage('File uploaded successfully');
          getAssociationDetails();
        } else {
          setLoader(false);
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const getFileExtension = filename => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
  };

  const editStatus = () => {
    setAssociationDetails({
      ...associationDetails,
      activeDocument: {
        ...associationDetails.activeDocument,
        status: 'Pending'
      }
    });
  };

  const openPDF = (fileName, download) => {
    setFileURI('');
    setFileExt('');
    if (fileName) {
      const URI = `${GET_COLI_FILE_DOWNLOAD_API(fileName)}`;
      const extn = getFileExtension(fileName)
        ? getFileExtension(fileName).toLowerCase()
        : '';

      setFileExt(extn);
      const typeValue = 'application/pdf';

      setLoader(true);
      axios
        .get(URI, {
          responseType: 'arraybuffer',
          headers: {
            multipartFormData: false,
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': window.location.origin,
            'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
            Authorization: `Bearer ${get(authToken, 'token', '')}`
          }
        })
        .then(response => {
          if (download) {
            const downloadUrl = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const link = document.createElement('a');
            link.href = downloadUrl;
            get(associationDetails, 'associationName', '');
            link.setAttribute(
              'download',
              `${get(
                associationDetails,
                'associationName',
                ''
              )}-ymca.certificate.${extn}`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else {
            const bytes = new Uint8Array(response.data); // use this if data is raw bytes else directly pass resData
            const blob = new window.Blob([bytes], { type: typeValue });
            const downloadUrl = window.URL.createObjectURL(blob);
            setFileURI(downloadUrl);
            setIsPdfViewer(true);
          }

          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        });
    } else {
      const extn = getFileExtension(uploadFileData.name)
        ? getFileExtension(uploadFileData.name).toLowerCase()
        : '';
      setFileExt(extn);
      const downloadUrl = window.URL.createObjectURL(uploadFileData);
      setFileURI(downloadUrl);
      setIsPdfViewer(true);
    }
  };

  const handleFileChange = event => {
    if (event.target.files[0].size > 768000) {
      setFileExceedMessage(VALIDATION_MESSAGES.INSURENCE_MAX_FILE_SIZE);
      setUploadFileData('');
    } else {
      const files = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ];
      if (files.includes(event.target.files[0].type)) {
        setFileExceedMessage('');
        setUploadFileData(event.target.files[0]);
      } else {
        setFileExceedMessage(VALIDATION_MESSAGES.INSURENCE_UPLOAD_VALID_FILE);
        setUploadFileData('');
      }
    }
  };

  const checkStatusYmcaRole = status =>
    !!(status === 'New' || status === 'Pending');

  const approveDocument = expirydate => {
    setLoader(true);
    const payload = {
      documentId: get(associationDetails, 'activeDocument.documentId', ''),
      status: 'Approved',
      ExpiryDate: expirydate,
      associationNumber: get(associationDetails, 'associationNumber', '')
    };
    handleToSetStatus(payload)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          setSuccessPopup(true);
          setSuccessMessage('Document Approved successfully');
        } else {
          setLoader(false);
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const rejectDocument = comments => {
    setLoader(true);
    const payload = {
      documentId: get(associationDetails, 'activeDocument.documentId', ''),
      status: 'Rejected',
      comments,
      associationNumber: get(associationDetails, 'associationNumber', '')
    };
    handleToSetStatus(payload)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          setSuccessPopup(true);
          setSuccessMessage('Document Rejected successfully');
        } else {
          setLoader(false);
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  // function for  YUSA_DATA_STEWARD Role to Approve or Reject the file
  const renderPreviewAndDownloadButton = fileName => (
    <div className="d-flex align-items-center">
      {!fileName.split('.').includes('doc') && (
        <button
          type="button"
          className="btn volunteer-action-view p-0"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => openPDF(fileName)}
        >
          <img src={ViewIcon} className="img-fluid mx-1" alt="view-icon-img" />
        </button>
      )}
      <button
        type="button"
        className="btn volunteer-action p-0 me-2"
        onClick={() => openPDF(fileName, true)}
      >
        <img
          src={DownloadIconArrow}
          className="img-fluid mx-2"
          alt="success-img"
        />
      </button>

      {checkStatusYmcaRole(activeStatus) &&
      !APP_LABELS.COI_PERMISSION_ROLES.includes(role) ? (
        <>
          |
          {get(associationDetails, 'status', '') === 'Rejected' && (
            <button
              type="button"
              className="status-cls approved-color mx-2 btn btn-transparent"
              onClick={() => setShowApprovePopup(true)}
            >
              Approve
            </button>
          )}
          {get(associationDetails, 'status', '') === 'Approved' && (
            <button
              type="button"
              className="status-cls rejected-color mx-2 px-0 btn btn-transparent"
              onClick={() => setShowRejectPopup(true)}
            >
              {' '}
              Reject
            </button>
          )}
          {checkStatusYmcaRole(get(associationDetails, 'status', '')) && (
            <>
              <button
                type="button"
                className="status-cls approved-color mx-2 btn btn-transparent"
                onClick={() => setShowApprovePopup(true)}
              >
                Approve
              </button>
              <button
                type="button"
                className="status-cls rejected-color  px-0 btn btn-transparent"
                onClick={() => setShowRejectPopup(true)}
              >
                {' '}
                Reject
              </button>
            </>
          )}
        </>
      ) : (
        !APP_LABELS.COI_PERMISSION_ROLES.includes(role) &&
        get(associationDetails, 'status', '') !== 'Expired' && (
          <>
            |{' '}
            <button
              className="btn table-link"
              type="button"
              onClick={editStatus}
            >
              <img
                src={EditPencilIcon}
                className="me-2 mb-1"
                alt="edit-pencil-icon"
              />{' '}
              Edit
            </button>
          </>
        )
      )}
    </div>
  );

  const archivePreviewAndDownloadButton = fileName => (
    <div className="d-flex align-items-center">
      {!fileName.split('.').includes('doc') && (
        <button
          type="button"
          className="btn volunteer-action-view p-0"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => openPDF(fileName)}
        >
          <img src={ViewIcon} className="img-fluid mx-1" alt="view-icon-img" />
        </button>
      )}
      <button
        type="button"
        className={`btn volunteer-action p-0 me-2 ${
          fileName.split('.').includes('doc') && 'ms-4'
        }`}
        onClick={() => openPDF(fileName, true)}
      >
        <img
          src={DownloadIconArrow}
          className="img-fluid mx-2"
          alt="success-img"
        />
      </button>
    </div>
  );

  // ASSOCIATION_DATA_STEWARD Role to submit the file
  const renderUploadButton = () =>
    !uploadFileData ? (
      <>
        <input
          id="uploadFile"
          hidden
          ref={UploadFileRef}
          type="file"
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={handleFileChange}
        />

        <button
          type="button"
          className="btn view-link-blue link-sm ps-0"
          onClick={() => {
            UploadFileRef.current.click();
          }}
        >
          <img
            src={UploadIconBlue}
            alt="edit-pencil-icon"
            className="me-2 volunteer-edit-doc img-fluid"
          />
          <span className="">Upload File</span>
        </button>
      </>
    ) : (
      <div>
        {!uploadFileData?.name.split('.').includes('doc') && (
          <>
            <button
              type="button"
              className="btn pt-0 px-0 m-0"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => openPDF()}
            >
              <span className="volunteer-action-view me-2" href="#">
                <img
                  src={ViewIcon}
                  className="img-fluid "
                  alt="view-icon-img"
                />
              </span>
            </button>

            <span className="me-2">|</span>
          </>
        )}

        <button
          onClick={() => {
            setUploadFileData(null);
          }}
          className="btn p-0 pt-1"
          type="button"
        >
          <span className="volunteer-action">
            <img
              src={DeleteIcon}
              className="img-fluid volunteer-view-delete success-img"
              alt="success-img"
            />
            <span className="ymca-delete-text">Delete File</span>
          </span>
        </button>
      </div>
    );

  const renderViewAndDeletePdf = () => (
    <>
      {!fileUrl.split('.').includes('doc') && (
        <button
          type="button"
          className="btn pt-0 px-0 m-0"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => openPDF(fileUrl)}
        >
          <span className="volunteer-action-view" href="#">
            <img src={ViewIcon} className="img-fluid " alt="view-icon-img" />
          </span>
        </button>
      )}
      <button
        type="button"
        className="btn pt-0 px-0 m-0"
        onClick={() => openPDF(fileUrl, true)}
      >
        <img
          src={DownloadIconArrow}
          className="img-fluid mx-3"
          alt="success-img"
        />
      </button>
      <>
        <input
          id="uploadFile"
          hidden
          ref={UploadFileRef}
          type="file"
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={handleFileChange}
        />{' '}
        |
        <button
          className="btn table-link"
          type="button"
          onClick={() => {
            UploadFileRef.current.click();
          }}
        >
          <img
            src={EditPencilIcon}
            className="me-2 mb-1"
            alt="edit-pencil-icon"
          />{' '}
          Edit
        </button>
      </>
    </>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    SecureStorage.setItem(
      STORAGE_KEYS.DASHBOARD_URL,
      role === APP_LABELS.YUSA_DATA_STEWARD
        ? '/insurance/dashboard'
        : '/insurance/association/dashboard'
    );

    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'insurance-association-dashboard'
      ]?.Instruction
    );

    getAssociationDetails();
  }, []);

  const renderErrorNote = documentTypeError => (
    <div className="errorNote">{documentTypeError}</div>
  );

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <div className="container">
              {(role !== APP_LABELS.ASSOCIATION_DATA_STEWARD && role !== APP_LABELS.CEO_DATA_STEWARD) && (
                <div className="row mb-3 align-items-center">
                  <div className="col-8">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb ymca-breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/insurance/dashboard">
                            {APP_LABELS.NAV_DASHBOARD}
                          </a>
                          <img
                            src={BreadcrumbIcon}
                            className="breadcrumb-icon"
                            alt=""
                          />
                        </li>
                        <li className="breadcrumb-item active">View Details</li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-4 text-end">
                    <Link className="ymca-title-link" to="/insurance/dashboard">
                      <img
                        src={BackIcon}
                        className="ymca-back-link"
                        alt="back-icon"
                      />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  </div>
                </div>
              )}
              <section className="dashboard-header">
                <div className="row align-items-end mt-3 justify-content-end ">
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading mb-2">
                        Insurance Details
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12" />
                </div>
              </section>
              <section className="stats-data mb-5">
                {(APP_LABELS.ASSOCIATION_DATA_STEWARD === role||APP_LABELS.CEO_DATA_STEWARD === role) && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="ymca-instructionBoxes">
                        <h4 className="ymca-instructHeadline">
                          {APP_LABELS.INSTRUCTIONS}
                        </h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: instructions
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row p-3">
                  {get(associationDetails, 'registeredDateTime', null) && (
                    <div className="col-lg-6 d-flex ps-0">
                      <span className="register-cls">
                        Certificate Upload Date & Time -
                      </span>{' '}
                      <span className="time-cls me-2">
                        {get(associationDetails, 'registeredDateTime', null) &&
                          convertUtcToLocal(
                            get(associationDetails, 'registeredDateTime'),
                            'DD MMM YYYY, h:mm A'
                          )}
                      </span>{' '}
                      | <span className="status-text mx-2">Status</span>
                      {getDesignedStatus(get(associationDetails, 'status', ''))}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-between">
                    <div className="col-lg-3 assoc-box d-flex align-items-center">
                      <img
                        src={AssociationNameIcon}
                        className="img-fluid me-4"
                        alt="AssociationNumIcon"
                      />
                      <div className="card-title">
                        <h6>Association Name</h6>
                        <p> {get(associationDetails, 'associationName', '')}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 assoc-box d-flex align-items-center">
                      <img
                        src={AssociationNumIcon}
                        className="img-fluid me-4"
                        alt="AssociationNumIcon"
                      />
                      <div className="card-title">
                        <h6>Association Number</h6>
                        <p>
                          {' '}
                          {get(associationDetails, 'associationNumber', '')}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 assoc-box d-flex align-items-center">
                      <img
                        src={UpdatedByIcon}
                        className="img-fluid me-4"
                        alt="UpdatedByIcon"
                      />
                      <div className="card-title">
                        <h6>Updated By</h6>
                        <p> {get(associationDetails, 'updatedBy', '')}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 assoc-box d-flex align-items-center">
                      <img
                        src={UpdatedOnIcon}
                        className="img-fluid me-4"
                        alt="UpdatedOnIcon"
                      />
                      <div className="card-title">
                        <h6>Updated On</h6>
                        <p>
                          {' '}
                          {get(associationDetails, 'updatedOn', '') &&
                            convertUtcToLocal(
                              get(associationDetails, 'updatedOn', ''),
                              'DD MMM YYYY h:mm A'
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* {YUSA_DATA_STEWARD Role to Approve or Reject the file} */}
              {(role === APP_LABELS.YUSA_DATA_STEWARD ||
                APP_LABELS.COI_PERMISSION_ROLES.includes(role)) && (
                <div className="table-responsive custom-scrollbar mt-3">
                  <table className="table ymca-table insurence-table">
                    <thead>
                      <tr>
                        <th scope="col">Document Type</th>
                        <th scope="col">Uploaded On</th>
                        {get(associationDetails, 'status', '') ===
                          'Rejected' && <th scope="col">Comments</th>}
                        {get(associationDetails, 'status', '') ===
                          'Approved' && <th scope="col">Expiry Date</th>}

                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {get(associationDetails, 'activeDocument.fileUrl', '') ? (
                        <tr>
                          <td>
                            {get(
                              associationDetails,
                              'activeDocument.documentType',
                              ''
                            )}
                          </td>
                          <td className="notranslate">
                            {uplodedDateTime &&
                              convertUtcToLocal(
                                uplodedDateTime,
                                'MMM DD, YYYY h:mm A'
                              )}
                          </td>
                          {get(associationDetails, 'status', '') ===
                            'Approved' && (
                            <td className="notranslate">
                              {get(
                                associationDetails,
                                'activeDocument.expiryDate',
                                null
                              ) &&
                                convertUtcToLocal(
                                  get(
                                    associationDetails,
                                    'activeDocument.expiryDate',
                                    ''
                                  ),
                                  'DD MMM YYYY'
                                )}
                            </td>
                          )}
                          {get(associationDetails, 'status', '') ===
                            'Rejected' && (
                            <td>
                              {get(
                                associationDetails,
                                'activeDocument.comments',
                                ''
                              )}
                            </td>
                          )}

                          <td>
                            {getDesignedStatus(
                              get(associationDetails, 'status', '')
                            )}
                          </td>
                          <td>{renderPreviewAndDownloadButton(fileUrl)}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="6">
                            <h6 className="text-center text-danger">
                              No Data Found
                            </h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              {/* {ASSOCIATION_DATA_STEWARD Role to submit the file} */}
              {(role === APP_LABELS.ASSOCIATION_DATA_STEWARD||role===APP_LABELS.CEO_DATA_STEWARD) && (
                <>
                  <div className="table-responsive custom-scrollbar mt-3">
                    <table className="table ymca-table insurence-table">
                      <thead>
                        <tr>
                          <th scope="col">Document Type</th>
                          <th scope="col">Uploaded On</th>
                          {activeStatus === 'Rejected' && (
                            <th scope="col">Comments</th>
                          )}
                          {activeStatus === 'Approved' && (
                            <th scope="col">Expiry Date</th>
                          )}
                          {fileUrl && <th scope="col">Status</th>}
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {get(
                              associationDetails,
                              'activeDocument.documentType',
                              ''
                            )}
                          </td>
                          <td className="notranslate">
                            {' '}
                            {uplodedDateTime
                              ? convertUtcToLocal(
                                  uplodedDateTime,
                                  'DD MMM YYYY, h:mm A'
                                )
                              : '-'}
                          </td>

                          {activeStatus === 'Rejected' && (
                            <td>
                              {get(
                                associationDetails,
                                'activeDocument.comments',
                                null
                              )}
                            </td>
                          )}
                          {activeStatus === 'Approved' && (
                            <td>
                              {get(
                                associationDetails,
                                'activeDocument.expiryDate',
                                null
                              ) &&
                                convertUtcToLocal(
                                  get(
                                    associationDetails,
                                    'activeDocument.expiryDate'
                                  ),
                                  'DD MMM, YYYY'
                                )}
                            </td>
                          )}
                          {fileUrl && (
                            <td>{getDesignedStatus(activeStatus)}</td>
                          )}
                          <td>
                            {get(
                              associationDetails,
                              'activeDocument.documentId',
                              null
                            ) && !uploadFileData
                              ? renderViewAndDeletePdf()
                              : renderUploadButton()}

                            {renderErrorNote(fileExceedMessage)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {(!get(
                    associationDetails,
                    'activeDocument.documentId',
                    null
                  ) ||
                    uploadFileData) && (
                    <div className="d-flex justify-content-end mt-5 mb-2">
                      <button
                        type="button"
                        className="btn ymc-outlineBtn btn-outline-primary custom-align mx-2"
                        onClick={() => setUploadFileData(null)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn ymca-primary-btn custom-align"
                        data-bs-dismiss="modal"
                        disabled={!uploadFileData}
                        onClick={() => handleUploadFile(uploadFileData)}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </>
              )}
              <h2 className="ymca-section-heading mt-5">Archive Data</h2>
              <div className="table-responsive custom-scrollbar mt-3">
                <table className="table ymca-table insurence-table">
                  <thead>
                    <tr>
                      <th scope="col">Document Type</th>
                      <th scope="col">Uploaded On</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Comments</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {get(associationDetails, 'archiveDocuments', [])?.length >
                    0 ? (
                      get(associationDetails, 'archiveDocuments', [])?.map(
                        row => (
                          <tr key={row?.documentType}>
                            <td>{row.documentType}</td>
                            <td className="notranslate">
                              {get(row, 'uplodedDateTime', null) &&
                                convertUtcToLocal(
                                  get(row, 'uplodedDateTime', ''),
                                  'DD MMM YYYY'
                                )}
                            </td>
                            <td className="notranslate">
                              {get(row, 'expiryDate', '') &&
                                convertUtcToLocal(
                                  get(row, 'expiryDate', ''),
                                  'DD MMM YYYY'
                                )}
                            </td>
                            <td>
                              {row.comments || <span className="ps-4">-</span>}
                            </td>
                            <td>{getDesignedStatus(get(row, 'status', ''))}</td>
                            <td>
                              {archivePreviewAndDownloadButton(row?.fileUrl)}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <h6 className="text-center text-danger">
                            No Data Found
                          </h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <PreviewModal
                fileURL={fileURI}
                pdfViewerFlag={isPdfViewer}
                fileExt={fileExt}
              />
              <ApprovePopup
                showApprovePopup={showApprovePopup}
                setShowApprovePopup={setShowApprovePopup}
                handleApprove={expirydate => approveDocument(expirydate)}
              />
              {showRejectPopup && (
                <RejectPopup
                  showRejectPopup={showRejectPopup}
                  setShowRejectPopup={setShowRejectPopup}
                  handleReject={comments => rejectDocument(comments)}
                />
              )}
              <ErrorPopup
                handleCloseErrorPopup={() => {
                  setErrorPopup(false);
                }}
                errorMessage={errorMessage}
                showErrorPopup={errorPopup}
              />
              {successPopup && (
                <CustomModal
                  show={successPopup}
                  type={APP_LABELS.SUCCESS}
                  message={successMessage}
                  submitButtonName="Ok"
                  onSubmit={() => {
                    setSuccessPopup(false);
                    getAssociationDetails();
                  }}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </BlockUi>
  );
};

Dashboard.propTypes = {
  handleUplaodFiles: PropTypes.func.isRequired,
  handleAssociationDetails: PropTypes.func.isRequired,
  handleToSetStatus: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

const mapDispatchToProps = {
  handleUplaodFiles: getCOLIuplaodFiles,
  handleAssociationDetails: getCOLIAssociationDetails,
  handleToSetStatus: setApproveRejectStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
