import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BasePage from '@BasePage';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';

const ProtectedRoute = ({ component, ...props }) => {
  const Page = component;
  const [redirect, setRedirect] = useState(false);
  const authToken = JSON.parse(sessionStorage.getItem('token'));

  // eslint-disable-next-line no-restricted-globals
  const locationPath = location;
  const path = locationPath.pathname.split('/')[1];
  const nextPath = path.split('-')[1];
  const firstPath = path.split('-')[0];
  const actualPath = locationPath.pathname.replace(/^\//, '');

  useEffect(() => {
    if (nextPath !== 'error') {
      if (authToken === null) {
        setRedirect(true);
      } else if (moment(get(authToken, 'expires')).unix() < moment().unix()) {
        sessionStorage.clear();
        setRedirect(true);
      } else if (
        (firstPath === 'employee' && !authToken?.user?.staff) ||
        (firstPath === 'annual' && !authToken?.user?.annualReports) ||
        (actualPath === 'annual/report' &&
          sessionStorage.getItem('selectedBatchYear') === null) ||
        (actualPath === 'annual/report/dashboard' &&
        sessionStorage.getItem('yusa_selected_associate') === null &&
          (get(authToken, 'user.role[0]') === APP_LABELS.YUSA_DATA_STEWARD ||
            get(authToken, 'user.role[0]') === APP_LABELS.YUSA_DATA_VIEWER ||
            get(authToken, 'user.role[0]') ===
              APP_LABELS.ALLIANCE_DATA_STEWARD ||
            get(authToken, 'user.role[0]') === APP_LABELS.YESS_DATA_STEWARD))
      ) {
        setRedirect(true);
      } else {
        setRedirect(false);
      }
    }
  });

  return (
    <BasePage includeHeader>
      <Page {...props} />
      {redirect && <Redirect to="/login" />}
    </BasePage>
  );
};

export default ProtectedRoute;
ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node
  ]).isRequired
};
