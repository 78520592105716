import { VOLUNTEERS_GET_URL, VOLUNTEERS_POST_URL } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getVolunteers = associationsNum => (dispatch, getState) =>
  asyncRequest({
    url: VOLUNTEERS_GET_URL(associationsNum),
    successAction: data => ({
      type: types.GET_VOLUNTEERS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_VOLUNTEERS_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postVolunteers = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: VOLUNTEERS_POST_URL(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_VOLUNTEERS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_VOLUNTEERS_FAILURE,
      payload: error
    }),
    dispatch
  });
export const resetReduxState = key => ({
  type: 'RESET_KEY',
  payload: { key }
});
