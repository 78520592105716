import * as types from './types';

const initialState = {
  GetMemberships: {},
  GetMembershipsNWM: {},
  PostMemberships: {},
  CrmProviderList: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_KEY':
      return {
        ...state,
        [action.payload.key]: {}
      };
    case types.GET_MEMBERSHIPS_SUCCESS: {
      return {
        ...state,
        GetMemberships: action.payload
      };
    }
    case types.GET_MEMBERSHIPS_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_MEMBERSHIPS_NWM_SUCCESS: {
      return {
        ...state,
        GetMembershipsNWM: action.payload
      };
    }
    case types.GET_MEMBERSHIPS_NWM_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_MEMBERSHIPS_SUCCESS: {
      return {
        ...state,
        PostMemberships: action.payload
      };
    }
    case types.POST_MEMBERSHIPS_FAILURE:
      return { ...state, error: action.payload };
    case types.GET_CRM_PROVIDER_LIST_SUCCESS: {
      return {
        ...state,
        CrmProviderList: action.payload
      };
    }
    case types.GET_CRM_PROVIDER_LIST_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
