export const GET_CDP_ASSOCIATE_DASHBOARD_SUCCESS =
  'GET_CDP_ASSOCIATE_DASHBOARD_SUCCESS';
export const GET_CDP_ASSOCIATE_DASHBOARD_FAILURE =
  'GET_CDP_ASSOCIATE_DASHBOARD_FAILURE';

export const GET_CDP_REDCAP_VIEW_DETAILS_REGISTRATION_SUCCESS =
  'GET_CDP_REDCAP_VIEW_DETAILS_REGISTRATION_SUCCESS';
export const GET_CDP_REDCAP_VIEW_DETAILS_REGISTRATON_FAILURE =
  'GET_CDP_REDCAP_VIEW_DETAILS_REGISTRATION_FAILURE';

export const GET_CDP_REDCAP_VIEW_DETAILS_VISITS_SUCCESS =
  'GET_CDP_REDCAP_VIEW_DETAILS_VISITS_SUCCESS';
export const GET_CDP_REDCAP_VIEW_DETAILS_VISITS_FAILURE =
  'GET_CDP_REDCAP_VIEW_DETAILS_VISITS_FAILURE';

export const GET_CDP_REDCAP_VIEW_DETAILS_ORG_CODE_SUCCESS =
  'GET_CDP_REDCAP_VIEW_DETAILS_ORG_CODE_SUCCESS';
export const GET_CDP_REDCAP_VIEW_DETAILS_ORG_CODE_FAILURE =
  'GET_CDP_REDCAP_VIEW_DETAILS_ORG_CODE_FAILURE';

export const GET_CDP_REDCAP_VIEW_DETAILS_COACH_SUCCESS =
  'GET_CDP_REDCAP_VIEW_DETAILS_COACH_SUCCESS';
export const GET_CDP_REDCAP_VIEW_DETAILS_COACH_FAILURE =
  'GET_CDP_REDCAP_VIEW_DETAILS_COACH_FAILURE';

export const GET_CDP_SOLERA_VIEW_DETAILS_SUCCESS =
  'GET_CDP_SOLERA_VIEW_DETAILS_SUCCESS';
export const GET_CDP_SOLERA_VIEW_DETAILS_FAILURE =
  'GET_CDP_SOLERA_VIEW_DETAILS_FAILURE';


export const SAVE_CDP_ASSOCIATION =
  'SAVE_CDP_ASSOCIATION';

export const CHECK_HIPAA_CERTIFICATE_SUCCESS =
  'CHECK_HIPAA_CERTIFICATE_SUCCESS';

export const CHECK_HIPAA_CERTIFICATE_FAILURE =
  'CHECK_HIPAA_CERTIFICATE_FAILURE';


export const POST_STATUS_UPDATE_SUCCESS = 'POST_STATUS_UPDATE_SUCCESS';
export const POST_STATUS_UPDATE_FAILURE = 'POST_STATUS_UPDATE_FAILURE';