import React, { useEffect, useState } from 'react';
import BasePage from '@BasePage';
import authentication from 'react-azure-b2c';
import { APP_LABELS } from '@config/app-config';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash.get';
import { getAuthConfig } from '@config/api-config';
import ymcalogo from '../../static/images/ymca-logo.svg';

const NewLogin = () => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    authentication.initialize(getAuthConfig());
    const authToken = JSON.parse(sessionStorage.getItem('token'));
    if (
      authToken !== null &&
      moment(get(authToken, 'expires')).unix() > moment().unix()
    ) {
      setRedirect(true);
    }
  });

  return (
    <BasePage includeHeader={false} includeFooter={false}>
      <div className="container-fluid">
        <div className="login-wrapper">
          <div className="row">
            <div className="col-lg-6 col-sm-12 text-center p-0 d-md-none d-lg-block">
              <div className="login-left-side">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <div className="login-banner">
                    <div className="banner-logo">
                      <img src={ymcalogo} alt="ymca-logo" />
                    </div>
                    <div className="banner-brief">
                      <h2 id="pri-head">{APP_LABELS.TOGETHER_WE}</h2>
                      <h2 id="mid-head">{APP_LABELS.CAN_BUILD_A}</h2>
                      <h2 id="post-head">{APP_LABELS.BETTER_US}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12">
              <div className="login-form-wrapper">
                <div className="login-section">
                  <img
                    src={ymcalogo}
                    className="d-lg-none d-md-block d-sm-block mb-4"
                    alt="ymca-logo"
                  />
                  <div className="ymca-login-heading mb-4">
                    {APP_LABELS.WELCOME_TO}
                    <br />
                    {APP_LABELS.LOGIN_PLATFORM}
                  </div>
                  <div className="login-form">
                    <p className="ymca-cachet-normal-dark-text">
                      {APP_LABELS.LOGIN_PAGE_MESSAGE}
                    </p>

                    <button
                      type="button"
                      className="btn ymca-primary-btn mt-4"
                      onClick={() => authentication.run()}
                    >
                      {APP_LABELS.GET_STARTED}
                    </button>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {redirect && <Redirect to="/home" />}
      </div>
    </BasePage>
  );
};

export default NewLogin;
