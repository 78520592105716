import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';

const Toaster = props => {
    const { status, message, closeTime } = props;

    const toastOptions = {
        position: 'top-center',
        autoClose: closeTime,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    };
    useEffect(() => {
        injectStyle();
        if (status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED) {
            toast.success(message, toastOptions);
        } else if (status?.toUpperCase() === APP_LABELS.STATUS_FAILED) {
            toast.error(message, toastOptions);
        }
    }, [status]);
    return (
        <div>
            <ToastContainer style={{ top: '125px' }} />
        </div>
    );
};
Toaster.propTypes = {
    status: PropTypes.string.isRequired,
    closeTime: PropTypes.number
};
Toaster.defaultProps = {
    closeTime: 5000,
    message: status == APP_LABELS.STATUS_COMPLETED ? 'Data has been saved successfully!.' : 'Error occured while processing request!.'
};
export default Toaster;
