import { USER_MANAGEMENT_UPLOAD_FILE } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

const getUserManagementUploadFile = uploadFileData => (dispatch, getState) =>
  asyncRequest({
    url: USER_MANAGEMENT_UPLOAD_FILE(),
    method: 'POST',
    data: uploadFileData,
    successAction: data => ({
      type: types.USER_MANAGEMENT_UPLOAD_FILE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.USER_MANAGEMENT_UPLOAD_FILE_FAILURE,
      payload: error
    }),
    dispatch
  });

export default getUserManagementUploadFile;
