import axios from 'axios';
import get from 'lodash.get';
import authentication from 'react-azure-b2c';
import apiRequest from '@redux/api/actions';
import { configureMocks } from './mock-config';
import { __DEV__, MOCK_MODE } from './app-config';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

// -----------------------------------------
// CORS - options (If not using local proxy)
// -----------------------------------------
// const { location } = window;
// const { protocol, hostname } = location;
// const apihostname = 'localhost'
// const BASE_API_URL = `${protocol}//${apihostname}`;
// -----------------------------------------
const client = axios.create({
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': window.location.origin,
    'Ocp-Apim-Subscription-Key': '3c92bec8b5df41c99f31bd7fc2057dd3' // For CORS (if not using proxy)
  }
});

if (MOCK_MODE) {
  configureMocks(client);
} else {
  client.interceptors.request.use(
    async config => {
      if (config.token) config.headers.Authorization = `${config.token}`;
      return config;
    },
    error => Promise.reject(error)
  );
}

export const dispatchErrorMessage = (
  error,
  failureAction,
  dispatch,
  isDefaultToast
) => {
  const message =
    'Oops it seems that something went wrong! Please try again in a few minutes.';
  if (isDefaultToast) {
    toast.error(message, {
      toastId: 'apiError'
    });
  }
  dispatch(failureAction(error));
};

/**
 * @param {string} url : url for request
 * @param {string} method : optional : GET, POST, UPDATE, DELETE. Default is GET
 * @param {string} data : optional: payload for POST, UPDATE, DELETE
 * @param {function} successAction : successAction after successful XHR request
 * @param {function} failureAction : failureAction after failure XHR request
 * @param {function} successCallback : optional : successCallback after successful XHR request
 */
export const asyncRequest = async ({
  url,
  method = 'GET',
  data = {},
  successAction,
  failureAction,
  successCallback,
  dispatch,
  token,
  isDefaultToast = false
}) => {
  dispatch(apiRequest());
  let hasNetworkError = false;
  let apiError;
  const authToken = JSON.parse(sessionStorage.getItem('token'));
  if (authToken !== undefined && authToken !== null) {
    token = `Bearer ${get(authToken, 'token', '')}`;
  }

  const options = Object.assign({}, { url }, { method }, { data }, { token });
  const response = await client.request(options).catch(error => {
    apiError = get(error, 'response.data');
    if (
      get(error, 'response.status') === 401 ||
      get(error, 'response.status') === 403
    ) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('associationname');
      sessionStorage.removeItem('strapitoken');
      sessionStorage.removeItem('dirty');
      sessionStorage.removeItem('persist:root');
      sessionStorage.removeItem('yusa_selected_associate');
      sessionStorage.clear();
      authentication.signOut();
    }
    hasNetworkError = true;
  });

  if (hasNetworkError) {
    dispatchErrorMessage(apiError, failureAction, dispatch, isDefaultToast);
    return apiError;
  }

  if (successCallback) {
    successCallback(response.data);
  }

  if (
    successAction &&
    (get(response, 'status') === 200 ||
      get(response, 'status') === 201 ||
      get(response, 'status') === 204)
  ) {
    dispatch(successAction(response.data));
  }
  return response;
};
