const MOYPCOLUMNS = [
  {
    Header: 'Upload Date',
    accessor: 'UploadDate',
    className: 'notranslate'
  },
  {
    Header: 'Source System',
    accessor: 'SourceSystem',
    className: 'notranslate'
  },
  {
    Header: 'Summary',
    accessor: 'Summary',
    disableSortBy: true
  },
  {
    Header: 'Status',
    accessor: 'Status',
    disableSortBy: true
  },
  {
    Header: '',
    accessor: 'DetailPage',
    disableSortBy: true
  }
];

export default MOYPCOLUMNS;
