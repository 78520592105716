import {
  POST_USER_DETAILS,
  GET_USER_DETAILS_BY_USER_ID,
  GET_MODULES
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const postUserRegistrationData = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: POST_USER_DETAILS(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.USER_REGISTRATION_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.USER_REGISTRATION_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getUserRegistrationDetails = userId => (dispatch, getState) =>
  asyncRequest({
    url: GET_USER_DETAILS_BY_USER_ID(userId),
    method: 'GET',
    successAction: data => ({
      type: types.USER_DETAILS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.USER_DETAILS_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getModuleList = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_MODULES(),
    method: 'GET',
    successAction: data => ({
      type: types.MODULE_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.MODULE_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });

export const resetUserDetails = () => dispatch => {
  dispatch({ type: types.RESET_USER_DETAILS });
};
