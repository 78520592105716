import React from 'react';
import PropTypes from 'prop-types';
import { ENUMS } from '@config/app-config';

const PreviewModal = props => {
  const { fileURL, documentTitle } = props;

  const setModalTitle = () => {
    if (documentTitle === ENUMS.BACKGROUND_SCREENING) {
      return 'Background Screening';
    }
    if (documentTitle === ENUMS.ATHLETE_PROTECTION_TRAINING) {
      return 'Athlete Protection Training';
    }
    if (documentTitle === ENUMS.SWIM_OFFICIAL_CERTIFICATION) {
      return 'Swim Official Certification';
    }
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog file-upload-popup">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {setModalTitle()}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <iframe
              src={fileURL}
              title="pdf viewer"
              width="100%"
              height="500px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewModal.propTypes = {
  documentTitle: PropTypes.string.isRequired,
  fileURL: PropTypes.string.isRequired
};

export default PreviewModal;
