/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { APP_LABELS, REGEX, FIELD_LENGTH } from '@config/app-config';
import {
  postUserRegistrationData,
  getUserRegistrationDetails,
  getModuleList,
  resetUserDetails
} from '@redux/user-management/user-form/actions';
import CustomModal from '@utilities/CustomModal';
import TextError from '@modules/shared/components/reusable-components/text-error';
import BackIcon from '@static/images/back-icon.svg';
import { UserRegistrationDetailsSelector } from '@redux/user-management/user-form/selectors';

const { ALPHABETS, ALPHABETSWITHHYPHEN } = REGEX;
const { VOLUNTEER_TEXTBOX, EMAIL_LENGTH } = FIELD_LENGTH;

const UserForm = props => {
  const {
    handleGetModuleList,
    handlePostUserRegistrationData,
    handleGetUserRegistrationDetails,
    UserRegistrationDetailsSelectorData,
    handelResetUserDetails,
    computedMatch
  } = props;

  const [loader, setLoader] = useState(false);
  let isFormValid;
  const [moduleList, setModuleList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(
    'User has been created successfully!'
  );
  const [successPopup, setSuccessPopup] = useState(false);

  const validationObj = {
    firstName: Yup.string()
      .nullable()
      .required('* First name is required.')
      .min(2, '* Please enter more than 1 characters.')
      .matches(
        ALPHABETSWITHHYPHEN,
        '* Please enter only characters and hyphens.'
      ),
    lastName: Yup.string()
      .nullable()
      .required('* Last name is required.')
      .min(2, '* Please enter more than 1 characters.')
      .matches(ALPHABETS, '* Please enter only characters.'),
    emailAddress: Yup.string()
      .nullable()
      .email('* Invalid email format.')
      .required('* Email is required.'),
    moduleCheck: Yup.array()
      .nullable()
      .min(1, '* Please select minimum one option.')
      .required('* Please select minimum one option.'),
    statusValue: Yup.string().required('* status is required')
  };
  let validationSchema = Yup.object(validationObj);

  const initialValues = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    moduleCheck: [],
    statusValue: ''
  };

  const setRoleData = userModuleRoles => {
    const selectedData = [];
    const userModuleRolesData = JSON.parse(JSON.stringify(userModuleRoles));

    for (const role of userModuleRolesData.roles) {
      selectedData.push(role.roleName);
    }
    return selectedData;
  };

  const setModuleData = userModuleRoles => {
    const selectedData = [];
    const userModuleRolesData = JSON.parse(JSON.stringify(userModuleRoles));

    for (const module of userModuleRolesData) {
      selectedData.push(module.modulename);
    }
    return selectedData;
  };

  const setEditValue = () => {
    let editValues = {
      emailAddress: '',
      firstName: '',
      lastName: '',
      moduleCheck: [],
      statusValue: ''
    };
    if (UserRegistrationDetailsSelectorData?.user) {
      editValues = {
        emailAddress: UserRegistrationDetailsSelectorData?.user?.emailAddress,
        firstName: UserRegistrationDetailsSelectorData?.user?.firstName,
        lastName: UserRegistrationDetailsSelectorData?.user?.lastName,
        moduleCheck: setModuleData(
          UserRegistrationDetailsSelectorData?.user?.userModuleRoles
        ),
        statusValue:
          UserRegistrationDetailsSelectorData?.user?.userStatus?.toString()
      };

      const userData =
        UserRegistrationDetailsSelectorData?.user?.userModuleRoles;
      if (userData) {
        for (const module of userData) {
          editValues[`moduleId_${module.moduleId}_role`] = setRoleData(module);
        }
      }
      return editValues;
    }
    return editValues;
  };

  const handleUserDetails = () => {
    setLoader(true);
    handleGetUserRegistrationDetails(get(computedMatch, 'params.userID')).then(
      res => {
        if (res.status === 200) {
          setLoader(false);
        } else {
          setLoader(false);
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      }
    );
  };

  useEffect(() => {
    window.scroll(0, 0);
    setLoader(true);
    handleGetModuleList().then(res => {
      if (res.status === 200) {
        setModuleList(res?.data?.modules);
        const moduleData = res?.data?.modules;

        if (moduleData) {
          for (const module of moduleData) {
            initialValues[`moduleId_${module.moduleId}_role`] = [];
            validationObj[`moduleId_${module.moduleId}_role`] = Yup.array()
              .nullable()
              .required('* Please select minimum one option.')
              .min(1, '* Please select minimum one option.');
          }
        }
        validationSchema = Yup.object(validationObj);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });

    if (computedMatch?.params?.userID) {
      handleUserDetails();
    } else {
      handelResetUserDetails();
    }
  }, []);

  const processModuleList = values => {
    const moduleListData = JSON.parse(JSON.stringify(moduleList));
    const processedModule = [];

    for (const moduleName of values.moduleCheck) {
      const tempArray = moduleListData.filter(
        module => module.modulename === moduleName
      );
      if (tempArray.length > 0) processedModule.push(...tempArray);
    }

    return JSON.parse(JSON.stringify(processedModule));
  };

  const processModuleRoleList = (values, setFieldError) => {
    const selectedModuleList = processModuleList(values);

    let tempSelectedRoles = [];

    for (const module of selectedModuleList) {
      tempSelectedRoles = [];
      if (
        values.hasOwnProperty(`moduleId_${module.moduleId}_role`) &&
        values[`moduleId_${module.moduleId}_role`].length > 0
      ) {
        isFormValid = true;
        for (const roleValue of values[`moduleId_${module.moduleId}_role`]) {
          const tempRoleArray = module.roles.filter(
            role => role.roleName === roleValue
          );
          if (tempRoleArray.length > 0) {
            tempSelectedRoles.push(...tempRoleArray);
          } else {
            setLoader(false);
            isFormValid = false;
            return;
          }
        }
      } else {
        setLoader(false);
        setFieldError('moduleCheck', '* Please select role for module.');
        isFormValid = false;
        return;
      }
      module.roles = JSON.parse(JSON.stringify(tempSelectedRoles));
    }

    return selectedModuleList;
  };

  const onSubmit = (values, { setFieldError }) => {
    setLoader(true);

    const PayloadObject = {
      emailAddress: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      userStatus: values.statusValue === 'true',
      userModuleRoles: processModuleRoleList(values, setFieldError)
    };
    if (computedMatch?.params?.userID) {
      PayloadObject.userId = computedMatch?.params?.userID;
      setSuccessMessage('User information has been updated successfully!');
    }
    if (isFormValid === false) {
      setLoader(false);
      return;
    }

    handlePostUserRegistrationData(PayloadObject).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setLoader(false);
        setSuccessPopup(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper volunteer-registeration-wrapper">
          <div className="container">
            <section className="dashboard-header mb-4">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2 className="ymca-sub-heading mb-0">User Management</h2>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                  <Link
                    className="ymca-title-link"
                    to="/userManagement/dashboard"
                  >
                    <img
                      src={BackIcon}
                      className="ymca-back-link"
                      alt="back-icon"
                    />
                    Back
                  </Link>
                </div>
              </div>
            </section>
          </div>
          <hr className="custom-hr" />
          <div className="container">
            <div className="ymca-dark-para-2 volunteer-details">
              User Details
            </div>
            <Formik
              initialValues={setEditValue() || initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors }) => (
                <Form autoComplete="off" className="ymca-registeration-form">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-md-6">
                          <span className="mb-2 required">
                            First Name
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              maxLength={VOLUNTEER_TEXTBOX}
                              name="firstName"
                              className="form-control"
                              type="text"
                              placeholder="Enter First Name"
                            />
                            <ErrorMessage
                              component={TextError}
                              name="firstName"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <span className="mb-2 required">
                            Last Name
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              maxLength={VOLUNTEER_TEXTBOX}
                              name="lastName"
                              className="form-control"
                              type="text"
                              placeholder="Enter Last Name"
                            />
                            <ErrorMessage
                              component={TextError}
                              name="lastName"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-md-6">
                          <span className="mb-2 required">
                            Email Address
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              maxLength={EMAIL_LENGTH}
                              name="emailAddress"
                              className="form-control"
                              type="text"
                              placeholder="Enter Email Address"
                            />
                            <ErrorMessage
                              component={TextError}
                              name="emailAddress"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="ymca-modal-content mt-0 pt-0">
                      Status<span className="text-mandatory">&#42;</span>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-6 col-sm-6">
                        <Field name="statusValue" value="true" type="radio" />
                        <span className="ms-2">Active</span>
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-6">
                        <Field name="statusValue" value="false" type="radio" />
                        <span className="ms-2">Inactive</span>
                      </div>
                    </div>
                    <ErrorMessage component={TextError} name="statusValue" />
                  </div>

                  <div className="ymca-modal-content mt-3 pb-0">
                    Role Assignment
                  </div>
                  {moduleList?.map(module => (
                    <div
                      className="card outline-card mt-3"
                      key={module.modulename}
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="ymca-modal-content mb-1">
                            Module
                            <span className="text-mandatory">&#42;</span>
                          </div>
                          <div className="form-check ymca-check-box pb-0">
                            <Field
                              name="moduleCheck"
                              className="form-check-input"
                              type="checkbox"
                              value={module.modulename}
                              id="flexCheckChecked"
                            />
                            <span
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              <span className="table-link">
                                {module.modulename}
                              </span>
                            </span>
                          </div>
                          <ErrorMessage
                            component={TextError}
                            name="moduleCheck"
                          />
                        </div>
                        <div className="col-lg-8">
                          <div className="ymca-modal-content mb-1">
                            Role<span className="text-mandatory">&#42;</span>
                          </div>
                          <div className="row">
                            {module.roles.map(role => (
                              <div
                                className="col-lg-3 col-md-6 col-sm-12"
                                key={`${module.modulename}.role.${role.roleName}`}
                              >
                                <div className="form-check ymca-check-box pb-0">
                                  <Field
                                    name={`moduleId_${module.moduleId}_role`}
                                    className="form-check-input"
                                    type="checkbox"
                                    value={
                                      values.moduleCheck.includes(
                                        module.modulename
                                      )
                                        ? role.roleName
                                        : ''
                                    }
                                    id={`${module.modulename}.role.${role.roleId}.${role.roleName}`}
                                    disabled={
                                      !values.moduleCheck.includes(
                                        module.modulename
                                      )
                                    }
                                  />
                                  <span
                                    className="form-check-label"
                                    htmlFor={`${module.modulename}.role.${role.roleId}.${role.roleName}`}
                                  >
                                    <span className="table-link">
                                      {role.roleName}
                                    </span>
                                  </span>
                                </div>
                                <ErrorMessage
                                  component={TextError}
                                  name={`moduleId_${module.moduleId}_role`}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {!isFormValid && (
                    <div className="errorNote">
                      * Please fill required fields.
                    </div>
                  )}

                  <div className="text-end">
                    <button
                      className="btn ymca-primary-btn login-btn mt-0 me-3"
                      type="submit"
                      onClick={() => scrollToErrors(errors)}
                    >
                      Submit
                    </button>
                    <Link
                      to="/userManagement/dashboard"
                      className="btn ymca-primary-outline-btn login-btn mt-0 me-3"
                      type="button"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </main>
      {redirect && <Redirect to="/userManagement/dashboard" />}
      {successPopup && (
        <CustomModal
          show={successPopup}
          type={APP_LABELS.SUCCESS}
          message={successMessage}
          submitButtonName="Ok"
          onSubmit={() => {
            setRedirect(true);
          }}
        />
      )}
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

UserForm.propTypes = {
  handleGetModuleList: PropTypes.func.isRequired,
  handlePostUserRegistrationData: PropTypes.func.isRequired,
  handleGetUserRegistrationDetails: PropTypes.func.isRequired,
  UserRegistrationDetailsSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  handelResetUserDetails: PropTypes.func.isRequired,
  computedMatch: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handelResetUserDetails: resetUserDetails,
  handleGetModuleList: getModuleList,
  handlePostUserRegistrationData: postUserRegistrationData,
  handleGetUserRegistrationDetails: getUserRegistrationDetails
};

const mapStateToProps = state => ({
  UserRegistrationDetailsSelectorData: UserRegistrationDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(UserForm));
