const TableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'notranslate'
  },
  {
    Header: 'Email Id',
    accessor: 'emailAddress',
    className: 'notranslate'
  },
  {
    Header: 'Module',
    accessor: 'moduleName',
    className: 'notranslate'
  },
  {
    Header: 'Role',
    accessor: 'roleName',
    className: 'notranslate'
  },
  {
    Header: 'Status',
    accessor: 'userStatus',
    className: 'notranslate'
  }
];

export default TableColumns;
