import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AssociationNumberSelector } from '@redux/dashboard/selectors';
import axios from 'axios';
import GetModulesConfigSelector from '@redux/module-config/selectors';
import downloadPdfIcon from '@static/images/download-pdf.svg';
import { GET_ANNUALREPORT_PDF_DOWNLOAD_PATH } from '@config/api-config';
import AnnualReportsDashboardDataSelector from '@redux/annual-reports-dashboard/selectors';
import {
  getAnnualReportsDashboardData,
  reopenAnnualReport
} from '@redux/annual-reports-dashboard/actions';
import get from 'lodash.get';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { APP_LABELS, STORAGE_KEYS } from '@config/app-config';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import successIcon from '@static/images/success_icon.svg';
import loadingIcon from '@static/images/loading_icon.svg';
import dropdownIcon from '@static/images/dropdown-icon.svg';
import blueDotIcon from '@static/images/blue-dot-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import CustomModal from '@utilities/CustomModal';
import SecureStorage from '@utilities/SecureStorage';
import moment from 'moment';
import Card from './Card';

const DatesElement = reportData => (
  <div className="col-md-3 YMCA-flexxey">
    <div className="notranslate">
      <div className="date_head">
        <h6>Start Date</h6>
      </div>
      <div className="date">
        <p>
          {reportData?.startDate
            ? moment(reportData?.startDate).format('MMMM DD, YYYY')
            : '-'}
        </p>
      </div>
    </div>
    <div className="notranslate">
      <div className="date_head">
        <h6>End Date</h6>
      </div>
      <div className="date">
        <p>
          {reportData?.endDate
            ? moment(reportData?.endDate).format('MMMM DD, YYYY')
            : '-'}
        </p>
      </div>
    </div>
  </div>
);

const handleSetYear = selectedBatchYear => {
  sessionStorage.setItem('selectedBatchYear', selectedBatchYear);
};

const DownloadElement = (reportData, downloadPdfApi) => (
  <div className="col-md-3 mb-3">
    <div className="date_head ms-5">
      <h6>Report</h6>
    </div>
    <div className="c-pointer status-icon ymca-success-text d-flex align-items-center ms-5 ymca-annualReportt ">
      <img src={downloadPdfIcon} alt="download-pdf-icon" className="me-1" />
      <span
        className="success-hover"
        tabIndex="0"
        aria-hidden="true"
        role="button"
        onClick={() => downloadPdfApi(reportData?.year)}
      >
        Download Pdf
      </span>
    </div>
  </div>
);

const statusElement = (
  reportData,
  role,
  selectedBatchYear,
  setSelectedBatchYear,
  setReOpenYear,
  setAlertPopup
) => {
  const location = {
    pathname: '/annual/report',
    state: { selectedYear: reportData?.year }
  };

  return (
    <div
      className={`${
        ((role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD) &&
          reportData?.status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED) ||
        reportData?.status?.toUpperCase() === APP_LABELS.STATUS_YETTOBEGIN
          ? 'col-md-3'
          : 'col-md-2 mb-3'
      }`}
    >
      <div className="progress_link align-items-center d-flex mt-2 YMCA-flexxey">
        {(role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD) &&
          reportData?.status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED && (
            <div className="ml-60">
              <button
                type="button"
                className="btn btn-transparent ymca-link text-decoration-none ymca-unsubmit"
                onClick={() => {
                  setReOpenYear(reportData?.year);
                  setAlertPopup(true);
                }}
              >
                Re-open
              </button>
            </div>
          )}

        <div>
          <div className="date_head">
            <h6>Status</h6>
          </div>
          {selectedBatchYear === reportData?.year ? (
            <Link
              to={location}
              onClick={() => handleSetYear(selectedBatchYear)}
            >
              View Progress
            </Link>
          ) : (
            <button
              type="button"
              className="btn btn-transparent ymca-link text-decoration-none p-0 mb-1"
            >
              <img
                src={blueDotIcon}
                alt="blue-dot-icon"
                className="me-1 mb-1"
              />
              {reportData?.status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                ? 'Submitted'
                : 'In Progress'}
            </button>
          )}
          <button
            type="button"
            className="btn btn-transparent mb-1"
            onClick={() => {
              if (selectedBatchYear) setSelectedBatchYear(null);
              else setSelectedBatchYear(reportData?.year);
            }}
          >
            <img
              src={dropdownIcon}
              alt="dropdown-icon"
              className={`ms-4 ${
                selectedBatchYear === reportData?.year && 'rotate-180'
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const HeaderContent = (
  reportData,
  role,
  downloadPdfApi,
  selectedBatchYear,
  setSelectedBatchYear,
  setReOpenYear,
  setAlertPopup
) => (
  <section
    className={`annual-report mt-4 ${
      selectedBatchYear === reportData?.year ? '' : 'annual-report-box'
    }`}
  >
    <div className="row align-items-center">
      <div className="col-md-3">
        <div className="report-2022">
          <h6 className="ymca-annualReportt">
            Annual Reports {reportData?.year}
          </h6>
        </div>
      </div>
      {DatesElement(reportData)}
      {(reportData?.status?.toUpperCase() === APP_LABELS.STATUS_YETTOBEGIN ||
        role === APP_LABELS.BRANCH_DATA_STEWARD) && (
        <div className="col-md-3" />
      )}
      {reportData?.status?.toUpperCase() !== APP_LABELS.STATUS_YETTOBEGIN &&
        role !== APP_LABELS.BRANCH_DATA_STEWARD &&
        DownloadElement(reportData, downloadPdfApi)}

      {statusElement(
        reportData,
        role,
        selectedBatchYear,
        setSelectedBatchYear,
        setReOpenYear,
        setAlertPopup
      )}
    </div>
  </section>
);

const AnnualreportDashboard = props => {
  const {
    LoginUserData,
    DashboardData,
    ModulesConfigData,
    handlerTogetAnnualReportsData,
    handleReopenAnnualReport,
    AssociationNumberData
  } = props;

  const [selectedBatchYear, setSelectedBatchYear] = useState(
    DashboardData[0]?.year
  );
  const [loader, setLoader] = useState(false);
  const role = get(LoginUserData, 'user.role[0]', null);
  const [alertPopup, setAlertPopup] = useState(false);
  const [reOpenYear, setReOpenYear] = useState(null);
  const [afterRevokePopup, setAfterRevokePopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const [instructions, setInstructions] = useState('');
  const currentYear = get(ModulesConfigData, 'data')?.find(
    o => o?.moduleName === 'Annual Reports'
  )?.year;

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', null);

  const getAnnualReportData = () => {
    setAfterRevokePopup(false);
    setLoader(true);
    handlerTogetAnnualReportsData(associationNumber).then(() => {
      setLoader(false);
    });
    setSelectedBatchYear(`${currentYear}`);
  };

  const revokeAPICall = () => {
    setAlertPopup(false);
    setReOpenYear(null);
    setLoader(true);
    handleReopenAnnualReport(associationNumber, reOpenYear).then(res => {
      if (res?.data?.isSuccess) {
        setAfterRevokePopup(true);
      } else {
        setErrorPopup(true);
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    SecureStorage.setItem(
      STORAGE_KEYS.DASHBOARD_URL,
      '/annual/report/dashboard'
    );
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'annual-report-association-dashboard'
      ]?.Instruction
    );
    window.scroll(0, 0);
    getAnnualReportData();
  }, []);

  const downloadPdfApi = year => {
    const associationname = get(
      AssociationNumberData,
      'data.association.associationName',
      ''
    );
    setLoader(true);

    const authToken = JSON.parse(sessionStorage.getItem('token'));
    const Offset = new Date().getTimezoneOffset();
    axios
      .get(
        GET_ANNUALREPORT_PDF_DOWNLOAD_PATH(
          role === APP_LABELS.YUSA_DATA_STEWARD ||
            role === APP_LABELS.YUSA_DATA_VIEWER ||
            role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
            role === APP_LABELS.YESS_DATA_STEWARD
            ? get(associateData, 'associationNumber', '')
            : get(LoginUserData, 'user.associations[0]', '0'),
          year,
          Offset
        ),
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'blob',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': window.location.origin,
            'Ocp-Apim-Subscription-Key': APP_LABELS.APIM_KEY,
            Authorization: `Bearer ${get(authToken, 'token', '')}`
          }
        }
      )
      .then(res => {
        const fileName = `${
          role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
            ? get(associateData, 'associationName', '')
            : associationname
        }-AnnualReport.pdf`;
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName.replaceAll('"', ''));
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false); 
      })
      .catch(() => {
        setLoader(false);
        setErrorPopup(true);
      });
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="ymca-data-wrapper dashboard-wrapper">
          <div className="container">
            {(role === APP_LABELS.YUSA_DATA_STEWARD ||
              role === APP_LABELS.YUSA_DATA_VIEWER ||
              role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
              role === APP_LABELS.YESS_DATA_STEWARD) && (
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-12" />
                <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
                  <Link className="ymca-title-link" to="/annual/yusa/dashboard">
                    <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                    Back
                  </Link>
                </div>
              </div>
            )}

            <section>
              <div className="ymca-sorroundings">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="ymca-instructionBoxes my-0 pb-0 pt-2">
                    <p className="ymca-instructTitle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: instructions
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3 align-items-center">
                <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="reports_head">
                    <h6 className="ymca-sub-heading">
                      {APP_LABELS.ANNUAL_REPORTS}
                    </h6>
                  </div>
                </div>
                {selectedBatchYear && (
                  <div className="col-lg-5 col-md-5 col-sm-12 d-flex justify-content-lg-end status-content">
                    <div className="checkbox_content d-flex">
                      <div className="checkbox_1">
                        <img src={successIcon} alt="success" />
                      </div>
                      <div className="content ps-2">
                        <h6>Section Completed</h6>
                        <p>
                          {'(All required fields within'}
                          <br />
                          {' the section are completed)'}
                        </p>
                      </div>
                    </div>
                    <div className="checkbox_content_1 d-flex">
                      <div className="checkbox">
                        <img src={loadingIcon} alt="loading" />
                      </div>
                      <div className="content text-start ps-2">
                        <h6>Section In Progress</h6>
                        <p>
                          {'(Certain fields within the '}
                          <br />
                          {'section are incomplete)'}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>

            {Array.isArray(DashboardData) &&
              DashboardData?.map(reportData => {
                if (
                  moment(new Date()).isAfter(reportData?.startDate, true) ||
                  associationNumber === '9999'
                ) {
                  return (
                    <>
                      {HeaderContent(
                        reportData,
                        role,
                        downloadPdfApi,
                        selectedBatchYear,
                        setSelectedBatchYear,
                        setReOpenYear,
                        setAlertPopup
                      )}

                      {selectedBatchYear === reportData?.year && (
                        <section>
                          <div className="row mt-4">
                            {reportData?.dashboardDTOs?.map(dto => (
                              <Card
                                id={
                                  reportData?.year >= '2023'
                                    ? dto?.sequenceNo
                                    : dto.formId
                                }
                                name={dto?.name}
                                status={dto?.status?.toUpperCase()}
                                lastUpdatedBy={dto?.updatedByName}
                                lastUpdatedDate={dto?.updatedDateTime}
                                userRole={role}
                                key={
                                  reportData?.year >= '2023'
                                    ? dto?.sequenceNo
                                    : dto.formId
                                }
                                year={dto?.year}
                              />
                            ))}
                          </div>
                        </section>
                      )}
                    </>
                  );
                }
              })}
          </div>
        </div>
      </main>

      <CustomModal
        show={alertPopup}
        type={APP_LABELS.INFORMATION}
        message="Are you sure you want to re-open Annual report?"
        submitButtonName="Continue"
        onSubmit={revokeAPICall}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={() => {
          setAlertPopup(false);
          setReOpenYear(null);
        }}
      />

      <CustomModal
        show={afterRevokePopup}
        message={APP_LABELS.REOPEN_STATUS}
        type={APP_LABELS.SUCCESS}
        submitButtonName={APP_LABELS.OK}
        onSubmit={getAnnualReportData}
      />

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={APP_LABELS.COMMON_ERROR_MESSAGE}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

AnnualreportDashboard.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  DashboardData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handlerTogetAnnualReportsData: PropTypes.func.isRequired,
  handleReopenAnnualReport: PropTypes.func.isRequired,
  AssociationNumberData: PropTypes.objectOf(PropTypes.any).isRequired,
  ModulesConfigData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handlerTogetAnnualReportsData: getAnnualReportsDashboardData,
  handleReopenAnnualReport: reopenAnnualReport
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  DashboardData: AnnualReportsDashboardDataSelector(state),
  AssociationNumberData: AssociationNumberSelector(state),
  ModulesConfigData: GetModulesConfigSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnualreportDashboard);
