import { CDP_DASHBOARD_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getCdpAssociateDashboard =
  (associationNumber, programid, showHidden) => (dispatch, getState) =>
    asyncRequest({
      url: CDP_DASHBOARD_URLS.ASSOCIATION(associationNumber, programid, showHidden),
      method: 'GET',
      successAction: data => ({
        type: types.GET_CDP_ASSOCIATE_DASHBOARD_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_CDP_ASSOCIATE_DASHBOARD_FAILURE,
        payload: error
      }),
      dispatch
    });

export const saveCdpAssociation = payload => dispatch => {
  dispatch({
    type: types.SAVE_CDP_ASSOCIATION,
    payload
  });
};


export const checkHipaaCertificate = () => (dispatch, getState) =>
  asyncRequest({
    url: CDP_DASHBOARD_URLS.HIPAA_CERTIFICATE(),
    method: 'GET',
    successAction: data => ({
      type: types.CHECK_HIPAA_CERTIFICATE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.CHECK_HIPAA_CERTIFICATE_FAILURE,
      payload: error
    }),
    dispatch
  });


export const updateViewStatus = (cdpDataFetchId, status) => (dispatch, getState) =>
  asyncRequest({
    url: CDP_DASHBOARD_URLS.UPDATE_VIEW_STATUS(cdpDataFetchId, status),
    method: 'POST',
    successAction: data => ({
      type: types.POST_STATUS_UPDATE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_STATUS_UPDATE_FAILURE,
      payload: error
    }),
    dispatch
  });