import {
  GET_ASSOCIATION_NUMBER,
  GET_ASSOCIATION_SUMMARY,
  GET_ASSOCIATION_CHANGE_LOG,
  GET_ASSOCIATION_LIST,
  GET_ASSOCIATION_SUMMARY_DATE_RANGE,
  GET_ASSOCIATION_MOYP_CHANGE_LOG
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getAssociationChangeLog =
  (associationNumber, startDate, endDate) => (dispatch, getState) =>
    asyncRequest({
      url: GET_ASSOCIATION_CHANGE_LOG(associationNumber, startDate, endDate),
      successAction: data => ({
        type: types.ASSOCIATION_CHANGE_LOG_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_CHANGE_LOG_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAssociationSummary =
  (startDate, endDate, Offset) => (dispatch, getState) =>
    asyncRequest({
      url: GET_ASSOCIATION_SUMMARY(startDate, endDate, Offset),
      successAction: data => ({
        type: types.ASSOCIATION_SUMMARY_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_SUMMARY_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAssociationNumber = AssociationNumber => (dispatch, getState) =>
  asyncRequest({
    url: GET_ASSOCIATION_NUMBER(AssociationNumber),
    successAction: data => ({
      type: types.ASSOCIATION_NUMBER_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ASSOCIATION_NUMBER_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getAssociationList =
  (startDate, endDate, includeAllYs, Offset) => (dispatch, getState) =>
    asyncRequest({
      url: GET_ASSOCIATION_LIST(startDate, endDate, includeAllYs, Offset),
      method: 'GET',
      successAction: data => ({
        type: types.ASSOCIATION_LIST_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_LIST_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getAssociationSummaryByDateRange =
  (AssociationNumber, fromDate, toDate, Offset) => (dispatch, getState) =>
    asyncRequest({
      url: GET_ASSOCIATION_SUMMARY_DATE_RANGE(
        AssociationNumber,
        fromDate,
        toDate,
        Offset
      ),
      successAction: data => ({
        type: types.ASSOCIATION_SUMMARY_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_SUMMARY_FAILURE,
        payload: error
      }),
      dispatch
    });
export const getAssociationMoypChangeLog =
  (associationNumber, startDate, endDate, Offset) => (dispatch, getState) =>
    asyncRequest({
      url: GET_ASSOCIATION_MOYP_CHANGE_LOG(
        associationNumber,
        startDate,
        endDate,
        Offset
      ),
      successAction: data => ({
        type: types.ASSOCIATION_MOYP_CHANGE_LOG_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ASSOCIATION_MOYP_CHANGE_LOG_FAILURE,
        payload: error
      }),
      dispatch
    });
