import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { __DEV__, REDUX_LOGGER_ENABLED } from '@config/app-config';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import * as reducers from './redux';
import { createFilter } from './utils/redux-persist-transform-filter';

/**
 * Redux Logger: https://github.com/LogRocket/redux-logger
 * Redux Devtools Extension: https://github.com/zalmoxisus/redux-devtools-extension/
 */

export default function configureStore() {
  // const saveSubsetFilter = createFilter('RedCapRegistrationStore', [
  //   'dataSystem'
  // ]);
  // const persistConfig = {
  //   key: 'root',
  //   storage,
  //   blacklist: ['uploadDataStore', 'cdpDetailsStore'],
  //   transforms: [saveSubsetFilter]
  // };
  const cdpAssociateDashboardStore = createFilter(
    'cdpAssociateDashboardStore',
    ['associationData']
  );

  const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    blacklist: ['uploadDataStore', 'cdpDetailsStore'],
    transforms: [cdpAssociateDashboardStore]
  };
  const rootReducer = combineReducers(reducers);
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  /* eslint-disable-next-line no-underscore-dangle */
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = composeEnhancer(middlewareEnhancer);

  if (__DEV__ && REDUX_LOGGER_ENABLED) {
    const logger = createLogger({ duration: true });
    middlewares.push(logger);
  }

  const store = createStore(persistedReducer, enhancers);
  const persistor = persistStore(store);

  if (__DEV__ && module.hot) {
    module.hot.accept('./redux', () => store.replaceReducer(rootReducer));
  }

  return { store, persistor };
}
