export const BRANCHES_COLUMNS = () => [
  {
    Header: 'Branch Name',
    accessor: 'branchName',
    className: 'notranslate ymca-word-break'
  },

  {
    Header: 'Address',
    accessor: 'branchAddress',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'No.Of Swim Programs',
    accessor: 'programsCount',
    className: 'notranslate'
  },
  {
    Header: 'No.Of Profiles',
    accessor: 'profilesCount',
    className: 'notranslate '
  },
  {
    Header: 'Last Updated',
    accessor: 'lastUpdatedDate',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    className: 'notranslate'
  }
];

export const SWIM_PROGRAM_COLUMNS = () => [
  {
    Header: 'Swim Program Name',
    accessor: 'programName',
    className: 'notranslate ymca-word-break '
  },

  {
    Header: 'Branch Name',
    accessor: 'branchName',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'Instructor Name',
    accessor: 'instructorName',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'Profile Count',
    accessor: 'profilesCount',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'Grant Name',
    accessor: 'specialGrant',
    className: 'notranslate ymca-word-break',
    Cell: ({ value }) => {
      if (value) return value;
      return '-';
    }
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    className: 'notranslate'
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'details',
    className: 'notranslate'
  }
];

export const PROFILE_COLUMNS = () => [
  {
    Header: 'Reference Id',
    accessor: 'referenceID',
    className: 'notranslate ymca-word-break '
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    className: 'notranslate'
  },
  {
    Header: 'Age (Date)',
    accessor: 'age',
    className: 'notranslate'
  },
  {
    Header: 'Branch Name',
    accessor: 'branchName',
    className: 'notranslate'
  },
  {
    Header: 'Swim Program Name',
    accessor: 'swimprogram',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'Ethnicity',
    accessor: 'ethinicity',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'Zip',
    accessor: 'zip',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'details',
    className: 'notranslate'
  }
];
