import * as types from './types';

const initialState = {
  YusaAssociationList: {},
  YusaYearList: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.YUSA_ASSOCIATION_LIST_SUCCESS: {
      return {
        ...state,
        YusaAssociationList: action.payload
      };
    }
    case types.YUSA_ASSOCIATION_LIST_FAILURE:
      return { ...state, error: action.payload };

    case types.YUSA_YEAR_LIST_SUCCESS: {
      return {
        ...state,
        YusaYearList: action.payload
      };
    }
    case types.YUSA_YEAR_LIST_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
