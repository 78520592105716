import {
  GET_BRANCH_DETAILS,
  GET_AR_BRANCH_LIST,
  SET_BRANCH_DETAILS,
  GET_COUNTRIES_LIST
  // GET_CRM_PROVIDER_LIST
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getBranchDetails =
  (associationId, branchId) => (dispatch, getState) =>
    asyncRequest({
      url: GET_BRANCH_DETAILS(associationId, branchId),
      method: 'GET',
      successAction: data => ({
        type: types.GET_BRANCH_DETAILS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_BRANCH_DETAILS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const saveBranchDetails = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: SET_BRANCH_DETAILS(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.SET_BRANCH_DETAILS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.SET_BRANCH_DETAILS_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getBranchList = associationId => (dispatch, getState) =>
  asyncRequest({
    url: GET_AR_BRANCH_LIST(associationId),
    method: 'GET',
    successAction: data => ({
      type: types.GET_LIST_OF_BRANCHES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_LIST_OF_BRANCHES_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getCountriesList = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_COUNTRIES_LIST(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_LIST_OF_COUNTRIES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_LIST_OF_COUNTRIES_FAILURE,
      payload: error
    }),
    dispatch
  });
// export const getCrmProviderList = () => (dispatch, getState) =>
// asyncRequest({
//   url:GET_CRM_PROVIDER_LIST(),
//   method: 'GET',
//   successAction: data => ({
//     type: types.GET_CRM_PROVIDER_LIST_SUCCESS,
//     async: true,
//     payload: data,
//     state: getState()
//   }),
//   failureAction: error => ({
//     type: types.GET_CRM_PROVIDER_LIST_FAILURE,
//     payload: error
//   }),
//   dispatch
// });
