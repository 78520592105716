// eslint-disable-next-line import/prefer-default-export
export const GetMembershipsSelector = ({ memberships: { GetMemberships } }) =>
  GetMemberships;

export const GetMembershipsNWMSelector = ({
  memberships: { GetMembershipsNWM }
}) => GetMembershipsNWM;

export const PostMembershipsSelector = ({ memberships: { PostMemberships } }) =>
  PostMemberships;
export const CrmProviderListDataSelector = ({
  memberships: { CrmProviderList }
}) => CrmProviderList;
