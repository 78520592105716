import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import moment from 'moment';
import crossiconblue from '@static/images/cross-icon-blue.svg';

const ViewDetailsPopup = props => {
  const {
    popupTitle,
    popupData,
    showViewDetailsPopup,
    handleCloseNewStaffPopup
  } = props;

  const showHideClassName = showViewDetailsPopup
    ? 'modal d-block'
    : 'modal d-none';
  return (
    <div
      className={showHideClassName}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ymca-modal view-details-modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="ymca-black-sub-heading">{popupTitle}</div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseNewStaffPopup}
            >
              <img
                src={crossiconblue}
                alt="cross-icon-blue"
                className="close-icon img-fluid"
              />
            </button>
          </div>
          <div className="modal-body px-0">
            <div className="ymca-modal-title">Program Details</div>
            <div className="row px-3">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.ASSOCIATION_NAME}
                </div>
                <div className="ymca-modal-content notranslate">
                  YMCA OF Great Richmond
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Branch Name</div>
                <div className="ymca-modal-content notranslate">YMCA</div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Program Name</div>
                <div className="ymca-modal-content notranslate">
                  School Fridays
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Start Date</div>
                <div className="ymca-modal-content notranslate">05/19/2015</div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">End Date</div>
                05/19/2016
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Instructor Name</div>
                Jhon Dia
              </div>
            </div>
            <hr />
            <div className="ymca-modal-title">Profile Details</div>
            <div className="row px-3">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Reference ID</div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'brandAdvocate', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Chiled Birth Date</div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'startDateWithAssociation')
                    ? moment(get(popupData, 'startDateWithAssociation')).format(
                        'MM/DD/yyyy'
                      )
                    : ''}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Gender</div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'startDateCurrentRole')
                    ? moment(get(popupData, 'startDateCurrentRole')).format(
                        'MM/DD/yyyy'
                      )
                    : ''}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Ethnicity</div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'endDateCurrentRole')
                    ? moment(get(popupData, 'endDateCurrentRole')).format(
                        'MM/DD/yyyy'
                      )
                    : ''}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  How Did You Hear About This Program
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'staffType', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Is Your Child New To The Y
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentJobTitle', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  What Level Of SAW Child Enrolled
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentSalary', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Was Student Able To Submerge Their Face Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionLeadLevel', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Was Student Able To Submerge Their Face Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionRole', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Bob Independently Lesson 1
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionPrimaryFunction', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Bob Independently Lesson 8
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionPrimaryFunction', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  Front Glide 5ft Lesson 1{' '}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionPrimaryFunction', '')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewDetailsPopup.propTypes = {
  popupTitle: PropTypes.string,
  showViewDetailsPopup: PropTypes.bool,
  popupData: PropTypes.arrayOf(PropTypes.any),
  handleCloseNewStaffPopup: PropTypes.func.isRequired
};

ViewDetailsPopup.defaultProps = {
  popupTitle: '',
  popupData: [],
  showViewDetailsPopup: false
};

export default ViewDetailsPopup;
