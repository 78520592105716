export const PledgeStatementDataSelector = ({
  annualReportPledgeStatement: { PledgeData }
}) => PledgeData;

export const CvoContactDataSelector = ({
  annualReportPledgeStatement: { CvoConatactDetails }
}) => CvoConatactDetails;

export const IncomingCvoContactDataSelector = ({
  annualReportPledgeStatement: { IncomingCvoDetails }
}) => IncomingCvoDetails;

export const IncomingCvoContactDataSelectorData = ({
  annualReportPledgeStatement: { IncomingCvoDetailsData }
}) => IncomingCvoDetailsData;

export const SendEmailRequestCEOCVOSelector = ({
  annualReportPledgeStatement: { SendEmailRequest }
}) => SendEmailRequest;

export const getCeoCvoDetailsSelector = ({
  annualReportPledgeStatement: { CeoCvoDetails }
}) => CeoCvoDetails;
