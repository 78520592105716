export const VolunteerAdminPreviewSelector = ({
  VolunteerAdminPreviewStore: { VolunteerAdminPreviewList }
}) => VolunteerAdminPreviewList;

export const VolunteerAdminApprovalSelector = ({
  VolunteerAdminApprovalStore: { VolunteerAdminApprovalData }
}) => VolunteerAdminApprovalData;

export const VolunteerAdminDocumentApprovalSelector = ({
  VolunteerAdminDocumentApprovalStore: { VolunteerAdminDocumentApprovalData }
}) => VolunteerAdminDocumentApprovalData;

export const VolunteerInitiateBackgroundScreeningSelector = ({
  VolunteerInitiateBackgroundScreeningStore: {
    VolunteerInitiateBackgroundScreeningData
  }
}) => VolunteerInitiateBackgroundScreeningData;

export const VolunteerCancelInitiatedBackgroundScreeningSelector = ({
  VolunteerCancelInitiatedBackgroundScreeningStore: {
    VolunteerCancelInitiatedBackgroundScreeningData
  }
}) => VolunteerCancelInitiatedBackgroundScreeningData;