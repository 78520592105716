import {
  ANNUAL_REPORT_DASHBOARD,
  AR_REOPEN
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getAnnualReportsDashboardData =
  AssociationId => (dispatch, getState) =>
    asyncRequest({
      url: ANNUAL_REPORT_DASHBOARD(AssociationId),
      successAction: data => ({
        type: types.ANNUAL_REPORT_DASHBOARD_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUAL_REPORT_DASHBOARD_FAILURE,
        payload: error
      }),
      dispatch
    });

export const reopenAnnualReport =
  (AssociationId, year) => (dispatch, getState) =>
    asyncRequest({
      url: AR_REOPEN(AssociationId, year),
      successAction: data => ({
        type: types.ANNUAL_REPORT_REOPEN_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ANNUAL_REPORT_REOPEN_FAILURE,
        payload: error
      }),
      dispatch
    });
