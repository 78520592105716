export const GET_MEMBERSHIPS_SUCCESS = 'GET_MEMBERSHIPS_SUCCESS';
export const GET_MEMBERSHIPS_FAILURE = 'GET_MEMBERSHIPS_FAILURE';

export const GET_MEMBERSHIPS_NWM_SUCCESS = 'GET_MEMBERSHIPS_NWM_SUCCESS';
export const GET_MEMBERSHIPS_NWM_FAILURE = 'GET_MEMBERSHIPS_NWM_FAILURE';

export const POST_MEMBERSHIPS_SUCCESS = 'POST_MEMBERSHIPS_SUCCESS';
export const POST_MEMBERSHIPS_FAILURE = 'POST_MEMBERSHIPS_FAILURE';

export const GET_CRM_PROVIDER_LIST_SUCCESS = 'GET_CRM_PROVIDER_LIST_SUCCESS';
export const GET_CRM_PROVIDER_LIST_FAILURE = 'GET_CRM_PROVIDER_LIST_FAILURE';
