import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  getSwimSafetyProgramsDelete,
  getSwimSafetyProgramsByFilters
} from '@redux/siwm-safety/swim-programs/actions';
import CustomModal from '@utilities/CustomModal';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { getSelectedBranchAndProgram } from '@redux/siwm-safety/swim-profiles/actions';
import { APP_LABELS } from '@config/app-config';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import { SWIM_PROGRAM_COLUMNS } from '../columns';
import Table from './table';

const ProgramTable = props => {
  const {
    handleGetProgramsDelete,
    setLoader,
    handleSelectedBranchAndProgram,
    recallCount,
    startDate,
    endDate,
    showAll,
    isFilterActive,
    handleProramsByFilters,
    isFilterClear,
    LoginUserData
  } = props;

  const Offset = new Date().getTimezoneOffset();
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [programDeletId, setProgramDeletId] = useState('');
  const [programsList, setProgramsList] = useState([]);
  const handleCloseAttentionPopup = () => setShowAttentionPopup(false);
  const [redirect, setRedirect] = useState(false);

  const role = get(LoginUserData, 'user.role[0]');

  const handleDeletePopup = id => {
    setProgramDeletId(id);
    setShowAttentionPopup(true);
  };

  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber = get(associateData, 'associationNumber', '');

  const handleViewDetails = data => {
    const selectedData = {
      branch: { ...data },
      program: { ...data }
    };
    handleSelectedBranchAndProgram(selectedData);
    setRedirect(true);
  };

  const programData = data =>
    sessionStorage.setItem('selected_swimprogram', JSON.stringify(data));

  const getProgramsList = list =>
    list.map(data => {
      const addSwimLocation = {
        pathname: '/swim/safety/addprogram',
        state: data
      };

      return {
        ...data,
        startDate: get(data, 'startDate')
          ? convertUtcToLocal(get(data, 'startDate'), 'DD MMM YYYY')
          : '-',
        endDate: get(data, 'endDate')
          ? convertUtcToLocal(get(data, 'endDate'), 'DD MMM YYYY')
          : '-',

        details: (
          <>
            <button
              type="button"
              onClick={() => handleViewDetails(data)}
              className="table-link swim-viewdetails-btn btn"
            >
              {APP_LABELS.DASH_VIEW_DETAILS}
            </button>
            {!APP_LABELS.VIEWER_PERMISSION_ROLES.includes(role) && (
              <>
                <span className="swim-safety-action-saperator">|</span>
                <Link
                  to={addSwimLocation}
                  className="table-link"
                  onClick={() => programData(data)}
                >
                  Edit
                </Link>
                <span className="swim-safety-action-saperator">|</span>
                <button
                  type="button"
                  className="swim-safety-action"
                  data-bs-toggle="modal"
                  data-bs-target="#rejectModal"
                  onClick={() => handleDeletePopup(data.id)}
                >
                  <span className="ymca-delete-text">Delete</span>
                </button>
              </>
            )}
          </>
        )
      };
    });

  const programList = () => {
    setLoader(true);
    handleProramsByFilters(
      startDate,
      endDate,
      Offset,
      showAll,
      associationNumber
    )
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setProgramsList(result?.data?.data);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const deletePrograms = () => {
    setLoader(true);
    handleGetProgramsDelete(programDeletId)
      .then(() => {
        setLoader(false);
        setProgramDeletId('');
        setShowAttentionPopup(false);
        programList();
        recallCount();
      })
      .catch(() => {
        setLoader(false);
        setProgramDeletId('');
        setShowAttentionPopup(false);
      });
  };
  useEffect(() => {
    if (!isFilterActive) {
      programList();
    }
  }, [isFilterClear]);

  useEffect(() => {
    if (isFilterActive) {
      setLoader(true);
      handleProramsByFilters(
        startDate,
        endDate,
        Offset,
        showAll,
        associationNumber
      )
        .then(result => {
          if (result?.data?.data) {
            setLoader(false);
            setProgramsList(result?.data?.data);
          }
          return setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [isFilterActive]);

  return (
    <>
      {redirect && <Redirect to="/swim/safety/program/details" />}
      <Table
        rows={getProgramsList(programsList)}
        headers={SWIM_PROGRAM_COLUMNS()}
        showCount
        title="Swim Programs"
        role={role}
      />
      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message="Are you sure want to delete?"
        submitButtonName="Yes"
        onSubmit={deletePrograms}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={handleCloseAttentionPopup}
      />
    </>
  );
};

ProgramTable.propTypes = {
  handleGetProgramsDelete: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  handleSelectedBranchAndProgram: PropTypes.func.isRequired,
  recallCount: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  showAll: PropTypes.bool.isRequired,
  handleProramsByFilters: PropTypes.func.isRequired,
  isFilterClear: PropTypes.bool.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetProgramsDelete: getSwimSafetyProgramsDelete,
  handleSelectedBranchAndProgram: getSelectedBranchAndProgram,
  handleProramsByFilters: getSwimSafetyProgramsByFilters
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramTable);
