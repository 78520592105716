import { CDP_UPLOAD_URLS, GET_CDP_WELLD_FETCH } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getCdpWellDFetchAction =
  (DataSystem, Program, associationNumber) => (dispatch, getState) =>
    asyncRequest({
      url: GET_CDP_WELLD_FETCH(DataSystem, Program, associationNumber),
      method: 'GET',
      successAction: data => ({
        type: types.POST_CDP_WELLD_FETCH_SUCCESS,
        async: true,
        payload: { result: data },
        state: getState()
      }),
      failureAction: error => ({
        type: types.POST_CDP_WELLD_FETCH_FAILURE,
        payload: error
      }),
      dispatch
    });

export const postCdpFileAction = payload => (dispatch, getState) =>
  asyncRequest({
    url: CDP_UPLOAD_URLS.DS_UPLOAD(),
    method: 'POST',
    data: payload,
    successAction: data => ({
      type: types.POST_CDP_SOLERA_FILE_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_CDP_SOLERA_FILE_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postCdpRedcapFetchAction = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: CDP_UPLOAD_URLS.REDCAP_FETCH(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_CDP_REDCAP_FETCH_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_CDP_REDCAP_FETCH_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getIsImportedMsgAction =
  (dataSystemId, programId, associationNumber) => (dispatch, getState) =>
    asyncRequest({
      url: CDP_UPLOAD_URLS.IS_IMPORTED(
        dataSystemId,
        programId,
        associationNumber
      ),
      method: 'GET',
      successAction: data => ({
        type: types.GET_CDP_IS_IMPORTED_MSG_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_CDP_IS_IMPORTED_MSG_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getProgramsAction = associationNumber => (dispatch, getState) =>
  asyncRequest({
    url: CDP_UPLOAD_URLS.PROGRAM(associationNumber),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_PROGRAM_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_PROGRAM_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getDolProgramsAction = () => (dispatch, getState) =>
  asyncRequest({
    url: CDP_UPLOAD_URLS.DOLPROGRAM(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_PROGRAM_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_PROGRAM_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getDolTypesAction = () => (dispatch, getState) =>
  asyncRequest({
    url: CDP_UPLOAD_URLS.DOLTYPES(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_PROGRAM_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_PROGRAM_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getDataSystemsAction = Program => (dispatch, getState) =>
  asyncRequest({
    url: CDP_UPLOAD_URLS.DATASYSTEM(Program),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CDP_DATASYSTEM_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CDP_DATASYSTEM_FAILURE,
      payload: error
    }),
    dispatch
  });




export const selectedDataSystemAction = payload => dispatch => {
  dispatch({
    type: types.SELECTED_DATA_SYSTEM,
    payload
  });
};

export const selectedProgramAction = payload => dispatch => {
  dispatch({
    type: types.SELECTED_PROGRAM,
    payload
  });
};

export const detailPageTypeAction = payload => dispatch => {
  dispatch({
    type: types.DETAIL_PAGE_TYPE,
    payload
  });
};

export const saveCdpFetchIdAction = payload => dispatch => {
  dispatch({
    type: types.SAVE_CDP_FETCH_ID,
    payload
  });
};

export const saveFetchTypeAction = payload => dispatch => {
  dispatch({
    type: types.SAVE_FETCH_TYPE,
    payload
  });
};
