
export const GET_RESOURCE_MOBILIZATION_SUCCESS =
  'GET_RESOURCE_MOBILIZATION_SUCCESS';
export const GET_RESOURCE_MOBILIZATION_FAILURE =
  'GET_RESOURCE_MOBILIZATION_FAILURE';

export const POST_RESOURCE_MOBILIZATION_SUCCESS =
  'POST_RESOURCE_MOBILIZATION_SUCCESS';
export const POST_RESOURCE_MOBILIZATION_FAILURE =
  'POST_RESOURCE_MOBILIZATION_FAILURE';
