import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import BackIcon from '@static/images/back-icon.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APP_LABELS, FIELD_LENGTH, CDP, REGEX } from '@config/app-config';
import TextError from '@modules/shared/components/reusable-components/text-error';
import get from 'lodash.get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSwimAssociationDropdown } from '@redux/siwm-safety/dashboard/actions';
import { getSwimSafetyBranchList } from '@redux/siwm-safety/swim-branches/actions';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import {
  getSwimSafetyProfileAddORupdate,
  getSwimSafetyProfileDetails
} from '@redux/siwm-safety/swim-profiles/actions';
import { getSwimProgramsByBranchId } from '@redux/siwm-safety/swim-programs/actions';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import CustomModal from '@utilities/CustomModal';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';

const { NUMERIC } = REGEX;

const { VOLUNTEER_TEXTBOX } = FIELD_LENGTH;

const AddProfile = props => {
  const {
    getSwimProfile,
    saveSwimProfile,
    handlegetAssociationList,
    handleSwimSafetyBranchList,
    handleToGetSwimPrograms,
    LoginUserData
  } = props;
  const [associationList, setAssociationList] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [programDropdown, setProgramDropdown] = useState([]);
  const [loader, setLoader] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const redirectUrl = sessionStorage.getItem('swim_profile_redirect_url');

  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber = get(associateData, 'associationNumber', '')
    ? get(associateData, 'associationNumber', '')
    : get(LoginUserData, 'user.associations[0]', '');

  const profileData = JSON.parse(sessionStorage.getItem('selected_profile'));
  const profileId = get(profileData, 'id', '');

  const validationObj = {
    association: Yup.string().nullable().required('* Association is required.'),
    branch: Yup.string().nullable().required('* Branch is required.'),
    profileId: Yup.string()
      .nullable()
      .matches(NUMERIC, '*Reference ID should only be Numbers.')
      .max(14, '* Reference ID length should be less than 15.')
      .required('* Reference ID is required.'),
    programId: Yup.string().nullable().required('* Swim program is required.'),
    childBirthDate: Yup.string()
      .nullable()
      .required('* Student birthdate is required.'),
    gender: Yup.string().nullable().required('* Gender is required.'),
    ethnicity: Yup.string()
      .nullable()
      .required(`* Student's race/ethnicity is required.`),
    zipCode: Yup.string()
      .nullable()
      .matches(/^(\d{5})(-\d{4})?$/i, 'Zipcode must be valid')
      .required('* Zipcode is required.'),
    howDidYouHear: Yup.string()
      .nullable()
      .required('* How did you hear about this program? is required.'),
    childNewToTheY: Yup.string().required('* This is required field.'),
    levelOfSAW: Yup.string().nullable().required('* Level of SAW is required.'),
    submergeTheirFaceLesson1: Yup.string().required(
      '* This is required field.'
    ),
    submergeTheirFaceLesson8: Yup.string().required(
      '* This is required field.'
    ),
    bobIndependentlyLesson1: Yup.string().required('* This is required field.'),
    bobIndependentlyLesson8: Yup.string().required('* This is required field.'),
    frontGlide5ftLesson1: Yup.string().required('* This is required field.'),
    frontGlide5ftLesson8: Yup.string().required('* This is required field.'),
    poolLesson1: Yup.string().required('* This is required field.'),
    poolLesson8: Yup.string().required('* This is required field.'),
    floatSwim10ftLesson1: Yup.string().required('* This is required field.'),
    floatSwim10ftLesson8: Yup.string().required('* This is required field.'),
    jumpIndependentlyLesson1: Yup.string().required(
      '* This is required field.'
    ),
    jumpIndependentlyLesson8: Yup.string().required(
      '* This is required field.'
    ),
    grabAssistedLesson1: Yup.string().required('* This is required field.'),
    grabAssistedLesson8: Yup.string().required('* This is required field.'),
    grabIndependentlyLesson1: Yup.string().required(
      '* This is required field.'
    ),
    grabIndependentlyLesson8: Yup.string().required(
      '* This is required field.'
    ),
    safelyExitThePoolWithoutHelp: Yup.string().required(
      '* This is required field.'
    ),
    everHadSwimLessonsBefore: Yup.string().required('* This is required field.')
  };
  const validationSchema = Yup.object(validationObj);

  const initialValues = {
    association: associationNumber,
    branch: '',
    profileId: '',
    programId: '',
    childBirthDate: '',
    gender: '',
    ethnicity: '',
    zipCode: '',
    howDidYouHear: '',
    childNewToTheY: '',
    levelOfSAW: '',
    submergeTheirFaceLesson1: '',
    submergeTheirFaceLesson8: '',
    bobIndependentlyLesson1: '',
    bobIndependentlyLesson8: '',
    frontGlide5ftLesson1: '',
    frontGlide5ftLesson8: '',
    poolLesson1: '',
    poolLesson8: '',
    floatSwim10ftLesson1: '',
    floatSwim10ftLesson8: '',
    jumpIndependentlyLesson1: '',
    jumpIndependentlyLesson8: '',
    grabAssistedLesson1: '',
    grabAssistedLesson8: '',
    grabIndependentlyLesson1: '',
    grabIndependentlyLesson8: '',
    safelyExitThePoolWithoutHelp: '',
    everHadSwimLessonsBefore: ''
  };

  const getSwimProfileDetails = () => {
    getSwimProfile(profileId).then(result => {
      if (result?.data?.data) {
        setProfileDetails(result?.data?.data);
      }
    });
  };

  const setEditValues = () => {
    let editValues = {
      association: associationNumber,
      branch: '',
      profileId: '',
      programId: '',
      childBirthDate: '',
      gender: '',
      ethnicity: '',
      zipCode: '',
      howDidYouHear: '',
      childNewToTheY: '',
      levelOfSAW: '',
      submergeTheirFaceLesson1: '',
      submergeTheirFaceLesson8: '',
      bobIndependentlyLesson1: '',
      bobIndependentlyLesson8: '',
      frontGlide5ftLesson1: '',
      frontGlide5ftLesson8: '',
      poolLesson1: '',
      poolLesson8: '',
      floatSwim10ftLesson1: '',
      floatSwim10ftLesson8: '',
      jumpIndependentlyLesson1: '',
      jumpIndependentlyLesson8: '',
      grabAssistedLesson1: '',
      grabAssistedLesson8: '',
      grabIndependentlyLesson1: '',
      grabIndependentlyLesson8: '',
      safelyExitThePoolWithoutHelp: '',
      everHadSwimLessonsBefore: ''
    };

    if (profileDetails.length > 0) {
      const profileInfo = profileDetails[0];
      editValues = {
        ...editValues,
        branch: profileInfo?.branchId.toString(),
        profileId: profileInfo?.profileId,
        programId: profileInfo?.programId.toString(),
        childBirthDate: profileInfo?.childBirthDate,
        gender: profileInfo?.gender,
        ethnicity: profileInfo?.ethnicity,
        zipCode: profileInfo?.zipCode,
        howDidYouHear: profileInfo?.howDidYouHear,
        childNewToTheY: profileInfo?.childNewToTheY ? 'true' : 'false',
        levelOfSAW: profileInfo?.levelOfSAW,
        submergeTheirFaceLesson1: profileInfo?.submergeTheirFaceLesson1
          ? 'true'
          : 'false',
        submergeTheirFaceLesson8: profileInfo?.submergeTheirFaceLesson8
          ? 'true'
          : 'false',
        bobIndependentlyLesson1: profileInfo?.bobIndependentlyLesson1
          ? 'true'
          : 'false',
        bobIndependentlyLesson8: profileInfo?.bobIndependentlyLesson8
          ? 'true'
          : 'false',
        frontGlide5ftLesson1: profileInfo?.frontGlide5ftLesson1
          ? 'true'
          : 'false',
        frontGlide5ftLesson8: profileInfo?.frontGlide5ftLesson8
          ? 'true'
          : 'false',
        poolLesson1: profileInfo?.poolLesson1 ? 'true' : 'false',
        poolLesson8: profileInfo?.poolLesson8 ? 'true' : 'false',
        floatSwim10ftLesson1: profileInfo?.floatSwim10ftLesson1
          ? 'true'
          : 'false',
        floatSwim10ftLesson8: profileInfo?.floatSwim10ftLesson8
          ? 'true'
          : 'false',
        jumpIndependentlyLesson1: profileInfo?.jumpIndependentlyLesson1
          ? 'true'
          : 'false',
        jumpIndependentlyLesson8: profileInfo?.jumpIndependentlyLesson8
          ? 'true'
          : 'false',
        grabAssistedLesson1: profileInfo?.grabAssistedLesson1
          ? 'true'
          : 'false',
        grabAssistedLesson8: profileInfo?.grabAssistedLesson8
          ? 'true'
          : 'false',
        grabIndependentlyLesson1: profileInfo?.grabIndependentlyLesson1
          ? 'true'
          : 'false',
        grabIndependentlyLesson8: profileInfo?.grabIndependentlyLesson8
          ? 'true'
          : 'false',
        safelyExitThePoolWithoutHelp: profileInfo?.safelyExitThePoolWithoutHelp
          ? 'true'
          : 'false',
        everHadSwimLessonsBefore: profileInfo?.everHadSwimLessonsBefore
          ? 'true'
          : 'false'
      };
    }
    return editValues;
  };

  const onSubmit = values => {
    const payloadBody = {
      id: profileId || 0,
      profileId: values.profileId,
      programId: parseInt(values.programId, 10),
      childBirthDate: values.childBirthDate,
      gender: values.gender,
      ethnicity: values.ethnicity,
      zipCode: values.zipCode,
      howDidYouHear: values.howDidYouHear,
      childNewToTheY: values.childNewToTheY === 'true',
      levelOfSAW: values.levelOfSAW,
      submergeTheirFaceLesson1: values.submergeTheirFaceLesson1 === 'true',
      submergeTheirFaceLesson8: values.submergeTheirFaceLesson8 === 'true',
      bobIndependentlyLesson1: values.bobIndependentlyLesson1 === 'true',
      bobIndependentlyLesson8: values.bobIndependentlyLesson8 === 'true',
      frontGlide5ftLesson1: values.frontGlide5ftLesson1 === 'true',
      frontGlide5ftLesson8: values.frontGlide5ftLesson8 === 'true',
      poolLesson1: values.poolLesson1 === 'true',
      poolLesson8: values.poolLesson8 === 'true',
      floatSwim10ftLesson1: values.floatSwim10ftLesson1 === 'true',
      floatSwim10ftLesson8: values.floatSwim10ftLesson8 === 'true',
      jumpIndependentlyLesson1: values.jumpIndependentlyLesson1 === 'true',
      jumpIndependentlyLesson8: values.jumpIndependentlyLesson8 === 'true',
      grabAssistedLesson1: values.grabAssistedLesson1 === 'true',
      grabAssistedLesson8: values.grabAssistedLesson8 === 'true',
      grabIndependentlyLesson1: values.grabIndependentlyLesson1 === 'true',
      grabIndependentlyLesson8: values.grabIndependentlyLesson8 === 'true',
      safelyExitThePoolWithoutHelp:
        values.safelyExitThePoolWithoutHelp === 'true',
      everHadSwimLessonsBefore: values.everHadSwimLessonsBefore === 'true'
    };

    setLoader(true);
    saveSwimProfile(payloadBody)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          setSuccessPopup(true);
          setSuccessMessage(
            profileId
              ? APP_LABELS.SWIMPROFILE_UPDATED_MESSAGE
              : APP_LABELS.SWIMPROFILE_SUCCESS_MESSAGE
          );
        } else {
          setLoader(false);
          setErrorMessage(res?.message || res?.data);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(true);
    if (profileId) getSwimProfileDetails();

    handlegetAssociationList().then(result => {
      if (result?.data?.data) {
        setAssociationList(result?.data?.data);
      }
    });

    handleSwimSafetyBranchList(get(associateData, 'associationNumber', ''))
      .then(result => {
        if (result?.data?.data) {
          setBranchDropdown(result?.data?.data?.branches);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });

    return () => {
      sessionStorage.removeItem('selected_profile');
    };
  }, []);

  const getProgramDropdownOptions = branchId => {
    handleToGetSwimPrograms(branchId).then(result => {
      if (result?.data?.data) {
        setProgramDropdown(result?.data?.data);
      }
    });
  };

  useEffect(() => {
    if (profileDetails[0]?.branchId)
      getProgramDropdownOptions(profileDetails[0]?.branchId);
  }, [profileDetails[0]?.branchId]);

  const GenderDropdwon = [
    { name: 'Male' },
    { name: 'Female' },
    { name: 'Non-Binary' },
    { name: 'Prefer Not to Identify' },
    { name: 'Other Identity' }
  ];

  const EthnicityDropdwon = [
    { name: 'Asian' },
    { name: 'Black or African American' },
    { name: 'Hispanic or Latino' },
    { name: 'Middle eastern or Northern African' },
    { name: 'Native American, Indigenous American or Alaskan Native' },
    { name: 'Native Hawaiian or Other Pacific Islander' },
    { name: 'White' },
    { name: 'Two or more races/ethnicities' },
    { name: 'Other identity' },
    { name: 'Prefer not to identify' },
    { name: 'Unknown' }
  ];

  const HowDidYouHearDropdwon = [
    { name: 'Y staff member/volunteer' },
    { name: 'Friend/family member/word of mouth' },
    { name: 'Mailing/Email communication' },
    { name: 'Poster/Flyer/Y event' },
    { name: "Y's website" },
    { name: 'Media (TV, Web, Radio, Print, Social, etc.)' },
    { name: 'School' },
    { name: 'Community Based Organization' },
    { name: 'Other' }
  ];

  const LevelOfSawDropdwon = [
    { name: 'Advanced' },
    { name: 'Beginner' },
    { name: 'Intermediate' }
  ];

  const validate = values => {
    const errors = {};
    if (
      values.zipCode &&
      !(
        /^(\d{5})(-\d{4})?$/i.test(values.zipCode) && values.zipCode !== '00000'
      )
    ) {
      errors.zipCode = 'Invalid Zip Code';
    }
    if (values.profileId && Number(values.profileId) + 1 === 1) {
      errors.profileId = 'Invalid Reference ID';
    }
    return errors;
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <div className="container">
              <section className="dashboard-header">
                <div className="row align-items-center mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading mb-2">
                        {profileId
                          ? APP_LABELS.EDIT_PROFILE
                          : APP_LABELS.ADD_PROFILE}
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                    <Link className="ymca-title-link" to={redirectUrl}>
                      <img
                        src={BackIcon}
                        className="ymca-back-link"
                        alt="back-icon"
                      />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  </div>
                </div>
              </section>
              <hr className="custom-hr mt-0" />

              <div className="container">
                <Formik
                  initialValues={profileId ? setEditValues() : initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                  validate={validate}
                >
                  {({ values, handleChange, setFieldValue }) => (
                    <Form
                      autoComplete="off"
                      className="ymca-registeration-form"
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label htmlFor="association" className="form-label">
                              {APP_LABELS.ASSOCIATION_NAME}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="association"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                              aria-label="Default select example"
                              value={values.association}
                              disabled
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {associationList?.map(data => (
                                <option
                                  key={data.associationNumber}
                                  value={data.associationNumber}
                                >
                                  {data.name}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage
                              component={TextError}
                              name="association"
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label htmlFor="branch" className="form-label">
                              {APP_LABELS.BRANCH}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="branch"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                              aria-label="Default select example"
                              value={values.branch}
                              onChange={e => {
                                getProgramDropdownOptions(e.target.value);
                                handleChange(e, setFieldValue, 'branch');
                              }}
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {branchDropdown?.map(data => (
                                <option
                                  key={data.branchId}
                                  value={data.branchid}
                                >
                                  {data.branchname}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage component={TextError} name="branch" />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label htmlFor="programId" className="form-label">
                              Swim Program{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="programId"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                              aria-label="Default select example"
                              value={values.programId}
                              onChange={handleChange}
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {programDropdown?.map(data => (
                                <option key={data.id} value={data.id}>
                                  {data.programName}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage
                              component={TextError}
                              name="programId"
                            />
                          </div>
                        </div>
                      </div>
                      <h2 className="ymca-section-heading">Profile Details</h2>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-4">
                            <span className="mb-2 required">
                              Reference ID{/* */}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <Field
                                maxLength={VOLUNTEER_TEXTBOX}
                                name="profileId"
                                className="form-control"
                                type="text"
                                placeholder="Enter Reference ID"
                                value={values.profileId}
                              />
                              <ErrorMessage
                                component={TextError}
                                name="profileId"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <span className="mb-2 required">
                              Student Age (student birthdate){/* */}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <input
                                type="date"
                                className="form-control w-100"
                                placeholder=""
                                name="childBirthDate"
                                onChange={handleChange}
                                value={values.childBirthDate}
                                max={moment(new Date())
                                  .subtract(2, 'Y')
                                  .format('YYYY-MM-DD')}
                                onKeyDown={e => {
                                  e.preventDefault();
                                }}
                              />
                              <ErrorMessage
                                component={TextError}
                                name="childBirthDate"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <span className="mb-2 required">
                              Sex{/* */}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <select
                                name="gender"
                                className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5"
                                aria-label="Default select example"
                                value={values.gender}
                                onChange={handleChange}
                              >
                                <option value="">{CDP.SELECT_DEFAULT}</option>
                                {GenderDropdwon?.map(data => (
                                  <option key={data.name} value={data.name}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                component={TextError}
                                name="gender"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label htmlFor="ethnicity" className="form-label">
                              {"Student's race/ethnicity"}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="ethnicity"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5"
                              aria-label="Default select example"
                              value={values.ethnicity}
                              onChange={handleChange}
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {EthnicityDropdwon?.map(data => (
                                <option key={data.name} value={data.name}>
                                  {data.name}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage
                              component={TextError}
                              name="ethnicity"
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="howDidYouHear"
                              className="form-label"
                            >
                              How did you hear about this program?{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="howDidYouHear"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5"
                              aria-label="Default select example"
                              value={values.howDidYouHear}
                              onChange={handleChange}
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {HowDidYouHearDropdwon?.map(data => (
                                <option key={data.name} value={data.name}>
                                  {data.name}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage
                              component={TextError}
                              name="howDidYouHear"
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label htmlFor="levelOfSAW" className="form-label">
                              What level of SAW was student enrolled ?{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="levelOfSAW"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5"
                              aria-label="Default select example"
                              value={values.levelOfSAW}
                              onChange={handleChange}
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {LevelOfSawDropdwon?.map(data => (
                                <option key={data.name} value={data.name}>
                                  {data.name}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage
                              component={TextError}
                              name="levelOfSAW"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <span className="mb-2 required">
                            Zip Code{/* */}
                            <span className="text-mandatory">&#42;</span>
                          </span>
                          <div className="form-group">
                            <Field
                              maxLength={VOLUNTEER_TEXTBOX}
                              name="zipCode"
                              className="form-control"
                              type="text"
                              placeholder="Enter Zipcode"
                              value={values.zipCode}
                            />
                            <ErrorMessage
                              component={TextError}
                              name="zipCode"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                        <label htmlFor="childNewToTheY" className="form-label">
                          Is your student new to the Y?{/* */}
                          <span className="text-mandatory ms-1">*</span>
                        </label>

                        <div className="ymca-radioBTN d-flex pb-0">
                          <div className="me-5">
                            <Field
                              type="radio"
                              name="childNewToTheY"
                              className="me-2"
                              value="true"
                              checked={values.childNewToTheY === 'true'}
                            />
                            <span>Yes</span>
                          </div>
                          <div className="">
                            <Field
                              type="radio"
                              name="childNewToTheY"
                              className="me-2"
                              value="false"
                              checked={values.childNewToTheY === 'false'}
                            />
                            <span>No</span>
                          </div>
                        </div>
                        <ErrorMessage
                          component={TextError}
                          name="childNewToTheY"
                        />
                      </div>
                      <h2 className="ymca-section-heading">Swimmimg Details</h2>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="submergeTheirFaceLesson1"
                              className="form-label"
                            >
                              Was student able to submerge their face lesson 1
                              {/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="submergeTheirFaceLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.submergeTheirFaceLesson1 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="submergeTheirFaceLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.submergeTheirFaceLesson1 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="submergeTheirFaceLesson1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="submergeTheirFaceLesson8"
                              className="form-label"
                            >
                              Was student able to submerge their face lesson 8
                              {/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="submergeTheirFaceLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.submergeTheirFaceLesson8 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="submergeTheirFaceLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.submergeTheirFaceLesson8 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="submergeTheirFaceLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="frontGlide5ftLesson1"
                              className="form-label"
                            >
                              Was student able to Front glide 5 ft. lesson 1
                              {/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="frontGlide5ftLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.frontGlide5ftLesson1 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="frontGlide5ftLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.frontGlide5ftLesson1 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="frontGlide5ftLesson1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="frontGlide5ftLesson8"
                              className="form-label"
                            >
                              Was student able to submerge & bob independently
                              lesson 8{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="frontGlide5ftLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.frontGlide5ftLesson8 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="frontGlide5ftLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.frontGlide5ftLesson8 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="frontGlide5ftLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="bobIndependentlyLesson1"
                              className="form-label"
                            >
                              Was student able to submerge & bob independently
                              lesson 1{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="bobIndependentlyLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.bobIndependentlyLesson1 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="bobIndependentlyLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.bobIndependentlyLesson1 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="bobIndependentlyLesson1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="bobIndependentlyLesson8"
                              className="form-label"
                            >
                              Was student able to Front glide 5 ft. lesson 8
                              {/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="bobIndependentlyLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.bobIndependentlyLesson8 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="bobIndependentlyLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.bobIndependentlyLesson8 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="bobIndependentlyLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label htmlFor="poolLesson1" className="form-label">
                              Was student able to Back float 10 sec. and roll to
                              front glide and exit the pool lesson 1{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="poolLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={values.poolLesson1 === 'true'}
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="poolLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={values.poolLesson1 === 'false'}
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="poolLesson1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label htmlFor="poolLesson8" className="form-label">
                              Was student able to back float 10 sec. and roll to
                              front glide and exit the pool lesson 8{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="poolLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={values.poolLesson8 === 'true'}
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="poolLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={values.poolLesson8 === 'false'}
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="poolLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="floatSwim10ftLesson1"
                              className="form-label"
                            >
                              Was student able to swim, float swim 10 ft. lesson
                              1{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="floatSwim10ftLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.floatSwim10ftLesson1 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="floatSwim10ftLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.floatSwim10ftLesson1 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="floatSwim10ftLesson1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="floatSwim10ftLesson8"
                              className="form-label"
                            >
                              Was student able to swim, float swim 10 ft. lesson
                              8{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="floatSwim10ftLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.floatSwim10ftLesson8 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="floatSwim10ftLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.floatSwim10ftLesson8 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="floatSwim10ftLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="jumpIndependentlyLesson1"
                              className="form-label"
                            >
                              Was student able to jump independently lesson 1
                              {/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="jumpIndependentlyLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.jumpIndependentlyLesson1 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="jumpIndependentlyLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.jumpIndependentlyLesson1 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="jumpIndependentlyLesson1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="jumpIndependentlyLesson8"
                              className="form-label"
                            >
                              Was student able to jump independently lesson 8
                              {/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="jumpIndependentlyLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.jumpIndependentlyLesson8 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="jumpIndependentlyLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.jumpIndependentlyLesson8 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="jumpIndependentlyLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="grabIndependentlyLesson1"
                              className="form-label"
                            >
                              Was student able to Jump, Push, Turn and Grab
                              independently lesson 1{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="grabIndependentlyLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.grabIndependentlyLesson1 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="grabIndependentlyLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.grabIndependentlyLesson1 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="grabIndependentlyLesson1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="grabIndependentlyLesson8"
                              className="form-label"
                            >
                              Was student able to Jump, Push, Turn and Grab
                              independently lesson 8{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="grabIndependentlyLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.grabIndependentlyLesson8 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="grabIndependentlyLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.grabIndependentlyLesson8 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="grabIndependentlyLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="grabAssistedLesson1"
                              className="form-label "
                            >
                              Was student able to perform Jump, Push, Turn and
                              Grab assisted lesson 1{/* */}
                            </label>
                            <span className="text-mandatory ms-1">*</span>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="grabAssistedLesson1"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.grabAssistedLesson1 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="grabAssistedLesson1"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.grabAssistedLesson1 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="grabAssistedLesson1"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="grabAssistedLesson8"
                              className="form-label "
                            >
                              Was student able to perform Jump, Push, Turn and
                              Grab assisted lesson 8{/* */}
                            </label>
                            <span className="text-mandatory ms-1">*</span>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="grabAssistedLesson8"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.grabAssistedLesson8 === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="grabAssistedLesson8"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.grabAssistedLesson8 === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="grabAssistedLesson8"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="everHadSwimLessonsBefore"
                              className="form-label"
                            >
                              Has your student ever had swim lessons before
                              {/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="everHadSwimLessonsBefore"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.everHadSwimLessonsBefore === 'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="everHadSwimLessonsBefore"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.everHadSwimLessonsBefore === 'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="everHadSwimLessonsBefore"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                            <label
                              htmlFor="safelyExitThePoolWithoutHelp"
                              className="form-label"
                            >
                              Can your student jump into the water and safely
                              exit the pool without help?{/* */}
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <div className="ymca-radioBTN d-flex pb-0">
                              <div className="me-5">
                                <Field
                                  type="radio"
                                  name="safelyExitThePoolWithoutHelp"
                                  className="me-2"
                                  value="true"
                                  checked={
                                    values.safelyExitThePoolWithoutHelp ===
                                    'true'
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div className="">
                                <Field
                                  type="radio"
                                  name="safelyExitThePoolWithoutHelp"
                                  className="me-2"
                                  value="false"
                                  checked={
                                    values.safelyExitThePoolWithoutHelp ===
                                    'false'
                                  }
                                />
                                <span>No</span>
                              </div>
                            </div>
                            <ErrorMessage
                              component={TextError}
                              name="safelyExitThePoolWithoutHelp"
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <button
                          className="btn ymca-primary-btn swim-addbranch-save-btn mt-3"
                          type="submit"
                        >
                          {profileId ? APP_LABELS.UPDATE : APP_LABELS.SAVE}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </div>

      {redirect && <Redirect to={redirectUrl} />}
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {successPopup && (
        <CustomModal
          show={successPopup}
          type={APP_LABELS.SUCCESS}
          message={successMessage}
          submitButtonName="Ok"
          onSubmit={() => {
            setRedirect(true);
          }}
        />
      )}
    </BlockUi>
  );
};

AddProfile.propTypes = {
  getSwimProfile: PropTypes.func.isRequired,
  saveSwimProfile: PropTypes.func.isRequired,
  handlegetAssociationList: PropTypes.func.isRequired,
  handleSwimSafetyBranchList: PropTypes.func.isRequired,
  handleToGetSwimPrograms: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  getSwimProfile: getSwimSafetyProfileDetails,
  saveSwimProfile: getSwimSafetyProfileAddORupdate,
  handlegetAssociationList: getSwimAssociationDropdown,
  handleSwimSafetyBranchList: getSwimSafetyBranchList,
  handleToGetSwimPrograms: getSwimProgramsByBranchId
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AddProfile));
