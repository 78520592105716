export const GET_CDP_FETCH_SUCCESS = 'GET_CDP_FETCH_SUCCESS';
export const GET_CDP_FETCH_FAILURE = 'GET_CDP_FETCH_FAILURE';

export const GET_REDCAP_FETCH_SUCCESS = 'GET_REDCAP_FETCH_SUCCESS';
export const GET_REDCAP_FETCH_FAILURE = 'GET_REDCAP_FETCH_FAILURE';

export const GET_CDP_DATA_FETCH_SUCCESS = 'GET_CDP_DATA_FETCH_SUCCESS';
export const GET_CDP_DATA_FETCH_FAILURE = 'GET_CDP_DATA_FETCH_FAILURE';

export const POST_CDP_SOLERA_IMPORT_SUCCESS = 'POST_CDP_SOLERA_IMPORT_SUCCESS';
export const POST_CDP_SOLERA_IMPORT_FAILURE = 'POST_CDP_SOLERA_IMPORT_FAILURE';

export const POST_CDP_REDCAP_IMPORT_SUCCESS = 'POST_CDP_REDCAP_IMPORT_SUCCESS';
export const POST_CDP_REDCAP_IMPORT_FAILURE = 'POST_CDP_REDCAP_IMPORT_FAILURE';

export const POST_CDP_WELLD_IMPORT_SUCCESS = 'POST_CDP_WELLD_IMPORT_SUCCESS';
export const POST_CDP_WELLD_IMPORT_FAILURE = 'POST_CDP_WELLD_IMPORT_FAILURE';

export const SET_POPOVER_DETAILS = 'SET_POPOVER_DETAILS';
