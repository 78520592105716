import { APP_LABELS } from "@config/app-config";

export const formatResponse = (response) => {
    let returnValue = response;
    if (response) {
        if (response.status && response.status === 200) {
            returnValue = {
                success: true,
                errors: undefined,
                data: response.data
            };
        } else if (Array.isArray(response)) {
            returnValue = {
                success: false,
                errors: response.join(","),
                data: undefined
            };
        } else if (response.hasOwnProperty("isSuccess")) {
            returnValue = {
                success: false,
                errors: response?.message ? response?.message : response,
                data: undefined
            };
        } else {
            returnValue = {
                success: false,
                errors: APP_LABELS.ERROR_MESSAGE,
                data: undefined
            };
        }
    }
    return returnValue;
}