import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import PropTypes from 'prop-types';
import { APP_LABELS } from '@config/app-config';

const SuccessToaster = props => {
  const { Status, message } = props;
  useEffect(() => {
    injectStyle();
    if (Status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED) {
      toast.success(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    } else if (Status?.toUpperCase() === APP_LABELS.STATUS_PARTIAL) {
      toast.warn('Data has been saved partially!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }
  }, [Status]);
  return (
    <div>
      <ToastContainer style={{ top: '100px' }} />
    </div>
  );
};

SuccessToaster.propTypes = {
  Status: PropTypes.string.isRequired
};

SuccessToaster.defaultProps = {
  message: 'Data has been saved successfully!'
};

export default SuccessToaster;
