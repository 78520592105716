/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import {
  getAnnualReportCollabOtherCommunityOrgApi,
  PostAnnualReportCollabOtherCommunityOrgApi
} from '@redux/annual-report-collaboration/actions';
import { Formik, Form } from 'formik';
import { CollabOtherCommunityOrgSelector } from '@redux/annual-report-collaboration/selectors';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const OtherCommunityOrg = props => {
  const {
    handleGetARCollabOtherCommunityOrgApi,
    setLoader,
    handlePostARCollabOtherCommunityOrgApi,
    selectedAsso,
    selectedBranch,
    setSuccessPopup,
    setErrorPopup,
    setErrorMessage,
    status,
    role,
    handleGetAnnualReportsDashboard,
    LoginUserData,
    selectedbranchName
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Collaborations';

  const formikref = useRef();
  const [initialValues, setInitialValues] = useState({});
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const fieldsObj = [
    {
      name: 'Active aging community',
      ProgramOrservice: 'activeAgingCommunityProgramOrservice',
      SharedFacility: 'activeAgingCommunitySharedFacility',
      SharedStaff: 'activeAgingCommunitySharedStaff',
      JointEvent: 'activeAgingCommunityJointEvent',
      PolicyAdvocacy: 'activeAgingCommunityPolicyAdvocacy',
      Other: 'activeAgingCommunityOther',
      OtherDescription: 'activeAgingCommunityOtherDescription'
    },
    {
      name: 'Community Health and Wellness Coalition',
      ProgramOrservice: 'communityHealthProgramOrservice',
      SharedFacility: 'communityHealthSharedFacility',
      SharedStaff: 'communityHealthSharedStaff',
      JointEvent: 'communityHealthJointEvent',
      PolicyAdvocacy: 'communityHealthPolicyAdvocacy',
      Other: 'communityHealthOther',
      OtherDescription: 'communityHealthOtherDescription'
    },
    {
      name: 'Community Health Centers/ FQHCs',
      ProgramOrservice: 'communityHealthCentersProgramOrservice',
      SharedFacility: 'communityHealthCentersSharedFacility',
      SharedStaff: 'communityHealthCentersSharedStaff',
      JointEvent: 'communityHealthCentersJointEvent',
      PolicyAdvocacy: 'communityHealthCentersPolicyAdvocacy',
      Other: 'communityHealthCentersOther',
      OtherDescription: 'communityHealthCentersOtherDescription'
    },
    {
      name: 'County/State Medical Societies',
      ProgramOrservice: 'countyStateMedicalProgramOrservice',
      SharedFacility: 'countyStateMedicalSharedFacility',
      SharedStaff: 'countyStateMedicalSharedStaff',
      JointEvent: 'countyStateMedicalJointEvent',
      PolicyAdvocacy: 'countyStateMedicalPolicyAdvocacy',
      Other: 'countyStateMedicalOther',
      OtherDescription: 'countyStateMedicalOtherDescription'
    },
    {
      name: 'Health Care Provider Groups',
      ProgramOrservice: 'healthCareProviderProgramOrservice',
      SharedFacility: 'healthCareProviderSharedFacility',
      SharedStaff: 'healthCareProviderSharedStaff',
      JointEvent: 'healthCareProviderJointEvent',
      PolicyAdvocacy: 'healthCareProviderPolicyAdvocacy',
      Other: 'healthCareProviderOther',
      OtherDescription: 'healthCareProviderOtherDescription'
    },
    {
      name: 'Hospital',
      ProgramOrservice: 'hospitalProgramOrservice',
      SharedFacility: 'hospitalSharedFacility',
      SharedStaff: 'hospitalSharedStaff',
      JointEvent: 'hospitalJointEvent',
      PolicyAdvocacy: 'hospitalPolicyAdvocacy',
      Other: 'hospitalOther',
      OtherDescription: 'hospitalOtherDescription'
    },
    {
      name: 'House of Worship (ex: church, temple, mosque)',
      ProgramOrservice: 'houseOfWorshipProgramOrservice',
      SharedFacility: 'houseOfWorshipSharedFacility',
      SharedStaff: 'houseOfWorshipSharedStaff',
      JointEvent: 'houseOfWorshipJointEvent',
      PolicyAdvocacy: 'houseOfWorshipPolicyAdvocacy',
      Other: 'houseOfWorshipOther',
      OtherDescription: 'houseOfWorshipOtherDescription'
    },
    {
      name: 'Library',
      ProgramOrservice: 'libraryProgramOrservice',
      SharedFacility: 'librarySharedFacility',
      SharedStaff: 'librarySharedStaff',
      JointEvent: 'libraryJointEvent',
      PolicyAdvocacy: 'libraryPolicyAdvocacy',
      Other: 'libraryOther',
      OtherDescription: 'libraryOtherDescription'
    },
    {
      name: 'Local Payor',
      ProgramOrservice: 'localPayorProgramOrservice',
      SharedFacility: 'localPayorSharedFacility',
      SharedStaff: 'localPayorSharedStaff',
      JointEvent: 'localPayorJointEvent',
      PolicyAdvocacy: 'localPayorPolicyAdvocacy',
      Other: 'localPayorOther',
      OtherDescription: 'localPayorOtherDescription'
    },
    {
      name: 'Local Senior Centers',
      ProgramOrservice: 'localSeniorCentersProgramOrservice',
      SharedFacility: 'localSeniorCentersSharedFacility',
      SharedStaff: 'localSeniorCentersSharedStaff',
      JointEvent: 'localSeniorCentersJointEvent',
      PolicyAdvocacy: 'localSeniorCentersPolicyAdvocacy',
      Other: 'localSeniorCentersOther',
      OtherDescription: 'localSeniorCentersOtherDescription'
    },
    {
      name: 'Museum',
      ProgramOrservice: 'museumProgramOrservice',
      SharedFacility: 'museumSharedFacility',
      SharedStaff: 'museumSharedStaff',
      JointEvent: 'museumJointEvent',
      PolicyAdvocacy: 'museumPolicyAdvocacy',
      Other: 'museumOther',
      OtherDescription: 'museumOtherDescription'
    },
    {
      name: 'Neighborhood Association',
      ProgramOrservice: 'neighborhoodAssociationProgramOrservice',
      SharedFacility: 'neighborhoodAssociationSharedFacility',
      SharedStaff: 'neighborhoodAssociationSharedStaff',
      JointEvent: 'neighborhoodAssociationJointEvent',
      PolicyAdvocacy: 'neighborhoodAssociationPolicyAdvocacy',
      Other: 'neighborhoodAssociationOther',
      OtherDescription: 'neighborhoodAssociationOtherDescription'
    },
    {
      name: 'Patnerships with their local Area Agency on aging',
      ProgramOrservice: 'agencyOnAgingProgramOrservice',
      SharedFacility: 'agencyOnAgingSharedFacility',
      SharedStaff: 'agencyOnAgingSharedStaff',
      JointEvent: 'agencyOnAgingJointEvent',
      PolicyAdvocacy: 'agencyOnAgingPolicyAdvocacy',
      Other: 'agencyOnAgingOther',
      OtherDescription: 'agencyOnAgingOtherDescription'
    },
    {
      name: 'Public Housing',
      ProgramOrservice: 'publicHousingProgramOrservice',
      SharedFacility: 'publicHousingSharedFacility',
      SharedStaff: 'publicHousingSharedStaff',
      JointEvent: 'publicHousingJointEvent',
      PolicyAdvocacy: 'publicHousingPolicyAdvocacy',
      Other: 'publicHousingOther',
      OtherDescription: 'publicHousingOtherDescription'
    },
    {
      name: 'Retirement/Nursing Home',
      ProgramOrservice: 'retirementNursingHomeProgramOrservice',
      SharedFacility: 'retirementNursingHomeSharedFacility',
      SharedStaff: 'retirementNursingHomeSharedStaff',
      JointEvent: 'retirementNursingHomeJointEvent',
      PolicyAdvocacy: 'retirementNursingHomePolicyAdvocacy',
      Other: 'retirementNursingHomeOther',
      OtherDescription: 'retirementNursingHomeOtherDescription'
    },
    {
      name: 'Theater',
      ProgramOrservice: 'theaterProgramOrservice',
      SharedFacility: 'theaterSharedFacility',
      SharedStaff: 'theaterSharedStaff',
      JointEvent: 'theaterJointEvent',
      PolicyAdvocacy: 'theaterPolicyAdvocacy',
      Other: 'theaterOther',
      OtherDescription: 'theaterOtherDescription'
    }
  ];

  const getAROtherCommunityOrg = () => {
    if (selectedAsso && selectedBranch) {
      setLoader(true);
      handleGetARCollabOtherCommunityOrgApi(selectedAsso, selectedBranch).then(
        res => {
          if (res?.data?.isSuccess) {
            setInitialValues(res?.data?.data);
            setLoader(false);
          } else {
            setLoader(false);
            setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
            setErrorPopup(true);
          }
        }
      );
    }
  };

  useEffect(() => {
    getAROtherCommunityOrg();
    formikref?.current?.resetForm();
  }, [selectedAsso, selectedBranch]);
  const [instructions, setInstructions] = useState('');
  useEffect(() => {
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'other-community-organizations'
      ]?.Instruction
    );
  }, []);

  const handleChange = (e, setFieldValue, name) => {
    setFieldValue(name, e.target.value);
  };

  const handleOtherFieldChange = (e, setFieldValue, name, discribeName) => {
    setFieldValue(name, e.target.checked);
    if (!e.target.checked) {
      setFieldValue(discribeName, '');
    }
  };

  const onSubmit = values => {
    setSuccessPopup(false);
    setLoader(true);
    const data = { ...values };
    data.branchNumber = selectedBranch;
    data.branchName = selectedbranchName;
    handlePostARCollabOtherCommunityOrgApi(data).then(res => {
      if (res?.data?.isSuccess) {
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        setSuccessPopup(true);
        getAROtherCommunityOrg();
      } else {
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
      setLoader(false);
    });
  };

  const handleNonofTheAbove = (allValues, value, setValues) => {
    const updateData = { ...allValues };
    fieldsObj.forEach(data => {
      Object.keys(data).forEach(key => {
        if (key !== 'name' && key !== 'OtherDescription') {
          updateData[data[key]] = false;
        }
        if (key === 'OtherDescription') {
          updateData[data[key]] = '';
        }
      });
    });
    if (value) {
      updateData.noneOfTheOrganizations = true;
    } else {
      updateData.noneOfTheOrganizations = false;
    }

    setValues(updateData);
  };

  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty, setValues }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-program">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                    <div className="ymca-instructionBoxes">
                      <h4 className="ymca-instructHeadline">
                        {APP_LABELS.INSTRUCTIONS}
                      </h4>
                      <p className="ymca-instructTitle">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: instructions
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="ymca-covidRelif py-0">
                    <h4 className="ymca-programActivities d-flex ms-0">
                      <ul className="ymca-mainCheckBTN">
                        <li className="ymca-listCheckBTN">
                          <input
                            className="ymca-checkboxStyled me-2"
                            id="noneOfTheOrganizations"
                            type="checkbox"
                            onChange={e =>
                              handleNonofTheAbove(
                                values,
                                e.target.checked,
                                setValues
                              )
                            }
                            value={values.noneOfTheOrganizations}
                            checked={values.noneOfTheOrganizations}
                          />
                          <label
                            htmlFor="noneOfTheOrganizations"
                            className="ymca-text ymca-textContent"
                          >
                            &nbsp;
                          </label>
                        </li>
                      </ul>
                      <span> {APP_LABELS.NONE_OF_THE_BELOW_APPLY} </span>
                    </h4>
                    <div
                      className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3 ${
                        values.noneOfTheOrganizations && 'ymca-disabled-section'
                      }`}
                    />

                    <div
                      className={`ymca-tableResponsive ${
                        values.noneOfTheOrganizations && 'ymca-disabled-section'
                      }`}
                    >
                      <table className="table ymca-table table-striped">
                        <thead>
                          <tr className="ymca-cuttingData">
                            <th>Organization</th>
                            <th>Shared Program or Service</th>
                            <th>Shared Facility</th>
                            <th>Shared Staff</th>
                            <th>Joint Event</th>
                            <th>Policy/ Advocacy</th>
                            <th>Other</th>
                            <th>Other (Describe)</th>
                            <th className="ymca-defaultSpace">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="ymca-cuttingBody">
                          {fieldsObj?.map(data => (
                            <tr key={data?.ProgramOrservice}>
                              <td className="ymca-normalWrap">{data.name}</td>
                              <td className="ymca-normalWrap">
                                <ul className="ymca-mainCheckBTN">
                                  <li className="ymca-listCheckBTN">
                                    <input
                                      className="ymca-checkboxStyled"
                                      id={data.ProgramOrservice}
                                      type="checkbox"
                                      onChange={e =>
                                        setFieldValue(
                                          data.ProgramOrservice,
                                          e.target.checked
                                        )
                                      }
                                      value={values[data.ProgramOrservice]}
                                      name={data.ProgramOrservice}
                                      checked={values[data.ProgramOrservice]}
                                      disabled={values.noneOfTheOrganizations}
                                    />
                                    <label
                                      htmlFor={data.ProgramOrservice}
                                      className="ymca-text ymca-textContent"
                                    >
                                      &nbsp;
                                    </label>
                                  </li>
                                </ul>
                              </td>
                              <td className="ymca-normalWrap">
                                <ul className="ymca-mainCheckBTN">
                                  <li className="ymca-listCheckBTN">
                                    <input
                                      className="ymca-checkboxStyled"
                                      id={data.SharedFacility}
                                      type="checkbox"
                                      onChange={e =>
                                        setFieldValue(
                                          data.SharedFacility,
                                          e.target.checked
                                        )
                                      }
                                      value={values[data.SharedFacility]}
                                      name={data.SharedFacility}
                                      checked={values[data.SharedFacility]}
                                      disabled={values.noneOfTheOrganizations}
                                    />
                                    <label
                                      htmlFor={data.SharedFacility}
                                      className="ymca-text ymca-textContent"
                                    >
                                      &nbsp;
                                    </label>
                                  </li>
                                </ul>
                              </td>
                              <td className="ymca-normalWrap">
                                <ul className="ymca-mainCheckBTN">
                                  <li className="ymca-listCheckBTN">
                                    <input
                                      className="ymca-checkboxStyled"
                                      id={data.SharedStaff}
                                      type="checkbox"
                                      onChange={e =>
                                        setFieldValue(
                                          data.SharedStaff,
                                          e.target.checked
                                        )
                                      }
                                      value={values[data.SharedStaff]}
                                      name={data.SharedStaff}
                                      checked={values[data.SharedStaff]}
                                      disabled={values.noneOfTheOrganizations}
                                    />
                                    <label
                                      htmlFor={data.SharedStaff}
                                      className="ymca-text ymca-textContent"
                                    >
                                      &nbsp;
                                    </label>
                                  </li>
                                </ul>
                              </td>
                              <td className="ymca-normalWrap">
                                <ul className="ymca-mainCheckBTN">
                                  <li className="ymca-listCheckBTN">
                                    <input
                                      className="ymca-checkboxStyled"
                                      id={data.JointEvent}
                                      type="checkbox"
                                      onChange={e =>
                                        setFieldValue(
                                          data.JointEvent,
                                          e.target.checked
                                        )
                                      }
                                      value={values[data.JointEvent]}
                                      name={data.JointEvent}
                                      checked={values[data.JointEvent]}
                                      disabled={values.noneOfTheOrganizations}
                                    />
                                    <label
                                      htmlFor={data.JointEvent}
                                      className="ymca-text ymca-textContent"
                                    >
                                      &nbsp;
                                    </label>
                                  </li>
                                </ul>
                              </td>
                              <td className="ymca-normalWrap">
                                <ul className="ymca-mainCheckBTN">
                                  <li className="ymca-listCheckBTN">
                                    <input
                                      className="ymca-checkboxStyled"
                                      id={data.PolicyAdvocacy}
                                      type="checkbox"
                                      onChange={e =>
                                        setFieldValue(
                                          data.PolicyAdvocacy,
                                          e.target.checked
                                        )
                                      }
                                      value={values[data.PolicyAdvocacy]}
                                      name={data.PolicyAdvocacy}
                                      checked={values[data.PolicyAdvocacy]}
                                      disabled={values.noneOfTheOrganizations}
                                    />
                                    <label
                                      htmlFor={data.PolicyAdvocacy}
                                      className="ymca-text ymca-textContent"
                                    >
                                      &nbsp;
                                    </label>
                                  </li>
                                </ul>
                              </td>
                              <td className="ymca-normalWrap">
                                <ul className="ymca-mainCheckBTN">
                                  <li className="ymca-listCheckBTN">
                                    <input
                                      className="ymca-checkboxStyled"
                                      id={data.Other}
                                      type="checkbox"
                                      onChange={e =>
                                        handleOtherFieldChange(
                                          e,
                                          setFieldValue,
                                          data.Other,
                                          data.OtherDescription
                                        )
                                      }
                                      value={values[data.Other]}
                                      name={data.Other}
                                      checked={values[data.Other]}
                                      disabled={values.noneOfTheOrganizations}
                                    />
                                    <label
                                      htmlFor={data.Other}
                                      className="ymca-text ymca-textContent"
                                    >
                                      &nbsp;
                                    </label>
                                  </li>
                                </ul>
                              </td>
                              <td className="ymca-fieldLine">
                                <input
                                  type="text"
                                  maxLength={255}
                                  className={
                                    values[data.Other] &&
                                    !values[data.OtherDescription]
                                      ? `${
                                          !values[data.Other] &&
                                          'ymca-fieldDisabled'
                                        } ymca-field `
                                      : `${
                                          !values[data.Other] &&
                                          'ymca-fieldDisabled'
                                        } ymca-field`
                                  }
                                  onChange={e =>
                                    handleChange(
                                      e,
                                      setFieldValue,
                                      data.OtherDescription
                                    )
                                  }
                                  value={values[data.OtherDescription]!==null?values[data.OtherDescription]:''}
                                  name={data.OtherDescription}
                                  disabled={!values[data.Other]}
                                />
                                <label
                                  htmlFor={data.OtherDescription}
                                  className="ymca-text ymca-textContent"
                                >
                                  &nbsp;
                                </label>
                              </td>
                              <td className="ymca-defaultSpace">&nbsp;</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={initialValues?.updatedByName}
                  Time={initialValues?.updatedDatetime}
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </>
  );
};

OtherCommunityOrg.propTypes = {
  handleGetARCollabOtherCommunityOrgApi: PropTypes.func.isRequired,
  handlePostARCollabOtherCommunityOrgApi: PropTypes.func.isRequired,
  setLoader: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedAsso: PropTypes.string.isRequired,
  selectedBranch: PropTypes.string.isRequired,
  setSuccessPopup: PropTypes.func.isRequired,
  setErrorPopup: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedbranchName: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetARCollabOtherCommunityOrgApi:
    getAnnualReportCollabOtherCommunityOrgApi,
  handlePostARCollabOtherCommunityOrgApi:
    PostAnnualReportCollabOtherCommunityOrgApi,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

const mapStateToProps = state => ({
  CollabOtherCommunityOrgApi: CollabOtherCommunityOrgSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OtherCommunityOrg));
