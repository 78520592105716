import * as types from './types';

const initialState = {
  GetMembershipKnowYourCommunity: {},
  PostMembershipKnowYourCommunity: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_KEY':
      return {
        ...state,
        [action.payload.key]: {}
      };
    case types.GET_MEMBERSHIP_NOW_YOUR_COMMUNITY_SUCCESS: {
      return {
        ...state,
        GetMembershipKnowYourCommunity: action.payload
      };
    }
    case types.GET_MEMBERSHIP_NOW_YOUR_COMMUNITY_FAILURE:
      return { ...state, error: action.payload };

    case types.POST_MEMBERSHIP_NOW_YOUR_COMMUNITY_SUCCESS: {
      return {
        ...state,
        PostMembershipKnowYourCommunity: action.payload
      };
    }
    case types.POST_MEMBERSHIP_NOW_YOUR_COMMUNITY_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
