import { useState } from 'react';
import CryptoJS from 'crypto-js';

const HASH_KEY = process.env.REACT_APP_STORAGE_SECRET_KEY;

function useSessionStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const encodedWord = CryptoJS.enc.Utf8.parse(key);
      const encryptedKey = CryptoJS.enc.Base64.stringify(encodedWord);
      const item = window.sessionStorage.getItem(encryptedKey);
      const decyptedValue = CryptoJS.AES.decrypt(item, HASH_KEY).toString(
        CryptoJS.enc.Utf8
      );
      return item ? JSON.parse(decyptedValue) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      const encodedWord = CryptoJS.enc.Utf8.parse(key);
      const encryptedKey = CryptoJS.enc.Base64.stringify(encodedWord);
      const encyptedValue = CryptoJS.AES.encrypt(
        JSON.stringify(valueToStore),
        HASH_KEY
      );
      setStoredValue(encyptedValue);
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(encryptedKey, encyptedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export default useSessionStorage;
