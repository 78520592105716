import * as types from './types';

const initialState = {
  CdpAdminDashboard: {},
  CdpAdminDashboardKPI: []
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CDP_ADMIN_DASHBOARD_SUCCESS: {
      return {
        ...state,
        CdpAdminDashboard: action.payload
      };
    }
    case types.GET_CDP_ADMIN_DASHBOARD_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_CDP_ADMIN_DASHBOARD_KPI_SUCCESS: {
      return {
        ...state,
        CdpAdminDashboardKPI: action.payload
      };
    }
    case types.GET_CDP_ADMIN_DASHBOARD_KPI_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_ASSOCIATION_BY_NUMBER_SUCCESS: {
      return {
        ...state,
        association: action.payload
      };
    }
    case types.GET_ASSOCIATION_BY_NUMBER_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
