import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { APP_LABELS, CDP } from '@config/app-config';
import get from 'lodash.get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomModal from '@utilities/CustomModal';
import Breadcrumb from '@static/images/breadcrumb-icon.svg';
import UploadsWhiteIcon from '@static/images/uploads-white-icon.svg';
import DataActiveGreenLight from '@static/images/swimRegistration/data-green-light.svg';
import DataGrren from '@static/images/swimRegistration/data-green.svg';
import YellowIcon from '@static/images/swimRegistration/data-yellow.svg';
import BackIcon from '@static/images/back-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import {
  SwimProfileSelector,
  SwimSelectedProgramSelector
} from '@redux/siwm-safety/swim-profiles/selectors';
import { getSwimSafetyImportProfiles } from '@redux/siwm-safety/swim-profiles/actions';
import Table from './table';
import { ADMIN_DASHBOARD_COLUMNS, INVALID_DASHBOARD_COLUMNS } from './columns';
import ViewDetailsPopup from './view-details-popup';
import ErrorPopup from '../upload-profile/error-popup';
import CardsList from './cardsList';

const UploadData = ({
  FetchSwimProfileData,
  handleSwimImportProfiles,
  FetchSelectedProgramSelector
}) => {
  const [activeTab, setActiveTab] = useState('Total Profiles');
  const [tableData, setTableData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isConfirmCheck, setIsConfirmCheck] = useState(false);
  const [showConfirmCheckMsg, setShowConfirmCheckMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [toastStatus, setToastStatus] = useState({ status: '', message: '' });
  const [cardDetails, setCardDetails] = useState({});
  const [selectedProfile, setSelectedProfile] = useState({});
  const [importDisable, setImportDisable] = useState(true);

  const newTableData = tableData?.map(data => ({
    ...data,
    childBirthDate: get(data, 'childBirthDate')
      ? convertUtcToLocal(get(data, 'childBirthDate'), 'DD MMM YYYY')
      : '-',
    errorSummary: data?.errorSummary
      ?.split(',')
      ?.map(res => <p key={res}>{res}</p>),
    details: (
      <button
        type="button"
        onClick={() => {
          setSelectedProfile(data);
          setShowPopup(true);
        }}
        className="table-link swim-viewdetails-btn btn"
      >
        {APP_LABELS.DASH_VIEW_DETAILS}
      </button>
    )
  }));

  useEffect(() => {
    setImportDisable(true);
    if (activeTab === 'Valid Profiles') {
      setImportDisable(false);
    } else {
      setImportDisable(
        !(
          get(FetchSwimProfileData?.data?.segregatedList, 'TotalProfiles', [])
            .length ===
          get(FetchSwimProfileData?.data?.segregatedList, 'NewProfiles', [])
            .length +
            get(
              FetchSwimProfileData?.data?.segregatedList,
              'ExistedProfiles',
              []
            ).length
        )
      );
    }
  }, [FetchSwimProfileData?.data?.segregatedList, activeTab]);

  useEffect(() => {
    const data = get(
      FetchSwimProfileData?.data?.segregatedList,
      'TotalProfiles',
      []
    );

    setCardDetails(FetchSelectedProgramSelector);
    setTableData(data);
  }, []);

  const handleTabs = tab => {
    if (tab === 'Total Profiles') {
      setActiveTab(tab);
      const data = get(
        FetchSwimProfileData?.data?.segregatedList,
        'TotalProfiles',
        []
      );
      setTableData(data);
    } else if (tab === 'Valid Profiles') {
      setActiveTab(tab);
      const data = [
        ...get(
          FetchSwimProfileData?.data?.segregatedList,
          'ExistedProfiles',
          []
        ),
        ...get(FetchSwimProfileData?.data?.segregatedList, 'NewProfiles', [])
      ];
      setTableData(data);
    } else if (tab === 'Invalid Profiles') {
      setActiveTab(tab);
      const data = get(FetchSwimProfileData?.data?.segregatedList, 'Error', []);
      setTableData(data);
    }
  };

  const showStatus = response => {
    if (
      response.status === 200 &&
      response?.data &&
      response.data?.success !== 'Failed'
    ) {
      if (response.status === 200 && response.data) {
        let seconds = 5;
        setToastStatus({
          status: APP_LABELS.SUCCESS,
          message: String.format(CDP.MESSAGES.importSuccessSwim)
        });
        const countdownInterval = setInterval(() => {
          seconds--;
          setToastStatus({
            status: APP_LABELS.SUCCESS,
            message: String.format(CDP.MESSAGES.importSuccess)
          });
          if (seconds === 0) {
            clearInterval(countdownInterval);
          }
        }, 1000);
      }
      if (response.hasOwnProperty('isSuccess') && !response.isSuccess) {
        setToastStatus({
          status: APP_LABELS.ERROR,
          message: APP_LABELS.ERROR_MESSAGE
        });
      }
    } else {
      setShowErrorPopup(true);
      setShowErrorMessage(
        response?.data?.message || 'Failed to import profiles data'
      );
    }
  };

  const onImportClick = async () => {
    if (!isConfirmCheck) {
      setShowConfirmCheckMsg(true);
      return;
    }
    const data = [
      ...get(FetchSwimProfileData?.data?.segregatedList, 'ExistedProfiles', []),
      ...get(FetchSwimProfileData?.data?.segregatedList, 'NewProfiles', [])
    ];

    const updateData = data?.map(res => {
      const updateRes = { ...res };
      Object.keys(res).forEach(key => {
        let value = res[key];
        if (
          value !== null &&
          typeof value === 'string' &&
          value.toUpperCase() === 'YES'
        ) {
          updateRes[key] = true;
        } else if (
          value !== null &&
          typeof value === 'string' &&
          value.toUpperCase() === 'NO'
        ) {
          updateRes[key] = false;
        }
      });
      return updateRes;
    });
    const payload = {
      profilesImport: [...updateData]
    };

    setLoader(true);
    handleSwimImportProfiles(payload)
      .then(response => {
        setLoader(false);
        showStatus(response);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onOkError = () => {
    if (toastStatus.status === APP_LABELS.SUCCESS) {
      setRedirect(true);
    } else {
      setToastStatus({});
    }
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <div className="container">
              <section className="dashboard-header">
                <div className="row align-items-center">
                  <div className="col-lg-11 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb ymca-breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/swim/association/dashboard">
                            {APP_LABELS.NAV_DASHBOARD}
                          </Link>
                          <img
                            src={Breadcrumb}
                            className="breadcrumb-icon"
                            alt="double-right-arrow"
                          />
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/swim/upload/profile">Upload Profiles</Link>
                          <img
                            src={Breadcrumb}
                            className="breadcrumb-icon"
                            alt="double-right-arrow"
                          />
                        </li>

                        <li className="breadcrumb-item active">Upload Data</li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-lg-1 col-md-4 col-sm-12 text-lg-end text-md-end">
                    <Link
                      className="ymca-title-link d-flex justify-content-end"
                      to="/swim/upload/profile"
                    >
                      <img src={BackIcon} className="me-2" alt="" />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  </div>
                </div>
              </section>

              <CardsList cardDetails={cardDetails} />

              <hr className="custom-hr mt-0" />

              <section className="stats-data">
                <div className="row ">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <button
                      type="button"
                      className={`swim-uploadDataCards ${
                        activeTab === 'Total Profiles'
                          ? 'swim-active-branches'
                          : 'swim-safety-associations'
                      } w-100`}
                      onClick={() => handleTabs('Total Profiles')}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          {activeTab === 'Total Profiles' ? (
                            <img
                              src={UploadsWhiteIcon}
                              className="dark-icon"
                              alt="icon"
                            />
                          ) : (
                            <img
                              src={DataGrren}
                              className="dark-icon"
                              alt="icon"
                            />
                          )}
                        </div>
                        <div className="card-title">
                          <h4>
                            {get(
                              FetchSwimProfileData?.data?.fileUploadSummary,
                              'totalUsers',
                              '0'
                            )}
                          </h4>
                          <p>Total Profiles</p>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <button
                      type="button"
                      className={`swim-uploadDataCards
                       ${
                         activeTab === 'Valid Profiles'
                           ? 'swim-safety-branches-active'
                           : 'swim-safety-branches'
                       } w-100`}
                      onClick={() => handleTabs('Valid Profiles')}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          {activeTab === 'Valid Profiles' ? (
                            <img
                              src={UploadsWhiteIcon}
                              className="dark-icon"
                              alt="icon"
                            />
                          ) : (
                            <img
                              src={DataActiveGreenLight}
                              className="dark-icon"
                              alt="icon"
                            />
                          )}
                        </div>
                        <div className="card-title">
                          <h4>
                            {' '}
                            {get(
                              FetchSwimProfileData?.data?.fileUploadSummary,
                              'existedUsers',
                              0
                            ) +
                              get(
                                FetchSwimProfileData?.data?.fileUploadSummary,
                                'newUsers',
                                0
                              )}
                          </h4>
                          <p>Valid Profile</p>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <button
                      type="button"
                      className={`swim-uploadDataCards ${
                        activeTab === 'Invalid Profiles'
                          ? 'swim-saftey-programs-active'
                          : 'swim-saftey-programs'
                      } w-100`}
                      onClick={() => handleTabs('Invalid Profiles')}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          {activeTab === 'Invalid Profiles' ? (
                            <img
                              src={UploadsWhiteIcon}
                              className="dark-icon"
                              alt="icon"
                            />
                          ) : (
                            <img
                              src={YellowIcon}
                              className="dark-icon"
                              alt="icon"
                            />
                          )}
                        </div>

                        <div className="card-title">
                          <h4>
                            {get(
                              FetchSwimProfileData?.data?.fileUploadSummary,
                              'error',
                              '0'
                            )}
                          </h4>
                          <p>Invalid Profile</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </section>

              <Table
                rows={newTableData}
                headers={
                  activeTab === 'Invalid Profiles'
                    ? INVALID_DASHBOARD_COLUMNS()
                    : ADMIN_DASHBOARD_COLUMNS()
                }
                title={activeTab}
              />

              <section className="ymca-form mt-3">
                <div className="container">
                  <div className="form-group">
                    <div className="form-check ymca-check-box my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={e => setIsConfirmCheck(e.target.checked)}
                        value={isConfirmCheck}
                        disabled={importDisable}
                      />
                      <label
                        className="form-check-label ymca-cachet-normal-dark-text"
                        htmlFor="flexCheckDefault"
                      >
                        I confirm to the changes in system.
                      </label>
                      {showConfirmCheckMsg && !isConfirmCheck && (
                        <p className="errorNote">
                          * Please check the confirm import data by checking
                          checkbox.
                        </p>
                      )}
                    </div>
                  </div>
                  <Link
                    to="/swim/upload/profile"
                    // onClick={e => onBackClick(e)}
                    role="button"
                    className="btn ymca-primary-outline-btn login-btn mt-0"
                  >
                    Cancel
                  </Link>
                  <button
                    disabled={importDisable}
                    className="btn ms-3 ymca-primary-btn login-btn mt-0"
                    type="button"
                    onClick={onImportClick}
                  >
                    {APP_LABELS.UPLOAD_DATA_IMPORT_DATA}
                  </button>
                </div>
              </section>

              <ViewDetailsPopup
                showViewDetailsPopup={showPopup}
                handleCloseNewStaffPopup={() => setShowPopup(false)}
                popupTitle="Profile Details"
                popupData={{
                  profileDetails: selectedProfile,
                  programDeatils: FetchSelectedProgramSelector
                }}
              />

              <CustomModal
                show={toastStatus.status}
                type={toastStatus.status}
                message={toastStatus.message}
                submitButtonName={
                  toastStatus.status === APP_LABELS.SUCCESS
                    ? 'Redirect to dashboard'
                    : 'Ok'
                }
                onSubmit={onOkError}
              />

              <ErrorPopup
                handleCloseErrorPopup={() => setShowErrorPopup(false)}
                errorMessage={showErrorMessage}
                showErrorPopup={showErrorPopup}
              />
              {redirect && <Redirect to="/swim/association/dashboard" />}
            </div>
          </div>
        </main>
      </div>
    </BlockUi>
  );
};

UploadData.propTypes = {
  FetchSwimProfileData: PropTypes.objectOf(PropTypes.any).isRequired,
  FetchSelectedProgramSelector: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSwimImportProfiles: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  FetchSwimProfileData: SwimProfileSelector(state),
  FetchSelectedProgramSelector: SwimSelectedProgramSelector(state)
});

const mapDispatchToProps = {
  handleSwimImportProfiles: getSwimSafetyImportProfiles
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadData);
