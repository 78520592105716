import {
  MEMBERSHIPS_GET_URL,
  MEMBERSHIPS_POST_URL,
  MEMBERSHIPS_NWM_GET_URL,
  GET_CRM_PROVIDER_LIST
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getMemberships = associationsNum => (dispatch, getState) =>
  asyncRequest({
    url: MEMBERSHIPS_GET_URL(associationsNum),
    successAction: data => ({
      type: types.GET_MEMBERSHIPS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_MEMBERSHIPS_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getMembershipsNWM = associationsNum => (dispatch, getState) =>
  asyncRequest({
    url: MEMBERSHIPS_NWM_GET_URL(associationsNum),
    successAction: data => ({
      type: types.GET_MEMBERSHIPS_NWM_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_MEMBERSHIPS_NWM_FAILURE,
      payload: error
    }),
    dispatch
  });

export const postMemberships = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: MEMBERSHIPS_POST_URL(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_MEMBERSHIPS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_MEMBERSHIPS_FAILURE,
      payload: error
    }),
    dispatch
  });
export const getCrmProviderList = () => (dispatch, getState) =>
  asyncRequest({
    url: GET_CRM_PROVIDER_LIST(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CRM_PROVIDER_LIST_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_CRM_PROVIDER_LIST_FAILURE,
      payload: error
    }),
    dispatch
  });
export const resetReduxState = key => ({
  type: 'RESET_KEY',
  payload: { key }
});
