import {
  STRATEGIC_RELATIONSHIPS_GET_URL,
  STRATEGIC_RELATIONSHIPS_POST_URL
} from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getStrategicRelationships =
  associationsNum => (dispatch, getState) =>
    asyncRequest({
      url: STRATEGIC_RELATIONSHIPS_GET_URL(associationsNum),
      successAction: data => ({
        type: types.GET_STRATEGIC_RELATIONSHIPS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_STRATEGIC_RELATIONSHIPS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const postStrategicRelationships = payloadBody => (dispatch, getState) =>
  asyncRequest({
    url: STRATEGIC_RELATIONSHIPS_POST_URL(),
    method: 'POST',
    data: payloadBody,
    successAction: data => ({
      type: types.POST_STRATEGIC_RELATIONSHIPS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.POST_STRATEGIC_RELATIONSHIPS_FAILURE,
      payload: error
    }),
    dispatch
  });
export const resetReduxState = key => ({
  type: 'RESET_KEY',
  payload: { key }
});
