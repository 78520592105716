import * as types from './types';

const initialState = {
  ARemployeeData: {},
  error: {}
};

export default function ARemployeeReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_AR_EMPLOYEE_UPDATE_SUCCESS: {
      return {
        ...state,
        ARemployeeData: action.payload
      };
    }
    case types.FETCH_AR_EMPLOYEE_API_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
