export const ASSOCIATION_NUMBER_SUCCESS = 'ASSOCIATION_NUMBER_SUCCESS';
export const ASSOCIATION_NUMBER_FAILURE = 'ASSOCIATION_NUMBER_FAILURE';

export const ASSOCIATION_SUMMARY_SUCCESS = 'ASSOCIATION_SUMMARY_SUCCESS';
export const ASSOCIATION_SUMMARY_FAILURE = 'ASSOCIATION_SUMMARY_FAILURE';

export const ASSOCIATION_CHANGE_LOG_SUCCESS = 'ASSOCIATION_CHANGE_LOG_SUCCESS';
export const ASSOCIATION_CHANGE_LOG_FAILURE = 'ASSOCIATION_CHANGE_LOG_FAILURE';

export const ASSOCIATION_LIST_SUCCESS = 'ASSOCIATION_LIST_SUCCESS';
export const ASSOCIATION_LIST_FAILURE = 'ASSOCIATION_LIST_FAILURE';

export const ASSOCIATION_MOYP_CHANGE_LOG_SUCCESS =
  'ASSOCIATION_MOYP_CHANGE_LOG_SUCCESS';
export const ASSOCIATION_MOYP_CHANGE_LOG_FAILURE =
  'ASSOCIATION_MOYP_CHANGE_LOG_FAILURE';
