/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import {
  getMembershipNowYourCommunity,
  postMembershipNowYourCommunity,
  resetReduxState
} from '@redux/diversity-inclusion-&-global-metrics/membership-know-your-community/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetMembershipKnowYourCommunitySelector } from '@redux/diversity-inclusion-&-global-metrics/membership-know-your-community/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import Help from '@modules/shared/components/reusable-components/help';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  YesRepresentTheMakeup: Yup.array().nullable(),
  NoMeasurableProgressAll: Yup.array().nullable(),
  NoMeasurableProgressSome: Yup.array().nullable(),
  NoMeasurableProgressLastYear: Yup.array().nullable(),
  NotKnowInformation: Yup.array().nullable(),
  specificStrategies: Yup.string().nullable()
});

const MembershipKnowYourCommunityTab = props => {
  const {
    handleGetMembershipNowYourCommunity,
    handlePostMembershipNowYourCommunity,
    GetMembershipKnowYourCommunitySelectorData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard,
    handleResetRedux
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Diversity Inclusion & Global Metrics';
  const formikref = useRef();
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [resStatus, setResStatus] = useState();
  const [specificStrategiesCount, setSpecificStrategiesCount] = useState(
    GetMembershipKnowYourCommunitySelectorData?.data?.specificStrategies
      ?.length || 0
  );
  const [specificStrategies, setSpecificStrategies] = useState(
    GetMembershipKnowYourCommunitySelectorData?.data?.specificStrategies
  );

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = success => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetMembershipNowYourCommunity(associationsNum).then(res => {
      if (res.status === 200) {
        setSpecificStrategiesCount(res?.data?.data?.specificStrategies?.length || 0);
        setSpecificStrategies(res?.data?.data?.specificStrategies);
        setResStatus(res.data.data?.recordStatus);
        setLoader(false);
        if (success) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'membership-know-your-community'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetMembershipKnowYourCommunity');
    };
  }, []);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const initialValues = {
    YesRepresentTheMakeup: [],
    NoMeasurableProgressAll: [],
    NoMeasurableProgressSome: [],
    NoMeasurableProgressLastYear: [],
    NotKnowInformation: []
  };

  const YesRepresentTheMakeupArray = [];
  const NoMeasurableProgressAllArray = [];
  const NoMeasurableProgressSomeArray = [];
  const NoMeasurableProgressLastYearArray = [];

  if (
    GetMembershipKnowYourCommunitySelectorData?.data?.yesRepresentTheMakeup?.includes(
      'makeupOfOurCommunityYstaff'
    )
  ) {
    YesRepresentTheMakeupArray.push('makeupOfOurCommunityYstaff');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressAll?.includes(
      'lastYearInAllOfYstaff'
    )
  ) {
    YesRepresentTheMakeupArray.push('lastYearInAllOfYstaff');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressSome?.includes(
      'lastYearInSomeOfYstaff'
    )
  ) {
    YesRepresentTheMakeupArray.push('lastYearInSomeOfYstaff');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressLastYear?.includes(
      'madeLittleYstaff'
    )
  ) {
    YesRepresentTheMakeupArray.push('madeLittleYstaff');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.notKnowInformation?.includes(
      'doNotKnowYstaff'
    )
  ) {
    YesRepresentTheMakeupArray.push('doNotKnowYstaff');
  }
  // ******************************************************************************** */
  if (
    GetMembershipKnowYourCommunitySelectorData?.data?.yesRepresentTheMakeup?.includes(
      'makeupOfOurCommunityBoard'
    )
  ) {
    NoMeasurableProgressAllArray.push('makeupOfOurCommunityBoard');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressAll?.includes(
      'lastYearInAllOfBoard'
    )
  ) {
    NoMeasurableProgressAllArray.push('lastYearInAllOfBoard');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressSome?.includes(
      'lastYearInSomeOfBoard'
    )
  ) {
    NoMeasurableProgressAllArray.push('lastYearInSomeOfBoard');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressLastYear?.includes(
      'madeLittleBoard'
    )
  ) {
    NoMeasurableProgressAllArray.push('madeLittleBoard');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.notKnowInformation?.includes(
      'doNotKnowBoard'
    )
  ) {
    NoMeasurableProgressAllArray.push('doNotKnowBoard');
  }

  // ******************************************************************************** */
  if (
    GetMembershipKnowYourCommunitySelectorData?.data?.yesRepresentTheMakeup?.includes(
      'makeupOfOurCommunityOtherVolunteers'
    )
  ) {
    NoMeasurableProgressSomeArray.push('makeupOfOurCommunityOtherVolunteers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressAll?.includes(
      'lastYearInAllOfOtherVolunteers'
    )
  ) {
    NoMeasurableProgressSomeArray.push('lastYearInAllOfOtherVolunteers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressSome?.includes(
      'lastYearInSomeOfOtherVolunteers'
    )
  ) {
    NoMeasurableProgressSomeArray.push('lastYearInSomeOfOtherVolunteers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressLastYear?.includes(
      'madeLittleOtherVolunteers'
    )
  ) {
    NoMeasurableProgressSomeArray.push('madeLittleOtherVolunteers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.notKnowInformation?.includes(
      'doNotKnowOtherVolunteers'
    )
  ) {
    NoMeasurableProgressSomeArray.push('doNotKnowOtherVolunteers');
  }

  // ******************************************************************************** */
  if (
    GetMembershipKnowYourCommunitySelectorData?.data?.yesRepresentTheMakeup?.includes(
      'makeupOfOurCommunityMembers'
    )
  ) {
    NoMeasurableProgressLastYearArray.push('makeupOfOurCommunityMembers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressAll?.includes(
      'lastYearInAllOfMembers'
    )
  ) {
    NoMeasurableProgressLastYearArray.push('lastYearInAllOfMembers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressSome?.includes(
      'lastYearInSomeOfMembers'
    )
  ) {
    NoMeasurableProgressLastYearArray.push('lastYearInSomeOfMembers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.noMeasurableProgressLastYear?.includes(
      'madeLittleMembers'
    )
  ) {
    NoMeasurableProgressLastYearArray.push('madeLittleMembers');
  } else if (
    GetMembershipKnowYourCommunitySelectorData?.data?.notKnowInformation?.includes(
      'doNotKnowMembers'
    )
  ) {
    NoMeasurableProgressLastYearArray.push('doNotKnowMembers');
  }

  const editValues = {
    YesRepresentTheMakeup: YesRepresentTheMakeupArray[0],
    NoMeasurableProgressAll: NoMeasurableProgressAllArray[0],
    NoMeasurableProgressSome: NoMeasurableProgressSomeArray[0],
    NoMeasurableProgressLastYear: NoMeasurableProgressLastYearArray[0],
    specificStrategies:
      GetMembershipKnowYourCommunitySelectorData?.data?.specificStrategies
  };

  const onSubmit = values => {
    setLoader(true);

    const firstQuestion = [];
    const secondQuestion = [];
    const thirdQuestion = [];
    const fourthQuestion = [];
    const fifthQuestion = [];

    if (values.YesRepresentTheMakeup?.includes('makeupOfOurCommunityYstaff')) {
      firstQuestion.push('makeupOfOurCommunityYstaff');
    } else if (
      values.YesRepresentTheMakeup?.includes('lastYearInAllOfYstaff')
    ) {
      secondQuestion.push('lastYearInAllOfYstaff');
    } else if (
      values.YesRepresentTheMakeup?.includes('lastYearInSomeOfYstaff')
    ) {
      thirdQuestion.push('lastYearInSomeOfYstaff');
    } else if (values.YesRepresentTheMakeup?.includes('madeLittleYstaff')) {
      fourthQuestion.push('madeLittleYstaff');
    } else if (values.YesRepresentTheMakeup?.includes('doNotKnowYstaff')) {
      fifthQuestion.push('doNotKnowYstaff');
    }

    if (values.NoMeasurableProgressAll?.includes('makeupOfOurCommunityBoard')) {
      firstQuestion.push('makeupOfOurCommunityBoard');
    } else if (
      values.NoMeasurableProgressAll?.includes('lastYearInAllOfBoard')
    ) {
      secondQuestion.push('lastYearInAllOfBoard');
    } else if (
      values.NoMeasurableProgressAll?.includes('lastYearInSomeOfBoard')
    ) {
      thirdQuestion.push('lastYearInSomeOfBoard');
    } else if (values.NoMeasurableProgressAll?.includes('madeLittleBoard')) {
      fourthQuestion.push('madeLittleBoard');
    } else if (values.NoMeasurableProgressAll?.includes('doNotKnowBoard')) {
      fifthQuestion.push('doNotKnowBoard');
    }

    if (
      values.NoMeasurableProgressSome?.includes(
        'makeupOfOurCommunityOtherVolunteers'
      )
    ) {
      firstQuestion.push('makeupOfOurCommunityOtherVolunteers');
    } else if (
      values.NoMeasurableProgressSome?.includes(
        'lastYearInAllOfOtherVolunteers'
      )
    ) {
      secondQuestion.push('lastYearInAllOfOtherVolunteers');
    } else if (
      values.NoMeasurableProgressSome?.includes(
        'lastYearInSomeOfOtherVolunteers'
      )
    ) {
      thirdQuestion.push('lastYearInSomeOfOtherVolunteers');
    } else if (
      values.NoMeasurableProgressSome?.includes('madeLittleOtherVolunteers')
    ) {
      fourthQuestion.push('madeLittleOtherVolunteers');
    } else if (
      values.NoMeasurableProgressSome?.includes('doNotKnowOtherVolunteers')
    ) {
      fifthQuestion.push('doNotKnowOtherVolunteers');
    }

    if (
      values.NoMeasurableProgressLastYear?.includes(
        'makeupOfOurCommunityMembers'
      )
    ) {
      firstQuestion.push('makeupOfOurCommunityMembers');
    } else if (
      values.NoMeasurableProgressLastYear?.includes('lastYearInAllOfMembers')
    ) {
      secondQuestion.push('lastYearInAllOfMembers');
    } else if (
      values.NoMeasurableProgressLastYear?.includes('lastYearInSomeOfMembers')
    ) {
      thirdQuestion.push('lastYearInSomeOfMembers');
    } else if (
      values.NoMeasurableProgressLastYear?.includes('madeLittleMembers')
    ) {
      fourthQuestion.push('madeLittleMembers');
    } else if (
      values.NoMeasurableProgressLastYear?.includes('doNotKnowMembers')
    ) {
      fifthQuestion.push('doNotKnowMembers');
    }

    const payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName:
        GetMembershipKnowYourCommunitySelectorData?.data?.createdByName,
      createdByPersonId:
        GetMembershipKnowYourCommunitySelectorData?.data?.createdByPersonId,
      createdDatetime:
        GetMembershipKnowYourCommunitySelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      knowYourCommunityId:
        GetMembershipKnowYourCommunitySelectorData?.data?.knowYourCommunityId,
      reportBatchId:
        GetMembershipKnowYourCommunitySelectorData?.data?.reportBatchId,
      recordStatus:
        GetMembershipKnowYourCommunitySelectorData?.data?.recordStatus,
      yesRepresentTheMakeup: firstQuestion,
      noMeasurableProgressAll: secondQuestion,
      noMeasurableProgressSome: thirdQuestion,
      noMeasurableProgressLastYear: fourthQuestion,
      notKnowInformation: fifthQuestion,

      specificStrategies
    };
    setSuccessPopup(false);
    handlePostMembershipNowYourCommunity(payloadBody).then(res => {
      if (res.status === 200) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall(true);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handelCount = (value, textareaName) => {
    if (textareaName === 'specificStrategies') {
      setSpecificStrategiesCount(value?.length || 0);
      setSpecificStrategies(value);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ dirty, errors }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-covidRelif">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <p className="ymca-instructTitle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: instructions
                        }}
                      />
                    </p>
                  </div>
                </div>

                {GetMembershipKnowYourCommunitySelectorData?.data?.recordStatus?.toUpperCase() ===
                  APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}

                <p className="ymca-textTitle mt-3">
                  {
                    'For each of the categories below, choose the rating that most accurately describes the degree to which this group in your Y represents the makeup of your community related to race, ethnicity, age, gender, income levels, sexual orientation, immigration status, or any other category where demographic data are available. This must be verifiable through actual data comparing internal demographics to external data. Note: Members/Constituents refers to the primary users of your Associations services.'
                  }
                </p>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table table-striped">
                    <thead>
                      <tr className="ymca-cuttingData">
                        <th>&nbsp;</th>
                        <th className="txt-mandatory pr-12">YMCA Staff</th>
                        <th className="txt-mandatory pr-12">Board</th>
                        <th className="txt-mandatory pr-12">
                          Other Volunteers
                        </th>
                        <th className="txt-mandatory pr-12">Members</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody">
                      <tr className="custom-row">
                        <td className="ymca-normalWrap ymcaFiftyPercent ">
                          Yes, we fully represent the makeup of our community in
                          all of the above categories
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="YesRepresentTheMakeup"
                              id="makeupOfOurCommunityYstaff"
                              type="radio"
                              className="styled-checkbox"
                              value="makeupOfOurCommunityYstaff"
                            />
                            <label htmlFor="makeupOfOurCommunityYstaff" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressAll"
                              id="makeupOfOurCommunityBoard"
                              type="radio"
                              className="styled-checkbox"
                              value="makeupOfOurCommunityBoard"
                            />
                            <label htmlFor="makeupOfOurCommunityBoard" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressSome"
                              id="makeupOfOurCommunityOtherVolunteers"
                              type="radio"
                              className="styled-checkbox"
                              value="makeupOfOurCommunityOtherVolunteers"
                            />
                            <label htmlFor="makeupOfOurCommunityOtherVolunteers" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressLastYear"
                              id="makeupOfOurCommunityMembers"
                              type="radio"
                              className="styled-checkbox"
                              value="makeupOfOurCommunityMembers"
                            />
                            <label htmlFor="makeupOfOurCommunityMembers" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent ">
                          No, but we have made clear, measurable progress in the
                          last year in all of the above listed categories
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="YesRepresentTheMakeup"
                              id="lastYearInAllOfYstaff"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInAllOfYstaff"
                            />
                            <label htmlFor="lastYearInAllOfYstaff" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressAll"
                              id="lastYearInAllOfBoard"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInAllOfBoard"
                            />
                            <label htmlFor="lastYearInAllOfBoard" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressSome"
                              id="lastYearInAllOfOtherVolunteers"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInAllOfOtherVolunteers"
                            />
                            <label htmlFor="lastYearInAllOfOtherVolunteers" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressLastYear"
                              id="lastYearInAllOfMembers"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInAllOfMembers"
                            />
                            <label htmlFor="lastYearInAllOfMembers" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent ">
                          No, but we have made clear, measurable progress in the
                          last year in some of the above listed categories
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="YesRepresentTheMakeup"
                              id="lastYearInSomeOfYstaff"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInSomeOfYstaff"
                            />
                            <label htmlFor="lastYearInSomeOfYstaff" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressAll"
                              id="lastYearInSomeOfBoard"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInSomeOfBoard"
                            />
                            <label htmlFor="lastYearInSomeOfBoard" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressSome"
                              id="lastYearInSomeOfOtherVolunteers"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInSomeOfOtherVolunteers"
                            />
                            <label htmlFor="lastYearInSomeOfOtherVolunteers" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressLastYear"
                              id="lastYearInSomeOfMembers"
                              type="radio"
                              className="styled-checkbox"
                              value="lastYearInSomeOfMembers"
                            />
                            <label htmlFor="lastYearInSomeOfMembers" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent">
                          No, and we have made little or no measurable progress
                          in the last year
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="YesRepresentTheMakeup"
                              id="madeLittleYstaff"
                              type="radio"
                              className="styled-checkbox"
                              value="madeLittleYstaff"
                            />
                            <label htmlFor="madeLittleYstaff" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressAll"
                              id="madeLittleBoard"
                              type="radio"
                              className="styled-checkbox"
                              value="madeLittleBoard"
                            />
                            <label htmlFor="madeLittleBoard" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressSome"
                              id="madeLittleOtherVolunteers"
                              type="radio"
                              className="styled-checkbox"
                              value="madeLittleOtherVolunteers"
                            />
                            <label htmlFor="madeLittleOtherVolunteers" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressLastYear"
                              id="madeLittleMembers"
                              type="radio"
                              className="styled-checkbox"
                              value="madeLittleMembers"
                            />
                            <label htmlFor="madeLittleMembers" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent ">
                          We do not know this information.
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="YesRepresentTheMakeup"
                              id="doNotKnowYstaff"
                              type="radio"
                              className="styled-checkbox"
                              value="doNotKnowYstaff"
                            />
                            <label htmlFor="doNotKnowYstaff" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressAll"
                              id="doNotKnowBoard"
                              type="radio"
                              className="styled-checkbox"
                              value="doNotKnowBoard"
                            />
                            <label htmlFor="doNotKnowBoard" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressSome"
                              id="doNotKnowOtherVolunteers"
                              type="radio"
                              className="styled-checkbox"
                              value="doNotKnowOtherVolunteers"
                            />
                            <label htmlFor="doNotKnowOtherVolunteers" />
                          </div>
                        </td>
                        <td className="ymca-normalWrap text-center">
                          <div className="">
                            <Field
                              name="NoMeasurableProgressLastYear"
                              id="doNotKnowMembers"
                              type="radio"
                              className="styled-checkbox"
                              value="doNotKnowMembers"
                            />
                            <label htmlFor="doNotKnowMembers" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  What specific strategies does your Y have in place to monitor,
                  maintain and promote community representation?
                </h4>

                <textarea
                  name="specificStrategies"
                  className="ymca-areaTextWidth mb-0"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={specificStrategies}
                  defaultValue={
                    GetMembershipKnowYourCommunitySelectorData?.data
                      ?.specificStrategies
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'specificStrategies')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${specificStrategiesCount}/8000)`}</p>
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={
                    GetMembershipKnowYourCommunitySelectorData?.data
                      ?.updatedByName
                  }
                  Time={
                    GetMembershipKnowYourCommunitySelectorData?.data
                      ?.updatedDatetime
                  }
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      {help && (
        <Help
          hidePopup={hideShowHelp}
          keyValue="membership-know-your-community"
        />
      )}
    </div>
  );
};

MembershipKnowYourCommunityTab.propTypes = {
  handleGetMembershipNowYourCommunity: PropTypes.func.isRequired,
  handlePostMembershipNowYourCommunity: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetMembershipKnowYourCommunitySelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetMembershipNowYourCommunity: getMembershipNowYourCommunity,
  handlePostMembershipNowYourCommunity: postMembershipNowYourCommunity,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetMembershipKnowYourCommunitySelectorData:
    GetMembershipKnowYourCommunitySelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(MembershipKnowYourCommunityTab));
