import React, { useState } from 'react';
import PropTypes from 'prop-types';
import crossiconblue from '@static/images/cross-icon-blue.svg';

const RejectPopup = props => {
  const { showRejectPopup, setShowRejectPopup, handleReject } = props;
  const [comment, setComment] = useState('');

  const modal = {
    background: 'rgb(0 0 0 / 0.5)'
  };
  const button = {
    width: '25px',
    heigt: '25px'
  };
  const showHideClassName = showRejectPopup
    ? 'modal d-flex align-items-center'
    : 'modal d-none';
  return (
    <div
      className={showHideClassName}
      style={modal}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ymca-modal view-details-modal-dialog">
        <div className="modal-content p-3">
          <div className="modal-header py-1 border-0">
            <div className="ymca-black-sub-heading">
              Add Comments for Rejection
            </div>
            <button
              type="button"
              className="btn-close"
              style={button}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowRejectPopup(false)}
            >
              <img
                src={crossiconblue}
                alt="cross-icon-blue"
                className="close-icon img-fluid"
              />
            </button>
          </div>
          <div className="modal-body py-0">
            <div className="col-lg-auto ymca-label pe-0 insurence-reject-popup">
              <div className="ymca-form">
                <div className="form-group">
                  <span> Comments</span>
                  <textarea
                    className="form-control mt-2"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Add comments"
                    onChange={e => {
                      setComment(e.target.value);
                    }}
                    value={comment}
                  />
                </div>
              </div>
            </div>
            <br />
            <button
              className="btn text-danger px-0 approve-text "
              type="button"
              disabled={!comment}
              onClick={() => {
                handleReject(comment);
                setShowRejectPopup(false);
              }}
            >
              Reject
            </button>
            <button
              className="btn px-3 approve-text"
              type="button"
              onClick={() => setShowRejectPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

RejectPopup.propTypes = {
  showRejectPopup: PropTypes.bool,
  setShowRejectPopup: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired
};
RejectPopup.defaultProps = {
  showRejectPopup: false
};

export default RejectPopup;
