const ASSOCIATION_COLUMNS = [
  {
    Header: 'Association Name',
    accessor: 'AssociationName',
    className: 'notranslate ymca-asso-wrap'
  },
  {
    Header: 'Contact Email',
    accessor: 'ContactEmail',
    className: 'notranslate'
  },
  {
    Header: 'Contact Number',
    accessor: 'ContactNumber',
    className: 'notranslate'
  },
  {
    Header: 'Member Group',
    accessor: 'MemberGroup',
    className: 'notranslate'
  },
  {
    Header: 'Source System',
    accessor: 'SourceSystem',
    className: 'notranslate'
  },
  {
    Header: 'Last Uploaded Data On',
    accessor: 'LastUpdatedOn',
    className: 'notranslate'
  },
  {
    Header: '',
    accessor: 'DetailPage'
  }
];

export default ASSOCIATION_COLUMNS;
