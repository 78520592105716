import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { APP_LABELS } from '@config/app-config';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { DashboardOverviewSelector } from '@redux/annualReport-overview/selectors';
import get from 'lodash.get';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import OverviewTab from './00-overview';
import YMCADirectoryInformationTab from './01-ymca-directory-information';
import EmployeesTab from './02-employees';
import ConstituencyReportTab from './03-constituency-report';
import DiversityGlobalTab from './04-diversity-inclusion-&-global-metrics';
import CollaborationsTab from './05-collaborations';
import ProgramActivitesTab from './07-program-&-activites';
import PledgeStatementTab from './08-pledge-statement';
import ReviewSubmitTab from './09-review-&-submit';
import SidebarNav from './sidebar-nav';
import SiteInformation from './site-information';

const AnnualReports = props => {
  const {
    location,
    AnnualReportsDashboardData,
    handleGetAnnualReportsDashboard,
    LoginUserData
  } = props;

  const { state } = location;
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState('v-pills-Overview-tab');
  const statusList = AnnualReportsDashboardData?.data
    ? AnnualReportsDashboardData?.data?.dashboardDTOs
    : null;
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);
  const[isDataLoading,setIsDataLoading]=useState(true);

  useEffect(() => {
    const element = document.getElementById(state?.sectionId);
    if (!isDataLoading && element) {
        element?.click();
      }
  }, [isDataLoading,state.sectionId]);

  useEffect(() => {
    if (state?.selectedYear)
      sessionStorage.setItem('selectedBatchYear', state?.selectedYear);
  }, [state?.selectedYear]);

  useEffect(() => {
    handleGetAnnualReportsDashboard(associationNumber, state?.selectedYear);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('lastactivetab', activeTab);
    if (activeTab === 'v-pills-Overview-tab') {
      sessionStorage.setItem('dirty', false);
    }
  }, [activeTab]);
  
  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="program-admin-wrapper dashboard-wrapper">
          <div className="container-fluid">
            <section className="dashboard-header">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="d-flex align-items-start ymca-itemArea">
                    <SidebarNav
                      setActiveTab={setActiveTab}
                      setIsDataLoading={setIsDataLoading}
                    />
                    <div
                      className={`tab-content ymca-tabContent ymca-noPad ${
                        (role === APP_LABELS.YUSA_DATA_VIEWER &&
                          activeTab !== 'v-pills-Overview-tab' &&
                          activeTab !== 'v-pills-ReviewSubmit-tab') ||
                        (role === APP_LABELS.ALLIANCE_DATA_STEWARD &&
                          activeTab !== 'v-pills-Overview-tab' &&
                          activeTab !== 'v-pills-ReviewSubmit-tab') ||
                        (role === APP_LABELS.YUSA_DATA_STEWARD &&
                          activeTab === 'v-pills-Pledge-tab') ||
                        (role === APP_LABELS.YESS_DATA_STEWARD &&
                          activeTab === 'v-pills-Pledge-tab') ||
                        (role === APP_LABELS.ASSOCIATION_DATA_STEWARD &&
                          activeTab === 'v-pills-Pledge-tab' &&
                          state?.selectedYear >= '2023') ||
                        (statusList &&
                          statusList[0]?.status?.toUpperCase() ===
                            'SUBMITTED' &&
                          activeTab !== 'v-pills-Overview-tab')
                          ? 'disable-input-role no-cursor'
                          : ''
                      }`}
                      id="v-pills-tabContent"
                    >
                      <div
                        className="tab-pane ymca-tabPane fade show active"
                        id="v-pills-Overview"
                        role="tabpanel"
                        aria-labelledby="v-pills-Overview-tab"
                      >
                        {activeTab === 'v-pills-Overview-tab' && (
                          <OverviewTab setLoader={setLoader} />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane ymca-varientTwo fade"
                        id="v-pills-Directory"
                        role="tabpanel"
                        aria-labelledby="v-pills-Directory-tab"
                      >
                        {activeTab === 'v-pills-Directory-tab' && (
                          <YMCADirectoryInformationTab
                            setLoader={setLoader}
                            status={statusList[0]?.status}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane ymca-varientTwo fade"
                        id="v-pills-siteInformations"
                        role="tabpanel"
                        aria-labelledby="v-pills-siteInformations-tab"
                      >
                        {activeTab === 'v-pills-siteInformations-tab' && (
                          <SiteInformation
                            setLoader={setLoader}
                            status={statusList[1]?.status}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane fade"
                        id="v-pills-updateEmployee"
                        role="tabpanel"
                        aria-labelledby="v-pills-updateEmployee-tab"
                      >
                        {activeTab === 'v-pills-updateEmployee-tab' && (
                          <EmployeesTab
                            setLoader={setLoader}
                            status={statusList[2]?.status}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane ymca-varientTwo fade"
                        id="v-pills-Constituency"
                        role="tabpanel"
                        aria-labelledby="v-pills-Constituency-tab"
                      >
                        {activeTab === 'v-pills-Constituency-tab' && (
                          <ConstituencyReportTab
                            setLoader={setLoader}
                            status={statusList[3]?.status}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane ymca-varientTwo fade"
                        id="v-pills-globalMetrics"
                        role="tabpanel"
                        aria-labelledby="v-pills-globalMetrics-tab"
                      >
                        {activeTab === 'v-pills-globalMetrics-tab' && (
                          <DiversityGlobalTab
                            setLoader={setLoader}
                            status={statusList[4]?.status}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane fade"
                        id="v-pills-Collaborations"
                        role="tabpanel"
                        aria-labelledby="v-pills-Collaborations-tab"
                      >
                        {activeTab === 'v-pills-Collaborations-tab' && (
                          <CollaborationsTab
                            setLoader={setLoader}
                            status={statusList[5]?.status}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane ymca-varientTwo fade "
                        id="v-pills-Program"
                        role="tabpanel"
                        aria-labelledby="v-pills-Program-tab"
                      >
                        {activeTab === 'v-pills-Program-tab' && (
                          <ProgramActivitesTab
                            setLoader={setLoader}
                            status={statusList[6]?.status}
                          />
                        )}
                      </div>

                      <div
                        className="tab-pane ymca-tabPane ymca-varientTwo fade"
                        id="v-pills-Pledge"
                        role="tabpanel"
                        aria-labelledby="v-pills-Pledge-tab"
                      >
                        {activeTab === 'v-pills-Pledge-tab' && (
                          <PledgeStatementTab
                            setLoader={setLoader}
                            status={statusList[7]?.status}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane ymca-tabPane ymca-varientTwo fade"
                        id="v-pills-ReviewSubmit"
                        role="tabpanel"
                        aria-labelledby="v-pills-ReviewSubmit-tab"
                      >
                        {activeTab === 'v-pills-ReviewSubmit-tab' && (
                          <ReviewSubmitTab
                            setLoader={setLoader}
                            branchStatus={statusList[8]?.status}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </BlockUi>
  );
};

AnnualReports.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  AnnualReportsDashboardData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  AnnualReportsDashboardData: DashboardOverviewSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
const mapDispatchToProps = {
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AnnualReports));
