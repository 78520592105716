import * as types from './types';

const initialState = {
  GetModulesConfigData: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_MODULES_CONFIG_SUCCESS: {
      return {
        ...state,
        GetModulesConfigData: action.payload
      };
    }
    case types.GET_MODULES_CONFIG_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
